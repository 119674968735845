import moment from "moment";
import { Card, Statistic } from "antd";
import { Line } from '@ant-design/plots';
import { Datum } from "@antv/g2plot";
import { convertToCurrency } from "../../../utils/conversion";
import { FallOutlined, RiseOutlined } from "@ant-design/icons";
import { WidgetColor } from "../../../types";
import WidgetSkeleton from "./WidgetSkeleton";
import { Fragment, useEffect, useMemo } from "react";

interface YearlyWidgetProps {
    data: object[];
    totalCostPastYear: number;
    trendNextYear: number;
    totalCostNextYear?: number;
    loading?: boolean;
}

const YearlyCostWidget = ({
    data,
    totalCostPastYear,
    trendNextYear,
    totalCostNextYear,
    loading
}: YearlyWidgetProps) => {

    const lineChart = useMemo(() => {
        return (
            <Line
                data={data}
                xField="start"
                yField="cost"
                xAxis={{
                    label: {
                        formatter: (v) => `${moment(v).format("MMM 'YY")}`,
                    }
                }}
                yAxis={{
                    label: {
                        formatter: (v) => `$${v}`,
                    }
                }}
                tooltip={{
                    title: "Cost for month",
                    formatter: (v: Datum) => {
                        return {
                            name: moment(v.start).format("MMMM YYYY"),
                            value: convertToCurrency(v.cost)
                        }
                    }
                }}
                smooth={true}
                animation={{
                    appear: {
                        animation: 'path-in',
                        duration: 3000,
                    },
                }}
                height={200}
                color={WidgetColor.Blue}
            />
        );
    }, [data]);

    return (
        <Card title="Year-to-date spent">
            {loading ?
                <WidgetSkeleton width={610} height={150} /> :
                <Fragment>
                    <div>
                        <Statistic
                            value={convertToCurrency(totalCostPastYear)}
                        />
                        <div>
                            {
                                Math.abs(trendNextYear) === Infinity ||
                                    !totalCostNextYear
                                    ? "trend not available"
                                    :
                                    <>
                                        <span style={{ color: trendNextYear > 0 ? "green" : "red" }}>
                                            <b>
                                                {
                                                    trendNextYear > 0 ? <FallOutlined /> : <RiseOutlined />
                                                }
                                                &nbsp;{trendNextYear > 0 ? "-" : "+"}
                                                {Math.abs(trendNextYear)}% ({convertToCurrency(totalCostNextYear)})
                                            </b>
                                        </span>
                                        <span style={{ color: "GrayText" }}>
                                            &nbsp;estimated forecast total for the upcoming year
                                        </span>
                                    </>
                            }
                        </div>
                    </div>
                    <br />
                    {lineChart}
                </Fragment>
            }
        </Card>
    )
};
export default YearlyCostWidget;