import { Button, Card, Col, Row, Skeleton, Space, Typography } from "antd";
import { App, Component } from "../../types";
import GitCommitLink from "../../components/GitCommitLink";
import MessageCard from "../../components/cards/MessageCard";
import { CrownOutlined, GlobalOutlined, InfoCircleOutlined, KubernetesOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { trackAssignDomainInit } from "../../backend/tracking/services";
import GitIcon from "../../assets/git-icon.svg";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { useNavigate, useParams } from "react-router";
import FargateLogo from "../../assets/fargate.png";
import { getEnvironmentService } from "../../backend";

const { Text } = Typography;

interface AppOverviewProps {
    component: Component;
    app: App;
}

export default function AppOverview({
    component,
    app
}: AppOverviewProps) {
    const navigate = useNavigate();
    const { projectId, envId } = useParams() as { projectId: string; envId: string; }
    const currentProject = useRecoilValue(currentProjectState);
    const [domainDisplaySettings, setDomainDisplaySettings] = useState<{
        ctaCard: boolean;
        assignCustomDomainBtn: boolean;
        displayDomain: boolean;
    }>();
    const [domain, setDomain] = useState<{ name: string; active: boolean; }>();

    const checkIfDomainIsWorking = (kubeConfig: any[]) => {
        // ingress-tls is used as a default secretName for shared clusters; 
        // ingress-tls is part of the certificate resource in a personal cluster => domain is not yet configured
        const hasCertificate = !!kubeConfig.find(r => r.kind === "Certificate" && r.apiVersion === "cert-manager.io/v1" && r.spec?.issuerRef?.kind === "ClusterIssuer" && r.spec?.secretName !== "ingress-tls");
        return hasCertificate;
    };

    useEffect(() => {
        /**
            * free & no domain                     - MessageCard
            * free & domain (microtica.rocks)      - MessageCard - display domain
            * free & domain (custom)               - NOT POSSIBLE
            * starter & no domain                  - Button
            * starter & domain (microtica.rocks)   - display domain
            * starter & domain (custom)            - display domain
        */

        const load = async () => {
            if (component.type === "kubernetes") {
                const domainName = app.deployment?.configurations.find(c => c.key === "MIC_DOMAIN_NAME")?.value || "n/a";
                const isDomainActive = checkIfDomainIsWorking(JSON.parse(app.deployment?.kubeConfig || "[{}]"));

                if (currentProject.paymentPlan?.id === "FREE") {
                    // Free plan project
                    if (isDomainActive) {
                        // Active domain
                        if (domainName === `${app.name}-${app.namespace}.microtica.rocks`) {
                            // Free plan & Active Microtica domain
                            setDomainDisplaySettings({
                                ctaCard: true,
                                assignCustomDomainBtn: false,
                                displayDomain: true
                            });
                        }
                    } else {
                        // Free plan & No domain
                        setDomainDisplaySettings({
                            ctaCard: true,
                            assignCustomDomainBtn: false,
                            displayDomain: false
                        });
                    }
                } else {
                    // Paid plan project
                    if (isDomainActive) {
                        // Paid plan & any domain
                        setDomainDisplaySettings({
                            ctaCard: false,
                            assignCustomDomainBtn: false,
                            displayDomain: true
                        });
                    } else {
                        // Paid plan & No domain
                        setDomainDisplaySettings({
                            ctaCard: false,
                            assignCustomDomainBtn: true,
                            displayDomain: false
                        });
                    }
                }
                setDomain({
                    name: `https://${domainName}`,
                    active: isDomainActive
                });
            } else {
                const { data: { outputs } } = await getEnvironmentService().getOutputsForResource(envId, component.name, projectId);
                const accessUrl = outputs?.find(o => o.key === "AccessUrl")?.value || "";
                const CNAME = outputs?.find(o => o.key === "CNAME")?.value;
                const displayAssignDomainBtn = !!accessUrl && !!CNAME && new URL(accessUrl).hostname === CNAME.toLowerCase();

                if (currentProject.paymentPlan?.id === "FREE" && displayAssignDomainBtn) {
                    setDomainDisplaySettings({
                        ctaCard: true,
                        assignCustomDomainBtn: true,
                        displayDomain: !!accessUrl
                    });
                } else if (currentProject.paymentPlan?.id !== "FREE" && displayAssignDomainBtn) {
                    setDomainDisplaySettings({
                        ctaCard: false,
                        assignCustomDomainBtn: true,
                        displayDomain: !!accessUrl
                    });
                } else {
                    setDomainDisplaySettings({
                        ctaCard: false,
                        assignCustomDomainBtn: false,
                        displayDomain: !!accessUrl
                    });
                }
                setDomain({
                    name: accessUrl,
                    active: true
                });
            }
        }

        if (app) {
            load();
        }
    }, [app]);


    const CTA = () => (
        <MessageCard
            type="cta"
            icon={
                <GlobalOutlined style={{ fontSize: 26, color: "#3399ff" }} />
            }
            text={
                <div>
                    <div style={{ fontWeight: 600 }}>Custom domain</div>
                    <div>
                        Set a custom domain to enhance your app's appeal and improve your customers' experience.
                    </div>
                </div>
            }
            extra={
                <Button
                    type="primary"
                    onClick={() => {
                        navigate(`/projects/${projectId}/environments/${envId}/components/${component.id}/${app.clusterId}/apps/${app.namespace}/${app.name}/settings/domain`);
                        trackAssignDomainInit({
                            serviceName: app.name,
                            clusterName: app.clusterId
                        });
                    }}
                >
                    <CrownOutlined /> Assign domain
                </Button>
            }
        />
    )

    const ClusterInfo = () => (
        <Space size="large">
            <div className="flex-align-center">
                {
                    component.type === "kubernetes" ?
                        <KubernetesOutlined style={{ fontSize: "30px", color: "#326CE5" }} /> :
                        <img src={FargateLogo} style={{ width: "32px" }} alt="fargate" />
                }
            </div>
            <Space size="large">
                <div>
                    <Space direction="vertical" size={0}>
                        <div>
                            Cluster ID
                        </div>
                        <div><b>{app.clusterId}</b></div>
                    </Space>
                </div>
                {
                    app.namespace &&
                    <Space direction="vertical" size={0}>
                        <div>Cluster Namespace</div>
                        <div><b>{app.namespace}</b></div>
                    </Space>
                }
            </Space>
        </Space>
    )

    const GitInfo = () => (
        <Space size="large">
            <div className="flex-align-center">
                <img src={GitIcon} style={{ height: "30px" }} alt="git" />
            </div>
            <div>
                <Space direction="vertical" size={0}>
                    <Text ellipsis={{ tooltip: app.deployment?.message }} style={{ width: "30vw" }}>
                        <b>{app.deployment?.message}</b>
                    </Text>
                    {app.deployment?.repository}
                    <GitCommitLink
                        version={app.deployment?.sha || app.deployment?.version}
                        repoUrl={app.deployment?.repository}
                        branch={app.deployment?.commitName}
                    />
                </Space>
            </div>
        </Space>
    )

    const DomainInfo = () => {
        return !domainDisplaySettings ? <Skeleton.Input /> :
            <div className="flex-justify-space-between">
                {
                    domainDisplaySettings?.displayDomain &&
                    <Space size="large">
                        <div className="flex-align-center">
                            <GlobalOutlined style={{ fontSize: 28 }} />
                        </div>
                        <div>
                            <div>Domain</div>
                            <a href={domain?.name} target="_blank" rel="noreferrer">
                                {domain?.name}
                            </a>
                        </div>
                    </Space>
                }
                {
                    domainDisplaySettings?.assignCustomDomainBtn &&
                    <>
                        <Space className="flex-align-center">
                            <Button
                                onClick={() => {
                                    if (component.type === "kubernetes") {
                                        navigate(`/projects/${projectId}/environments/${envId}/components/${component.id}/${app.clusterId}/apps/${app.namespace}/${app.name}/settings/domain`);
                                        trackAssignDomainInit({
                                            serviceName: app.name,
                                            clusterName: app.clusterId
                                        });
                                    } else {
                                        navigate(`/projects/${projectId}/environments/${envId}/components/${component.id}/settings/domain`);
                                    }
                                }}
                            >
                                <CrownOutlined style={{ color: "goldenrod" }} /> {component.type === "kubernetes" ? "Assign domain" : "Assign custom domain"}
                            </Button>
                            {
                                component.type === "kubernetes" &&
                                <div className="gray-text">
                                    <InfoCircleOutlined /> Assign a domain to make your app accessible on the internet.
                                </div>
                            }
                        </Space>
                    </>
                }
            </div>
    }


    return (
        <Space direction="vertical" size="large" className="full-width">
            <div className="flex-justify-space-between">
                <div style={{ fontWeight: 600, fontSize: 16 }}>
                    Application Overview
                    <div className="gray-text" style={{ fontWeight: 400, fontSize: 14 }}>
                        Applications are your source code deployed on cluster components.
                    </div>
                </div>
            </div>
            {
                domainDisplaySettings?.ctaCard &&
                <Row gutter={24} style={{ marginBottom: 20 }}>
                    <Col xs={24}>
                        <CTA />
                    </Col>
                </Row>
            }
            <Card>
                <Space direction="vertical" size="large" className="full-width">
                    {/* Cluster details */}
                    <ClusterInfo />

                    {/* Git commit info */}
                    <GitInfo />

                    {/* Domain info */}
                    <DomainInfo />
                </Space>
            </Card>
        </Space>
    )
}