import GitIcon from "./GitIcon";

interface GitCommitLinkProps {
    version?: string;
    repoUrl?: string;
    commitType?: string;
    branch?: string;
    hideIcon?: boolean;
    color?: "gray" | "black"
}

const GitCommitLink = ({
    version,
    repoUrl,
    commitType,
    branch,
    hideIcon,
    color = "black"
}: GitCommitLinkProps) => {
    if (!version) {
        return <>none</>;
    }

    try {
        new URL(repoUrl!);
        if (version.includes("-") || !repoUrl) {
            return <Link
                to={""}
                commitType={commitType}
                version={version}
                repoUrl={repoUrl}
                branch={branch}
                hideIcon={hideIcon}
                color={color}
            />
        }
    } catch (error) {
        return <Link
            to={""}
            commitType={commitType}
            version={version}
            repoUrl={repoUrl}
            branch={branch}
            hideIcon={hideIcon}
            color={color}
        />;
    }

    const { host, pathname } = new URL(repoUrl);
    let commitUrl = "";

    switch (host) {
        case "github.com":
            commitUrl = `${repoUrl}/commit/${version}`;
            break;
        case "bitbucket.org":
            commitUrl = `${repoUrl}/commits/${version}`;
            break;
        case "gitlab.com":
            commitUrl = `${repoUrl}/commit/${version}`;
            break
        default:
            const [, region] = host.split(".");
            const repoName = pathname.split("/").reverse()[0];
            commitUrl = `https://${region}.console.aws.amazon.com/codesuite/codecommit/repositories/${repoName}/commit/${version}?region=${region}`;
            break;
    }

    return <Link
        to={commitUrl}
        commitType={commitType}
        version={version}
        repoUrl={repoUrl}
        branch={branch}
        hideIcon={hideIcon}
        color={color}
    />
}

const Link = ({
    to,
    version,
    commitType,
    branch,
    repoUrl,
    hideIcon,
    color
}: GitCommitLinkProps & { to: string; }) => {
    const shortVersion = commitType === "tag" && version?.includes("-") ? version : version!.substring(0, 7);

    return (
        <>
            {hideIcon ? undefined : <><GitIcon repoUrl={repoUrl} color={color} /> </>}{to ?
                <a href={to} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
                    {branch ? `${branch}@` : branch}{shortVersion}
                </a> :
                <>
                    {branch ? `${branch}@` : branch}{shortVersion}
                </>
            }
        </>
    )
}

export default GitCommitLink;