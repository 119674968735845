import { GetPricingForEC2WithPriceResponse, GetPricingForRDSResponseClusters, GetPricingForRDSResponseInstances } from "@microtica/ms-cloud-cost-optimizer-sdk";
import { getCostOptimizationService } from "../../backend";
import { ESTIMATED_SAVINGS_COEFICIENT, TOTAL_HOURS_IN_MONTH } from "../cron";
import { DEFAULT_COST_ALLOCATION_TAG } from "../../enums/enums";

interface IExpectedSavingsAndCostRequest {
    awsAccountId: string,
    awsRegion: string,
    projectId: string,
    envId: string
}
type InstancePricingType = (GetPricingForEC2WithPriceResponse | GetPricingForRDSResponseInstances | GetPricingForRDSResponseClusters) & { type: string };


const calculateStoragePricing = (instance: InstancePricingType): number => {
    if (instance.type === "ec2") {
        return (instance as GetPricingForEC2WithPriceResponse).ebsData!.reduce((acc, ebs) => {
            acc += ebs.price || 0;
            return acc;
        }, 0)
    }
    return instance.type === "rds" ?
        (instance as GetPricingForRDSResponseInstances | GetPricingForRDSResponseClusters).storagePrice || 0 :
        0
}

export const getExpectedMonthlySavingsAndCost = async ({
    awsAccountId,
    awsRegion,
    projectId,
    envId
}: IExpectedSavingsAndCostRequest) => {
    // https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/monitoring-instance-state-changes.html
    const EC2_BILLED_STATUSES = ["pending", "running"];
    // source: https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/accessing-monitoring.html
    const RDS_INSTANCE_BILLED_STATUSES = ["available", "backing-up", "configuring-enhanced-monitoring", "configuring-iam-database-auth", "configuring-log-exports", "converting-to-vpc", "incompatible-option-group", "incompatible-parameters", "maintenance", "modifying", "moving-to-vpc", "rebooting", "resetting-master-credentials", "renaming", "restore-error", "storage-full", "storage-optimization", "upgrading"];
    // source: https://docs.aws.amazon.com/AmazonRDS/latest/AuroraUserGuide/accessing-monitoring.html
    const RDS_CLUSTER_BILLED_STATUSES = ["available", "backing-up", "backtracking", "failing-over", "maintenance", "migrating", "modifying", "promoting", "renaming", "resetting-master-credentials", "storage-optimization", "update-iam-db-auth", "upgrading"];


    const { data: allResourcesWithPricing } = await getCostOptimizationService().getAllResourcesWithPricing(awsAccountId, projectId, awsRegion, false, DEFAULT_COST_ALLOCATION_TAG, envId);

    const activeEc2Resources: InstancePricingType[] = [];
    const stoppedEc2Resources: InstancePricingType[] = [];
    const activeRdsResources: InstancePricingType[] = [];
    const stoppedRdsResources: InstancePricingType[] = [];

    allResourcesWithPricing.ec2.forEach(resource => {
        if (EC2_BILLED_STATUSES.includes(resource.state)) {
            activeEc2Resources.push({ ...resource, type: "ec2" });
        } else {
            stoppedEc2Resources.push({ ...resource, type: "ec2" });
        }
    });

    allResourcesWithPricing.rds.clusters.forEach(resource => {
        if (RDS_CLUSTER_BILLED_STATUSES.includes(resource.state)) {
            activeRdsResources.push({ ...resource, type: "rds" });
        } else {
            stoppedRdsResources.push({ ...resource, type: "rds" });
        }
    });

    allResourcesWithPricing.rds.instances.forEach(resource => {
        if (RDS_INSTANCE_BILLED_STATUSES.includes(resource.state)) {
            activeRdsResources.push({ ...resource, type: "rds" });
        } else {
            stoppedRdsResources.push({ ...resource, type: "rds" });
        }
    });

    const activeEc2AndRdsResources = [
        ...activeEc2Resources,
        ...activeRdsResources
    ];

    const estimatedEc2AndRdsCostPerEnvironment = +(activeEc2AndRdsResources.reduce((totalCost, instance) => {
        if (instance.price) {
            return totalCost + (instance.price * TOTAL_HOURS_IN_MONTH) + calculateStoragePricing(instance)
        } else {
            return totalCost;
        }
    }, 0).toFixed(2));

    // get expected savings
    let estimatedEc2CostPerAwsAccount = 0;
    let estimatedRdsCostPerAwsAccount = 0;
    let estimatedAllServicesCostPerAwsAccount = 0;
    const { data: { estimatedCostForTheCurrentMonth } } = await getCostOptimizationService().getCostForecastForCurrentMonth(projectId!, awsAccountId);
    if (estimatedCostForTheCurrentMonth) {
        estimatedEc2CostPerAwsAccount = estimatedCostForTheCurrentMonth.ids[awsAccountId].ec2;
        estimatedRdsCostPerAwsAccount = estimatedCostForTheCurrentMonth.ids[awsAccountId].rds;
        estimatedAllServicesCostPerAwsAccount = estimatedCostForTheCurrentMonth.ids[awsAccountId].allServices;
    }
    const expectedMonthlySavingsPerEnvironment = +((estimatedEc2AndRdsCostPerEnvironment * ESTIMATED_SAVINGS_COEFICIENT).toFixed(2));

    const expectedMonthlySavingsPercentagePerEnvironment = estimatedAllServicesCostPerAwsAccount === undefined ? 0 :
        Math.round((expectedMonthlySavingsPerEnvironment / estimatedAllServicesCostPerAwsAccount) * 100);

    return {
        envId,
        awsAccount: {
            estimatedEc2Cost: estimatedEc2CostPerAwsAccount,
            estimatedRdsCost: estimatedRdsCostPerAwsAccount,
            estimatedAllServicesCost: estimatedAllServicesCostPerAwsAccount
        },
        environment: {
            estimatedEc2AndRdsCost: estimatedEc2AndRdsCostPerEnvironment,
            expectedMonthlySavings: expectedMonthlySavingsPerEnvironment,
            expectedMonthlySavingsPercentage: expectedMonthlySavingsPercentagePerEnvironment
        },
        activeResources: activeEc2AndRdsResources.map(r => ({ id: r.id, type: r.type })),
        stoppedResources: [...stoppedEc2Resources, ...stoppedRdsResources].map(r => ({ id: r.id, type: r.type }))
    }
}

