

import { Divider, Space } from "antd";
import { AWS_REGIONS, GCP_REGIONS } from "../enums/enums";
import { Link, useParams } from "react-router-dom";
import { CloudProvider } from "../types";
import CloudIcon from "./CloudIcon";
import Paragraph from "antd/es/typography/Paragraph";

interface CloudAccountLabelProps {
    envId: string;
    accountId?: string;
    region?: string;
    provider?: CloudProvider;
}

const CloudAccountLabel = ({
    envId,
    accountId,
    region,
    provider
}: CloudAccountLabelProps) => {
    const { projectId } = useParams() as { projectId: string; }
    return (
        <div style={{ fontSize: "14px", fontWeight: 500 }}>
            {
                provider === "aws" && accountId && region ?
                    <Space>
                        <CloudIcon provider={provider} />
                        {accountId}
                        <span className="gray-text">
                            - {AWS_REGIONS.find(r => r.value === region || r.id === region)?.name}
                        </span>
                    </Space> :
                    provider === "google" && accountId && region ?
                        <div style={{ width: "20vw" }}>
                            <Paragraph
                                ellipsis={{
                                    rows: 1,
                                    tooltip: `${accountId} - ${GCP_REGIONS.find(r => r.value === region || r.id === region)?.name}`
                                }}
                            >
                                <CloudIcon provider={provider} />&nbsp;
                                {accountId}&nbsp;
                                <span className="gray-text">
                                    - {GCP_REGIONS.find(r => r.value === region || r.id === region)?.name}
                                </span>
                            </Paragraph>
                        </div>
                        :
                        <div style={{ fontWeight: "normal" }}>
                            <Space>
                                <CloudIcon provider={provider} />
                                No linked cloud account
                            </Space>
                            <Divider type="vertical" />
                            <Link
                                to={`/projects/${projectId}/environments/${envId}/settings`}
                                onClick={(e) => e.stopPropagation()}
                            >
                                Link account
                            </Link>
                        </div>

            }
        </div >
    )
}

export default CloudAccountLabel;