const CostDashboardPotentialSavingsExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Potential savings
            </div>
            <div className="explanation-subtitle">
                Estimated saving hours: 472 hours/month
            </div>
            <br />

            <p>
                Estimated savings if you limit your EC2 and RDS resources to run <b>exclusively</b> during <b>weekday business hours.</b>
            </p>
        </>
    )
}

export default CostDashboardPotentialSavingsExplanation;