import { Card, Col, Empty, Result, Row, Skeleton, Space } from "antd";
import Meta from "antd/lib/card/Meta";
import Paragraph from "antd/lib/typography/Paragraph";
import { Fragment, ReactNode, useEffect, useState } from "react";
import { NavigateOptions, useNavigate } from "react-router";
import Search from "antd/es/input/Search";
import { IaC } from "../types";

export interface GridListDataSource {
    key: string;
    title: string | ReactNode;
    description?: string | ReactNode;
    type?: "component" | "app" | string;
    filterMetadata?: string;
    avatar?: ReactNode;
    tags?: {
        position: string;
        value: ReactNode;
    }[];
    link?: string;
    disabled?: boolean;
    className?: string;
    pack?: string;          // "free" | "starter" | "advanced"
    cloudProvider?: "aws" | "google" | "azurerm" | string;
    iac?: IaC;
    deploymentTarget?: "kubernetes" | "fargate";
    group?: "resources" | "templates";
    repoUrl?: string;
    extra?: ReactNode;
}
export interface GridListProps {
    dataSource: GridListDataSource[];
    loading?: boolean;
    navigateState?: NavigateOptions | object;
    disableEmptyState?: boolean;
    itemsInRow?: 2 | 3 | 4 | 6 | 8;
    hoverable?: boolean;
    filter?: boolean;
    filterPlaceholder?: string;
}

const GridList = ({
    dataSource,
    loading,
    navigateState,
    disableEmptyState,
    itemsInRow,
    hoverable,
    filter,
    filterPlaceholder = "Search"
}: GridListProps) => {
    const navigate = useNavigate();

    const [filteredItems, setFilteredItems] = useState<GridListDataSource[]>(dataSource);
    const [filterText, setFilterText] = useState("");


    useEffect(() => {
        if (filterText !== "") {
            setFilteredItems(
                dataSource.filter(item =>
                    (item.filterMetadata || item.key).toLowerCase().includes(filterText.toLowerCase())
                )
            )
        } else {
            setFilteredItems(dataSource);
        }

    }, [dataSource, filterText]);

    return (
        <div className="grid-list">
            {
                loading ? <Skeleton /> :
                    <>
                        {
                            filter ?
                                <>
                                    <Row gutter={[24, 24]}>
                                        <Col xs={24} sm={12} md={8} lg={itemsInRow ? 24 / itemsInRow : 8}>
                                            <Search
                                                placeholder={filterPlaceholder}
                                                allowClear
                                                type="primary"
                                                size="large"
                                                onSearch={value => setFilterText(value)}
                                            />
                                        </Col>
                                    </Row>
                                    <br />
                                </> : undefined
                        }
                        {filteredItems.length !== 0 ?
                            <>
                                <Row gutter={[24, 24]}>
                                    {
                                        filteredItems
                                            .map(item => (
                                                <Col xs={24} sm={12} md={8} lg={itemsInRow ? 24 / itemsInRow : 8} style={{ display: "inline-flex", alignSelf: "stretch" }} key={item.key}>
                                                    <Card
                                                        hoverable={hoverable === undefined ? true : hoverable}
                                                        className={(() => {
                                                            const customClasses = ["grid-list-card"];
                                                            item.className && customClasses.push(item.className);
                                                            item.disabled && customClasses.push("ant-card-disabled");
                                                            return customClasses.join(" ");
                                                        })()}
                                                        onClick={() => item.link && navigate(item.link, navigateState)} style={{ width: "100%" }}
                                                    >
                                                        <Meta
                                                            title={
                                                                <div className="flex-justify-space-between">
                                                                    <Space className="flex-align-center">
                                                                        {item.avatar}
                                                                        <div style={{ lineHeight: "28px" }}>
                                                                            <div style={{ whiteSpace: "break-spaces" }}>
                                                                                {item.title}
                                                                            </div>
                                                                            {
                                                                                (item.tags || [])
                                                                                    .filter(item => item.position === "top")
                                                                                    .map((tag, index) => <Fragment key={index}>{tag.value}</Fragment>)
                                                                            }
                                                                        </div>
                                                                    </Space>
                                                                    <>{item.extra}</>
                                                                </div>
                                                            }
                                                            description={
                                                                <Paragraph ellipsis={{ rows: 2 }} style={{ color: "GrayText" }}>
                                                                    {item.description}
                                                                </Paragraph>
                                                            }
                                                        />
                                                        <div className="ant-card-footer">
                                                            {
                                                                (item.tags || [])
                                                                    .filter(item => item.position === "bottom")
                                                                    .map((tag, index) => <Fragment key={index}>{tag.value}</Fragment>)
                                                            }
                                                        </div>
                                                    </Card>
                                                </Col>
                                            ))
                                    }
                                </Row>
                            </>
                            :
                            !disableEmptyState ?
                                <Result
                                    icon={Empty.PRESENTED_IMAGE_SIMPLE}
                                    subTitle={
                                        <>
                                            <div>
                                                It looks like there are no matching results found.
                                            </div>
                                            <div>
                                                Please try another search.
                                            </div>
                                        </>
                                    }
                                /> :
                                undefined
                        }
                    </>
            }
        </div>
    )
}

export default GridList;