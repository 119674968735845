import { Button, Card, Form, notification, Space, Steps } from "antd";
import Text from 'antd/lib/typography/Text';
import { LoadingOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getKubeService, getPipelinesService, getProjectService } from "../../backend";
import { currentProjectState } from "../../recoil/project";
import { IAppDetails } from "../../types";
import { RequiredMark } from "antd/lib/form/Form";
import { deployApplication, handleRestartApp } from "../../utils/application/handle-app-danger-actions";
import { useNavigate, useParams } from "react-router-dom";
import { BACKEND_TIMEOUT_IN_MS, PROPAGATION_WAITING_TIME, DOMAIN_SETUP_WAITING_TIME, getAssignAppDomainSteps, AXIOS_TIMEOUT_ERROR_CODE } from "../../utils/application/assign-domain-steps";
import { useCurrentProject } from "../../contexts/Project";
import { trackAssignDomainFailed, trackAssignDomainSucceeded } from "../../backend/tracking/services";

interface AssignAppDomainStepsProps {
    appDetails: IAppDetails;
    envId: string;
    updateDomainEnabled: boolean;
    onCancel?: () => void;
}

const AssignAppDomainSteps = ({
    appDetails,
    envId,
    updateDomainEnabled,
    onCancel
}: AssignAppDomainStepsProps) => {
    const navigate = useNavigate();
    const { projectId, clusterId, namespace, appName } = useParams() as {
        projectId: string;
        componentId: string;
        clusterId: string;
        namespace: string;
        appName: string;
    };
    const currentProject = useRecoilValue(currentProjectState);
    const { updateCurrentProject } = useCurrentProject();
    const [ingressHostname, setIngressHostname] = useState<string>();
    const [ingressIP, setIngressIP] = useState<string>();
    const [useCustomDomain, setUseCustomDomain] = useState(appDetails.domain !== `${appDetails.name}-${appDetails.namespace}.microtica.rocks`);
    const [newDomain, setNewDomain] = useState(appDetails.domain);
    const [currentStep, setCurrentStep] = useState(0);
    const [currentAction, setCurrentAction] = useState<number>();
    const [form] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>('optional');
    // Waiting time for the connection to be established (in seconds)
    const [waitingTime, setWaitingTime] = useState(0);

    // handle deploy for app with old template format
    const handleApplyNewTemplateAndDeploy = async () => {
        setCurrentAction(2);

        const pipelineId = appDetails.pipelineId || appName!;
        const { data: { workDir, gitAccountId, automatedTrigger, branchFilter } } = await getPipelinesService().getPipelineById(projectId, pipelineId);

        try {
            await Promise.all([
                getPipelinesService().updateRepositoryFilesWithIngressConfiguration(currentProject.id, gitAccountId!, {
                    repositoryUrl: appDetails.repositoryUrl,
                    targetBranch: appDetails.branch,
                    workDir: workDir || "/"
                }),
                deployApplication({ // workaround for injecting "MIC_DOMAIN_NAME" env var
                    projectId: currentProject.id,
                    app: {
                        name: appDetails.name!,
                        clusterId,
                        namespace: appDetails.namespace,
                        image: appDetails?.image || "",
                        monitoring: appDetails?.monitoring,
                        configurations: (appDetails?.configuration || [])
                            .filter(c => c.key !== "MIC_DOMAIN_NAME")
                            .concat({ key: "MIC_DOMAIN_NAME", value: newDomain }),
                    },
                    userPaymentPlan: currentProject.paymentPlan?.id!,
                    options: { timeout: BACKEND_TIMEOUT_IN_MS }
                })
            ])
        } catch (err: any) {
            // ignore 504 gateway timeout error (ECONNABORTED)
            if (err?.code !== AXIOS_TIMEOUT_ERROR_CODE) {
                trackAssignDomainFailed({
                    serviceName: appDetails.name,
                    clusterName: clusterId,
                    domain: newDomain
                });
                notification.error({
                    message: err?.response?.data?.message || "An unexpected error occurred. Please try again."
                });
                setCurrentAction(undefined);
                return;
            }
        }

        if (!automatedTrigger || branchFilter !== appDetails.branch) {
            notification.success({
                message: "Microtica files updated successfully",
                description: <Space direction="vertical" style={{ marginTop: 12 }}>
                    <Text strong>You should manually trigger a pipeline build.</Text>
                    <Text>If automated deployments are not set for this application, you should additionally do a manual deploy of the newly built version.</Text>
                </Space>,
                duration: 0 // don't close automatically
            });
        } else {
            const LinkBtn = (<Button
                onClick={() => {
                    navigate(`/projects/${currentProject.id}/pipelines`);
                }}
                type="link"
                className="no-padding"
            >
                View active pipeline
            </Button>);

            notification.success({
                message: "Microtica files updated successfully",
                description: <Space direction="vertical" style={{ marginTop: 12 }}>
                    <Text>Pipeline is running.</Text>
                    <Text>If app deployment is not trigged at the end of this build, you should manually deploy the newly built version.</Text>
                    <Text>After the app is deployed, it would take up to a minute to create the certificate.</Text>
                    {LinkBtn}
                </Space>,
                duration: 0 // don't close automatically
            });
        }
        trackAssignDomainSucceeded({
            serviceName: appDetails.name,
            clusterName: clusterId,
            domain: newDomain
        });
        // close modal
        onCancel?.();
        setCurrentAction(undefined);
        setCurrentStep(0);
    }

    // handle deploy for app with new template format
    const handleRedeployApp = async () => {
        setCurrentAction(2);

        try {
            // redeploy app with domainName
            await handleRestartApp({
                projectId: projectId,
                envId,
                app: {
                    name: appName,
                    clusterId,
                    namespace: namespace,
                    image: appDetails?.image || "",
                    monitoring: appDetails?.monitoring,
                    configurations: (appDetails?.configuration || [])
                        .filter(c => c.key !== "MIC_DOMAIN_NAME")
                        .concat({ key: "MIC_DOMAIN_NAME", value: newDomain }),
                },
                userPaymentPlan: currentProject.paymentPlan?.id!,
                options: { timeout: BACKEND_TIMEOUT_IN_MS }
            });
        } catch (err: any) {
            trackAssignDomainFailed({
                serviceName: appName,
                clusterName: clusterId,
                domain: newDomain
            });
            notification.error({
                message: err?.response?.data?.message || "An unexpected error occurred. Please try again."
            });
            setCurrentAction(undefined);
            return;
        }

        trackAssignDomainSucceeded({
            serviceName: appName,
            clusterName: clusterId,
            domain: newDomain
        });
        // close modal
        onCancel?.();
        setCurrentAction(undefined);
        setCurrentStep(0);
    }

    const fetchIngressAndCertManagerStatus = async (initialLoad?: boolean) => {
        const { data: {
            ingressHostname,
            ingressIP,
            isCertManagerReady,
            isClusterIssuerReady,
            isIngressControllerReady,
            hasCNameDnsRecord,
            hasADnsRecord
        } } = await getKubeService().getIngressAndCertManagerStatus(clusterId, projectId, newDomain);
        // is first step completed
        const setupReady = isIngressControllerReady && isCertManagerReady && isClusterIssuerReady && (!!ingressHostname || !!ingressIP);

        setIngressHostname(ingressHostname);
        setIngressIP(ingressIP);
        if (!initialLoad) {
            // if first step (0) is completed
            if (setupReady) {
                // if second step (1) is completed, move to third step (2)
                if (hasCNameDnsRecord || hasADnsRecord) {
                    setCurrentStep(2);
                }
                else {
                    // if second step (1) is NOT completed and currentStep is not 1, move to second step (1)
                    setCurrentStep(step => {
                        if (step !== 1) {
                            if (!useCustomDomain) {
                                // create CNAME record in Cloudflare for Microtica domain
                                getKubeService().createCNameRecordForMicroservice(clusterId, appName, namespace, projectId);
                            }
                            return 1;
                        }
                        return step;
                    })
                }
            }
        }
        return { hasCNameDnsRecord, hasADnsRecord, setupReady };
    };

    useEffect(() => {
        fetchIngressAndCertManagerStatus(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkDnsRecord = () => {
        setWaitingTime(0);
        setCurrentAction(1);
        notification.info({
            message: "Propagation check has begun",
            description: "It could take a few moments"
        });
        // check until dns record is returned and move to 'Deploy application' step
        const interval = setInterval(async () => {
            const { hasCNameDnsRecord, hasADnsRecord } = await fetchIngressAndCertManagerStatus();

            setWaitingTime(waitingTime => {
                const waitingTimeElapsed = waitingTime + 5 > PROPAGATION_WAITING_TIME;
                // check if DNS record is set (success) OR the waiting time has elapsed (assume failure)
                if (hasCNameDnsRecord || hasADnsRecord || waitingTimeElapsed) {
                    // reset the state
                    clearInterval(interval);
                    setCurrentAction(undefined);

                    if (hasCNameDnsRecord || hasADnsRecord) {
                        return 0; //reset waiting time
                    } else {
                        // waiting time has elapsed -> assume the setup failed
                        trackAssignDomainFailed({
                            serviceName: appName,
                            clusterName: clusterId,
                            domain: newDomain
                        });
                        return waitingTime + 5;
                    }
                } else {
                    return waitingTime + 5;
                }
            });
        }, 5000);
        return () => clearInterval(interval);
    }

    const checkDomainConfiguration = () => {
        // check until cluster issuer is ready and move to 'Create CNAME record' step
        const interval = setInterval(async () => {
            const { setupReady } = await fetchIngressAndCertManagerStatus();

            setWaitingTime(waitingTime => {
                const waitingTimeElapsed = waitingTime + 5 > DOMAIN_SETUP_WAITING_TIME;
                // check if setup is ready (success) OR the waiting time has elapsed (assume failure)
                if (setupReady || waitingTimeElapsed) {
                    // clear the state
                    clearInterval(interval);
                    setCurrentAction(undefined);

                    if (setupReady) {
                        return 0; //reset waiting time
                    } else {
                        // waiting time has elapsed -> assume the setup failed
                        trackAssignDomainFailed({
                            serviceName: appName,
                            clusterName: clusterId,
                            domain: newDomain
                        });
                        return waitingTime + 5;
                    }
                } else {
                    return waitingTime + 5;
                }
            })
        }, 5000);
        return () => clearInterval(interval);
    }

    const shouldInitiateStarterPlanTrial = useCustomDomain && currentProject?.paymentPlan?.id === "FREE";

    const handleAddIngressAndCertManager = async () => {
        setWaitingTime(0);
        setCurrentAction(0);
        notification.info({
            message: "Domain setup has started",
            description: "It would take a few moments to do the setup",
            icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
        });
        try {
            if (shouldInitiateStarterPlanTrial) {
                const { data: { paymentPlans } } = await getProjectService().getAllPaymentPlans();
                const starterPlan = paymentPlans.find(plan => plan.id === "STARTER")!;
                await getProjectService().changeProjectSubscription(
                    currentProject!.id,
                    {
                        paymentPlanId: starterPlan.id!,
                        priceId: starterPlan.pricing.find(p => p.isDefault)!.id
                    }
                );
                await updateCurrentProject(currentProject.id);
            }
            const { setupReady } = await fetchIngressAndCertManagerStatus();

            if (!setupReady) {
                // ms-kube has a 30sec timeout
                await getKubeService().applyIngressAndCertManagerConfiguration(clusterId, projectId, { timeout: BACKEND_TIMEOUT_IN_MS });
                checkDomainConfiguration();
            } else {
                notification.success({
                    message: "Domain setup has finished"
                });
                setCurrentAction(undefined);
            }
        } catch (error: any) {
            if (error?.code === AXIOS_TIMEOUT_ERROR_CODE) {
                // 504 gateway timeout occurred, skip this error 
                checkDomainConfiguration();
            } else {
                notification.error({
                    message: "Error setting up domain",
                    description: <pre className="white-space-pre-wrap" style={{ fontSize: "12px" }}>
                        {(error as any).response.data.message}
                        <br />
                        <br />
                        {(error as any).response.data.kubernetesError.message}
                    </pre>,
                    duration: 0
                });
                setCurrentAction(undefined);
            }
        }
    }
    const stepItems = getAssignAppDomainSteps({
        currentStep,
        waitingTime,
        form,
        requiredMark,
        isModal: !updateDomainEnabled,
        app: {
            name: appDetails.name,
            namespace: appDetails.namespace,
            lastDeploymentIncludesIngressSetup: appDetails.lastDeploymentIncludesIngressSetup,
            clusterId
        },
        shouldInitiateStarterPlanTrial,
        newDomain,
        setNewDomain,
        useCustomDomain,
        setUseCustomDomain,
        handleAddIngressAndCertManager,
        handleRedeployApp,
        handleApplyNewTemplateAndDeploy,
        checkDnsRecord,
        currentAction,
        ingressHostname,
        ingressIP
    });

    return (
        <Fragment>
            <Steps
                current={currentStep}
                size="small"
                items={
                    stepItems.map((item, index) => ({
                        title: item.title,
                        disabled: currentStep !== index,
                        status: currentStep > index ? "finish" : currentStep < index ? "wait" : "process",
                        icon: currentAction === index && <LoadingOutlined />
                    }))
                }
            />
            <Card style={{ marginTop: 32 }}>
                {stepItems[currentStep].description}
            </Card>
        </Fragment>
    )
}

export default AssignAppDomainSteps;