import { Button, Empty, List, Modal, Tabs } from "antd";
import { TEMPLATES } from "../../enums/enums";
import { CrownOutlined } from "@ant-design/icons";
import { ReactNode, useEffect, useState } from "react";
import { getEnvironmentService } from "../../backend";
import TemplateAvatar from "../TemplateAvatar";
import { GridListDataSource } from "../GridList";
import Search from "antd/es/input/Search";
import { Link, useNavigate } from "react-router-dom";
import { IEnvDetails } from "../../types";
import { trackKubeConnectInit } from "../../backend/tracking/kubernetes";
import ConnectClusterModal from "./ConnectClusterModal";
import { trackTemplateKubernetesConnectExisting } from "../../backend/tracking/templates";

interface ChooseComponentModalProps {
    projectId: string;
    env: IEnvDetails;
    open?: boolean;
    onOk?: () => void;
    onCancel?: () => void;
}

const ChooseComponentModal = ({
    projectId,
    env,
    open,
    onOk,
    onCancel,
}: ChooseComponentModalProps) => {
    const navigate = useNavigate();
    const [components, setComponents] = useState<GridListDataSource[]>([]);
    const [filteredComponents, setFilteredComponents] = useState<GridListDataSource[]>([]);
    const [filter, setFilter] = useState("");
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("templates");
    const [showConnectClusterModal, setShowConnectClusterModal] = useState(false);

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            const { data: { components } } = await getEnvironmentService().getComponents(projectId);

            // combine our Components with some of the Templates that we want to be displayed in the Resources section
            const combinedComponents = [
                // add the templates that we want to display in the Resources section
                ...TEMPLATES
                    .filter(item =>
                        item.group === "resources" &&
                        (item.cloudProvider ? item.cloudProvider === env.cloudProvider : true) &&
                        (item.iac ? item.iac === env.infrastructureAsCodeTool : true)
                    )
                    .map(item => ({
                        ...item,
                        link: `/projects/${projectId}/environments/${env.id}/templates/${item.key}?template=${item.link}`,
                        filterMetadata: `${item.title} ${item.description}`
                    })),
                ...components
                    // Display only a few of our Components that dont require a VPC. These are prod values
                    .filter(item => item.cloudProvider === env.cloudProvider?.toString() &&
                        item.infrastructureAsCodeTool === env.infrastructureAsCodeTool?.toString()
                        && item.type !== "fargate"
                    )
                    // map components to the format we need to display
                    .map(resource => {
                        return {
                            resource, // we need this to pass along in the navigateState later
                            key: resource.id,
                            title: resource.name,
                            description: resource.description,
                            link: `/projects/${projectId}/environments/${env.id}/templates/${resource.id}/add-component?templateName=${resource.name}`,
                            avatar: <TemplateAvatar name={resource.cloudProvider} shape="square" size="large" />,
                            pack: resource.isPublic ? "free" : undefined,
                            tags: [
                                {
                                    position: "top",
                                    value: <span style={{ color: "GrayText", fontSize: 12 }}>Starter</span>
                                }
                            ],
                            template: ["Networking", "Amazon S3", "Amazon DynamoDB", "CloudFront + S3"].includes(resource.name),
                            filterMetadata: `${resource.name} ${resource.description} ${resource.type}`
                        }
                    })
            ];
            setComponents(combinedComponents);
            setLoading(false);
        }

        load();
    }, []);

    useEffect(() => {
        if (activeTab === "templates") {
            setFilteredComponents(
                components
                    .filter(c => !!c.pack)
                    .filter(c => filter ? c.filterMetadata?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) : true)
            );
        } else {
            setFilteredComponents(
                components
                    .filter(c => !c.pack)
                    .filter(c => filter ? c.filterMetadata?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) : true)
            );
        }

    }, [components, activeTab, filter]);

    return <Modal
        title={
            <div>
                <div>Choose template to deploy</div>
                <div className="gray-text" style={{ fontSize: "12px", fontWeight: 500 }}>
                    Component will be deployed in {env.name} environment
                </div>
            </div>
        }
        centered
        closable
        open={open}
        footer={null}
        onCancel={onCancel}
        className="ant-scollable-modal"

    >
        <br />
        <Tabs
            size="small"
            activeKey={activeTab}
            onChange={setActiveTab}
            items={[{
                key: "templates",
                label: "Microtica templates"
            }, {
                key: "my",
                label: "My templates"
            }]}
        />
        <List
            header={
                <Search className="full-width" placeholder="Search components" onSearch={setFilter} />
            }
            loading={loading}
            dataSource={filteredComponents}
            locale={{
                emptyText: <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No templates found for the search criteria or the linked cloud provider doesn't have available templates."
                />
            }}
            renderItem={(item) => (
                <List.Item
                    extra={
                        item.key === "connect-kubernetes" ?
                            // Connect existing Kubernetes
                            <Button onClick={() => {
                                setShowConnectClusterModal(true);
                                trackKubeConnectInit();
                            }}
                            >
                                Connect
                            </Button> :
                            // Deploy template
                            item.link ?
                                <Link to={item.link}>
                                    <Button>Deploy</Button>
                                </Link> :
                                undefined
                    }
                >
                    <List.Item.Meta
                        avatar={item.avatar}
                        title={
                            <>
                                {item.title} {item.pack && item.pack !== "free" ? <CrownOutlined style={{ color: "goldenrod" }} /> : undefined}
                            </>
                        }
                        description={item.description}
                    />
                </List.Item>
            )}
        />
        {
            showConnectClusterModal &&
            <ConnectClusterModal
                envId={env.id}
                environments={[]}
                visible={showConnectClusterModal}
                onCancel={() => setShowConnectClusterModal(false)}
                onOk={() => {
                    onOk?.()
                    setShowConnectClusterModal(false);
                }}
            />
        }
    </Modal >

}

export default ChooseComponentModal;