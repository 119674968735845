import moment from "moment";
import { Button, List, Space, Avatar, Empty } from "antd";
import { useEffect, useState } from "react";
import { App, Component } from "../../types";
import { getKubeService } from "../../backend";
import { useParams } from "react-router";
import { GetMicroservicePodEventsResponse } from "@microtica/ms-kube-sdk";
import { CheckOutlined, ClockCircleOutlined, KubernetesOutlined, WarningOutlined } from "@ant-design/icons";
import AlarmSeverityTag from "../../components/alarms/AlarmSeverityTag";

interface AppIncidentsProps {
    component: Component;
    app: App;
}

interface IncidentsState {
    id: string;
    timestamp: number;
    severity: "Critical" | "High" | "Medium" | "Low";
    code?: string;
    description: string;
    type: "event" | "alarm" | string;
}

const AppIncidents = ({ component, app }: AppIncidentsProps) => {
    const { projectId } = useParams() as { projectId: string; }
    const [loading, setLoading] = useState(true);
    const [incidents, setIncidents] = useState<IncidentsState[]>([]);


    useEffect(() => {
        loadData();

        const interval = setInterval(() => {
            loadData();
        }, 30000);

        return () => { clearInterval(interval); }
    }, []);

    const loadData = async () => {
        const { data: { pods } } = await getKubeService().getMicroserviceDeploymentStatus(
            app.name,
            component.clusterId!,
            app.namespace,
            projectId
        );
        const podsNames = pods.map(p => p.metadata.name);
        const podsEvents = await Promise.all(podsNames.map(podName =>
            getKubeService().getMicroservicePodEvents(
                app.name,
                app.namespace,
                component.clusterId!,
                podName,
                projectId
            )
        )) as { data: GetMicroservicePodEventsResponse }[];

        setIncidents(
            podsEvents
                .reduce((allEvents, res) => {
                    const events = res.data.events;
                    allEvents.push(...events.map(e => ({
                        id: "",
                        timestamp: e.firstSeen!,
                        severity: e.type! as "Critical" | "High" | "Medium" | "Low",
                        code: "",
                        description: `${e.message!} ${(e.count || 0) > 1 ? `(x${e.count} ${moment(e.lastSeen).from(e.firstSeen)})` : ""}`,
                        type: "event"
                    })));

                    return allEvents;
                }, [] as IncidentsState[])
                .sort((a, b) => b.timestamp - a.timestamp)
        );
        setLoading(false);
    }

    const handleMarkAsResolved = (id: string) => {

    }

    return (
        <Space direction="vertical" size="large" className="flex-justify-space-between">
            <div className="flex-justify-space-between flex-align-center">
                <div style={{ fontWeight: 600, fontSize: 16 }}>
                    Application Events
                    <div className="gray-text" style={{ fontWeight: 400, fontSize: 14 }}>
                        Recent app events and active alarms, gaining real-time insights into your application's health and performance, with details on critical occurrences and potential issues.
                    </div>
                </div>
            </div>
            <List
                loading={loading}
                bordered={true}
                style={{ overflow: "auto", height: "calc(100vh - 404px)" }}
                locale={{
                    emptyText:
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <>
                                    <div>
                                        Currently, there are no events or active alarms for your application.
                                    </div>
                                    <div>
                                        While it's normal not to see any events, it's also a positive indication of your application's stability and health.
                                    </div>
                                </>
                            }
                        />
                }}
                dataSource={incidents}
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            <Button
                                icon={<CheckOutlined />}
                                style={{ visibility: item.type === "alarm" ? "visible" : "hidden" }}
                                onClick={() => handleMarkAsResolved(item.id)}
                            />
                        ]}
                    >
                        <List.Item.Meta
                            title={
                                <Space className="gray-text">
                                    <ClockCircleOutlined />
                                    {
                                        `${moment(item.timestamp).fromNow()} • ${moment(item.timestamp).format("HH:mm • DD MMM YY")}`
                                    }
                                    <AlarmSeverityTag severity={item.severity} />
                                </Space>
                            }
                            description={
                                <Space style={{ color: "black" }}>
                                    {
                                        item.code ? <b>{item.code}</b> : undefined
                                    }
                                    {item.description}
                                </Space>
                            }
                            avatar={
                                item.type === "alarm" ?
                                    <Avatar icon={<WarningOutlined />} style={{ backgroundColor: "#808080" }} size={"large"} /> :
                                    <KubernetesOutlined style={{ fontSize: "37px", color: "#326CE5" }} />
                            }
                        />
                    </List.Item>
                )}
            />
        </Space>
    )
}

export default AppIncidents;