import { Button, Card, Col, Form, InputNumber, Row, Select, Skeleton, Space, Typography, notification } from "antd";
import PageContentWrapper from "../../components/PageContentWrapper";
import { ArrowLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { ChartMetric, ChartMetricItem, Dictionary } from "../../types";
import { PageHeader } from "@ant-design/pro-layout";
import { Link } from "react-router-dom";
import ChartWidget from "../../components/dashboards/widgets/ChartWidget";
import capitalizeFirstLetter from "../../utils/capitalize-first-letter";
import { stringToColor } from "../../utils/string-to-color";
import { CreatePixieAlarmConfigRequestMetricGroupEnum, EnableMonitoringRequestClusterTypeEnum, GetAlarmConfigByIdResponseOneOf, GetAlarmConfigByIdResponseOneOf1, GetAlarmConfigByIdResponseOneOfConfigValue, GetAlarmConfigByIdResponseOneOfConfigValueSeverityEnum, ListClustersResponseInner, UpdateAwsExtendedStatisticAlarmRequestAwsAlarmConfig, UpdateAwsExtendedStatisticAlarmRequestAwsAlarmConfigUnitEnum, UpdateAwsStatisticAlarmRequest, UpdatePixieAlarmConfigRequestMetricGroupEnum } from "@microtica/ms-monitoring-sdk";
import { getBaseUrl, getEnvironmentService, getKubeService, getMonitoringService } from "../../backend";
import { GetServiceMetrics, mapMetricsToWidget } from "../../utils/metrics";
import { useCurrentProject } from "../../contexts/Project";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { useDoubleConfigurationModal } from "../../contexts/Modal";
import { getAllEcsClustersInProject } from "./getAllProjectClusters";
import MessageCard from "../../components/cards/MessageCard";
import { ChartAnnotation } from "../../components/dashboards/widgets/types";
import { mapGraphNameToPixieMetricGroup, mapPixieMetricGroupToGraphName } from "./utils";

interface CreateAlarmLocationState {
    default: {
        namespaceAndApplication: string;
        clusterId: string;
    };
}

const ProjectCreateAlarm = () => {
    const navigate = useNavigate();
    const { projectId, metricGroup, clusterType } = useParams() as { projectId: string; metricGroup: string; clusterType: EnableMonitoringRequestClusterTypeEnum }
    const { state } = useLocation() as { state: CreateAlarmLocationState };
    const currentProject = useRecoilValue(currentProjectState);
    const { updateCurrentProject } = useCurrentProject();
    const { open: openConfirmModal } = useDoubleConfigurationModal();
    const [form] = Form.useForm();
    const [selectedClusterId, setSelectedClusterId] = useState<string>();
    const [application, setApplication] = useState<string>();
    const [thresholds, setThresholds] = useState<{ [key: number]: string[] }>({});
    const [selectedChartDisplay, setSelectedChartDisplay] = useState<string>("All");
    const [metrics, setMetrics] = useState<Dictionary<ChartMetric>>({});
    const [defaultChosenMetric, setDefaultChosenMetric] = useState<string>();
    const [missingMetricsData, setMissingMetricsData] = useState(false);
    const [microservicesGroupedByCluster, setMicroservicesGroupedByCluster] = useState<{ [key: string]: { microservices: string[]; envId: string; type: "k8s" | "ecs" } }>({});
    const [hasFormFieldError, setHasFormFieldError] = useState(false);
    const [metricUnit, setMetricUnit] = useState<{ name: string; value: number }>();
    const [resetAnnotation, setResetAnnotation] = useState(false);
    const [filterByThreshold, setFilterByThreshold] = useState<(r: ChartMetricItem) => (r: ChartMetricItem) => boolean>(r => r => true);
    const [updateAlarmId, setUpdateAlarmId] = useState<string>();
    const [loadingPrimaryButton, setLoadingPrimaryButton] = useState(false);
    const [loadingDangerButton, setLoadingDangerButton] = useState(false);
    const { Text } = Typography;

    // Pixie alarm: map metricGroup
    // Aws alarm: metricGroup
    const [alarmChartName, setAlarmChartName] = useState<string>("");

    useEffect(() => {
        setAlarmChartName(clusterType === "k8s" ?
            mapPixieMetricGroupToGraphName[metricGroup!] || metricGroup :
            metricGroup
        );
    }, [clusterType, metricGroup]);

    const groupMicroservicesByCluster = async () => {
        let deployedMicroservices = [];
        // retrieve all deployed microservices
        if (clusterType === EnableMonitoringRequestClusterTypeEnum.K8s) {
            const [{ data: { microservices } }, { data: status }] = await Promise.all([
                getKubeService().getDeployedMicroservicesInProject(projectId),
                getMonitoringService().listClusters(projectId).catch(error => ({ data: [] as ListClustersResponseInner[] }))
            ]);
            deployedMicroservices = microservices.filter(m => ["CS_HEALTHY", "CS_UPDATING", "CS_CONNECTED"].includes(status.find(s => s.clusterId === m.kubernetesId)?.status || ""))
                .map(microservice => ({ ...microservice, type: EnableMonitoringRequestClusterTypeEnum.K8s }));
        } else {
            const allEcsClusters = await getAllEcsClustersInProject(projectId);
            const ecsClustersWithMonitoring = allEcsClusters.filter(c => ["CS_HEALTHY", "CS_UPDATING", "CS_CONNECTED"].includes(c.status || ""));
            const ecsClusters = await Promise.all(ecsClustersWithMonitoring.map(async cluster => {
                const { data: monitoringConfigs } = await getMonitoringService().getECSClusterMonitoringConfigs(cluster.id, cluster.envId, projectId);
                return {
                    ...cluster,
                    hasMetric: monitoringConfigs.findIndex(c => c.Name === alarmChartName) !== -1
                };
            }));
            deployedMicroservices = ecsClusters
                .filter(cluster => cluster.hasMetric)
                .map(ecsCluster => {
                    return {
                        name: ecsCluster.id,
                        kubernetesId: ecsCluster.id,
                        type: EnableMonitoringRequestClusterTypeEnum.Ecs,
                        envId: ecsCluster.envId,
                        namespace: undefined
                    }
                })
        }

        const microservicesGroupedByCluster = deployedMicroservices.reduce((acc, microservice) => {
            const microserviceName = microservice.namespace ? `${microservice.namespace}/${microservice.name}` : microservice.name;
            if (acc[microservice.kubernetesId]) {
                acc[microservice.kubernetesId].microservices.push(microserviceName);
            } else {
                acc[microservice.kubernetesId] = {
                    type: microservice.type,
                    envId: microservice.type === EnableMonitoringRequestClusterTypeEnum.Ecs ?
                        microservice.envId :
                        microservice.kubernetesId.split("-").slice(0, 2).join("-"),
                    microservices: [microserviceName]
                }
            }
            return acc;
        }, {} as { [key: string]: { microservices: string[]; envId: string, type: EnableMonitoringRequestClusterTypeEnum } });

        Object.keys(microservicesGroupedByCluster).forEach(clusterId => {
            // delete clusters that don't have deployed microservices
            if (microservicesGroupedByCluster[clusterId].microservices.length === 0) {
                delete microservicesGroupedByCluster[clusterId];
            }
        })
        if (Object.keys(microservicesGroupedByCluster).length === 0) {
            setMissingMetricsData(true);
        } else {
            setMissingMetricsData(false);
        }
        return microservicesGroupedByCluster;
    }

    useEffect(() => {
        const loadClusters = async () => {
            try {
                const microservicesGroupedByCluster = await groupMicroservicesByCluster();
                setMicroservicesGroupedByCluster(microservicesGroupedByCluster);
                if (state?.default.clusterId) {
                    setSelectedClusterId(state.default.clusterId);
                    setApplication(state.default.namespaceAndApplication);
                }
                else {
                    // user has probably accessed this URL directly
                    const clusterId = Object.keys(microservicesGroupedByCluster)[0];
                    setSelectedClusterId(clusterId);
                    setApplication(microservicesGroupedByCluster[clusterId]?.microservices[0]);
                }
            } catch (error) {
                console.log("error: ", error);
            }
        }
        if (clusterType === "k8s" || (clusterType === "ecs" && alarmChartName)) {
            loadClusters();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alarmChartName]);

    useEffect(() => {
        // change the current project if the projectId in the URL is different from the current project saved in local storage
        if (!!projectId && projectId !== currentProject.id) {
            updateCurrentProject(projectId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    useEffect(() => {
        const loadMetrics = async () => {
            const selectedEnvId = microservicesGroupedByCluster[selectedClusterId!].envId;
            const appsQuery = clusterType === EnableMonitoringRequestClusterTypeEnum.Ecs ?
                undefined :
                microservicesGroupedByCluster[selectedClusterId!].microservices.join("|")

            try {
                const [{ data: metrics }, { data: { alarmsConfigs } }] = await Promise.all([
                    getMonitoringService().getServiceMetrics(
                        selectedClusterId!,
                        selectedEnvId,
                        "-30m",
                        projectId,
                        appsQuery,
                        undefined,
                        "1m"
                    ),
                    getMonitoringService().getAlarmsConfigsByClusterId(selectedClusterId!, projectId)
                ]);

                const metricsData = await mapMetricsToWidget(metrics as GetServiceMetrics, clusterType, { envId: selectedEnvId, projectId });
                const formattedAlarms = alarmsConfigs
                    .reduce((acc, alarm) => {
                        if (alarm.alarmType === "AWS") {
                            const metricName = (alarm.config as unknown as { MetricName: string }).MetricName;
                            if (acc[metricName]?.length) {
                                acc[metricName].push(alarm);
                            } else {
                                acc[metricName] = [alarm];
                            }
                        } else {
                            if (acc[mapPixieMetricGroupToGraphName[alarm.metricGroup]]?.length) {
                                acc[mapPixieMetricGroupToGraphName[alarm.metricGroup]].push(alarm);
                            } else {
                                acc[mapPixieMetricGroupToGraphName[alarm.metricGroup]] = [alarm];
                            }
                        }
                        return acc;
                    }, {} as { [key: string]: (GetAlarmConfigByIdResponseOneOf1 | GetAlarmConfigByIdResponseOneOf)[] });
                // append alarm data
                if (clusterType === EnableMonitoringRequestClusterTypeEnum.K8s) {
                    // Pixie alarms
                    Object.keys(metricsData).forEach(metricName => {
                        if (formattedAlarms[metricName]) {
                            metricsData[metricName].alarms = (formattedAlarms[metricName] as GetAlarmConfigByIdResponseOneOf[]);
                        }
                    })
                } else {
                    // AWS alarms
                    Object.keys(formattedAlarms)
                        .forEach((metricName) => {
                            let chartName = (formattedAlarms[metricName][0] as GetAlarmConfigByIdResponseOneOf1).awsAlarmName.replace(`${formattedAlarms[metricName][0].clusterId}-`, "");
                            metricsData[chartName].alarms = (formattedAlarms[metricName] as GetAlarmConfigByIdResponseOneOf[]);
                        })
                }
                if (!metricUnit) {
                    setMetricUnit({
                        name: metricsData[alarmChartName].unit,
                        value: 1
                    })
                }
                setMetrics(metricsData);

                const defaultMetric = Object.keys(metricsData)[0];
                setDefaultChosenMetric(clusterType === "ecs" ? defaultMetric.replaceAll(" ", "_") : mapGraphNameToPixieMetricGroup[defaultMetric]);
                if (!metricsData[alarmChartName]) {
                    setMissingMetricsData(true);
                } else {
                    setMissingMetricsData(false);
                }
            } catch (error: any) {
                notification.error({
                    message: "Cannot fetch metrics",
                    description: error.response?.data?.statusMessage
                });
            }
        }
        if (selectedClusterId && microservicesGroupedByCluster[selectedClusterId] && alarmChartName) {
            loadMetrics();
        }
    }, [selectedClusterId, microservicesGroupedByCluster, alarmChartName]);

    useEffect(() => {
        const fetchAlarms = (newMetrics: Dictionary<ChartMetric>) => {
            if (newMetrics[alarmChartName]?.alarms) {
                const foundAlarm = newMetrics[alarmChartName].alarms?.find(alarm => (alarm as GetAlarmConfigByIdResponseOneOf).namespace ?
                    application === `${(alarm as GetAlarmConfigByIdResponseOneOf).namespace}/${alarm.applicationName}` :
                    application === alarm.applicationName);
                if (foundAlarm) {
                    setUpdateAlarmId(foundAlarm.id);
                    if (metrics[alarmChartName].type === "ecs") {
                        let awsAlarmFieldName = metrics[alarmChartName].metrics[0];
                        let currentValue = (metrics[alarmChartName].alarms?.[0].config.Threshold || 0) as number;
                        let severity = metrics[alarmChartName].alarms?.[0].config.Severity;
                        form.setFieldsValue({
                            severity,
                            [awsAlarmFieldName]: currentValue
                        });
                        setThresholds({
                            [currentValue]: [awsAlarmFieldName]
                        });
                    } else {
                        const metricFields = Object.keys(foundAlarm.config);

                        const formFieldValues = metricFields.reduce((obj, metricField) => {
                            const threshold = (foundAlarm.config[metricField] as GetAlarmConfigByIdResponseOneOfConfigValue).threshold;
                            obj["formFields"] = {
                                ...obj["formFields"],
                                [metricField]: threshold
                            };
                            obj["thresholds"] = {
                                ...obj["thresholds"],
                                [threshold]: [...(obj["thresholds"]?.[threshold] || []), metricField]
                            };
                            return obj;
                        }, {} as { "formFields": { [key: string]: number | string }, "thresholds": { [key: number]: string[] } });
                        formFieldValues["formFields"]["severity"] = (foundAlarm.config[metricFields[0]] as GetAlarmConfigByIdResponseOneOfConfigValue).severity;
                        form.setFieldsValue(formFieldValues["formFields"]);
                        setThresholds(formFieldValues["thresholds"]);
                    }
                } else {
                    setUpdateAlarmId(undefined);
                    newMetrics[alarmChartName].metrics.forEach(metric => form.resetFields([metric]));
                    setThresholds({});
                }
            }
        };

        if (metrics && Object.keys(metrics).length > 0 && application && alarmChartName) {
            fetchAlarms(metrics);
        }
    }, [metrics, application, alarmChartName]);

    const chartAnnotations = useMemo(() => {
        const getThresholds = () => {
            if (selectedChartDisplay === 'All') {
                setFilterByThreshold((r: ChartMetricItem) => (r: ChartMetricItem) => true);
                return thresholds;
            }

            const metricValue = Object.keys(thresholds).find(metricValue => thresholds[parseFloat(metricValue)].includes(selectedChartDisplay))
            setFilterByThreshold((r: ChartMetricItem) => (r: ChartMetricItem) => r.metric === selectedChartDisplay);
            if (metricValue) {
                return {
                    [metricValue]: [selectedChartDisplay]
                }
            }
            return {};
        }

        return Object.entries(getThresholds()).map(([metricValue, metrics]) => ({
            type: "line",
            start: ["start", parseFloat(metricValue)],
            end: ["end", parseFloat(metricValue)],
            text: {
                content: clusterType === EnableMonitoringRequestClusterTypeEnum.K8s ?
                    `${metrics.map(metric => capitalizeFirstLetter(metric)).join(" & ").replaceAll("_", " ")} threshold` :
                    `${capitalizeFirstLetter(metrics.join(" & ").replaceAll("_", " ").replace(/([A-Z])/g, ' $1').trim())} threshold`,
                style: {
                    textAlign: "left",
                    fill: stringToColor(metrics[0]),
                },
            },
            style: {
                lineDash: [4, 4],
                stroke: stringToColor(metrics[0]),
            }
        })) as ChartAnnotation[];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChartDisplay, thresholds, resetAnnotation]);

    const updateThresholds = (unit: number) => {
        const newThresholds: { [key: number]: string[] } = {};

        metrics[alarmChartName].metrics.forEach(metric => {
            const metricValue = form.getFieldValue(metric);
            if (!!metricValue) {
                newThresholds[metricValue * unit] = [...(newThresholds[metricValue * unit] || []), metric];
            }
        })

        setThresholds(newThresholds);
    }

    const selectMetricUnit = (unit: string) => {
        if (unit === "Bytes") {
            return (
                <Select
                    value={metricUnit?.value}
                    style={{ width: 120 }}
                    onChange={(newMetricUnit, option) => {
                        setMetricUnit({
                            name: (option as any).children,
                            value: newMetricUnit
                        });
                        updateThresholds(newMetricUnit);
                    }}
                >
                    <Select.Option value={1}>Bytes</Select.Option>
                    <Select.Option value={Math.pow(2, 10)}>Kilobytes</Select.Option>
                    <Select.Option value={Math.pow(2, 20)}>Megabytes</Select.Option>
                    <Select.Option value={Math.pow(2, 30)}>Gigabytes</Select.Option>
                </Select>
            );
        }
        if (unit === "Nanoseconds") {
            return (
                <Select
                    value={metricUnit?.value}
                    style={{ width: 135 }}
                    onChange={(newMetricUnit, option) => {
                        setMetricUnit({
                            name: (option as any).children,
                            value: newMetricUnit
                        });
                        updateThresholds(newMetricUnit);
                    }}
                >
                    <Select.Option value={1}>Nanoseconds</Select.Option>
                    <Select.Option value={1000}>Microseconds</Select.Option>
                    <Select.Option value={1000000}>Milliseconds</Select.Option>
                    <Select.Option value={1000000000}>Seconds</Select.Option>
                </Select>
            );
        }
        if (unit === "Seconds") {
            // we must send the threshold value in Seconds
            // if the user chooses other unit we must transform the threshold value to Seconds using the Select.Option value prop 
            return (
                <Select
                    value={metricUnit?.value}
                    style={{ width: 135 }}
                    onChange={(newMetricUnit, option) => {
                        setMetricUnit({
                            name: (option as any).children,
                            value: newMetricUnit
                        });
                        updateThresholds(newMetricUnit);
                    }}
                >
                    <Select.Option value={1 / 1000000000}>Nanoseconds</Select.Option>
                    <Select.Option value={1 / 1000000}>Microseconds</Select.Option>
                    <Select.Option value={1 / 1000}>Milliseconds</Select.Option>
                    <Select.Option value={1}>Seconds</Select.Option>
                </Select>
            );
        }
        return unit;
    };

    const handleCreateOrUpdatePixieAlarm = async (values: { [key: string]: string | number }, metricFields: string[]) => {
        const [namespace, applicationName] = (values["application"] as string).split("/");
        try {
            const pixieAlarmConfig = metricFields.reduce((obj, key) => {
                if (values[key] !== undefined && values[key] !== null && values[key] !== 0) {
                    obj[key] = {
                        threshold: (values[key] as number) * (metricUnit?.value || 1),
                        severity: values["severity"] as GetAlarmConfigByIdResponseOneOfConfigValueSeverityEnum
                    }
                }
                return obj;
            }, {} as { [key: string]: { threshold: number; severity: GetAlarmConfigByIdResponseOneOfConfigValueSeverityEnum } });
            const environmentId = (values["clusterId"] as string).split("-").slice(0, 2).join("-");
            const { data: { awsAccountId, region } } = await getEnvironmentService().getStage(environmentId, projectId);
            setLoadingPrimaryButton(true);
            if (updateAlarmId) {
                // we must send all objects as strings since the content type is multipart/form-data
                await getMonitoringService().updatePixieAlarmConfig(updateAlarmId, (values["clusterId"] as string), projectId,
                    {
                        environmentId,
                        cloudAccount: {
                            id: awsAccountId!,
                            region: region!
                        },
                        namespace,
                        applicationName,
                        metricGroup: metricGroup as UpdatePixieAlarmConfigRequestMetricGroupEnum,
                        pixieAlarmConfig,
                    },
                    { headers: { "Content-Type": "multipart/form-data" } }
                );
                notification.success({ message: "Alarm updated" });
            } else {
                // we must send all objects as strings since the content type is multipart/form-data
                await getMonitoringService().createPixieAlarmConfig((values["clusterId"] as string), projectId,
                    {
                        environmentId,
                        cloudAccount: JSON.stringify({
                            id: awsAccountId!,
                            region: region!
                        }) as any,
                        namespace,
                        applicationName,
                        metricGroup: metricGroup as CreatePixieAlarmConfigRequestMetricGroupEnum,
                        pixieAlarmConfig: JSON.stringify(pixieAlarmConfig) as any,
                    },
                    { headers: { "Content-Type": "multipart/form-data" } }
                );
                notification.success({ message: "Alarm created" });
            }
            setTimeout(() => {
                navigate(`/projects/${projectId}/monitoring`);
            }, 1000);
        } catch (error: any) {
            setLoadingPrimaryButton(false);
            notification.error({
                message: updateAlarmId ? "Cannot update alarm" : "Cannot create alarm",
                description: error?.response?.data?.message
            });
        }
    }

    const handleCreateOrUpdateAwsAlarm = async (values: { [key: string]: string | number }) => {
        const environmentId = microservicesGroupedByCluster[values["clusterId"]].envId;
        const { data: { awsAccountId, region } } = await getEnvironmentService().getStage(environmentId, projectId);
        const cloudAccount = {
            id: awsAccountId!,
            region: region!
        };
        if (updateAlarmId) {
            const { applicationName, config } = metrics[alarmChartName].alarms![0];
            const awsAlarmName = (metrics[alarmChartName].alarms![0] as GetAlarmConfigByIdResponseOneOf1).awsAlarmName ? (metrics[alarmChartName].alarms![0] as GetAlarmConfigByIdResponseOneOf1).awsAlarmName : undefined;
            const parsedDescription = JSON.parse(config.AlarmDescription as unknown as string);
            parsedDescription.severity = (values["severity"] as string).toUpperCase();
            await getMonitoringService().updateAwsAlarmConfig(
                updateAlarmId,
                (values["clusterId"] as string),
                projectId,
                {
                    environmentId,
                    cloudAccount,
                    applicationName,
                    awsAlarmName,
                    awsAlarmConfig: {
                        ...config,
                        AlarmDescription: JSON.stringify(parsedDescription),
                        Threshold: (values[metrics[alarmChartName].metrics[0]] as number) * (metricUnit?.value || 1),
                        Severity: values["severity"],
                    }
                } as UpdateAwsStatisticAlarmRequest
            );
            notification.success({ message: "Alarm updated" });
        } else {
            const micAwsAccountId = getBaseUrl() === "https://api.ms-dev.microtica.io" ? "678898389733" : "652222714481";
            const { data: monitoringConfigs } = await getMonitoringService().getECSClusterMonitoringConfigs((values["clusterId"] as string), environmentId, projectId);
            const monitoringConfig = monitoringConfigs.find(c => c.Name === alarmChartName)!;
            const awsAlarmConfig = {
                ActionsEnabled: true,
                AlarmActions: [
                    `arn:aws:sns:${region}:${micAwsAccountId}:MicroticaCFNTopic`
                ],
                AlarmDescription: JSON.stringify({
                    "projectId": projectId,
                    "envId": environmentId,
                    "resourceId": (values["clusterId"] as string).replace(`${environmentId}-`, ""),
                    "severity": (values["severity"] as string).toUpperCase()
                }),
                ComparisonOperator: "GreaterThanOrEqualToThreshold",
                DatapointsToAlarm: 1,
                Dimensions: monitoringConfig.Dimensions,
                EvaluationPeriods: 1,
                MetricName: monitoringConfig.MetricName,
                Namespace: monitoringConfig.Namespace,
                OKActions: [
                    `arn:aws:sns:${region}:${micAwsAccountId}:MicroticaCFNTopic`
                ],
                Period: 60, // TODO: 60*5 to be in sync with pixie alarms period??
                ...(monitoringConfig.Statistics ? { Statistic: monitoringConfig.Statistics[0] } : {}),
                ...(monitoringConfig.ExtendedStatistics ? { ExtendedStatistic: monitoringConfig.ExtendedStatistics[0] } : {}),
                Unit: metrics[alarmChartName].unit as UpdateAwsExtendedStatisticAlarmRequestAwsAlarmConfigUnitEnum,
                TreatMissingData: "notBreaching",
                Threshold: (values[metrics[alarmChartName].metrics[0]] as number) * (metricUnit?.value || 1),
                Severity: values["severity"]
            } as UpdateAwsExtendedStatisticAlarmRequestAwsAlarmConfig;
            await getMonitoringService().createAwsAlarmConfig(
                (values["clusterId"] as string),
                projectId,
                {
                    environmentId,
                    cloudAccount,
                    applicationName: values["clusterId"] as string,
                    awsAlarmConfig,
                    awsAlarmName: `${values["clusterId"]}-${alarmChartName}`
                }
            );
            notification.success({ message: "Alarm created" });
        }
        setTimeout(() => {
            navigate(`/projects/${projectId}/monitoring`);
        }, 1000);
    }

    const handleCreateOrUpdateAlarm = async (values: { [key: string]: string | number }) => {
        const metricFields = Object.keys(values)
            .filter(fieldName => !["clusterId", "application", "severity"].includes(fieldName));

        const invalidThresholdValueIndex = metricFields.findIndex(fieldName => values[fieldName] && values[fieldName] !== 0);
        if (invalidThresholdValueIndex === -1) {
            setHasFormFieldError(true);
        } else {
            setHasFormFieldError(false);
            if (clusterType === "k8s") {
                await handleCreateOrUpdatePixieAlarm(values, metricFields);
            } else {
                await handleCreateOrUpdateAwsAlarm(values);
            }
        }
    }

    const handleDeleteAlarm = async (alarmId: string) => {
        setLoadingDangerButton(true);
        try {
            await getMonitoringService().deleteAlarmConfigById(alarmId, projectId);
            notification.success({ message: "Alarm successfully deleted!" });
            setTimeout(() => {
                navigate(`/projects/${projectId}/monitoring`);
            }, 1000);
        } catch (error: any) {
            notification.error({
                message: "Cannot delete alarm",
                description: error?.response?.data?.message
            });
        } finally {
            setLoadingDangerButton(false);
        }
    };

    const showDeleteConfirm = (alarmId: string) => {
        openConfirmModal({
            title: "Delete Alarm?",
            description: <>
                The <b>{alarmChartName} alarm</b> for your <b>{application}</b> application ({selectedClusterId?.split("-").slice(2).join("-")} cluster) will be deleted and you will be no longer notified for potential issues regarding your application.
            </>,
            confirmation: `${alarmChartName} alarm`,
            okText: "Delete Alarm",
            cancelText: 'Cancel',
            onOk: () => {
                return handleDeleteAlarm(alarmId);
            }
        });
    }

    return (
        <PageContentWrapper
            size="large"
            header={
                <PageHeader>
                    {
                        <Space size="large">
                            <Link to={`/projects/${projectId}/monitoring`}>
                                <Button icon={<ArrowLeftOutlined />} />
                            </Link>
                            <div>
                                <div style={{ fontSize: "16px" }}>
                                    {updateAlarmId ? "Update alarm" : "Create alarm"}
                                </div>
                                <div style={{ fontSize: "20px", fontWeight: 600 }}>
                                    {alarmChartName}
                                </div>
                            </div>
                        </Space>
                    }
                </PageHeader>
            }
        >
            {
                missingMetricsData ?
                    <Card bordered>
                        <Row>
                            <Col span={12} className="flex-column-justify-space-between">
                                <MessageCard
                                    type="error"
                                    text={
                                        <Text>
                                            There are no <Text strong>{alarmChartName}</Text> metrics for your {clusterType === "ecs" ? "Fargate" : "EKS"} cluster(s)
                                        </Text>
                                    }
                                />
                                {
                                    Object.keys(metrics).length > 0 ?
                                        <>
                                            <Space direction="vertical">
                                                <div>I want to configure alarm for the following metric</div>
                                                <Select defaultValue={defaultChosenMetric} onChange={value => setDefaultChosenMetric(value)}>
                                                    {
                                                        Object.keys(metrics).map(metric => (
                                                            <Select.Option value={clusterType === "ecs" ? metric.replaceAll(" ", "_") : mapGraphNameToPixieMetricGroup[metric]} key={metric}>
                                                                {metric}
                                                            </Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Space>
                                            <Button
                                                type="primary"
                                                style={{ width: "fit-content" }}
                                                onClick={() => {
                                                    navigate(`/projects/${projectId}/monitoring/${clusterType}/alarm/${defaultChosenMetric}`)
                                                }}
                                            >
                                                Proceed
                                            </Button>
                                        </>
                                        :
                                        <>
                                            <Space direction="vertical">
                                                {clusterType === "ecs" ?
                                                    <>
                                                        <div>Add <Text strong>{alarmChartName}</Text> metric in the Outputs section of your <Text code>.microtica/index.json</Text> file and then click on the  <Text strong>Sync metrics</Text> button in your Resource Settings page.</div>
                                                        <div>Right after you have completed these two steps, you can create alarm for this metric!</div>
                                                    </> :
                                                    <>
                                                        <div>It looks like you do not have configured metrics for your EKS cluster(s).</div>
                                                        <div>In the Monitoring page you can find instructions on how to enable monitoring for your cluster.</div>
                                                    </>
                                                }
                                            </Space>
                                            <Button
                                                type="primary"
                                                style={{ width: "fit-content" }}
                                                onClick={() => {
                                                    navigate(`/projects/${projectId}/monitoring`)
                                                }}
                                                icon={<ArrowLeftOutlined />}
                                            >
                                                Go back to Monitoring
                                            </Button>
                                        </>
                                }
                            </Col>
                            <Col span={11} offset={1} style={{ height: "fit-content" }}>
                                <ChartWidget
                                    title={alarmChartName}
                                    metric={{
                                        data: [],
                                        metrics: [],
                                        type: clusterType,
                                        unit: "Count"
                                    }}
                                />
                            </Col>
                        </Row>
                    </Card> :
                    selectedClusterId && microservicesGroupedByCluster[selectedClusterId] && Object.keys(metrics).length > 0 && metrics[alarmChartName] ?
                        <Card bordered>
                            <Row>
                                <Col span={12}>
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        requiredMark={false}
                                        onFinish={handleCreateOrUpdateAlarm}
                                        initialValues={{
                                            clusterId: state?.default.clusterId || selectedClusterId,
                                            application: state?.default.namespaceAndApplication || microservicesGroupedByCluster[selectedClusterId].microservices[0],
                                            severity: "Medium",
                                        }}
                                    >
                                        <div className="input-field-section">
                                            <Form.Item
                                                name="clusterId"
                                                label="Cluster"
                                                required
                                                className="input-form-item"
                                            >
                                                <Select
                                                    onChange={(clusterId) => {
                                                        setSelectedClusterId(clusterId);
                                                        setApplication(microservicesGroupedByCluster[clusterId].microservices[0]);
                                                        form.setFieldValue("application", microservicesGroupedByCluster[clusterId].microservices[0])
                                                    }}
                                                >
                                                    {
                                                        Object.keys(microservicesGroupedByCluster).map(clusterId => (
                                                            <Select.Option value={clusterId} key={clusterId}>
                                                                {microservicesGroupedByCluster[clusterId].type === "k8s" ?
                                                                    clusterId.split("-").slice(2).join("-") :
                                                                    clusterId
                                                                }
                                                            </Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="input-field-section">
                                            <Form.Item
                                                name="application"
                                                label="Application"
                                                required
                                                className="input-form-item"
                                            >
                                                <Select
                                                    onChange={(app) => setApplication(app)}
                                                >
                                                    {
                                                        microservicesGroupedByCluster[selectedClusterId].microservices.map(microserviceName => (
                                                            <Select.Option value={microserviceName} key={microserviceName}>{microserviceName}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        {(metrics[alarmChartName]?.metrics || []).map(metric => (
                                            <div className="input-field-section" key={metric}>
                                                <Form.Item
                                                    name={metric}
                                                    label={
                                                        metrics[alarmChartName].type === "k8s" ?
                                                            `${capitalizeFirstLetter(metric.replaceAll("_", " "))} threshold` :
                                                            `${capitalizeFirstLetter(metric.replaceAll("_", " ").replace(/([A-Z])/g, ' $1').trim())} threshold`
                                                    }
                                                    className="input-form-item"
                                                    rules={[
                                                        {
                                                            pattern: new RegExp(/^([1-9]\d*)$/), // no decimal places
                                                            message: metrics[alarmChartName].type === "k8s" ?
                                                                `${capitalizeFirstLetter(metric.replaceAll("_", " "))} threshold is not a valid positive integer number` :
                                                                `${capitalizeFirstLetter(metric.replaceAll("_", " ").replace(/([A-Z])/g, ' $1').trim())} threshold is not a valid positive integer number`
                                                        }
                                                    ]}
                                                >
                                                    <InputNumber
                                                        addonAfter={selectMetricUnit(metrics[alarmChartName].unit)}
                                                        className="full-width"
                                                        status={hasFormFieldError ? "error" : undefined}
                                                        onChange={(newValue: number | null) => {
                                                            const newThreshold: number = (newValue || 0) * (metricUnit?.value || 1);
                                                            setThresholds(prevThresholds => {
                                                                const prevValue = Object.keys(prevThresholds).find(key => prevThresholds[key as unknown as number].includes(metric)) as number | undefined;

                                                                if (prevValue !== undefined) {
                                                                    const updatedMetrics = prevThresholds[prevValue].filter(m => m !== metric);
                                                                    if (updatedMetrics.length === 0) {
                                                                        delete prevThresholds[prevValue];
                                                                    } else {
                                                                        prevThresholds[prevValue] = updatedMetrics;
                                                                    }
                                                                }
                                                                if (newValue) {
                                                                    return {
                                                                        ...prevThresholds,
                                                                        // e.g. should be always in nanoseconds
                                                                        [newThreshold]: [...(prevThresholds[newThreshold] || []), metric]
                                                                    };
                                                                } else {
                                                                    setResetAnnotation(prevReset => !prevReset);
                                                                    return prevThresholds;
                                                                }
                                                            });
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>)
                                        )}
                                        {hasFormFieldError &&
                                            <div style={{ color: "#ff4d4f", margin: "-15px 0 20px 0" }}>
                                                Provide at least one threshold value greater than zero
                                            </div>
                                        }
                                        <div style={{ color: "var(--primary-color)", margin: "-15px 0 20px 0" }}>
                                            <InfoCircleOutlined /> Provide integer value in threshold fields. If you are looking for a greater precision try choosing a smaller metric unit (if available).
                                        </div>
                                        <div className="input-field-section">
                                            <Form.Item
                                                name="severity"
                                                label="Severity"
                                                required
                                                className="input-form-item"
                                            >
                                                <Select>
                                                    {
                                                        ["Critical", "High", "Medium"].map(severity => (
                                                            <Select.Option value={severity} key={severity}>{severity}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <Button htmlType="submit" type="primary" loading={loadingPrimaryButton}>
                                            {updateAlarmId ? "Update alarm" : "Create alarm"}
                                        </Button>
                                    </Form>
                                </Col>
                                <Col span={11} offset={1} style={{ height: "fit-content" }}>
                                    <Row className="flex-justify-space-between" style={{ paddingBottom: "7px" }}>
                                        <Col>
                                            View your threshold values in chart context
                                        </Col>
                                        <Col>
                                            <Select onChange={(selection) => setSelectedChartDisplay(selection)} defaultValue={selectedChartDisplay} size="small" style={{ width: 180, marginTop: "-1px" }}>
                                                <Select.Option value={"All"} key={"All"}>All</Select.Option>
                                                {
                                                    metrics[alarmChartName].metrics.map(metric => (
                                                        <Select.Option value={metric} key={metric}>
                                                            {metrics[alarmChartName].type === "k8s" ?
                                                                capitalizeFirstLetter(metric.replaceAll("_", " ")) :
                                                                capitalizeFirstLetter(metric.replaceAll("_", " ").replace(/([A-Z])/g, ' $1').trim())
                                                            }
                                                        </Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Col>
                                    </Row>
                                    <ChartWidget
                                        title={alarmChartName}
                                        metric={{
                                            ...metrics[alarmChartName],
                                            data: metrics[alarmChartName].data.filter(r => r.group === application && filterByThreshold(r)),
                                        }}
                                        annotations={chartAnnotations}
                                        chartMetricUnit={metricUnit}
                                    />
                                    {updateAlarmId &&
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end" }}>
                                            <Button
                                                danger
                                                type="primary"
                                                loading={loadingDangerButton}
                                                onClick={() => showDeleteConfirm(updateAlarmId)}
                                                style={{ marginTop: "24px" }}
                                            >
                                                Delete alarm
                                            </Button>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Card> :
                        <Card bordered>
                            <Skeleton paragraph={{ rows: 5 }} />
                        </Card>
            }
        </PageContentWrapper >
    )
}

export default ProjectCreateAlarm;