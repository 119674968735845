import { Card, Col, Divider, Dropdown, notification, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { getEnvironmentService } from "../../backend";
import ExplanationButton from "../../components/explanations/ExplanationButton";
import { ComponentConfig, ComponentSchemaInputProperties, Dictionary, IComponentDetails } from "../../types";
import ResourceVariables from "../../components/settings/ResourceVariables";
import ResourceSettingsVariablesExplanation from "../../components/explanations/ResourceSettingsVariablesExplanation";
import { LoadingOutlined } from "@ant-design/icons";

const ResourceSettingsVariables = () => {
    const navigate = useNavigate();
    const { projectId, envId } = useParams();
    const { component } = useOutletContext<{ component: IComponentDetails }>();
    const [loading, setLoading] = useState(false);
    const [variables, setVariables] = useState<Dictionary<ComponentConfig[]>>({});
    const [inputProps] = useState<ComponentSchemaInputProperties>(component.component.schema.properties.inputs.properties);
    const [disableSave, setDisableSave] = useState(true);

    useEffect(() => {
        const initialValues = Object.entries(inputProps).reduce((acc, [key, value]) => {
            // Get the default value from the schema
            const val = component.configurations.find(c => c.key === key)?.value || value.default?.toString();

            if (value.category === "variables") {
                const envVars = (val || "").split(",").reduce((acc, obj) => {
                    const [key, value] = obj.split("=");
                    if (key && value) {
                        acc.push({
                            key: key.trim(),
                            value: value.trim()
                        });
                    }
                    return acc;
                }, [] as ComponentConfig[]);

                acc[key] = envVars;
            }
            return acc;
        }, {} as Dictionary<ComponentConfig[]>);

        setVariables(initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = async () => {
        const configurations = Object.keys(variables).reduce((acc, key) => {
            const variable = variables[key];
            const config = acc.find(a => a.key === key);

            const normalizedValue = variable.map(v => `${v.key}=${v.value}`).join(",");

            if (config) {
                config.value = normalizedValue;
            } else {
                acc.push({
                    key,
                    value: normalizedValue
                });
            }
            return acc;
        }, component.configurations);

        setLoading(true);
        await getEnvironmentService().updateResource(
            envId!,
            component.name,
            projectId!,
            {
                componentVersion: component.version,
                configurations
            }
        );
        setLoading(false);
    }

    const deployResource = async () => {
        await getEnvironmentService().deployStage(
            envId!,
            projectId!,
            {
                partial: true,
                resourceVersionOverrides: {
                    [component.name]: component.version
                }
            }
        );
    }

    const updateVariables = (groupKey: string, updatedVars: ComponentConfig[]) => {
        setVariables({
            ...variables,
            [groupKey]: updatedVars
        });
        setDisableSave(false);
    }

    const handleSaveAndDeploy = async () => {
        try {
            setLoading(true);

            await handleSave();
            await deployResource();

            notification.success({
                message: "Variables update initiated",
                description: "It would take a few minutes to apply the changes.",
                icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
            });
            setTimeout(() => {
                navigate(`/projects/${projectId!}/pipelines`)
            }, 1000);
        } catch (error: any) {
            notification.error({
                message: "Variables update failed",
                description: error.response.data.message
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <Card bordered>
            <Card.Meta
                title={
                    <>
                        <span>
                            Variables
                        </span>
                        <ExplanationButton
                            content={<ResourceSettingsVariablesExplanation />}
                        />
                    </>
                }
                description="Customize your app's behavior by defining key-value pairs that can be accessed within your code."
            />
            <Divider />

            {
                Object.keys(variables).map(groupKey =>
                    <ResourceVariables
                        values={variables[groupKey]}
                        onChange={updatedVars => updateVariables(groupKey, updatedVars)}
                    />
                )
            }

            <br />

            <Row className="flex-justify-end">
                <Col>
                    <Dropdown.Button
                        trigger={["click"]}
                        htmlType="submit"
                        loading={loading}
                        disabled={disableSave}
                        onClick={handleSaveAndDeploy}
                        menu={{
                            items: [{
                                key: "save",
                                label: "Save",
                                onClick: () => handleSave()
                            }]
                        }}
                    >
                        Save and Deploy
                    </Dropdown.Button>
                </Col>
            </Row>
        </Card>
    );
}

export default ResourceSettingsVariables;