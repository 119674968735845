import { useEffect } from "react";
import { TEMPLATES } from "../enums/enums";

interface MetaTags {
    title: string;
    description: string;
    url: string;
    image: string;
}
const MetaTag = ({ defaultTags, templateName }: {
    defaultTags: boolean,
    templateName?: string
}) => {
    const defaultMetaTags = {
        title: "Microtica's Production-ready Templates",
        description: "Infrastructure & application templates to build solutions on Kubernetes or AWS in minutes.",
        url: window.location.href,
        image: "https://uploads-ssl.webflow.com/62ac69b4a12a55787f0f7668/6304a2a98a1e1236193895fe_open-graph-image-min.png",
    };

    useEffect(() => {
        if (defaultTags === false && !!templateName) {
            // extract the data from the template name and use custom meta tags
            const template = TEMPLATES.find(item => item.key.includes(templateName) || templateName.includes(item.key));
            // get default title if template.title is not string (e.g. ReactNode)
            const metaTitle = typeof template?.title === "string" ? `Microtica ${template!.title!} Template` : defaultMetaTags.title;
            changeMetaTags({
                title: metaTitle,
                description: template?.description?.toString()!,
                url: window.location.href,
                image: "https://uploads-ssl.webflow.com/62ac69b4a12a55787f0f7668/6304a2a98a1e1236193895fe_open-graph-image-min.png"
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateName])

    useEffect(() => {
        if (defaultTags === true) {
            // use default meta tags
            changeMetaTags(defaultMetaTags);
        }

        return () => {
            // reset to the default meta tags
            changeMetaTags(defaultMetaTags);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultTags])

    const changeMetaTags = (metaTags: MetaTags) => {
        document.title = metaTags.title;
        document.querySelector("meta[name='description']")!.setAttribute("content", metaTags.description);
        // Facebook meta tags
        document.querySelector("meta[property='og:url']")!.setAttribute("content", metaTags.url);
        document.querySelector("meta[property='og:type']")!.setAttribute("content", "website");
        document.querySelector("meta[property='og:title']")!.setAttribute("content", metaTags.title);
        document.querySelector("meta[property='og:description']")!.setAttribute("content", metaTags.description);
        document.querySelector("meta[property='og:image']")!.setAttribute("content", metaTags.image);
        // Twitter meta tags
        document.querySelector("meta[name='twitter:card']")!.setAttribute("content", "summary_large_image");
        document.querySelector("meta[property='twitter:url']")!.setAttribute("content", metaTags.url);
        document.querySelector("meta[name='twitter:title']")!.setAttribute("content", metaTags.title);
        document.querySelector("meta[name='twitter:description']")!.setAttribute("content", metaTags.description);
        document.querySelector("meta[name='twitter:image']")!.setAttribute("content", metaTags.image);
    }

    return (<></>);
}

export default MetaTag;