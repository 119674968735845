import { useEffect, useState } from "react";
import { CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined, DownOutlined, TrophyOutlined, SearchOutlined, LoadingOutlined, DashboardOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { getElasticSearchService, getPipelinesService } from "../backend";
import { Avatar, Button, Card, Col, Dropdown, notification, Row, Skeleton, Tag, Tooltip } from "antd";
import { Pipeline, PipelineBuildDetails } from "@microtica/ms-ap-sdk";
import GitCommitLink from "../components/GitCommitLink";
import moment from "moment";
import { PipelineMetricsResponseResponse } from "@microtica/ms-elasticsearch-sdk";
import DisplayMetric from "./DisplayMetric";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Search from "antd/lib/input/Search";
import Title from "antd/lib/typography/Title";
import InfiniteScroll from "react-infinite-scroll-component";
import ExplanationButton from "./explanations/ExplanationButton";
import PipelineBuildsExplanation from "./explanations/PipelineBuildsExplanation";
import { newBuildNotificationsState } from "../recoil/notification";
import { useRecoilValue, useSetRecoilState } from "recoil";
import MessageCard from "./cards/MessageCard";
import { fetchProjectLimitations } from "../utils/local-storage";
import { ProjectLimitations } from "@microtica/ms-project-sdk";
import { currentProjectState } from "../recoil/project";
import { calculateSubsequentBuildButtonClicks } from "../utils/subsequent-button-clicks";
import { buildPipelineButtonState } from "../recoil/payment-plan-required";
import { PIPELINE_BUILD_STATUS_CODES } from "../enums/enums";

interface PipelineBuildsProps {
    buildPath: string;
    pipelineId: string;
    projectId: string;
}
const PipelineBuilds = ({
    projectId,
    buildPath,
    pipelineId
}: PipelineBuildsProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [builds, setBuilds] = useState<PipelineBuildDetails[]>([]);
    const newBuildNotifications = useRecoilValue(newBuildNotificationsState);
    const [pipelineMetrics, setPipelineMetrics] = useState<PipelineMetricsResponseResponse>();
    const [loadingPipelineMetrics, setLoadingPipelineMetrics] = useState(true);
    const [loadingPipelineBuilds, setLoadingPipelineBuilds] = useState(true);
    const [filteredBuilds, setFilteredBuilds] = useState(builds);   // [] initially
    const [paginationConfig, setPaginationConfig] = useState<{ limit: number, offset: number, hasMore: boolean }>({ limit: 10, offset: 0, hasMore: true });
    const [repositoryBranches, setRepositoryBranches] = useState<string[]>([]);
    const [pipelineDetails, setPipelineDetails] = useState<Pipeline>();
    const [isPipelineTriggered, setIsPipelineTriggered] = useState<boolean>(false);
    const [deploymentTime, setDeploymentTime] = useState<number>();
    const [buildMinutesUsed, setBuildMinutesUsed] = useState<number>();
    const [projectLimitations, setProjectLimitations] = useState<ProjectLimitations>();
    const [buildMinutesUsedPercentage, setBuildMinutesUsedPercentage] = useState<number>();
    const [ctaMessage, setCtaMessage] = useState<{ title: string | React.ReactNode, description: string | React.ReactNode, buttonText: string }>();
    const currentProject = useRecoilValue(currentProjectState);


    const setBuildPipelineButton = useSetRecoilState(buildPipelineButtonState);

    useEffect(() => {
        if (pipelineId) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pipelineId])

    useEffect(() => {
        if (newBuildNotifications.some(notification => notification.itemId === pipelineId)) {
            handleRefreshBuilds();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newBuildNotifications]);

    useEffect(() => {
        if (pipelineDetails) {
            getRepositoryBranches();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pipelineDetails])

    const loadData = async () => {
        await Promise.all([
            getPipelineMetrics(),
            getPipelineBuilds(),
            getPipelineDetails(),
            getDeploymentTime(),
            getProjectLimitations()
        ]);
    }

    const getPipelineMetrics = async () => {
        setLoadingPipelineMetrics(true);
        const { data: { response } } = await getElasticSearchService().getPipelineMetrics(projectId, pipelineId);
        setPipelineMetrics(response);
        setLoadingPipelineMetrics(false);
    }

    const getDeploymentTime = async () => {
        const { data: { response: { deploymentTime } } } = await getElasticSearchService().getDeploymentTime(projectId!);
        setDeploymentTime(deploymentTime);
    }

    useEffect(() => {
        if (pipelineMetrics?.buildTime !== undefined && deploymentTime !== undefined) {
            // calculate current build minutes usage
            const MILLISECONDS_TO_MINUTES = 60000;
            setBuildMinutesUsed(Math.round((pipelineMetrics.buildTime + deploymentTime) / (MILLISECONDS_TO_MINUTES)));
        }
    }, [pipelineMetrics, deploymentTime])

    const getProjectLimitations = () => {
        const limitations = fetchProjectLimitations();
        setProjectLimitations(limitations);
    }

    useEffect(() => {
        if (buildMinutesUsed !== undefined && projectLimitations !== undefined) {
            const percentageOfBuildMinutesUsed = Math.round(buildMinutesUsed / projectLimitations.buildMinutes * 100);
            setBuildMinutesUsedPercentage(percentageOfBuildMinutesUsed);
            if (percentageOfBuildMinutesUsed >= 100) {
                // build minutes used
                setCtaMessage({
                    title: "No more available build minutes in this plan",
                    description: "You have used all the build minutes included in this plan. Upgrade your plan to get more build minutes and run your CI/CD pipeline.",
                    buttonText: "Unlock more minutes"
                });
            } else if (percentageOfBuildMinutesUsed > 70) {
                // build minutes almost used
                if (currentProject.paymentPlan?.id === "FREE") {
                    setCtaMessage({
                        title: `${percentageOfBuildMinutesUsed}% of build minutes used`,
                        description: <>By running your CI/CD pipeline more frequently, you can catch bugs and issues earlier in the development process, resulting in a more stable and reliable app for your customers. With our <b>Starter plan</b>, you'll get <b>500 build minutes per month</b>, giving you even more room to experiment and innovate.</>,
                        buttonText: "Unlock more minutes"
                    });
                } else if (currentProject.paymentPlan?.id === "STARTER") {
                    setCtaMessage({
                        title: `${percentageOfBuildMinutesUsed}% of build minutes used`,
                        description: "Empower your team to deploy updates at the speed of business with 2000 build minutes.",
                        buttonText: "Unlock more minutes"
                    });
                }
            }
        }
    }, [buildMinutesUsed, projectLimitations, currentProject])

    const getPipelineBuilds = async () => {
        const { builds } = await fetchPipelineBuilds({
            offset: paginationConfig.offset,
            limit: paginationConfig.limit
        });
        setBuilds(prevState => ([...prevState, ...builds]));
        setFilteredBuilds(prevState => ([...prevState, ...builds]));

        setPaginationConfig(prevState => ({
            limit: prevState.limit,
            offset: prevState.offset + prevState.limit,
            hasMore: !!builds.length ? true : false
        }));
    }

    const fetchPipelineBuilds = async ({ offset, limit }: { offset: number, limit: number }): Promise<{ builds: PipelineBuildDetails[] }> => {
        setLoadingPipelineBuilds(true);
        const { data: { builds } } = await getPipelinesService().getPipelineBuilds(
            projectId,
            pipelineId,
            offset,
            limit
        ).catch(_ => {
            return { data: { builds: [] } };
        });
        setLoadingPipelineBuilds(false);
        return { builds };
    }

    const renderStatusColor = (status: string, statusCode?: string) => {
        switch (status) {
            case "SUCCEEDED":
                return statusCode ? "orange" : "green"
            case "FAILED":
                return "red";
            case "RUNNING":
                return "blue";
        }
    }

    const handleSearch = (searchQuery: string) => {
        setFilteredBuilds(builds.filter(build => build.id.includes(searchQuery)));
    }

    const getRepositoryBranches = async () => {
        const { data: { branches } } = await getPipelinesService()
            .listGitAccountRepositoryBranches(projectId, pipelineDetails?.gitAccountId!, pipelineDetails?.repositoryUrl!);
        setRepositoryBranches(branches);
    }

    const getPipelineDetails = async () => {
        const { data: pipeline } = await getPipelinesService().getPipelineById(projectId, pipelineId);
        setPipelineDetails(pipeline);
    }

    const handlePipelineTrigger = async (branch: string) => {
        try {
            setIsPipelineTriggered(true);
            const { data: { buildId } } = await getPipelinesService()
                .triggerPipeline(projectId, pipelineId, { ref: `refs/heads/${branch}` });
            notification.success({
                message: "Pipeline build triggered",
                description: "It could take a few moments for the changes to take effect.",
                icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
            });

            setBuildPipelineButton((prevState) => {
                return calculateSubsequentBuildButtonClicks(prevState);
            })

            setTimeout(() => {
                navigate(`${location.pathname}/${pipelineId}/build/${buildId}`);
            }, 2000);
        } catch (error) {
            notification.error({
                message: "Error triggering build",
                description: (error as any).response?.data?.message || "We encountered an error while trying to trigger a build. Please try again."
            });
        } finally {
            setIsPipelineTriggered(false);
        }
    }

    const handleRefreshBuilds = async () => {
        const { builds } = await fetchPipelineBuilds({
            offset: 0,
            limit: 10
        });
        setBuilds(builds);
        setFilteredBuilds(builds);
        setPaginationConfig({
            offset: 10,
            limit: 10,
            hasMore: !!builds.length ? true : false
        });
    }

    const disableManualBuild = !!buildMinutesUsedPercentage && buildMinutesUsedPercentage >= 100;

    return (
        <>
            {
                ctaMessage &&
                <MessageCard
                    style={{ marginBottom: 20 }}
                    icon={
                        <DashboardOutlined style={{ fontSize: 28, color: "#3399ff" }} />
                    }
                    text={
                        <div>
                            <div style={{ fontWeight: 600 }}>
                                {ctaMessage.title}
                            </div>
                            <div>
                                {ctaMessage.description}
                            </div>
                        </div>
                    }
                    type="cta"
                    extra={
                        <Button type="primary">
                            <Link to="/pricing/upgrade">
                                {ctaMessage.buttonText}
                            </Link>
                        </Button>
                    }
                />
            }

            <Card title={
                <>
                    <span>Pipeline builds</span>
                    <ExplanationButton
                        content={<PipelineBuildsExplanation />}
                    />

                    <span style={{ float: "right" }}>
                        <Dropdown
                            trigger={["click"]}
                            placement="bottomRight"
                            disabled={disableManualBuild}
                            menu={{
                                direction: "ltr",
                                items: [
                                    {
                                        key: "choose",
                                        label: "Choose branch:",
                                        style: { pointerEvents: "none" }
                                    },
                                    { type: "divider" },
                                    ...repositoryBranches.map(branch => ({
                                        key: branch,
                                        label: branch,
                                        onClick: () => handlePipelineTrigger(branch)
                                    }))
                                ]
                            }}
                        >
                            <Tooltip
                                title={
                                    disableManualBuild ?
                                        "No more available build minutes in this plan" :
                                        undefined
                                }
                            >
                                <Button
                                    type="primary"
                                    disabled={disableManualBuild}
                                    loading={isPipelineTriggered}
                                >
                                    Trigger pipeline <DownOutlined />
                                </Button>
                            </Tooltip>
                        </Dropdown>
                    </span>
                </>
            }>
                {
                    loadingPipelineMetrics ?
                        <Skeleton /> :
                        <Row gutter={24}>
                            <Col xs={24} sm={12} md={6}>
                                <DisplayMetric
                                    size="medium"
                                    icon={<CheckCircleOutlined />}
                                    title="Successful builds"
                                    data={pipelineMetrics?.successfulExecutions!}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <DisplayMetric
                                    size="medium"
                                    icon={<CloseCircleOutlined />}
                                    title="Failed builds"
                                    data={pipelineMetrics?.failedExecutions!}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <DisplayMetric
                                    size="medium"
                                    icon={<TrophyOutlined />}
                                    title="Success rate"
                                    data={`${(pipelineMetrics?.successfulExecutions! / (pipelineMetrics?.successfulExecutions! + pipelineMetrics?.failedExecutions!) * 100).toFixed(0)}%`}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <DisplayMetric
                                    size="medium"
                                    icon={<ClockCircleOutlined />}
                                    title="Total build time"
                                    data={
                                        pipelineMetrics?.buildTime ?
                                            moment.utc(
                                                pipelineMetrics?.buildTime
                                            ).format("m[min] s[sec]")
                                                .replace(/^0min /, "")
                                                .replace(/^0sec$/, "")
                                            : "0min"
                                    }
                                />
                            </Col>
                        </Row>
                }


                {/* Search box */}
                <Search
                    style={{ margin: "25px 0" }}
                    size="large"
                    placeholder="Search build executions..."
                    onKeyUp={(event: any) => handleSearch(event.target.value)}
                    onSearch={handleSearch}
                />

                {
                    loadingPipelineBuilds ?
                        <Skeleton /> :
                        <Card
                            type="inner"
                            title={
                                <Row gutter={24}>
                                    <Col xs={24} sm={12} md={4}>
                                        Status
                                    </Col>
                                    <Col xs={24} sm={12} md={9}>
                                        Execution
                                    </Col>
                                    <Col xs={24} sm={12} md={8}>
                                        Commit
                                    </Col>
                                    <Col xs={24} sm={12} md={3} style={{ textAlign: "right" }}>
                                        Time
                                    </Col>
                                </Row>
                            }>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: 500,
                                    overflow: "auto"
                                }}
                            >
                                <InfiniteScroll
                                    scrollableTarget="scrollableDiv"
                                    dataLength={filteredBuilds.length}
                                    next={getPipelineBuilds}
                                    hasMore={paginationConfig.hasMore && !!filteredBuilds.length}
                                    loader={
                                        <div className="infinite-scroll-loading">
                                            <LoadingOutlined />
                                        </div>
                                    }
                                    style={{ padding: "0 10px" }}
                                >
                                    {
                                        filteredBuilds.length ?
                                            filteredBuilds.map(build => (
                                                <Row gutter={24} key={build.id} className="pipelines-item" align="middle">
                                                    <Col xs={24} sm={12} md={4}>
                                                        <Tag color={renderStatusColor(build.status, build.statusCode)}>
                                                            {build.statusCode ?
                                                                <Tooltip title={PIPELINE_BUILD_STATUS_CODES[build.statusCode]} >
                                                                    <ExclamationCircleOutlined /> &nbsp;
                                                                </Tooltip> : null}
                                                            {build.status}
                                                        </Tag>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={9}>
                                                        <Link to={`${buildPath}/pipeline/${pipelineId}/build/${build.id}`}>
                                                            {build.id}
                                                        </Link>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={8}>
                                                        {
                                                            build.metadata.commit ?
                                                                <div style={{ fontSize: "12px" }}>
                                                                    <b>{build.metadata.commit.message}</b>
                                                                    <div>
                                                                        Commit by <Avatar src={build.metadata.commit.user.avatar} size={16} /> {build.metadata.commit.user.name}
                                                                    </div>
                                                                    <div>
                                                                        <GitCommitLink version={build.metadata.commit.sha || build.metadata.commit.version} repoUrl={build.metadata.repository} branch={build.metadata.commit.name} />
                                                                    </div>
                                                                </div> :
                                                                <>No deployment information available</>
                                                        }
                                                    </Col>
                                                    <Col xs={24} sm={12} md={3} style={{ textAlign: "right" }}>
                                                        <div style={{ fontWeight: 500 }}>
                                                            {
                                                                moment.utc(
                                                                    moment.duration(moment(build.stopDate || new Date()).diff(moment(build.startDate))).as("milliseconds")
                                                                ).format("m[min] s[sec]")
                                                                    .replace(/^0min /, "")
                                                                    .replace(/^0sec$/, "")
                                                            }
                                                        </div>
                                                        <div style={{ color: "gray" }}>
                                                            {build.startDate ? moment(build.startDate).fromNow() : ""}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )) :
                                            <div style={{ textAlign: "center" }}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <SearchOutlined style={{ fontSize: 60, verticalAlign: "middle", color: "#244460" }} />
                                                    <Title level={5} style={{ color: "black" }}>It looks like there are no results with that query</Title>
                                                    <p style={{ color: "black" }}>Please try another search</p>
                                                </div>
                                            </div>
                                    }
                                </InfiniteScroll>
                            </div>
                        </Card>
                }
            </Card >
        </>
    )
}

export default PipelineBuilds;