import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useAuth } from '../../contexts/Auth';
import { currentProjectState } from '../../recoil/project';

export default function PrivateOutlet() {
    const { isLoggedIn } = useAuth();
    const location = useLocation();
    const currentProject = useRecoilValue(currentProjectState);

    if (isLoggedIn) {
        // TODO why is this logic needed?
        if (
            ["/", "/projects", "/projects/"].includes(location.pathname) &&
            currentProject?.id &&
            !location.pathname.endsWith("/projects")
        ) {
            return <Navigate to={`/projects/${currentProject?.id}`} />;
        } else {
            return <Outlet />
        }
    } else {
        return <Navigate to="/auth/login" />;
    }
}