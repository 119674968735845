import { ProjectLimitations } from "@microtica/ms-project-sdk";
import { handleSetMixpanelGroup } from "../backend/tracking";
import { ExecutingTemplate } from "../types/index";

export const setProjectLocalStorage = (project: object) => {
    localStorage.setItem("project", JSON.stringify(project));
    handleSetMixpanelGroup();
};

export const getProject = () => {
    return JSON.parse(localStorage.getItem("project") || "{}");
};

export const getUser = () => {
    return JSON.parse(localStorage.getItem("profile") || "{}");
};

export const getExecutingTemplates = (envId?: string): ExecutingTemplate[] => {
    if (envId) {
        return JSON.parse(localStorage.getItem("executingTemplates") || "[]").filter((template: any) => template.env.id === envId)
    }
    return JSON.parse(localStorage.getItem("executingTemplates") || "[]")
}

export const setExecutingTemplates = (executingTemplates: ExecutingTemplate[]) => {
    localStorage.setItem("executingTemplates", JSON.stringify(executingTemplates));
}

export const getEnvironmentsLimit = () => {
    const project = getProject();
    return Object.keys(project).includes("paymentPlan") ?
        project.paymentPlan.limitations.environmentsAllowed :
        0
}

export const fetchProjectLimitations = (): ProjectLimitations => {
    const project = getProject();
    return project.paymentPlan?.limitations;
}

export const setDefaultPlan = (planId: string) => {
    localStorage.setItem("defaultPlan", planId);
}

export const getDefaultPlan = () => {
    return localStorage.getItem("defaultPlan") || "FREE";
}

export const resetDefaultPlan = () => {
    localStorage.removeItem("defaultPlan");
}

export const setMonitoringFilter = (envId: string, clusterId?: string, apps?: string[], timerange?: string) => {
    localStorage.setItem(
        "monitoringFilter",
        JSON.stringify({ envId, clusterId, apps, timerange })
    );
}

export const getMonitoringFilter = (): { envId?: string; clusterId?: string; apps?: string[]; timerange?: string; } => {
    return JSON.parse(localStorage.getItem("monitoringFilter") || "{}");
}