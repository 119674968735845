const EnvironmentVariablesExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Environment variables
            </div>
            <div className="explanation-subtitle">
                Dynamic app variables
            </div>
            <br />

            <p>
                Microtica allows you to define custom variables that can be used by your application at runtime.
            </p>
            <p>
                The environment variables need to be defined in the <code style={{ color: "var(--primary-color)" }}>.microtica/schema.json</code> file
                in your app. After that you can reference them in your application as you would any other environment variables.
            </p>
            <p>
                Any environment variable update here will trigger a new deployment of your app with the new values.
            </p>
        </>
    )
}

export default EnvironmentVariablesExplanation;