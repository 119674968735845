import { Card, Input, Form, Button, notification } from "antd";

import { RequiredMark } from "antd/lib/form/Form";
import { useState } from "react";
import Title from "antd/lib/typography/Title";
import { getUserManagementService } from "../../backend";
import MicroticaLogo from "../../assets/logo-color.svg";
import { Link } from "react-router-dom";

export default function ResetPasswordRequest() {
    const [form] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    async function handleResetPassword(values: { email: string }) {
        try {
            setLoading(true);
            await getUserManagementService().forgotUserPassword({
                username: values.email
            });
        } catch (error: any) {
            notification.error({
                message: "Unable to reset password",
                description: error.response.data.message
            });
        } finally {
            setLoading(false);
            setEmailSent(true);
        }
    }

    return (
        <>
            <Link to="/">
                <img src={MicroticaLogo} style={{ height: "40px" }} alt="microtica" />
            </Link>
            <br />
            <Card style={{ width: "400px" }} bordered>
                <Card.Meta
                    title={<Title level={4}>Reset your password</Title>}
                    description="Enter the email address associated with your account. We will send a reset link to your email."
                />
                <br />
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={requiredMark}
                    onFinish={handleResetPassword}
                >
                    <Form.Item
                        name="email"
                        label="Email"
                        required
                        rules={[{ required: true, message: `Please input email!` }]}
                    >
                        <Input placeholder="email" type="email" onChange={() => setEmailSent(false)} />
                    </Form.Item>
                    <Button htmlType="submit" type="primary" loading={loading} disabled={emailSent} block>
                        {
                            emailSent ? "Link sent " : "Send reset link"
                        }
                    </Button>
                </Form>
            </Card>
        </>
    )
}