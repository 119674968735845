const GitAccountExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Git Account
            </div>
            <div className="explanation-subtitle">
                Code repository
            </div>
            <br />

            <ul>
                <li>
                    You must connect a Git account in order to deploy an application and Microtica will handle the rest
                </li>
                <li>
                    There can be multiple accounts connected from the same Git provider
                </li>
                <li>
                    All the members on the same project will be able to use that Git account in order to deploy an application
                </li>
                <li>
                    If you signed up to Microtica with Github, your project will already be linked to that Github account
                </li>
                <li>
                    You can add or remove Git accounts from Microtica at any time
                </li>
            </ul>
        </>
    )
}

export default GitAccountExplanation;