export const stringToColor = (text: string) => {
    // var colors = [
    //     "#F1F1F1", 
    //     "#202020", 
    //     "#7E909A", 
    //     "#1C4E80", 
    //     "#A5D8DD", 
    //     "#EA6A47", 
    //     "#0091D5",
    //     // '#7eadd4',
    //     // '#4365bf',
    //     // '#3a5cbc',
    //     // '#4b6ec2',
    //     // '#5277c4',
    //     // '#5a80c7',
    //     // '#6189ca',
    //     // '#6892cd',
    //     // '#6f9bcf',
    //     // '#77a4d2',
    //     // '#86b6d6',
    //     // '#8ebfd9',
    //     // '#97c8db',
    //     // '#a0d2dc',
    //     // '#aadade',
    //     // '#b5e3e0',
    //     // '#c1ece1',
    //     // '#d0f4e2',
    //     // '#e2fbe2',
    //     // '#ffffe0'
    // ]

    // Ref: https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
    const colors = [
        "#7E909A", "#1C4E80", "#A5D8DD", "#EA6A47", "#0091D5",
        "#614dff", "#48446e", "#d2980d", "#7eb0d5", "#6AB187", "#488A99", "#287F71"
        // "#d2980d", "#a57c1b", "#363445", "#48446e", "#5e569b", "#776bcd", "#9080ff",
        // "#b30000", "#7c1158", "#4421af", "#1a53ff", "#0d88e6", "#00b7c7", "#5ad45a", "#ebdc78"
    ]


    if (text === '5xx' || text === "client_errors_count" || text === "server_errors_count" || (parseInt(text) >= 500 && parseInt(text) <= 599)) {
        return '#da1e28';
    } else if (text === '4xx' || (parseInt(text) >= 400 && parseInt(text) <= 499)) {
        return '#ff832b';
    }

    var hash = 0;
    if (text.length === 0) return colors[hash];
    for (var i = 0; i < text.length; i++) {
        hash = text.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    hash = ((hash % colors.length) + colors.length) % colors.length;
    return colors[hash].toString();
}