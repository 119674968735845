import { GetStagesItem } from "@microtica/ms-engine-sdk";
import { Button, Empty, Result, Space } from "antd";
import { useEffect, useState } from "react"
import GridList from "../../components/GridList";
import moment from "moment-timezone";
import { CalendarOutlined, PlusOutlined, SettingOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import CreateEnvironmentModal from "./CreateEnvironmentModal";
import { getEnvironmentService } from "../../backend";
import CloudAccountLabel from "../../components/CloudAccountLabel";

const ProjectEnvironments = () => {
    const { projectId } = useParams() as { projectId: string; };
    const [loading, setLoading] = useState(true);
    const [environments, setEnvironments] = useState<GetStagesItem[]>([]);
    const [createEnvironmentModalVisible, setCreateEnvironmentModalVisible] = useState(false);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = async () => {
        const { data: { stages } } = await getEnvironmentService().getStages(projectId);
        setEnvironments(stages);
        setLoading(false);
    }

    const EnvironmentsPlaceholder = () => (
        <div className="flex-justify-center full-width">
            <Result
                title="Welcome to your environments!"
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                subTitle={
                    <>
                        <div>
                            It's currently blank, with no environments ready to roll.
                        </div>
                        <div>
                            Start by creating new environment below!
                        </div>
                    </>
                }
                extra={
                    <Button
                        onClick={() => {
                            setCreateEnvironmentModalVisible(true);
                        }}>
                        <PlusOutlined /> Create Environment
                    </Button>
                }
            />
        </div>
    )

    return (
        <>

            <GridList
                filter={environments.length > 6}
                filterPlaceholder="Search environments"
                itemsInRow={3}
                loading={loading}
                disableEmptyState={true}
                dataSource={environments.map((env: GetStagesItem) => ({
                    key: env.id,
                    title: <>
                        {env.name}
                        <CloudAccountLabel
                            provider={env.cloudProvider}
                            envId={env.id}
                            accountId={env.awsAccountId || env.gcpProjectId}
                            region={env.awsRegion || env.gcpRegion}
                        />
                    </>,
                    extra:
                        <Link to={`/projects/${projectId}/environments/${env.id}/settings`} onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            <Button icon={<SettingOutlined />} />
                        </Link>,
                    filterMetadata: `${env.id} ${env.description} ${env.awsAccountId} ${env.description} ${env.awsAccountId} ${env.awsRegion} ${env.status}`,
                    description: env.description,
                    tags: [
                        {
                            position: "bottom",
                            value:
                                <Space>
                                    <CalendarOutlined />
                                    {`Last updated ${moment(env.lastDeployed).fromNow()}`}
                                </Space>
                        },

                    ],
                    link: `/projects/${projectId}/environments/${env.id}`
                }))}
            />
            {
                !loading && environments?.length === 0 &&
                <EnvironmentsPlaceholder />
            }

            {createEnvironmentModalVisible && <CreateEnvironmentModal
                visible={createEnvironmentModalVisible}
                onCancel={() => setCreateEnvironmentModalVisible(false)}
                onOk={async () => {
                    await loadData();
                    setCreateEnvironmentModalVisible(false);
                }}
            />}
        </>
    )
}

export default ProjectEnvironments;