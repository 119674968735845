import Modal from "antd/lib/modal/Modal";
import { Button } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { paymentPlanRequiredModalState } from "../../recoil/payment-plan-required";
import { WarningTwoTone } from '@ant-design/icons';
import { currentProjectState } from "../../recoil/project";
import { useState } from "react";

const PaymentPlanRequiredModal = () => {
    const currentProject = useRecoilValue(currentProjectState);
    const [currentPaymentPlanRequiredModalState, setCurrentTemplateState] = useRecoilState(paymentPlanRequiredModalState);

    const [title] = useState(currentProject?.paymentPlan?.id === "FREE" ? "Upgrade your plan to continue" : "Your project is deactivated");
    const [description] = useState(
        currentProject?.paymentPlan?.id === "FREE" ?
            "This is one of our paid features. To continue your work, subscribe to a paid plan." :
            "Your project is deactivated because your trial has expired or your subscription has been canceled. To continue your work, subscribe to a paid plan."
    );

    const onCancel = () => {
        setCurrentTemplateState({ visible: false });
    }

    return (
        <Modal
            title={title}
            centered
            closable={true}
            open={currentPaymentPlanRequiredModalState.visible}
            onCancel={onCancel}
            zIndex={1001}   // always display on top (default is 1000)
            footer={[
                <Button
                    type="primary"
                >
                    <a href="/pricing/upgrade">
                        Choose plan
                    </a>
                </Button>
            ]}
        >
            <div className="flex-justify-center">
                <span style={{ width: "15%", display: "inline-block", textAlign: "center", fontSize: 40 }}>
                    <WarningTwoTone />
                </span>
                <span style={{ width: "85%", display: "inline-block" }}>
                    {description}
                </span>
            </div>
        </Modal>
    )
}

export default PaymentPlanRequiredModal;
