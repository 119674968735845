import { Button, Card, Divider, Form, Input, notification, Skeleton, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getKubeService } from "../../backend";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { RequiredMark } from "antd/es/form/Form";

export default function KubernetesSettingsGeneral() {
    const { projectId, envId, clusterId } = useParams() as {
        projectId: string;
        envId: string;
        clusterId: string;
    };
    const [requiredMark] = useState<RequiredMark>('optional');
    const [loading, setLoading] = useState(true);
    const [editingCluster, setEditingCluster] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        const load = async () => {
            const { data: cluster } = await getKubeService().getKubernetes(clusterId, projectId);
            form.setFieldsValue({
                name: cluster.name,
                endpoint: cluster.endpoint,
                apiToken: "(sensitive)"
            });
            setLoading(false);
        }

        load();
    }, []);

    const handleUpdate = async () => {
        try {
            const { name, apiToken, endpoint } = await form.validateFields();

            setEditingCluster(true);

            await getKubeService().updateKubernetes(
                clusterId,
                projectId,
                apiToken !== "(sensitive)" ? { name, endpoint, apiToken } : { name, endpoint }
            );
            notification.success({
                message: `Cluster updated`,
                description: `Cluster ${name} was successfully updated`,
            });

        } catch (error) {
            if ((error as any).errorFields) {
                notification.error({
                    message: "Error updating cluster",
                    description: "Cluster configuration is not valid."
                });
            } else {
                notification.error({
                    message: "Error updating cluster",
                    description: (error as any).response?.data?.message
                });
            }
        } finally {
            setEditingCluster(false);
        }
    }

    return (
        <Space direction="vertical" className="full-width">
            <Card bordered>
                <Card.Meta
                    title="Cluster Configuration"
                    description="Configure access to your Kubernetes cluster"
                />
                <Divider />
                {
                    loading ? <Skeleton /> :
                        <>
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={{}}
                                requiredMark={requiredMark}
                            >
                                <Form.Item
                                    name="name"
                                    label="Name"
                                    required
                                    rules={[{ required: true, message: 'Please input the Kubernetes Name!' }]}
                                >
                                    <Input autoFocus={!!envId} />
                                </Form.Item>
                                <Form.Item
                                    name="endpoint"
                                    label="Endpoint"
                                    required
                                    rules={[
                                        { required: true, message: 'Please input the Kubernetes Endpoint!' }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="apiToken"
                                    label="API token"
                                    required
                                    rules={[{ required: true, message: 'Please input the API token!' }]}
                                >
                                    <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                                </Form.Item>
                                <div className="text-right">
                                    <Button
                                        onClick={handleUpdate}
                                        loading={editingCluster}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        </>
                }
            </Card>
        </Space>
    )
}