import { Alert, Avatar, Button, Checkbox, Col, Drawer, Modal, ModalFuncProps, notification, Row, Skeleton, Space, Tooltip } from "antd";
import {
    ExpandAltOutlined,
    InfoCircleOutlined,
    ShrinkOutlined,
    RocketOutlined,
    CloseOutlined,
    ThunderboltOutlined,
    CloseCircleFilled
} from "@ant-design/icons";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { getEnvironmentService, getElasticSearchService, getRealtimeService } from "../backend";
import DeploymentStatusTag, { isDeploymentStatusInProgress } from "./DeploymentStatusTag";
import { EnvDeployment, Log } from "../types";
import { mapInfraDeployments } from "../utils/deployments";
import { trackCancelDeployment } from "../backend/tracking/deployment";
import Logs from "./Logs";
import EnvironmentDeploymentResource from "./EnvironmentDeploymentResource";
import { Socket } from "socket.io-client";

interface ComponentDeployDetailsDrawerProps {
    type: "build" | "deploy" | string;
    projectId: string;
    envId: string;
    cloudProvider: string;
    iac: string;
    deploymentId: string;
    open?: boolean;
    onClose?: () => void;
}

const ComponentDeployDetailsDrawer = ({
    type,
    projectId,
    envId,
    cloudProvider,
    iac,
    deploymentId,
    open,
    onClose
}: ComponentDeployDetailsDrawerProps) => {
    const [events, setEvents] = useState<string[]>([
        "⏳ \u001b[36mFetching logs...\u001b[0m",
        ""
    ]);
    const [loading, setLoading] = useState(true);
    const [deploymentDetails, setDeploymentDetails] = useState<EnvDeployment>();
    const [disableCancelDeploymentBtn, setDisableCancelDeploymentBtn] = useState(false);
    const [eventsExpanded, setEventsExpanded] = useState(false);
    const [showUpdatesOnly, setShowUpdatesOnly] = useState(false);
    const [hasUpdates, setHasUpdates] = useState(false);
    const [socket, setSocket] = useState<Socket>();
    const [, setModal] = useState<{
        destroy: () => void;
        update: (configUpdate: ModalFuncProps) => void;
    }>();

    useEffect(() => {
        (async () => {
            const data = await loadData();
            const hasUpdates = data?.resources.some(r => !r.prev.equal);
            setHasUpdates(hasUpdates || false);
            setShowUpdatesOnly(hasUpdates || false);
            setEventsExpanded(data?.resources.length === 0);

            if (iac === "terraform") {
                const socket = getRealtimeService(
                    {
                        projectId,
                        stageId: envId,
                        deploymentId,
                        typeOfLogs: "terraform"
                    }
                );
                setSocket(socket);
            }
        })();

        moment.updateLocale(moment.locale(), { invalidDate: "" });

        const interval = setInterval(async () => {
            const data = await loadData();

            // Stop getting updates if the deployment is already completed
            if (data && !isDeploymentStatusInProgress(data?.status.name!)) {
                clearInterval(interval);
            }
        }, 5000);
        return () => clearInterval(interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let allLogs: Log[] = [];
        if (socket !== undefined) {
            socket.on("logs", (logs: Log[]) => {

                allLogs = allLogs.concat(...logs);

                setEvents(allLogs.map(l => `[${l.timestamp}] ${l.message}`));
            });
        }

        return () => { socket?.disconnect() }
    }, [socket]);

    const loadData = async () => {
        try {
            const [
                { data: envDetails },
                { data: { events: cfnEvents } },
                { data: { response: deployments } }
            ] = await Promise.all([
                getEnvironmentService().getStage(envId, projectId),
                iac === "cloudformation" ?
                    getEnvironmentService().getStageDeploymentEvents(envId, projectId).catch(_ => ({ data: { events: [] } })) :
                    Promise.resolve(({ data: { events: [] } })),
                getElasticSearchService().getStageDeploymentHistory(projectId, envId)
            ]);

            const deployment = deployments.find(d => d.deploymentId === deploymentId);
            const infraDeployment = mapInfraDeployments(deployments, envDetails.lastDeploymentId).find(d => d.id === deploymentId); // TODO

            setDeploymentDetails(infraDeployment);

            if (iac === "cloudformation") {
                // Get the deployment events from the history
                // If not present, get the events from CFN directly
                const historyEvents = [
                    ...(infraDeployment?.status.errors || []).map(e => ({
                        eventId: `${e.logicalResourceId}-${e.statusReason}`,
                        logicalResourceId: e.logicalResourceId,
                        resourceStatus: "DEPLOYMENT_FAILED",
                        resourceType: e.resourceType,
                        statusReason: e.statusReason,
                        timestamp: new Date(infraDeployment?.timestamp!).getTime(),
                        stackId: "",
                        stackName: "",
                        physicalResourceId: "",
                        clientRequestToken: ""
                    })),
                    ...deployment?.logs?.events[`environment-${envId.split("-")[0]}`].events || [],
                ];

                const deploymentEvents = deployment?.status === "DEPLOY_QUEUED" ? [] :
                    historyEvents.length > 0 ? historyEvents : cfnEvents;
                const sortedEvents = deploymentEvents.sort((d1, d2) => Number(new Date(d1.timestamp)) - Number(new Date(d2.timestamp)));
                setEvents(
                    sortedEvents.map(e =>
                        `${moment(e.timestamp).format()} ${e.resourceStatus?.includes("FAILED") ? "\u001b[31m" : ""}[${e.resourceStatus}] \u001b[0m${e.logicalResourceId}${e.statusReason ? `\n${e.resourceStatus?.includes("FAILED") ? "\u001b[31m" : ""}===> ${e.statusReason}\u001b[0m` : ""}`
                    )
                );
            }

            return infraDeployment;

        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    const showCancelStageDeploymentConfirm = () => {
        const modal = Modal.confirm({
            title: "Cancel deployment",
            icon: <CloseCircleFilled style={{ color: "#ff4d4f" }} />,
            content: "Are you sure you want to cancel the deployment? This action cannot be undone.",
            okText: "Cancel Deployment",
            okButtonProps: {
                danger: true
            },
            cancelText: "No",
            centered: true,
            async onOk() {
                return handleCancelDeployment();
            },
            onCancel() {
                setModal(undefined);
            }
        });
        setModal(modal);
    }

    const handleCancelDeployment = async () => {
        try {
            setDisableCancelDeploymentBtn(true);
            await getEnvironmentService().cancelDeploy(
                envId,
                projectId,
                deploymentDetails?.status.name === "DEPLOY_QUEUED" ? {
                    deploymentId
                } : undefined
            );
            notification.info({
                message: "Deployment canceled",
                description: "The environment deployment has been canceled"
            });
            trackCancelDeployment(envId);
        } catch (error: any) {
            setDisableCancelDeploymentBtn(false);
            notification.info({
                message: "Deployment canceled error",
                description: error.response?.data?.message || "An unknown erorr has occured"
            });
        }
        setModal(undefined);
    }

    const handleClose = () => {
        onClose?.();
    }

    const Header = () => (
        !deploymentDetails ? <Skeleton /> :
            <div>
                <div className="flex-justify-space-between" style={{ marginBottom: "48px" }}>
                    <Row gutter={[24, 24]}>
                        <Col>
                            <Space direction="vertical">
                                <div style={{ fontSize: "14px", fontWeight: 600 }}>
                                    Environment
                                </div>
                                <div>
                                    {deploymentDetails.envId}
                                </div>
                            </Space>
                        </Col>
                        <Col>
                            <Space direction="vertical">
                                <div style={{ fontSize: "14px", fontWeight: 600 }}>
                                    Execution time
                                </div>
                                <div>
                                    {
                                        moment(deploymentDetails.timestamp).fromNow()
                                    }
                                </div>
                            </Space>
                        </Col>
                        <Col>
                            <Space direction="vertical">
                                <div style={{ fontSize: "14px", fontWeight: 600 }}>
                                    Status
                                </div>
                                <div>
                                    <DeploymentStatusTag status={deploymentDetails.status.name} />
                                </div>
                            </Space>
                        </Col>
                    </Row>
                    {
                        deploymentDetails.status.name === "DEPLOY_QUEUED" ||
                            (["CREATE_IN_PROGRESS", "UPDATE_IN_PROGRESS"].includes(deploymentDetails.status.name!) && cloudProvider === "aws") ?
                            <Button
                                disabled={disableCancelDeploymentBtn}
                                danger={true}
                                onClick={showCancelStageDeploymentConfirm}
                            >
                                Cancel deployment
                            </Button> : undefined
                    }
                </div>
            </div>
    )

    const TypeIcon = () => {
        if (type === "deploy") {
            return <Avatar style={{ backgroundColor: "#077BB5" }} icon={<RocketOutlined rotate={45} />} />
        } else {
            return <Avatar style={{ backgroundColor: "goldenrod", paddingTop: 1 }} icon={<ThunderboltOutlined />} />
        }
    }

    return (
        <Drawer
            title={
                <Space size="middle">
                    <TypeIcon />
                    <div>
                        <div>
                            Deployment
                        </div>
                        <div style={{ fontSize: "14px", fontWeight: 400 }}>
                            ID: {deploymentDetails?.id}
                        </div>
                    </div>
                </Space>
            }
            placement="bottom"
            height="100%"
            onClose={handleClose}
            open={open}
            destroyOnClose={true}
            closable={false}
            extra={
                <Button
                    icon={<CloseOutlined />}
                    onClick={handleClose}
                />
            }
        >
            {
                loading ?
                    <Skeleton /> :
                    <>
                        <Header />
                        {
                            deploymentDetails?.error ?
                                // Deployment error
                                <Alert
                                    message="Deployment error"
                                    description={deploymentDetails.error}
                                    type="error"
                                    showIcon
                                /> :
                                // Deployment details
                                <Row gutter={24}>
                                    {/* Events */}
                                    <Col span={eventsExpanded ? 24 : 18}>
                                        <Logs
                                            title="Deployments logs"
                                            height="calc(100vh - 220px)"
                                            extra={
                                                deploymentDetails?.resources.length! > 0 &&
                                                <Button
                                                    type="link"
                                                    style={{ paddingRight: 0, color: "white" }}
                                                    icon={eventsExpanded ? <ShrinkOutlined /> : <ExpandAltOutlined />}
                                                    onClick={() => setEventsExpanded(!eventsExpanded)}
                                                >
                                                    {eventsExpanded ? "Show updates" : "Expand"}
                                                </Button>
                                            }
                                            logs={events}
                                        />
                                    </Col>

                                    {/* Updated resources */}
                                    {
                                        !eventsExpanded &&
                                        <Col span={6}>
                                            <div style={{ fontSize: "18px", fontWeight: 600 }}>
                                                Updates
                                            </div>
                                            <div style={{ margin: "16px 0" }}>
                                                <Tooltip title="Show only the resources that has version or configuration changes in this deployment.">
                                                    <Checkbox
                                                        checked={showUpdatesOnly}
                                                        onChange={(e) => setShowUpdatesOnly(e.target.checked)}
                                                    >
                                                        Only updated resources
                                                    </Checkbox>
                                                    <InfoCircleOutlined />
                                                </Tooltip>
                                            </div>
                                            {
                                                deploymentDetails?.resources
                                                    .filter(r => !showUpdatesOnly ? true : !r.prev?.equal)
                                                    .map(r => (<EnvironmentDeploymentResource envId={envId} deploymentType={deploymentDetails.type} resource={r} key={r.id} />))
                                            }
                                            {
                                                !hasUpdates && showUpdatesOnly &&
                                                <div className="text-center">
                                                    No resources with updated version or configuration changes in this deployment.
                                                </div>
                                            }
                                        </Col>
                                    }
                                </Row>
                        }

                    </>
            }
        </Drawer>
    )
}

export default ComponentDeployDetailsDrawer;