import { Button, Card, Divider, Space } from "antd";
import { GithubOutlined, GoogleOutlined } from '@ant-design/icons';
import { useAuth } from "../../contexts/Auth";
import { useCardClassName } from "../../contexts/Helpers/helpers";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentTemplateStepState, templateState } from "../../recoil/template";
import { FormState } from "../../types";
import { useEffect } from "react";
import { Link } from "react-router-dom";

interface SignupTemplateModuleProps {
    onChange?: () => void;
}

const SignupTemplateModule = ({
    onChange
}: SignupTemplateModuleProps) => {
    const { isLoggedIn } = useAuth();
    const currentTemplateStep = useRecoilValue(currentTemplateStepState);
    const [currentTemplateState, setCurrentTemplateState] = useRecoilState(templateState);
    const { loginWithExternalProvider } = useAuth({ redirectUrl: `${window.location.pathname}${window.location.search || ""}` });

    useEffect(() => {
        handleSetTemplateState(!isLoggedIn ? "editing" : "saved");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    const handleSignupWithExternalProvider = (externalProviderType: "github" | "google") => async () => {
        handleSetTemplateState("saved");
        await loginWithExternalProvider(externalProviderType, "signup");
        onChange?.();
    }

    const cardClassName = useCardClassName(
        currentTemplateState["login"] === "disabled",
        [currentTemplateState, currentTemplateStep]
    );

    const handleSetTemplateState = (mode: FormState) => {
        setCurrentTemplateState({
            ...currentTemplateState,
            login: mode
        });
    }

    return (
        <Card bordered key="destination" className={cardClassName}>
            <Card.Meta
                title="Get Started"
                description={`Continue with GitHub or your email to deploy this template with Microtica.`}
            />
            <br />
            <Card type="inner">
                <div className="flex-justify-center">
                    <Space direction="vertical">
                        <br />
                        <Button className="ant-btn-github" icon={<GithubOutlined />} onClick={handleSignupWithExternalProvider("github")}>
                            Continue with GitHub
                        </Button>
                        <Button className="ant-btn-google" icon={<GoogleOutlined />} onClick={handleSignupWithExternalProvider("google")}>
                            Continue with Google
                        </Button>
                        <Divider>or</Divider>
                        <Button type="link" className="full-width">
                            <Link to="/auth/signup">
                                Sign up with Email
                            </Link>
                        </Button>
                        <br />
                    </Space>
                </div>
            </Card>
        </Card>
    )
}

export default SignupTemplateModule;