import { Column, ColumnConfig, Options, Plot } from "@ant-design/plots";
import { Card } from "antd";
import moment from "moment";
import { Datum } from "@antv/g2plot";
import { Types } from "@antv/g2";
import { stringToColor } from "../../../utils/string-to-color";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { ChartAnnotation, handleAnnotations } from "./types";
import { ChartMetricItem } from "../../../types";

interface ColumnChartWiggetProps {
    title: string;
    seriesField?: string;
    data: ChartMetricItem[];
    height?: number;
    config?: ColumnConfig;
    extra?: ReactElement;
    annotations?: ChartAnnotation[];
}

const ColumnChartWidget = ({
    title,
    data,
    config,
    height = 200,
    extra,
    annotations,
}: ColumnChartWiggetProps) => {
    const ref = useRef<any>();
    const [updatedAnnotations, setUpdatedAnnotations] = useState<ChartAnnotation[]>([]);
    const [maxThresholdValue, setMaxThresholdValue] = useState<number>();
    const [initialMaxYValue, setInitialMaxYValue] = useState<number>();

    const getTicks = (plot: Plot<Options>, initial: boolean) => {
        const maxAnnotationThreshold = Math.max(0, ...(annotations || []).map(el => el.start[1]));
        let ticks = plot.chart.getController('axis').getComponents().find(c => c.direction === 'left')?.component.cfg.ticks || [];
        let stepValue = parseFloat(ticks[1]?.name || 0);
        if (initial) {
            let maxYValue = parseFloat(ticks[ticks.length - 1]?.name || 0);
            setInitialMaxYValue(maxYValue);
        } else if (initialMaxYValue) {
            const newMaxYValue = Math.max(maxAnnotationThreshold, initialMaxYValue);
            const updatedAnnotations = handleAnnotations(newMaxYValue, stepValue, annotations)
            setUpdatedAnnotations(updatedAnnotations);
            setMaxThresholdValue(newMaxYValue);
        }
    }

    useEffect(() => {
        if (ref?.current) {
            getTicks(ref.current.getChart(), false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [annotations]);

    const conf = useMemo(() => ({
        data,
        isGroup: true,
        xField: 'timestamp',
        yField: 'value',
        seriesField: 'group',
        xAxis: {
            label: {
                formatter: (v: string) => `${moment(parseInt(v)).format('HH:mm')}`
            }
        },
        yAxis: {
            maxLimit: maxThresholdValue
        },
        legend: { position: "bottom-left" as Types.TooltipCfg["position"] },
        color: ({ group }: Datum) => {
            return stringToColor(group);
        },
        height,
        animation: false,
        tooltip: {
            title: (_: string, datum: Datum) => {
                return moment(datum.timestamp).format("DD MMM YY • HH:mm")
            },
            formatter: (datum: Datum) => {
                return {
                    name: datum.group,
                    value: datum.value
                }
            }
        },
        annotations: updatedAnnotations,
        columnStyle: updatedAnnotations.length > 0 ?
            { fillOpacity: 0.3 } :
            undefined,
        onReady: (chart: any) => getTicks(chart, true),
        ...config
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [data, maxThresholdValue, updatedAnnotations]);

    return (
        <Card size="small" title={title} className="full-height monitoring-chart" extra={extra}>
            <Card.Meta style={{ marginBottom: "15px" }} />

            <Column {...conf} ref={ref} />

            {
                conf.data.length === 0 ?
                    <div
                        className="gray-text text-center"
                        style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                        No data available for {title} metric
                    </div> : undefined
            }
        </Card>
    )
}

export default ColumnChartWidget;