import { useEffect } from "react";
import { getEnvironmentService } from "../../backend";

interface IUseNotDeployedResourcesDataProps {
    envId: string;
    projectId: string;
    setNotDeployedResources: React.Dispatch<React.SetStateAction<string[]>>;
}

export const useNotDeployedResourcesData = ({
    envId,
    projectId,
    setNotDeployedResources
}: IUseNotDeployedResourcesDataProps) => {

    const loadStageResources = async () => {
        const { data: { resources } } = await getEnvironmentService().getStageResources(envId, projectId);
        setNotDeployedResources(
            resources
                .filter(r => ["NOT_DEPLOYED", "DELETE_COMPLETE", "ROLLBACK_COMPLETE"].includes(r.status))
                .map(r => r.name)
        );
    }

    useEffect(() => {
        loadStageResources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}