import { MsUserApi } from "@microtica/ms-usermanagement-sdk";
import { MsProjectApi } from "@microtica/ms-project-sdk";
import { MsKubeApi } from "@microtica/ms-kube-sdk";
import { MsElasticsearchApi } from "@microtica/ms-elasticsearch-sdk";
import { MsEngineApi } from "@microtica/ms-engine-sdk";
import { MsAwsApi } from "@microtica/ms-aws-sdk";
import { MsApApi } from '@microtica/ms-ap-sdk';
import { MsNotificationApi } from "@microtica/ms-notification-sdk";
import { MsMonitoringApi, Configuration } from "@microtica/ms-monitoring-sdk";
import { ComponentInstanceSchedulerApi } from "@microtica/ms-cloud-cost-optimizer-sdk";
import { env } from "../env";
import { io } from "socket.io-client";
import { AxiosInstance } from "axios";
import axios from "axios";
import { registerRequestInterceptor } from "./interceptors";

const axiosInstance = axios.create() as any;
registerRequestInterceptor(axiosInstance);

export function getBaseUrl(): string {
    return env.REACT_APP_BACKEND_ENDPOINT;
};

export function getIdToken(): string | undefined {
    if (process.env.NODE_ENV === "test") {
        return "testing_access_token";
    }
    return localStorage.getItem("idToken") || undefined;
}

export function getUserManagementService() {
    return new MsUserApi(
        {
            accessToken: getIdToken()
        },
        `${getBaseUrl()}/ms-usermanagement`,
        axiosInstance
    );
}

export function getProjectService() {
    return new MsProjectApi(
        {
            accessToken: getIdToken()
        },
        `${getBaseUrl()}/ms-project`,
        axiosInstance
    );
}

export function getKubeService() {
    return new MsKubeApi(
        {
            accessToken: getIdToken()
        },
        `${getBaseUrl()}/ms-kube`,
        axiosInstance
    );
}

export function getElasticSearchService() {
    return new MsElasticsearchApi(
        {
            accessToken: getIdToken()
        },
        `${getBaseUrl()}/ms-elasticsearch`,
        axiosInstance
    );
}

export function getEnvironmentService() {
    return new MsEngineApi(
        {
            accessToken: getIdToken()
        },
        `${getBaseUrl()}/ms-engine`,
        axiosInstance
    );
}

export function getCloudService() {
    return new MsAwsApi(
        {
            accessToken: getIdToken()
        },
        `${getBaseUrl()}/ms-aws`,
        axiosInstance
    );
}

export function getPipelinesService() {
    return new MsApApi(
        {
            accessToken: getIdToken()
        },
        `${getBaseUrl()}/ms-ap`,
        axiosInstance
    );
}

export function getMonitoringService() {
    const axiosInstance: AxiosInstance = axios.create({
        baseURL: `${getBaseUrl()}/ms-monitoring`
    })
    const configuration: Configuration = new Configuration({
        accessToken: getIdToken(),
    })
    return new MsMonitoringApi(
        configuration,
        `${getBaseUrl()}/ms-monitoring`,
        axiosInstance
    );
}

export function getNotificationService() {
    return new MsNotificationApi(
        {
            accessToken: getIdToken()
        },
        `${getBaseUrl()}/ms-notification`,
        axiosInstance
    );
}

export function getCostOptimizationService() {
    return new ComponentInstanceSchedulerApi({
        basePath: `${getBaseUrl()}/ms-cloud-cost-optimizer`,
        accessToken: getIdToken()
    });
}

export function getRealtimeService(queryParams: any) {
    return io(
        getBaseUrl(), {
        path: "/ms-realtime/websockets/",
        withCredentials: true,
        reconnection: false,
        transports: ['websocket'],
        query: {
            ...queryParams,
            Authorization: `Bearer ${getIdToken()}`
        }
    });
}

export function disconnectRealtimeService() {
    return io().disconnect();
}