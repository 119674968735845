import { TreeNode } from '../types';
import { useNavigate } from 'react-router-dom';

import Tree from 'react-d3-tree';
import { MouseEventHandler, RefObject, useEffect, useRef, useState } from 'react';

interface TreeMapProps {
    width?: number;
    height?: number;
    margin?: { top: number; right: number; bottom: number; left: number };
    data: TreeNode;
}

const TreeMap = ({
    data
}: TreeMapProps) => {
    const navigate = useNavigate();
    const [translate, setTranslate] = useState({ x: 0, y: 0 });
    const treeContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            if (!entries || entries.length === 0) {
                return;
            }

            const { height } = entries[0].contentRect;

            setTranslate({
                x: 50,
                y: height / 2
            });
        });

        if (treeContainerRef.current) {
            resizeObserver.observe(treeContainerRef.current);
        }

        return () => {
            if (treeContainerRef.current) {
                resizeObserver.unobserve(treeContainerRef.current);
            }
        };
    }, []);

    const renderRectSvgNode = ({ nodeDatum, toggleNode }: { nodeDatum: TreeNode; toggleNode: MouseEventHandler }) => (
        <g onClick={() => {
            if (nodeDatum.link) {
                navigate(nodeDatum.link);
            }
        }}>
            {
                nodeDatum.attributes?.Type === "Project" ?
                    <rect width="40" height="40" x="-20" y="-20" fill="#A5D8DD" rx="8" ry="8" onClick={toggleNode} /> :
                    nodeDatum.attributes?.Type === "Owner" ?
                        <circle cx="0" cy="0" r="20" onClick={toggleNode} fill="black" /> :
                        nodeDatum.attributes?.Type === "Environment" ?
                            <circle cx="0" cy="0" r="20" onClick={toggleNode} fill="#0091D5" /> :
                            <rect width="40" height="40" x="-20" y="-20" fill="#7eb0d5" rx="8" ry="8" stroke-dasharray="4, 2" onClick={toggleNode} />
            }

            <text fill="black" strokeWidth="1" x="0" y="45" fontWeight={300}>
                {nodeDatum.name}
            </text>

            {
                nodeDatum.attributes?.Type === "Owner" ?
                    <text fill="rgba(0, 0, 0, 0.45)" x="0" dy={45 + 18} strokeWidth="0">
                        OWNER
                    </text> :
                    Object.keys(nodeDatum.attributes || {}).map((key, index) => (
                        <text fill="rgba(0, 0, 0, 0.45)" x="0" dy={45 + 18 * (index + 1)} strokeWidth="0">
                            {key}: {nodeDatum.attributes?.[key]}
                        </text>
                    ))
            }
        </g>
    );

    return <div style={{ height: "73vh" }} ref={treeContainerRef}>
        <Tree
            data={data}
            translate={translate}
            zoom={0.8}
            depthFactor={400}
            collapsible={false}
            separation={{ siblings: 1, nonSiblings: 1 }}
            centeringTransitionDuration={1}
            renderCustomNodeElement={renderRectSvgNode}
        />
    </div>
}

export default TreeMap;