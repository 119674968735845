import { Modal, notification } from "antd";
import { useState } from "react";
import { getMonitoringService } from "../../backend";
import MonitoringImage from "../../assets/monitoring.png";
import MessageCard from "../cards/MessageCard";
import { EnableMonitoringRequestClusterTypeEnum } from "@microtica/ms-monitoring-sdk";

interface EnableMonitoringModalProps {
    projectId: string;
    envId: string;
    clusterId: string;
    clusterName: string;
    clusterType: EnableMonitoringRequestClusterTypeEnum;
    onOk?: () => void;
    onCancel?: () => void;
    open?: boolean;
}


const EnableMonitoringModal = ({
    projectId,
    envId,
    clusterId,
    clusterName,
    clusterType,
    onOk,
    onCancel,
    open
}: EnableMonitoringModalProps) => {
    const [enabling, setEnabling] = useState(false);

    const handleEnableMonitoring = async () => {
        try {
            setEnabling(true);
            await getMonitoringService().enableMonitoring(
                clusterId,
                projectId,
                {
                    clusterType,
                    stageId: envId
                }
            );
            onOk?.();
            notification.success({
                message: "Enable monitoring initiated",
                description: "It could take a few minutes for the monitoring to become available."
            });
        } catch (error) {
            onOk?.();
            notification.error({
                message: "Cannot enable monitoring",
                description: <pre className="white-space-pre-wrap" style={{ fontSize: "12px" }}>
                    {(error as any).response.data.message}
                    {
                        (error as any).response.data.kubernetesError &&
                        <>
                            <br />
                            <br />
                            {(error as any).response.data.kubernetesError?.message}
                        </>
                    }

                </pre>
            })
        } finally {
            setEnabling(false);
        }
    }

    return <Modal
        open={open}
        title="Enable Monitoring"
        okText="Enable Monitoring"
        onOk={async () => {
            await handleEnableMonitoring();
        }}
        okButtonProps={{ loading: enabling }}
        onCancel={onCancel}
    >
        <br />
        Start tracking your <b>application's performance</b> metrics <b>in real-time</b>.
        <br />
        <br />
        Monitor <b>resource utilization</b>, <b>response times</b>, and overall <b>system health</b> to ensure optimal functionality and user experience.
        <br />
        <br />
        <MessageCard
            text={
                <div>
                    You are enabling monitoring for <b>{clusterName}</b> cluster.
                </div>
            }
            type="neutral"
        />
        <br />
        <img src={MonitoringImage} width={"100%"} />
        <br />
    </Modal >
}

export default EnableMonitoringModal;