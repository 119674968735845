import { RocketOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Card, Space, Tabs } from "antd";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { App, Component, IEnvDetails } from "../../types";
import { useEffect, useState } from "react";
import KubernetesLogs from "../app/KubernetesLogs";
import AppPipelines from "../app/AppPipelines";
import AppIncidents from "../app/AppIncidents";
import { Link } from "react-router-dom";
import DeployAppModal from "../../components/modals/DeployAppModal";
import AppOverview from "../app/AppOverview";
import AppMetrics from "../app/AppMetrics";

const EnvironmentKubernetesAppDetails = () => {
    const navigate = useNavigate();
    const { projectId, envId, componentId, namespace, appName, section } = useParams() as {
        projectId: string;
        envId: string;
        componentId: string;
        namespace: string;
        appName: string;
        section: string;
    };
    const { env, component, app } = useOutletContext<{
        env: IEnvDetails;
        component: Component;
        app: App;
    }>();
    const [activeTab, setActiveTab] = useState(section);
    const [showDeployModal, setShowDeployModal] = useState(false);

    useEffect(() => {
        setActiveTab(section);
    }, [section]);

    if (!app) {
        return <></>
    }

    return (
        <>
            <Card
                bordered
                title={
                    <div style={{ padding: "10px 0" }}>
                        <div style={{ fontWeight: 400 }}>Application</div>
                        <div style={{ fontWeight: 600 }}>{app.name}</div>
                    </div>
                }
                extra={
                    <Space>
                        {
                            <Button
                                type="primary"
                                onClick={() => setShowDeployModal(true)}
                                icon={<RocketOutlined style={{ fontSize: 16 }} rotate={45} />}
                            >
                                Deploy
                            </Button>
                        }
                        <Link to={`/projects/${projectId}/environments/${envId}/components/${componentId}/${app.clusterId}/apps/${namespace}/${appName}/settings`}>
                            <Button icon={<SettingOutlined />} />
                        </Link>
                    </Space>
                }
                className="full-width"
                styles={{
                    header: { border: "none" }
                }}
                style={{ height: section === "metrics" ? "auto" : "calc(100vh - 128px)" }}
            >
                <Tabs
                    activeKey={activeTab}
                    onChange={(activeKey) => {
                        setActiveTab(activeKey);
                        navigate(`/projects/${projectId}/environments/${envId}/components/${componentId}/apps/${app.namespace}/${appName}/${activeKey}`, { replace: true });
                    }}
                    items={[
                        {
                            key: "overview",
                            label: "Overview"
                        },
                        {
                            key: "logs",
                            label: "Logs"
                        },
                        {
                            key: "pipelines",
                            label: "Pipelines"
                        },
                        {
                            key: "metrics",
                            label: "Metrics"
                        },
                        {
                            key: "incidents",
                            label: <>
                                {/* Incidents <Badge color="red" /> */}
                                Events
                            </>
                        }
                    ]}
                />
                {activeTab === "overview" && <AppOverview component={component} app={app} />}
                {activeTab === "logs" && <KubernetesLogs env={env} component={component} app={app} />}
                {activeTab === "pipelines" && <AppPipelines env={env} component={component} />}
                {activeTab === "metrics" && <AppMetrics component={component} app={app} />}
                {activeTab === "incidents" && <AppIncidents component={component} app={app} />}
            </Card>
            {
                showDeployModal &&
                <DeployAppModal
                    visible={showDeployModal}
                    app={app}
                    onCancel={() => setShowDeployModal(false)}
                    onOk={() => {
                        // envDetails.refreshRoot();
                        setShowDeployModal(false);
                    }}
                />
            }
            {/* {
                <AutomatedBuildsAndDeploymentsModal
                    numberOfClicks={noOfClicks} />
            } */}
        </>
    )
}

export default EnvironmentKubernetesAppDetails;