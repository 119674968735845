import { Divider, Image } from "antd";
import Heading from "../../components/Heading";
import { Fragment } from "react";
import SideDrawer from "./SideDrawer";

interface ChangelogDrawerProps {
    visible?: boolean;
    onClose?: () => void;
}

const ChangelogDrawer = ({
    visible,
    onClose
}: ChangelogDrawerProps) => {
    const changelog = [
        {
            title: "📧 Email Alerts for Build Minutes and Deployments",
            date: "July 10, 2023",
            description: <>
                We're thrilled to introduce a powerful enhancement to our platform that ensures your workflows run smoothly without any hiccups! Now, you'll receive timely email alerts when <b>you reach 70% of your build minutes and/or deployment limit</b>, as well as if you exceed them.

                <br />
                <br />

                No more surprises or interruptions! With our personalized email notifications, you'll have full visibility into your resource usage and be empowered to optimize your processes proactively. Stay on top of your game, maximize efficiency, and take full control of your automated deployments.
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/w8qZQgABnrBwjKhKTkcfe_image.png",
            link: "https://docs.microtica.com/changelog#9HxyL"
        },
        {
            title: "💰 Maximize Your Cloud Savings with Powerful Cost Optimization Tools",
            date: "June 15, 2023",
            description: <>
                We're thrilled to unveil our latest update: Microtica's Cost Optimization feature. As a cloud delivery platform on AWS, we understand the importance of efficient cost management. With this new feature, we're putting the power in your hands to optimize your cloud spending, make informed decisions, and save big.

                <br />
                <br />

                Discover the full potential of Microtica's Cost Optimization feature. Upgrade your plan today and start maximizing your cloud savings like never before. Let's optimize your cloud costs together!
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/kLDNrfd48ta4olJKjWKsK_image.png",
            link: "https://docs.microtica.com/changelog#ky0v_"
        },
        {
            title: "📊🔍 Pipeline Logging: Introducing Pipeline Steps and Step Execution Outputs",
            date: "May 18, 2023",
            description: <>
                This month we've worked on some exciting updates to our product's logging capabilities that will improve the way you monitor and track your workflows. Our pipeline logs now provide comprehensive details about pipeline steps and their corresponding execution outputs, giving you unprecedented visibility into your processes.

                <br />
                <br />

                We are confident that our enhanced logs will empower you to streamline your processes, increase productivity, and make data-driven decisions with maximum confidence.
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/9EEi2RAx_NyIRGME5c9ET_image.png",
            link: "https://docs.microtica.com/changelog#ocZf9"
        },
        {
            title: "🪲 Improvements and fixes",
            date: "May 2023",
            description: <ul>
                <li>Improvements in the <a href="https://docs.microtica.com/assign-a-custom-domain" target="_blank" rel="noreferrer">Add custom domain</a> flow.</li>
                <li>Optimized data refresh on pods (containers) in App Overview.</li>
            </ul>,
            link: "https://docs.microtica.com/changelog#JpNP5"
        },
        {
            title: "🔑 The Medusa template now includes Backend + Admin",
            date: "Apr 28, 2023",
            description: <>
                We are delighted to announce that our existing <a href="https://app.microtica.com/templates/new?template=https://raw.githubusercontent.com/microtica/templates/master/medusa-server/.microtica/template.yaml">Medusa Server (backend) template</a> now includes the Admin dashboard, as supported by Medusa 🚀

                <br />
                <br />

                With this new addition, our users can now take advantage of both the Medusa Backend and Medusa Admin, which together provide a complete e-commerce solution. Medusa Backend is a powerful server that handles all of the critical backend operations, while Medusa Admin provides an intuitive and user-friendly interface for managing and configuring the storefront.

                <br />
                <br />

                At Microtica, we're dedicated to providing our users with the best possible experience. That's why we're constantly working on enhancing our platform and templates. We believe that this new addition to our Medusa template will significantly benefit our users and help them build robust and scalable e-commerce applications with ease.
            </>,
            link: "https://docs.microtica.com/changelog#KL39_"
        },
        {
            title: "🔍 Announcing the improved pipeline logs",
            date: "Apr 19, 2023",
            description: <>
                We are excited to announce a significant improvement to our logs feature at Microtica! 🚀

                <br />
                <br />

                Our enhanced logs feature now provides a clear distinction between successful and error logs, making it easier for you to quickly identify and troubleshoot issues. We have also improved the organization of logs by showing steps in a more structured and easy-to-read format.

                <br />
                <br />

                We hope that you find our new logs improvements useful and would love to hear your feedback. Please share your thoughts with us, and let us know how we can further enhance your experience with Microtica.
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/OLAlxGj9WxDHH0XbiF-G9_image.png",
            link: "https://docs.microtica.com/changelog#ntgAU"
        },
        {
            title: "🗂️ n8n template with a default MySQL database",
            date: "Apr 19, 2023",
            description: <>
                We are excited to announce that Microtica now automatically includes MySQL as the default database for our <a href="https://app.microtica.com/templates/new?template=https://raw.githubusercontent.com/microtica/templates/master/n8n/.microtica/template.yaml">n8n template</a>! 🚀

                <br />
                <br />

                With this new addition, our users can now benefit from MySQL's excellent performance, reliability, and scalability without having to manually set up a database.

                <br />
                <br />

                By adding this option to our n8n template, we're giving our users more flexibility and choice to optimize their workflows and enhance their productivity.
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/qYp1pCbG08OscYS31FbcK_pasted-graphic-3.png",
            link: "https://docs.microtica.com/changelog#SpVBo"
        },
        {
            title: "📣 NextJS Template upgrade to v13",
            date: "Apr 7, 2023",
            description: <>
                We are pleased to announce that Microtica has upgraded our Next.js template from version 12 to version 13, which is a major release! 🚀

                <br />
                <br />

                With this upgrade, our users can take advantage of the latest features and improvements offered by Next.js. Version 13 introduces significant enhancements to performance, SEO, and accessibility, making it an excellent choice for building modern web applications.

                <br />
                <br />

                We're excited to see what our users can achieve with the upgraded Next.js template, and we're looking forward to hearing your feedback
            </>,
            link: "https://docs.microtica.com/changelog#0CuxX"
        },
        {
            title: "📢 Assign a custom domain for API-based components",
            date: "Mar 20, 2023",
            description: <>
                We've extended our custom domain feature to API-based components and resources in the platform. This specifically affects the <a href="https://app.microtica.com/templates/new?template=https://raw.githubusercontent.com/microtica/templates/master/aws-lambda-golang/.microtica/template.yaml">Lambda (Golang) template</a>.
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/-YHp_t2EPzeMH5clUs_G7_image.png",
            link: "https://docs.microtica.com/changelog#5OeRm"
        },
        {
            title: "🪲 Improvements and fixes",
            date: "Mar 13, 2023",
            description: <ul>
                <li>You're now able to get the logs <b>for a specific container</b> inside a Kubernetes pod</li>
                <li>Add property <b>MIC_CONTAINER_PORT</b> in the Environment Variables section (App Settings tab).</li>
                <li>Fixed a bug where the schema defaults were not pre-set when the resource was added to an environment.</li>
            </ul>,
            link: "https://docs.microtica.com/changelog#_Fq5Y"
        },
        {
            title: "📢 Introducing Appwrite production-ready template",
            date: "Feb 27, 2023",
            description: <>
                You can now deploy a production-ready Appwrite backend server on your AWS account following 3 simple steps.

                <br />
                <br />

                The <a href="https://app.microtica.com/templates/new?template=https://raw.githubusercontent.com/microtica/templates/master/appwrite/.microtica/template.yaml">Appwrite</a> template includes the following components that will be created in your environment:
                <br />
                <br />
                <ul>
                    <li><b>VPC</b> — VPC, subnets, and networking</li>
                    <li><b>EKS Cluster</b> - Container infrastructure based on Kubernetes, application load balancer, and persistent storage</li>
                    <li><b>Appwrite application</b> - the actual application server that will be deployed on your AWS account</li>
                </ul>

                You can find the <a href="https://docs.microtica.com/appwrite" target="_blank" rel="noreferrer">documentation for the Appwrite template here</a>.

                <br />
                <br />

                Happy deploying! 🚀
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/bPzJuuIicv5kkHbtPtVkg_image.png",
            link: "https://docs.microtica.com/changelog#GV-vp"
        },
        {
            title: "⚡ Google Signup",
            date: "Feb 23, 2023",
            description: <>
                Microtica now supports easy signup and sign-in with Google. This update was inspired by our growing user base that uses a Google-based private or company account. Additionally to a quick signup/sign-in with GitHub, you can now quickly authenticate with Google.
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/4F-zjIVMcHaRzbboB_Mb2_image.png",
            link: "https://docs.microtica.com/changelog#DtMzz"
        },
        {
            title: "🪲 Improvements and fixes",
            date: "Feb 20, 2023",
            description: <ul>
                <li>A new and improved template flow on the dedicated template page. You can preview the steps you have to perform to deploy the template on the cloud in a progress indicator on the left. We've added focus on the step in hand on the right.</li>
                <li>Upgraded the AWS EKS template to Kubernetes version 1.24.</li>
                <li>Made improvements to the Deployment status tags in the Deployments page and the Deployments tab to avoid inconsistencies.</li>
            </ul>,
            link: "https://docs.microtica.com/changelog#8usZq"
        },
        {
            title: "⚡ Optimized deployment pipelines",
            date: "Feb 15, 2023",
            description: <>
                We've been working on optimizing the integrated CI/CD pipelines, and are proud to share that our newest release offers <b>faster builds and deployments by 60%</b>. You can deliver product versions faster than ever!
            </>,
            link: "https://docs.microtica.com/changelog#vFlam"
        },
        {
            title: "⚡ Assign a custom domain on Applications and Resources",
            date: "Jan 13, 2023",
            description: <>
                With this update we are dramatically simplifying the custom domain setup for your applications and cloud resources.
                <br />
                <br />
                To assign a custom domain just go to your application or resource details and click on <b>Assign domain</b> button and then just follow the few simple steps.
                <br />
                <br />
                For detailed instructions follow the <a href="https://docs.microtica.com/assign-a-custom-domain" target="_blank" rel="noreferrer">Assign a Custom Domain</a> docs.
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/Ug59-RXL_mlI2PwwnMFkx_image.png?auto=format&ixlib=react-9.1.1&w=1153&h=517&dpr=1&q=75",
            link: "https://docs.microtica.com/changelog#Q2F6C"
        },
        {
            title: "⚡ Pipeline builds for your applications",
            date: "Dec 2, 2022",
            description: <>
                We are integrating application pipeline build and deployment executions in the Application Pipeline section.
                <br />
                <br />
                Now you will be able to navigate through pipeline execution history and go to a specific execution to see the logs and debug any potential issues with the application build or deployment process.
                <br />
                <br />
                Happy deploying! 🚀
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/929d4SKzVCLMiUJJHQnlc_image.png?auto=format&ixlib=react-9.1.1&w=2292&h=1406&dpr=2&q=50",
            link: "https://docs.microtica.com/changelog#VdJS9"
        },
        {
            title: "⚡ Resource Logs - follow your application logs directly from Microtica Portal",
            date: "Nov 18, 2022",
            description: <>
                Up until now when you had to debug an application deployed on AWS Fargate (Strapi, Medusa etc.) you had to login into AWS console and follow the logs from there.
                <br />
                <br />
                This was a big pain point for our customers so today we are happy to announce that we are releasing the integrated resource logs into our portal. With this change you no longer have to login into AWS console to debug your apps.
                <br />
                <br />
                Happy debugging! 🪲
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/weyXdide0QhT60LyZm3M7_screenshot-2022-11-18-at-100423.png?auto=format&ixlib=react-9.1.1&w=1135&h=568&dpr=1&q=75",
            link: "https://docs.microtica.com/changelog#5AHs3"
        },
        {
            title: "📢 Introducing Medusa Server Template",
            date: "Nov 11, 2022",
            description: <>
                You can now deploy a production-ready Medusa Server on your AWS account in a few simple steps.
                <br />
                <br />
                The template includes managed Postgres and Redis instances for high availability and scalability. Alternatively, for those who want a cheaper solution for development, the template offers a "development" mode that works with SQLite and "fake" Redis instance.
                <br />
                <br />
                The solution can be fully integrated with your existing Medusa Admin and Medusa Storefront.
                <br />
                <br />
                In the upcoming months we plan to release a bundle of all three Medusa components as one solution.
                <br />
                <br />
                Happy deploying! 🚀
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/Lbi3R1CEsgSpZOuNqU_sO_twitter-3.png?auto=format&ixlib=react-9.1.1&w=2400&h=1350&dpr=1&q=75",
            link: "https://docs.microtica.com/changelog#QCDpk"
        },
        {
            title: "🪲 Improvements and fixes",
            date: "Nov 4, 2022",
            description: <ul>
                <li>Improved navigation across the whole application with more accessible contextual actions</li>
                <li>Application monitoring and logs are now the default view in application details</li>
                <li>Improved the app list by providing more visibility of running, pending and failed pods</li>
            </ul>,
            link: "https://docs.microtica.com/changelog#bzFhx"
        },
        {
            title: "📢 Introducing Template Executions",
            date: "Oct 14, 2022",
            description: <>
                You can now follow template deployment executions in real-time even if you leave the template page. On this page you can follow the status as well as debug any potential errors during deployment.
                <br />
                <br />
                Happy deploying! 🚀
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/Qy3boTpDX5YA0lbD7tGTK_screenshot-2022-10-18-at-130343.png?auto=format&ixlib=react-9.1.1&w=1159&h=781&dpr=1&q=75",
            link: "https://docs.microtica.com/changelog#f8cJ1"
        },
        {
            title: "🪲 Improvements and fixes",
            date: "Oct 14, 2022",
            description: <ul>
                <li>Show commit details next to each resource and app</li>
                <li>Improve creating new EKS cluster flow when deploying app</li>
                <li>Show Kubernetes pod events for apps before the app logs become available</li>
                <li>Added welcome page for better user onboarding</li>
            </ul>,
            link: "https://docs.microtica.com/changelog#ec7EG"
        },
        {
            title: "⚡ Track your plan usage in real-time",
            date: "Oct 7, 2022",
            description: <>
                With this update, you can now track your environment, application and build minutes usage for a project in real-time.
                <br />
                <br />
                You can find the usage statistics under <i>Project Settings</i> &#8594; <i>Plan usage</i>.
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/AQOv3anTGYgxCKT_BbY7Q_screenshot-2022-10-10-at-131923.png?auto=format&ixlib=react-9.1.1&w=2294&h=952&dpr=2&q=50",
            link: "https://docs.microtica.com/changelog#xuKVv"
        },
        {
            title: "⚡ Deployment summary - review the configuration before deploying an App",
            date: "Sep 30, 2022",
            description: <>
                Now when deploying an app from a template, you will see a summary of the steps that will take in place. Here you can review the Git configuration, the environment in which the app will be deployed and on which cluster/AWS account the resources will be provisioned.
                <br />
                <br />
                Happy deploying! 🚀
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/lJOztTZWulZcAmO--D-Wq_screenshot-2022-09-30-at-124447.png?auto=format&ixlib=react-9.1.1&w=775&h=578&dpr=1&q=75",
            link: "https://docs.microtica.com/changelog#xuKVv"
        },
        {
            title: "🪲 Improvements and fixes",
            date: "Sep 29, 2022",
            description: <ul>
                <li>Fixed user with read-only access was not able to see the active pipeline builds</li>
                <li>Improve "Choose where to deploy" template step with better cluster selection logic</li>
                <li>After app deployment from template, navigate users to the app being deployed instead of apps list</li>
            </ul>,
            link: "https://docs.microtica.com/changelog#-2Obt"
        },
        {
            title: "📢 Introducing Framework Detection: deploy your apps much faster",
            date: "Sep 23, 2022",
            description: "You no longer have to have a Dockerfile file present in your repository to be able to deploy your existing applications. We now auto-detect your app framework and set a default configuration for building, packaging and deploying your app.",
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/7PGuYa0ggM6P8I7Pab3o2_image.png?auto=format&ixlib=react-9.1.1&w=1177&h=598&dpr=1&q=75",
            link: "https://docs.microtica.com/changelog#xqZ89"
        },
        {
            title: "⚡ Save massive a amount of time finding and fixing your failed deployments",
            date: "Sep 16, 2022",
            description: <>
                We as developers usually don't really pay attention when deployments go smoothly until something goes wrong. In these cases we have to go deeper and analyse where the problem occur and understand the root cause so we can quickly resolve it.
                <br />
                <br />
                With Deployments we are addressing this need to quickly and reliable understand what's going on with your current and past deployments.
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/fUUpwdGDOwaOesm7HfaR9_image.png?auto=format&ixlib=react-9.1.1&w=1166&h=629&dpr=1&q=75",
            link: "https://docs.microtica.com/changelog#N7oFV"
        },
        {
            title: "🪲 Improvements and fixes",
            date: "Sep 13, 2022",
            description: <ul>
                <li>When removing an AWS account, we now unlink the AWS account from the environments</li>
                <li>Fixed template execution logs not appear if execution failed</li>
                <li>Added a possibility to create additional cluster in templates page</li>
                <li>Fixed users unable to create new repo if the Git account has no other repos</li>
                <li>Removed 'Cancel Plan' button in Billing for FREE plan projects</li>
                <li>Add quick navigation button to logs in app overview page</li>

            </ul>,
            link: "https://docs.microtica.com/changelog#jFz86"
        },
        {
            title: "⚡ Automated validation of your Git webhooks permissions immediately in the first template step",
            date: "Sep 9, 2022",
            description: <>
                When deploying apps using templates, Microtica adds a Webhook to your repository for continuous build and deploy. Since each Git provider requires a specific minimum role to create a webhook, we are now making it immediately visible when the user has no sufficient rights to create a webhook.
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/lbpqtdwdS8RqsU_zzLntb_image.png?auto=format&ixlib=react-9.1.1&w=1252&h=386&dpr=1&q=75",
            link: "https://docs.microtica.com/changelog#GW9oz"
        },
        {
            title: "⚡ Create Kubernetes cluster without leaving the app deployment steps",
            date: "Sep 2, 2022",
            description: <>
                When deploying an app but you still don't have a Kubernetes cluster, instead of moving to a different page to create the cluster and then go back to the app, you can now provision a new cluster without leaving the app deployment page.
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/h0CckC_M3y9dC1FNoiQca_image.png?auto=format&ixlib=react-9.1.1&w=777&h=422&dpr=1&q=75",
            link: "https://docs.microtica.com/changelog#jgNaq"
        },
        {
            title: "⚡ Connect your AWS account with one click",
            date: "Aug 26, 2022",
            description: <>
                We are introducing a big improvement when connecting your AWS account by reducing the process to just one click.
                <br />
                <br />
                Now with just one click we initiate the CloudFormation stack that gives Microtica permissions to provision infrastructure on your AWS account.
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/CAm0Lk6eAZubKpUfhRbq1_image.png?auto=format&ixlib=react-9.1.1&w=537&h=240&dpr=1&q=75",
            link: "https://docs.microtica.com/changelog#6KvDq"
        },
        {
            title: "📢 Introducing AWS CodeCommit integration",
            date: "Aug 19, 2022",
            description: <>
                If you version your source code on CodeCommit, you can now connect the repo and get fully automate deployments directly from the Microtica portal.
                <br />
                <br />
                Happy deploying! 🚀
            </>,
            imageUrl: "https://archbee.imgix.net/pJMDLZwhjs4SKnSajyB-i/0uET7NYRkWnGe230IEpqZ_image.png?auto=format&ixlib=react-9.1.1&w=846&h=166&dpr=1&q=75",
            link: "https://docs.microtica.com/changelog#gBIqP"
        },
    ];

    return (
        <SideDrawer
            title="What's New"
            visible={visible}
            onClose={onClose}
            content={
                changelog.map((log, index) => (
                    <Fragment key={index}>
                        <Heading title={log.title} level={4} />
                        <p>
                            <i>{log.date}</i>
                        </p>
                        <p>
                            {log.description}
                        </p>
                        {
                            log.imageUrl &&
                            <Image width={"100%"} src={log.imageUrl} />
                        }
                        <p>
                            <br />
                            Read more in the <a href={log.link} target="_blank" rel="noreferrer">Changelog</a>
                        </p>
                        <Divider />
                    </Fragment>
                ))
            }
        />
    )
}

export default ChangelogDrawer;