import { Button, Card, Divider, Dropdown, Empty, List, notification, Space } from "antd";
import { useEffect, useState } from "react";
import { getCloudService, getEnvironmentService } from "../../backend";
import { MoreOutlined } from "@ant-design/icons";
import { useDoubleConfigurationModal } from "../../contexts/Modal";
import ConnectAwsAccountModal from "../../components/settings/ConnectAwsAccountModal";
import { trackSpecCloudAccountInit } from "../../backend/tracking/user-settings";
import ExplanationButton from "../../components/explanations/ExplanationButton";
import CloudAccountExplanation from "../../components/explanations/CloudAccountExplanation";
import ChooseAwsAccountModal from "../../components/modals/ChooseAwsAccountModal";
import { linkAwsAccountToStage } from "../../utils/aws-accounts";
import { Link, useParams } from "react-router-dom";
import UpdateAwsAccountModal from "../../components/modals/UpdateAwsAccountModal";
import CloudIcon from "../../components/CloudIcon";
import { CloudProvider } from "../../types";
import ConnectGcpAccountModal from "../../components/settings/ConnectGcpAccountModal";
import Paragraph from "antd/es/typography/Paragraph";

export default function ProjectIntegrationsCloud() {
    const { projectId } = useParams() as { projectId: string; };
    const [loadingAccounts, setLoadingAccounts] = useState(true);
    const [cloudAccounts, setCloudAccounts] = useState<{
        id: string;
        name: string;
        role: string;
        provider: CloudProvider;
        environments: { id: string; name: string }[]
    }[]>([]);
    const { open: openConfirmModal } = useDoubleConfigurationModal();
    const [connectAwsAccountModalVisible, setConnectAwsAccountModalVisible] = useState(false);
    const [connectGcpAccountModalVisible, setConnectGcpAccountModalVisible] = useState(false);
    const [chooseAwsAccountModal, setChooseAwsAccountModal] = useState(false);
    const [updateAwsAccountModalVisible, setUpdateAwsAccountModalVisible] = useState(false);
    const [cloudAccountId, setCloudAccountId] = useState<string>();
    const [cloudAccountToUpdate, setCloudAccountToUpdate] = useState<{ id: string; name: string; role: string; }>();

    useEffect(() => {
        loadCloudAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadCloudAccounts() {
        try {
            setLoadingAccounts(true);

            const [
                { data: { stages } },
                { data: { awsAccounts } },
                { data: { gcpAccounts } },
                // { data: { azureAccounts } }
            ] = await Promise.all([
                getEnvironmentService().getStages(projectId),
                getCloudService().getAwsAccounts(projectId),
                getCloudService().getGcpAccounts(projectId),
                // getCloudService().getAzureAccounts(projectId),
            ]);

            setCloudAccounts([
                ...awsAccounts.map(acc => ({
                    id: acc.id,
                    name: acc.accountName,
                    role: acc.iamRole,
                    provider: "aws",
                    environments: stages.filter(s => s.awsAccountId === acc.id).map(s => ({ id: s.id, name: s.name })) || []
                })),
                ...gcpAccounts.map(acc => ({
                    id: acc.gcpProjectId,
                    name: acc.projectName,
                    role: acc.targetServiceAccount,
                    provider: "google",
                    environments: stages.filter(s => s.gcpProjectId === acc.gcpProjectId).map(s => ({ id: s.id, name: s.name })) || []
                })),
                // ...azureAccounts.map(acc => ({
                //     id: acc.applicationId,
                //     name: acc.subscriptionName,
                //     role: acc.tenantId,
                //     provider: "azure",
                //     environments: stages.filter(s => s.subscriptionId === acc.subscriptionId).map(s => ({ id: s.id, name: s.name })) || []
                // }))
            ]);
        } catch (error) {

        } finally {
            setLoadingAccounts(false);
        }
    }

    async function handleDisconnectAccount(accountId: string, provider: string) {
        try {
            if (provider === "aws") {
                await getCloudService().deleteAwsAccount(accountId, projectId);
            } else if (provider === "google") {
                await getCloudService().deleteGcpAccount(accountId, projectId);
            }
            await loadCloudAccounts();
            notification.success({
                message: "Cloud account disconnected"
            });
        } catch (error: any) {
            notification.error({
                message: "Error disconnecting cloud account",
                description: error.response.data.message
            });
        }
    }

    function showDisconnectConfirm(accountId: string, provider: string, confirmation: string) {
        const providerName = provider === "aws" ? "AWS" : provider === "google" ? "GCP" : "Cloud";
        openConfirmModal({
            title: `Disconnect ${providerName} account?`,
            description: <>
                <p>By disconnecting this {providerName} account, Microtica won't be able to perform any infrastructure changes on your account. However you can continue managing the infrastructure from the {providerName} console.</p>
                <p>Any deployments on environments which are using this cloud account will be disabled until the account is connected again.</p>
                <p>Any cost reports regarding this cloud account will be no longer send.</p>
                <p>To reactivate the infrastructure management just reconnect this account.</p>
            </>,
            confirmation,
            okText: `Disconnect ${confirmation}`,
            cancelText: 'Cancel',
            onOk: () => {
                return handleDisconnectAccount(accountId, provider);
            }
        })
    }

    return (
        <>
            <Card>
                <Card.Meta
                    title={
                        <>
                            <span>
                                Connect Cloud Account
                            </span>
                            <ExplanationButton
                                content={<CloudAccountExplanation />}
                            />
                        </>
                    }
                />
                <Divider />
                <Space size="middle">
                    <Button onClick={() => {
                        setConnectAwsAccountModalVisible(true);
                        trackSpecCloudAccountInit("aws");
                    }}>
                        <Space>
                            <CloudIcon provider="aws" /> Connect AWS Account
                        </Space>
                    </Button>
                    <Button onClick={() => {
                        setConnectGcpAccountModalVisible(true);
                        trackSpecCloudAccountInit("google");
                    }}>
                        <Space>
                            <CloudIcon provider="google" /> Connect GCP account
                        </Space>
                    </Button>
                </Space>
            </Card>
            <br />

            <List
                bordered
                itemLayout="horizontal"
                className="ant-list-wrapper-bordered"
                dataSource={cloudAccounts}
                loading={loadingAccounts}
                header="Connected Cloud accounts"
                locale={{
                    emptyText:
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <>
                                    <div>
                                        No connected Cloud accounts yet!
                                    </div>
                                </>
                            }
                        />
                }}
                renderItem={item => (
                    <List.Item
                        style={{ background: "white" }}
                        actions={[
                            <Dropdown
                                placement="bottomRight"
                                trigger={["click"]}
                                menu={{
                                    items: [
                                        {
                                            key: "rename",
                                            label: item.provider === "aws" ? "Rename" : "Update",
                                            onClick: () => {
                                                setCloudAccountToUpdate(item);

                                                if (item.provider === "aws") {
                                                    setUpdateAwsAccountModalVisible(true);
                                                } else if (item.provider === "google") {
                                                    setConnectGcpAccountModalVisible(true);
                                                }
                                            }
                                        },
                                        {
                                            key: "disconnect",
                                            label: `Disconnect ${item.name}`,
                                            danger: true,
                                            onClick: () => showDisconnectConfirm(item.id, item.provider, item.name)
                                        }
                                    ]
                                }}
                            >
                                <Button icon={<MoreOutlined />} />
                            </Dropdown>
                        ]}
                    >
                        <List.Item.Meta
                            avatar={<CloudIcon provider={item.provider} />}
                            title={`${item.name} (${item.id})`}
                            description={
                                <>
                                    {
                                        item.environments.length === 0 ? "The account is currently not linked to any environment" :
                                            <>
                                                <Paragraph
                                                    ellipsis={{ rows: 1, expandable: true, symbol: "Show more" }}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <span className="gray-text">
                                                        Linked environments:&nbsp;
                                                    </span>
                                                    {
                                                        item.environments.map((e, index) => (
                                                            <>
                                                                <Link to={`/projects/${projectId}/environments/${e.id}`}>
                                                                    {e.name}
                                                                </Link>
                                                                {index !== item.environments.length - 1 && <Divider type="vertical" />}
                                                            </>
                                                        ))
                                                    }
                                                </Paragraph>
                                            </>
                                    }

                                </>
                            }
                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        />
                    </List.Item>
                )}
            />
            {
                // Workaround. The modal is not unmouting when closed so we need to unmount the whole component using 'modalVisible'
                // We have a process of periodic API calls which remain active even if when the modal closes
                connectAwsAccountModalVisible &&
                <ConnectAwsAccountModal
                    visible={connectAwsAccountModalVisible}
                    onOk={(newAwsAccountId) => {
                        loadCloudAccounts();
                        setConnectAwsAccountModalVisible(false);
                        setCloudAccountId(newAwsAccountId);
                        setChooseAwsAccountModal(true);
                    }}
                    onCancel={() => setConnectAwsAccountModalVisible(false)}
                />
            }
            {
                // This modal becomes active when the user connects an AWS account and now needs to select a stage and a region
                chooseAwsAccountModal &&
                <ChooseAwsAccountModal
                    selectedAccount={{ accountId: cloudAccountId, region: undefined }}
                    visible={chooseAwsAccountModal}
                    chooseStage={true}
                    onCancel={() => {
                        setChooseAwsAccountModal(false);
                        setCloudAccountToUpdate(undefined);
                    }}
                    onOk={async ({ accountId, region, envId }: { accountId: string, region: string, envId?: string }) => {
                        await linkAwsAccountToStage({
                            envId: envId!,
                            projectId: projectId!,
                            awsAccountId: accountId,
                            awsRegion: region
                        });
                        setChooseAwsAccountModal(false);
                        setCloudAccountToUpdate(undefined);
                    }}
                />
            }
            {
                updateAwsAccountModalVisible && cloudAccountToUpdate &&
                <UpdateAwsAccountModal
                    id={cloudAccountToUpdate.id}
                    name={cloudAccountToUpdate.name}
                    roleArn={cloudAccountToUpdate.role}
                    open={updateAwsAccountModalVisible}
                    onOk={() => {
                        loadCloudAccounts();
                        setUpdateAwsAccountModalVisible(false);
                        setCloudAccountToUpdate(undefined);
                    }}
                    onCancel={() => {
                        setUpdateAwsAccountModalVisible(false);
                        setCloudAccountToUpdate(undefined);
                    }}
                />
            }

            {
                connectGcpAccountModalVisible &&
                <ConnectGcpAccountModal
                    serviceAccountName={cloudAccountToUpdate?.name}
                    serviceAccountEmail={cloudAccountToUpdate?.role}
                    visible={connectGcpAccountModalVisible}
                    onOk={() => {
                        loadCloudAccounts();
                        setConnectGcpAccountModalVisible(false);
                        setCloudAccountToUpdate(undefined);
                    }}
                    onCancel={() => {
                        setConnectGcpAccountModalVisible(false);
                        setCloudAccountToUpdate(undefined);
                    }}
                />
            }
        </>
    )
}