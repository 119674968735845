import yaml from "js-yaml";
import { PageHeader } from "@ant-design/pro-layout";
import { useEffect, useState } from "react";
import { Template } from "../../types";
import { getTemplate } from "../../utils/get-template";
import { useQuery } from "../../contexts/Navigation";
import { Button, Space } from "antd";
import { Outlet, useParams } from "react-router";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import SkeletonInput from "antd/es/skeleton/Input";
import PageContentWrapper from "../../components/PageContentWrapper";


export default function TemplateRoot() {
    const query = useQuery();
    const { projectId } = useParams() as { projectId: string; }
    const [template, setTemplate] = useState<Template>();

    useEffect(() => {
        const load = async () => {
            const templateString = await getTemplate(query.get("template")!);
            setTemplate(yaml.load(templateString) as Template);
        }
        load();
    }, []);

    return (
        <PageContentWrapper
            size="large"
            header={
                <PageHeader>
                    {
                        template ?
                            <Space size="large">
                                <Link to={`/projects/${projectId}/templates`}>
                                    <Button icon={<ArrowLeftOutlined />} />
                                </Link>
                                <div>
                                    <div style={{ fontSize: "16px", }}>
                                        Deploy template
                                    </div>
                                    <div style={{ fontSize: "20px", fontWeight: 600 }}>
                                        {template?.name}
                                    </div>
                                </div>
                            </Space> :
                            <SkeletonInput />
                    }
                </PageHeader>
            }
        >
            <Outlet />
        </PageContentWrapper>
    )

}