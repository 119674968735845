import Modal from "antd/lib/modal/Modal";
import { Button, Form, Input, notification, Typography } from "antd";
import { useState } from "react";
import AWSIcon from "../../assets/aws-logo.svg";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { getCloudService } from "../../backend";
import { RequiredMark } from "antd/lib/form/Form";
import { useParams } from "react-router";

interface UpdateAwsAccountModalProps {
    id: string;
    name: string;
    roleArn: string;
    open?: boolean;
    onOk: () => void;
    onCancel: () => void;
}

const UpdateAwsAccountModal = ({
    id,
    name,
    roleArn,
    open,
    onOk,
    onCancel
}: UpdateAwsAccountModalProps) => {
    const { projectId } = useParams() as { projectId: string };
    const [form] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [updating, setUpdating] = useState(false);

    const handleUpdate = async () => {
        const values = await form.validateFields();

        try {
            setUpdating(true);
            await getCloudService().updateAwsAccount(
                id,
                projectId,
                {
                    id,
                    accountName: values.name,
                    externalId: values.externalId === "externalIdValue" ? undefined : values.externalId,
                    iamRole: values.roleArn
                }
            );
            onOk?.();
            notification.success({
                message: "Update succeeded"
            });
        } catch (error) {
            notification.error({
                message: "Update failed",
                description: (error as any).response?.data?.message
            });
        } finally {
            setUpdating(true);
        }
    }

    return (
        <Modal
            title={
                <div className="flex-align-center flex-justify-space-between">
                    Rename AWS Account
                    <img src={AWSIcon} style={{ height: "20px" }} alt="aws" />
                </div>
            }
            centered
            closable={false}
            open={open}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    type="primary"
                    key="submit"
                    loading={updating}
                    onClick={handleUpdate}
                >
                    Rename
                </Button>
            ]}
        >
            <br />
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    name,
                    roleArn,
                    externalId: "externalIdValue"
                }}
                requiredMark={requiredMark}
            >
                <Form.Item
                    name="name"
                    label="Name"
                    required
                    rules={[{ required: true, message: 'Please input the AWS account name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="roleArn"
                    label="Role ARN"
                    required
                    rules={[
                        { required: true, message: 'Please input the AWS account IAM role ARN!' }
                    ]}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    name="externalId"
                    label="External ID"
                    required
                    rules={[{ required: true, message: 'Please input the IAM role external ID!' }]}
                >
                    <Input.Password
                        disabled
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default UpdateAwsAccountModal;