import { Tag } from "antd";
import {
    SyncOutlined,
    CheckCircleFilled,
    ReloadOutlined,
    ExclamationCircleFilled,
    ClockCircleFilled,
    MinusCircleFilled,
    WarningFilled,
    StopOutlined
} from '@ant-design/icons';
import { StatusColor } from "../types";

const inProgressStates = [
    "CREATE_IN_PROGRESS",
    "UPDATE_IN_PROGRESS",
    "UPDATE_COMPLETE_CLEANUP_IN_PROGRESS",
    "UPDATE_ROLLBACK_COMPLETE_CLEANUP_IN_PROGRESS",
    "UPDATE_ROLLBACK_IN_PROGRESS",
    "DELETE_IN_PROGRESS",
    "ROLLBACK_IN_PROGRESS"
];

export function isResourceDeployed(status: string = "") {
    return [
        "DEPLOYED",
        "CREATE_COMPLETE",
        "UPDATE_COMPLETE",
        "UPDATE_ROLLBACK_COMPLETE",
        "UPDATE_IN_PROGRESS",
        "UPDATE_COMPLETE_CLEANUP_IN_PROGRESS",
        "UPDATE_ROLLBACK_COMPLETE_CLEANUP_IN_PROGRESS",
        "UPDATE_ROLLBACK_IN_PROGRESS",
        "ROLLBACK_IN_PROGRESS",
        "UPDATE_FAILED"
    ].includes(status);
}

export function getResourceStatusColor(status?: string) {
    if (!status) {
        return StatusColor.Default;
    }
    const activeStates = [
        "CREATE_COMPLETE",
        "UPDATE_COMPLETE",
        "UPDATE_ROLLBACK_COMPLETE"
    ];

    const failedStates = [
        "UPDATE_FAILED",
        "CREATE_FAILED",
        "DELETE_FAILED",
        "ROLLBACK_FAILED",
        "UPDATE_ROLLBACK_FAILED"
    ];

    return activeStates.includes(status) ?
        StatusColor.Active :
        inProgressStates.includes(status) ?
            StatusColor.Processing :
            failedStates.includes(status) ?
                StatusColor.Failed :
                StatusColor.Default
}

export function isDeploymentStatusInProgress(status: string) {
    return inProgressStates.includes(status);
}

export function mapDeploymentStatus(status?: string) {
    if (!status) {
        return {
            status: "Not deployed"
        };
    }
    else if ([
        "ROLLBACK_COMPLETE",
        "UPDATE_ROLLBACK_COMPLETE"
    ].includes(status)) {
        return {
            icon: <ExclamationCircleFilled />,
            color: "warning",
            status: "Rollbacked"
        };
    }
    else if (["DEPLOY_QUEUED", "QUEUED"].includes(status)) {
        return {
            icon: <ClockCircleFilled />,
            status: "Queued"
        }
    }
    else if (status === "DELETE_COMPLETE") {
        return {
            icon: <MinusCircleFilled />,
            status: "Deleted"
        };
    }
    else if (status === "NOT_DEPLOYED") {
        return {
            status: "Not deployed"
        };
    }
    else if ([
        "DEPLOYED",
        "CREATE_COMPLETE",
        "UPDATE_COMPLETE"
    ].includes(status)) {
        return {
            icon: <CheckCircleFilled />,
            color: "success",
            status: "Deployed"
        }
    }
    else if ([
        "CREATE_IN_PROGRESS"
    ].includes(status)) {
        return {
            icon: <ReloadOutlined spin />,
            color: "processing",
            status: "Deploying"
        }
    }
    else if ([
        "UPDATE_IN_PROGRESS",
        "UPDATE_COMPLETE_CLEANUP_IN_PROGRESS"
    ].includes(status)) {
        return {
            icon: <ReloadOutlined spin />,
            color: "processing",
            status: "Deploying"
        }
    } else if (status === "DELETE_IN_PROGRESS") {
        return {
            icon: <ReloadOutlined spin />,
            color: "error",
            status: "Deleting"
        }
    } else if ([
        "ROLLBACK_IN_PROGRESS",
        "UPDATE_ROLLBACK_COMPLETE_CLEANUP_IN_PROGRESS",
        "UPDATE_ROLLBACK_IN_PROGRESS"
    ].includes(status)) {
        return {
            icon: <SyncOutlined spin />,
            color: "processing",
            status: "Rollbacking"
        };
    } else if ([
        "FAILED",
        "UPDATE_FAILED",
        "CREATE_FAILED",
        "DELETE_FAILED",
        "ROLLBACK_FAILED",
        "UPDATE_ROLLBACK_FAILED",
    ].includes(status)) {
        return {
            icon: <WarningFilled />,
            color: "error",
            status: "Failed"
        }
    } else if (status === "DEPLOY_CANCELED") {
        return {
            icon: <StopOutlined />,
            status: "Canceled"
        }
    } else {
        return {
            status: "Unknown status"
        }
    }
}

export default function DeploymentStatusTag(props: { status?: string, size?: "small" | "large" }) {
    const className = props.size === "large" ? "no-margin ant-tag-lg" : "no-margin";
    const { status, icon, color } = mapDeploymentStatus(props.status);

    return (
        <Tag className={className} icon={icon} color={color}>
            {status}
        </Tag>
    );
};