import { Button, Card, Col, Divider, Form, Input, notification, Row, Space } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useOutletContext, useParams } from "react-router";
import { getEnvironmentService } from "../../backend";
import { AwsRegionType, IEnvDetails } from "../../types";
import ChooseGcpAccountForm from "../../components/ChooseGcpAccountForm";
import ChooseAwsAccountForm from "../../components/ChooseAwsAccountForm";

export default function EnvironmentSettingsGeneral() {
    const [envForm] = Form.useForm();
    const { projectId, envId } = useParams() as { projectId: string; envId: string; };
    const { env } = useOutletContext<{ env: IEnvDetails }>();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [loadingUpdateEnv, setLoadingUpdateEnv] = useState(false);


    async function handleUpdate(values: { name: string, description: AwsRegionType }) {
        try {
            setLoadingUpdateEnv(true);
            await getEnvironmentService().updateStage(
                envId,
                projectId,
                {
                    name: values.name,
                    description: values.description
                }
            );
            env.onChange?.();
            notification.success({ message: `${values.name} environment has been updated sucessfully.` });
        } catch (error: any) {
            notification.error({
                message: "Update environment defails failed",
                description: error.response.data.message
            });
        } finally {
            setLoadingUpdateEnv(false);
        }
    }

    return (
        <Space direction="vertical" className="full-width">
            {/* General */}
            <Card bordered>
                <Card.Meta
                    title="General settings"
                    description="Details about your environment"
                />
                <Divider />
                <Form
                    form={envForm}
                    layout="vertical"
                    initialValues={{
                        name: env.name,
                        description: env.description,
                    }}
                    onValuesChange={() => { }}
                    requiredMark={requiredMark}
                    wrapperCol={{}}
                    onFinish={handleUpdate}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        required
                        rules={[{ required: true, message: 'Please select cloud account!' }]}
                    >
                        <Input placeholder="DEV" style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        required
                        rules={[{ required: true, message: 'Please select cloud region!' }]}
                    >
                        <TextArea placeholder="My production environment" maxLength={200} style={{ height: 100, marginBottom: "20px" }} />
                    </Form.Item>
                    <Divider />
                    <Row style={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <Col span={24} style={{ textAlign: "right" }}>
                            <Button htmlType="submit" loading={loadingUpdateEnv}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>

            <br />

            {/* Cloud account */}
            <Card bordered>
                <Card.Meta
                    title="Cloud account"
                    description="Environment components and apps will be deployed on the selected cloud account"
                />

                <Divider />

                {
                    env.cloudProvider === "aws" ?
                        <ChooseAwsAccountForm env={env} /> :
                        env.cloudProvider === "google" ?
                            <ChooseGcpAccountForm env={env} /> : undefined
                }
            </Card>
        </Space>
    );
}