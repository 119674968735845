import { Alert, Button, Card, Divider, Modal, Space, Steps, notification } from "antd";
import { useEffect, useState } from "react";
import { getPipelinesService, getProjectService } from "../../backend";
import { DeploymentState, TemplateType, PipelineSpec, FormState, EnvironmentDetails, Template, Dictionary } from "../../types";
import PipelineStatusTag from "../PipelineStatusTag";
import yaml from "js-yaml";
import { useNavigate } from "react-router-dom";
import TemplatePipelineStatusDrawer from "./TemplatePipelineStatusDrawer";
import { CloseCircleOutlined, CrownOutlined, LoadingOutlined } from "@ant-design/icons";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { currentTemplateStepState, templateState } from "../../recoil/template";
import { useCardClassName } from "../../contexts/Helpers/helpers";
import DeployTemplateSummary, { DeployTemplateSummaryProps } from "./DeployTemplateSummaryModule";
import { PipelineBuildDetailsStagesStatusEnum } from "@microtica/ms-ap-sdk";
import { useCurrentProject } from "../../contexts/Project";
import { getDetailedPaymentPlan } from "../../utils/payment-plans";
import { PricingPlanNames } from "../../enums/enums";
import { trackDeployTemplateUpgradeCancel } from "../../backend/tracking/deployment";
import BuildDetailsDrawer from "../BuildDetailsDrawer";

const stepStatus: { [status: string]: "wait" | "process" | "finish" | "error" } = {
    PENDING: "wait",
    RUNNING: "process",
    SUCCEEDED: "finish",
    DONE: "finish",
    FAILED: "error"
}
interface DeployTemplateModuleProps {
    templateType: TemplateType;
    env?: EnvironmentDetails;
    templateExecutionId?: string;
    buildId?: string;
    appName?: string;
    creatingCluster: boolean;
    clusterAppName?: string;
    onDone?: () => void
    onDeploy: () => void;
    disabled?: boolean;
    addBackButton?: boolean;
    summary?: DeployTemplateSummaryProps;
    template?: Template;
}

const DeployTemplate = ({
    templateType,
    env,
    templateExecutionId,
    buildId,
    appName,
    creatingCluster,
    clusterAppName,
    onDone,
    onDeploy,
    disabled,
    addBackButton,
    summary,
    template
}: DeployTemplateModuleProps) => {
    const navigate = useNavigate();
    const currentProject = useRecoilValue(currentProjectState);
    const { updateCurrentProject } = useCurrentProject();
    const [shouldInitiateTrial, setShouldInitiateTrial] = useState<boolean>();
    const [currentStep, setCurrentStep] = useState<{ step: number, status: string }>({ step: -1, status: "QUEUED" });
    const [visibleLogs, setVisibleLogs] = useState(false);
    const [selectedPipeline, setSelectedPipeline] = useState<{ id: string; name: string; buildId: string; errorMessage?: string; }>();
    const [environmentTemplateDone, setEnvironmentTemplateDone] = useState<boolean>(false);
    const [currentTemplateState, setCurrentTemplateState] = useRecoilState(templateState);
    const currentTemplateStep = useRecoilValue(currentTemplateStepState);
    const [deploymentState, setDeploymentState] = useState<DeploymentState>({
        stages: [],
        status: "QUEUED"
    });
    const [deploymentPlaceholder, setDeploymentPlaceholder] = useState<any>(
        <div>
            Start the deployment for your {templateType === "environment" ? "environment" : "application"}
        </div>
    );


    const cardClassName = useCardClassName(
        !!disabled,
        [disabled, currentTemplateStep]
    );

    useEffect(() => {
        if (templateExecutionId && buildId) {
            loadData();
            const interval = setInterval(async () => {
                if (templateExecutionId && buildId) {
                    const status = await loadData();
                    if (["SUCCEEDED", "FAILED"].includes(status)) {
                        clearInterval(interval);
                    }
                }
            }, 5000);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateExecutionId, buildId]);

    useEffect(() => {
        setDeploymentPlaceholder(disabled ?
            <div>Start the deployment for your {templateType === "environment" ? "environment" : "application"}</div> :
            <></>
        );
        handleSetTemplateState(disabled ? "disabled" : "editing");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    useEffect(() => {
        if (environmentTemplateDone) {
            onDone?.();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [environmentTemplateDone]);

    useEffect(() => {
        setShouldInitiateTrial(["Strapi Serverless", "Medusa Server", "n8n.io"].includes(template?.name || "template") && currentProject.paymentPlan?.id === "FREE");
    }, []);

    const handleSetTemplateState = (mode: FormState) => {
        setCurrentTemplateState({
            ...currentTemplateState,
            deploy: mode
        });
    }

    const handleShowLogs = async (pipelineName: string, pipelineId: string) => {
        const { data: { latestBuild } } = await getPipelinesService()
            .getPipelineBuilds(currentProject!.id, pipelineId)
            .then(res => {
                if (res.data.builds.length === 0) {
                    throw new Error("No builds");
                } else {
                    return res;
                }
            })
            .catch(async _ => {
                return getPipelinesService().getPipelineBuilds(currentProject!.id, templateExecutionId!);
            });

        setSelectedPipeline({
            id: latestBuild?.pipelineId!,
            name: pipelineName,
            buildId: latestBuild?.id!,
            errorMessage: latestBuild?.errorMessage
        });
        setVisibleLogs(true);
    }

    const loadData = async () => {
        const { data: templateExecution } = await getPipelinesService().getPipelineBuild(
            currentProject!.id,
            templateExecutionId!,
            buildId!
        ).catch(err => {
            // Ignore error 404 because it will always happen (only once)
            if (err.response.data.code !== 404) {
                notification.error({
                    message: "An error occured",
                    description: err.response.data.message
                });
            }
            throw err;
        })

        const pipelineSpec = yaml.load(templateExecution.microticaYaml) as PipelineSpec;

        // if execution status transitions, remove the placeholder text
        if (templateExecution.status !== "QUEUED") {
            setDeploymentPlaceholder(<></>)
        }

        const newDeploymentState = {
            loading: ["QUEUED", "PENDING", "RUNNING"].includes(templateExecution.status),
            status: templateExecution.status,
            stages: (templateExecution.stages || []).map(stage => ({
                name: stage.name,
                current: stage.status === "RUNNING" || stage.status === "FAILED",
                status: stage.status,
                result:
                    <>
                        {
                            templateExecution.steps?.filter(step => step.stage === stage.name)
                                .map(step => {
                                    const { type, pipeline } = pipelineSpec.steps[step.name];
                                    return (
                                        <div className="flex-justify-space-between" key={step.name}>
                                            <Space>
                                                <PipelineStatusTag status={step.status} />
                                                <div>{step.name}</div>
                                            </Space>
                                            {
                                                type === "trigger-pipeline" && pipeline &&
                                                <Button
                                                    type="link"
                                                    style={{ paddingRight: 0 }}
                                                    onClick={() => {
                                                        handleShowLogs(step.name, pipeline)
                                                    }}
                                                >
                                                    View Logs
                                                </Button>
                                            }
                                        </div>
                                    );
                                })
                        }
                    </>
            })),
            error: templateExecution.errorMessage
        };

        if (
            templateType === "environment" &&
            templateExecution.status === "SUCCEEDED"
        ) {
            newDeploymentState.stages.push({
                name: "Done",
                current: true,
                status: PipelineBuildDetailsStagesStatusEnum.SUCCEEDED,
                result:
                    <div className="flex-justify-space-between">
                        <Space>
                            <div>Your environment is being deployed</div>
                        </Space>
                        {
                            <a href={`/projects/${currentProject.id}/pipelines`} target="_blank" rel="noreferrer">
                                <Button
                                    type="link"
                                    style={{ paddingRight: 0 }}
                                >
                                    View deployment
                                </Button>
                            </a>
                        }
                    </div>
            });
            setEnvironmentTemplateDone(true);
        } else if (
            templateType === "cluster" &&
            templateExecution.status === "SUCCEEDED"
        ) {
            const serviceName = pipelineSpec.steps[appName!].service;
            newDeploymentState.stages.push({
                name: "Done",
                current: true,
                status: PipelineBuildDetailsStagesStatusEnum.SUCCEEDED,
                result:
                    <div className="flex-justify-space-between">
                        <Space>
                            <div>Your application has been deployed</div>
                        </Space>
                        {
                            <Button
                                type="link"
                                style={{ paddingRight: 0 }}
                                onClick={() => !creatingCluster ?
                                    navigate(`/projects/${currentProject.id}/environments/${env?.id}/components/${env?.clusterId}/apps/${env?.clusterNamespace}/${serviceName}`) :
                                    navigate(`/projects/${currentProject.id}/environments/${env?.id}/components/${env?.id}-${clusterAppName}/apps/microtica/${serviceName}`)
                                }
                            >
                                View application
                            </Button>
                        }
                    </div>
            });
        }
        setDeploymentState(newDeploymentState);

        const currentStageIndex = newDeploymentState.stages.findIndex(stage => stage.current);
        setCurrentStep(currentStageIndex !== -1 ? { step: currentStageIndex, status: newDeploymentState.stages[currentStageIndex].status } : { step: -1, status: "QUEUED" });


        return templateExecution.status;
    }

    const handleActiveStepChange = () => {
        const step = templateType === "environment" ? "env" : "cluster";
        setCurrentTemplateState(currentTemplateState => ({
            ...currentTemplateState,
            [step]: "editing"
        }));
    }

    const isTrialEligible = (planId: string) => {
        return currentProject?.freeTrials && !(currentProject.freeTrials as Dictionary<boolean>)[planId];
    }

    const showSelectPlanConfirm = (plan: { id: string; name: string; priceId: string; price: string }) => {
        return new Promise((resolve) => {
            Modal.confirm({
                title: `Activate ${plan.name} plan`,
                content:
                    <Space direction='vertical'>
                        <div>
                            The {template?.name || "template"} is only available in the {plan.name} pricing plan.
                            You are about to activate the Starter plan that will cost {plan.price} per month.
                        </div>
                        {
                            isTrialEligible(plan.id) ?
                                <div>
                                    <b>7 day free trial</b> will be applied.
                                </div> :
                                <div>
                                    Your credit card will be <b>charged immediately</b> because you've already used your free trial period.
                                </div>
                        }
                    </Space>,
                okText: `Activate ${plan.name} plan`,
                centered: true,
                async onOk() {
                    await getProjectService().changeProjectSubscription(
                        currentProject!.id,
                        {
                            paymentPlanId: plan.id,
                            priceId: plan.priceId
                        }
                    );
                    await updateCurrentProject(currentProject.id);

                    return resolve(undefined);
                },
                onCancel() {
                    setDeploymentState({
                        stages: [],
                        status: "QUEUED",
                        loading: false
                    });
                    trackDeployTemplateUpgradeCancel(env?.id!, template?.name!)
                }
            });
        })
    }

    const handleDeploy = async () => {
        // reset the deployment state to the initial values
        setDeploymentState({
            stages: [],
            status: "QUEUED",
            loading: true
        });

        if (shouldInitiateTrial) {
            const starterPlan = await getDetailedPaymentPlan(PricingPlanNames.STARTER);
            await showSelectPlanConfirm({
                id: starterPlan.id,
                name: starterPlan.name,
                priceId: starterPlan.priceId,
                price: starterPlan.price!
            });
        }

        setDeploymentPlaceholder(
            <Space>
                <LoadingOutlined spin />
                <div>Preparing your {templateType === "environment" ? "environment" : "application"} for deployment...</div>
            </Space>
        );

        onDeploy();
    }


    return (
        <Card bordered className={cardClassName}>
            <Card.Meta title="Deploy" />
            <Divider />
            {
                summary &&
                <DeployTemplateSummary
                    templateType={summary?.templateType}
                    gitAccount={summary!.gitAccount}
                    environment={summary!.environment}
                    appName={summary!.appName}
                    framework={summary!.framework}
                    templateRepositoryUrl={summary!.templateRepositoryUrl}
                    visible={!disabled && !deploymentState.stages?.length}
                    shouldCreateCluster={summary!.shouldCreateCluster}
                    clusterAppName={summary!.clusterAppName}
                    footerMessage={deploymentPlaceholder}
                />
            }
            <Steps
                direction="vertical"
                size="small"
                current={currentStep.step}
                status={stepStatus[currentStep.status]}
                items={
                    deploymentState.stages?.map(step => ({
                        title: step.name,
                        description: step.result
                    }))
                }
            />
            {
                deploymentState.error &&
                <>
                    <br />
                    <Alert
                        message={
                            <>
                                <CloseCircleOutlined style={{ color: "red" }} /> {deploymentState.error}
                            </>
                        }
                        type="error"
                    />
                </>
            }
            <Divider />
            <div className={!!addBackButton ? "flex-justify-space-between" : "flex-justify-space-between flex-align-center"}>
                {!!addBackButton ?
                    <Button
                        disabled={deploymentState?.loading || ["SUCCEEDED", "FAILED"].includes(deploymentState.status)}
                        onClick={handleActiveStepChange}>
                        Back
                    </Button> : null
                }
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleDeploy}
                    loading={deploymentState?.loading}
                    disabled={disabled || deploymentState?.loading || ["SUCCEEDED", "FAILED"].includes(deploymentState.status)}
                >
                    {
                        deploymentState?.loading ? "Deploying" : shouldInitiateTrial ? <><CrownOutlined /> Deploy</> : "Deploy"
                    }
                </Button>
            </div>
            {
                visibleLogs &&
                <BuildDetailsDrawer
                    type={"deploy"}
                    projectId={currentProject.id}
                    pipelineId={selectedPipeline?.id!}
                    buildId={selectedPipeline?.buildId!}
                    open={visibleLogs}
                    onClose={() => setVisibleLogs(false)}
                />
            }
        </Card>
    );
}

export default DeployTemplate;