import { Button, Card, Col, Divider, Form, Input, notification, Row, Space } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { getProjectService } from "../../backend";
import { IProjectDetails } from "../../types";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDoubleConfigurationModal } from "../../contexts/Modal";
import { trackRemoveProject } from "../../backend/tracking/user-settings";
import { useRecoilState } from "recoil";
import { currentProjectState, projectsState } from "../../recoil/project";
import { useCurrentProject } from "../../contexts/Project";
import { getUser, setProjectLocalStorage } from "../../utils/local-storage";
import { handleSetMixpanelUserGroups } from "../../backend/tracking";

export default function ProjectSettingsGeneral() {
    const [form] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>('optional');
    const { projectDetails } = useOutletContext<{ projectDetails: IProjectDetails }>();
    const [loadingUpdateProject, setLoadingUpdateProject] = useState(false);
    const [loadingDeleteProject, setLoadingDeleteProject] = useState(false);
    const { open: openConfirmModal } = useDoubleConfigurationModal();
    const [projects, setProjects] = useRecoilState(projectsState);
    const [currentProject, setCurrentProject] = useRecoilState(currentProjectState);
    const { updateCurrentProject } = useCurrentProject();

    async function handleUpdateProject(values: { name: string; description: string }) {
        try {
            setLoadingUpdateProject(true);
            await getProjectService().updateProject(
                projectDetails?.id,
                {
                    name: values.name,
                    description: values.description
                }
            );
            setCurrentProject({
                ...currentProject,
                name: values.name,
                description: values.description
            });
            setProjects(projects.map(p => {
                if (p.id !== currentProject.id) {
                    return p;
                }
                return {
                    ...p,
                    name: values.name,
                    description: values.description
                }
            }));
            setProjectLocalStorage({
                ...currentProject,
                name: values.name,
                description: values.description
            });
            notification.success({ message: `Project '${values.name}' has been updated successfully.'` });
        } catch (error: any) {
            notification.error({
                message: "Project update failed",
                description: error.response.data.message
            });
        } finally {
            setLoadingUpdateProject(false);
        }
    }

    async function handleDelete() {
        try {
            setLoadingDeleteProject(true);
            await getProjectService().deleteProject(projectDetails?.id, window.location.origin);
            notification.success({ message: "Project removed" });
            trackRemoveProject(projectDetails?.name, projectDetails?.id);

            const currentProjects = [...projects.filter(p => p.id !== projectDetails?.id)];
            setProjects(currentProjects);
            if (currentProjects.length !== 0) {
                // Set first project as the current project
                await updateCurrentProject(currentProjects[0].id);
                (window as Window).location = `/projects/${currentProjects[0].id}`;
            } else {
                // Create a new project on a free plan
                const [username] = getUser().email.split("@");
                const projectName = `${username}'s project`;
                const projectDescription = `${username}'s default project`;
                const { data: { id } } = await getProjectService().createProject({
                    name: projectName,
                    description: projectDescription,
                    paymentPlanId: "FREE"
                });
                await updateCurrentProject(id);
                handleSetMixpanelUserGroups([id]);
                (window as Window).location = `/projects/${id}`;
            }
        } catch (error: any) {
            notification.error({
                message: "Project delete failed",
                description: error.response.data.message
            });
        } finally {
            setLoadingDeleteProject(false);
        }
    }

    function showDeleteConfirm(confirmation: string) {
        openConfirmModal({
            title: "Delete Project?",
            description: <>
                The project will be permanently deleted, including its environments, apps and cloud resources.
            </>,
            confirmation,
            alert: <b>This action cannot be undone.</b>,
            okText: "Delete Project",
            cancelText: 'Cancel',
            onOk: () => {
                return handleDelete();
            }
        });
    }

    return (
        <>
            <Card bordered>
                <Card.Meta
                    title="General settings"
                    description="Project details."
                />
                <Divider />
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        name: projectDetails?.name,
                        description: projectDetails?.description
                    }}
                    requiredMark={requiredMark}
                    onFinish={handleUpdateProject}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        required
                        rules={[{ required: true, message: 'Please enter project name!' }]}
                    >
                        <Input placeholder="My project" style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        required
                        rules={[{ required: true, message: 'Please enter project description!' }]}
                    >
                        <TextArea placeholder="This is my app project" showCount maxLength={200} style={{ height: 100, marginBottom: "20px" }} />
                    </Form.Item>
                    <Divider />
                    <Row style={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <Col span={24} style={{ textAlign: "right" }}>
                            <Button htmlType="submit" loading={loadingUpdateProject}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <br />
            <Card bordered>
                <Card.Meta
                    title={
                        <Space>
                            <ExclamationCircleOutlined style={{ color: 'red' }} />
                            Delete Project
                        </Space>
                    }
                    description="Project will be permanently deleted, including its environments, apps and cloud resources."
                />
                <Divider />
                <Row>
                    {currentProject!.paymentPlan!.id === "FREE" &&
                        <Col span={24}>
                            <Space>
                                <ExclamationCircleOutlined style={{ color: 'red' }} />
                                A project on a Free plan can not be deleted
                            </Space>
                        </Col>
                    }
                    <Col span={24} style={{ textAlign: "right" }}>
                        <Button
                            danger
                            type="primary"
                            loading={loadingDeleteProject}
                            disabled={currentProject!.paymentPlan!.id === "FREE"}
                            onClick={() => showDeleteConfirm(projectDetails?.name)}
                        >
                            Delete
                        </Button>
                    </Col>
                </Row>
            </Card>
        </>
    )
}