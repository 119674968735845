import { ListSchedulesResponseRules } from "@microtica/ms-cloud-cost-optimizer-sdk";
import { NamePath } from "antd/lib/form/interface";
import { ISavingScheduleAggregatedUtilization } from "../types";

// interfaces
interface ConvertToCronRequest {
    localTimeZone: string,
    getFieldValue: (name: NamePath) => any,
    setCronExpression: React.Dispatch<React.SetStateAction<{
        startExpression: string;
        stopExpression: string;
    } | undefined>>,
    setExpectedMonthlySavings: (totalMonthlySavingHours: number) => void
}

// constants
const NUMBER_OF_WEEKS = 4;
export const TOTAL_HOURS_IN_MONTH = 24 * 7 * NUMBER_OF_WEEKS; // 24 hours * 7 days * 4 weeks
export const WEEK_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export const HOURS = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
// (4weeks * ((5days * 14h) + (2days * 24h))) / (4weeks * 7days * 24h) = 70%
export const ESTIMATED_SAVINGS_COEFICIENT = 0.7;

// helper functions
const getDaysBefore = (weekDays: string[]): string[] => {
    const dayBeforeMap: { [day: string]: string } = {
        "Monday": "Sunday",
        "Tuesday": "Monday",
        "Wednesday": "Tuesday",
        "Thursday": "Wednesday",
        "Friday": "Thursday",
        "Saturday": "Friday",
        "Sunday": "Saturday",
    };

    const days: string[] = weekDays.map(day => {
        if (!dayBeforeMap.hasOwnProperty(day)) {
            throw new Error("Invalid day");
        }
        return dayBeforeMap[day];
    });
    return days;
}

const getDaysAfter = (weekDays: string[]): string[] => {
    const dayAfterMap: { [day: string]: string } = {
        "Monday": "Tuesday",
        "Tuesday": "Wednesday",
        "Wednesday": "Thursday",
        "Thursday": "Friday",
        "Friday": "Saturday",
        "Saturday": "Sunday",
        "Sunday": "Monday",
    };
    const days: string[] = weekDays.map(day => {
        if (!dayAfterMap.hasOwnProperty(day)) {
            throw new Error("Invalid day");
        }
        return dayAfterMap[day];
    });
    return days;
}

const cronExpressionTimeZoneMoreThanZero = (zoneOffset: number, hour: number, selectedDays: string[]): string => {
    if ((hour - zoneOffset) < 0) {
        return `cron(0 ${24 + (hour - zoneOffset)} ? * ${getDaysBefore(selectedDays).join(",")} *)`;
    } else {
        return `cron(0 ${hour - zoneOffset} ? * ${selectedDays.join(",")} *)`;
    }
}

// it generates Cron expression when Timezone is less then zero
const cronExpressionTimeZoneLessThanZero = (zoneOffset: number, hour: number, selectedDays: string[]): string => {
    if ((hour - zoneOffset) > 23) {
        return `cron(0 ${0 + Math.abs(24 - (hour - zoneOffset))} ? * ${getDaysAfter(selectedDays).join(",")} *)`;
    } else {
        return `cron(0 ${hour - zoneOffset} ? * ${selectedDays.join(",")} *)`
    }
}

const calculateHourInTimeZone = (hour: string, timezone: string, days: string[]): {
    hour: number;
    days: string[];
} => {
    const timeZoneOffset = parseInt(timezone.split("UTC")[1] || "0")

    if ((parseInt(hour) + timeZoneOffset) < 0) {
        return { hour: 24 + ((parseInt(hour) + timeZoneOffset)), days: getDaysBefore(days) }
    } else if ((parseInt(hour) + timeZoneOffset) > 23) {
        return { hour: Math.abs(24 - (parseInt(hour) + timeZoneOffset)), days: getDaysAfter(days) }
    } else {
        return { hour: (parseInt(hour) + timeZoneOffset), days }
    }
}

export const calculateUtilization = (schedule: ListSchedulesResponseRules, localTimeZone: string): ISavingScheduleAggregatedUtilization => {
    let [, startHour, , , utilizedDays] = schedule.startScheduleExpression.split(" ");
    let [, stopHour] = schedule.stopScheduleExpression.split(" ");

    utilizedDays = calculateHourInTimeZone(startHour, localTimeZone, utilizedDays.split(",")).days.join(",");
    const startHourInTimeZone = calculateHourInTimeZone(startHour, localTimeZone, utilizedDays.split(",")).hour;
    const stopHourInTimeZone = calculateHourInTimeZone(stopHour, localTimeZone, utilizedDays.split(",")).hour;

    const utilizedDaysCount = utilizedDays.split(",").length;
    const utilizedHours = 24 - (stopHourInTimeZone - startHourInTimeZone);
    const totalUtilizedHours = ((utilizedHours * utilizedDaysCount) + (24 * (7 - utilizedDaysCount))) * NUMBER_OF_WEEKS;
    const estimatedSavingPercentage = Math.round((totalUtilizedHours / TOTAL_HOURS_IN_MONTH) * 100);

    const dailyUtilizedHours = WEEK_DAYS.map(day => {
        if (utilizedDays.includes(day)) {
            return utilizedHours;
        } else {
            return 24;
        }
    });

    return {
        totalUtilizedHours,
        estimatedSavingPercentage,
        dailyUtilizedHours,
        startHourInTimeZone,
        stopHourInTimeZone,
        utilizedDays: utilizedDays.split(",")
    }
}

export const getLocalTimeZone = (): string => {
    const zoneOffset = new Date().getTimezoneOffset();

    if (zoneOffset > 0) {
        return `UTC-${zoneOffset / 60}`;
    } else {
        return `UTC+${Math.abs(zoneOffset / 60)}`;
    }
}

export const convertToCron = ({
    localTimeZone,
    getFieldValue,
    setCronExpression,
    setExpectedMonthlySavings
}: ConvertToCronRequest): void => {
    const selectedStartHour = getFieldValue("startHour");
    const selectedStopHour = getFieldValue("stopHour");
    const selectedDays: string[] = getFieldValue("weekDays");
    if (!selectedStartHour || !selectedStopHour || parseInt(selectedStartHour) >= parseInt(selectedStopHour) || !selectedDays || !selectedDays.length) {
        return;
    }

    const zoneOffset = parseInt(localTimeZone.split("UTC")[1] || "0");

    const startExpression = zoneOffset > 0 ?
        cronExpressionTimeZoneMoreThanZero(zoneOffset, parseInt(selectedStartHour), selectedDays) :
        zoneOffset < 0 ?
            cronExpressionTimeZoneLessThanZero(zoneOffset, parseInt(selectedStartHour), selectedDays) :
            `cron(0 ${parseInt(selectedStartHour)} ? * ${selectedDays.join(",")} *)`

    const stopExpression = zoneOffset > 0 ?
        cronExpressionTimeZoneMoreThanZero(zoneOffset, parseInt(selectedStopHour), selectedDays) :
        zoneOffset < 0 ?
            cronExpressionTimeZoneLessThanZero(zoneOffset, parseInt(selectedStopHour), selectedDays) :
            `cron(0 ${parseInt(selectedStopHour)} ? * ${selectedDays.join(",")} *)`

    // calculate saving resource hours for the days that have running resources (per week)
    const selectedDaysSavingHours= selectedDays.length * (24 - (selectedStopHour - selectedStartHour));
    // calculate saving resource hours for the days that don't have running resources (per week)
    const noSelectedDaysSavingHours = (7 - selectedDays.length) * 24;
    // 4 weeks * weekly saving hours
    const totalMonthlySavingHours = 4 * (selectedDaysSavingHours + noSelectedDaysSavingHours);
    setExpectedMonthlySavings(totalMonthlySavingHours);
    setCronExpression({
        startExpression,
        stopExpression
    });
}