import { Card, Col, Row, Skeleton, Space, Tag, notification } from "antd";
import PageContentWrapper from "../../components/PageContentWrapper";
import { useEffect, useMemo, useState } from "react";
import AlarmSeverityTag, { AlarmSeverityTagProps } from "../../components/alarms/AlarmSeverityTag";
import { ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { getEnvironmentService, getMonitoringService } from "../../backend";
import { useParams } from "react-router";
import { currentProjectState } from "../../recoil/project";
import { useRecoilValue } from "recoil";
import MessageCard from "../../components/cards/MessageCard";
import ProjectAlarmDetails from "./ProjectAlarmDetails";
import capitalizeFirstLetter from "../../utils/capitalize-first-letter";
import { useSearchParams } from "react-router-dom";
import { Alarm } from "./utils";
import { ListClustersResponseInner } from "@microtica/ms-monitoring-sdk";
import { MONITORING_CLUSTER_STATUS_ORDER } from "../../enums/enums";

const ProjectAlarms = () => {
    const { projectId } = useParams() as { projectId: string; }
    const [searchParams, setSearchParams] = useSearchParams();
    const currentProject = useRecoilValue(currentProjectState);
    const [alarms, setAlarms] = useState<Alarm[]>([]);
    const [selectedAlarm, setSelectedAlarm] = useState<Alarm>();
    const [loading, setLoading] = useState(false);
    const [fetchingError, setFetchingError] = useState<string>();

    const load = async () => {
        setLoading(true);
        try {
            const { data: status } = await getMonitoringService().listClusters(projectId)
                .catch(error => {
                    if (error.response.status === 404) {
                        return { data: [] as ListClustersResponseInner[] }
                    } else {
                        throw error;
                    }
                })
            if (status.length === 0 || (status.findIndex(s => MONITORING_CLUSTER_STATUS_ORDER.includes(s.status)) === -1)) {
                setFetchingError("In order to receive alarms for your applications you must enable monitoring. Go back to your Monitoring page.");
                return;
            }
            const [{ data: { alarms } }, { data: { stages } }] = await Promise.all([
                getMonitoringService().getUnresolvedAlarmsByProjectId(projectId),
                getEnvironmentService().getStages(projectId)
            ]);
            const firedAlarms = alarms.map(a => ({
                ...a,
                environmentName: stages.find(s => s.id === a.environmentId)?.name || a.environmentId.split("-")[0]
            })).sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
            setAlarms(firedAlarms);
            const alarmId = searchParams.get("id");
            if (alarmId) {
                const foundAlarm = firedAlarms.find(a => a.id === alarmId);
                if (foundAlarm) {
                    setSelectedAlarm(foundAlarm);
                } else {
                    notification.info({
                        message: "The requested alarm is resolved"
                    })
                    // alarm id is not present in the alarms list
                    setSearchParams({});
                    setSelectedAlarm(firedAlarms[0]);
                }
            } else {
                setSelectedAlarm(firedAlarms[0]);
            }
            setFetchingError(undefined);
        } catch (error) {
            setFetchingError("Cannot retrieve alarms");
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams.get("id")]);

    const LoadingPlaceholder = () => (
        <>
            <Col span={6}>
                <Card
                    size="small"
                    title={
                        <div style={{ fontSize: "18px", padding: "4px" }}>
                            Alarms list
                        </div>
                    }
                    styles={{
                        header: { padding: "12px" }
                    }}
                    style={{ height: "50vh" }}
                >
                    <br />
                    <Skeleton paragraph={{ rows: 2 }} />
                    <br /><br />
                    <Skeleton paragraph={{ rows: 2 }} />
                </Card>
            </Col>
            <Col span={18}>
                <Card
                    size="small"
                    title={
                        <div style={{ fontSize: "18px", padding: "4px" }}>
                            Alarm details
                        </div>
                    }
                    styles={{
                        header: { padding: "12px" }
                    }}
                    style={{ height: "65vh" }}
                >
                    <br />
                    <Skeleton paragraph={{ rows: 2 }} title={false} />
                    <br /><br />
                    <Skeleton paragraph={{ rows: 5 }} title={false} />
                    <br /><br />
                    <Skeleton paragraph={{ rows: 2 }} title={false} />
                </Card>
            </Col>
        </>
    );

    const NoDataInfo = ({ cause }: { cause: "error" | "noAlarms" }) => (
        <>
            <Col span={6}>
                <Card
                    size="small"
                    title={
                        <div style={{ fontSize: "18px", padding: "4px" }}>
                            Alarms list (0)
                        </div>
                    }
                    styles={{
                        header: { padding: "12px" }
                    }}
                    style={{ height: "50vh" }}
                >
                </Card>
            </Col>
            <Col span={18}>
                <Card
                    size="small"
                    title={
                        <div style={{ fontSize: "18px", padding: "4px" }}>
                            Alarm details
                        </div>
                    }
                    styles={{
                        header: { padding: "12px" }
                    }}
                    style={{ height: "65vh" }}
                >
                    <div style={{ padding: "10px 16px" }}>
                        {
                            cause === "error" ?
                                <MessageCard
                                    type="error"
                                    text={fetchingError}
                                /> :
                                <MessageCard
                                    type="success"
                                    text={"You don't have unresolved alarms!"}
                                />
                        }
                    </div>
                </Card>
            </Col>
        </>
    )

    const AlarmsSectionDetails = () => (
        <Col span={18}>
            <ProjectAlarmDetails alarm={selectedAlarm!} loadAlarms={load} />
        </Col>
    )

    const AlarmsSectionList = useMemo(() => (
        <Col span={6}>
            <Card
                size="small"
                title={
                    <div style={{ fontSize: "18px", padding: "4px" }}>
                        Alarms list {alarms.length > 0 ? <>({alarms.length})</> : <></>}
                    </div>
                }
                styles={{
                    header: { padding: "12px" }
                }}
                style={{ minHeight: "50vh", maxHeight: "80vh", overflow: "scroll" }}
            >
                {
                    alarms.map(alarm => (
                        <Card
                            size="small"
                            style={selectedAlarm?.id === alarm.id ? { marginBottom: "8px", cursor: "pointer", borderColor: "#097bb5", } : { marginBottom: "8px", cursor: "pointer" }}
                            key={alarm.id}
                            onClick={() => { setSelectedAlarm(alarm); }}
                        >
                            <Space size={6} direction="vertical" style={{ display: "flex" }}>
                                <div className="flex-justify-space-between flex-align-start">
                                    {
                                        alarm.state === "OK" ?
                                            <Tag color="green" style={{ marginRight: "4px" }}>Resolved</Tag> :
                                            <AlarmSeverityTag severity={capitalizeFirstLetter(alarm.severity) as AlarmSeverityTagProps["severity"]} />
                                    }
                                </div>
                                <div>
                                    <div style={{ fontSize: "14px", fontWeight: 600 }}>
                                        {currentProject.name} Project
                                    </div>
                                    <div style={{ fontSize: "12px", fontWeight: 400 }}>
                                        <span style={{ fontWeight: 600 }}>Env:</span> {alarm.environmentId}
                                    </div>
                                    <div style={{ fontSize: "12px", fontWeight: 400 }}>
                                        <span style={{ fontWeight: 600 }}>App:</span> {alarm.applicationName}
                                    </div>
                                </div>
                                <div className="gray-text" style={{ fontSize: "11px" }}>
                                    <ClockCircleOutlined />&nbsp;
                                    {
                                        `${moment(alarm.timestamp).fromNow()} • ${moment(alarm.timestamp).format("HH:mm • DD MMM YY")}`
                                    }
                                </div>
                            </Space>
                        </Card>
                    ))
                }
            </Card>
        </Col>
    ), [alarms, currentProject.name, selectedAlarm?.id])

    return <PageContentWrapper size="large">
        <Row gutter={[24, 24]}>
            {loading ?
                <LoadingPlaceholder /> :
                fetchingError ?
                    <NoDataInfo cause="error" /> :
                    alarms.length === 0 ?
                        <NoDataInfo cause="noAlarms" /> :
                        <>
                            {AlarmsSectionList}
                            <AlarmsSectionDetails />
                        </>

            }
        </Row>
    </PageContentWrapper>;
}

export default ProjectAlarms;