const GitRepositoryExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Git Repository
            </div>
            <div className="explanation-subtitle">
                Code repository
            </div>
            <br />

            <ul>
                <li>
                    In order to deploy an application you must select a Git repository
                </li>
                <li>
                    You can choose to deploy an application from an <span style={{ fontStyle: "italic" }}> already existing</span> repository or you can choose Microtica to <span style={{ fontStyle: "italic" }}>create one </span>
                    for you
                </li>
                <li>
                    In both cases, we will inject the necessary files for the CI/CD pipeline to ensure that the template is deployed properly
                </li>
            </ul>
        </>
    )
}

export default GitRepositoryExplanation;