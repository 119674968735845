import { Alert, Button, Card, Col, Divider, Dropdown, Empty, List, notification, Result, Row, Skeleton, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDoubleConfigurationModal } from "../../contexts/Modal";
import { MoreOutlined, CrownOutlined, WarningOutlined, InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { Elements } from "@stripe/react-stripe-js";
import PaymentCardModal from "../../components/billing/PaymentCardModal";
import { getProjectService } from "../../backend";
import { PaymentMethod, Subscription } from "@microtica/ms-project-sdk";
import { Link, useParams } from "react-router-dom";
import { formatAmount } from "../../contexts/Helpers/helpers";
import capitalizeFirstLetter from "../../utils/capitalize-first-letter";
import StripeProvider from "../../contexts/Billing/stripe-provider";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { useCurrentProject } from "../../contexts/Project";
import MessageCard from "../../components/cards/MessageCard";
import ExplanationButton from "../../components/explanations/ExplanationButton";
import PaymentMethodsExplanation from "../../components/explanations/PaymentMethodsExplanation";

interface PaymentHistory {
    attempt_count: number;
    next_payment_attempt: number;
}

const ProjectSettingsBilling = () => {
    const { projectId } = useParams();
    const currentProject = useRecoilValue(currentProjectState);
    const { open: openConfirmModal } = useDoubleConfigurationModal();
    const [paymentModalVisible, setPaymentModalVisible] = useState(false);
    const [activeSubscription, setActiveSubscription] = useState<Subscription | any>();
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [paymentHistory, setPaymentHistory] = useState<PaymentHistory[]>([]);
    const [loading, setLoading] = useState(true);
    const [loadingReactivation, setLoadingReactivation] = useState(false);
    const [disableBilling, setDisableBilling] = useState(false);
    const { updateCurrentProject } = useCurrentProject();

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = async () => {
        try {
            await Promise.all([
                loadPaymentMethods(),
                loadSubscription(),
                loadSubscriptionHistory()
            ]);
        } catch (error: any) {
            if (error.status === 403) {
                setDisableBilling(true);
            }
        } finally {
            setLoading(false);
        }
    }

    const loadPaymentMethods = async () => {
        const { data: paymentMethods } = await getProjectService().getProjectPaymentMethods(projectId!);
        setPaymentMethods(paymentMethods);
    }

    const loadSubscription = async () => {
        const { data: subscription } = await getProjectService().getPaymentSubscription(projectId!);
        setActiveSubscription({
            ...subscription,
            quantity: (subscription as any).quantity || 1
        });
    }

    const loadSubscriptionHistory = async () => {
        const { data: paymentHistory } = await getProjectService().getPaymentSubscriptionHistory(projectId!) as any;
        setPaymentHistory(paymentHistory.data);
    }

    const handleCancelPlan = async () => {
        await getProjectService().cancelSubscription(projectId!);
        await loadSubscription();
    }

    const handleRemovePaymentMethod = async (paymentMedhodId: string) => {
        await getProjectService().removePaymentMethod(projectId!, paymentMedhodId);
        await loadPaymentMethods();
        await updateCurrentProject(projectId!);
    }

    const handleSetDefaultPaymentMethod = async (paymentMethodId: string) => {
        await getProjectService().setDefaultPaymentMethod(projectId!, paymentMethodId);
        await loadPaymentMethods();
    }

    const showDisconnectConfirm = (accountId: string, confirmation: string, isDefault: boolean) => {
        openConfirmModal({
            title: "Remove card?",
            description: <>
                You are about to remove the card ending in {confirmation}.
                <br />
                The payment method will no longer be usable after removing from the customer.
                <br />
                {
                    isDefault &&
                    <>
                        <br />
                        <Alert message="The payment method you are about to remove is a default payment method. Another available payment method will be selected as default." type="info" />
                    </>
                }
            </>,
            confirmation,
            okText: "Remove card",
            cancelText: 'Cancel',
            onOk: () => {
                return handleRemovePaymentMethod(accountId);
            }
        })
    }

    const showCancelPlanConfirm = (confirmation: string) => {
        openConfirmModal({
            title: "Cancel active plan?",
            description: <>
                This action will deactivate your project before the next billing cycle and you will only be able to access the project with read-only permission.
                <br />
                <br />
                You can still reactivate this project in the future.
                <br />
                {
                    <>
                        <br />
                        <Alert message={`Your project will be deactivated on ${moment(activeSubscription.current_period_end * 1000).format("MMM DD")}.`} type="info" />
                    </>
                }
            </>,
            confirmation,
            okText: "Cancel Plan",
            cancelText: 'Close',
            onOk: () => {
                return handleCancelPlan();
            }
        })
    }

    const handleAddNewCard = async () => {
        setPaymentModalVisible(false);
        await loadData();
        await updateCurrentProject(projectId!);
    }

    const SubscriptionStatus = ({ subscription }: { subscription?: any }) => {
        if (!subscription) {
            return <>
                There is no active subscription for this project. If you want to continue using this project you need to <Link to="/pricing/activate">Select a Plan</Link>.
                <br />
                <br />
            </>
        }
        if (subscription.status === "trialing") {
            if (subscription.cancel_at && !paymentMethods.length) {
                return <>
                    <WarningOutlined style={{ color: "red" }} />&nbsp;
                    The project is currently in a <b>trial period</b> and it doesn't have any payment method added.
                    Add a payment method before the trial ends on {moment(subscription.trial_end * 1000).format("MMM DD")} to keep using your project.
                    <br />
                    <br />
                </>
            } else if (subscription.cancel_at && paymentMethods.length) {
                return <>
                    <WarningOutlined style={{ color: "red" }} />&nbsp;
                    The project is currently in a <b>trial period</b> and it's scheduled for <b>cancelation</b> on {moment(subscription.trial_end * 1000).format("MMM DD")}.
                    Reactivate the subscription to keep using your project.
                    <br />
                    <br />
                </>
            } else {
                return (
                    <>
                        <InfoCircleOutlined style={{ color: "var(--primary-color)" }} />&nbsp;
                        The project is currently in a <b>trial period</b> that ends on {moment(subscription.trial_end * 1000).format("MMM DD")}. You will be charged automatically after the trial ends.
                        <br />
                        <br />
                    </>
                )
            }
        }
        if (subscription.cancel_at) {
            return <>
                <WarningOutlined style={{ color: "red" }} />&nbsp;
                The project is currently in a <b>cancelation period</b> and the subscription will end on {moment(subscription.cancel_at * 1000).format("MMM DD")}.
                No additional charges will be executed after that.
                <br />
                <br />
            </>
        }
        return null;
    }

    const handleReactivateSubscription = async () => {
        try {
            setLoadingReactivation(true);
            await getProjectService().discardSubscriptionCancellation(projectId!);
            await loadSubscription();
        } catch (error: any) {
            notification.error({
                message: "Cannot reactivate subscription",
                description: error.response?.data?.message
            });
        } finally {
            setLoadingReactivation(false);
        }
    }

    return (
        <>
            {
                loading ? <Skeleton /> :
                    disableBilling ?
                        <Card>
                            <Result
                                status="warning"
                                title="You are not authorized to access billing settings."
                                subTitle="Only project owner is allowed to access and modify billing."
                            />
                        </Card> :
                        <>
                            {
                                paymentHistory[0]?.attempt_count > 0 && paymentHistory[0].next_payment_attempt &&
                                <>
                                    <MessageCard
                                        type="error"
                                        text={
                                            <div>
                                                The payment has failed. Please check if your payment method is valid or if you have sufficient funds.
                                                <br />
                                                Another payment will be retried {moment(paymentHistory[0].next_payment_attempt * 1000).fromNow()}.
                                            </div>
                                        }
                                    />
                                    <br />
                                </>

                            }
                            <Card>
                                <Card.Meta title="Active Plan" />
                                <Divider />
                                {
                                    loading ? <Skeleton /> : <SubscriptionStatus subscription={activeSubscription} />
                                }
                                {
                                    !loading && activeSubscription &&
                                    <List
                                        bordered
                                        itemLayout="horizontal"
                                        className="ant-list-wrapper-bordered"
                                        dataSource={activeSubscription?.items.data}
                                        loading={loading}
                                        header="Active products for this project"
                                        renderItem={(item: any) => (
                                            <List.Item
                                                style={{ background: "white" }}
                                                extra={
                                                    <>{formatAmount(item.price.unit_amount / 100, item.price.currency)}</>
                                                }
                                            >
                                                <List.Item.Meta
                                                    title={
                                                        <Space>
                                                            <span>
                                                                {item.quantity > 1 ? `${item.quantity} x ` : ""}{item.price.product.name}
                                                            </span>
                                                            {item.price.metadata.addon && <Tag color="processing">Addon</Tag>}
                                                        </Space>
                                                    }
                                                    description={`Effective from ${moment(item.created * 1000).format("MMM DD")}`}
                                                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                                />
                                            </List.Item>
                                        )}
                                        footer={
                                            <div>
                                                <b>Total</b>
                                                <span style={{ float: "right" }}>
                                                    <b>
                                                        {formatAmount(activeSubscription?.quantity * activeSubscription?.total_amount / 100, activeSubscription?.currency)}
                                                    </b>
                                                </span>
                                            </div>
                                        }
                                    />
                                }
                                <Divider />
                                <Row>
                                    <Col span={12}>
                                        {
                                            activeSubscription && currentProject.paymentPlan?.id !== "FREE" && !activeSubscription?.cancel_at &&
                                            <Button
                                                type="link"
                                                htmlType="submit"
                                                style={{ paddingLeft: 0 }}
                                                onClick={() => showCancelPlanConfirm("cancel plan")}
                                            >
                                                Cancel plan
                                            </Button>
                                        }
                                    </Col>
                                    <Col span={12} style={{ textAlign: "right" }}>
                                        {
                                            !activeSubscription ?
                                                <Button type="primary">
                                                    <Link to="/pricing/activate">
                                                        Select Plan
                                                    </Link>
                                                </Button> :
                                                activeSubscription?.status === "trialing" && activeSubscription?.cancel_at && !paymentMethods.length ?
                                                    <Button type="primary" onClick={() => setPaymentModalVisible(true)}>
                                                        Add Payment Method
                                                    </Button> :
                                                    activeSubscription?.cancel_at ?
                                                        <Button type="primary" onClick={() => handleReactivateSubscription()} loading={loadingReactivation}>
                                                            Reactivate Subscription
                                                        </Button> :
                                                        <Button type="primary">
                                                            <Link to="/pricing/upgrade">
                                                                <CrownOutlined /> Upgrade Plan
                                                            </Link>
                                                        </Button>
                                        }
                                    </Col>
                                </Row>
                            </Card>
                            <br />

                            {/* Payment Methods card */}
                            <Card>
                                <Card.Meta title={
                                    <>
                                        <span>
                                            Payment Methods
                                        </span>
                                        <ExplanationButton
                                            content={<PaymentMethodsExplanation />}
                                        />
                                    </>
                                } />

                                <Divider />

                                <List
                                    bordered
                                    itemLayout="horizontal"
                                    className="ant-list-wrapper-bordered"
                                    dataSource={paymentMethods}
                                    loading={loading}
                                    locale={{
                                        emptyText:
                                            <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                description={
                                                    <>
                                                        <div>
                                                            No payment methods added yet!
                                                        </div>
                                                        <div>
                                                            <Button type="link" onClick={() => setPaymentModalVisible(true)}>Add Payment Method</Button>
                                                        </div>
                                                    </>
                                                }
                                            />
                                    }}
                                    renderItem={(item: any) => (
                                        <List.Item
                                            style={{ background: "white" }}
                                            actions={[
                                                <Tag hidden={!item.default} color="processing">Default</Tag>,
                                                <Dropdown
                                                    placement="bottomRight"
                                                    trigger={["click"]}
                                                    menu={{
                                                        items: [
                                                            {
                                                                key: "default",
                                                                label: "Set as default",
                                                                disabled: item.default,
                                                                onClick: () => handleSetDefaultPaymentMethod(item.id)
                                                            },
                                                            {
                                                                key: "remove",
                                                                label: "Remove card",
                                                                danger: true,
                                                                onClick: () => showDisconnectConfirm(item.id, item.lastDigits, item.default)
                                                            }
                                                        ]
                                                    }}
                                                >
                                                    <Button icon={<MoreOutlined />} />
                                                </Dropdown>
                                            ]}
                                        >
                                            <List.Item.Meta
                                                avatar={<img src={require(`../../assets/${item.brand}-card.svg`)} alt="card-avatar" />}
                                                title={<>{capitalizeFirstLetter(item.brand)} ending in {item.lastDigits}</>}
                                                description={<>Expiry {item.expMonth}/{item.expYear}</>}
                                                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                            />
                                        </List.Item>
                                    )}
                                />
                                <br />
                                <Row>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Button onClick={() => setPaymentModalVisible(true)}>
                                            Add Payment Method
                                        </Button>
                                    </Col>
                                    {
                                        <StripeProvider>
                                            {({ stripe }) => {
                                                return (stripe ?
                                                    <Elements stripe={stripe}>
                                                        <PaymentCardModal
                                                            visible={paymentModalVisible}
                                                            onCancel={() => setPaymentModalVisible(false)}
                                                            onOk={() => handleAddNewCard()}
                                                        />
                                                    </Elements> :
                                                    <div></div>
                                                )
                                            }}
                                        </StripeProvider>
                                    }
                                </Row>
                            </Card>

                            <br />

                            {/* Payment history table */}
                            <Table
                                dataSource={paymentHistory}
                                pagination={false}
                                loading={loading}
                                rowKey="id"
                                locale={{ emptyText: "No available invoices yet" }}
                                expandable={{
                                    expandedRowRender: (record: any) =>
                                        <div style={{ margin: "-16px" }}>
                                            <Table
                                                dataSource={record.lines.data}
                                                columns={[
                                                    {
                                                        title: "Description",
                                                        render: line => <>
                                                            <div>
                                                                {line.description}
                                                            </div>
                                                            {
                                                                line.discount_amounts.map((discount: { amount: number }) => (
                                                                    <div className="gray-text">
                                                                        {discount.amount / line.amount * 100}% discount
                                                                    </div>
                                                                ))
                                                            }
                                                        </>
                                                        // dataIndex: "description"
                                                    },
                                                    {
                                                        title: "QTY",
                                                        dataIndex: "quantity",
                                                        align: "right"
                                                    },
                                                    {
                                                        title: "Amount",
                                                        align: "right",
                                                        render: line => <>
                                                            <div>
                                                                {formatAmount(line.amount / 100, line.currency)}
                                                            </div>
                                                            {
                                                                line.discount_amounts.map((discount: { amount: number }) => (
                                                                    <div className="gray-text">
                                                                        -{formatAmount(discount.amount / 100, line.currency)}
                                                                    </div>
                                                                ))
                                                            }
                                                        </>
                                                    }
                                                ]}
                                                pagination={false}
                                                size="small"
                                                summary={() => (
                                                    <>
                                                        <Table.Summary.Row>
                                                            <Table.Summary.Cell index={0} align="right" colSpan={2}><b>Subtotal</b></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={0} align="right">
                                                                <b>{formatAmount(record.subtotal / 100, record.currency)}</b>
                                                            </Table.Summary.Cell>
                                                        </Table.Summary.Row>
                                                        <Table.Summary.Row>
                                                            <Table.Summary.Cell index={0} align="right" colSpan={2}><b>Total</b></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={0} align="right">
                                                                <b>{formatAmount(record.total / 100, record.currency)}</b>
                                                            </Table.Summary.Cell>
                                                        </Table.Summary.Row>
                                                        <Table.Summary.Row>
                                                            <Table.Summary.Cell index={0} align="right" colSpan={2}><b>Amount Due</b></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={0} align="right">
                                                                <b>{formatAmount(record.amount_due / 100, record.currency)}</b>
                                                            </Table.Summary.Cell>
                                                        </Table.Summary.Row>
                                                    </>
                                                )}
                                            />
                                        </div>
                                }}
                                showHeader={false}
                                title={() => <b>Invoices</b>}
                                columns={[
                                    {
                                        key: "invoice",
                                        render: item =>
                                            <Space direction="vertical">
                                                <Space>
                                                    <b>{moment(item.created * 1000).format("MMMM YYYY")}</b> <Tag>{item.status}</Tag>
                                                </Space>
                                                <div style={{ color: "GrayText" }}>{item.number}</div>
                                            </Space>
                                    },
                                    {
                                        key: 'actions',
                                        align: "right",
                                        render: item => (
                                            <Space size="large">
                                                <b>{formatAmount(item.total / 100, item.currency)}</b>
                                                <Dropdown
                                                    placement="bottomRight"
                                                    trigger={["click"]}
                                                    menu={{
                                                        items: [
                                                            {
                                                                key: "download",
                                                                label: <a href={item.invoice_pdf}>Download PDF</a>
                                                            }
                                                        ]
                                                    }}
                                                >
                                                    <Button icon={<MoreOutlined />} />
                                                </Dropdown>
                                            </Space>
                                        )
                                    }
                                ]}
                            />
                        </>
            }
        </>
    )
}

export default ProjectSettingsBilling;