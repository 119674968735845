import { trackEvent } from "./index";

interface IResourceAssignDomain {
    resourceName: string;
    envId: string;
    domain: string;
}

export const trackResourceDomainInit = ({ resourceName, envId }: Omit<IResourceAssignDomain, "domain">) => {
    trackEvent("resource_domain_init", {
        envId,
        resourceName
    });
};

export const trackResourceDomainUpdateInit = ({ resourceName, envId, domain }: IResourceAssignDomain) => {
    trackEvent("resource_domain_update_init", {
        envId,
        resourceName,
        domain
    });
};

export const trackResourceDomainEntered = ({ resourceName, envId, domain }: IResourceAssignDomain) => {
    trackEvent("resource_domain_entered", {
        envId,
        resourceName,
        domain
    });
};

export const trackResourceDomainCheckSucceeded = ({ resourceName, envId, domain }: IResourceAssignDomain) => {
    trackEvent("resource_domain_check_succeeded", {
        envId,
        resourceName,
        domain
    });
};

export const trackResourceAssignDomainFailed = ({ resourceName, envId, domain }: IResourceAssignDomain) => {
    trackEvent("resource_assign_domain_failed", {
        envId,
        resourceName,
        domain
    });
};

export const trackResourceUndeployInit = ({ resourceName, envId }: { resourceName: string, envId: string }) => {
    trackEvent("resource_undeploy_init", {
        envId,
        resourceName
    });
};
