import { Button, Card, Col, Form, Input, notification, Row, Skeleton } from "antd";
import Heading from "../../components/Heading";
import { useEffect, useState } from "react";
import { RequiredMark } from "antd/lib/form/Form";
import PricingPlanCard from "../../components/billing/PricingPlanCard";
import { PricingPlan } from "../../types";
import { PricingPlanNames } from "../../enums/enums";
import { useQuery } from "../../contexts/Navigation";
import { Link, useNavigate } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { getProjectService } from "../../backend";
import PageContentWrapper from "../../components/PageContentWrapper";
import { useSetRecoilState } from "recoil";
import { projectsState } from "../../recoil/project";
import { useCurrentProject } from "../../contexts/Project";
import { handleSetMixpanelUserGroups } from "../../backend/tracking";
import { getDetailedPaymentPlan } from "../../utils/payment-plans";

export default function ProjectNew() {
    const query = useQuery();
    const navigate = useNavigate();
    const [projectForm] = Form.useForm();
    const [contactForm] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [selectedPlan, setSelectedPlan] = useState<PricingPlan>();
    const [loading, setLoading] = useState(false);
    const [planId] = useState(query.get("plan") as PricingPlanNames);
    const { updateCurrentProject } = useCurrentProject();
    const setProjectsList = useSetRecoilState(projectsState);

    useEffect(() => {
        async function loadData() {
            const plan = await getDetailedPaymentPlan(planId);
            setSelectedPlan(plan);
        }
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleCreateProject(values: { name: string; description: string; paymentMethod?: string }) {
        try {
            setLoading(true);
            const { data: { id } } = await getProjectService().createProject(
                {
                    name: values.name,
                    description: values.description,
                    paymentPlanId: selectedPlan!.id,
                }
            );

            const project = await updateCurrentProject(id);

            setProjectsList((projects) => {
                handleSetMixpanelUserGroups([
                    ...projects.map(p => p.id),
                    project.id
                ]);

                return [
                    ...projects,
                    project
                ];
            });

            navigate(`/projects/${id}/templates`);
        } catch (error) {
            notification.error({
                message: "Cannot create new project",
                description: (error as any).response.data.message
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <PageContentWrapper size="large">
            <Heading
                title="Create Project"
                description="Choose the pricing plan the suits your needs."
                level={2}
            />
            {
                selectedPlan ?
                    <Row gutter={24}>
                        <Col span={8} style={{ display: "inline-flex", alignSelf: "stretch" }}>
                            <PricingPlanCard
                                id={selectedPlan.id}
                                name={selectedPlan.name}
                                description={selectedPlan.description}
                                features={selectedPlan.features}
                                price={selectedPlan.price}
                                trialEligible={true}
                                hideSelect={true}
                                emphasize={true}
                            />
                        </Col>
                        <Col span={16} style={{ alignSelf: "stretch" }}>
                            {
                                selectedPlan.name === "Custom" ?
                                    <Card>
                                        <Card.Meta title="Contact us" />
                                        <br />
                                        <Form
                                            form={contactForm}
                                            layout="vertical"
                                            initialValues={{}}
                                            onFinish={() => { }}
                                            requiredMark={requiredMark}
                                        >
                                            <Form.Item
                                                name="name"
                                                label="Your name"
                                                required
                                                rules={[{ required: true, message: 'Please input your name!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="email"
                                                label="Your email"
                                                required
                                                rules={[{ required: true, message: 'Please input your email!' }]}
                                            >
                                                <Input type="email" />
                                            </Form.Item>
                                            <Form.Item
                                                name="description"
                                                label="Shorty explan your project"
                                                required
                                                rules={[{ required: true, message: 'Please explain your use-case!' }]}
                                            >
                                                <TextArea maxLength={100} showCount />
                                            </Form.Item>
                                            <br />
                                            <Row>
                                                <Col span={8} style={{ textAlign: "left" }}>
                                                    <Button type="link" className="no-padding">
                                                        <Link to="/pricing/new">Choose another plan</Link>
                                                    </Button>
                                                </Col>
                                                <Col span={16} style={{ textAlign: "right" }}>
                                                    <Button type="primary" htmlType="submit">
                                                        Send request
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card> :
                                    <Card>
                                        <Card.Meta title="Project details" />
                                        <br />
                                        <Form
                                            form={projectForm}
                                            layout="vertical"
                                            initialValues={{}}
                                            onFinish={handleCreateProject}
                                            requiredMark={requiredMark}
                                        >
                                            <Form.Item
                                                name="name"
                                                label="Name"
                                                required
                                                rules={[{ required: true, message: 'Please input project name!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="description"
                                                label="Description"
                                                required
                                                rules={[{ required: true, message: 'Please input project description!' }]}
                                            >
                                                <Input maxLength={100} showCount />
                                            </Form.Item>
                                            <br />
                                            <Row>
                                                <Col span={8} style={{ textAlign: "left" }}>
                                                    <Button type="link" className="no-padding">
                                                        <Link to="/pricing/new">Select another plan</Link>
                                                    </Button>
                                                </Col>
                                                <Col span={16} style={{ textAlign: "right" }}>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        loading={loading}
                                                    >
                                                        Start 7 day free trial
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card>
                            }
                        </Col>
                    </Row> :
                    <Skeleton />
            }
        </PageContentWrapper>
    )
}