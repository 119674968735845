import { Col, Row, Space, Tag, Timeline } from "antd";
import { ReactNode } from "react";
import TemplateAvatar from "../TemplateAvatar";
import { AWS_REGIONS, GCP_REGIONS } from "../../enums/enums";
import GitIcon from "../GitIcon";
import { EnvironmentDetails, TemplateType } from "../../types";
import { BranchesOutlined, KubernetesOutlined } from "@ant-design/icons";
import CloudIcon from "../CloudIcon";

export interface DeployTemplateSummaryProps {
    templateType?: TemplateType;
    appName?: string;
    gitAccount: {
        branch: string;
        fullName: string;
        repositoryUrl: string
    };
    environment: EnvironmentDetails;
    visible?: boolean;
    templateRepositoryUrl: string;
    shouldCreateCluster?: boolean;
    framework?: string;
    footerMessage?: ReactNode;
    clusterAppName?: string;
}

export default function DeployTemplateSummary({
    templateType,
    gitAccount,
    environment,
    appName,
    templateRepositoryUrl,
    shouldCreateCluster,
    visible,
    framework,
    footerMessage,
    clusterAppName
}: DeployTemplateSummaryProps) {

    return (
        <>
            {visible &&
                <>
                    <div className="text-center">
                        <Space>
                            <TemplateAvatar name={framework!} />
                            <span className="deploy-template-summary-step-title">
                                {appName!}
                            </span>
                        </Space>
                        <div>Review your {templateType === "cluster" ? "app" : "component"} deployment</div>
                    </div>
                    <br />
                    <br />
                    <Timeline mode="alternate">
                        {shouldCreateCluster &&
                            [<Timeline.Item key="create-resource">
                                <span className="deploy-template-summary-step-title">
                                    Deploy component
                                </span>
                                <div>
                                    {clusterAppName} in {environment.name} environment
                                </div>
                                <div>
                                    <CloudIcon provider={environment.cloudProvider} /> {environment.accountId || environment.gcpProjectId}
                                    {
                                        environment.cloudProvider === "aws" ?
                                            ` - ${AWS_REGIONS.find(region => region.id === environment.region)?.name}` :
                                            ` - ${GCP_REGIONS.find(region => region.id === environment.gcpRegion)?.name}`
                                    }
                                </div>
                            </Timeline.Item>,
                            <Timeline.Item key="app-deployment">
                                <span className="deploy-template-summary-step-title">
                                    Proceed with app deployment
                                </span>
                            </Timeline.Item>
                            ]
                        }
                        <Timeline.Item key="fetch-from-repo">
                            <span className="deploy-template-summary-step-title">
                                Fetch from repo
                            </span>
                            {gitAccount?.repositoryUrl ?
                                <div>
                                    <div>
                                        <GitIcon repoUrl={gitAccount.repositoryUrl} />&nbsp;<a href={gitAccount.repositoryUrl} target="_blank" rel="noreferrer">{gitAccount.fullName}</a>
                                        {gitAccount.branch &&
                                            <div>
                                                <BranchesOutlined /> {gitAccount.branch}
                                            </div>
                                        }
                                    </div>
                                </div> :
                                <div>
                                    <a href={templateRepositoryUrl} target="_blank" rel="noreferrer">
                                        {templateRepositoryUrl}
                                    </a>
                                </div>
                            }
                        </Timeline.Item>
                        {
                            environment &&
                            <Timeline.Item key="create-app-resource-in-environment">
                                <span className="deploy-template-summary-step-title">
                                    Create {templateType === "cluster" ? "app" : "component"}
                                </span>
                                <div>
                                    in {environment.name} environment
                                </div>
                            </Timeline.Item>
                        }
                        {
                            ((environment.clusterId && environment.clusterNamespace) || shouldCreateCluster) &&
                            <Timeline.Item key="deploy-in-cluster">
                                <span className="deploy-template-summary-step-title flex-align-center">
                                    <KubernetesOutlined style={{ fontSize: "22px", color: "#326CE5" }} />
                                    &nbsp;Deploy app
                                </span>
                                <div>
                                    in {shouldCreateCluster ? clusterAppName : environment.clusterId} cluster
                                </div>
                                <div>
                                    Namespace: {shouldCreateCluster ? "microtica" : environment.clusterNamespace}
                                </div>
                            </Timeline.Item>
                        }
                        {templateType === "environment" &&
                            <Timeline.Item key="cloud-account">
                                <span className="deploy-template-summary-step-title">
                                    <CloudIcon provider={environment.cloudProvider} /> Provision infrastructure
                                </span>
                                <div>
                                    {environment.accountId}
                                </div>
                                <div>
                                    {
                                        environment.cloudProvider === "aws" ?
                                            AWS_REGIONS.find(region => region.id === environment.region)?.name :
                                            GCP_REGIONS.find(region => region.id === environment.gcpRegion)?.name
                                    }
                                </div>
                            </Timeline.Item>
                        }
                    </Timeline>
                    <br />
                </>
            }
            <Row gutter={24}>
                <Col xs={24} style={{ textAlign: "center" }}>
                    {footerMessage}
                </Col>
            </Row>
        </>
    )
}