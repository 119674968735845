import { Avatar, Card, Col, Form, Row, Select, Skeleton, Typography } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { GetMicroserviceVersionsResponseVersionsTags, ListKubernetesResponseKuberneteses } from "@microtica/ms-kube-sdk";
import { DefaultOptionType } from "antd/lib/select";
import GitCommitLink from "../GitCommitLink";

interface DeployApplicationModalGeneralSectionProps {
    serviceVersion?: GetMicroserviceVersionsResponseVersionsTags;
    serviceVersions: GetMicroserviceVersionsResponseVersionsTags[];
    handleServiceVersionChange: ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void);
    clusterSection?: {
        namespaceDisabled: boolean;
        kubernetesNamespaces: (ListKubernetesResponseKuberneteses & { namespaces: string[] })[];
        handleClusterChange: ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void);
        handleNamespaceSnippetChange: ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void);
    },
    fetchMoreData?: Function;
    loading?: boolean;
}

const DeployApplicationModalGeneralSection = ({
    serviceVersion,
    serviceVersions,
    handleServiceVersionChange,
    clusterSection,
    fetchMoreData,
    loading
}: DeployApplicationModalGeneralSectionProps) => {
    const { Text } = Typography;
    let lastScrollPosition = 0;
    // don't display these namespaces
    const UNAVAILABLE_NAMESPACES = ["kube-node-lease", "kube-public", "kube-system"];

    const handleAboutToReachBottom = () => {
        if (fetchMoreData) {
            fetchMoreData();
        }
    }

    const handleScrollUpdate = (values: any) => {
        const { scrollTop, scrollHeight, clientHeight } = values.target;
        const pad = 50; // 50px of the bottom
        const percentageOfContentScrolled = ((scrollTop + pad) / (scrollHeight - clientHeight));
        // percentageOfContentScrolled will be greater than 1 if we are about to reach the bottom
        if (percentageOfContentScrolled > 1 && scrollTop > lastScrollPosition) {
            handleAboutToReachBottom();
        }
        lastScrollPosition = scrollTop;
    }

    return (
        <Card>
            <Card.Meta
                title="Version"
                description="Configure the image tag to be deployed. Avoid using the 'latest' tag, use exact tag instead"
            />
            <br />
            <Form.Item
                key="version"
                name="version"
                required={true}
                rules={[
                    { required: true, message: `Please select version!` }
                ]}
                initialValue={serviceVersion ? serviceVersion.name : undefined}
            >
                <Select
                    className="ant-select-deploy-versions"
                    onChange={handleServiceVersionChange}
                    onPopupScroll={handleScrollUpdate}
                    loading={loading}
                >
                    {
                        serviceVersions.map(serviceVersion => (
                            <Select.Option value={serviceVersion.name} key={serviceVersion.name} className="flex-align-center">
                                <div className="flex-justify-space-between">
                                    <span>
                                        <Text>
                                            {serviceVersion.commit?.message}
                                        </Text>
                                    </span>
                                    <span style={{ fontSize: 12 }} className="gray-text">
                                        <ClockCircleOutlined /> {moment(serviceVersion.date).fromNow()}
                                    </span>
                                </div>
                                <div style={{ fontSize: 12 }}>
                                    <GitCommitLink
                                        version={serviceVersion.name}
                                        commitType={serviceVersion.commit?.type}
                                        hideIcon
                                        branch={serviceVersion.commit?.name}
                                    />
                                </div>
                                <div style={{ fontSize: 12 }}>
                                    <span style={{ color: "#000000e0" }}>
                                        <Avatar src={serviceVersion.commit?.user?.avatar} style={{ width: "15px", height: "15px" }} />
                                        {serviceVersion.commit?.user?.name}
                                    </span>
                                </div>
                            </Select.Option>
                        ))
                    }
                    {
                        loading &&
                        <Select.Option value="placeholder" key="placeholder" className="flex-align-center" disabled>
                            <Skeleton paragraph={{ rows: 1 }} />
                        </Select.Option>
                    }
                </Select >
            </Form.Item >
            {
                clusterSection ?
                    <>
                        < br />
                        <Card.Meta
                            title="Destination"
                            description="Choose on which cluster and namespace to deploy this application."
                        />
                        <br />
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    key="clusterId"
                                    name="clusterId"
                                    label="Cluster"
                                    required={true}
                                    rules={[
                                        { required: true, message: `Please select cluster!` }
                                    ]}
                                >
                                    <Select onChange={clusterSection.handleClusterChange}>
                                        {
                                            clusterSection.kubernetesNamespaces.map(({ name, id }) => (
                                                <Select.Option value={id} key={id}>{name}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    shouldUpdate={(prevValues, currentValues) => prevValues.clusterId !== currentValues.clusterId}
                                >
                                    {({ getFieldValue }) =>
                                        <Form.Item
                                            key="namespace"
                                            name="namespace"
                                            label="Namespace"
                                            required={true}
                                            rules={[
                                                { required: true, message: `Please select namespace!` }
                                            ]}>
                                            {getFieldValue('clusterId') ? (
                                                <Select
                                                    onChange={clusterSection.handleNamespaceSnippetChange}
                                                    disabled={clusterSection.namespaceDisabled}
                                                >
                                                    {
                                                        clusterSection.kubernetesNamespaces
                                                            .filter(({ id }) => id === getFieldValue('clusterId'))
                                                            .map(({ namespaces }) => (
                                                                namespaces
                                                                    .filter(namespace => !UNAVAILABLE_NAMESPACES.includes(namespace))
                                                                    .map(namespace => (
                                                                        <Select.Option value={namespace} key={namespace}>{namespace}</Select.Option>
                                                                    ))
                                                            ))
                                                    }
                                                </Select>
                                            ) : <Select disabled={true}></Select>
                                            }
                                        </Form.Item>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                    </> :
                    <></>
            }
        </Card >
    )
}

export default DeployApplicationModalGeneralSection;