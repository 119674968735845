import { Badge, Space} from "antd";
import { LiteralUnion } from "antd/es/_util/type";
import { PresetColorKey } from "antd/es/theme/internal";
import { ReactNode } from "react";

interface IMenuItemNotification {
    icon: ReactNode;
    text: string;
    badgeText?: ReactNode;
    badgeColor?: LiteralUnion<PresetColorKey>;
}

const MenuItemNotification = ({ icon, text, badgeText, badgeColor = "blue" }: IMenuItemNotification) => {
    return (
        <div className="flex-justify-space-between flex-align-center">
            <Space>
                {icon}
                {text}
            </Space>
            <div>
                {
                    badgeText ?
                        <Badge
                            count={badgeText}
                            color={badgeColor}
                            style={{ marginTop: "-4px" }}
                        /> :
                        null
                }
            </div>
        </div>
    );
}

export default MenuItemNotification;