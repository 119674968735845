import { trackEvent } from "./index";

export const trackUpdateProject = (
    projectId: string, 
    projectName: string,
    projectDescription: string
) => {
    trackEvent("project_updated", { projectId, projectName, projectDescription });
};

export const trackRemoveProject = (
    projectName: string,
    projectId: string
) => {
    trackEvent("project_removed", { projectId, projectName });
};

export const trackCloudAccountInit = () => {
    trackEvent("cloud_account_init", {});
};

export const trackSpecCloudAccountInit = (
    type: "aws" | "google"
) => {
    trackEvent(`${type}_account_init`, {});
};

export const trackGitAccountInit = (
    type: "github" | "bitbucket" | "gitlab" | "codecommit"
) => {
    trackEvent("git_account_init", {
        type
    });
};

export const trackUserAdded = (
    userEmail: string
) => {
    trackEvent("user_added", {
        userEmail
    });
};

export const trackUserRemoved = (
    userEmail: string
) => {
    trackEvent("user_removed", {
        userEmail
    });
};

export const trackUserInviteResend = (
    userEmail: string
) => {
    trackEvent("user_invite_resend", {
        userEmail
    });
};

export const trackSlackAdded = () => {
    trackEvent("slack_added", {});
};

export const trackSlackRemoved = () => {
    trackEvent("slack_removed", {});
};

// not used
export const trackNotificationsSubscribed = () => {
    trackEvent("notifications_subscribed", {});
};
// not used
export const trackNotificationsUnsubscribed = () => {
    trackEvent("notifications_unsubscribed", {});
};

export const trackBillingPilot = () => {
    trackEvent("billing_pilot_init", {});
};

export const trackWhatsNewClicked = () => {
    trackEvent("whats_new_clicked", {});
};

export const trackNotificationsClicked = () => {
    trackEvent("notifications_clicked", {});
};
