import { Button, Card, Divider, Dropdown, Empty, Image, List, notification, Space } from "antd";
import { useEffect, useState } from "react";
import { getPipelinesService } from "../../backend";
import { useAuth } from "../../contexts/Auth";
import { GithubOutlined, GitlabOutlined, MoreOutlined } from "@ant-design/icons";
import BitbicketLogo from "../../assets/bitbucket-icon.svg";
import BitbicketLogoWhite from "../../assets/bitbucket-icon-white.svg";
import AwsLogo from "../../assets/aws-logo.svg";
import AwsLogoWhite from "../../assets/aws-logo-white.svg";
import Avatar from "antd/lib/avatar/avatar";
import { useDoubleConfigurationModal } from "../../contexts/Modal";
import { trackGitAccountInit } from "../../backend/tracking/user-settings";
import { GitProvider } from "../../types";
import ConnectCodeCommitModal from "../../components/modals/ConnectCodeCommitModal";
import ExplanationButton from "../../components/explanations/ExplanationButton";
import GitAccountExplanation from "../../components/explanations/GitAccountExplanation";
import { useParams } from "react-router-dom";

export default function ProjectIntegrationsGit() {
    const { projectId } = useParams();
    const { connectGitHub, connectBitbucket, connectGitLab } = useAuth();
    const [loadingAccounts, setLoadingAccounts] = useState(true);
    const [gitAccounts, setGitAccounts] = useState<{ id: string; name: string; provider: GitProvider; }[]>([]);
    const { open: openConfirmModal } = useDoubleConfigurationModal();
    const [showConnectCodeCommitModal, setShowConnectCodeCommitModal] = useState(false);

    useEffect(() => {
        loadGitAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadGitAccounts() {
        try {
            setLoadingAccounts(true);
            const { data: { gitAccounts } } = await getPipelinesService().listGitAccounts(projectId!);

            setGitAccounts(gitAccounts.map(acc => ({
                id: acc.gitAccountId,
                name: acc.accountName,
                provider: acc.type
            })));
        } catch (error) {

        } finally {
            setLoadingAccounts(false);
        }
    }

    async function handleConnectGitHubAccount() {
        trackGitAccountInit("github");

        const { code, type } = await connectGitHub();
        await handleConnectGitAccount(code, type);
    }

    async function handleConnectGitLabAccount() {
        trackGitAccountInit("gitlab");

        const { code, type } = await connectGitLab();
        await handleConnectGitAccount(code, type);
    }

    async function handleConnectBitbucketAccount() {
        trackGitAccountInit("bitbucket");

        const { code, type } = await connectBitbucket();
        await handleConnectGitAccount(code, type);
    }

    async function handleConnectCodeCommitAccount() {
        trackGitAccountInit("codecommit");

        setShowConnectCodeCommitModal(true);
    }

    async function handleConnectGitAccount(code: string, provider: GitProvider) {
        try {
            await getPipelinesService().initiateOAuth(
                projectId!,
                code,
                provider
            );

            await loadGitAccounts();
        } catch (error: any) {
            notification.error({
                message: "Error connecting Git account",
                description: error.response.data.message
            });
        }
    }

    async function handleDisconnectAccount(accountId: string, provider: GitProvider) {
        try {
            await getPipelinesService().deleteGitAccount(projectId!, accountId);
            await loadGitAccounts();
            notification.success({
                message: "Git account disconnected"
            });
        } catch (error: any) {
            notification.error({
                message: "Error disconnecting Git account",
                description: error.response.data.message
            });
        }
    }

    function showDisconnectConfirm(accountId: string, provider: GitProvider, confirmation: string) {
        openConfirmModal({
            title: "Disconnect Git account?",
            description: <>
                By disconnecting this Git account, deployments using it will stop working and you won't be able to apply new changes.
                <br />
                <br />
                To enable automated deployments, first reconnect this account then enable automated deployments in Resource/App settings.
            </>,
            confirmation,
            okText: "Disconnect",
            cancelText: 'Cancel',
            onOk: () => {
                return handleDisconnectAccount(accountId, provider);
            }
        })
    }

    const GitAvatar = (props: { provider: string }) => {
        if (props.provider === "github") {
            return <GithubOutlined style={{ fontSize: "25px" }} />
        } else if (props.provider === "gitlab") {
            return <GitlabOutlined style={{ fontSize: "25px" }} />
        } else if (props.provider === "bitbucket") {
            return <Image src={BitbicketLogo} style={{ width: "25px", height: "25px" }} preview={false} />
        } else if (props.provider === "codecommit") {
            return <Image src={AwsLogo} style={{ width: "25px", height: "25px" }} preview={false} />
        } else {
            return <Avatar />
        }
    }

    return (
        <>
            <Card>
                <Card.Meta title={
                    <>
                        <span>
                            Connect Git Account
                        </span>
                        <ExplanationButton
                            content={<GitAccountExplanation />}
                        />
                    </>
                } />
                <Divider />
                <Space direction="vertical">
                    <Space size="large">
                        <Button className="ant-btn-github" onClick={handleConnectGitHubAccount} icon={<GithubOutlined />}>
                            GitHub
                        </Button>
                        <Button
                            className="ant-btn-bitbucket"
                            onClick={handleConnectBitbucketAccount}
                            icon={
                                <img
                                    src={BitbicketLogoWhite}
                                    alt="bitbucket"
                                    style={{ width: "14px", marginRight: "10px", marginTop: "-3px" }}
                                />
                            }
                        >
                            Bitbucket
                        </Button>
                        <Button className="ant-btn-gitlab" onClick={handleConnectGitLabAccount} icon={<GitlabOutlined />}>
                            GitLab
                        </Button>
                        <Button
                            className="ant-btn-codecommit"
                            onClick={handleConnectCodeCommitAccount}
                            icon={
                                <img
                                    src={AwsLogoWhite}
                                    alt="codecommit"
                                    style={{ width: "18px", marginRight: "10px", marginTop: "-2px" }}
                                />
                            }
                        >
                            CodeCommit
                        </Button>
                    </Space>
                </Space>
            </Card>
            <br />

            <List
                bordered
                itemLayout="horizontal"
                className="ant-list-wrapper-bordered"
                dataSource={gitAccounts}
                loading={loadingAccounts}
                header="Connected Git accounts"
                locale={{
                    emptyText: <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <>
                                <div>
                                    No connected Git accounts yet!
                                </div>
                            </>
                        }
                    />
                }}
                renderItem={item => (
                    <List.Item
                        style={{ background: "white" }}
                        actions={[
                            <Dropdown
                                placement="bottomRight"
                                trigger={["click"]}
                                menu={{
                                    items: [
                                        {
                                            key: "disconnect",
                                            label: `Disconnect ${item.name}`,
                                            danger: true,
                                            onClick: () => showDisconnectConfirm(item.id, item.provider, item.name)
                                        }
                                    ]
                                }}
                            >
                                <Button icon={<MoreOutlined />} />
                            </Dropdown>
                        ]}
                    >
                        <List.Item.Meta
                            avatar={<GitAvatar provider={item.provider} />}
                            title={item.name}
                            description={item.id}
                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        />
                    </List.Item>
                )}
            />
            {
                showConnectCodeCommitModal &&
                <ConnectCodeCommitModal
                    visible={showConnectCodeCommitModal}
                    onCancel={() => setShowConnectCodeCommitModal(false)}
                    onOk={() => {
                        loadGitAccounts();
                        setShowConnectCodeCommitModal(false);
                    }}
                />
            }
        </>
    )
}