import { CheckCircleTwoTone, CloseCircleFilled, CloseOutlined, DoubleRightOutlined, HourglassOutlined, LoadingOutlined, ProductOutlined, RocketOutlined, StopOutlined, ThunderboltOutlined, WarningFilled } from "@ant-design/icons";
import { Avatar, Button, Col, Drawer, Modal, ModalFuncProps, Row, Skeleton, Space, Steps, Tooltip, notification } from "antd"
import { Fragment, useEffect, useState } from "react";
import GitCommitLink from "./GitCommitLink";
import { Dictionary, Log, PipelineItem, PricingPlan } from "../types";
import moment from "moment";
import BuildStatusTag from "./BuildStatusTag";
import { PipelineBuildDetails, PipelineBuildDetailsStagesStatusEnum, PipelineBuildDetailsSteps, PipelineBuildDetailsStepsStatusEnum, PipelineStepEnvironmentDeploymentOutput } from "@microtica/ms-ap-sdk";
import { Socket } from "socket.io-client";
import { useCurrentProject } from "../contexts/Project";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../recoil/project";
import { disconnectRealtimeService, getEnvironmentService, getPipelinesService, getProjectService, getRealtimeService } from "../backend";
import { getDetailedPaymentPlan } from "../utils/payment-plans";
import { PricingPlanNames } from "../enums/enums";
import Logs from "./Logs";
import MessageCard from "./cards/MessageCard";
import { StageDeployment } from "@microtica/ms-elasticsearch-sdk";
import { getPipelines } from "../utils/deployments";
import DeploymentStatusTag from "./DeploymentStatusTag";

const LOGS_PLACEHOLDER: string = `⏳ Initializing logs...

`;

enum StepStatus {
    WAIT = "wait",
    PROCESS = "process",
    FINISH = "finish",
    ERROR = "error"
}

interface BuildDetailsDrawerProps {
    type: "build" | "deploy" | string;
    projectId: string;
    pipelineId: string;
    buildId: string;
    open?: boolean;
    onClose?: () => void;
}

interface PipelineBuildDetailsStepsExt extends PipelineBuildDetailsSteps {
    deployment?: StageDeployment;
}

const BuildDetailsDrawer = ({
    type,
    projectId,
    pipelineId,
    buildId,
    open,
    onClose
}: BuildDetailsDrawerProps) => {
    const [pipeline, setPipeline] = useState<PipelineItem>();
    const [buildDetails, setBuildDetails] = useState<PipelineBuildDetails>();
    const [currentSelectedStep, setCurrentSelectedStep] = useState<{ stageIndex?: number, stepIndex: number }>();
    const [socket, setSocket] = useState<Socket>();
    const [logs, setLogs] = useState(LOGS_PLACEHOLDER);
    const [showDetailedLogs] = useState(false);
    const [loading, setLoading] = useState(true);
    const [disableCancelBuild, setDisableCancelBuild] = useState(false);
    const [buildStages, setBuildStages] = useState<{
        name: string,
        status: PipelineBuildDetailsStagesStatusEnum,
        startDate?: number;
        stopDate?: number;
        steps: PipelineBuildDetailsStepsExt[];
        step?: boolean;
    }[]>();
    const [buildSteps, setBuildSteps] = useState<PipelineBuildDetailsStepsExt[]>();
    const [plan, setPlan] = useState<PricingPlan>()
    const { updateCurrentProject } = useCurrentProject();
    const currentProject = useRecoilValue(currentProjectState);
    const [, setModal] = useState<{
        destroy: () => void;
        update: (configUpdate: ModalFuncProps) => void;
    }>();


    useEffect(() => {
        const load = async () => {
            const { data } = await getPipelinesService().getPipelineBuild(projectId, pipelineId, buildId)
            setBuildDetails(data);
        }

        load();
    }, []);

    useEffect(() => {
        let allLogs = "";
        if (socket !== undefined) {
            socket.on("logs", (logs: string | Log[]) => {

                if (typeof logs === 'string') {
                    allLogs = allLogs.concat(logs);
                } else if (Array.isArray(logs)) {
                    allLogs = allLogs.concat(...logs.map(l => `[${l.timestamp}] ${l.message}\n`));
                }

                setLogs(allLogs);
            });
            socket.off("logs", () => {
                setLogs(LOGS_PLACEHOLDER);
            });
        }

        return () => { socket?.disconnect() }
    }, [socket]);

    useEffect(() => {
        if (currentSelectedStep?.stepIndex === undefined && buildDetails && buildDetails.status !== "QUEUED") {
            // manually select the first step once build data is fetched & there is no selected current step & status is not QUEUED anymore
            if (buildSteps) {
                const failedStepIndex = buildDetails?.steps.findIndex(step => step.status === "FAILED" || step.status === "RUNNING");
                failedStepIndex !== -1 ?
                    // set the current step to the failed step
                    onSelectedStepChange({ stepIndex: failedStepIndex! }) :
                    // set the current step to the first one
                    onSelectedStepChange({ stepIndex: 0 });
            } else if (buildStages) {
                // find the index of the step that is FAILED or RUNNING in the list of steps. Will use this to determine the stage that this step is part of
                const stepIndex = buildDetails.steps.findIndex(step => step.status === "FAILED" || step.status === "RUNNING");
                // find the index of the stage the FAILED or RUNNING step belongs to
                const stageIndex = buildStages.findIndex(stage => stage.name === buildDetails?.steps[stepIndex]?.stage);
                stepIndex !== -1 ?
                    // set the current step to the FAILED or RUNNING step
                    onSelectedStepChange({
                        stageIndex,
                        // the index of the step that is FAILED or RUNNING within that stage's steps
                        stepIndex: buildStages[stageIndex].steps.findIndex(step => step.name === buildDetails.steps[stepIndex].name)
                    }) :
                    // set the current step to the first one
                    onSelectedStepChange({
                        stageIndex: 0,
                        stepIndex: 0
                    });
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildDetails]);

    useEffect(() => {
        loadPipeline();
        const interval = setInterval(async () => {
            const status = await loadPipeline();
            if (["SUCCEEDED", "FAILED"].includes(status) || status.endsWith("COMPLETE")) {
                clearInterval(interval);
            }
        }, 10000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pipelineId, buildId]);

    useEffect(() => {
        if (currentSelectedStep) {
            const { stageIndex, stepIndex } = currentSelectedStep;
            const stepName = (stageIndex !== undefined) ?
                buildStages?.[stageIndex]?.steps[stepIndex].name :
                buildSteps?.[stepIndex].name;

            const step = buildSteps?.find(s => s.name === stepName)!;

            if (pipeline && step?.deployment && step.deployment.infrastructureAsCodeTool === "cloudformation") {
                loadDeploymentLogs(step);
                const interval = setInterval(async () => {
                    await loadDeploymentLogs(step);
                    if (["SUCCEEDED", "FAILED"].includes(pipeline.status) || pipeline.status.endsWith("COMPLETE")) {
                        clearInterval(interval);
                    }
                }, 10000);
                return () => clearInterval(interval);
            }
        }
    }, [pipeline, currentSelectedStep]);

    useEffect(() => {
        if (currentSelectedStep?.stepIndex !== undefined) {
            onSelectedStepChange({
                stepIndex: currentSelectedStep.stepIndex,
                stageIndex: currentSelectedStep.stageIndex
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDetailedLogs]);

    const loadPipeline = async () => {
        const pipelines = await getPipelines(projectId, { pipelineId, buildId });
        const pipeline = pipelines.find(p => p.build?.pipelineId === pipelineId && p.build.id === buildId)!;
        const build = pipeline.build!;

        setPipeline(pipeline);

        // const { data: build } = await getPipelinesService().getPipelineBuild(projectId, pipelineId, buildId);
        if (build.errorMessage === "The Free plan only allows for manual builds & deployments. To enable automated builds & deployments, upgrade your plan.") {
            const plan = await getDetailedPaymentPlan(PricingPlanNames.STARTER);
            setPlan(plan);
        }
        if (build.errorMessage === "You have exceeded the number of build minutes for this month. Upgrade your plan to get more.") {
            let plan;
            if (currentProject?.paymentPlan?.id === PricingPlanNames.FREE) {
                plan = await getDetailedPaymentPlan(PricingPlanNames.STARTER);
            } else {
                plan = await getDetailedPaymentPlan(PricingPlanNames.ADVANCED);
            }
            setPlan(plan);
        }

        if (pipeline.type === "deploy") {
            const stepsWithDeployment = build.steps.map(step => {
                if (
                    (step.output as PipelineStepEnvironmentDeploymentOutput)?.deploymentTarget === "environment" &&
                    (step.output as PipelineStepEnvironmentDeploymentOutput)?.deploymentId
                ) {
                    const deployment = pipelines.find(p => p.id === (step.output as PipelineStepEnvironmentDeploymentOutput)?.deploymentId);
                    return {
                        ...step,
                        status: mapDeployToBuildStepStatus(deployment?.status),
                        deployment
                    }
                }
                return step;
            }) as PipelineBuildDetailsStepsExt[];

            if (build.stages?.length) {
                const stagesWithDeploymentStatus = build.stages.map(stage => {
                    const pendingStep = stepsWithDeployment.find(s =>
                        s.stage === stage.name &&
                        (s.status === "PENDING" || s.status === "RUNNING") &&
                        (s.output as PipelineStepEnvironmentDeploymentOutput)?.deploymentTarget === "environment" &&
                        (s.output as PipelineStepEnvironmentDeploymentOutput)?.deploymentId
                    );

                    if (pendingStep) {
                        stage.status = mapDeployToBuildStageStatus(pendingStep.deployment?.status);
                    }
                    return stage;
                });

                build.stages = [...stagesWithDeploymentStatus];
            }

            build.steps = [
                ...stepsWithDeployment
            ];
        }

        if (build.stages?.length) {
            // build has stages with steps
            setBuildStages(build.stages.map(stage => {
                return {
                    name: stage.name,
                    status: stage.status,
                    steps: build.steps.filter(step => {
                        return step.stage === stage.name;
                    })
                };
            }));
        } else if (build.steps?.length) {
            // build hassteps without stages
            setBuildSteps(build.steps);
        }
        setLoading(false);
        setBuildDetails(build);

        return pipeline.status;
    }

    const mapDeployToBuildStepStatus = (status: string = "") => {
        if (status.endsWith("_COMPLETE")) {
            return PipelineBuildDetailsStepsStatusEnum.SUCCEEDED;
        } else if (status.endsWith("_IN_PROGRESS")) {
            return PipelineBuildDetailsStepsStatusEnum.RUNNING;
        } else if (status.endsWith("FAILED")) {
            return PipelineBuildDetailsStepsStatusEnum.FAILED;
        } else {
            return PipelineBuildDetailsStepsStatusEnum.PENDING;
        }
    }

    const mapDeployToBuildStageStatus = (status: string = "") => {
        if (status.endsWith("_COMPLETE")) {
            return PipelineBuildDetailsStagesStatusEnum.SUCCEEDED;
        } else if (status.endsWith("_IN_PROGRESS")) {
            return PipelineBuildDetailsStagesStatusEnum.RUNNING;
        } else if (status.endsWith("FAILED")) {
            return PipelineBuildDetailsStagesStatusEnum.FAILED;
        } else {
            return PipelineBuildDetailsStagesStatusEnum.PENDING;
        }
    }

    const loadDeploymentLogs = async (step: PipelineBuildDetailsStepsExt) => {
        const envId = (step.output as PipelineStepEnvironmentDeploymentOutput)?.stageId!;

        let deploymentEvents = [];

        if (pipeline?.events && pipeline.events.length > 0) {
            deploymentEvents = pipeline?.events;
        } else {
            const { data: { events } } = await getEnvironmentService()
                .getStageDeploymentEvents(
                    envId,
                    projectId
                )
                .catch(_ => ({ data: { events: [] } }));
            deploymentEvents = events;
        }

        const sortedEvents = deploymentEvents.sort((d1, d2) => Number(new Date(d1.timestamp)) - Number(new Date(d2.timestamp)));

        setLogs(
            sortedEvents.map(e => {
                return `${moment(e.timestamp).format()} ${e.resourceStatus?.includes("FAILED") ? "\u001b[31m" : ""}[${e.resourceStatus}] \u001b[0m${e.logicalResourceId}${e.statusReason ? `\n${e.resourceStatus?.includes("FAILED") ? "\u001b[31m" : ""}===> ${e.statusReason}\u001b[0m` : ""}`;
            }).join("\n")
        )
    }

    const onSelectedStepChange = async ({ stageIndex, stepIndex }: { stageIndex?: number, stepIndex: number }) => {
        if (stageIndex === currentSelectedStep?.stageIndex && stepIndex === currentSelectedStep?.stepIndex) {
            return;
        }

        const stepName = (stageIndex !== undefined) ?
            buildStages?.[stageIndex]?.steps[stepIndex].name :
            buildSteps?.[stepIndex].name;

        const step = (buildSteps || buildStages?.[stageIndex!].steps)?.find(s => s.name === stepName)!;

        socket?.close();

        setLogs(LOGS_PLACEHOLDER);

        if (!step?.deployment) {
            const newSocket = getRealtimeService(
                {
                    projectId,
                    pipelineId,
                    buildId,
                    stepName,
                    typeOfLogs: "pipeline",
                    codebuildLogs: showDetailedLogs
                }
            );
            setSocket(newSocket);
            disconnectRealtimeService();
        } else if (step?.deployment && step.deployment.infrastructureAsCodeTool === "cloudformation") {
            await loadDeploymentLogs(step);
        } else if (step?.deployment && step.deployment.infrastructureAsCodeTool === "terraform") {
            const newSocket = getRealtimeService(
                {
                    projectId,
                    stageId: (step.deployment as StageDeployment & { envId: string }).envId,
                    deploymentId: step.deployment.id,
                    typeOfLogs: "terraform"
                }
            );
            setSocket(newSocket);
            disconnectRealtimeService();
        }

        setCurrentSelectedStep({ stageIndex, stepIndex });
    }

    const showCancelBuildConfirm = () => {
        const modal = Modal.confirm({
            title: "Cancel build",
            icon: <CloseCircleFilled style={{ color: "#ff4d4f" }} />,
            content: "Are you sure you want to cancel the build execution? This action cannot be undone.",
            okText: "Cancel Build",
            cancelText: "No",
            centered: true,
            okButtonProps: {
                danger: true
            },
            async onOk() {
                return handleCancelBuild();
            },
            onCancel() {
                setModal(undefined);
            }
        });
        setModal(modal);
    }

    const handleCancelBuild = async () => {
        await getPipelinesService().stopPipelineExecution(projectId, pipelineId, buildId);
        setDisableCancelBuild(true);
        notification.success({
            message: "Build cancel initiated",
            description: "It could take a few moments for the changes to take effect."
        });
    }

    const renderStatus = (status: string) => {
        switch (status) {
            case "SUCCEEDED":
                return {
                    icon: <CheckCircleTwoTone twoToneColor="rgb(46, 154, 0)" />,
                    color: "green",
                    stepStatus: StepStatus.FINISH,
                    displayStatus: "Done"
                };
            case "FAILED":
                return {
                    icon: <WarningFilled />,
                    color: "red",
                    stepStatus: StepStatus.ERROR,
                    displayStatus: "Failed"
                };
            case "RUNNING":
                return {
                    icon: <LoadingOutlined />,
                    stepStatus: StepStatus.PROCESS,
                    displayStatus: "In progress"
                };
            case "SKIPPED":
                return {
                    icon: <DoubleRightOutlined />,
                    stepStatus: StepStatus.WAIT,
                    displayStatus: "Skipped"
                };
            case "ABORTED":
                return {
                    icon: <StopOutlined />,
                    stepStatus: StepStatus.FINISH,
                    displayStatus: "Canceled"
                };
            case "PENDING":
            case "QUEUED":
            default:
                return {
                    icon: <HourglassOutlined />,
                    stepStatus: StepStatus.WAIT,
                    displayStatus: "Pending"
                };
        }
    }

    const isTrialEligible = (planId: string) => {
        return currentProject?.freeTrials && !(currentProject.freeTrials as Dictionary<boolean>)[planId];
    }

    const showSelectPlanConfirm = () => Modal.confirm({
        title: `Activate ${plan!.name} plan`,
        content:
            <Space direction='vertical'>
                <div>
                    You are about to activate the {plan!.name} plan that will cost {plan!.price} per month.
                </div>
                {
                    isTrialEligible(plan!.id) ?
                        <div>
                            <b>7 day free trial</b> will be applied.
                        </div> :
                        <div>
                            Your credit card will be <b>charged immediately</b> because you've already used your free trial period.
                        </div>
                }
            </Space>,
        okText: `Activate ${plan!.name} plan`,
        centered: true,
        async onOk() {
            return handleSelectPlan();
        }
    });

    const mapErrorMessage = (errorMessage: string) => {

        if (errorMessage === "The Free plan only allows for manual builds & deployments. To enable automated builds & deployments, upgrade your plan.") {
            const modifiedErrorMessage = errorMessage.replace("upgrade your plan.", "");

            return <Space size={0}>
                {modifiedErrorMessage}
                <Button
                    style={{ paddingLeft: 3, paddingRight: 3 }}
                    type="link"
                    onClick={showSelectPlanConfirm}>
                    upgrade your plan.
                </Button>
            </Space>
        } else if (errorMessage === "You have exceeded the number of build minutes for this month. Upgrade your plan to get more.") {
            const [firstPart, secondPart] = errorMessage.split("Upgrade your plan");

            return <Space size={0}>
                {firstPart}
                <Button
                    style={{ paddingLeft: 3, paddingRight: 3 }}
                    type="link"
                    onClick={showSelectPlanConfirm}>
                    Upgrade your plan
                </Button>
                {secondPart}
            </Space>
        }

        return errorMessage
    }

    const handleSelectPlan = async () => {
        try {
            await getProjectService().changeProjectSubscription(
                currentProject!.id,
                {
                    paymentPlanId: plan!.id,
                    priceId: plan!.priceId!
                }
            );
            await updateCurrentProject(currentProject!.id);
        } catch (error: any) {
            notification.error({
                message: `Error activating the ${plan!.name} plan`,
                description: <>
                    {error.response.data.message}&nbsp;
                    <a href={`/projects/${currentProject!.id}/settings/billing`}>Go to Billing</a> to check your settings.
                </>
            })
        }
    }

    const handleClose = () => {
        onClose?.();
    }

    const Header = () => (
        !pipeline?.build ? <Skeleton /> :
            <div className="flex-justify-space-between" style={{ marginBottom: "48px" }}>
                <Row gutter={[24, 24]}>
                    {
                        type === "template" ? undefined :
                            <Col>
                                <Space direction="vertical">
                                    <div style={{ fontSize: "14px", fontWeight: 600 }}>
                                        Commit
                                    </div>
                                    <div>
                                        <GitCommitLink version={pipeline.build.metadata.commit.sha || pipeline.build.metadata.commit.version} repoUrl={pipeline.build.metadata.repository} branch={pipeline.build.metadata.commit.name} />
                                    </div>
                                </Space>
                            </Col>
                    }
                    <Col>
                        <Space direction="vertical">
                            <div style={{ fontSize: "14px", fontWeight: 600 }}>
                                Execution time
                            </div>
                            <div>
                                {
                                    moment.utc(
                                        moment.duration(moment(pipeline.build.stopDate || new Date()).diff(moment(pipeline.build.startDate))).as("milliseconds")
                                    ).format("m[min] s[sec]")
                                        .replace(/^0min (\d+)sec$/, "$1sec")
                                        .replace(/^(\d+)min 0sec$/, "$1min")
                                }
                            </div>
                        </Space>
                    </Col>
                    <Col>
                        <Space direction="vertical">
                            <div style={{ fontSize: "14px", fontWeight: 600 }}>
                                Status
                            </div>
                            <div>
                                {
                                    pipeline.stage === "deploy" ?
                                        <DeploymentStatusTag status={pipeline.status} /> :
                                        <BuildStatusTag status={pipeline.status} statusCode={pipeline.statusCode} />
                                }
                                {/* <BuildStatusTag status={pipeline.build.status} statusCode={pipeline.build.statusCode} /> */}
                            </div>
                        </Space>
                    </Col>
                </Row>

                {
                    pipeline.status === "RUNNING" && type !== "template" &&
                    <Button
                        danger
                        onClick={showCancelBuildConfirm}
                        disabled={disableCancelBuild}
                        title={disableCancelBuild ? "This build has just been cancelled" : undefined}
                    >
                        Cancel Build
                    </Button>
                }
            </div>
    )

    const TypeIcon = () => {
        if (type === "deploy") {
            return <Avatar style={{ backgroundColor: "#077BB5" }} icon={<RocketOutlined rotate={45} />} />
        } else if (type === "template") {
            return <Avatar style={{ backgroundColor: "goldenrod", paddingTop: 1 }} icon={<ProductOutlined />} />
        } else {
            return <Avatar style={{ backgroundColor: "goldenrod", paddingTop: 1 }} icon={<ThunderboltOutlined />} />
        }
    }

    return (
        <Drawer
            title={
                <Space size="middle">
                    <TypeIcon />
                    <div>
                        <div>
                            {
                                type === "template" ? "Template Pipeline Execution" : "Pipeline Execution"
                            }

                        </div>
                        <div style={{ fontSize: "14px", fontWeight: 400 }}>
                            ID: {buildId}
                        </div>
                    </div>
                </Space>
            }
            placement="bottom"
            height="100%"
            onClose={handleClose}
            open={open}
            destroyOnClose={true}
            closable={false}
            extra={
                <Button
                    icon={<CloseOutlined />}
                    onClick={handleClose}
                />
            }
        >
            <Header />

            {
                buildDetails?.errorMessage ?
                    <Fragment>
                        <MessageCard
                            type="error"
                            text={mapErrorMessage(buildDetails?.errorMessage)}
                        />
                        <br />
                    </Fragment> : null
            }

            {
                loading || buildDetails?.errorMessage ? undefined :
                    <Row gutter={[24, 24]}>
                        <Col span={5}>
                            <div style={{ fontWeight: 600 }}>
                                Pipeline
                            </div>
                            <br />
                            <Steps
                                direction="vertical"
                                size="small"
                                current={1}
                                className="build-pipeline"
                                items={
                                    buildStages ?
                                        buildStages.map((stage, stageIndex) => {
                                            const { icon, stepStatus, displayStatus } = renderStatus(stage.status);
                                            return {
                                                title: <div style={{ fontWeight: 700 }}>
                                                    {stage.name.toUpperCase()}
                                                </div>,
                                                description:
                                                    stage.steps.map((step, stepIndex) => {
                                                        const { icon, stepStatus, displayStatus } = renderStatus((step.output as PipelineStepEnvironmentDeploymentOutput)?.skipped ? "SKIPPED" : step.status);
                                                        return <div
                                                            className={
                                                                `flex-justify-space-between build-step ${currentSelectedStep?.stageIndex === stageIndex && currentSelectedStep?.stepIndex === stepIndex ? "build-step-selected" : ""} ${stepStatus === "wait" ? "disabled" : ""}`
                                                            }
                                                            onClick={() => {
                                                                onSelectedStepChange({ stageIndex, stepIndex });
                                                            }}
                                                        >
                                                            <div>
                                                                {step.name}
                                                                <div style={{ fontSize: "12px", color: "GrayText" }}>
                                                                    {
                                                                        moment.utc(
                                                                            moment.duration(moment(step.stopDate).diff(moment(step.startDate))).as("milliseconds")
                                                                        ).format("m[min] s[sec]")
                                                                    }
                                                                </div>
                                                            </div>
                                                            <Tooltip title={displayStatus}>
                                                                {icon}
                                                            </Tooltip>
                                                        </div>
                                                    }),
                                                status: stepStatus,
                                                disabled: stepStatus === "wait",
                                                icon: <Tooltip title={displayStatus}>
                                                    {icon}
                                                </Tooltip>
                                            }
                                        }) :
                                        buildSteps?.map((step, stepIndex) => {
                                            const { icon, stepStatus, displayStatus } = renderStatus((step.output as PipelineStepEnvironmentDeploymentOutput)?.skipped ? "SKIPPED" : step.status);
                                            return {
                                                title: <div
                                                    aria-disabled={true}
                                                    className={`flex-justify-space-between build-step ${currentSelectedStep?.stepIndex === stepIndex ? "build-step-selected" : ""} ${stepStatus === "wait" ? "disabled" : ""}`}
                                                    onClick={() => {
                                                        onSelectedStepChange({ stepIndex });
                                                    }}
                                                >
                                                    <div>
                                                        {step.name}
                                                        <div style={{ fontSize: "12px", color: "GrayText" }}>
                                                            {
                                                                moment.utc(
                                                                    moment.duration(moment(step.stopDate).diff(moment(step.startDate))).as("milliseconds")
                                                                ).format("m[min] s[sec]")
                                                            }
                                                        </div>
                                                    </div>
                                                </div>,
                                                status: stepStatus,
                                                disabled: stepStatus === "wait",
                                                icon: <Tooltip title={displayStatus}>
                                                    {icon}
                                                </Tooltip>
                                            }
                                        })
                                }
                            />
                        </Col>
                        <Col span={19}>
                            <Logs
                                logs={logs.split("\n")}
                                height="calc(100vh - 220px)"
                            />
                        </Col>
                    </Row>
            }
        </Drawer>
    )
}

export default BuildDetailsDrawer;