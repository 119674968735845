import { Card, Col, Empty, Progress, Row, Skeleton, Space, Table } from "antd";
import { Component, IEnvDetails } from "../../types";
import { useEffect, useState } from "react";
import { getKubeService } from "../../backend";
import { useParams } from "react-router";
import { GetKubernetesStatusResponse, ListKubernetesWithDetailsResponseNodesStatusNodes } from "@microtica/ms-kube-sdk";
import { LockFilled, UnlockFilled } from "@ant-design/icons";

interface KubernetesOverviewProps {
    env: IEnvDetails;
    component: Component;
}

const KubernetesOverview = ({
    component
}: KubernetesOverviewProps) => {
    const { projectId, componentId } = useParams() as { projectId: string; envId: string; componentId: string; };
    const [loading, setLoading] = useState(true);
    const [clusterStatus, setClusterStatus] = useState<GetKubernetesStatusResponse>();
    const [offline, setOffline] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            try {
                const { data: clusterStatus } = await getKubeService().getKubernetesStatus(component.clusterId!, projectId);

                setClusterStatus(clusterStatus);
                setOffline(false);
            } catch (error) {
                if (
                    (error as any).response.data.kubernetesError?.code === 500 ||
                    (error as any).response.data.code === 404
                ) {
                    setOffline(true);
                }
            } finally {
                setLoading(false);
            }
        }

        const interval = setInterval(async () => {
            await loadData();
        }, 30000);

        loadData();

        return () => {
            clearInterval(interval);
        }
    }, [componentId]);

    return (
        <Space direction="vertical" size="large" className="flex-justify-space-between">
            {
                offline ?
                    <Empty
                        description={
                            <>
                                <div>Your Kubernetes cluster is currently offline.</div>
                                <div>
                                    If the cluster is still active in the cloud, you can&nbsp;
                                    <a href="https://docs.microtica.com/eks-administration#Y8y55" target="_blank" style={{ fontWeight: 500 }}>
                                        connect
                                    </a>
                                    &nbsp;to it and check the status there.
                                </div>
                            </>
                        }
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    /> :
                    <>
                        <div className="flex-justify-space-between">
                            <div>
                                <div style={{ fontWeight: 600, fontSize: 16 }}>
                                    Kubernetes Overview
                                </div>
                            </div>
                            <div>
                                <a href="https://docs.microtica.com/eks-administration#Y8y55" target="_blank" style={{ fontWeight: 500 }}>
                                    How to connect with the cluster?
                                </a>
                            </div>
                        </div>
                        {
                            !clusterStatus ? <Skeleton.Input /> :
                                <div style={{ lineHeight: "22px" }}>
                                    <Row gutter={[12, 12]}>
                                        <Col span={8}>
                                            <Card size="small">
                                                <Card.Meta
                                                    title={
                                                        <div style={{ fontSize: "12px" }}>
                                                            <div className="gray-text">
                                                                Cluster ID
                                                            </div>
                                                            <div>
                                                                {clusterStatus.id}
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card size="small">
                                                <Card.Meta
                                                    title={
                                                        <div style={{ fontSize: "12px" }}>
                                                            <div className="gray-text">
                                                                Version
                                                            </div>
                                                            <div>
                                                                {
                                                                    clusterStatus.nodesStatus.nodes.length > 0 ?
                                                                        clusterStatus.nodesStatus.nodes[0].version :
                                                                        "No active nodes"
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card size="small">
                                                <Card.Meta
                                                    title={
                                                        <div style={{ fontSize: "12px" }}>
                                                            <div className="gray-text">
                                                                Access
                                                            </div>
                                                            <div>
                                                                {
                                                                    clusterStatus.associatedNamespace ?
                                                                        <div>
                                                                            <LockFilled /> <b>{clusterStatus.associatedNamespace}</b> namespace only
                                                                        </div> :
                                                                        <div>
                                                                            <UnlockFilled /> ALL namespaces
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>

                        }
                        <Table
                            title={() => {
                                return "Table displaying Kubernetes nodes. Kubernetes nodes contain the infrastructure where your application workloads are deployed and managed."
                            }}
                            dataSource={clusterStatus?.nodesStatus.nodes}
                            pagination={false}
                            loading={loading}
                            locale={{
                                emptyText:
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={
                                            <>
                                                <div>
                                                    No active Kubernetes nodes found.
                                                </div>
                                                <div>
                                                    This may occur if the cluster is not yet set up, has encountered an error, or if there are currently no nodes available for deployment.
                                                </div>
                                            </>
                                        }
                                    />
                            }}
                            scroll={{ y: "calc(100vh - 550px)" }}
                            columns={[
                                {
                                    key: "node",
                                    title: "Node",
                                    dataIndex: "name",
                                    render: name => name
                                },
                                {
                                    title: "CPU usage (vCPU)",
                                    align: "right",
                                    render: (node: ListKubernetesWithDetailsResponseNodesStatusNodes) => `${(node.resources.usage.cpu / 1000).toFixed(1)}`
                                },
                                {
                                    title: "Memory usage (GiB)",
                                    align: "right",
                                    render: (node: ListKubernetesWithDetailsResponseNodesStatusNodes) => `${(node.resources.usage.memory / 1024).toFixed(1)}`
                                },
                                {
                                    title: "Usage %",
                                    render: (node: ListKubernetesWithDetailsResponseNodesStatusNodes) =>
                                        node.resources.status === "OK" ?
                                            <>
                                                <div style={{ fontSize: "12px", lineHeight: "12px" }}>
                                                    <span>CPU: {Math.round(node.resources.usage.cpu / node.resources.allocated.cpu * 100)}%</span>
                                                    <Progress
                                                        percent={node.resources.usage.cpu / node.resources.allocated.cpu * 100}
                                                        status={Math.round(node.resources.usage.cpu / node.resources.allocated.cpu * 100) > 80 ? "exception" : "normal"}
                                                        trailColor="lightgray"
                                                        size="small"
                                                        showInfo={false}
                                                    />
                                                </div>
                                                <div style={{ fontSize: "12px", lineHeight: "12px" }}>
                                                    <span>Memory: {Math.round(node.resources.usage.memory / node.resources.allocated.memory * 100)}%</span>
                                                    <Progress
                                                        percent={node.resources.usage.memory / node.resources.allocated.memory * 100}
                                                        status={Math.round(node.resources.usage.memory / node.resources.allocated.memory * 100) > 80 ? "exception" : "normal"}
                                                        trailColor="lightgray"
                                                        size="small"
                                                        showInfo={false}
                                                    />
                                                </div>
                                            </> :
                                            <>
                                                Not available
                                            </>
                                }
                            ]}
                        />
                    </>
            }
        </Space>
    )
}

export default KubernetesOverview;