import { Modal } from "antd";
import { useEffect, useState } from "react";
import ChooseGcpAccountForm from "../ChooseGcpAccountForm";
import { IEnvDetails } from "../../types";

interface ChooseGcpAccountModalProps {
    env: IEnvDetails;
    visible?: boolean;
    onOk?: ({ gcpProjectId, gcpRegion, gcpZone }: { gcpProjectId: string, gcpRegion: string; gcpZone: string }) => void;
    onCancel?: () => void;
}

const ChooseGcpAccountModal = ({
    env,
    visible,
    onOk,
    onCancel
}: ChooseGcpAccountModalProps) => {
    const [modalVisible, setModalVisible] = useState(visible);

    useEffect(() => {
        setModalVisible(visible);
    }, [visible]);

    return (
        <Modal
            title="Choose GCP account"
            centered
            closable={false}
            open={modalVisible}
            footer={<></>}
        >
            <ChooseGcpAccountForm
                env={env}
                onSelect={onOk}
                onCancel={onCancel}
            />
        </Modal>
    )
}

export default ChooseGcpAccountModal;