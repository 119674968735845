import { Skeleton, Space, Tabs } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { GlobalOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { Outlet, useParams, useNavigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getElasticSearchService, getEnvironmentService, getKubeService } from "../../backend";
import { useEffect, useState } from "react";
import PageContentWrapper from "../../components/PageContentWrapper";
import { currentProjectState } from "../../recoil/project";
import { ComponentSchema, IAppDetails, PodPhase, StatusColor } from "../../types";
import { ServiceDeploymentCommit } from "@microtica/ms-elasticsearch-sdk";
import { ItemType } from "antd/es/menu/interface";
import capitalizeFirstLetter from "../../utils/capitalize-first-letter";
import PageHeaderBreadcrumb from "../../components/PageHeaderBreadcrumb";
import PageHeaderContent from "../../components/PageHeaderContent";
import GitCommitLink from "../../components/GitCommitLink";
import { handleRestartApp, handleDeleteApp } from "../../utils/application/handle-app-danger-actions";
import { useDoubleConfigurationModal } from "../../contexts/Modal";
import AssignAppDomainModal from "./AssignAppDomainModal";
import { GetKubernetesStatusResponseTypeEnum } from "@microtica/ms-kube-sdk";
import { trackAssignDomainInit } from "../../backend/tracking/services";
import { useCurrentProject } from "../../contexts/Project";
import { newServiceDeploymentNotificationsState } from "../../recoil/notification";

const AppRoot = () => {
    const { projectId, envId, appName, clusterId, namespace } = useParams();
    const { updateCurrentProject } = useCurrentProject();
    const navigate = useNavigate();
    const location = useLocation();
    const currentProject = useRecoilValue(currentProjectState);
    const newServiceDeploymentNotifications = useRecoilValue(newServiceDeploymentNotificationsState);
    const { open: openConfirmModal } = useDoubleConfigurationModal();

    const [appDetails, setAppDetails] = useState<IAppDetails>();
    const [activeTab, setActiveTab] = useState("overview");
    const [envName, setEnvName] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [notFoundError, setNotFoundError] = useState<boolean>(false);

    useEffect(() => {
        // change the current project if the projectId in the URL is different from the current project saved in local storage
        // this is the root component for all App related routes, so no need to do this in other places
        if (!!projectId && projectId !== currentProject.id) {
            updateCurrentProject(projectId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // reload data if there was notification that this app was deployed
        if (!!newServiceDeploymentNotifications.length &&
            !!newServiceDeploymentNotifications.find(n => n.projectId === projectId && n.additionalInfo?.stageId === envId && n.additionalInfo?.clusterId === clusterId && n.additionalInfo?.namespace === namespace && n.itemId === appName)) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newServiceDeploymentNotifications]);

    useEffect(() => {
        setActiveTab(location.pathname.split("/")[9] || "overview");
    }, [location.pathname]);

    const checkIfDomainIsWorking = (kubeConfig: any[]) => {
        // ingress-tls is used as a default secretName for shared clusters; 
        // ingress-tls is part of the certificate resource in a personal cluster => domain is not yet configured
        const hasCertificate = !!kubeConfig.find(r => r.kind === "Certificate" && r.apiVersion === "cert-manager.io/v1" && r.spec?.issuerRef?.kind === "ClusterIssuer" && r.spec?.secretName !== "ingress-tls");
        return hasCertificate;
    };

    const loadData = async () => {
        const [
            { data: deploymentStatus },
            { data: { microservicesStatus: servicesStatus, type: clusterType, associatedNamespace } },
            { data: environment },
            { data: { response: allDeployments } }
        ] = await Promise.all([
            getKubeService().getMicroserviceDeploymentStatus(
                appName!,
                clusterId!,
                namespace!,
                projectId!),
            getKubeService().getKubernetesStatus(clusterId!, projectId!),
            getEnvironmentService().getStage(envId!, projectId!),
            getElasticSearchService().getServiceDeploymentHistory(
                projectId!,
                appName!,
                clusterId!)
        ]).catch(err => {
            // display PageNotFound if one of the API calls failed with 404 error
            if (err?.response?.data?.code === 404) {
                setNotFoundError(true);
            }
            throw err;
        });

        setNotFoundError(false);
        const serviceDeployments = allDeployments.filter(d => d.name === appName);
        const lastDeployment = serviceDeployments[0];
        const status = servicesStatus.find(s => s.name === appName)!;
        const domain = deploymentStatus.configurations.find(c => c.key === "MIC_DOMAIN_NAME")?.value || "n/a";
        const lastDeploymentKubeConfig = JSON.parse(lastDeployment?.kubeConfig || "[{}]");
        let latestAppDetails = {
            name: appName!,
            description: lastDeployment?.description,
            domain,
            isDomainWorking: clusterType === GetKubernetesStatusResponseTypeEnum.Shared || !!associatedNamespace ? true : checkIfDomainIsWorking(lastDeploymentKubeConfig),
            lastDeploymentIncludesIngressSetup: !!lastDeploymentKubeConfig.find((r: any) => r.kind === "Ingress" && r.spec?.ingressClassName === "nginx"),
            branch: lastDeployment?.commit?.name || "n/a",
            createdAt: lastDeployment ? moment(lastDeployment.timestamp).fromNow() : "n/a",
            version: lastDeployment?.commit?.sha! || lastDeployment?.commit?.version!,
            image: lastDeployment?.commit?.version,
            commitDate: lastDeployment?.commit?.date!,
            status: status.isHealthy ? "ACTIVE" : status.isPending ? "PENDING" : "FAILED",
            commitMessage: lastDeployment?.commit?.message || "n/a",
            configuration: deploymentStatus.configurations,
            // Update kube SDK and remove the workaround
            clusterId: clusterId!,
            clusterType: clusterType,
            associatedNamespace,
            namespace: status.namespace,
            isPublic: status.isPublic,
            schema: JSON.parse(lastDeployment?.schemaJSON || "{}") as ComponentSchema,
            repositoryUrl: lastDeployment?.commit?.repository || lastDeployment?.imageRepository,
            pipelineId: (lastDeployment?.commit as ServiceDeploymentCommit & { pipelineId: string })?.pipelineId,
            deployments: allDeployments.filter(d => d.name === appName).map(d => ({
                id: d.id,
                name: d.name,
                version: d.version,
                branch: d.commit?.name,
                commitType: d.commit?.type,
                commitMessage: d.commit?.message || `Manual deployment of ${lastDeployment.imageRepository}`,
                repositoryUrl: d.commit?.repository || lastDeployment.imageRepository,
                imageRepository: lastDeployment.imageRepository,
                createdAt: moment(d.commit?.date || d.timestamp).fromNow(),
                createdBy: d.initiator.email,
                initiator: d.commit?.user,
                pipeline: d.commit && {
                    id: (d.commit as any).pipelineId,
                    buildId: d.commit.version,
                    public: false
                },
                status: {
                    name: d.status,
                    error: (d as any).error
                },
                timestamp: moment(d.commit?.date || d.timestamp).fromNow()
            })),
            monitoring: {
                initialCpu: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_CPU")!.value!),
                cpuLimit: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MAX_CPU")!.value!),
                cpuUsage: deploymentStatus.usage?.averageCpu!,
                initialMemory: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MEMORY")!.value!),
                memoryLimit: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MAX_MEMORY")!.value!),
                memoryUsage: deploymentStatus.usage?.averageMemory!,
                minReplicas: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MIN_REPLICAS")!.value!),
                maxReplicas: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MAX_REPLICAS")!.value!),
                runningPods: status.podStatus.runningPods,
                failedPods: status.podStatus.failedPods,
                pods: deploymentStatus.pods.map(p => ({
                    name: p.metadata.name,
                    phase: p.status.phase as PodPhase,
                    containers: p.status.containerStatuses?.map(container => ({
                        name: container.name,
                        image: container.image,
                        restartCount: container.restartCount,
                        ready: container.ready
                    })) || []
                }))
            }
        };
        setEnvName(environment.name);
        setAppDetails(latestAppDetails);
    }

    const headerBreadcrumb = appDetails ?
        <PageHeaderBreadcrumb
            items={[
                { link: "/projects", name: "Projects" },
                { link: `/projects/${projectId}`, name: currentProject.name },
                { link: `/projects/${projectId}/environments`, name: "Environments" },
                { link: `/projects/${projectId}/environments/${envId}`, name: envName },
                { link: `/projects/${projectId}/environments/${envId}/apps`, name: "Apps" },
                { link: `/projects/${projectId}/environments/${envId}/apps/${appDetails?.clusterId}/${appDetails?.namespace}/${appDetails?.name}`, name: appDetails?.name }
            ]}
        /> : <Skeleton.Button active={true} size="small" />;

    const headerTabs = (
        <Tabs
            activeKey={activeTab}
            onChange={(activeKey) => {
                navigate(activeKey);
                setActiveTab(activeKey)
            }}
            items={[
                {
                    key: "overview",
                    label: "Overview"
                },
                {
                    key: "pipeline",
                    label: "Pipeline"
                },
                {
                    key: "deployments",
                    label: "Deployments"
                },
                {
                    key: "settings",
                    label: "App Settings"
                },
            ]}
        />
    );

    const actionItems = [
        {
            label: "View Logs",
            key: "logs",
            onClick: () => navigate(`/projects/${projectId}/environments/${envId}/apps/${clusterId}/${namespace}/${appName}/overview`, { state: { scrollToLogs: true } })
        },
        { type: "divider" },
        {
            label: "Configure App",
            key: "configure",
            onClick: () => navigate(`/projects/${projectId}/environments/${envId}/apps/${clusterId}/${namespace}/${appName}/settings`)
        },
        {
            label: "Git Settings",
            key: "configure-git",
            onClick: () => navigate(`/projects/${projectId}/environments/${envId}/apps/${clusterId}/${namespace}/${appName}/settings/pipeline`)
        },
        { type: "divider" },
        {
            label: "Restart App",
            key: "deploy",
            danger: true,
            onClick: () => openConfirmModal({
                title: "Restart Application?",
                description: "The current application instances will be terminated and new instances will be created.",
                alert: <b>This action cannot be undone.</b>,
                confirmation: appName!,
                okText: "Restart Application",
                cancelText: 'Cancel',
                onOk: () => handleRestartApp({
                    projectId: projectId!,
                    envId: envId!,
                    app: {
                        name: appName!,
                        clusterId: clusterId!,
                        namespace: namespace!,
                        image: appDetails?.image || "",
                        monitoring: appDetails?.monitoring,
                        configurations: appDetails?.configuration
                    },
                    userPaymentPlan: currentProject.paymentPlan?.id!,
                    navigate
                })
            })
        },
        {
            label: "Delete App",
            key: "undeploy",
            danger: true,
            onClick: () => openConfirmModal({
                title: "Delete Application?",
                description: "The application will be permanently deleted, including its deployments and domains.",
                alert: <b>This action cannot be undone.</b>,
                confirmation: appName!,
                okText: "Delete Application",
                cancelText: 'Cancel',
                onOk: () => handleDeleteApp({
                    envId: envId!,
                    projectId: projectId!,
                    app: {
                        name: appName!,
                        clusterId: clusterId!,
                        namespace: namespace!
                    },
                    navigate
                })
            })
        }
    ] as ItemType[];

    const headerContent = appDetails ?
        <PageHeaderContent
            title={{
                prefix: "Application",
                mainText: appDetails?.name
            }}
            summaryTag={{
                text: capitalizeFirstLetter(appDetails.status.toLowerCase()),
                color: appDetails.status === "ACTIVE"
                    ? StatusColor.Active
                    : appDetails.status === "PENDING"
                        ? StatusColor.Processing
                        : StatusColor.Failed
            }}
            summary={[
                { secondaryText: appDetails.createdAt },
                {
                    secondaryText: <>
                        <GitCommitLink version={appDetails.version} repoUrl={appDetails.repositoryUrl} branch={appDetails.branch} color="gray" />
                    </>
                },
            ]}
            mainLinkButton={
                appDetails.isDomainWorking ? {
                    link: `https://${appDetails.domain}`,
                    title: <><GlobalOutlined /> Visit</>
                } : undefined
            }
            mainPrimaryButton={
                !appDetails.isDomainWorking ? {
                    title: <Space size={4}><GlobalOutlined />Assign domain</Space>,
                    onClick: () => {
                        setIsModalVisible(true);
                        trackAssignDomainInit({
                            serviceName: appDetails.name,
                            clusterName: appDetails.clusterId
                        });
                    }
                } : undefined
            }
            actionItems={actionItems}
        /> : <Skeleton.Input />;

    return (
        <PageContentWrapper
            size="large"
            header={
                <PageHeader
                    title={headerBreadcrumb}
                    footer={headerTabs}
                    style={{ paddingTop: "8px" }}
                >
                    {headerContent}
                </PageHeader>
            }
            loading={!appDetails}
            notFoundError={notFoundError}
        >
            {appDetails ? <Outlet context={{ appDetails }} /> : <Skeleton />}
            {
                isModalVisible && appDetails &&
                <AssignAppDomainModal
                    visible={isModalVisible}
                    appDetails={appDetails}
                    envId={envId!}
                    onCancel={() => setIsModalVisible(false)}
                />
            }
        </PageContentWrapper>
    );
}

export default AppRoot;