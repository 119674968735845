const CostDashboardSavingSchedulesExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Saving Schedules
            </div>
            <div className="explanation-subtitle">
                Reduce cloud costs for non-production environments
            </div>
            <br />
            <p>
                Optimize your cloud usage with custom sleep cycles using Saving Schedules.
            </p>
            <p>
                Simply activate saving mode and Microtica will handle the rest automatically.
            </p>
            <p>
                If you need immediate cloud access during these periods, you can manually bring it back instantly.
            </p>
        </>
    )
}

export default CostDashboardSavingSchedulesExplanation;