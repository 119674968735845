interface IResourceActionEnabled {
    action: "deploy" | "undeploy" | "delete";
    resourceStatus: string;
    envStatus: string;
}
export const isResourceActionEnabled = ({ action, resourceStatus, envStatus }: IResourceActionEnabled) => {
    // Disable actions if the stage's status is IN_PROGRESS
    if (envStatus?.includes("IN_PROGRESS") && envStatus !== "REVIEW_IN_PROGRESS") {
        return false;
    }
    // Make decision based on the resource's status
    switch (action) {
        case "undeploy":
            // Undeploy button should be enabled if the resource status has a status different than DELETE_COMPLETE and NOT_DEPLOYED
            return !["DELETE_COMPLETE", "NOT_DEPLOYED"].includes(resourceStatus);
        case "delete":
            // Delete button should be enabled if the resource has DELETE_COMPLETE or NOT_DEPLOYED or ROLLBACK_COMPLETE status
            return ["DELETE_COMPLETE", "NOT_DEPLOYED", "ROLLBACK_COMPLETE"].includes(resourceStatus);
        case "deploy":
        default:
            return true;
    }
}


export const canUpdateComponent = (status: string = "") => {
    return ![
        "CREATE_COMPLETE",
        "UPDATE_COMPLETE",
        "ROLLBACK_COMPLETE",
        "UPDATE_ROLLBACK_COMPLETE",
        "CREATE_IN_PROGRESS",
        "DELETE_IN_PROGRESS",
        "ROLLBACK_IN_PROGRESS",
        "UPDATE_IN_PROGRESS",
        "UPDATE_COMPLETE_CLEANUP_IN_PROGRESS",
        "UPDATE_ROLLBACK_COMPLETE_CLEANUP_IN_PROGRESS",
        "UPDATE_ROLLBACK_IN_PROGRESS",
        "DELETE_FAILED"
    ].includes(status);
}