import { LOGIN_TYPE } from "../../enums/enums";
import { handleTrackLoginEvent, handleTrackSignUpEvent, trackEvent } from "./index";

export const trackUserRegister = ({ userId, username, type, gitAccountId }:
    { userId: string, username: string, type: LOGIN_TYPE, gitAccountId?: string }) => {
    handleTrackSignUpEvent({ userId, username, type, gitAccountId });
};

export const trackUserLogin = ({ username, type }: { username: string, type: LOGIN_TYPE }) => {
    handleTrackLoginEvent({ username, type })
};

export const trackUserResetPassword = (username: string) => {
    trackEvent("user_password_reset", { email: username });
};
