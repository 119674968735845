import { Card, Space, Divider, Tooltip } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { DeploymentType, EnvDeploymentResource } from "../types";
import DeploymentStatusTag from "./DeploymentStatusTag";
import GitCommitLink from "./GitCommitLink";
import { Link, useParams } from "react-router-dom";
import GitDiffLink from "./GitDiffLink";

interface EnvironmentDeploymentResourceProps {
    envId: string;
    resource: EnvDeploymentResource;
    deploymentType: DeploymentType;
}

const EnvironmentDeploymentResource = ({
    envId,

    resource,
    deploymentType
}: EnvironmentDeploymentResourceProps) => {
    const { projectId } = useParams() as { projectId: string; };

    return (<>
        <Card
            type="inner"
            size="small"
            title={
                deploymentType === "app" ?
                    <Link
                        to={`/projects/${projectId}/environments/${envId}/components/${resource.kubernetesId}/apps/${resource.kubernetesNamespace}/${resource.name}/overview`}
                    >
                        {resource.prev?.equal ? "" : "*"} {resource.name}
                    </Link> :
                    <Link
                        to={`/projects/${projectId}/environments/${envId}/components/${resource.id}/pipelines`}
                    >
                        {resource.prev?.equal ? "" : "*"} {resource.name}
                    </Link>
            }
            extra={<DeploymentStatusTag status={resource.status.name} />}
        >

            {/* Current version */}
            <Space direction="vertical">
                <b>{resource.commitMessage}</b>
                <div>
                    <GitCommitLink version={resource.version} repoUrl={resource.repositoryUrl} commitType={resource.commitType} branch={resource.branch} /> {resource.createdAt}
                </div>
            </Space>

            {/* Changes */}
            {
                !resource.prev?.equal &&
                <>
                    <Divider plain>Updates</Divider>
                    {
                        resource.version !== resource.prev?.version &&
                        <Space>
                            <b>Version change</b>
                            <GitCommitLink version={resource.prev?.version} repoUrl={resource.prev?.repositoryUrl} commitType={resource.prev?.commitType} hideIcon={true} />
                            <ArrowRightOutlined />
                            <GitCommitLink version={resource.version} repoUrl={resource.repositoryUrl} commitType={resource.commitType} hideIcon={true} />
                            <GitDiffLink baseVersion={resource.prev?.version} compareVersion={resource.version} repoUrl={resource.prev?.repositoryUrl}>(Show diff)</GitDiffLink>
                        </Space>
                    }
                    {
                        resource.prev?.changes.length! > 0 &&
                        <div>
                            <b>Configuration changes</b>
                            {
                                resource.prev?.changes.map(c => (
                                    <div key={c[0].key}>
                                        {c[0].key}: &nbsp;
                                        <Tooltip trigger="click" title={<><b>Old value</b><br />{c[0].value}</>}>
                                            <span className="span-link">Old value</span>
                                        </Tooltip>&nbsp;

                                        <ArrowRightOutlined /> &nbsp;
                                        <Tooltip trigger="click" title={<><b>New value</b><br />{c[1].value}</>}>
                                            <span className="span-link">New value</span>
                                        </Tooltip>
                                    </div>
                                ))
                            }
                        </div>
                    }
                </>
            }
        </Card>
        <br />
    </>);
}

export default EnvironmentDeploymentResource;