import { Alert, Button, Card, Col, Row, Space, Tag, notification } from "antd";
import AlarmSeverityTag, { AlarmSeverityTagProps } from "../../components/alarms/AlarmSeverityTag";
import { CheckOutlined } from "@ant-design/icons";
import capitalizeFirstLetter from "../../utils/capitalize-first-letter";
import { Fragment } from "react/jsx-runtime";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { setMonitoringFilter } from "../../utils/local-storage";
import ChartWidget from "../../components/dashboards/widgets/ChartWidget";
import { useEffect, useState } from "react";
import { ChartMetric, Dictionary } from "../../types";
import { ClusterMonitoringStatusResponseStatusEnum, EnableMonitoringRequestClusterTypeEnum, GetAlarmConfigByIdResponseOneOf, GetAlarmConfigByIdResponseOneOf1, GetUnresolvedAlarmsByProjectIdResponseAlarmsInnerAlarmTypeEnum } from "@microtica/ms-monitoring-sdk";
import { getMonitoringService } from "../../backend";
import { GetServiceMetrics, mapMetricsToWidget } from "../../utils/metrics";
import MonitoringErrorPlaceholder from "../../components/explanations/MonitoringErrorPlaceholder";
import { Alarm, getComparationSymbol, getUnitSymbol, mapGraphNameToPixieMetricGroup, mapPixieMetricGroupToGraphName } from "./utils";
import { ActionElement } from "./utils/ActionElement";
import { useDoubleConfigurationModal } from "../../contexts/Modal";

const ProjectAlarmDetails = ({ alarm, loadAlarms }: { alarm: Alarm, loadAlarms: () => Promise<void> }) => {
    const { open: openConfirmModal } = useDoubleConfigurationModal();
    const { projectId } = useParams() as { projectId: string; }
    const navigate = useNavigate();
    const [loadingMetrics, setLoadingMetrics] = useState(false);
    const [metrics, setMetrics] = useState<Dictionary<ChartMetric>>({});
    const [monitoringError, setMonitoringError] = useState<{ status: ClusterMonitoringStatusResponseStatusEnum; message: string; }>();

    const loadMetrics = async () => {
        const appsQuery = alarm.alarmType === GetUnresolvedAlarmsByProjectIdResponseAlarmsInnerAlarmTypeEnum.Aws ?
            undefined :
            alarm.applicationName;

        try {
            setLoadingMetrics(true);
            const clusterId = alarm.alarmType === GetUnresolvedAlarmsByProjectIdResponseAlarmsInnerAlarmTypeEnum.Pixie ? alarm.kubernetesName : alarm.applicationName;
            const startTime = moment(alarm.timestamp).subtract({ hours: 1 }).utc(false).format('YYYY-MM-DD HH:mm:ss.SS [+0000]'); //1h before alarm.timestamp
            const endTime = moment(alarm.timestamp).add({ hours: 1 }).utc(false).format('YYYY-MM-DD HH:mm:ss.SS [+0000]'); //1h after alarm.timestamp
            const [{ data: metrics }, { data: { alarmsConfigs } }] = await Promise.all([
                getMonitoringService().getServiceMetrics(
                    clusterId!,
                    alarm.environmentId!,
                    startTime,
                    projectId,
                    appsQuery,
                    endTime,
                    "1m",
                ),
                getMonitoringService().getAlarmsConfigsByClusterId(clusterId!, projectId)
            ]);
            const clusterType = alarm.alarmType === GetUnresolvedAlarmsByProjectIdResponseAlarmsInnerAlarmTypeEnum.Pixie ?
                EnableMonitoringRequestClusterTypeEnum.K8s :
                EnableMonitoringRequestClusterTypeEnum.Ecs;
            const metricsData = await mapMetricsToWidget(metrics as GetServiceMetrics, clusterType, { envId: alarm.environmentId!, projectId });
            const formattedAlarms = alarmsConfigs
                .reduce((acc, alarm) => {
                    if (alarm.alarmType === "AWS") {
                        const metricName = (alarm.config as unknown as { MetricName: string }).MetricName;
                        if (acc[metricName]?.length) {
                            acc[metricName].push(alarm);
                        } else {
                            acc[metricName] = [alarm];
                        }
                    } else {
                        if (acc[mapPixieMetricGroupToGraphName[alarm.metricGroup]]?.length) {
                            acc[mapPixieMetricGroupToGraphName[alarm.metricGroup]].push(alarm);
                        } else {
                            acc[mapPixieMetricGroupToGraphName[alarm.metricGroup]] = [alarm];
                        }
                    }
                    return acc;
                }, {} as { [key: string]: (GetAlarmConfigByIdResponseOneOf1 | GetAlarmConfigByIdResponseOneOf)[] });

            if (alarm.alarmType === GetUnresolvedAlarmsByProjectIdResponseAlarmsInnerAlarmTypeEnum.Pixie) {
                // append alarm data
                // Pixie alarms
                Object.keys(metricsData).forEach(metricName => {
                    if (formattedAlarms[metricName]) {
                        metricsData[metricName].alarms = formattedAlarms[metricName];
                    }
                })
            } else {
                // AWS alarms
                Object.keys(formattedAlarms)
                    .forEach(metricName => {
                        const chartName = (formattedAlarms[metricName][0] as GetAlarmConfigByIdResponseOneOf1).awsAlarmName.replace(`${formattedAlarms[metricName][0].clusterId}-`, "");
                        metricsData[chartName].alarms = formattedAlarms[metricName];
                    })
            }
            setMetrics(metricsData);
            setMonitoringError(undefined);
        } catch (error: any) {
            setMonitoringError({
                status: error.response?.data?.status,
                message: error.response?.data?.statusMessage
            });
        }
        finally {
            setLoadingMetrics(false);
        }
    }
    useEffect(() => {
        loadMetrics();
    }, []);


    const handleMarkAsResolvedAlarm = async (alarmId: string) => {
        await getMonitoringService().markTriggeredAlarmAsResolved(alarmId, projectId);
        notification.success({
            message: "Successfully marked alarm as resolved"
        })
        loadAlarms();
    }

    const showMarkAsResolvedConfirmation = (alarm: Alarm) => {
        const metricNames = alarm.trigger.flatMap(t => t.metricName).join(",");
        openConfirmModal({
            title: "Mark alarm as resolved",
            description:
                <Space direction='vertical' style={{ marginBottom: "-8px" }}>
                    <div>
                        The following {alarm.severity.toLowerCase()} alarm will be marked as resolved and removed from Alarms list.
                    </div>
                    <ul>
                        <li><b>Metric:</b> {metricNames}</li>
                        <li><b>Environment:</b> {alarm.environmentId}</li>
                        <li><b>Application:</b> {alarm.applicationName}</li>
                        <li><b>Timestamp:</b> {moment(alarm.timestamp).format("DD MMM YY HH:mm")}</li>
                    </ul>
                    <Alert message={<b>This action cannot be undone.</b>} type="error" />
                </Space>,
            confirmation: metricNames,
            okText: "Mark as resolved",
            cancelText: "Cancel",
            onOk: () => handleMarkAsResolvedAlarm(alarm.id)
        });
    }

    return (
        <Card
            size="small"
            title={
                <div style={{ fontSize: "18px", padding: "4px" }}>
                    Alarm details <span style={{ fontWeight: "400" }}> for {alarm.applicationName}</span>
                </div>
            }
            extra={
                <Button
                    icon={<CheckOutlined />}
                    type="primary"
                    ghost
                    onClick={() => showMarkAsResolvedConfirmation(alarm)}
                >
                    Mark as resolved
                </Button>
            }
            styles={{
                header: { padding: "12px" }
            }}
            style={{ minHeight: "65vh" }}
        >
            <div style={{ padding: "12px 4px 12px 4px" }}>
                <div className="flex-justify-space-between flex-align-center">
                    <div>
                        {alarm.state === "OK" ?
                            <Tag color="green" style={{ marginRight: "4px" }}>Resolved</Tag> :
                            <AlarmSeverityTag severity={capitalizeFirstLetter(alarm.severity) as AlarmSeverityTagProps["severity"]} />
                        }
                    </div>
                    <div>
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => alarm.alarmType === GetUnresolvedAlarmsByProjectIdResponseAlarmsInnerAlarmTypeEnum.Pixie ?
                                    navigate(`/projects/${projectId}/environments/${alarm.environmentId}/components/${alarm.kubernetesName?.replace(`${alarm.environmentId}-`, "")!}/apps/${alarm.applicationName}/overview`) :
                                    navigate(`/projects/${projectId}/environments/${alarm.environmentId}/components/${alarm.applicationName}/apps/${alarm.applicationName}/overview`)
                                }
                            >
                                Go to app
                            </Button>
                            <Button
                                type="primary"
                                ghost
                                onClick={() => alarm.alarmType === GetUnresolvedAlarmsByProjectIdResponseAlarmsInnerAlarmTypeEnum.Pixie ?
                                    navigate(`/projects/${projectId}/environments/${alarm.environmentId}/components/${alarm.kubernetesName?.replace(`${alarm.environmentId}-`, "")!}/apps/${alarm.applicationName}/logs`) :
                                    navigate(`/projects/${projectId}/environments/${alarm.environmentId}/components/${alarm.applicationName}/apps/${alarm.applicationName}/logs`)
                                }
                            >
                                Show logs
                            </Button>
                        </Space>
                    </div>
                </div>
                {alarm.trigger.map(triggerDetails => (
                    <Fragment key={triggerDetails.metricName}>
                        <div style={{ fontSize: "18px", fontWeight: 600 }}>
                            {triggerDetails.metricName}
                        </div>
                        <div style={{ fontSize: "14px", marginBottom: "8px" }}>
                            exceeded the threshold of {getComparationSymbol(triggerDetails.comparisonOperator)} {triggerDetails.threshold}{getUnitSymbol(triggerDetails.unit)} in period of {triggerDetails.period / 60} minutes.
                        </div>
                    </Fragment>
                ))}

                <div style={{ fontSize: "14px", marginBottom: "32px", fontWeight: 500 }}>
                    <a href={alarm.docsUrl} target="_blank" rel="noreferrer"> Not sure what this code means?</a>
                </div>

                <Card size="small">
                    {
                        [
                            { label: "Timestamp:", description: moment(alarm.timestamp).format("DD-MM-YYYY HH:mm") },
                            { label: "Alarm reason:", description: alarm.stateReason },
                            { label: "Environment:", description: alarm.environmentName },
                            { label: "Application:", description: alarm.applicationName },
                        ]
                            .concat(alarm.awsResources ? [{ label: "AWS resource:", description: alarm.awsResources }] : [])
                            .map(item => (
                                <Row gutter={[24, 24]} key={item.label}>
                                    <Col span={4}>
                                        <div style={{ fontWeight: 600 }}>{item.label}</div>
                                    </Col>
                                    <Col span={20}>
                                        {item.description}
                                    </Col>
                                </Row>
                            ))
                    }
                </Card>

                <div style={{ marginTop: "40px" }}>
                    <div className="flex-justify-space-between">
                        <div style={{ fontSize: "18px", fontWeight: 600 }}>
                            Metrics in context of alarm
                        </div>
                        <div>
                            <Button
                                type="primary"
                                ghost
                                onClick={() => {
                                    const clusterName = alarm.alarmType === GetUnresolvedAlarmsByProjectIdResponseAlarmsInnerAlarmTypeEnum.Pixie ? alarm.kubernetesName : alarm.applicationName;
                                    setMonitoringFilter(alarm.environmentId!, clusterName, [alarm.applicationName]);
                                    navigate(`/projects/${projectId}/monitoring`)
                                }}
                            >
                                See all metrics
                            </Button>
                        </div>
                    </div>
                    <div className="gray-text" style={{ fontSize: "14px", fontWeight: 400 }}>
                        Shown metrics are for the period of 1 hour before and 1 hour after the alarm has been triggered
                    </div>

                    <div style={{ marginTop: "24px" }}>
                        {monitoringError ?
                            <MonitoringErrorPlaceholder
                                projectId={projectId}
                                envId={alarm.environmentId}
                                clusterId={alarm.alarmType === GetUnresolvedAlarmsByProjectIdResponseAlarmsInnerAlarmTypeEnum.Pixie ? alarm.kubernetesName! : alarm.applicationName}
                                clusterName={alarm.alarmType === GetUnresolvedAlarmsByProjectIdResponseAlarmsInnerAlarmTypeEnum.Pixie ? alarm.kubernetesName! : alarm.applicationName}
                                clusterType={alarm.alarmType === GetUnresolvedAlarmsByProjectIdResponseAlarmsInnerAlarmTypeEnum.Pixie ? EnableMonitoringRequestClusterTypeEnum.K8s : EnableMonitoringRequestClusterTypeEnum.Ecs}
                                error={monitoringError}
                            /> :
                            <Row gutter={[24, 24]} style={{ opacity: loadingMetrics ? 0.8 : 1 }}>
                                {
                                    Object.entries(metrics).map(([key, metric]) =>
                                        <Col span={8} key={key}>
                                            <ChartWidget
                                                title={key}
                                                metric={metric}
                                                action={<ActionElement
                                                    metric={metric}
                                                    metricGroup={metric.type === "k8s" ? mapGraphNameToPixieMetricGroup[key] || key.replaceAll(" ", "_") : key}
                                                    selectedApps={[alarm.applicationName]}
                                                    selectedCluster={alarm.alarmType === GetUnresolvedAlarmsByProjectIdResponseAlarmsInnerAlarmTypeEnum.Pixie ? alarm.kubernetesName! : alarm.applicationName}
                                                />}
                                            />
                                        </Col>
                                    )
                                }
                            </Row>
                        }
                    </div>
                </div>
            </div>
        </Card >
    )
}


export default ProjectAlarmDetails;