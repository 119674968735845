import { Select, Result, Empty, Button, Space, Form, notification, Divider } from "antd";
import { GCP_REGIONS, GCP_ZONES } from "../enums/enums";
import { useEffect, useState } from "react";
import CloudIcon from "./CloudIcon";
import { trackSpecCloudAccountInit } from "../backend/tracking/user-settings";
import { GCPRegionType, GCPZoneType, IEnvDetails } from "../types";
import { RequiredMark } from "antd/es/form/Form";
import { getCloudService, getEnvironmentService } from "../backend";
import { useParams } from "react-router";
import { UpdateStageRequestGcpRegionEnum, UpdateStageRequestGcpZoneEnum } from "@microtica/ms-engine-sdk";
import { canUpdateComponent } from "../utils/environment";
import DeploymentStatusTag from "./DeploymentStatusTag";
import ConnectGcpAccountModal from "./settings/ConnectGcpAccountModal";

interface ChooseGcpAccountFormProps {
    env: IEnvDetails;
    onSelect?: ({ gcpProjectId, gcpRegion, gcpZone }: { gcpProjectId: string, gcpRegion: string; gcpZone: string }) => void;
    onCancel?: () => void;
}
const ChooseGcpAccountForm = ({
    env,
    onSelect,
    onCancel
}: ChooseGcpAccountFormProps) => {
    const { projectId } = useParams() as { projectId: string; }
    const [accountForm] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [cloudAccounts, setCloudAccounts] = useState<{ id: string; name: string }[]>([]);
    const [connectGcpAccountModalVisible, setConnectGcpAccountModalVisible] = useState(false);
    const [loadingUpdateAccount, setLoadingUpdateAccount] = useState(false);
    const [canUpdate, setCanUpdate] = useState(false);
    const [gcpZones, setGcpZones] = useState<{ id: string; name: string }[]>([]);
    const [selectedGcpRegion, setSelectedGcpRegion] = useState<string | undefined>(
        env.gcpRegion && GCP_REGIONS.find(r => r.value === env.gcpRegion)!.id
    );

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        setGcpZones(
            GCP_ZONES.filter(z =>
                z.name.startsWith(GCP_REGIONS.find(r => r.id === selectedGcpRegion)?.value!)
            )
        );
    }, [selectedGcpRegion]);

    const loadData = async () => {
        const canUpdate = canUpdateComponent(env.status);

        const { data: { gcpAccounts } } = await getCloudService().getGcpAccounts(projectId);
        setCloudAccounts(
            gcpAccounts.map(acc => ({ id: acc.gcpProjectId, name: acc.projectName }))
        );
        setCanUpdate(canUpdate || (!env.gcpProjectId && !!gcpAccounts.length));
        setSelectedGcpRegion(env.gcpRegion && GCP_REGIONS.find(r => r.value === env.gcpRegion)!.id);
    }

    async function handleUpdateAccount(values: {
        gcpProjectId: string;
        gcpRegion: GCPRegionType;
        gcpZone: GCPZoneType;
    }) {
        try {
            setLoadingUpdateAccount(true);

            const updatedAccount = {
                gcpProjectId: values.gcpProjectId,
                gcpRegion: UpdateStageRequestGcpRegionEnum[values.gcpRegion],
                gcpZone: UpdateStageRequestGcpZoneEnum[values.gcpZone]
            };
            await getEnvironmentService().updateStage(
                env.id,
                projectId,
                updatedAccount
            );

            onSelect?.(updatedAccount);
            env.onChange?.();

            notification.success({
                message: "Cloud account updated",
                description: "The Cloud account for this environment has been updated successfully",
            });
        } catch (error: any) {
            notification.error({
                message: "Cloud account can not be updated",
                description: error?.response?.data ? error.response.data.message : "We encountered an error while updating this environment. Please contact our support team on support@microtica.com or write on our Discord community channel."
            });
        } finally {
            setLoadingUpdateAccount(false);
        }
    }


    const handleSelectGcpRegion = (region: string) => {
        setSelectedGcpRegion(region);
        accountForm.setFieldValue("gcpZone", undefined);
    }

    return (
        !cloudAccounts.length ?
            <>
                <Result
                    icon={Empty.PRESENTED_IMAGE_SIMPLE}
                    subTitle={
                        <>
                            <div>
                                It looks like you haven't connected any cloud accounts yet!
                            </div>
                            <div>
                                Connect a cloud account to deploy your infrastructure.
                            </div>
                        </>
                    }
                    extra={
                        <Button onClick={() => {
                            setConnectGcpAccountModalVisible(true);
                            trackSpecCloudAccountInit("google");
                        }}>
                            <Space>
                                <CloudIcon provider={env.cloudProvider} /> Connect GCP Account
                            </Space>
                        </Button>
                    }
                />
                {
                    // Workaround. The modal is not unmouting when closed so we need to unmount the whole component using 'connectGcpAccountModalVisible'
                    // We have a process of periodic API calls which remain active even if when the modal closes
                    connectGcpAccountModalVisible &&
                    <ConnectGcpAccountModal
                        visible={connectGcpAccountModalVisible}
                        onOk={() => {
                            loadData();
                            setConnectGcpAccountModalVisible(false);
                        }}
                        onCancel={() => setConnectGcpAccountModalVisible(false)}
                    />
                }
            </> :
            <Form
                form={accountForm}
                layout="vertical"
                initialValues={{
                    gcpProjectId: env.gcpProjectId,
                    gcpRegion: env.gcpRegion && GCP_REGIONS.find(r => r.value === env.gcpRegion)!.id,
                    gcpZone: env.gcpZone && GCP_ZONES.find(r => r.name === env.gcpZone)!.id
                }}
                requiredMark={requiredMark}
                onFinish={handleUpdateAccount}
            >
                <Form.Item
                    name="gcpProjectId"
                    label="Cloud account"
                    required
                    rules={[{ required: true, message: 'Please select cloud account!' }]}
                >
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="GCP account"
                        disabled={!canUpdate}
                        filterOption={(input, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                        filterSort={(optionA, optionB) => {
                            return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }}
                    >
                        {
                            cloudAccounts.map(acc => (
                                <Select.Option value={acc.id} key={acc.id}>
                                    {acc.name}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="gcpRegion"
                    label="GCP region"
                    required
                    rules={[{ required: true, message: 'Please select cloud region!' }]}
                >
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select region"
                        disabled={!canUpdate}
                        onSelect={handleSelectGcpRegion}
                        value={selectedGcpRegion}
                        filterOption={(input, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                        filterSort={(optionA, optionB) => {
                            return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }}
                    >
                        {
                            GCP_REGIONS.map(region => (
                                <Select.Option value={region.id} key={region.id}>
                                    {region.name}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="gcpZone"
                    label="GCP zone"
                    required
                    rules={[{ required: true, message: 'Please select cloud zone!' }]}
                >
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select zone"
                        disabled={!canUpdate}
                        filterOption={(input, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                        filterSort={(optionA, optionB) => {
                            return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }}
                    >
                        {
                            gcpZones.map(zone => (
                                <Select.Option value={zone.id} key={zone.id}>
                                    {zone.name}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Divider />
                <div className="flex-justify-space-between flex-align-center">
                    <div>
                        {
                            canUpdate ? undefined :
                                <>
                                    Account cannot be updated <DeploymentStatusTag status={env.status} />
                                </>
                        }
                    </div>
                    <Space>
                        {
                            onCancel ? <Button onClick={onCancel}>
                                Close
                            </Button> : undefined
                        }
                        <Button htmlType="submit" disabled={!canUpdate} loading={loadingUpdateAccount} type="primary">
                            Save
                        </Button>
                    </Space>
                </div>
            </Form>
    );
}

export default ChooseGcpAccountForm;