// react
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
// antd
import { Space, Card, Button, Col, Row } from "antd";
import { SearchOutlined, CloudUploadOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
// components
import DeployTemplate from "../../components/template-modules/DeployTemplateModule";
import PipelineStatusTag from "../../components/PipelineStatusTag";
//moment
import moment from "moment-timezone";
//types
import { ExecutingTemplate } from "../../types";
//tracking
import { trackDeployAppClicked } from "../../backend/tracking/templates";
import TemplateAvatar from "../../components/TemplateAvatar";
import Heading from "../../components/Heading";
import useReadLocalStorage from "../../contexts/LocalStorage";

export default function EnvironmentRunningTemplates() {
    const { envId } = useParams();
    const executingTemplates = useReadLocalStorage<ExecutingTemplate[]>("executingTemplates");
    const [currentlyRunningTemplates, setCurrentlyRunningTemplates] = useState<any>([]);

    useEffect(() => {
        const fetchRunningTemplates = () => {
            setCurrentlyRunningTemplates((executingTemplates || [])
                .filter((template: any) => template.env.id === envId)
                .sort((a, b) => {
                    return b.started! - a.started!
                }));
        }

        fetchRunningTemplates();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executingTemplates]);

    return (
        <div>
            {currentlyRunningTemplates.length ?
                <>
                    <Heading
                        title="Recent template executions"
                        description={"Active template executions in the last 15 minutes."}
                        level={3}
                    />
                    <Row gutter={24}>
                        {
                            currentlyRunningTemplates.map((template: ExecutingTemplate, index: number) => {
                                return (
                                    // 'key' param is crucial here in order for React to update the state of the proper item
                                    <Col xs={24} md={12} key={template.buildId} style={{ padding: 12 }}>
                                        <Card
                                            title={
                                                <>
                                                    <Space>
                                                        <TemplateAvatar name={template.framework!} size="large" />
                                                        <span>{template.appName}</span>
                                                        <PipelineStatusTag status={template.status || "RUNNING"} />
                                                    </Space>
                                                    <div style={{ marginLeft: 30, color: "gray" }}>
                                                        <small>
                                                            {
                                                                template.finished ?
                                                                    "finished " + moment(template.finished).fromNow()
                                                                    : "started " + moment(template.started).fromNow()
                                                            }
                                                        </small>
                                                    </div>
                                                </>
                                            }>
                                            <DeployTemplate
                                                appName={template.appName}
                                                onDeploy={() => { }}
                                                creatingCluster={template.clusterDetails.creatingCluster}
                                                clusterAppName={template.clusterDetails.clusterAppName}
                                                templateType={template!.type || "environment"}
                                                env={template.env}
                                                templateExecutionId={template!.templateExecutionId}
                                                buildId={template!.buildId}
                                                disabled={false}
                                            />
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </>
                :
                <div style={{ textAlign: "center" }}>
                    <div style={{ textAlign: 'center' }}>
                        <SearchOutlined style={{ fontSize: 60, verticalAlign: "middle", color: "#244460" }} />
                        <Title level={5} style={{ color: "black" }}>It looks like you haven't executed any templates recently</Title>
                        <p style={{ color: "black" }}>Try deploying the most popular web apps with our tailored templates </p>
                    </div>
                    <Link to="/templates" state={{ envId }}>
                        <Button type="primary" onClick={() => trackDeployAppClicked()}>
                            <CloudUploadOutlined /> Deploy app
                        </Button>
                    </Link>
                </div>
            }
        </div >


    );
}