import { LoginUserBasicResponse, LoginUserResponse } from "@microtica/ms-usermanagement-sdk";
import { Button, Card, Form, Input, notification } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import Title from "antd/lib/typography/Title";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getUserManagementService } from "../../backend";
import { trackUserAdded } from "../../backend/tracking/user-settings";
import { useAuth } from "../../contexts/Auth";
import MicroticaLogo from "../../assets/logo-color.svg";

export default function SetPassword() {
    const [form] = Form.useForm();
    const { email, code } = useParams();
    const { processAuthData } = useAuth();
    const [requiredMark] = useState<RequiredMark>('optional');

    async function handleSetPassword(values: { password: string }) {
        try {
            const { data } = await getUserManagementService().setUserPassword({
                newPassword: values.password,
                username: email!,
                sessionToken: code!,
            });

            await processAuthData(data as LoginUserResponse & LoginUserBasicResponse, "email");
            trackUserAdded(email!);
        } catch (err) {
            notification.error({
                message: "Unable to set new password",
                description: "The invitation is valid for 24 hours. Contact the administrator to resend the invitation."
            });
        }
    }

    return (
        <>
            <Link to="/">
                <img src={MicroticaLogo} style={{ height: "40px" }} alt="microtica" />
            </Link>
            <br />
            <Card style={{ width: "400px" }} bordered>
                <Card.Meta
                    title={<Title level={4}>Set new password</Title>}
                    description="You've been invited to Microtica. Set your password to get started."
                />
                <br />
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={requiredMark}
                    onFinish={handleSetPassword}
                >
                    <Form.Item
                        name="email"
                        label="Email"
                        required
                    >
                        <Input disabled placeholder="email" defaultValue={email} />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="New Password"
                        required
                        rules={[{ required: true, message: `Please input the new password!` }]}
                    >
                        <Input type="password" placeholder="password" />
                    </Form.Item>
                    <Button htmlType="submit" type="primary" block>
                        Get Started
                    </Button>
                    <small>By signing up to Microtica, you agree to our <a href="https://microtica.com/terms-of-service/" target="_blank" rel="noreferrer"> terms of service</a>.</small>
                </Form>
            </Card>
        </>
    )
}