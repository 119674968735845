import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Modal from "antd/lib/modal/Modal";
import { Button, Form, Space, notification } from "antd";
import { useEffect, useState } from "react";
import PoweredByStripe from "../../assets/powered-by-stripe-blurple.svg";
import { StripeCardElement } from "@stripe/stripe-js";
import { getProjectService } from "../../backend";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";

export interface PaymentCardModalProps {
    visible?: boolean;
    onOk?: () => void;
    onCancel?: () => void;
}

const PaymentCardModal = ({
    visible,
    onOk,
    onCancel
}: PaymentCardModalProps) => {
    const [form] = Form.useForm();
    const stripe = useStripe();
    const elements = useElements();
    const currentProject = useRecoilValue(currentProjectState);
    const [isVisible, setIsVisible] = useState(visible);
    const [paymentReady, setPaymentReady] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setIsVisible(visible);
    }, [visible]);

    const handleAddPaymentMethod = async () => {
        try {
            setLoading(true);
            const { paymentMethod } = await stripe?.createPaymentMethod({
                type: "card",
                card: elements?.getElement(CardElement) as StripeCardElement,
            })!;

            const { data: { status, client_secret } } = await getProjectService().attachPaymentMethod(
                currentProject!.id,
                paymentMethod!.id
            ) as any;

            if (status === "requires_action") {
                await stripe?.confirmCardSetup(client_secret);
            } else {
                onOk?.();
            }

            // Close the payment modal
            onOk?.();
        } catch (error: any) {
            notification.error({
                message: "Could not add card",
                description: error.response?.data?.message || "An issue occured while adding your card. Please try again or enter a new card"
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            title={
                <Space>
                    Enter your card details
                    <img src={PoweredByStripe} style={{ height: "25px" }} alt="stripe" />
                </Space>
            }
            destroyOnClose={true}
            centered
            open={isVisible}
            onCancel={onCancel}
            footer={[
                <Button onClick={onCancel}>Cancel</Button>,
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!paymentReady}
                    loading={loading}
                    onClick={handleAddPaymentMethod}
                >
                    Save
                </Button>
            ]}
        >
            <Form form={form} onFinish={handleAddPaymentMethod}>
                <br />
                <CardElement onReady={() => {
                    setPaymentReady(true);
                }} />
                <br />
                <Button htmlType="submit" className="display-none"></Button>
            </Form>
        </Modal>
    )
}

export default PaymentCardModal;