import { DeployMicroserviceRequest, MicroserviceConfigurationItem } from "@microtica/ms-kube-sdk";
import { notification } from "antd";
import { NavigateFunction } from "react-router";
import { getKubeService, getMonitoringService } from "../../backend";
import { IAppMonitoring } from "../../types";
import { AXIOS_TIMEOUT_ERROR_CODE } from "./assign-domain-steps";
import { LoadingOutlined } from "@ant-design/icons";

interface IRestartAppProps {
    projectId: string;
    envId: string;
    app: {
        name: string;
        namespace: string;
        clusterId: string;
        image: string;
        configurations?: MicroserviceConfigurationItem[];
        monitoring?: IAppMonitoring;
    },
    userPaymentPlan: string;
    navigate?: NavigateFunction;
    options?: { timeout: number };
}

interface IDeleteAppProps extends Omit<IRestartAppProps, "app" | "navigate" | "userPaymentPlan"> {
    app: {
        name: string;
        namespace: string;
        clusterId: string;
    },
    navigate: NavigateFunction;
}

// DELETE APP
export const handleDeleteApp = async ({
    envId,
    projectId,
    app,
    navigate
}: IDeleteAppProps) => {
    try {
        const { data: response } = await getKubeService().undeployMicroservice(
            app.name,
            app.namespace,
            app.clusterId,
            projectId);

        if (response.done) {

            notification.success({
                message: `Application deleted`,
                description: `Application ${app.name} was successfully deleted`,
            });

            try {
                getMonitoringService().cleanupPixieMonitoringForApplication(app.clusterId, app.namespace, app.name, projectId)
            } catch (e) {
                console.log("Error occurred on cleanup monitoring: ", e);
            }
            setTimeout(() => {
                navigate(`/projects/${projectId}/environments/${envId}`);
            }, 1000);
        }
    } catch (error) {
        notification.error({
            message: "Error deleting application",
            description: (error as any).response.data.message
        });
    }
}


// RESTART APP
export const deployApplication = async ({
    projectId,
    app,
    userPaymentPlan,
    options
}: Omit<IRestartAppProps, "navigate" | "envId">) => {
    const { image, configurations, monitoring, clusterId, namespace, name } = app;
    const microserviceRequest: DeployMicroserviceRequest = {
        deployment: {
            image,
            configurations,
            containerPort: parseInt(configurations?.find(c => c.key === "MIC_CONTAINER_PORT")?.value || "80")
        },
        autoScaling: userPaymentPlan === "FREE" ? undefined : {
            cpu: monitoring?.initialCpu,
            maxCpu: monitoring?.cpuLimit,
            memory: monitoring?.initialMemory,
            maxMemory: monitoring?.memoryLimit,
            cpuUtilization: 80,
            memoryUtilization: 80,
            minReplicas: monitoring?.minReplicas,
            maxReplicas: monitoring?.maxReplicas
        }
    }

    await getKubeService().deployMicroservice(
        name,
        clusterId,
        namespace,
        projectId,
        microserviceRequest,
        options
    );
}

export const handleRestartApp = async ({
    projectId,
    app,
    userPaymentPlan,
    options
}: IRestartAppProps) => {
    try {
        await deployApplication({
            projectId,
            app,
            userPaymentPlan,
            options
        });

        notification.success({
            message: "Restart initiated",
            description: "It would take a few moments to apply the changes.",
            icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
        });
    } catch (error: any) {
        if (!!options?.timeout && error?.code === AXIOS_TIMEOUT_ERROR_CODE) {
            // 504 gateway timeout occurred, skip this error 
            notification.success({
                message: "Restart initiated",
                description: "It would take a few moments to apply the changes.",
                icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
            });
        } else {
            notification.error({
                message: "Restart failed",
                description: error.response.data.message
            });
        }
    }
}