import { Modal } from "antd";
import AssignAppDomainSteps from "../../components/settings/AssignAppDomainSteps";
import { IAppDetails } from "../../types";

interface AssignAppDomainModalProps {
    visible: boolean;
    appDetails: IAppDetails;
    envId: string;
    onCancel: () => void;
}

const AssignAppDomainModal = ({
    visible,
    appDetails,
    envId,
    onCancel
}: AssignAppDomainModalProps) => {

    return (
        <Modal
            title="Assign domain"
            open={visible}
            footer={null}
            width="700px"
            onCancel={onCancel}
            maskClosable={false}
        >
            <AssignAppDomainSteps
                envId={envId}
                appDetails={appDetails}
                onCancel={onCancel}
                updateDomainEnabled={false}
            />
        </Modal>
    )
}

export default AssignAppDomainModal;