import { Modal, Space, notification } from "antd";
import { NavigateFunction } from "react-router";
import { getEnvironmentService, getMonitoringService } from "../../backend";
import { trackUndeployInit } from "../../backend/tracking/deployment";

interface IUndeployEnvironmentProps {
    envId: string;
    projectId: string;
    navigate: NavigateFunction;
}

interface IUndeployEnvironmentModalProps extends IUndeployEnvironmentProps {
    envStatus: string;
}

export async function handleUndeployEnvironment({
    envId,
    projectId,
    navigate
}: IUndeployEnvironmentProps) {
    // Disable monitoring for all clusters in the environments
    try {
        const { data: clusters } = await getMonitoringService().listClusters(projectId);
        await Promise.all(clusters
            .filter(c => c.stageId === envId)
            .map(c => getMonitoringService().disableMonitoring((c as any).clusterId, c.stageId, projectId))
        );
    } catch (error) {
        // Ignore error
    }

    await getEnvironmentService().undeployStage(envId, projectId);
    trackUndeployInit(envId!);
    setTimeout(() => {
        navigate(`/projects/${projectId}/pipelines`);
    }, 3000);
    notification.success({
        message: "Environment undeploy initiated",
        description: "It could take a few moments for the changes to take effect."
    })
}

export const showUndeployEnvironmentModalConfirm = ({
    envId,
    envStatus,
    projectId,
    navigate
}: IUndeployEnvironmentModalProps) => {
    Modal.confirm({
        title: "Environment cannot be deployed",
        content:
            <Space direction='vertical'>
                <div>
                    Stack is in <b>{envStatus}</b> status, you must undeploy it first
                </div>
            </Space>,
        okText: "Undeploy",
        centered: true,
        onOk: () => handleUndeployEnvironment({
            envId,
            projectId,
            navigate
        })
    });
}