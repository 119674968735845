import { UserNotificationsNotifications } from "@microtica/ms-notification-sdk";
import { Project } from "@microtica/ms-project-sdk";
import { Button, Drawer } from "antd";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { getNotificationService, getProjectService } from "../../backend";
import { currentProjectState } from "../../recoil/project";
import { setProjectLocalStorage } from "../../utils/local-storage";
import Notification from "./Notification";

interface NotificationsDrawerProps {
    projects: Project[],
    notifications: UserNotificationsNotifications[],
    type: "Incidents" | "Updates";
    visible?: boolean;
    onClose: () => void;
    onMarkAsRead?: () => void;
}

const NotificationsDrawer = ({
    projects,
    notifications,
    type,
    visible,
    onClose,
    onMarkAsRead
}: NotificationsDrawerProps) => {
    const [currentProject, setCurrentProject] = useRecoilState(currentProjectState);
    const [sortByProject, setSortByProject] = useState<boolean>(false);

    const updateProjectInfo = async (projectId: string) => {
        const { data: project } = await getProjectService().getProject(projectId);
        setProjectLocalStorage(project);
        setCurrentProject(project!);
    }

    const toggleSort = (value: boolean) => {
        setSortByProject(value);
    }

    const handleMarkAllNotificationsAsRead = async () => {
        await getNotificationService().markNotificationsAsRead(currentProject.id);
        onMarkAsRead?.();
        onClose();
    }

    return (
        <Drawer
            title={type}
            placement="right"
            open={visible}
            destroyOnClose={true}
            closable={true}
            onClose={onClose}
        >
            {
                <>
                    <div style={{ marginBottom: 8 }}>
                        <div className="flex-justify-space-between">
                            <div>
                                <Button
                                    type="text"
                                    size="small"
                                    onClick={() => toggleSort(false)}
                                    style={{ fontWeight: sortByProject ? 400 : 700 }}
                                >
                                    By date
                                </Button>
                                |
                                <Button
                                    type="text"
                                    size="small"
                                    onClick={() => toggleSort(true)}
                                    style={{ fontWeight: sortByProject ? 700 : 400 }}
                                >
                                    By project
                                </Button>
                            </div>
                            <div>
                                <Button type="text" size="small" onClick={handleMarkAllNotificationsAsRead}>
                                    Mark all as read
                                </Button>
                            </div>
                        </div>
                    </div>
                    {
                        notifications
                            .sort((a, b) => sortByProject ? a.projectId.localeCompare(b.projectId) : b.timestamp.localeCompare(a.timestamp))
                            .map(notification => (
                                <Notification
                                    {...notification}
                                    key={notification.id}
                                    type={type}
                                    projectName={projects.find(p => p.id === notification.projectId)?.name!}
                                    onClose={onClose}
                                    updateProjectInfo={updateProjectInfo}
                                />
                            ))
                    }
                </>
            }
        </Drawer>
    )
}

export default NotificationsDrawer;