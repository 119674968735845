import { Space } from "antd";
import { Link } from "react-router-dom";
import PageContentWrapper from "../../components/PageContentWrapper";
import NotFoundImage from "../../assets/404.png"

const PageNotFound = () => {
    return (
        <PageContentWrapper size="large">
            <Space className="page-not-found">
                <div className="">
                    <img src={NotFoundImage} alt="404_not_found" />
                </div>
                <div >
                    <div id="header">Whoops, this page does not exist</div>
                    <div id="body">
                        The page you are looking for does not exist. You might want to <Link to="/projects"> go back to your projects</Link> and start again from there.
                    </div>
                </div>
            </Space>
        </PageContentWrapper>
    )
}

export default PageNotFound;