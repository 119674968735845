import Modal from "antd/lib/modal/Modal";
import { Button, Form, Input, Space, notification } from "antd";
import { useEffect, useState } from "react";
import { getCloudService } from "../../backend";
import { RequiredMark } from "antd/es/form/Form";
import { useParams } from "react-router";

export interface ConnectGcpAccountModalProps {
    serviceAccountName?: string;
    serviceAccountEmail?: string;
    visible?: boolean;
    onOk?: (id: string) => void;
    onCancel?: () => void;
}

// In seconds
const CONNECTION_WAITING_TIME = 240;

const ConnectGcpAccountModal = ({
    serviceAccountName,
    serviceAccountEmail,
    visible,
    onOk,
    onCancel
}: ConnectGcpAccountModalProps) => {
    const { projectId } = useParams() as { projectId: string; };
    const [form] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [updating, setUpdating] = useState(false);
    const [modalVisible, setModalVisible] = useState(visible);

    useEffect(() => {
        setModalVisible(visible);
    }, [visible]);

    const handleAccountAction = async () => {
        const values = await form.validateFields() as { projectName: string; targetServiceAccount: string; };

        try {
            setUpdating(true);
            if (serviceAccountName && serviceAccountEmail) {
                await getCloudService().updateGcpAccount(
                    projectId,
                    values
                );
            } else {
                await getCloudService().addGcpAccount(
                    projectId,
                    values
                );
            }
            onOk?.(values.targetServiceAccount.split("@")[1].split(".")[0]);
        } catch (error) {
            notification.error({
                message: "Failed to connect GCP account",
                description: (error as any).response?.data?.message
            });
        } finally {
            setUpdating(false);
        }
    }

    return (
        <Modal
            title="Connect GCP Account"
            centered
            closable={false}
            open={modalVisible}
            footer={[
                <div className="flex-justify-space-between flex-align-center">
                    <a href="https://docs.microtica.com/connect-a-gcp-account" target="_blank">
                        How to connect GCP account?
                    </a>
                    <Space>
                        <Button onClick={() => {
                            setModalVisible(false);
                            onCancel?.();
                        }}
                        >
                            Close
                        </Button>
                        <Button
                            type="primary"
                            loading={updating}
                            onClick={handleAccountAction}
                        >
                            {
                                serviceAccountName && serviceAccountEmail ? "Update" : "Connect"
                            }
                        </Button>
                    </Space>
                </div>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    projectName: serviceAccountName,
                    targetServiceAccount: serviceAccountEmail
                }}
                requiredMark={requiredMark}
            >
                <Form.Item
                    name="projectName"
                    label="Name"
                    required
                    rules={[{ required: true, message: 'Please input the cloud account name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="targetServiceAccount"
                    label="GCP Service Account Email"
                    required
                    rules={[
                        { required: true, type: "email", message: 'Please input the GCP service account email!' }
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ConnectGcpAccountModal;