import { CheckCircleOutlined, CrownOutlined, InfoCircleOutlined, LoadingOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { DeployMicroserviceRequest } from "@microtica/ms-kube-sdk";
import { Button, Card, Col, Collapse, Divider, Form, Input, Modal, notification, Radio, Row, Space, Typography } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router";
import { getKubeService, getProjectService } from "../../backend";
import ExplanationButton from "../../components/explanations/ExplanationButton";
import AppSettingsScalingExplanation from "../../components/explanations/AppSettingsScalingExplanation";
import { Dictionary, IAppDetails, PricingPlan } from "../../types";

import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { PricingPlanNames } from "../../enums/enums";
import { useCurrentProject } from "../../contexts/Project";
import Title from "antd/lib/typography/Title";
import AppScalingBlurScreenshot from "../../assets/app-scaling.png"
import { getDetailedPaymentPlan } from "../../utils/payment-plans";
const { Text } = Typography;


const AppSettingsScaling = () => {
    const [scalingForm] = Form.useForm();
    const { projectId, clusterId, appName } = useParams();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [loadingScaling, setLoadingScaling] = useState(false);
    const { paymentPlan: userPaymentPlan, id, freeTrials } = useRecoilValue(currentProjectState);
    const { updateCurrentProject } = useCurrentProject();
    const [nextPaymentPlan, setNextPaymentPlan] = useState<PricingPlan>()
    const [scalingType, setScalingType] = useState<"fixedSize" | "autoscale">(userPaymentPlan?.id === PricingPlanNames.ADVANCED ? "autoscale" : "fixedSize")
    const disabledAppScalingProperties = scalingType === "autoscale" && userPaymentPlan?.id === PricingPlanNames.STARTER;
    const {
        app: {
            image,
            configuration,
            namespace,
            monitoring: {
                cpuLimit,
                initialCpu,
                initialMemory,
                memoryLimit,
                minReplicas,
                maxReplicas,
                cpuUsage,
                memoryUsage,
                runningPods
            }
        }
    } = useOutletContext<{ app: IAppDetails }>();

    const deployApplication = async () => {
        const updatedValues = scalingForm.getFieldsValue(true);
        const microserviceRequest: DeployMicroserviceRequest = {
            deployment: {
                image,
                configurations: configuration,
                containerPort: parseInt(configuration?.find(c => c.key === "MIC_CONTAINER_PORT")?.value || "80")
            },
            autoScaling: {
                cpu: +updatedValues.cpu,
                maxCpu: +updatedValues.maxCpu,
                memory: +updatedValues.memory,
                maxMemory: +updatedValues.maxMemory,
                cpuUtilization: 80,
                memoryUtilization: 80,
                minReplicas: +updatedValues.minReplicas,
                maxReplicas: scalingType === "fixedSize" ? +updatedValues.minReplicas : +updatedValues.maxReplicas
            }
        }

        await getKubeService().deployMicroservice(
            appName!,
            clusterId!,
            namespace,
            projectId!,
            microserviceRequest
        )
    }

    useEffect(() => {
        async function loadData() {
            if (userPaymentPlan?.id === PricingPlanNames.FREE) {
                const starterPlan = await getDetailedPaymentPlan(PricingPlanNames.STARTER);
                setNextPaymentPlan(starterPlan);
            } else {
                const advancedPlan = await getDetailedPaymentPlan(PricingPlanNames.ADVANCED);
                setNextPaymentPlan(advancedPlan);
            }
        }
        loadData();
    }, [userPaymentPlan]);

    const handleConfigureScaling = async () => {
        try {
            setLoadingScaling(true);
            await deployApplication();

            notification.success({
                message: "Scaling initiated",
                description: "It would take a few moments to apply the changes.",
                icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
            });
        } catch (error: any) {
            notification.error({
                message: "Application scaling failed",
                description: error.response.data.message
            });
        } finally {
            setLoadingScaling(false);
        }
    }

    const isTrialEligible = (planId: string) => {
        return freeTrials && !(freeTrials as Dictionary<boolean>)[planId];
    }

    const handleSelectPlan = async () => {
        try {
            await getProjectService().changeProjectSubscription(
                id,
                {
                    paymentPlanId: nextPaymentPlan!.id,
                    priceId: nextPaymentPlan!.priceId!
                }
            );
            await updateCurrentProject(id);
        } catch (error: any) {
            notification.error({
                message: `Error activating the ${nextPaymentPlan!.name} plan`,
                description: <>
                    {error.response.data.message}&nbsp;
                    <a href={`/projects/${id}/settings/billing`}>Go to Billing</a> to check your settings.
                </>
            })
        }
    }

    const showSelectPlanConfirm = () => Modal.confirm({
        title: `Activate ${nextPaymentPlan!.name} plan`,
        content:
            <Space direction='vertical'>
                <div>
                    You are about to activate the {nextPaymentPlan!.name} plan that will cost {nextPaymentPlan!.price} per month.
                </div>
                {
                    isTrialEligible(nextPaymentPlan!.id) ?
                        <div>
                            <b>7 day free trial</b> will be applied.
                        </div> :
                        <div>
                            Your credit card will be <b>charged immediately</b> because you've already used your free trial period.
                        </div>
                }
            </Space>,
        okText: `Activate ${nextPaymentPlan!.name} plan`,
        centered: true,
        async onOk() {
            return handleSelectPlan();
        }
    });

    const disabledAppScaling = (<div className="ant-card ant-card-bordered">
        <Row>
            <Col offset={2} span={9} className="flex-align-center">
                <Space direction="vertical" size={24}>
                    <Space size={16} direction="vertical">
                        <Space direction="vertical" size={0}>
                            <Title level={4} style={{ color: "green", fontWeight: "bold", }}>
                                Don't let traffic spikes slow you down.
                                <br />
                                Start scaling today!
                            </Title>
                        </Space>
                        <div>
                            <ul className="no-margin" style={{ paddingLeft: 0, listStyleType: "none" }}>
                                <li><CheckCircleOutlined style={{ color: "green" }} /> High Availability</li>
                                <li><CheckCircleOutlined style={{ color: "green" }} /> Resource Optimization</li>
                                <li><CheckCircleOutlined style={{ color: "green" }} /> Simplified Management</li>
                            </ul>
                        </div>
                    </Space>
                    <Button
                        type="primary"
                        onClick={() => showSelectPlanConfirm()}>
                        <Space>
                            <CrownOutlined />
                            Enable app scaling
                        </Space>
                    </Button>
                </Space>
            </Col>
            <Col offset={1} span={12}>
                <img src={AppScalingBlurScreenshot} alt="cost-explorer" className="cost-dashboard-screenshot" />
            </Col>
        </Row>
    </div>)


    const addonPlus = (type: "minReplicas" | "maxReplicas") => (
        <Button
            type="text"
            size="small"
            disabled={disabledAppScalingProperties}
            onClick={() => {
                if (type === "minReplicas") {
                    const minReplicas = scalingForm.getFieldValue("minReplicas");
                    const updatedMinReplicas = minReplicas + 1;
                    scalingForm.setFieldValue(type, updatedMinReplicas);
                } else {
                    const maxReplicas = scalingForm.getFieldValue("maxReplicas");
                    const updatedMaxReplicas = maxReplicas + 1;
                    scalingForm.setFieldValue(type, updatedMaxReplicas);
                }
            }}
        >
            <PlusOutlined
                style={{
                    fontSize: 16,
                }}
            />
        </Button>
    );

    const addonMinus = (type: "minReplicas" | "maxReplicas") => (
        <Button
            type="text"
            size="small"
            disabled={disabledAppScalingProperties}
            onClick={() => {
                if (type === "minReplicas") {
                    const minReplicas = scalingForm.getFieldValue("minReplicas");
                    const updatedMinReplicas = minReplicas - 1;
                    scalingForm.setFieldValue("minReplicas", updatedMinReplicas)
                } else {
                    const maxReplicas = scalingForm.getFieldValue("maxReplicas");
                    const updatedMaxReplicas = maxReplicas - 1;
                    scalingForm.setFieldValue("maxReplicas", updatedMaxReplicas)
                }
            }}>
            <MinusOutlined
                style={{
                    fontSize: 16
                }}
            />
        </Button>
    );

    const appScaling = (<Space direction="vertical" size="large">
        Automated scaling will be initiated when the application reaches 80% of the maximum assigned CPU or Memory resources, within the maximum number of instances.
        <Form
            form={scalingForm}
            layout="vertical"
            requiredMark={requiredMark}
            initialValues={{
                cpu: initialCpu,
                maxCpu: cpuLimit,
                memory: initialMemory,
                maxMemory: memoryLimit,
                minReplicas: minReplicas,
                maxReplicas: maxReplicas
            }}
            onFinish={handleConfigureScaling}
        >
            <Collapse defaultActiveKey="cpu">
                {/* cpu */}
                <Collapse.Panel key="cpu" header="CPU allocation">
                    <Row gutter={[24, 12]}>
                        <Col span={12}>
                            <Form.Item
                                name="cpu"
                                label="Minimum"
                                required
                                tooltip="The initial CPU resources given to the application"
                                rules={[{ required: true, message: `Please input the minimum CPU resources!` }]}
                            >
                                <Input type="number" addonAfter="mCPU" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="maxCpu"
                                label="Maximum"
                                required
                                tooltip="The maximum allowed CPU resources given to the application"
                                rules={[{ required: true, message: `Please input the maxumim CPU resources!` }]}
                            >
                                <Input type="number" addonAfter="mCPU" />
                            </Form.Item>
                        </Col>
                        <Col>
                            {
                                cpuUsage === "NaNm" ?
                                    <><LoadingOutlined /> Collecting usage data:</> :
                                    <><InfoCircleOutlined /> The application is currently using <b>{cpuUsage} vCPU</b>.</>
                            }
                        </Col>
                    </Row>
                </Collapse.Panel>

                {/* memory */}
                <Collapse.Panel key="memory" header="Memory allocation">
                    <Row gutter={[24, 12]}>
                        <Col span={12}>
                            <Form.Item
                                name="memory"
                                label="Minimum"
                                required
                                tooltip="The initial memory given to the application"
                                rules={[{ required: true, message: `Please input the minimum memory!` }]}
                            >
                                <Input type="number" addonAfter="MiB" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="maxMemory"
                                label="Maximum"
                                required
                                tooltip="The maximum allowed memory given to the application"
                                rules={[{ required: true, message: `Please input the maxumim memory!` }]}
                            >
                                <Input type="number" addonAfter="MiB" />
                            </Form.Item>
                        </Col>
                        <Col>
                            {
                                memoryUsage === "NaNMi" ?
                                    <><LoadingOutlined /> Collecting usage data</> :
                                    <><InfoCircleOutlined /> The application is currently using <b>{memoryUsage}B</b>.</>
                            }
                        </Col>
                    </Row>
                </Collapse.Panel>

                {/* replicas/instances */}
                <Collapse.Panel key="instances" header="Scaling">
                    {
                        <>
                            <Row gutter={[24, 12]}>
                                <Col span={24}>
                                    <Space direction="vertical">
                                        <Text style={{ fontSize: 15, paddingBottom: 10 }} strong>Select a scaling type</Text>
                                        <Radio.Group
                                            defaultValue={scalingType}
                                            onChange={(e) => { setScalingType(e.target.value); }}>
                                            <Radio value="fixedSize">Fixed size</Radio>
                                            <Radio value="autoscale">
                                                {
                                                    userPaymentPlan?.id === PricingPlanNames.STARTER && <><CrownOutlined style={{ color: "goldenrod" }} />&nbsp;</>
                                                }
                                                Autoscale
                                            </Radio>
                                        </Radio.Group>
                                    </Space>
                                </Col>
                            </Row>
                            <br />
                            <Row gutter={[24, 12]}>
                                <Col style={{ color: "GrayText" }} span={18}>
                                    {
                                        scalingType === "autoscale" ?
                                            "Autoscaling is ideal for effortlessly managing unpredictable workloads with precision, as it automatically applies scaling when needed." :
                                            "Ideal for workloads with predictable patterns. Allows manual add and remove of instances"
                                    }
                                </Col>
                                {
                                    disabledAppScalingProperties ?
                                        <Col span={6} style={{ display: "inline-flex", alignSelf: "center" }}>
                                            <Button
                                                onClick={() => showSelectPlanConfirm()}
                                                style={{ width: "90%" }}
                                                type="primary">
                                                Upgrade Now
                                            </Button>
                                        </Col> : null
                                }
                            </Row>
                            <br />
                            <Row gutter={[24, 12]}>
                                <Col span={24} style={{ paddingBottom: 10 }}>
                                    <Text style={{ fontSize: 15 }} strong>Scaling range</Text>
                                </Col>
                            </Row>
                            <Row gutter={[24, 12]}>
                                <Col span={12}>
                                    <Form.Item
                                        name="minReplicas"
                                        wrapperCol={{ span: 13 }}
                                        label={scalingType === "fixedSize" ? "Instances" : "Minimum"}
                                        required
                                        rules={[
                                            { required: true, message: "Please input the instances!" },
                                            {
                                                validator: async (_, value) => {
                                                    if (value < 1) {
                                                        throw new Error("Should be at least 1");
                                                    }
                                                }
                                            }
                                        ]}
                                    >
                                        <Input
                                            type="text"
                                            inputMode="numeric"
                                            style={{ textAlign: "center" }}
                                            addonAfter={addonPlus("minReplicas")}
                                            addonBefore={addonMinus("minReplicas")}
                                            disabled={disabledAppScalingProperties}
                                        />
                                    </Form.Item>
                                </Col>
                                <br />
                                {
                                    scalingType === "autoscale" ?
                                        <Col span={12}>
                                            <Form.Item
                                                name="maxReplicas"
                                                label="Maximum"
                                                wrapperCol={{ span: 13 }}
                                                required
                                                rules={[
                                                    { required: true, message: `Please input the maxumim instances!` },
                                                    {
                                                        validator: async (_, value) => {
                                                            if (value < 1) {
                                                                throw new Error("Should be at least 1");
                                                            }
                                                            if (value < minReplicas) {
                                                                throw new Error(`Should be higher or equal to minimum instances`);
                                                            }
                                                        }
                                                    }
                                                ]}>
                                                <Input
                                                    type="text"
                                                    inputMode="numeric"
                                                    style={{ textAlign: "center" }}
                                                    disabled={disabledAppScalingProperties}
                                                    addonAfter={addonPlus("maxReplicas")}
                                                    addonBefore={addonMinus("maxReplicas")}
                                                />
                                            </Form.Item>
                                        </Col> : null
                                }
                            </Row>
                            <Row gutter={[24, 12]}>
                                <Col>
                                    <InfoCircleOutlined /> The application is currently running on <b>{runningPods}</b> instance(s).
                                </Col>
                            </Row>
                        </>
                    }
                </Collapse.Panel>
            </Collapse>
            <br />

            <Row style={{ display: "flex", alignItems: "center" }}>
                <Col span={12}>
                    Learn more about <a href="https://docs.microtica.com/">Application Scaling</a>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                    <Button htmlType="submit" loading={loadingScaling} disabled={userPaymentPlan?.id === "FREE"}>
                        Save
                    </Button>
                </Col>
            </Row>
        </Form>
    </Space>)

    return (
        <Space direction="vertical" className="full-width">
            <Card bordered>
                <Card.Meta
                    title={
                        <>
                            <span>
                                Scaling
                            </span>
                            <ExplanationButton
                                content={<AppSettingsScalingExplanation />}
                            />
                        </>
                    }
                    description="Limit the resources that application should use and configure the number of instances."
                />
                <Divider />
                {
                    userPaymentPlan?.id === PricingPlanNames.FREE ?
                        disabledAppScaling : appScaling
                }
            </Card>
        </Space >
    );
}

export default AppSettingsScaling;