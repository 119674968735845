import { Button, Card, Space } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { PricingPlan } from "../../types";
import { ReactNode } from "react";

interface PricingPlanCardProps extends PricingPlan {
    disabled?: boolean;
    loading?: boolean;
    hideSelect?: boolean;
    emphasize?: boolean;
    action?: ReactNode;
    trialEligible?: boolean;
    onSelect?: (plan: PricingPlan) => void;
}

const PricingPlanCard = ({
    disabled,
    loading,
    hideSelect,
    emphasize,
    onSelect,
    id,
    name,
    description,
    features,
    price,
    action,
    trialEligible
}: PricingPlanCardProps) => {

    const handleSelectPlan = async () => {
        onSelect?.({
            id: id,
            name: name,
            description: description,
            features: features,
            price: price
        });
    }

    return (
        <Card className={emphasize ? "full-height ant-card-bordered-extra" : "full-height"}>
            <Space direction="vertical" size="large">
                <Card.Meta
                    title={<b>{name}</b>}
                    description={description}
                />
                {
                    price ?
                        <span>
                            <span style={{ fontSize: "40px", fontWeight: "800" }}>{price}</span>
                            <small> /per month</small>
                            {
                                trialEligible ?
                                    <div>
                                        7 day free trial
                                    </div> :
                                    <div>
                                        &nbsp;
                                    </div>
                            }
                        </span> :
                        <span>
                            <span style={{ fontSize: "40px", fontWeight: "800" }}>Custom</span>
                            {
                                // Just a place holder to keep the buttons in line
                                trialEligible &&
                                <div>
                                    &nbsp;
                                </div>
                            }
                        </span>
                }

                {
                    hideSelect ||
                    (
                        action ? action :
                            <Button
                                type="primary"
                                onClick={handleSelectPlan}
                                loading={loading}
                                disabled={disabled}
                            >
                                Select {name}
                            </Button>
                    )
                }

                <Space direction="vertical">
                    {
                        features.map(feature => (
                            <div key={feature}>
                                <CheckCircleFilled style={{ color: "var(--primary-color)" }} /> {feature}
                            </div>
                        ))
                    }
                </Space>
                <br />
            </Space>
        </Card>
    )
}

export default PricingPlanCard;