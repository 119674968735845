import { Button, Form, Modal } from "antd";
import { useEffect, useState } from "react";
import { RequiredMark } from "antd/lib/form/Form";
import ChooseAwsAccountCard from "../ChooseAwsAccountCard";
import ChooseStageCard from "../ChooseStageCard";
import { useRecoilValue } from "recoil";
import { getCloudService, getEnvironmentService } from "../../backend";
import { currentProjectState } from "../../recoil/project";

interface ChooseAwsAccountModalProps {
    visible: boolean;
    selectedAccount: { accountId?: string, region?: string };
    chooseStage?: boolean;
    onOk: ({ accountId, region, envId }: { accountId: string, region: string, envId?: string }) => void;
    onCancel: () => void;
}
const ChooseAwsAccountModal = ({
    visible,
    selectedAccount,
    chooseStage,
    onOk,
    onCancel
}: ChooseAwsAccountModalProps) => {
    const [form] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>("optional");
    const [awsAccount, setAwsAccount] = useState<{ accountId?: string, region?: string }>({ accountId: undefined, region: undefined })
    const currentProject = useRecoilValue(currentProjectState);
    const [awsAccounts, setAwsAccounts] = useState<{ id: string; name: string }[]>([]);
    const [selectedStage, setSelectedStage] = useState<{ envId?: string }>({ envId: undefined });
    const [stages, setStages] = useState<{ id: string; name: string }[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (selectedAccount?.accountId && !awsAccount.accountId) {
            // set the account from props only when the modal is loaded (when awsAccount is still not set)
            setAwsAccount({ accountId: selectedAccount.accountId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount]);

    useEffect(() => {
        loadAwsAccounts();
        if (chooseStage) {
            loadStages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadAwsAccounts = async () => {
        const { data: { awsAccounts } } = await getCloudService().getAwsAccounts(currentProject!.id);
        setAwsAccounts(awsAccounts.map(acc => ({ id: acc.id, name: acc.accountName })));
    }

    const loadStages = async () => {
        const { data: { stages } } = await getEnvironmentService().getStages(currentProject.id);
        setStages(stages.map(stage => ({ id: stage.id, name: stage.name })));
    }

    return (
        <Modal
            title="Choose AWS Region"
            centered
            closable={false}
            open={visible && !!awsAccounts?.length && (chooseStage ? !!stages?.length : true)}
            footer={[
                <Button
                    key="cancel"
                    onClick={() => {
                        onCancel();
                    }}
                >
                    Cancel
                </Button>,
                <Button
                    htmlType="submit"
                    type="primary"
                    key="continue"
                    disabled={
                        !awsAccount.accountId || !awsAccount.region || (chooseStage && !selectedStage.envId) || loading
                    }
                    loading={loading}
                    onClick={async () => {
                        setLoading(true);
                        onOk({
                            accountId: awsAccount.accountId!,
                            region: awsAccount.region!,
                            envId: selectedStage.envId!
                        });
                    }}
                >
                    Continue
                </Button>
            ]}
            onCancel={() => {
                onCancel();
            }}
        >
            <Form
                form={form}
                layout="horizontal"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14, offset: 1 }}
                labelAlign="right"
                requiredMark={requiredMark}
            >
                {
                    chooseStage &&
                    <>
                        <ChooseStageCard
                            stages={stages}
                            onSelect={setSelectedStage}
                        />
                        <br />
                    </>
                }
                <ChooseAwsAccountCard
                    awsAccounts={awsAccounts}
                    selectedAccount={awsAccount}
                    onSelect={setAwsAccount}
                    visibleInfoForCostReport={true}
                />
            </Form>
        </Modal>
    )
}

export default ChooseAwsAccountModal;