import { atom } from "recoil"

export const paymentPlanRequiredModalState = atom({
    key: "PaymentPlanRequiredModalState",
    default: {
        visible: false
    }
});

export const buildPipelineButtonState = atom({
    key: 'BuildPipelineButtonState',
    default: {
        noOfClicks: 0,
        lastClickTimestamp: 0
    }
});

export const deployEnvironmentButtonState = atom({
    key: 'DeployEnvironmentButtonState',
    default: {
        noOfClicks: 0,
        lastClickTimestamp: 0
    }
});