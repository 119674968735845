import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getEnvironmentService, getPipelinesService } from "../../backend";
import PageContentWrapper from "../../components/PageContentWrapper";
import { PageHeader } from "@ant-design/pro-layout";
import { GetComponentResponse, GetStagesItem } from "@microtica/ms-engine-sdk";
import { Avatar, Button, Card, Col, Dropdown, Empty, notification, Row, Segmented, Skeleton, Space, Table, Tabs, Tag, Typography } from "antd";
import { Link } from "react-router-dom";
import {
    ApartmentOutlined,
    ArrowLeftOutlined,
    ControlOutlined,
    LockOutlined,
    NodeIndexOutlined,
    ReadOutlined,
    RocketOutlined,
    UsergroupAddOutlined
} from "@ant-design/icons";
import SkeletonInput from "antd/es/skeleton/Input";
import MarkdownPreview from "@uiw/react-markdown-preview";
import CloudIcon from "../../components/CloudIcon";
import IaCIcon from "../../components/IaCIcon";
import { useRecoilState } from "recoil";
import { projectsState } from "../../recoil/project";
import { Pipeline, PipelineBuildDetails } from "@microtica/ms-ap-sdk";
import { ComponentSchemaInputProp, TreeNode } from "../../types";
import Paragraph from "antd/es/typography/Paragraph";
import TreeMap from "../../components/TreeMap";
import { mapDeploymentStatus } from "../../components/DeploymentStatusTag";
import ShareComponentModal from "../../components/modals/ShareComponentModal";
import GitCommitLink from "../../components/GitCommitLink";
import moment from "moment";
import BuildStatusTag from "../../components/BuildStatusTag";
import CloudAccountLabel from "../../components/CloudAccountLabel";

const { Text } = Typography;

interface TemplateDetailsProps {

}

interface SchemaProp {
    key: string;
    description?: string;
    default?: string;
    enum?: string[];
    required: boolean;
    sensitive?: boolean;
}

interface TabItem {
    key: string;
    label: string;
    children: ReactNode;
    icon: ReactNode;
};

const TemplateComponentDetails = ({

}: TemplateDetailsProps) => {
    const navigate = useNavigate();
    const [projects] = useRecoilState(projectsState);
    const { projectId, componentId } = useParams() as { projectId: string; componentId: string; }
    const [envs, setEnvs] = useState<GetStagesItem[]>([]);
    const [component, setComponent] = useState<GetComponentResponse>();
    const [pipeline, setPipeline] = useState<Pipeline>();
    const [versions, setVersions] = useState<PipelineBuildDetails[]>();
    const [inputProps, setInputProps] = useState<SchemaProp[]>([]);
    const [filter, setFilter] = useState('All');
    const [dependencies, setDependencies] = useState<TreeNode>({ name: "Loading", attributes: { "Type": "Owner" } });
    const [showShareModal, setShowShareModal] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (projects) {
            load();
        }
    }, [projects]);

    const load = async () => {
        try {
            const { data: component } = await getEnvironmentService().getComponent(componentId, projectId);
            const [
                { data: { stages } },
                { data: pipeline },
                { data: { builds } }
            ] = await Promise.all([
                getEnvironmentService().getStages(projectId),
                getPipelinesService().getPipelineById(projectId, component.pipelineId),
                getPipelinesService().getPipelineBuilds(projectId, component.pipelineId),
            ]);

            setEnvs(stages.filter(s =>
                s.cloudProvider === component.cloudProvider &&
                s.infrastructureAsCodeTool === component.infrastructureAsCodeTool
            ));
            setComponent(component);
            setPipeline(pipeline);
            setVersions(builds);

            const ownerId = component.accessList.find(al => al.assigneeType === "parent")?.assigneeId!;
            const deps = {
                name: getProjectDetails(ownerId)?.name!,
                link: `/projects/${ownerId}`,
                attributes: {
                    "Type": "Owner",
                    "Permissions": "OWNER"
                },
                children: component.accessList
                    .filter(al => !!getProjectDetails(al.assigneeId))
                    .map(al => ({
                        name: getProjectDetails(al.assigneeId)?.name!,
                        link: `/projects/${al.assigneeId}`,
                        attributes: {
                            "Type": "Project",
                            "Permissions": al.permissions.join("|")
                        },
                        children: component.instances
                            .filter(i => i.projectId === al.assigneeId)
                            .map(i => ({
                                name: i.stageName,
                                link: `/projects/${al.assigneeId}/environments/${i.stageId}`,
                                attributes: {
                                    "Type": "Environment"
                                },
                                children: component.instances
                                    .filter(ins => ins.stageId === i.stageId)
                                    .map(i => ({
                                        name: i.componentName,
                                        link: `/projects/${al.assigneeId}/environments/${i.stageId}/components/${i.componentName}/pipelines`,
                                        attributes: {
                                            "Type": "Component",
                                            "Status": mapDeploymentStatus(i.stageStatus).status
                                        }
                                    }))
                            }))
                    }))
            };
            setDependencies(deps);
        } catch (error) {
            notification.error({
                message: "Error getting component",
                description: (error as any).response?.data?.message || "We encountered an error while trying to get component details. Please try again."
            });
        } finally {
            setLoading(false);
        }
    }

    const getProjectDetails = (projectId: string) => {
        return projects.find(p => p.id === projectId);
    }

    useEffect(() => {
        setInputProps(
            Object.entries(component?.schema?.properties.inputs.properties || {})
                .filter(([key, prop]) =>
                    filter === "Required" ? component?.schema?.properties?.inputs?.required?.some(r => r === key) :
                        filter === "Sensitive" ? prop.sensitive === true : true
                )
                .map(([key, prop]) => ({
                    key,
                    description: prop.description,
                    default: prop.default,
                    enum: prop.enum,
                    required: !!component?.schema?.properties?.inputs?.required?.some(r => r === key),
                    sensitive: prop.sensitive
                }))
        );
    }, [component, filter]);

    const ConfigurationTab = () => (
        <>
            <Table
                pagination={false}
                dataSource={inputProps}
                sticky={{ offsetHeader: 64 }}
                title={() => (
                    <div className="flex-justify-space-between flex-align-center">
                        <div style={{ fontWeight: 600, fontSize: 16 }}>
                            Input Parameters
                            <div className="gray-text" style={{ fontWeight: 400, fontSize: 14 }}>
                                Configure component using custom input parameters. Example: database type, instance size.
                            </div>
                        </div>
                        <Segmented
                            options={["All", "Required", "Sensitive"]}
                            value={filter}
                            onChange={setFilter}
                        />
                    </div>
                )}
                locale={{
                    emptyText:
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <>
                                    <div>
                                        No input parameters are currently defined for this component.
                                    </div>
                                    <div>
                                        To define input parameters, please add them to the <b>.microtica/schema.json</b> file in your Git repository.
                                    </div>
                                </>
                            }
                        />
                }}
                columns={[
                    {
                        title: "Name",
                        render: (item: SchemaProp) => <>
                            {item.key}
                            {
                                item.required ? <span style={{ color: "red" }}>*</span> : undefined
                            }
                        </>
                    },
                    {
                        title: "Description",
                        render: (item: ComponentSchemaInputProp) => item.description
                    },
                    {
                        title: "Allowed values",
                        render: (item: SchemaProp) =>
                            <Paragraph ellipsis={{ rows: 2, symbol: "show more", expandable: true }} style={{ margin: 0 }}>
                                {
                                    item.enum?.map(e => (
                                        <Tag bordered={false} style={{ marginBottom: 5 }}>{e}</Tag>
                                    ))
                                }
                            </Paragraph>
                    },
                    {
                        title: "Default",
                        render: (item: SchemaProp) => item.default
                    },
                    {
                        title: "Sensitive",
                        align: "center",
                        width: "100px",
                        render: (item: SchemaProp) => item.sensitive ? <LockOutlined /> : undefined
                    }
                ]}
            />

            <br />
            <br />

            <Table
                pagination={false}
                dataSource={
                    Object.entries(component?.schema?.properties.outputs.properties || {}).map(([key, prop]) => {
                        return {
                            ...prop,
                            label: key
                        };
                    })
                }
                sticky={{ offsetHeader: 64 }}
                title={() => (
                    <div className="flex-justify-space-between">
                        <div style={{ fontWeight: 600, fontSize: 16 }}>
                            Output Parameters
                            <div className="gray-text" style={{ fontWeight: 400, fontSize: 14 }}>
                                Dynamic outputs serve as a gateway for cross-component referencing. Example: database connection string, VPC CIDR.
                            </div>
                        </div>
                    </div>
                )}
                locale={{
                    emptyText:
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <>
                                    <div>
                                        No output parameters are currently defined for this component.
                                    </div>
                                    <div>
                                        To define output parameters, please add them to the <b>.microtica/schema.json</b> file in your Git repository.
                                    </div>
                                </>
                            }
                        />
                }}
                columns={[
                    {
                        title: "Name",
                        render: (item: ComponentSchemaInputProp) => item.label
                    },
                    {
                        title: "Type",
                        render: (item: ComponentSchemaInputProp) => item.type
                    }
                ]}
            />
        </>
    )

    const DependenciesTab = () => (
        <Card>
            <Card.Meta
                title="Component Dependency Map"
                description="Navigate through projects, environments, and components."
            />
            <TreeMap data={dependencies!} />
        </Card>
    )

    const VersionsTab = () => (
        <Table
            pagination={false}
            dataSource={versions}
            locale={{
                emptyText:
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <>
                                <div>
                                    This template does not have any versions yet!
                                </div>
                                <div>
                                    Please either push changes on Git or manually trigger a pipeline from the branch.
                                </div>
                            </>
                        }
                    />
            }}
            columns={[
                {
                    title: 'Author',
                    key: 'author',
                    width: "100px",
                    render: (item: PipelineBuildDetails) => (
                        <div style={{ fontWeight: 700, fontSize: "13px" }}>
                            <Avatar className="no-border" src={item.metadata.commit.user.avatar} />
                        </div>
                    )
                },
                {
                    title: 'Commit',
                    key: 'commit',
                    render: (item: PipelineBuildDetails) => (
                        <Space direction="vertical" style={{ fontSize: "12px" }}>
                            <div style={{ fontSize: "12px", lineHeight: "20px" }}>
                                <b>{item.metadata.commit.message} </b>
                                <div>
                                    <GitCommitLink
                                        version={item.metadata.commit.sha || item.metadata.commit.version}
                                        repoUrl={item.metadata.repository}
                                        branch={item.metadata.commit.name}
                                    />
                                </div>
                            </div>
                        </Space>
                    )
                },
                {
                    key: 'time',
                    title: "Execution time",
                    render: (item: PipelineBuildDetails) => (
                        <Space direction="vertical" style={{ fontSize: "12px" }}>
                            <div style={{ fontSize: "13px" }}>
                                {moment(item.startDate).fromNow()}
                            </div>
                            <div>
                                {item.metadata.commit.user.name}
                            </div>
                        </Space>
                    )
                },
                {
                    key: 'status',
                    title: "Status",
                    align: "right",
                    render: (build: PipelineBuildDetails) => (
                        <BuildStatusTag status={build.status} statusCode={build.statusCode} />
                    )
                }
            ]}
        />
    )

    return (
        <PageContentWrapper
            size="large"
            header={
                <PageHeader>
                    {
                        component ?
                            <div className="flex-justify-space-between flex-align-center">
                                <div>
                                    <Space size="large">
                                        <Link to={`/projects/${projectId}/templates`}>
                                            <Button icon={<ArrowLeftOutlined />} />
                                        </Link>
                                        <div>
                                            <div style={{ fontSize: "16px", }}>
                                                Template
                                            </div>
                                            <div style={{ fontSize: "20px", fontWeight: 600 }}>
                                                {component.name}
                                            </div>
                                        </div>
                                    </Space>
                                </div>
                                <Space>
                                    <Dropdown
                                        menu={{
                                            items: envs.map(e => ({
                                                key: e.id,
                                                label: <>
                                                    <div style={{ padding: "10px" }}>
                                                        <div style={{ fontSize: "16px", fontWeight: 500 }}>
                                                            {e.name}
                                                        </div>
                                                        <div className="gray-text">
                                                            <CloudAccountLabel
                                                                provider={e.cloudProvider}
                                                                envId={e.id}
                                                                accountId={e.awsAccountId || e.gcpProjectId}
                                                                region={e.awsRegion || e.gcpRegion}
                                                            />
                                                        </div>
                                                    </div>
                                                </>,
                                                onClick: () => {
                                                    navigate(
                                                        `/projects/${projectId}/environments/${e.id}/templates/${component.id}/add-component?templateName=${component.name}`
                                                    )
                                                }
                                            }))
                                        }}
                                        trigger={["click"]}
                                        placement="bottomRight"
                                    >
                                        <Button type="primary">
                                            <RocketOutlined rotate={45} /> Deploy
                                        </Button>
                                    </Dropdown>
                                    {
                                        component.accessList.some(al => al.assigneeType === "parent" && al.assigneeId === projectId) &&
                                        <Button onClick={() => setShowShareModal(true)}>
                                            <UsergroupAddOutlined /> Share
                                        </Button>
                                    }
                                </Space>
                            </div> :
                            <Space direction="vertical">
                                <SkeletonInput size="small" />
                                <SkeletonInput />
                            </Space>
                    }
                </PageHeader>
            }
        >
            {/* Overview */}
            <Card>
                <Card.Meta
                    title="Description"
                    description={component?.description}
                />
                <br />
                <Row gutter={[12, 12]}>
                    <Col span={6}>
                        <Card size="small">
                            <Card.Meta
                                title={
                                    <div style={{ fontSize: "12px" }}>
                                        <div className="gray-text">
                                            Cloud provider
                                        </div>

                                        {
                                            loading ? <Skeleton.Input size="small" /> :
                                                <Text
                                                    ellipsis={{ tooltip: component?.cloudProvider === "aws" ? "Amazon Web Services (AWS)" : "Google Cloud Platform (GCP)" }}
                                                    style={{ width: "50vw" }}
                                                >
                                                    <CloudIcon provider={component?.cloudProvider} />
                                                    <span style={{ marginLeft: "8px" }}>
                                                        {
                                                            component?.cloudProvider === "aws" ? "Amazon Web Services (AWS)" : "Google Cloud Platform (GCP)"
                                                        }
                                                    </span>
                                                </Text>
                                        }
                                    </div>
                                }
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card size="small">
                            <Card.Meta
                                title={
                                    <div style={{ fontSize: "12px" }}>
                                        <div className="gray-text">
                                            Infrastructure as a Code
                                        </div>
                                        {
                                            loading ? <Skeleton.Input size="small" /> :
                                                <Text
                                                    ellipsis={{ tooltip: component?.infrastructureAsCodeTool }}
                                                    style={{ width: "50vw" }}
                                                >
                                                    <IaCIcon provider={component?.infrastructureAsCodeTool} />
                                                    <span style={{ marginLeft: "8px" }}>
                                                        {
                                                            component?.infrastructureAsCodeTool === "cloudformation" ? "AWS CloudFormation" : "Terraform"
                                                        }
                                                    </span>
                                                </Text>
                                        }
                                    </div>
                                }
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card size="small">
                            <Card.Meta
                                title={
                                    <div style={{ fontSize: "12px" }}>
                                        <div className="gray-text">
                                            Repository
                                        </div>
                                        {
                                            loading ? <Skeleton.Input size="small" /> :
                                                pipeline ?
                                                    <Text ellipsis={{ tooltip: pipeline.repositoryUrl }}>
                                                        <a href={pipeline.repositoryUrl} target="_blank">
                                                            {pipeline.repositoryUrl}
                                                        </a>
                                                    </Text> :
                                                    "n/a"
                                        }
                                    </div>
                                }
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card size="small">
                            <Card.Meta
                                title={
                                    <div style={{ fontSize: "12px" }}>
                                        <div className="gray-text">
                                            Branch filter
                                        </div>
                                        {
                                            loading ? <Skeleton.Input size="small" /> :
                                                <Text ellipsis={{ tooltip: pipeline?.branchFilter || "n/a" }}>
                                                    {pipeline?.branchFilter || "n/a"}
                                                </Text>
                                        }
                                    </div>
                                }
                            />
                        </Card>
                    </Col>
                </Row>

            </Card>

            <br />
            <br />

            {
                loading ? <Skeleton /> :
                    <Tabs items={[
                        ...component?.isPublic == false ?
                            [{
                                key: "dependencies",
                                label: "Dependencies",
                                children: <DependenciesTab />,
                                icon: <ApartmentOutlined />
                            }] : [],
                        ...[
                            {
                                key: "readme",
                                label: "Readme",
                                children: <Card>
                                    {
                                        component?.readme ?
                                            <MarkdownPreview
                                                source={component?.readme!}
                                                wrapperElement={{
                                                    "data-color-mode": "light"
                                                }}
                                                style={{ padding: "10px" }}
                                            /> :
                                            <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                description={
                                                    <>
                                                        <div>
                                                            <b>No README.md Available</b>
                                                        </div>
                                                        <div>
                                                            To provide a README and have it rendered here, please add a README.md file to the <b>.microtica/README.md</b> of your project.
                                                        </div>
                                                    </>
                                                }
                                            />
                                    }
                                </Card>,
                                icon: <ReadOutlined />
                            },
                            {
                                key: "schema",
                                label: "Configuration",
                                children: <ConfigurationTab />,
                                icon: <ControlOutlined />
                            },
                            {
                                key: "Versions",
                                label: "Versions",
                                children: <VersionsTab />,
                                icon: <NodeIndexOutlined />
                            }
                        ]
                    ]} />
            }


            {
                showShareModal &&
                <ShareComponentModal
                    componentId={component?.id!}
                    open={showShareModal}
                    onCancel={() => {
                        load();
                        setShowShareModal(false);
                    }}
                />
            }
        </PageContentWrapper>
    )
}

export default TemplateComponentDetails;