const AppSettingsPipelineEnvironmentVariablesExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Environment variables
            </div>
            <div className="explanation-subtitle">
                Dynamic pipeline variables
            </div>
            <br />

            <p>
                Microtica allows you to define custom variables that can be used to parametrize the way your pipeline works.
            </p>
            <p>
                In each pipeline, Microtica will automatically inject a predefined set of variables like <code style={{ color: "var(--primary-color)" }}>MIC_PIPELINE_EXECUTION_ID, MIC_USER_NAME, MIC_COMMIT_REF</code> etc. that can be used in conjunction with the custom variables.
            </p>
            <p>
                <span>Use pipeline variables when you want to:</span>
                <ul>
                    <li>parametrize the way your pipeline works</li>
                    <li>avoid hard-coding within the step execution logic</li>
                    <li>avoid storing sensitive information within the pipeline yaml</li>
                </ul>
            </p>
            <p>
                <span>Microtica offers two types of variables:</span>
                <ul>
                    <li>plaintext variables</li>
                    <li>sensitive variables</li>
                </ul>
            </p>
            <p>
                Learn more about <a href="https://docs.microtica.com/variables" target="_blank" rel="noreferrer">pipeline environment variables.</a>
            </p>
        </>
    )
}

export default AppSettingsPipelineEnvironmentVariablesExplanation;