import { atom } from "recoil"
import { PaymentMethod, Project } from "@microtica/ms-project-sdk";
import { ProjectDetails } from "../types";

export interface ProjectRecoilState extends ProjectDetails {
    subscription?: any;
    paymentMethods?: PaymentMethod[]
}

export const projectsState = atom({
    key: "ProjectsState",
    default: [] as ProjectDetails[]
});

export const currentProjectState = atom({
    key: "CurrentProjectState",
    default: JSON.parse(localStorage.getItem("project") || "{}") as ProjectRecoilState
});