import { getEnvironmentService, getKubeService, getProjectService } from "../backend";
import { Component, GetComponentsResponseInstances } from "@microtica/ms-engine-sdk";
import { ProjectTemplate } from "../types";
import { TEMPLATES } from "../enums/enums";
import { Project } from "@microtica/ms-project-sdk";
import { GetMicroservicesResponseMicroservices } from "@microtica/ms-kube-sdk";


export const getTemplates = async (authenticated: boolean, projectId?: string,): Promise<ProjectTemplate[]> => {

    const [
        { data: { projects } },
        { data: { components } },
        { data: { microservices: apps } },
    ] = await Promise.all([
        authenticated ?
            getProjectService().listProjects() :
            Promise.resolve({ data: { projects: [] as Project[] } }),
        projectId ?
            getEnvironmentService().getComponents(projectId) :
            Promise.resolve({ data: { components: [] as Component[] } }),
        projectId ?
            getKubeService().getMicroservices(projectId) :
            Promise.resolve({ data: { microservices: [] as GetMicroservicesResponseMicroservices[] } })
    ]);

    const allTemplates = [
        ...TEMPLATES
            .filter(item =>
                item.type === "component" || item.type === "app"
            )
            .map(item => ({
                id: item.key,
                name: item.title,
                description: item.description,
                pack: item.pack,
                type: "template",
                cloudProvider: item.cloudProvider,
                iac: item.iac,
                owner: "microtica",
                public: true,
                numberOfProjects: 0,
                instances: [],
                filterMetadata: [
                    item.key,
                    item.title,
                    item.description,
                    item.cloudProvider,
                    item.iac
                ].join(" "),
                link: `/projects/${projectId}/templates/${item.key}?template=${item.link}`
            })),
        ...components.map(component => ({
            id: component.id,
            name: component.name,
            description: component.description,
            type: component.isPublic ? "template" : "component",
            pack: "free",
            cloudProvider: component.cloudProvider,
            iac: component.infrastructureAsCodeTool,
            owner: component.isPublic ?
                "microtica" :
                projects.find(p => p.id === component.accessList.find(al => al.assigneeType === "parent")?.assigneeId)!,
            public: component.isPublic,
            numberOfProjects: component.accessList.length,
            instances: component.instances.reduce((acc, i) => {
                // Check if the environment already exists in the accumulator
                // and if user has access to the project
                if (
                    !acc.some(instance => instance.stageId === i.stageId) &&
                    projects.some(p => p.id === i.projectId)
                ) {
                    acc.push(i);
                }
                return acc;
            }, [] as GetComponentsResponseInstances[]),
            filterMetadata: [
                component.name,
                component.description,
                component.cloudProvider,
                component.infrastructureAsCodeTool,
                component.type,
                ...component.instances
                    .filter(i => i.stageName && projects.some(p => p.id === i.projectId))
                    .map(i => `${projects.find(p => p.id === i.projectId)?.name}/${i.stageName}`)
            ].join(" "),
            link: `/projects/${projectId}/templates/components/${component.id}/details`,
        })),
        ...apps.map(app => ({
            id: app.name,
            name: app.name,
            description: app.description,
            type: "component",
            pack: "free",
            iac: "kubernetes",
            owner: projects.find(p => p.id === projectId),
            public: app.isPublic,
            numberOfProjects: 1,
            instances: [],
            filterMetadata: [
                app.name,
                app.description,
                "kubernetes",
                app.imageRepository,
                app.registry
            ].join(" "),
            link: `/projects/${projectId}/templates/apps/${app.name}/details`
        }))
    ] as ProjectTemplate[];

    return allTemplates;


}