import { CSSProperties, ReactNode, useEffect, useState } from "react";
import { Avatar } from "antd";
import { BuildOutlined, KubernetesOutlined } from "@ant-design/icons";

const TemplateAvatar = ({ name, size, shape, style = {} }: {
    name: string,
    shape?: "circle" | "square",
    size?: "default" | "large" | "small" | "xs" | number,
    style?: CSSProperties;
}) => {
    const [avatarSrc, setAvatarSrc] = useState<ReactNode>();

    useEffect(() => {
        parseAvatarSrc(name);
    }, [name])

    const parseAvatarSrc = (serviceName: string) => {
        if (!serviceName) {
            setAvatarSrc(<BuildOutlined style={{ color: "black", fontSize: 18 }} className="flex-align-center" />);
        } else if (serviceName.toLowerCase().includes("n8n")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/n8n.png");
        } else if (serviceName.toLowerCase().includes("strapi")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/strapi.png");
        } else if (serviceName.toLowerCase().includes("nextjs")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/nextjs.png");
        } else if (serviceName.toLowerCase().includes("nuxtjs")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/nuxtjs.svg");
        } else if (serviceName.toLowerCase().includes("rocketchat")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/rocketchat.png");
        } else if (serviceName.toLowerCase().includes("react")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/reactjs.png");
        } else if (serviceName.toLowerCase().includes("angular")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/angularjs.png");
        } else if (serviceName.toLowerCase().includes("vuejs")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/vuejs.png");
        } else if (serviceName.toLowerCase().includes("eks")) {
            setAvatarSrc(<KubernetesOutlined style={{ fontSize: "23px", color: "#326CE5" }} />);
        } else if (serviceName.toLowerCase().includes("fargate")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/fargate.png");
        } else if (serviceName.toLowerCase().includes("spa")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/cloudfront.png");
        } else if (serviceName.toLowerCase().includes("webiny")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/webiny.png");
        } else if (serviceName.toLowerCase().includes("appwrite")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/appwrite.svg");
        } else if (serviceName.toLowerCase().includes("nginx")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/nginx.png");
        } else if (serviceName.toLowerCase().includes("svelte")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/svelte.png");
        } else if (serviceName.toLowerCase().includes("gatsby")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/gatsby.png");
        } else if (serviceName.toLowerCase().includes("nodejs")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/nodejs.png");
        } else if (serviceName.toLowerCase().includes("gin")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/gin.png");
        } else if (serviceName.toLowerCase().includes("nest")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/nestjs.svg");
        } else if (serviceName.toLowerCase().includes("iris")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/iris.png");
        } else if (serviceName.toLowerCase().includes("django")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/django.png");
        } else if (serviceName.toLowerCase().includes("python")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/python.png");
        } else if (serviceName.toLowerCase().includes("medusa")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/medusa.png");
        } else if (serviceName.toLowerCase().includes("lambda")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/lambda.png");
        } else if (serviceName.toLowerCase().startsWith("gcp-") || serviceName.toLowerCase().startsWith("google")) {
            setAvatarSrc(<img src="https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/gcp.svg" style={{ height: "86%" }} />);
        } else if (serviceName.toLowerCase().includes("terraform")) {
            setAvatarSrc(<img src="https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/terraform.svg" style={{ height: "100%" }} />);
        } else if (serviceName.toLowerCase().includes("cloudformation")) {
            setAvatarSrc(<img src="https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/cloudformation.svg" style={{ height: "120%" }} />);
        } else if (serviceName.toLowerCase().includes("aws")) {
            setAvatarSrc("https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/aws.png");
        } else {
            return setAvatarSrc(<BuildOutlined style={{ color: "black", fontSize: 18 }} className="flex-align-center" />);
        }
    }

    if (shape === "square") {
        style = {
            ...{
                ...style,
                backgroundColor: "var(--layout-body-background)",
                padding: "8px"
            }
        }
    }

    if (size === "xs") {
        style = {
            ...{
                ...style,
                width: 18,
                height: 18
            }
        }
    }

    return (
        <Avatar
            size={!size || size === "xs" ? "default" : size}
            shape={shape}
            className="flex-align-center"
            style={style}
            src={avatarSrc}
        />
        // <Avatar
        //     className="flex-align-center"
        //     src={avatarSrc}
        //     size={!size || size === "xs" ? "default" : size}
        //     style={size === "xs" ? { width: 18, height: 18 } : {}}
        // />

    )
}

export default TemplateAvatar;