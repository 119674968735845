import { Button, List, Modal, Tabs } from "antd";
import { TEMPLATES } from "../../enums/enums";
import { CrownOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { getEnvironmentService, getKubeService } from "../../backend";
import TemplateAvatar from "../TemplateAvatar";
import { GridListDataSource } from "../GridList";
import Search from "antd/es/input/Search";
import { Link } from "react-router-dom";
import { IEnvDetails } from "../../types";

interface ChooseAppModalProps {
    projectId: string;
    env: Omit<IEnvDetails, "resources">;
    filter?: {
        componentId?: string;
        deploymentTarget: { type: "kubernetes" | "fargate", value: string; };
    };
    open?: boolean;
    onOk?: (key: string) => void;
    onCancel?: () => void;
}

const ChooseAppModal = ({
    projectId,
    env,
    filter,
    open,
    onOk,
    onCancel,
}: ChooseAppModalProps) => {
    const [components, setComponents] = useState<GridListDataSource[]>([]);
    const [filteredComponents, setFilteredComponents] = useState<GridListDataSource[]>([]);
    const [nameFilter, setNameFilter] = useState("");
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("templates");


    useEffect(() => {
        const load = async () => {
            setLoading(true);

            const [
                { data: { microservices } },
                { data: { components } }
            ] = await Promise.all([
                getKubeService().getMicroservices(projectId),
                getEnvironmentService().getComponents(projectId)
            ])

            // combine our Components with some of the Templates that we want to be displayed in the Resources section
            const combinedComponents = [
                // add the templates that we want to display in the Resources section
                ...TEMPLATES
                    .filter(item =>
                        !item.group && (filter?.deploymentTarget ? item.deploymentTarget === filter.deploymentTarget.type : true) &&
                        (item.cloudProvider ? item.cloudProvider === env.cloudProvider : true) &&
                        (
                            (item.iac ? item.iac === env.infrastructureAsCodeTool : true) ||
                            (item.iac ? item.iac === "kubernetes" : true)
                        )
                    )
                    .map(item => ({
                        ...item,
                        link:
                            filter?.deploymentTarget.type === "kubernetes" ?
                                `/projects/${projectId}/environments/${env.id}/components/${filter?.componentId}/${filter?.deploymentTarget.value}/templates/${item.key}?template=${item.link}` :
                                `/projects/${projectId}/environments/${env.id}/templates/${item.key}?template=${item.link}`,
                        filterMetadata: `${item.title} ${item.description}`
                    })),
                ...components
                    // Display only a few of our Components that dont require a VPC. These are prod values
                    .filter(item => item.cloudProvider === env.cloudProvider?.toString() &&
                        item.infrastructureAsCodeTool === env.infrastructureAsCodeTool?.toString() &&
                        item.type === "fargate"
                    )
                    // map components to the format we need to display
                    .map(resource => {
                        return {
                            resource, // we need this to pass along in the navigateState later
                            key: resource.id,
                            title: resource.name,
                            description: resource.description,
                            link: `/projects/${projectId}/environments/${env.id}/templates/${resource.id}/add-component?templateName=${resource.name}`,
                            avatar: <TemplateAvatar name={resource.cloudProvider} shape="square" size="large" />,
                            pack: resource.isPublic ? "free" : undefined,
                            tags: [
                                {
                                    position: "top",
                                    value: <span style={{ color: "GrayText", fontSize: 12 }}>Starter</span>
                                }
                            ],
                            template: ["Networking", "Amazon S3", "Amazon DynamoDB", "CloudFront + S3"].includes(resource.name),
                            filterMetadata: `${resource.name} ${resource.description} ${resource.type}`
                        }
                    }),
                ...microservices
                    // Display only a few of our Components that dont require a VPC. These are prod values
                    // map components to the format we need to display
                    .map(app => {
                        return {
                            resource: app,           // we need this to pass along in the navigateState later
                            key: app.id,
                            title: app.name,
                            description: app.description,
                            link: filter?.deploymentTarget.type === "kubernetes" ?
                                `/projects/${projectId}/environments/${env.id}/components/${filter?.componentId}/${filter?.deploymentTarget.value}/templates/${app.name}/add-app` :
                                `/projects/${projectId}/environments/${env.id}/templates/${app.name}/add-app`,
                            avatar: <TemplateAvatar name="" shape="square" size="large" />,
                            tags: [
                                {
                                    position: "top",
                                    value: <span style={{ color: "GrayText", fontSize: 12 }}>Starter</span>
                                }
                            ],
                            template: ["Networking", "Amazon S3", "Amazon DynamoDB", "CloudFront + S3"].includes(app.name),
                            filterMetadata: `${app.name} ${app.description}`
                        }
                    })
            ];
            setComponents(combinedComponents);
            setLoading(false);
        }

        load();
    }, []);

    useEffect(() => {
        if (activeTab === "templates") {
            setFilteredComponents(
                components
                    .filter(c => !!c.pack)
                    .filter(c => nameFilter ? c.filterMetadata?.toLocaleLowerCase().includes(nameFilter.toLocaleLowerCase()) : true)
            );
        } else {
            setFilteredComponents(
                components
                    .filter(c => !c.pack)
                    .filter(c => nameFilter ? c.filterMetadata?.toLocaleLowerCase().includes(nameFilter.toLocaleLowerCase()) : true)
            );
        }

    }, [components, activeTab, nameFilter]);

    return <Modal
        title={
            <div>
                <div>Choose template to deploy</div>
                <div className="gray-text" style={{ fontSize: "12px", fontWeight: 500 }}>
                    {
                        filter?.deploymentTarget.type === "kubernetes" ?
                            "Application will be deployed on Kubernetes cluster" :
                            "Application will be deployed on the existing or newly created cluster"
                    }

                </div>
            </div>
        }
        centered
        closable
        open={open}
        footer={null}
        onCancel={onCancel}
        className="ant-scollable-modal"
    >
        <br />
        <Tabs
            size="small"
            activeKey={activeTab}
            onChange={setActiveTab}
            items={[{
                key: "templates",
                label: "Microtica templates"
            }, {
                key: "my",
                label: "My templates"
            }]}
        />
        <List
            header={
                <Search className="full-width" placeholder="Search apps" onSearch={setNameFilter} />
            }
            loading={loading}
            dataSource={filteredComponents}
            renderItem={(item) => (
                <List.Item
                    extra={
                        item.link ? <Link to={item.link}>
                            <Button>Deploy</Button>
                        </Link> : undefined
                    }
                >
                    <List.Item.Meta
                        avatar={item.avatar}
                        title={
                            <>
                                {item.title} {item.pack && item.pack !== "free" ? <CrownOutlined style={{ color: "goldenrod" }} /> : undefined}
                            </>
                        }
                        description={item.description}
                    />
                </List.Item>
            )}
        >

        </List>
    </Modal>

}

export default ChooseAppModal;