import { Column } from "@ant-design/plots"
import { Annotation, Datum, PatternAttr } from "@antv/g2plot";
import { Card } from "antd"
import { each, groupBy } from '@antv/util';
import { convertToCurrency } from "../../../utils/conversion";
import WidgetSkeleton from "./WidgetSkeleton";
import { useMemo } from "react";

interface ColumnWidgetProps {
    title: string;
    description: string;
    data: { value: number; type: string; entity: string; }[];
    xField: string;
    yField: string;
    seriesField: string;
    color?: ((datum: Datum, defaultColor?: string) => string);
    pattern?: PatternAttr;
    loading?: boolean;
}

const ColumnWidget = ({
    title,
    description,
    data,
    xField,
    yField,
    seriesField,
    color,
    pattern,
    loading
}: ColumnWidgetProps) => {

    const columnChart = useMemo(() => {
        const annotations: Annotation[] = [];

        // Add total estimated cost at the top of the column bar
        each(groupBy(data, xField), (values, k) => {
            const value = values.reduce((a: number, b: { value: number }) => {
                return a + b.value;
            }, 0);
            annotations.push({
                type: 'text',
                position: [k, value],
                content: `$${value}`,
                style: {
                    textAlign: 'center',
                    fontSize: 14,
                    fontWeight: "bold"
                },
                offsetY: -10,
            });
        });

        return (
            <Column
                data={data}
                isStack={true}
                xField={xField}
                yField={yField}
                seriesField={seriesField}
                legend={false}
                appendPadding={[10, 0, 0, 0]}
                tooltip={{
                    formatter: (v: Datum) => {
                        return {
                            name: v[seriesField],
                            value: convertToCurrency(v.value)
                        }
                    }
                }}
                annotations={annotations}
                interactions={[
                    {
                        type: 'active-region',
                        enable: false,
                    },
                ]}
                height={250}
                color={color}
                pattern={pattern}
            />
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, xField, yField, seriesField]);

    return (
        <Card>
            <Card.Meta
                title={title}
                description={description}
            />
            <br />
            {
                loading ?
                    <WidgetSkeleton width={370} height={150} /> :
                    columnChart
            }
        </Card>
    )
}


export default ColumnWidget