import { LoadingOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { useEffect, useState } from "react";
import { getMonitoringService } from "../../backend";
import { useParams } from "react-router";
import PageContentWrapper from "../../components/PageContentWrapper";
import { Link } from "react-router-dom";


const ProjectMonitoringLogin = () => {
    const { projectId } = useParams() as { projectId: string };
    const [loginError, setLoginError] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                const { data: { accessToken, idToken } } = await getMonitoringService().getMonitoringCredentials(projectId)
                if (accessToken && idToken) {
                    window.location.href = `https://monitoring.microtica.com/auth/callback?access_token=${accessToken}&id_token=${idToken}`;
                }
            } catch (error) {
                setLoginError(true)
            }
        })()
    }, []);

    return (
        <PageContentWrapper size="large">
            {
                loginError ?
                    <Result
                        title="Login error!"
                        status="error"
                        subTitle={
                            <>
                                <div>
                                    Please ensure that active monitoring is enabled for at least one cluster within this project.
                                </div>
                                <div>
                                    If you require further assistance or have any questions, don't hesitate to contact our support team at <a href="mailto:support@microtica.com">support@microtica.com</a>.
                                </div>
                            </>
                        }
                        extra={
                            <Link to={`/projects/${projectId}/monitoring`}>
                                <Button>
                                    Go to Monitoring Dashboard
                                </Button>
                            </Link>
                        }
                    /> :
                    <Result
                        title="Logging in..."
                        icon={<LoadingOutlined />}
                        subTitle={
                            <div>
                                Hang tight! You will be automatically redirected to Metrics Explorer shortly.
                            </div>
                        }
                    />
            }
        </PageContentWrapper>
    )
}

export default ProjectMonitoringLogin;