import { Button, Card, Divider, Skeleton, Space, Steps } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import Heading from "../../components/Heading";
import PageContentWrapper from "../../components/PageContentWrapper";
import { currentProjectState } from "../../recoil/project";
import { Link } from "react-router-dom";
import TemplateAvatar from "../../components/TemplateAvatar";
import { WindowConfig } from "../../types";

const Welcome = () => {

    const currentProject = useRecoilValue(currentProjectState);
    const windowWithType = window as unknown as WindowConfig;

    const handleOpenChat = () => {
        windowWithType.gist.chat("navigate", "newConversation"); // Open Message screen
    }

    return (
        <PageContentWrapper size="large">
            {
                !currentProject ?
                    <Skeleton />
                    :
                    <>
                        <Card style={{ padding: "20px" }}>
                            <Heading
                                title="Welcome to Microtica! 🎉"
                                description="Thanks for joining Microtica. We are so happy to have you on board!"
                            />

                            <Divider />

                            <Steps
                                direction="vertical"
                                current={2}
                                items={[
                                    {
                                        title:
                                            <Space>
                                                Created Project - {currentProject.name}
                                            </Space>,
                                        status: "finish",
                                        description: `A new project called '${currentProject.name}' was created. You can change the name in project settings.`
                                    },
                                    {
                                        title: <>Created Environment - development</>,
                                        status: "finish",
                                        description: "Environment is the place where you organize and deploy your apps and infrastructure."
                                    },
                                    {
                                        title: <b>Deploy your first app</b>,
                                        status: "process",
                                        description:
                                            <div style={{ marginLeft: 0 }}>
                                                <br />
                                                <Space>
                                                    <Link to={`/projects/${currentProject.id}/templates/import-app?template=https://raw.githubusercontent.com/microtica/templates/master/import-app/template.yaml&import=true`}>
                                                        <Button style={{ height: "40px" }}>
                                                            Import an existing app
                                                        </Button>
                                                    </Link>
                                                    <Divider type="vertical" />
                                                    <Link to={`/projects/${currentProject.id}/templates/strapi-serverless?template=https://raw.githubusercontent.com/microtica/templates/master/strapi-serverless/.microtica/template.yaml`}>
                                                        <Card size="small" hoverable>
                                                            <Card.Meta
                                                                title={
                                                                    <Space className="flex-align-center">
                                                                        <TemplateAvatar size="small" name="strapi" />
                                                                        Strapi Serverless
                                                                    </Space>
                                                                }
                                                            />
                                                        </Card>
                                                    </Link>
                                                    <Link to={`/projects/${currentProject.id}/templates/medusa-server?template=https://raw.githubusercontent.com/microtica/templates/master/medusa-server/.microtica/template.yaml`}>
                                                        <Card size="small" hoverable>
                                                            <Card.Meta
                                                                title={
                                                                    <Space className="flex-align-center">
                                                                        <TemplateAvatar size="small" name="medusa" />
                                                                        Medusa
                                                                    </Space>
                                                                }
                                                            />
                                                        </Card>
                                                    </Link>
                                                    <Link to={`/projects/${currentProject.id}/templates/nextjs?template=https://raw.githubusercontent.com/microtica/templates/master/nextjs/.microtica/template.yaml`}>
                                                        <Card size="small" hoverable>
                                                            <Card.Meta
                                                                title={
                                                                    <Space className="flex-align-center">
                                                                        <TemplateAvatar size="small" name="nextjs" />
                                                                        Next.js
                                                                    </Space>
                                                                }
                                                            />
                                                        </Card>
                                                    </Link>
                                                    <Link to={`/projects/${currentProject.id}/templates/nodejs?template=https://raw.githubusercontent.com/microtica/templates/master/nodejs/.microtica/template.yaml`}>
                                                        <Card size="small" hoverable>
                                                            <Card.Meta
                                                                title={
                                                                    <Space className="flex-align-center">
                                                                        <TemplateAvatar size="small" name="nodejs" />
                                                                        Node.js
                                                                    </Space>
                                                                }
                                                            />
                                                        </Card>
                                                    </Link>
                                                    <Link to={`/projects/${currentProject.id}/templates`}>
                                                        <Button type="link">Explore all templates <RightOutlined /></Button>
                                                    </Link>
                                                </Space>
                                                <br />
                                                <br />
                                            </div>
                                    }
                                ]}
                            />
                        </Card>

                        {/* <Divider />

                        <GridList itemsInRow={4} hoverable={false} dataSource={[
                            {
                                key: "learn",
                                title: "Learn",
                                avatar: <Avatar size="large" shape="square" style={{ backgroundColor: "var(--layout-body-background)" }} icon={<StarOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />} />,
                                description: "Browse our free library of videos and documentation.",
                                tags: [
                                    {
                                        position: "bottom",
                                        value: <a href="https://docs.microtica.com" target="_blank" rel="noreferrer">Browse documentation <RightOutlined /></a>
                                    }
                                ]
                            },
                            {
                                key: "explore",
                                title: "Explore",
                                avatar: <Avatar size="large" shape="square" style={{ backgroundColor: "var(--layout-body-background)" }} icon={<EyeOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />} />,
                                description: "Explore and contribute to our open-source templates library.",
                                tags: [
                                    { position: "bottom", value: <Link to="/templates">Browse templates <RightOutlined /></Link> }
                                ]
                            },
                            {
                                key: "community",
                                title: "Community",
                                avatar: <Avatar size="large" shape="square" style={{ backgroundColor: "var(--layout-body-background)", padding: "8px" }} icon={<img src={DiscordLogo} alt="discord" />} />,
                                description: "Join our growing community and get in touch with us.",
                                tags: [{ position: "bottom", value: <a href="https://discord.gg/ADaFvAsakW" target="_blank" rel="noreferrer">Join on Discord <RightOutlined /></a> }]
                            },
                            {
                                key: "feedback",
                                title: "Send feedback",
                                avatar: <Avatar size="large" shape="square" style={{ backgroundColor: "var(--layout-body-background)" }} icon={<CommentOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />} />,
                                description: "Join our growing community and get in touch with us.",
                                tags: [{ position: "bottom", value: <span className="span-link" onClick={handleOpenChat}>Open the chat <RightOutlined /></span> }]
                            }
                        ]} /> */}
                    </>
            }
        </PageContentWrapper>
    )
}

export default Welcome;