import { Tag, Tooltip } from "antd";
import { SyncOutlined, CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined, StopOutlined } from '@ant-design/icons';

export default function PipelineStatusTag(props: { status?: string }) {

    if (!props.status) {
        return null;
    }

    if (["QUEUED", "PENDING"].includes(props.status)) {
        return (
            <Tooltip title="Pending execution">
                <ClockCircleOutlined />
            </Tooltip>
        );
    } else if (["RUNNING"].includes(props.status)) {
        return (
            <SyncOutlined spin />
        );
    } else if (["SUCCEEDED"].includes(props.status)) {
        return (
            <CheckCircleOutlined />
        );
    } else if (["FAILED"].includes(props.status)) {
        return (
            <CloseCircleOutlined style={{ color: "red" }} />
        );
    } else if (["ABORTED"].includes(props.status)) {
        return (
            <Tooltip title="The execution was canceled due to other step failure">
                <StopOutlined style={{ color: "GrayText" }} />
            </Tooltip>
        );
    }

    return (
        <Tag>Unknown status</Tag>
    );
};