import { Alert, Button, Col, Divider, Dropdown, Form, Row, Space, Tag } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { getEnvironmentService } from "../backend";
import { useAuth } from "../contexts/Auth";
import { currentProjectState } from "../recoil/project";
import { latestTemplateStepState, templateState } from "../recoil/template";
import { ComponentConfig, ComponentSchema, Dictionary, FormState, TemplateType } from "../types";
import { jaroWrinkerAlgorithm } from "../utils/jaro-winker-algorithm";
import ComponentConfigFormItems from "./ComponentConfigFormItems";

interface ComponentConfigFormProps {
    schema: ComponentSchema;
    values?: ComponentConfig[];
    loading?: boolean;
    disabled?: boolean;
    actions?: { key: string; value: string; }[];
    resourceName?: string;
    shouldValidateEksResource?: boolean;
    handleReferencedResources?: { shouldHandle: boolean, notDeployedResources: string[] };
    addBackButton?: boolean;
    templateType?: TemplateType;
    filterCategories?: string[];
    onChange?: (config: ComponentConfig[]) => void;
    onActionClick?: (config: ComponentConfig[], key: string) => void;
}

const ComponentConfigForm = ({
    schema,
    values,
    loading,
    disabled,
    actions,
    resourceName,
    shouldValidateEksResource,
    handleReferencedResources,
    addBackButton,
    templateType,
    filterCategories,
    onChange,
    onActionClick
}: ComponentConfigFormProps) => {
    const { envId } = useParams();
    const { isLoggedIn } = useAuth();
    const currentProject = useRecoilValue(currentProjectState);
    const [form] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>('optional');
    const required = (Object.keys(schema).length && schema.properties.inputs.required) || [];
    const inputProps = Object.keys(schema).length ? schema.properties.inputs.properties : {};
    const [mappedResourcesOutputs, setMappedResourcesOutputs] = useState<{ [key: string]: { name: string; suggestionScore: number; }[] }>({});
    const [references, setReferences] = useState<string[]>((values || []).filter(val => val.reference).map(val => val.key));
    const [currentTemplateState, setCurrentTemplateState] = useRecoilState(templateState);
    const latestTemplateStep = useRecoilValue(latestTemplateStepState);
    const [referencedResources, setReferencedResources] = useState<string[]>([]);

    useEffect(() => {
        updateReferencedResources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputProps, references]);

    const updateReferencedResources = () => {
        if (!!handleReferencedResources?.shouldHandle) {
            setReferencedResources([
                ...new Set(references
                    .map(reference => form.getFieldValue(reference)?.split(".")?.[0] || "")
                    .filter(reference => reference !== "" && handleReferencedResources!.notDeployedResources.includes(reference))
                )
            ]);
        }
    }

    const loadData = async () => {
        if (!envId) { return; }

        const { data: { resources } } = await getEnvironmentService().getStageResources(envId!, currentProject!.id);

        const outputs = resources.reduce((acc, resource) => {
            const outputs = resource.component.schema ? resource.component.schema.properties.outputs : {};
            acc[resource.name] = Object.keys(outputs.properties || {});
            return acc;
        }, {} as Dictionary<string[]>);

        if (Object.keys(outputs).length && Object.keys(inputProps).length) {
            const mappedOutputs: { [key: string]: { name: string; suggestionScore: number }[] } = {};
            Object.entries(inputProps).forEach(([key]) => {
                Object.keys(outputs)
                    .filter(resource => resource !== resourceName)
                    .forEach(resource => {
                        const resourceOutputs = outputs[resource] || [];
                        mappedOutputs[key] = (mappedOutputs[key] || []).concat(
                            resourceOutputs.map(output => ({
                                name: `${resource}.${output}`,
                                suggestionScore: jaroWrinkerAlgorithm(key, output)
                            }))
                        )
                    })
                if (mappedOutputs[key]) {
                    mappedOutputs[key].sort((a, b) => b.suggestionScore - a.suggestionScore);
                }
            })
            setMappedResourcesOutputs(mappedOutputs);
        }
    }

    useEffect(() => {
        if (isLoggedIn) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema, isLoggedIn]);

    useEffect(() => {
        handleSetTemplateState(disabled ? "disabled" : "editing");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    const handleConfigChange = async (values: Dictionary<string | []>) => {
        handleSetTemplateState("saved");
        onChange?.(
            Object.entries(values).filter(([_key, value]) => value !== "" && value !== undefined).map(([key, value]) => ({
                key,
                value: Array.isArray(value) ? value.join(",") : value,
                sensitive: inputProps[key].sensitive || false,
                reference: references.includes(key),
                required: required.includes(key)
            }))
        );
    }

    const handleAction = async (values: Dictionary<string>, action: string) => {
        handleSetTemplateState("saved");
        onActionClick?.(
            Object.entries(values).filter(([_key, value]) => value !== "" && value !== undefined).map(([key, value]) => ({
                key,
                value,
                sensitive: inputProps[key].sensitive || false,
                reference: references.includes(key),
                required: required.includes(key)
            })),
            action
        );
    }

    const handleSetTemplateState = (mode: FormState) => {
        const step = templateType === "environment" ? "env" : "cluster";
        setCurrentTemplateState(currentTemplateState => ({
            ...currentTemplateState,
            config: mode,
            ...(mode === "saved" && { [step]: "editing" }) // update next step's status
        }));
    }

    const handleActiveStepChange = () => {
        setCurrentTemplateState(currentTemplateState => ({
            ...currentTemplateState,
            git: "editing",
            config: latestTemplateStep.index > 1 ? "saved" : "editing"
        }))
    }

    const initialValues = Object.entries(inputProps).reduce((acc, [key, value]) => {
        // Get the initial value from the user input, otherwise get the default value from the schema
        const val = values?.find(v => v.key === key)?.value || value.default || "";
        const defaultValue = value.type === "array" ?
            (val as string).split(",") :
            val;
        acc[key] = defaultValue;
        return acc;
    }, {} as Dictionary<string | string[] | number>);

    return (
        <Form
            form={form}
            layout="vertical"
            labelAlign="right"
            initialValues={initialValues}
            requiredMark={requiredMark}
            onFinish={handleConfigChange}
        >
            <ComponentConfigFormItems
                formItems={inputProps}
                required={required}
                setFieldsValue={form.setFieldsValue}
                getFieldValue={form.getFieldValue}
                validateFields={form.validateFields}
                references={references}
                handleUpdateReferences={setReferences}
                onChange={() => handleSetTemplateState("editing")}
                emptyText={<Tag color="default">No environment variables</Tag>}
                mappedResourcesOutputs={mappedResourcesOutputs}
                filterCategories={filterCategories}
                eksInitialNodeInstanceType={
                    !!shouldValidateEksResource ?
                        initialValues["nodeInstanceType"] as string
                        : undefined
                }
                onReferenceChange={!!handleReferencedResources?.shouldHandle ? updateReferencedResources : undefined}
            />
            {
                !!handleReferencedResources?.shouldHandle && !!referencedResources.length &&
                <Space direction="vertical">
                    <br />
                    <Alert
                        message={
                            <div>
                                If you proceed to <b>Save and Deploy</b> this resource, the following inactive resources will also be deployed since they are referenced in the <i>Resource Configuration</i> section:
                                <b> {referencedResources.join(", ")}.</b>
                            </div>
                        }
                        type="info"
                    />
                </Space>
            }
            <Divider />

            <Row className={!!addBackButton ? "flex-justify-space-between" : "flex-justify-end"}>
                {!!addBackButton ?
                    <Button onClick={handleActiveStepChange}>
                        Back
                    </Button> : null
                }
                <Col>
                    {
                        actions && actions.length > 0 ?
                            <Dropdown.Button
                                trigger={["click"]}
                                htmlType="submit"
                                loading={loading}
                                menu={{
                                    items: actions.map(action => ({
                                        key: action.key,
                                        label: action.value,
                                        onClick: () => handleAction(form.getFieldsValue(), action.key)
                                    }))
                                }}
                            >
                                {!!addBackButton ? "Next" : "Save and Deploy"}
                            </Dropdown.Button> :
                            <Button
                                htmlType="submit"
                                type={currentTemplateState["config"] === "saved" ? "default" : "primary"}
                                loading={loading}
                                disabled={currentTemplateState["config"] === "disabled"}
                            >
                                {!!addBackButton ? "Next" : "Save"}
                            </Button>
                    }
                </Col>
            </Row>
        </Form>
    );
}

export default ComponentConfigForm;