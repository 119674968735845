import { Button, Card, Col, Divider, Dropdown, Form, Input, notification, Row, Select, Skeleton, Space } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import { Fragment, useEffect, useState } from "react";
import { getCloudService, getEnvironmentService } from "../../backend";
import { DownOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { CreateStageRequestCloudProviderEnum, CreateStageRequestInfrastructureAsCodeToolEnum, UpdateStageRequestAwsRegionEnum, UpdateStageRequestGcpRegionEnum, UpdateStageRequestGcpZoneEnum } from "@microtica/ms-engine-sdk";
import { AwsRegionType, Environment, EnvironmentDetails, FormState, GCPRegionType, GCPZoneType } from "../../types";
import { AWS_REGIONS, GCP_REGIONS, GCP_ZONES } from "../../enums/enums";
import { trackEnvCreateFailed, trackEnvCreateInit, trackEnvCreateSucceeded } from "../../backend/tracking/environment";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { currentTemplateStepState, latestTemplateStepState, templateState } from "../../recoil/template";
import { useCardClassName } from "../../contexts/Helpers/helpers";
import { useAuth } from "../../contexts/Auth";
import { trackTemplateEnvironmentSelect } from "../../backend/tracking/templates";
import { parseTemplatesRepositoryUrl } from "../../utils/parse-templates-repository-url";
import { getEnvironmentsLimit } from "../../utils/local-storage";
import AwsAccountSelect from "../AwsAccountSelect";
import ExplanationButton from "../explanations/ExplanationButton";
import EnvironmentExplanation from "../explanations/EnvironmentExplanation";
import { ItemType } from "antd/es/menu/interface";
import CloudIcon from "../CloudIcon";
import GcpAccountSelect from "../GcpAccountSelect";
import { AwsAccountAction, handleReport } from "../../utils/handle-report";

interface EnvironmentTemplateModuleProps {
    value?: string;
    templateUrl: string;
    initialEnvironment?: Environment;
    onSelect?: (environment: EnvironmentDetails) => void;
}

const EnvironmentTemplateModule = ({
    value,
    templateUrl,
    initialEnvironment,
    onSelect
}: EnvironmentTemplateModuleProps) => {
    const [form] = Form.useForm();
    const { isLoggedIn } = useAuth();
    const currentProject = useRecoilValue(currentProjectState);
    const [requiredMark] = useState<RequiredMark>('optional');
    const [environments, setEnvironments] = useState<Environment[]>([]);
    const [selectedEnvironment, setSelectedEnvironment] = useState(initialEnvironment);
    const [missingCloudConfigs, setMissingCloudConfigs] = useState(false);
    const [mode, setMode] = useState<"new" | "existing">("existing");
    const [loadingEnvs, setLoadingEnvs] = useState(true);
    const [creatingEnv, setCreatingEnv] = useState(false);
    const [cloudAccounts, setCloudAccounts] = useState<{ id: string; name: string }[]>([]);
    const [enableCreateEnvironment, setEnableCreateEnvironment] = useState(true);
    const [currentTemplateState, setCurrentTemplateState] = useRecoilState(templateState);
    const currentTemplateStep = useRecoilValue(currentTemplateStepState);
    const latestTemplateStep = useRecoilValue(latestTemplateStepState);
    const [missingEnvironmentError, setMissingEnvironmentError] = useState<string>();
    const [missingCloudAccountError, setMissingCloudAccountError] = useState<string>();

    useEffect(() => {
        // load the envs again once the current project changes
        loadEnvironments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProject]);

    const cardClassName = useCardClassName(
        currentTemplateState["env"] === "disabled",
        [currentTemplateState, currentTemplateStep]
    );

    const loadEnvironments = async () => {
        try {
            const { data: { stages: environments } } = await getEnvironmentService().getStages(currentProject!.id);
            const environmentsLimit = getEnvironmentsLimit();
            if (environmentsLimit <= environments.length) {
                setEnableCreateEnvironment(false);
            }
            const defaultEnv = environments.length === 1 ? environments[0] : environments.find(env => env.id === value);

            if (defaultEnv) {
                setSelectedEnvironment({
                    id: defaultEnv.id,
                    name: defaultEnv.name,
                    accountId: defaultEnv.awsAccountId,
                    region: defaultEnv.awsRegion && AWS_REGIONS.find(r => r.value === defaultEnv.awsRegion)?.id,
                    gcpProjectId: defaultEnv.gcpProjectId,
                    gcpRegion: defaultEnv.gcpRegion && GCP_REGIONS.find(r => r.value === defaultEnv.gcpRegion)!.id,
                    gcpZone: defaultEnv.gcpZone && GCP_ZONES.find(r => r.name === defaultEnv.gcpZone)?.id,
                    cloudProvider: defaultEnv.cloudProvider,
                    infrastructureAsCodeTool: defaultEnv.infrastructureAsCodeTool
                });
            }

            setEnvironments(environments.map(env => ({
                id: env.id,
                name: env.name,
                cloudProvider: env.cloudProvider,
                infrastructureAsCodeTool: env.infrastructureAsCodeTool,
                accountId: env.awsAccountId,
                region: env.awsRegion,
                gcpProjectId: env.gcpProjectId,
                gcpRegion: env.awsRegion,
                gcpZone: env.gcpZone
            })));
            setMode(environments.length > 0 ? "existing" : "new");

        } catch (error) {

        } finally {
            setLoadingEnvs(false);
        }
    }

    const loadCloudAccounts = async (provider: string) => {
        if (provider === "aws") {
            const { data: { awsAccounts } } = await getCloudService().getAwsAccounts(currentProject!.id);
            setCloudAccounts(awsAccounts.map(acc => ({ id: acc.id, name: acc.accountName })));
        } else if (provider === "google") {
            const { data: { gcpAccounts } } = await getCloudService().getGcpAccounts(currentProject!.id);
            setCloudAccounts(gcpAccounts.map(acc => ({ id: acc.gcpProjectId, name: acc.projectName })));
        }
    }

    useEffect(() => {
        if (selectedEnvironment) {
            loadCloudAccounts(selectedEnvironment.cloudProvider);
        }
    }, [selectedEnvironment]);

    useEffect(() => {
        if (isLoggedIn) {
            loadEnvironments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    useEffect(() => {
        if (mode === "new") {
            trackEnvCreateInit();
        }
    }, [mode]);

    useEffect(() => {
        const loadData = async () => {
            if (selectedEnvironment) {
                const { data: {
                    awsAccountId,
                    region,
                    gcpProjectId,
                    gcpRegion,
                    gcpZone
                } } = await getEnvironmentService().getStage(
                    selectedEnvironment.id,
                    currentProject!.id
                );

                if (
                    (selectedEnvironment.cloudProvider === "aws" && awsAccountId && region) ||
                    (selectedEnvironment.cloudProvider === "google" && gcpProjectId && gcpRegion && gcpZone)
                ) {
                    setMissingCloudConfigs(false);
                } else {
                    setMissingCloudConfigs(true);
                }
            };
        };

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEnvironment]);

    const handleActiveStepChange = () => {
        setCurrentTemplateState(currentTemplateState => ({
            ...currentTemplateState,
            config: "editing",
            env: latestTemplateStep.index > 2 ? "saved" : "editing"
        }))
    }

    const handleOnSelect = ({
        accountId,
        region,
        gcpProjectId,
        gcpRegion,
        gcpZone
    }: {
        accountId?: string;
        region?: string;
        gcpProjectId?: string;
        gcpRegion?: string;
        gcpZone?: string;
    }) => {
        const destination = {
            ...selectedEnvironment,
            accountId,
            region,
            gcpProjectId,
            gcpRegion,
            gcpZone
        } as Environment;
        setSelectedEnvironment(destination);
        handleSetTemplateState("editing");
        if (
            missingCloudAccountError &&
            (
                (selectedEnvironment?.cloudProvider === "aws" && !!destination.accountId && !!destination.region) ||
                (selectedEnvironment?.cloudProvider === "google" && !!destination.gcpProjectId && !!destination.gcpRegion && !!destination.gcpZone)
            )
        ) {
            setMissingCloudAccountError(undefined);
        }
    }

    const handleCreateEnvironment = async (values: { name: string, description: string; cloudProvider: string; }) => {
        const cloudProvider = values.cloudProvider === "aws" ?
            CreateStageRequestCloudProviderEnum.Aws :
            CreateStageRequestCloudProviderEnum.Google;
        const infrastructureAsCodeTool = values.cloudProvider === "aws" ?
            CreateStageRequestInfrastructureAsCodeToolEnum.Cloudformation :
            CreateStageRequestInfrastructureAsCodeToolEnum.Terraform;

        try {
            setCreatingEnv(true);

            const { data: { id } } = await getEnvironmentService().createStage(
                currentProject!.id,
                {
                    name: values.name,
                    description: values.description,
                    cloudProvider,
                    infrastructureAsCodeTool
                });

            await loadEnvironments();
            setMode("existing");
            setSelectedEnvironment({
                ...values,
                id,
                cloudProvider,
                infrastructureAsCodeTool
            });
            notification.success({ message: "New environment created" });
            trackEnvCreateSucceeded(
                id,
                values.name,
                cloudProvider,
                infrastructureAsCodeTool,
                false,
                templateUrl
            );
        } catch (error: any) {
            notification.error({
                message: "Cannot create new environment",
                description: error.response.data.message
            });
            trackEnvCreateFailed(
                values.name,
                cloudProvider,
                infrastructureAsCodeTool,
                false,
                error.response.data.message,
                templateUrl
            );
        } finally {
            setCreatingEnv(false);
        }
    }

    const handleSelectExistingEnvironment = async () => {
        if (!selectedEnvironment) {
            setMissingEnvironmentError("Please select environment");
            return;
        }
        if (
            (selectedEnvironment.cloudProvider === "aws" && (!selectedEnvironment.accountId || !selectedEnvironment.region)) ||
            (selectedEnvironment.cloudProvider === "google" && (!selectedEnvironment.gcpProjectId || !selectedEnvironment.gcpRegion || !selectedEnvironment.gcpZone))
        ) {
            setMissingCloudAccountError("Please select cloud account");
            return;
        }

        await updateEnvCloud();

        const { templateName } = parseTemplatesRepositoryUrl(templateUrl);
        trackTemplateEnvironmentSelect(templateName.join("/"));
        onSelect?.(selectedEnvironment);
        handleSetTemplateState("saved");
    }

    const updateEnvCloud = async () => {

        const { data: env } = await getEnvironmentService().getStage(selectedEnvironment?.id!, currentProject.id);

        if (selectedEnvironment?.cloudProvider === "aws" && !env.awsAccountId && !env.region) {
            const awsAccountId = selectedEnvironment.accountId!;
            const awsRegion = UpdateStageRequestAwsRegionEnum[selectedEnvironment.region as AwsRegionType];

            await Promise.all([
                getEnvironmentService().updateStage(
                    selectedEnvironment.id,
                    currentProject!.id,
                    {
                        awsAccountId,
                        awsRegion
                    }
                ),
                // handleReport(AwsAccountAction.CreateUpdate, currentProject!.id, awsAccountId, awsRegion)
            ]);
        } else if (selectedEnvironment?.cloudProvider === "google" && !env.gcpProjectId && !env.gcpRegion && !env.gcpZone) {
            await getEnvironmentService().updateStage(
                selectedEnvironment.id,
                currentProject!.id,
                {
                    gcpProjectId: selectedEnvironment.gcpProjectId,
                    gcpRegion: UpdateStageRequestGcpRegionEnum[selectedEnvironment.gcpRegion as GCPRegionType],
                    gcpZone: UpdateStageRequestGcpZoneEnum[selectedEnvironment.gcpZone as GCPZoneType]
                }
            )
        }
    }

    const handleSetTemplateState = (mode: FormState) => {
        setCurrentTemplateState({
            ...currentTemplateState,
            env: mode,
            ...(mode === "saved" && { deploy: "editing" })
        });
    }

    const NewEnvironment = () => (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                cloudProvider: "aws"
            }}
            onFinish={handleCreateEnvironment}
            requiredMark={requiredMark}
        >
            <Row gutter={12}>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label="Name"
                        required
                        rules={[{ required: true, message: 'Please input environment name!' }]}
                    >
                        <Input placeholder="development" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="description"
                        label="Description"
                        required
                        rules={[{ required: true, message: 'Please input environment description!' }]}
                    >
                        <Input placeholder="My development environment" maxLength={100} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="cloudProvider"
                        label="Cloud provider"
                        required
                        rules={[{ required: true, message: 'Please select cloud provider!' }]}
                        extra={
                            <small className="gray-text">
                                Once the environment is set up, you can't switch providers.
                            </small>
                        }
                    >
                        <Select className="full-width">
                            <Select.Option value="aws" key="aws">
                                <Space>
                                    <CloudIcon provider="aws" /> Amazon Web Services
                                </Space>
                            </Select.Option>
                            <Select.Option value="google" key="google">
                                <Space>
                                    <CloudIcon provider="google" /> Google Cloud Platform
                                </Space>
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={12} style={{ textAlign: "left" }}>
                    {
                        environments.length > 0 &&
                        <Button type="link" style={{ padding: 0 }} onClick={() => setMode("existing")}>
                            Choose existing environment
                        </Button>
                    }
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                    <Button type="primary" htmlType="submit" loading={creatingEnv}>
                        Create
                    </Button>
                </Col>
            </Row>
        </Form>
    );

    const ExistingEnvironment = () => (
        <>
            <Space>
                Environment:
                <Dropdown
                    trigger={["click"]}
                    placement="bottomRight"
                    menu={{
                        items: environments
                            .map(env => ({
                                key: env.id,
                                label: env.name,
                                onClick: () => {
                                    if (selectedEnvironment?.id !== env.id) {
                                        setSelectedEnvironment({
                                            ...env,
                                            region: AWS_REGIONS.find(r => r.value === env.region)?.id
                                        });
                                        handleSetTemplateState("editing");
                                        if (missingEnvironmentError) {
                                            setMissingEnvironmentError(undefined);
                                        }
                                    }
                                }
                            }) as ItemType)
                            .concat(enableCreateEnvironment ? [
                                { type: "divider" },
                                {
                                    key: "create-environment",
                                    label: <><PlusCircleOutlined /> Create Environment</>,
                                    onClick: () => setMode("new")
                                }
                            ] : [])
                    }}
                    disabled={currentTemplateState["env"] === "disabled" || !!value}
                    className={missingEnvironmentError ? "error-field-wrapper" : ""}
                >
                    <Button>
                        {selectedEnvironment ? selectedEnvironment.name : "Select environment"} <DownOutlined />
                    </Button>
                </Dropdown>
            </Space>
            {
                missingEnvironmentError &&
                <div className="error-field-wrapper" style={{ marginLeft: "7em", color: "#ff4d4f" }}>
                    {missingEnvironmentError}
                </div>
            }
            <br />
            {
                selectedEnvironment && missingCloudConfigs &&
                <>
                    <br />
                    {
                        selectedEnvironment.cloudProvider === "aws" ?
                            <AwsAccountSelect
                                awsAccounts={cloudAccounts}
                                selectedAccount={selectedEnvironment}
                                onSelect={handleOnSelect}
                                errorClassName={missingCloudAccountError ? "error-field-wrapper" : undefined}
                                visibleInfoForCostReport={true}
                            /> :
                            selectedEnvironment.cloudProvider === "google" ?
                                <GcpAccountSelect
                                    gcpAccounts={cloudAccounts}
                                    selectedAccount={selectedEnvironment}
                                    onSelect={handleOnSelect}
                                    errorClassName={missingCloudAccountError ? "error-field-wrapper" : undefined}
                                /> :
                                undefined
                    }
                    {
                        missingCloudAccountError &&
                        <div className="error-field-wrapper" style={{ color: "#ff4d4f" }}>
                            {missingCloudAccountError}
                        </div>
                    }
                </>
            }
            <Divider />
            <div className="flex-justify-space-between flex-align-center">
                <Button onClick={handleActiveStepChange}>
                    Back
                </Button>
                <Button
                    type={currentTemplateState["env"] === "editing" ? "primary" : "default"}
                    onClick={async () => {
                        await handleSelectExistingEnvironment();
                    }}
                    disabled={
                        currentTemplateState["env"] === "disabled"
                    }
                >
                    Next
                </Button>
            </div>
        </>
    );

    return (
        <Card className={cardClassName}>
            <Card.Meta
                title={
                    <>
                        <span>
                            {
                                mode === "new" ?
                                    "Create Environment" :
                                    "Configure Environment"
                            }
                        </span>
                        <ExplanationButton
                            content={<EnvironmentExplanation />}
                        />
                    </>
                }
                description="Environments separate your cloud infrastructure for development and production apps."
            />
            <Divider />
            {
                loadingEnvs ? <Skeleton /> :
                    mode === "new" ? <NewEnvironment /> : <ExistingEnvironment />
            }
        </Card>
    )
}

export default EnvironmentTemplateModule;