import { Button, Result, Space, notification } from "antd";
import { useState } from "react";
import EnableMonitoringModal from "../modals/EnableMonitoringModal";
import { ClusterMonitoringStatusResponseStatusEnum, EnableMonitoringRequestClusterTypeEnum } from "@microtica/ms-monitoring-sdk";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDoubleConfigurationModal } from "../../contexts/Modal";
import { getMonitoringService } from "../../backend";

interface MonitoringErrorPlaceholderProps {
    projectId: string;
    envId: string;
    componentId?: string;
    clusterId: string;
    clusterName: string;
    clusterType: EnableMonitoringRequestClusterTypeEnum;
    error: {
        status: ClusterMonitoringStatusResponseStatusEnum;
        message?: string;
    }
    onOk?: () => void;
}

const MonitoringErrorPlaceholder = ({
    projectId,
    envId,
    componentId,
    clusterId,
    clusterName,
    clusterType,
    error,
    onOk
}: MonitoringErrorPlaceholderProps) => {
    const { open: openConfirmModal } = useDoubleConfigurationModal();
    const [modalVisible, setModalVisible] = useState(false);
    const [inProgress] = useState(error.status === ClusterMonitoringStatusResponseStatusEnum.Updating);
    const [disconnected] = useState(error.status === ClusterMonitoringStatusResponseStatusEnum.Disconnected);

    const CTA = (props: { status: ClusterMonitoringStatusResponseStatusEnum }) => {
        if (props.status == ClusterMonitoringStatusResponseStatusEnum.UnhealthyMemory) {
            return <Space>
                <Link to={`/projects/${projectId}/environments/${envId}/components/${componentId}/${clusterId}/settings`}>
                    <Button>
                        Scale up
                    </Button>
                </Link>
                <Button danger onClick={handleDisableMonitoring}>
                    Disable Monitoring
                </Button>
            </Space>
        } else if (props.status === ClusterMonitoringStatusResponseStatusEnum.Unhealthy) {
            return <Button danger onClick={handleDisableMonitoring}>
                Disable Monitoring
            </Button>
        } else if (props.status === ClusterMonitoringStatusResponseStatusEnum.DegradedMemory) {
            return <Space>
                <Link to={`/projects/${projectId}/environments/${envId}/components/${componentId}/${clusterId}/settings`}>
                    <Button>
                        Scale up
                    </Button>
                </Link>
                <Button danger onClick={handleDisableMonitoring}>
                    Disable Monitoring
                </Button>
            </Space>
        } else if (props.status === ClusterMonitoringStatusResponseStatusEnum.DegradedCrashRate) {
            return <Button danger onClick={handleDisableMonitoring}>
                Disable Monitoring
            </Button>
        } else if (props.status === ClusterMonitoringStatusResponseStatusEnum.Disconnected) {
            return <Button onClick={() => setModalVisible(true)}>
                Reconnect
            </Button>
        } else {
            return undefined;
        }
    }

    const handleDisableMonitoring = () => {
        openConfirmModal({
            title: "Disable Monitoring?",
            description: "Disabling monitoring will pause data collection and analysis. You can enable monitoring again at any time.",
            confirmation: clusterId,
            okText: "Disable Monitoring",
            cancelText: 'Cancel',
            onOk: async () => {
                try {
                    await getMonitoringService().disableMonitoring(clusterId, envId, projectId);
                    onOk?.();
                } catch (error) {
                    notification.error({
                        message: "Cannot disable monitoring",
                        description: <pre className="white-space-pre-wrap" style={{ fontSize: "12px" }}>
                            {(error as any).response.data.message}
                            <br />
                            <br />
                            {(error as any).response.data.kubernetesError?.message}
                        </pre>
                    })
                }
            }
        })
    }

    return <div className="flex-justify-center full-width">
        <Result
            status={inProgress ? "info" : "warning"}
            icon={inProgress ? <ClockCircleOutlined /> : undefined}
            title={inProgress ?
                "Monitoring setup in progress..." :
                disconnected ?
                    "Monitoring is disconnected!" :
                    "Monitoring is unhealthy!"

            }
            subTitle={error.message}
            extra={
                <CTA status={error.status} />
            }
        />
        {
            modalVisible &&
            <EnableMonitoringModal
                projectId={projectId}
                envId={envId}
                clusterId={clusterId}
                clusterName={clusterName}
                clusterType={clusterType}
                open={modalVisible}
                onOk={() => {
                    setModalVisible(false);
                    onOk?.();
                }}
                onCancel={() => setModalVisible(false)}
            />
        }
    </div>
}


export default MonitoringErrorPlaceholder;