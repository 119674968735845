import { EnableMonitoringRequestClusterTypeEnum, GetUnresolvedAlarmsByProjectIdResponseAlarmsInner, ListClustersResponseInnerStatusEnum } from "@microtica/ms-monitoring-sdk";

export const getComparationSymbol = (operator: string) => {
    switch (operator) {
        case "GreaterThanOrEqualToThreshold":
            return ">=";
        case "GreaterThanThreshold":
        case "GreaterThanUpperThreshold":
            return ">";
        case "LessThanLowerOrGreaterThanUpperThreshold":
            return "<>";
        case "LessThanLowerThreshold":
        case "LessThanThreshold":
            return "<";
        case "LessThanOrEqualToThreshold":
            return "<=";
        default:
            return operator;
    }
}

export const getUnitSymbol = (unit: string) => {
    switch (unit) {
        case "Percent":
            return "%";
        case "Seconds":
            return "seconds";
        case "Megabytes":
            return "MB";
    }
}

export type Alarm = GetUnresolvedAlarmsByProjectIdResponseAlarmsInner & { environmentName: string };


export interface Cluster {
    id: string;
    name: string;
    type: EnableMonitoringRequestClusterTypeEnum;
    envId: string;
    envName: string;
    componentId?: string;
    status?: ListClustersResponseInnerStatusEnum;
    statusMessage?: string;
}

export const mapPixieMetricGroupToGraphName: { [key: string]: string } = {
    "cpu": "CPU",
    "memory": "Memory",
    "latency": "Latency",
    "http_requests": "Number Of Requests",
    "exit_code": "Crashes",
}

export const mapGraphNameToPixieMetricGroup = Object.fromEntries(Object
    .entries(mapPixieMetricGroupToGraphName)
    .map(([key, value]) => [value, key])
) as { [key: string]: string };

export type PixieMetricGroupKeys = keyof typeof mapPixieMetricGroupToGraphName;