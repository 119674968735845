import { Row, Col, Select, Result, Empty, Button, Space, Form, notification, Divider } from "antd";
import { AWS_REGIONS } from "../enums/enums";
import { useEffect, useState } from "react";
import CloudIcon from "./CloudIcon";
import { trackSpecCloudAccountInit } from "../backend/tracking/user-settings";
import { AwsRegionType, IEnvDetails } from "../types";
import { RequiredMark } from "antd/es/form/Form";
import { getCloudService, getEnvironmentService } from "../backend";
import { useParams } from "react-router";
import { UpdateStageRequestAwsRegionEnum } from "@microtica/ms-engine-sdk";
import { canUpdateComponent } from "../utils/environment";
import DeploymentStatusTag from "./DeploymentStatusTag";
import MessageCardAwsAccountCostReport from "./cards/MessageCardAwsAccountCostReport";
import ConnectAwsAccountModal from "./settings/ConnectAwsAccountModal";

interface ChooseAwsAccountFormProps {
    env: IEnvDetails;
    onSelect?: ({ awsAccountId, awsRegion }: { awsAccountId: string, awsRegion: string; }) => void;
    onCancel?: () => void;
}
const ChooseAwsAccountForm = ({
    env,
    onSelect,
    onCancel
}: ChooseAwsAccountFormProps) => {
    const { projectId } = useParams() as { projectId: string; }
    const [accountForm] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [cloudAccounts, setCloudAccounts] = useState<{ id: string; name: string }[]>([]);
    const [connectAwsAccountModalVisible, setConnectAwsAccountModalVisible] = useState(false);
    const [loadingUpdateAccount, setLoadingUpdateAccount] = useState(false);
    const [canUpdate, setCanUpdate] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const canUpdate = canUpdateComponent(env.status);

        const { data: { awsAccounts } } = await getCloudService().getAwsAccounts(projectId);
        setCloudAccounts(
            awsAccounts.map(acc => ({ id: acc.id, name: acc.accountName }))
        );
        // enable the user to update the stage's AWS account if there is no account connected to the stage but there are accounts added to the project
        setCanUpdate(canUpdate || (!env.awsAccountId && !!awsAccounts.length));
    }

    async function handleUpdateAccount(values: {
        awsAccountId: string;
        awsRegion: AwsRegionType;
    }) {
        try {
            setLoadingUpdateAccount(true);

            const updatedAccount = {
                awsAccountId: values.awsAccountId,
                awsRegion: UpdateStageRequestAwsRegionEnum[values.awsRegion],
            };
            await getEnvironmentService().updateStage(
                env.id,
                projectId,
                updatedAccount
            );

            onSelect?.(updatedAccount);
            env.onChange?.();

            notification.success({
                message: "Cloud account updated",
                description: "The Cloud account for this environment has been updated successfully",
            });
        } catch (error: any) {
            notification.error({
                message: "Cloud account can not be updated",
                description: error?.response?.data ? error.response.data.message : "We encountered an error while updating this environment. Please contact our support team on support@microtica.com or write on our Discord community channel."
            });
        } finally {
            setLoadingUpdateAccount(false);
        }
    }

    return (
        !cloudAccounts.length ?
            <>
                <Result
                    icon={Empty.PRESENTED_IMAGE_SIMPLE}
                    subTitle={
                        <>
                            <div>
                                It looks like you haven't connected any cloud accounts yet!
                            </div>
                            <div>
                                Connect a cloud account to deploy your infrastructure.
                            </div>
                        </>
                    }
                    extra={
                        <Button onClick={() => {
                            setConnectAwsAccountModalVisible(true);
                            trackSpecCloudAccountInit("aws");
                        }}>
                            <Space>
                                <CloudIcon provider={env.cloudProvider} /> Connect AWS account
                            </Space>
                        </Button>
                    }
                />
                {
                    // Workaround. The modal is not unmouting when closed so we need to unmount the whole component using 'connectAwsAccountModalVisible'
                    // We have a process of periodic API calls which remain active even if when the modal closes
                    connectAwsAccountModalVisible &&
                    <ConnectAwsAccountModal
                        visible={connectAwsAccountModalVisible}
                        onOk={() => {
                            loadData();
                            setConnectAwsAccountModalVisible(false);
                        }}
                        onCancel={() => setConnectAwsAccountModalVisible(false)}
                    />
                }
            </> :
            <Form
                form={accountForm}
                layout="vertical"
                initialValues={{
                    awsAccountId: env.awsAccountId,
                    awsRegion: env.region && AWS_REGIONS.find(r => r.value === env.region)!.id
                }}
                requiredMark={requiredMark}
                onFinish={handleUpdateAccount}
            >
                <Form.Item
                    name="awsAccountId"
                    label="AWS account"
                    required
                    rules={[{ required: true, message: 'Please select AWS account!' }]}
                >
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select account"
                        disabled={!canUpdate}
                        filterOption={(input, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                        filterSort={(optionA, optionB) => {
                            return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }}
                    >
                        {
                            cloudAccounts.map(acc => (
                                <Select.Option value={acc.id} key={acc.id}>
                                    {acc.name}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="awsRegion"
                    label="AWS region"
                    required
                    rules={[{ required: true, message: 'Please select cloud region!' }]}
                >
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select region"
                        disabled={!canUpdate}
                        filterOption={(input, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                        filterSort={(optionA, optionB) => {
                            return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }}
                    >
                        {
                            AWS_REGIONS.map(region => (
                                <Select.Option value={region.id} key={region.id}>
                                    {region.name}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                {/* <MessageCardAwsAccountCostReport
                    condition={!env.awsAccountId || !env.region}
                /> */}
                <Divider />
                <div className="flex-justify-space-between flex-align-center">
                    <div>
                        {
                            canUpdate ? undefined :
                                <>
                                    Account cannot be updated <DeploymentStatusTag status={env.status} />
                                </>
                        }
                    </div>
                    <Space>
                        {
                            onCancel ? <Button onClick={onCancel}>
                                Close
                            </Button> : undefined
                        }
                        <Button htmlType="submit" disabled={!canUpdate} loading={loadingUpdateAccount}>
                            Save
                        </Button>
                    </Space>
                </div>
            </Form>
    );
}

export default ChooseAwsAccountForm;