import url from "url";
import yaml from "js-yaml";
import moment from "moment-timezone";
import {
    ServiceDeployment,
    StageDeploymentLogsErrorMessages,
    StageDeployment
} from "@microtica/ms-elasticsearch-sdk";
import { GetDeployedMicroservicesResponseDeployedMicroservices, MicroserviceConfigurationItem } from "@microtica/ms-kube-sdk";
import { DeploymentType, PipelineItem, PipelineItemTarget, PipelineSpec } from "../types";
import { getElasticSearchService, getPipelinesService } from "../backend";
import { PipelineBuildDetails, PipelineStepEnvironmentDeploymentOutput } from "@microtica/ms-ap-sdk";

export const mapInfraDeployments = (deployments: StageDeployment[], lastDeploymentId?: string) => {
    return deployments.map(d => {
        const prevDeployment = deployments.find(dd => dd.deploymentId === d.lastDeploymentId);
        const resources = (d.resources || []).map(resourceDetails => {
            const resourceDeployment = d.resources.find(res => res.name === resourceDetails.name)!;
            const prevResourceDeployment = (prevDeployment?.resources || []).find(res => res.name === resourceDetails.name)!;
            const { metadata } = resourceDeployment.component!;

            const changes = prevResourceDeployment ? arraysChanges(prevResourceDeployment.configurations, resourceDetails.configurations) : [];

            return {
                id: resourceDetails.name,
                name: resourceDetails.name,
                deploymentId: d.deploymentId,
                version: metadata?.commit.sha || metadata.commit.version,
                branch: metadata.commit ? metadata.commit.name : "n/a",
                commitType: metadata?.commit.type,
                commitMessage: metadata.commit ? metadata.commit.message : "n/a",
                repositoryUrl: metadata.repository,
                pipeline: {
                    id: resourceDeployment.component.pipelineId,
                    buildId: resourceDeployment.component.version,
                    public: !!resourceDeployment.component.isPublic
                },
                createdAt: moment(resourceDeployment.component.metadata.commit.date || resourceDeployment.lastDeployed).fromNow(),
                createdBy: d.initiator.email,
                initiator: metadata.commit.user,
                avatarUrl: metadata.commit.user.avatar,
                status: {
                    name: resourceDetails.status,
                    errors: d.logs?.events?.[resourceDetails.name]?.errorMessages
                },
                configurations: resourceDetails.configurations,
                timestamp: "n/a",
                prev: {
                    changes,
                    equal: !prevResourceDeployment ? false :
                        (
                            prevResourceDeployment.component.metadata.commit.sha ?
                                prevResourceDeployment.component.metadata.commit.sha === metadata.commit.sha :
                                prevResourceDeployment.component.metadata.commit.version === metadata.commit.version
                        ) &&
                        changes.length === 0,
                    version: prevResourceDeployment?.component.metadata.commit.sha || prevResourceDeployment?.component.metadata.commit.version,
                    branch: prevResourceDeployment?.component.metadata.commit.name || "n/a",
                    commitType: prevResourceDeployment?.component.metadata.commit.type,
                    repositoryUrl: prevResourceDeployment?.component.metadata.repository
                }
            }
        });

        const resourceErrors = (d.resources || []).reduce((errors, resource) => {
            const resourceErrors = d.logs?.events?.[resource.name]?.errorMessages || [];
            errors.push(...resourceErrors);
            return errors;
        }, [] as StageDeploymentLogsErrorMessages[]);

        return {
            envId: d.id,
            id: d.deploymentId,
            name: d.name,
            type: "infrastructure" as DeploymentType,
            active: lastDeploymentId === d.deploymentId,
            createdAt: moment(d.timestamp).fromNow(),
            createdBy: d.initiator.email,
            error: d.error,
            status: {
                name: d.status,
                errors: resourceErrors
            },
            timestamp: d.timestamp,
            resources
        }
    });
}

export const mapAppDeployments = (deployments: ServiceDeployment[], apps: GetDeployedMicroservicesResponseDeployedMicroservices[]) => {
    return deployments.map(d => {
        const prevDeployment = deployments.find(dd => dd.deploymentId === d.lastDeploymentId);

        const changes = prevDeployment ? arraysChanges(
            prevDeployment.configurations.filter(c => !c.key.startsWith("MIC_")),
            d.configurations.filter(c => !c.key.startsWith("MIC_"))
        ) : [];

        return {
            envId: (d as ServiceDeployment & { stageId: string }).stageId,
            id: d.deploymentId,
            name: d.name,
            type: "app" as DeploymentType,
            active: apps.find(a => a.name === d.name && a.kubernetesId === d.kubernetesId)?.deploymentId === d.deploymentId,
            createdAt: moment(d.timestamp).fromNow(),
            createdBy: d.initiator.email,
            error: (d as any).error,
            status: {
                name: d.status,
                errors: (d as any).error && [(d as any).error] as any
            },
            timestamp: d.timestamp,
            resources: [{
                id: d.id,
                name: d.name,
                deploymentId: d.id,
                kubernetesId: d.kubernetesId,
                kubernetesName: d.kubernetesName,
                kubernetesNamespace: (d as any).namespace,
                version: d.commit?.sha || d.commit?.version,
                branch: d.commit?.name || "n/a",
                commitType: d.commit?.type,
                commitMessage: d.commit?.message || "n/a",
                repositoryUrl: d.commit?.repository || d.imageRepository,
                pipeline: {
                    id: (d.commit as any)?.pipelineId,
                    buildId: d.commit?.version,
                    public: false
                },
                createdAt: moment(d.commit?.date || d.timestamp).fromNow(),
                createdBy: d.initiator.email,
                avatarUrl: d.commit?.user.avatar,
                status: {
                    name: d.status
                },
                configurations: d.configurations,
                timestamp: d.commit?.date || d.timestamp,
                prev: {
                    changes,
                    equal: !prevDeployment ? false :
                        (
                            d.commit?.sha === prevDeployment.commit?.sha ||
                            d.commit?.version === prevDeployment.commit?.version
                        ) &&
                        changes.length === 0,
                    version: prevDeployment?.commit?.sha || prevDeployment?.commit?.version!,
                    branch: prevDeployment?.commit?.name || "n/a",
                    commitType: prevDeployment?.commit?.type!,
                    repositoryUrl: prevDeployment?.commit?.repository
                }
            }]
        }
    });
}

const objectsEqual = (o1: any, o2: any) => {
    if (!o1 && !o2) {
        return true;
    } else if (!o1 || !o2) {
        return false;
    }

    return Object.keys(o1).length === Object.keys(o2).length
        && Object.keys(o1).every(p => o1[p] === o2[p]);
}

const arraysChanges = (a1: MicroserviceConfigurationItem[], a2: MicroserviceConfigurationItem[]) => {
    const changes: MicroserviceConfigurationItem[][] = [];

    let conf1: MicroserviceConfigurationItem[] = [];
    let conf2: MicroserviceConfigurationItem[] = [];
    let reverseChange = false;

    if (a1.length >= a2.length) {
        conf1 = a1;
        conf2 = a2;
        reverseChange = true;
    } else if (a1.length < a2.length) {
        conf1 = a2;
        conf2 = a1;
    }

    conf1.forEach((o: MicroserviceConfigurationItem) => {
        const a = conf2.find(a => a.key === o.key);

        if (!a) {
            return changes.push(
                reverseChange ?
                    [{ key: o.key, value: "no value" }, o].reverse() :
                    [{ key: o.key, value: "no value" }, o]
            );
        }

        const equal = objectsEqual(o, a);
        if (!equal) {
            changes.push(reverseChange ? [a, o].reverse() : [a, o]);
        }
    });

    return changes;
}

export const getPipelines = async (
    projectId: string,
    query: {
        pipelineId?: string,
        buildId?: string,
        envId?: string,
        componentId?: string,
        kubernetesId?: string,
        appName?: string,
        from?: number,
        to?: number
    } = {}
) => {
    const [
        { data: { builds } },
        { data: { response: deployments } }
    ] = await Promise.all([
        query.pipelineId && !query.buildId ?
            getPipelinesService().getPipelineBuilds(projectId, query.pipelineId) :
            query.pipelineId && query.buildId ?
                getPipelinesService().getPipelineBuild(projectId, query.pipelineId, query.buildId).then(res => ({ data: { builds: [res.data] } })) :
                getPipelinesService().getProjectBuilds(projectId, query.from, query.to, 0, 300),
        getElasticSearchService().getDeploymentHistory(
            projectId,
            query.from ? new Date(query.from).toISOString() : undefined,
            query.to ? new Date(query.to).toISOString() : undefined,
            query.envId,
            query.kubernetesId,
            query.appName
        )
    ]);

    const deploymentIds = deployments.map(d => d.deploymentId);

    const checkForDeploymentSteps = (build: PipelineBuildDetails) => {
        const pipelineSpec = yaml.load(build.microticaYaml) as PipelineSpec;

        return {
            hasDeployStep: (build.steps || []).some(step =>
                !deploymentIds.includes((step.output as PipelineStepEnvironmentDeploymentOutput)?.deploymentId!) &&
                pipelineSpec.steps[step.name].type === "deploy"
            ),
            triggersDeploy: (build.steps || []).some(step => {
                if (!deploymentIds.includes((step.output as PipelineStepEnvironmentDeploymentOutput)?.deploymentId!) &&
                    pipelineSpec.steps[step.name].type === "deploy") {
                    return build.metadata ? matchBranch(
                        build.metadata.commit.name,
                        pipelineSpec.steps[step.name].parameters?.branch_filter
                    ) : true
                } else {
                    return false;
                }
            })
        }
    }

    const matchBranch = (branchName: string, branchFilter?: string): boolean => {
        if (!branchFilter) {
            return true;
        }

        const regex = new RegExp(`^${branchFilter}$`);
        return regex.test(branchName);
    }

    const isDeployStep = (build: PipelineBuildDetails, stepName: string) => {
        const pipelineSpec = yaml.load(build.microticaYaml) as PipelineSpec;
        return pipelineSpec.steps[stepName].type === "deploy";
    }

    const getStepSpec = (build: PipelineBuildDetails, stepName: string) => {
        const pipelineSpec = yaml.load(build.microticaYaml) as PipelineSpec;
        return pipelineSpec.steps[stepName];
    }

    const getRepoName = (repoUrl: string) => {
        return url.parse(repoUrl).path?.substring(1) || "";
    }

    const pipelines = [
        ...deployments.map(deployment => {
            const triggeredByBuild = builds
                .find(b => (b.steps || [])
                    .some(s =>
                        b.metadata &&
                        (s.output as PipelineStepEnvironmentDeploymentOutput)?.deploymentId === deployment.deploymentId
                    )
                );

            if (deployment.entityType === "stage-deployment") {
                const envDeployment = deployment as StageDeployment;
                const targetComponents = Object.keys(envDeployment.resourceVersionOverrides || {});
                const removedTargetComponents = targetComponents.filter(c => envDeployment.resourceVersionOverrides?.[c] === null);
                const component = (envDeployment.resources || []).find(r => r.name === targetComponents[0] || envDeployment.resources[0].name)!;
                const initiator = component?.component.metadata.commit.user;

                const resourceErrors = (envDeployment.resources || []).reduce((errors, resource) => {
                    const resourceErrors = envDeployment.logs?.events?.[resource.name]?.errorMessages || [];
                    errors.push(...resourceErrors);
                    return errors;
                }, [] as StageDeploymentLogsErrorMessages[]);

                const events = [
                    ...(resourceErrors || []).map(e => ({
                        eventId: `${e.logicalResourceId}-${e.statusReason}`,
                        logicalResourceId: e.logicalResourceId,
                        resourceStatus: "DEPLOYMENT_FAILED",
                        resourceType: e.resourceType,
                        statusReason: e.statusReason,
                        timestamp: new Date(envDeployment?.timestamp!).getTime(),
                        stackId: "",
                        stackName: "",
                        physicalResourceId: "",
                        clientRequestToken: ""
                    })),
                    ...envDeployment?.logs?.events?.[`environment-${deployment.id.split("-")[0]}`]?.events || [],
                ];

                const updatedTargets = (envDeployment.resources || [])
                    // Filter only the resources which were explicitly deployed
                    .filter(res => targetComponents.length > 0 ? targetComponents.includes(res.name) : true)
                    .map(resource => ({
                        type: "component",
                        name: resource.name,
                        commit: resource.component.metadata.commit,
                        repository: resource.component.metadata.repository,
                        repositoryName: getRepoName(resource.component.metadata.repository),
                        envId: resource.stageId,
                        status: resource.status,
                        statusReason: resource.statusReason,
                        undeployed: resource.status.includes("DELETE")
                    })) as PipelineItemTarget[]

                const removedTargets = removedTargetComponents.map(c => ({
                    type: "component",
                    name: c,
                    envId: deployment.id,
                    commit: {
                        date: new Date(deployment.timestamp).toISOString(),
                        message: "Undeploy component",
                        name: "Undeploy component",
                        sha: "",
                        type: "",
                        user: {
                            name: 'microtica',
                            externalId: "microtica",
                            avatar: 'https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/microtica.jpeg'
                        },
                        version: ""
                    },
                    repository: "",
                    repositoryName: "",
                    status: deployment.status,
                    undeployed: true
                }))

                const targets = [
                    ...(updatedTargets.length > 0 ?
                        updatedTargets :
                        removedTargets.length === 0 ?
                            [{
                                type: "component",
                                name: "ALL components",
                                envId: deployment.id,
                                commit: {
                                    date: new Date(deployment.timestamp).toISOString(),
                                    message: "Undeploy component",
                                    name: "Undeploy component",
                                    sha: "",
                                    type: "",
                                    user: {
                                        name: 'microtica',
                                        externalId: "microtica",
                                        avatar: 'https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/microtica.jpeg'
                                    },
                                    version: ""
                                },
                                repository: "",
                                repositoryName: "",
                                status: deployment.status,
                                undeployed: deployment.status.includes("DELETE")
                            }] : []),
                    ...removedTargets
                ] as PipelineItemTarget[]

                return {
                    type: "deploy",
                    stage: "deploy",
                    targetType: "environment",
                    id: envDeployment.deploymentId,
                    name: envDeployment.name,
                    partial: !!envDeployment.partial,
                    envId: deployment.id,
                    cloudProvider: envDeployment.cloudProvider,
                    infrastructureAsCodeTool: envDeployment.infrastructureAsCodeTool,
                    build: triggeredByBuild,
                    targets,
                    events,
                    startDate: new Date(envDeployment.timestamp),
                    status: envDeployment.status,
                    initiatorName: initiator ? initiator.name : envDeployment.initiator.email,
                    initiatorAvatar: initiator ? initiator.avatar : "https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/microtica.jpeg"
                }
            } else {
                const appDeployment = deployment as ServiceDeployment;
                const envId = (appDeployment as ServiceDeployment & { stageId: string }).stageId;
                return {
                    type: "deploy",
                    stage: "deploy",
                    targetType: "app",
                    id: appDeployment.deploymentId,
                    name: appDeployment.name,
                    envId,
                    build: triggeredByBuild,
                    targets: appDeployment.commit ? [{
                        type: "app",
                        name: appDeployment.name,
                        commit: appDeployment.commit,
                        repository: appDeployment.commit?.repository,
                        repositoryName: appDeployment.commit && getRepoName(appDeployment.commit.repository),
                        envId: (appDeployment as ServiceDeployment & { stageId: string }).stageId,
                        status: appDeployment.status
                    }] as PipelineItemTarget[] : [],
                    startDate: new Date(appDeployment.timestamp),
                    status: appDeployment.status,
                    initiatorName: appDeployment.commit?.user.name,
                    initiatorAvatar: appDeployment.commit?.user.avatar
                };
            }
        }),
        ...builds
            .filter(build => !(build.steps || []).find(s => deploymentIds.includes((s.output as PipelineStepEnvironmentDeploymentOutput)?.deploymentId!)))
            .reduce((acc, build) => {
                const { hasDeployStep, triggersDeploy } = checkForDeploymentSteps(build);
                let targets: PipelineItemTarget[] = [];

                if (!build.metadata) {
                    targets = [{
                        type: "template",
                        name: build.name,
                        envId: build.name,
                        commit: {
                            date: new Date(build.startDate).toISOString(),
                            message: "Template deployment",
                            name: "Template deployment",
                            sha: "",
                            type: "",
                            user: {
                                name: 'microtica',
                                externalId: "microtica",
                                avatar: 'https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/microtica.jpeg'
                            },
                            version: ""
                        },
                        repository: "",
                        repositoryName: "",
                        status: build.status
                    }];
                } else if (build.metadata && hasDeployStep && triggersDeploy) {
                    targets = (build.steps || []).reduce((acc, step) => {
                        if (!isDeployStep(build, step.name)) {
                            return acc;
                        }

                        const { target, parameters } = getStepSpec(build, step.name);

                        if (!matchBranch(build.metadata.commit.name, parameters?.branch_filter)) {
                            return acc;
                        }

                        if (target === "environment") {
                            for (const [key, value] of Object.entries(parameters?.partial?.resource_version_overrides || {})) {
                                const buildStep = builds.find(b => b.id === value);
                                if (buildStep) {
                                    acc.push({
                                        name: key,
                                        type: "component",
                                        commit: buildStep.metadata.commit,
                                        repository: buildStep.metadata.repository,
                                        repositoryName: getRepoName(buildStep.metadata.repository),
                                        envId: parameters?.env_id,
                                        status: buildStep.status
                                    });
                                }
                            }
                        } else {
                            const buildStep = builds.find(b => b.id === parameters?.tag);
                            if (buildStep && parameters) {
                                acc.push({
                                    name: parameters.service!,
                                    type: "app",
                                    commit: buildStep.metadata.commit,
                                    repository: buildStep.metadata.repository,
                                    repositoryName: getRepoName(buildStep.metadata.repository),
                                    envId: parameters?.env_id,
                                    status: buildStep.status
                                });
                            }
                        }

                        return acc;
                    }, [] as PipelineItemTarget[])
                } else {
                    targets = [{
                        type: "none",
                        name: build.name,
                        commit: build.metadata.commit,
                        repository: build.metadata.repository,
                        repositoryName: getRepoName(build.metadata.repository),
                        status: build.status
                    }];
                }

                acc.push({
                    type: build.metadata && hasDeployStep && triggersDeploy ? "deploy" :
                        build.metadata && hasDeployStep && !triggersDeploy ? "build" :
                            !build.metadata ? "template" : "buildonly",
                    stage: "build",
                    id: build.id,
                    name: build.pipelineId,
                    partial: true,
                    envId: targets.find(t => !!t.envId)?.envId,
                    build,
                    targets,
                    startDate: new Date(build.startDate),
                    status: build.status,
                    initiatorName: build.metadata?.commit.user.name || "microtica",
                    initiatorAvatar: build.metadata?.commit.user.avatar || "https://microtica.s3.eu-central-1.amazonaws.com/assets/templates/logos/microtica.jpeg"
                });

                return acc;
            }, [] as PipelineItem[])
    ].sort((a, b) => b.startDate.getTime() - a.startDate.getTime());

    return pipelines;
}