import { ReactElement } from "react";
import { ChartMetric } from "../../../types";
import AreaChartWidget from "./AreaChartWidget";
import ColumnChartWidget from "./ColumnChartWidget";
import ColumnStackedChartWidget from "./ColumnStackedChartWidget";
import LineChartWidget from "./LineChartWidget";
import { ChartAnnotation } from "./types";

interface ChartWidgetProps {
    title: string;
    metric: ChartMetric;
    action?: ReactElement;
    annotations?: ChartAnnotation[];
    chartMetricUnit?: {name: string; value: number};
}


const ChartWidget = ({
    title,
    metric,
    action,
    annotations,
    chartMetricUnit
}: ChartWidgetProps) => {
    const titleWithUnit = title;

    if (metric.metrics.length === 1) {
        if (metric.unit === "Percent") {
            return <AreaChartWidget title={titleWithUnit} unit={metric.unit} data={metric.data} extra={action} annotations={annotations} chartMetricUnit={chartMetricUnit} />;
        } else if (metric.unit === "Count") {
            return <ColumnChartWidget title={titleWithUnit} data={metric.data} extra={action} annotations={annotations} />
        } else {
            return <LineChartWidget title={titleWithUnit} unit={metric.unit} data={metric.data} extra={action} annotations={annotations} chartMetricUnit={chartMetricUnit} />;
        }
    } else {
        return <ColumnStackedChartWidget title={titleWithUnit} unit={metric.unit} data={metric.data} extra={action} annotations={annotations} chartMetricUnit={chartMetricUnit} />
    }
}

export default ChartWidget;