import { Pipeline, RepositoriesResponseRepositories } from "@microtica/ms-ap-sdk";
import { Alert, Button, Card, Checkbox, Col, Divider, Dropdown, Form, Input, notification, Row, Select, Skeleton, Space, Table, Tooltip } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getPipelinesService } from "../../backend";
import { currentProjectState } from "../../recoil/project";
import { UnlockOutlined, LockFilled, MoreOutlined } from "@ant-design/icons";
import GitIcon from "../GitIcon";
import { usePrevious } from "../../contexts/State";
import { RequiredMark } from "antd/lib/form/Form";
import { useAuth } from "../../contexts/Auth";
import { trackGitAccountInit } from "../../backend/tracking/user-settings";
import { GIT_TYPES } from "../../enums/enums";
import { Dictionary } from "../../types";
import ExplanationButton from "../explanations/ExplanationButton";
import AppSettingsAutomatedDeploymentExplanation from "../explanations/AppSettingsAutomatedDeploymentExplanation";
import AppSettingsPipelineEnvironmentVariablesExplanation from "../explanations/AppSettingsPipelineEnvironmentVariablesExplanation";
import { ItemType } from "antd/es/menu/interface";
import NotFoundRepo from "../explanations/NotFoundRepo";

interface PipelineSettingsProps {
    pipelineId: string;
}
interface Variable {
    key: string;
    value: string;
    sensitive?: boolean;
}

const PipelineSettings = ({
    pipelineId
}: PipelineSettingsProps) => {
    const [variablesForm] = Form.useForm();
    const [gitForm] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>('optional');
    const currentProject = useRecoilValue(currentProjectState);
    const [pipeline, setPipeline] = useState<Pipeline>();
    const [loading, setLoading] = useState(true);
    const [loadingRepos, setLoadingRepos] = useState(false);
    const [savingRepository, setSavingRepository] = useState(false);
    const [savingAutomatedDeployment, setSavingAutomatedDeployment] = useState(false);
    const [gitAccounts, setGitAccounts] = useState<{ id: string; name: string; provider: string; }[]>([]);
    const [selectedGitAccount, setSelectedGitAccount] = useState<{ id: string; name: string; provider: string; }>();
    const [selectedGitRepository, setSelectedGitRepository] = useState<{ id: string; url: string; fullName: string; }>();
    const [gitRepos, setGitRepos] = useState<RepositoriesResponseRepositories[]>([]);
    const [variables, setVariables] = useState<Variable[]>([]);
    const prevGitAccount = usePrevious(selectedGitAccount);
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
    const { connectGitHub, connectBitbucket, connectGitLab } = useAuth();
    const [gitError, setGitError] = useState<{ message: ReactNode, code: number }>();       // the 'code' param is used for conditional rendering of the error message
    const [automatedDeploymentsForm] = Form.useForm<{ branchFilter: string, modifiedFiles: string, automatedTrigger: boolean }>();
    const [disableAutomatedDeploymentsForm, setDisableAutomatedDeploymentsForm] = useState<boolean>();
    const [hasErrorsAutomatedDeploymentForm, setHasErrorsAutomatedDeploymentForm] = useState<boolean>(false);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // set this value when the pipeline details are fetched
        setDisableAutomatedDeploymentsForm(!pipeline?.automatedTrigger);
    }, [pipeline]);

    useEffect(() => {
        const loadData = async () => {
            if (selectedGitAccount && selectedGitAccount.id !== prevGitAccount?.id) {
                setLoadingRepos(true);
                try {
                    const { data: { repositories } } = await getPipelinesService().listGitAccountRepositories(
                        currentProject!.id,
                        selectedGitAccount.id
                    );
                    const repo = repositories.find(repo => repo.url === pipeline?.repositoryUrl!);

                    setGitRepos(repositories);
                    setSelectedGitRepository(repo);

                    if (repo) {
                        gitForm.setFieldValue("gitRepositoryId", repo?.fullName);
                    } else {
                        gitForm.resetFields(["gitRepositoryId"]);
                    }
                } catch (error) {
                } finally {
                    setLoadingRepos(false);
                }
            }
        }

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGitAccount]);

    const loadData = async () => {
        const { data: pipeline } = await getPipelinesService().getPipelineById(currentProject.id, pipelineId);
        try {
            const [{ data: { gitAccounts } }] = await Promise.all([
                getPipelinesService().listGitAccounts(currentProject!.id)
            ]);
            setPipeline(pipeline);
            setGitAccounts(gitAccounts.map(acc => ({
                id: acc.gitAccountId,
                name: acc.accountName,
                provider: acc.type
            })));
            setVariables(sortVariables(pipeline.environmentVariables || []));

            if (gitAccounts.length) {
                const account = gitAccounts.find(acc => acc.gitAccountId === pipeline.gitAccountId);

                setSelectedGitAccount({
                    id: account?.gitAccountId!,
                    name: account?.accountName!,
                    provider: account?.type!
                });
            }
        } catch (error: any) {
            if (error.response.data.innerError?.code === 401) {
                // TODO: the backend must provide exact error code that points to authentication problem
                setGitError({
                    message: <>
                        Authentication failed for your {(GIT_TYPES as Dictionary<string>)[pipeline.gitAccountType!]} account.
                        <br />
                        Please <Button
                            type="link"
                            style={{ padding: 0 }}
                            onClick={() => handleReconnectGitAccount(pipeline.gitAccountType!)}
                        >
                            Reconnect
                        </Button> your account to proceed.
                    </>,
                    code: 401
                });
            } else if (error.response.data.code === 403) {
                // The user is not authorized to access some of the resources
                setGitError({
                    message: <>
                        You are not authorized to make changes to this resource.
                    </>,
                    code: 403
                });
            } else {
                notification.error({
                    message: "Error getting Git information",
                    description: error.response.data.errorMessage || error.response.data.message
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const sortVariables = (variables: Variable[]) => {
        return variables.sort((a, b) => {
            if (a.key > b.key) {
                return 1;
            }
            if (b.key > a.key) {
                return -1;
            }
            return 0;
        });
    }

    const handleOnSelectAccount = (id: string) => {
        const account = gitAccounts.find(acc => acc.id === id);
        setSelectedGitAccount(account);
    }

    const handleSelectGitRepository = (fullName: string) => {
        const repo = gitRepos.find(repo => repo.fullName === fullName);
        setSelectedGitRepository(repo);
    }

    const handleSaveGitRepository = async (values: { workDir: string }) => {
        try {
            setSavingRepository(true);
            await getPipelinesService().updatePipeline(
                currentProject.id,
                pipeline?.id!,
                {
                    ...pipeline,
                    gitAccountId: selectedGitAccount?.id,
                    repositoryUrl: selectedGitRepository?.url,
                    workDir: values.workDir
                }
            );
            notification.success({ message: "Repository settings have been saved successfully." });
        } catch (error) {
            notification.error({ message: "Unable to save repository settings" });
        } finally {
            setSavingRepository(false);
        }
    }

    const handleSaveAutomatedDeployments = async (values: { automatedTrigger: boolean; branchFilter: string; modifiedFiles: string }) => {
        try {
            setSavingAutomatedDeployment(true);
            await getPipelinesService().updatePipeline(
                currentProject.id,
                pipeline?.id!,
                {
                    ...pipeline,
                    ...values
                }
            );
            notification.success({ message: "Automated deployment settings have been updated successfully." });
        } catch (error) {
            notification.error({ message: "Unable to save automated deployment settings" });
        } finally {
            setSavingAutomatedDeployment(false);
        }
    }

    const handleToggleVariableEncryption = async (key: string) => {
        try {
            const updatedVars = variables.map(v => ({
                ...v,
                sensitive: v.key === key ? !v.sensitive : v.sensitive
            }));

            await getPipelinesService().updatePipeline(
                currentProject.id,
                pipeline?.id!,
                {
                    ...pipeline,
                    environmentVariables: updatedVars
                }
            );

            setVariables(sortVariables(updatedVars));
        } catch (error) {
            notification.error({ message: "Unable to encypt environment variable" });
        } finally {
        }
    }

    const handleDeleteVariable = async (key: string) => {
        try {
            const updatedVars = variables.filter(v => v.key !== key);

            await getPipelinesService().updatePipeline(
                currentProject.id,
                pipeline?.id!,
                {
                    ...pipeline,
                    environmentVariables: updatedVars
                }
            );

            setVariables(sortVariables(updatedVars));
        } catch (error) {
            notification.error({ message: "Unable to delete environment variable" });
        } finally {
        }
    }

    const handleAddVariable = async (key: string, value: string, sensitive: boolean) => {
        try {
            const keyExists = variables.find(v => v.key === key) !== undefined;

            if (keyExists) {
                return notification.error({
                    message: `The specified key '${key}' already exists`,
                    description: "To update existing environment variable, remove the old one and then add new."
                });
            }

            const updatedVars = [
                { key, value, sensitive },
                ...variables,
            ];

            await getPipelinesService().updatePipeline(
                currentProject.id,
                pipeline?.id!,
                {
                    ...pipeline,
                    environmentVariables: updatedVars
                }
            );

            variablesForm.resetFields();

            setVariables(sortVariables(updatedVars));
            setShouldUpdate(!shouldUpdate);
        } catch (error) {
            notification.error({ message: "Unable to save automated deployment settings" });
        } finally {
        }
    }

    const handleReconnectGitAccount = async (provider: string) => {
        if (provider === "bitbucket") {
            await handleConnectBitbucketAccount();
        } else if (provider === "github") {
            await handleConnectGitHubAccount();
        } else if (provider === "gitlab") {
            await handleConnectGitLabAccount();
        }
    }

    const handleConnectGitHubAccount = async () => {
        trackGitAccountInit("github");

        const { code, type } = await connectGitHub();
        await handleConnectGitAccount(code, type);
    }

    const handleConnectGitLabAccount = async () => {
        trackGitAccountInit("gitlab");

        const { code, type } = await connectGitLab();
        await handleConnectGitAccount(code, type);
    }

    const handleConnectBitbucketAccount = async () => {
        trackGitAccountInit("bitbucket");

        const { code, type } = await connectBitbucket();
        await handleConnectGitAccount(code, type);
    }

    const handleConnectGitAccount = async (code: string, type: "github" | "bitbucket" | "gitlab") => {
        try {
            await getPipelinesService().initiateOAuth(
                currentProject!.id,
                code,
                type
            );

            await loadData();
        } catch (error: any) {
            notification.error({
                message: "Error connecting Git account",
                description: error.response.data.message
            });
        }
    }

    const handleOnFieldsChange = async (changedFields: any[]) => {
        if (changedFields[0].name[0] === "automatedTrigger") {
            // disable or enable the rest of the form inputs based on 'automatedTrigger' field value
            setDisableAutomatedDeploymentsForm(!changedFields[0].value);

            // trigger fields validation when 'automatedTrigger' checkbox is toggled in order to remove any existing errors
            // from the 'branchName' field if the field was marked as optional
            await automatedDeploymentsForm.validateFields();
        }

        // disable the 'Save' button if the form is not valid
        const hasErrors = automatedDeploymentsForm.getFieldsError().some(({ errors }) => errors.length);
        setHasErrorsAutomatedDeploymentForm(hasErrors);
    }

    return (
        <Space direction="vertical" className="full-width">
            {
                loading ?
                    <Skeleton /> :
                    <>
                        {/* Git Repository Card */}
                        <Card>
                            <Card.Meta
                                title={
                                    <>
                                        <span>
                                            Git Repository
                                        </span>
                                    </>
                                }
                                description="The Git repository from which the source code will be built and deployed."
                            />

                            <Divider />

                            {
                                gitError ?
                                    <Alert message={gitError.message} type="error" /> :
                                    <Form
                                        form={gitForm}
                                        layout="vertical"
                                        requiredMark={requiredMark}
                                        initialValues={{
                                            gitAccountId: selectedGitAccount?.id,
                                            gitRepositoryId: selectedGitRepository?.id,
                                            workDir: pipeline?.workDir
                                        }}
                                        onFinish={handleSaveGitRepository}
                                    >
                                        <Row gutter={[24, 24]}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Git Account"
                                                    name="gitAccountId"
                                                    required
                                                    rules={[
                                                        { required: true, message: 'Please select Git account' }
                                                    ]}
                                                >
                                                    <Select
                                                        style={{ width: "100%" }}
                                                        placeholder="Select Git account"
                                                        value={selectedGitAccount?.id}
                                                        onChange={handleOnSelectAccount}
                                                    >
                                                        {
                                                            gitAccounts.map(acc => (
                                                                <Select.Option value={acc.id} key={acc.id}>
                                                                    <Space>
                                                                        <GitIcon provider={acc.provider as "github" | "gitlab" | "codecommit" | "bitbucket"} />{acc.name}
                                                                    </Space>
                                                                </Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Git Repository"
                                                    name="gitRepositoryId"
                                                    required
                                                    rules={[
                                                        { required: true, message: 'Please select Git repository' }
                                                    ]}
                                                >
                                                    <Select
                                                        style={{ width: "100%" }}
                                                        disabled={!selectedGitAccount || loadingRepos}
                                                        loading={loadingRepos}
                                                        placeholder="Select Git repository"
                                                        showSearch={true}
                                                        value={selectedGitRepository?.id}
                                                        onChange={handleSelectGitRepository}
                                                        notFoundContent={
                                                            <NotFoundRepo
                                                                projectId={currentProject.id}
                                                                provider={selectedGitAccount?.provider}
                                                                accountName={selectedGitAccount?.name!}
                                                            />
                                                        }
                                                    >
                                                        {
                                                            gitRepos.map(repo => (
                                                                <Select.Option value={repo.fullName} key={repo.id}>
                                                                    {repo.fullName}
                                                                </Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            label="Microtica directory"
                                            name="workDir"
                                            required
                                            tooltip={"Directory under which Microtica related files are stored. Files like microtica.yaml, schema.json should exist in the provided directory."}
                                            rules={[
                                                { required: true, message: 'Please select Git repository' }
                                            ]}
                                        >
                                            <Input placeholder="./.microtica" />
                                        </Form.Item>

                                        <Divider />

                                        <Row>
                                            <Col span={24} style={{ textAlign: "right" }}>
                                                <Button htmlType="submit" loading={savingRepository}>
                                                    Save
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                            }

                        </Card>

                        <br />


                        {/* Automated Deployment Settings Card */}
                        <Card className={gitError && gitError.code === 401 ? "ant-card-disabled" : ""}>
                            <Card.Meta
                                title={
                                    <>
                                        <span>
                                            Automated Deployments
                                        </span>
                                        <ExplanationButton
                                            content={<AppSettingsAutomatedDeploymentExplanation />}
                                        />
                                    </>
                                }
                                description="Trigger build and deployment only if certain criteria is matched."
                            />
                            <Divider />

                            {
                                gitError && gitError.code === 403 ?
                                    <Alert message={gitError.message} type="error" /> :
                                    <Form
                                        form={automatedDeploymentsForm}
                                        layout="vertical"
                                        initialValues={{
                                            automatedTrigger: pipeline?.automatedTrigger,
                                            branchFilter: pipeline?.branchFilter,
                                            modifiedFiles: pipeline?.modifiedFiles
                                        }}
                                        requiredMark={requiredMark}
                                        onFieldsChange={handleOnFieldsChange}
                                        onFinish={handleSaveAutomatedDeployments}
                                    >
                                        <Form.Item
                                            name="automatedTrigger"
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                Deploy on every commit or tag pushed to a branch
                                                <div style={{ color: "GrayText" }}>
                                                    Enabling this feature will automatically trigger build and deployment when commit or tag is pushed on the specified branch or the modified files pattern is satisfied
                                                </div>
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            label="Branch Name"
                                            name="branchFilter"
                                            tooltip={
                                                "Trigger deployment for branches or tags that match the regex. E.g. master, feature/.*, v0.*"
                                            }
                                            required={!disableAutomatedDeploymentsForm}
                                            rules={[
                                                { required: !disableAutomatedDeploymentsForm, message: 'Please enter branch name!' }
                                            ]}
                                        >
                                            <Input
                                                disabled={disableAutomatedDeploymentsForm}
                                                placeholder="main" />
                                        </Form.Item>

                                        <Form.Item
                                            label="Modified files (Glob pattern)"
                                            name="modifiedFiles"
                                            tooltip={
                                                "Trigger deployment only if certain files change. You can define multiple patters separated by comma. E.g. path/**/dir, path/*.json. Leave empty or / to trigger on any file change."
                                            }
                                        >
                                            <Input
                                                disabled={disableAutomatedDeploymentsForm}
                                                placeholder="Leave empty or / to trigger on any file change" />
                                        </Form.Item>

                                        <Divider />

                                        <Row>
                                            <Col span={24} style={{ textAlign: "right" }}>
                                                <Button
                                                    disabled={hasErrorsAutomatedDeploymentForm}
                                                    htmlType="submit"
                                                    loading={savingAutomatedDeployment}>
                                                    Save
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                            }
                        </Card>

                        <br />

                        {/* Environment Variables Card */}
                        <Card className={gitError && gitError.code === 401 ? "ant-card-disabled" : ""}>
                            <Card.Meta
                                title={
                                    <>
                                        <span>
                                            Pipeline Variables
                                        </span>
                                        <ExplanationButton
                                            content={<AppSettingsPipelineEnvironmentVariablesExplanation />}
                                        />
                                    </>
                                }
                                description={
                                    <>
                                        Enhance your Microtica pipelines with environment variables in the <code style={{ color: "var(--primary-color)" }}>microtica.yaml</code> file, adding flexibility to your build phase.
                                        Explore more at <a href="https://docs.microtica.com/variables" target="_blank" rel="noreferrer">Pipeline Variables</a>.
                                    </>
                                }
                            />

                            <Divider />
                            {
                                gitError && gitError.code === 403 ?
                                    <Alert message={gitError.message} type="error" /> :
                                    <Form
                                        layout="vertical"
                                        form={variablesForm}
                                        requiredMark={requiredMark}
                                        onFinish={() => {
                                            handleAddVariable(
                                                variablesForm.getFieldValue("key"),
                                                variablesForm.getFieldValue("value"),
                                                variablesForm.getFieldValue("sensitive")
                                            )
                                        }}
                                    >
                                        <Row gutter={24}>
                                            <Col span={10}>
                                                <Form.Item
                                                    label="Name"
                                                    name="key"
                                                    required
                                                    rules={[
                                                        { required: true, message: 'Please enter variable name!' }
                                                    ]}
                                                >
                                                    <Input placeholder="ENV_ID" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item
                                                    label="Value"
                                                    name="value"
                                                    required
                                                    rules={[
                                                        { required: true, message: 'Please enter variable value!' }
                                                    ]}
                                                    shouldUpdate={shouldUpdate}
                                                >
                                                    <Input
                                                        type={variablesForm.getFieldValue("sensitive") ? "password" : "text"}
                                                        placeholder="env-a2cbnm"
                                                        suffix={
                                                            variablesForm.getFieldValue("sensitive") ?
                                                                <Tooltip
                                                                    title="Encrypted sensitive variable"
                                                                >
                                                                    <Button
                                                                        type="text"
                                                                        size="small"
                                                                        icon={<LockFilled style={{ color: "GrayText" }} />}
                                                                        onClick={() => {
                                                                            variablesForm.setFieldsValue({
                                                                                sensitive: !variablesForm.getFieldValue("sensitive")
                                                                            });
                                                                            setShouldUpdate(!shouldUpdate);
                                                                        }}
                                                                    />
                                                                </Tooltip> :
                                                                <Tooltip
                                                                    title="This variable is not encrypted. Click to encrypt."
                                                                >
                                                                    <Button
                                                                        type="text"
                                                                        size="small"
                                                                        icon={<UnlockOutlined style={{ color: "GrayText" }} />}
                                                                        onClick={() => {
                                                                            variablesForm.setFieldsValue({
                                                                                sensitive: !variablesForm.getFieldValue("sensitive")
                                                                            });
                                                                            setShouldUpdate(!shouldUpdate);
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={3}>
                                                <Form.Item label=" " required>
                                                    <Button htmlType="submit" style={{ float: "right" }}>Add</Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>

                            }
                            <br />

                            {
                                variables && variables.length > 0 &&
                                <Table
                                    size="small"
                                    dataSource={variables} pagination={false}
                                    columns={[
                                        {
                                            title: "Name",
                                            key: 'name',
                                            dataIndex: "key",

                                        },
                                        {
                                            title: "Value",
                                            key: 'value',
                                            render: variable => variable.sensitive ? "******" : variable.value
                                        },
                                        {
                                            key: "actions",
                                            align: "right",
                                            render: variable => (
                                                <Dropdown
                                                    placement="bottomRight"
                                                    trigger={["click"]}
                                                    menu={{
                                                        items: (!variable.sensitive ?
                                                            [
                                                                {
                                                                    key: "encrypt",
                                                                    label: "Encrypt",
                                                                    onClick: () => handleToggleVariableEncryption(variable.key)
                                                                } as ItemType
                                                            ] : []
                                                        ).concat([
                                                            {
                                                                key: "remove",
                                                                label: "Remove",
                                                                danger: true,
                                                                onClick: () => handleDeleteVariable(variable.key)
                                                            }
                                                        ])
                                                    }}
                                                >
                                                    <Button icon={<MoreOutlined />} />
                                                </Dropdown>
                                            )
                                        }
                                    ]}>
                                </Table>
                            }
                        </Card>
                    </>
            }
        </Space>
    );
}

export default PipelineSettings;