import { Card, Input, Form, Button, notification } from "antd";

import { RequiredMark } from "antd/lib/form/Form";
import { useState } from "react";
import Title from "antd/lib/typography/Title";
import { getUserManagementService } from "../../backend";
import { useNavigate, useParams } from "react-router";
import { trackUserResetPassword } from "../../backend/tracking/authentication";
import MicroticaLogo from "../../assets/logo-color.svg";
import { Link } from "react-router-dom";

export default function ResetPassword() {
    const [form] = Form.useForm();
    const { email, code } = useParams();
    const navigate = useNavigate();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [loading, setLoading] = useState(false);
    const [passwordSet, setPasswordSet] = useState(false);

    async function handleResetPassword(values: { email: string; password: string; }) {
        try {
            setLoading(true);
            await getUserManagementService().confirmUserForgotPassword({
                username: email!,
                password: values.password,
                code: code!
            });
            setPasswordSet(true);
            notification.success({
                message: "New password set",
                description: "Your new password was successfully set."
            });
            trackUserResetPassword(email!);
        } catch (error: any) {
            if (error.response.data.code === "ExpiredCodeException") {
                notification.warning({
                    message: "Unable to set new password",
                    description: <>
                        The password reset is <b>valid for 1 hour</b>. Please <a href="/auth/login">initiate forgot password</a>.
                    </>
                });
            } else if (error.response.data.code === "LimitExceededException") {
                notification.error({
                    message: "Unable to set new password",
                    description: error.response.data.message
                });
            } else {
                notification.error({
                    message: "Unable to set new password",
                    description: "Please contact support."
                });
            }
        } finally {
            setLoading(false);
        }
    }

    function handleGotoLogin() {
        navigate("/auth/login");
    }

    return (
        <>
            <Link to="/">
                <img src={MicroticaLogo} style={{ height: "40px" }} alt="microtica" />
            </Link>
            <br />
            {
                passwordSet ?
                    <Card style={{ width: "400px" }} bordered>
                        <Card.Meta
                            title={<Title level={4}>The new password is set</Title>}
                            description="You can now login with your new password."
                        />
                        <br />
                        <Button htmlType="submit" type="primary" onClick={handleGotoLogin} block>
                            Goto Login
                        </Button>
                    </Card> :
                    <Card style={{ width: "400px" }} bordered>
                        <Card.Meta
                            title={<Title level={4}>Set new password</Title>}
                            description="You requested reset of your current password. Set your new password here."
                        />
                        <br />

                        <Form
                            form={form}
                            layout="vertical"
                            requiredMark={requiredMark}
                            onFinish={handleResetPassword}
                        >
                            <Form.Item
                                name="email"
                                label="Email"
                                required
                            >
                                <Input placeholder="Email" type="email" defaultValue={email} disabled />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="New password"
                                required
                                rules={[{ required: true, message: `Please input email!` }]}
                            >
                                <Input placeholder="New password" type="password" />
                            </Form.Item>
                            <Button htmlType="submit" type="primary" loading={loading} block>
                                Set password
                            </Button>
                        </Form>
                    </Card>
            }
        </>
    )
}