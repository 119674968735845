import { Col, Menu, Row, Skeleton } from "antd";
import type { MenuProps } from "antd";
import {
    BarsOutlined,
    DatabaseOutlined,
    ExclamationCircleOutlined,
    GlobalOutlined,
    MonitorOutlined,
    NodeIndexOutlined,
    RiseOutlined,
    SettingOutlined,
    WarningOutlined
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router";
import { CONFIG_CATEGORIES } from "../../enums/enums";
import MenuItemNotification from "../../components/MenuItemNotification";
import { getEnvironmentService } from "../../backend";
import { GetResourceResponse } from "@microtica/ms-engine-sdk";
import { ComponentDetails, IComponentDetails, IEnvDetails } from "../../types";
import { isResourceDeployed } from "../../components/DeploymentStatusTag";

export default function ResourceSettingsRoot() {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const { projectId, envId, componentId, clusterId } = useParams() as {
        projectId: string;
        envId: string;
        componentId: string;
        clusterId: string;
    };
    const { env } = useOutletContext<{ env: IEnvDetails }>();
    const [component, setComponent] = useState<IComponentDetails>();
    const [menuItems, setMenuItems] = useState<MenuProps["items"]>();
    const [activeTab, setActiveTab] = useState("general");

    useEffect(() => {
        const loadData = async () => {
            const [{ data: resource }, { data: { outputs } }] = await Promise.all([
                getEnvironmentService().getResource(envId, componentId, projectId),
                getEnvironmentService()
                    .getOutputsForResource(envId, componentId, projectId)
                    .catch(_ => ({ data: { outputs: [] } }))
            ]);

            const component = env.resources.find(c => c.name === componentId);

            if (!component) return;

            const accessUrl = outputs?.find(o => o.key === "AccessUrl")?.value;
            const CNAME = outputs?.find(o => o.key === "CNAME")?.value;
            const componentDetails = {
                ...component?.component,
                ...(resource as GetResourceResponse & { component: ComponentDetails }).component
            };
            const componentProps = componentDetails.schema.properties.inputs.properties!;

            const items: MenuProps["items"] = [];

            if (Object.values(
                componentProps
            ).some(v => !CONFIG_CATEGORIES.includes(v.category!))
            ) {
                items.push({
                    key: "general",
                    label: <MenuItemNotification
                        icon={<BarsOutlined />}
                        text="General"
                    />
                });
            }

            if (Object.values(
                componentProps
            ).find(v => ["scaling", "autoscaling", "allocation"].includes(v.category!))
            ) {
                items.push({
                    key: "scaling",
                    label: <MenuItemNotification
                        icon={<RiseOutlined />}
                        text="Scaling"
                    />
                });
            }

            if (clusterId && isResourceDeployed(resource.status!)) {
                items.push({
                    key: "monitoring",
                    label: <MenuItemNotification
                        icon={<MonitorOutlined />}
                        text="Monitoring"
                        badgeText="New"
                    />
                });
            }

            if (Object.values(
                componentProps
            ).find(v => ["alarm"].includes(v.category!))
            ) {
                items.push({
                    key: "alarms",
                    label: <MenuItemNotification
                        icon={<WarningOutlined />}
                        text="Alarms"
                        badgeText="New"
                    />
                });
            }

            if (Object.values(
                componentProps
            ).find(v => ["storage"].includes(v.category!))
            ) {
                items.push({
                    key: "storage",
                    label: <MenuItemNotification
                        icon={<DatabaseOutlined />}
                        text="Storage"
                    />
                });
            }

            if (!!accessUrl && !!CNAME) {
                items.push({
                    key: "domain",
                    label: <MenuItemNotification
                        icon={<GlobalOutlined />}
                        text="Domain"
                    />
                })
            }

            if (!componentDetails.isPublic) {
                items.push({
                    key: "pipeline",
                    label: <MenuItemNotification
                        icon={<NodeIndexOutlined />}
                        text="Pipeline"
                    />
                })
            }

            if (Object.values(
                componentProps
            ).find(v => ["variables"].includes(v.category!))
            ) {
                items.push({
                    key: "variables",
                    label: <MenuItemNotification
                        icon={<BarsOutlined />}
                        text="Variables"
                    />
                });
            }

            if (Object.values(
                componentProps
            ).find(v => ["advanced"].includes(v.category!))
            ) {
                items.push({
                    key: "advanced",
                    label: <MenuItemNotification
                        icon={<SettingOutlined />}
                        text="Advanced"
                    />
                });
            }

            items.push({
                key: "danger-zone",
                label: <MenuItemNotification
                    icon={<ExclamationCircleOutlined />}
                    text="Danger Zone"
                />,
                danger: true
            });

            setMenuItems(items);
            setComponent({
                name: component.name,
                version: componentDetails.version,
                status: component.status,
                component: {
                    id: componentDetails.id,
                    name: componentDetails.name,
                    description: componentDetails.description,
                    schema: componentDetails.schema,
                    pipelineId: componentDetails.pipelineId,
                    isPublic: componentDetails.isPublic
                },
                domain: {
                    // if accessUrl equals the loadbalancer's DNS name (CNAME), display "Assign Custom Domain" btn 
                    // We must lowercase the CNAME since the URL utility is automatically lowecasing the hostname
                    displayAssignDomainBtn: !!accessUrl && !!CNAME && new URL(accessUrl).hostname === CNAME.toLowerCase(),
                    eligibleForDomainConfiguration: !!accessUrl && !!CNAME,
                    accessUrl,
                    CNAME
                },
                configurations: component.configurations
            });
            setLoading(false);
        }

        if (env) {
            loadData();
        }
    }, [env]);

    useEffect(() => {
        if (menuItems) {
            const tabIndex = clusterId ? 9 : 8;

            if (!location.pathname.split("/")[tabIndex]) {
                navigate(`${location.pathname}/${menuItems[0]?.key}`, { replace: true });
                setActiveTab(menuItems[0]?.key as string);
            } else {
                setActiveTab(location.pathname.split("/")[tabIndex]);
            }
        }
    }, [menuItems]);

    return (
        <Row gutter={24}>
            <Col span={6}>
                {
                    loading ? <Skeleton /> :
                        <Menu
                            mode="inline"
                            className="ant-card-bordered"
                            selectedKeys={[activeTab]}
                            onSelect={(item) => {
                                navigate(item.key, { replace: true });
                                setActiveTab(item.key);
                            }}
                            items={menuItems}
                        />
                }
            </Col>
            <Col span={18}>
                {
                    loading ? <Skeleton /> :
                        <Outlet context={{ env, component }} />
                }
            </Col>
        </Row>
    )
}