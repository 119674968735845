const ResourceConfigurationExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Resource Configuration
            </div>
            <div className="explanation-subtitle">
                Input parameters
            </div>
            <br />

            <ul>
                <li>
                    A <span style={{ fontStyle: "italic" }}>Resource</span> is an entity that is provisioned in your AWS account through Microtica
                </li>
                <li>
                    In order to provision the <span style={{ fontStyle: "italic" }}>Resource</span> properly, Microtica needs a few input parameters to be provided from your side
                </li>
            </ul>
            <br />

            <h4>Change configuration</h4>
            <p>
                Resource's configuration can be updated at any time after the resource has been provisioned. You can either choose to:
                <ul>
                    <li>
                        <span style={{ fontStyle: "italic" }}> Save and Deploy</span> - initiates an immediate deployment or just
                    </li>
                    <li>
                        <span style={{ fontStyle: "italic" }}>Save</span> - saves the configuration changes and  applies them during the next deployment
                    </li>
                </ul>
            </p>
        </>
    )
}

export default ResourceConfigurationExplanation;