import { Column } from "@ant-design/plots";
import { Col, Divider, Row, Statistic } from "antd";
import capitalizeFirstLetter from "../../../utils/capitalize-first-letter";
import { ListSchedulesResponseRules, ListSchedulesResponseRulesStateEnum } from "@microtica/ms-cloud-cost-optimizer-sdk";
import { WEEK_DAYS } from "../../../utils/cron";
import { ISavingScheduleAggregatedUtilization, WidgetColor } from "../../../types";
import { useMemo } from "react";
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { convertToCurrencyWithoutFractionDigits } from "../../../utils/conversion";

interface ISavingScheduleWidgetProps {
    scheduleRule: ListSchedulesResponseRules,
    isLoading: boolean,
    expectedMonthlySavingsAmount: number,
    aggregatedUtilization?: ISavingScheduleAggregatedUtilization,
    timeZone: string
}

const SavingScheduleWidget = ({
    scheduleRule,
    isLoading,
    expectedMonthlySavingsAmount,
    aggregatedUtilization,
    timeZone
}: ISavingScheduleWidgetProps) => {
    const columnChart = useMemo(() => {
        return (
            <Column
                data={(aggregatedUtilization?.dailyUtilizedHours || [])
                    .flatMap((hours, index) => [
                        {
                            type: "after",
                            day: WEEK_DAYS[index].substring(0, 2),
                            value: hours === 24 ?
                                24 :
                                24 - (aggregatedUtilization?.stopHourInTimeZone || 0)
                        },
                        {
                            type: "during",
                            day: WEEK_DAYS[index].substring(0, 2),
                            value: hours === 24 ?
                                0 :
                                (aggregatedUtilization?.stopHourInTimeZone || 0) - (aggregatedUtilization?.startHourInTimeZone || 0)
                        },
                        {
                            type: "before",
                            day: WEEK_DAYS[index].substring(0, 2),
                            value: hours === 24 ?
                                0 :
                                aggregatedUtilization?.startHourInTimeZone
                        }
                    ])
                }
                xField="day"
                yField="value"
                seriesField="type"
                interactions={[
                    {
                        type: "active-region",
                        enable: false
                    },
                ]}
                tooltip={{
                    fields: ["day", "type"],
                    title: (datum) => `${WEEK_DAYS.find(weekDay => weekDay.startsWith(datum))!}`,
                    formatter: (datum) => {
                        if (!!aggregatedUtilization?.utilizedDays.find(d => d.startsWith(datum.day))) {
                            const totalDailyRunningHours = aggregatedUtilization?.stopHourInTimeZone - aggregatedUtilization?.startHourInTimeZone;
                            return datum.type === "during" ?
                                {
                                    name: "Running state",
                                    value: `${totalDailyRunningHours} hours (${aggregatedUtilization?.startHourInTimeZone}-${aggregatedUtilization?.stopHourInTimeZone}h)`
                                } :
                                {

                                    name: "Stopped state",
                                    value: `${24 - totalDailyRunningHours} hours`
                                }
                        } else {
                            return datum.type === "during" ?
                                {
                                    name: "Running resources",
                                    value: "0 hours"
                                } :
                                {

                                    name: "Stopped resources",
                                    value: "24 hours"
                                }
                        }
                    }
                }}
                columnWidthRatio={0.95}
                yAxis={{
                    maxLimit: 24,
                    tickCount: 9
                }}
                height={180}
                loading={isLoading}
                legend={false}
                color={(datum) => ["before", "after"].includes(datum.type) ? WidgetColor.FadedBlue : WidgetColor.Blue}
                pattern={(_datum, color) => {
                    if (color === WidgetColor.FadedBlue) {
                        return {
                            type: "line",
                            cfg: {
                                rotation: 135,
                                backgroundColor: WidgetColor.FadedBlue
                            }
                        }
                    }
                    return {
                        type: "dot",
                        cfg: {
                            rotation: 0,
                            fill: color
                        }
                    }
                }}
            />
        );
    }, [aggregatedUtilization, isLoading]);

    return (
        <div>
            <Row>
                <Col xs={24} md={10}>
                    <Row gutter={[32, 32]}>
                        <Col md={12}>
                            <Statistic
                                title={<b style={{ fontSize: 14, fontWeight: 500 }}>Status</b>}
                                value={capitalizeFirstLetter(scheduleRule.state)}
                                prefix={
                                    scheduleRule.state === ListSchedulesResponseRulesStateEnum.DISABLED ?
                                        <CloseCircleOutlined style={{ color: "red" }} /> :
                                        <CheckCircleOutlined style={{ color: "green" }} />
                                }
                                valueStyle={{
                                    fontSize: "18px"
                                }}
                            />
                        </Col>
                        <Col md={12}>
                            <Statistic
                                title={<b style={{ fontSize: 14, fontWeight: 500 }}>Time Zone</b>}
                                value={timeZone}
                                prefix={<ClockCircleOutlined />}
                                valueStyle={{
                                    fontSize: "18px"
                                }}
                            />
                        </Col>
                        <Col md={12}>
                            <Statistic
                                title={<b style={{ fontSize: 14, fontWeight: 500 }}>Estimated Saving per month</b>}
                                value={convertToCurrencyWithoutFractionDigits(expectedMonthlySavingsAmount)}
                                valueStyle={{ color: "green", fontSize: "24px" }}
                            />
                        </Col>
                        <Col md={12}>
                            <Statistic
                                title={<b style={{ fontSize: 14, fontWeight: 500 }}>Estimated Saving per year</b>}
                                value={convertToCurrencyWithoutFractionDigits(Math.round(expectedMonthlySavingsAmount) * 12)}
                                valueStyle={{ color: "green", fontSize: "24px" }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={0} sm={0} md={1}>
                    <Divider type="vertical" style={{ height: "100%" }} />
                </Col>
                <Col xs={24} md={13}>
                    {columnChart}
                </Col>
            </Row>
        </div>
    )
}

export default SavingScheduleWidget;