import { loadStripe, Stripe } from "@stripe/stripe-js"
import { useEffect, useState } from "react"
import { env } from "../../env"

const StripeProvider = ({ children }: { children: (param: { stripe?: Promise<Stripe | null> }) => any }) => {
    const [stripe, setStripe] = useState<Promise<Stripe | null>>();

    useEffect(() => {
        if (!stripe) {
            setStripe(loadStripe(env.REACT_APP_STRIPE_PUBLISHABLE_KEY));
        }
    }, [stripe]);

    return children({ stripe });
}

export default StripeProvider;
