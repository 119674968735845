const ResourceSettingsAlarmsExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Alarms
            </div>
            <br />

            <p>
                Custom Alarms in Microtica empower you to set specific performance and error thresholds for your applications, allowing you to stay on top of critical conditions and act proactively to address potential issues.
            </p>
            <p>
                With custom Alarms, you can set the criteria that matter most to your application's reliability and performance. When the defined conditions are met or exceeded, Microtica will immediately fire an alarm, notifying you of the issue.
            </p>
            <p>
                These alarms offer a proactive approach to monitoring your applications, ensuring you're aware of problems before they impact your users. By setting thresholds that align with your application's unique requirements, you can effectively manage and maintain its performance.
            </p>
        </>
    )
}

export default ResourceSettingsAlarmsExplanation;