import dayjs from 'dayjs';
import { Button, Select, Space, DatePicker } from "antd";
import Logs from "../../components/Logs";
import { App, Component, IEnvDetails } from "../../types";
import { useEffect, useState } from "react";
import { disconnectRealtimeService, getEnvironmentService, getRealtimeService } from "../../backend";
import { useParams } from "react-router";
import AppLogsDrawer from "../../components/AppLogsDrawer";
import Search from "antd/es/input/Search";
import { Socket } from "socket.io-client";
import { ReloadOutlined } from '@ant-design/icons';
import { isResourceDeployed } from '../../components/DeploymentStatusTag';
import LogsPlaceholder from '../../components/LogsPlaceholder';
const { Option } = Select;
const { RangePicker } = DatePicker;

interface FargateLogsProps {
    env: IEnvDetails;
    component: Component;
    app: App;
    advancedFilter?: boolean;
    startTime?: number;
    endTime?: number;
}

interface LogItem {
    timestamp: number;
    message: string;
    logStream: string
}

const FargateLogs = ({
    env,
    component,
    app,
    advancedFilter,
}: FargateLogsProps) => {
    const { projectId, envId, componentId } = useParams() as { projectId: string; envId: string; componentId: string; };
    const [socket, setSocket] = useState<Socket>();
    const [loading, setLoading] = useState(isResourceDeployed(component.status) ? true : false);
    const [logs, setLogs] = useState<string[]>(!isResourceDeployed(component.status) ? [
        "Logs are currently unavailable for this application as it has not been deployed yet.",
    ] : []);
    const [showAppLogs, setShowAppLogs] = useState(false);
    const [filterOperator, setFilterOperator] = useState<"regex">("regex");
    const [filterValue, setFilterValue] = useState("");
    const [logsGroup, setLogsGroup] = useState<string>();
    const [startTime, setStartTime] = useState<number>();
    const [endTime, setEndTime] = useState<number>();
    // const [minStartTime, setMinStartTime] = useState<number>();
    const [maxEndTime, setMaxEndTime] = useState<number>();

    useEffect(() => {
        const load = async () => {
            const { data: { outputs } } = await getEnvironmentService().getOutputsForResource(envId, componentId, projectId);
            setLogsGroup(outputs.find(o => o.key === "ApplicationLogs")?.value);
        }
        load();
    }, []);

    useEffect(() => {
        if (logsGroup) {
            refreshLogs();
        }
    }, [logsGroup]);

    const refreshLogs = () => {
        const dateNow = dayjs().utc();
        const startTimestamp = dateNow.add(-30, 'm').valueOf();
        const endTimestamp = dateNow.valueOf();
        setMaxEndTime(dateNow.local().valueOf());
        setRealtimeSocket(startTimestamp, endTimestamp);
    }

    const setRealtimeSocket = async (startTimestamp?: number, endTimestamp?: number, newFilterValue?: string) => {
        setLoading(true);
        setStartTime(startTimestamp || dayjs().utc().add(-30, 'm').valueOf());
        setEndTime(endTimestamp || dayjs().utc().valueOf());
        const socket = getRealtimeService({
            projectId,
            awsAccountId: env.awsAccountId,
            logsGroup,
            typeOfLogs: "service",
            startTime: startTimestamp,
            endTime: endTimestamp,
            messageQuery: typeof newFilterValue === "string" ? newFilterValue : filterValue
        });
        setSocket(socket);
        disconnectRealtimeService();
    }

    useEffect(() => {
        let allLogs: LogItem[] = [];
        if (socket !== undefined) {
            socket.on("logs", (data: { logs: LogItem[] }) => {
                // EDIT: We don't know when was the first log, give the user freedom to choose the time range
                // first message arrived (first message is always the most recent one)
                // if (logs.length === 0 && allLogs.length === 0 && data.logs.length > 0) {
                //     setMinStartTime(data.logs[data.logs.length - 1].timestamp);
                // }
                allLogs = [...data.logs, ...allLogs];
                setLogs(allLogs.map(l => l.message));
                setLoading(false);
            });
        }
        return () => { socket?.disconnect() }
    }, [socket]);

    const handleShowContextLogs = async (timestamp: number) => {
        setFilterValue("");
        const dateNow = dayjs(timestamp).utc();
        const startTimestamp = dateNow.add(-30, 'm').valueOf();
        const endTimestamp = dateNow.add(30, 'm').valueOf();
        setRealtimeSocket(startTimestamp, endTimestamp, "");
    }

    return (
        <Space direction="vertical" size="large" className="flex-justify-space-between">
            {
                advancedFilter ?
                    <div>
                        <div style={{ fontWeight: 600, marginBottom: "8px" }}>
                            Filters
                        </div>
                        <div className="flex-justify-space-between">
                            <div>
                                <Space>
                                    <Search
                                        enterButton={false}
                                        onSearch={(value) => {
                                            setFilterValue(value);
                                            setRealtimeSocket(startTime, endTime, value);
                                        }}
                                        addonBefore={
                                            <Select
                                                defaultValue={filterOperator}
                                                value={filterOperator}
                                                onSelect={(value) => setFilterOperator(value)}
                                            >
                                                <Option value="regex">Line contains regex metch</Option>
                                            </Select>
                                        }
                                        addonAfter={undefined}
                                        allowClear={true}
                                    />
                                </Space>
                            </div>
                            <Space>
                                <RangePicker
                                    value={[dayjs(startTime), dayjs(endTime)]}
                                    showTime={{ format: 'HH:mm' }}
                                    presets={[
                                        { label: 'Last 5 minutes', value: [dayjs().utc().add(-5, 'm'), dayjs().utc()] },
                                        { label: 'Last 30 minutes', value: [dayjs().utc().add(-30, 'm'), dayjs().utc()] },
                                        { label: 'Last 12 hours', value: [dayjs().utc().add(-12, 'h'), dayjs().utc()] },
                                        { label: 'Last day', value: [dayjs().utc().add(-1, 'd'), dayjs().utc()] }
                                    ]}
                                    onChange={([start, end]: any) => {
                                        const startTimestamp = start?.valueOf() || startTime;
                                        const endTimestamp = end?.valueOf() || endTime;
                                        setRealtimeSocket(startTimestamp, endTimestamp);
                                    }}
                                    // minDate={dayjs(minStartTime)}
                                    maxDate={dayjs(maxEndTime)}
                                    // force user to change dates using the calendar
                                    inputReadOnly={true}
                                />
                                <Button
                                    icon={<ReloadOutlined />}
                                    onClick={() => {
                                        refreshLogs();
                                    }}
                                />
                            </Space>
                        </div>
                    </div> :
                    <div className="flex-justify-space-between flex-align-center">
                        <div style={{ fontWeight: 600, fontSize: 16 }}>
                            Application logs
                            <div className="gray-text" style={{ fontWeight: 400, fontSize: 14 }}>
                                Logs from the past 30 minutes
                            </div>
                        </div>
                        <Space>
                            {
                                advancedFilter ? undefined :
                                    <Space>
                                        <Button onClick={() => setShowAppLogs(true)}>Search logs</Button>
                                        <Button icon={<ReloadOutlined />} onClick={() => refreshLogs()}
                                        />
                                    </Space>
                            }
                        </Space>
                    </div>
            }
            {
                !loading ?
                    <Logs
                        logs={logs.length > 0 ? logs : ["\u001b[33mNo logs found in the specified time range.\u001b[0m"]}
                        limit={2000}
                        height={advancedFilter ? "calc(100vh - 295px)" : "calc(100vh - 382px)"}
                        onSelect={handleShowContextLogs}
                    /> :
                    <LogsPlaceholder height={advancedFilter ? "calc(100vh - 295px)" : "calc(100vh - 366px)"} />
            }
            {
                showAppLogs &&
                <AppLogsDrawer
                    env={env}
                    component={component}
                    app={app}
                    open={showAppLogs}
                    onClose={() => setShowAppLogs(false)}
                />
            }
        </Space>
    )
}

export default FargateLogs;