import Modal from "antd/lib/modal/Modal";
import { Button, Card, Checkbox, Col, Form, Input, notification, Row, Select, Space, Typography } from "antd";
import { useState } from "react";
import KubernetesIcon from "../../assets/kubernetes-icon.svg";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { getKubeService } from "../../backend";
import { CreateKubernetesRequestTypeEnum } from "@microtica/ms-kube-sdk";
import { RequiredMark } from "antd/lib/form/Form";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { Environment } from "../../types";
const { Text } = Typography;

interface ConnectClusterModalProps {
    visible: boolean;
    envId?: string;
    environments?: Omit<Environment, "cloudProvider">[];
    onOk: (name: string) => void;
    onCancel: () => void;
}
interface ConnectClusterFormValues {
    name: string;
    apiToken: string;
    endpoint: string;
    associatedNamespace: string;
    cpuRequests?: number;
    memoryRequests?: number;
    storageRequests?: number;
    cpuLimits?: number;
    memoryLimits?: number;
}

const ConnectClusterModal = ({
    visible,
    envId,
    environments,
    onOk,
    onCancel
}: ConnectClusterModalProps) => {
    const currentProject = useRecoilValue(currentProjectState);
    const [requiredMark] = useState<RequiredMark>('optional');
    const [connectingCluster, setConnectingCluster] = useState(false);
    const [associateNamespace, setAssociateNamespace] = useState(false);
    const [resourceQuotas, setResourceQuotas] = useState(false);
    const [form] = Form.useForm();
    const defaultEnvironment = environments && environments.length === 1 ? environments[0].id : undefined;
    const [selectedEnvironment, setSelectedEnvironment] = useState(envId || defaultEnvironment);

    const handleConnectCluster = async (values: ConnectClusterFormValues) => {
        try {
            const { name, apiToken, endpoint, associatedNamespace } = values;
            const resourceQuotas = parseResourceQuotaValues(values);
            setConnectingCluster(true);
            await getKubeService().createKubernetes(currentProject!.id, {
                name,
                apiToken,
                endpoint,
                type: CreateKubernetesRequestTypeEnum.Personal,
                stageId: selectedEnvironment!,
                associatedNamespace,
                resourceQuotas
            });
            onOk(name);

            notification.success({
                message: `Cluster connected`,
                description: `Cluster ${name} was successfully connected`,
            });

        } catch (error) {
            notification.error({
                message: "Error connecting cluster",
                description: (error as any).response?.data?.message
            });
        } finally {
            setConnectingCluster(false);
        }
    }

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            handleConnectCluster(values);
        } catch (error) {

        }
    }

    const handleKeyUp = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    }

    const parseResourceQuotaValues = (values: ConnectClusterFormValues) => {
        const response = {
            ...(values.cpuRequests || values.memoryRequests || values.storageRequests ?
                {
                    requests: {
                        cpu: values.cpuRequests,
                        memory: values.memoryRequests,
                        storage: values.storageRequests
                    }
                } :
                {}),
            ...(values.cpuLimits || values.memoryLimits ?
                {
                    limits: {
                        cpu: values.cpuLimits,
                        memory: values.memoryLimits
                    }
                } :
                {})
        };
        return (Object.keys(response).length === 0) ? undefined : response;
    }

    const resetResourceQuotas = () => {
        setResourceQuotas(false);
        form.resetFields(["cpuRequests", "cpuLimits", "memoryRequests", "memoryLimits", "storageRequests"]);
    }

    const shouldSelectEnvironment = environments && environments.length > 1 && !envId;
    return (
        <Modal
            title={
                <>
                    Connect Existing Kubernetes
                    <img src={KubernetesIcon} style={{ height: "25px", float: "right" }} alt="kubernetes" />
                </>
            }
            centered
            closable={false}
            open={visible}
            footer={[
                <div className="flex-justify-space-between flex-align-center">
                    <a href="https://docs.microtica.com/connect-an-existing-kubernetes-cluster" target="_blank" rel="noreferrer">
                        How to connect?
                    </a>
                    <Space>
                        <Button
                            key="cancel"
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            key="submit"
                            loading={connectingCluster}
                            onClick={handleSubmit}
                        >
                            Connect Kubernetes
                        </Button>
                    </Space>
                </div>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                onKeyUp={handleKeyUp}
                requiredMark={requiredMark}
            >
                {shouldSelectEnvironment &&
                    <Form.Item
                        name="envId"
                        label="Environment"
                        required
                        rules={[
                            { required: true, message: `Please select environment!` }
                        ]}
                    >
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Select environment"
                            value={selectedEnvironment}
                            onSelect={setSelectedEnvironment}
                            autoFocus={true}
                        >
                            {
                                environments.map(env => (
                                    <Select.Option value={env.id} key={env.id}>
                                        {env.name}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                }
                <Form.Item
                    name="name"
                    label="Name"
                    required
                    rules={[{ required: true, message: 'Please input the Kubernetes Name!' }]}
                >
                    <Input autoFocus={!!envId} />
                </Form.Item>
                <Form.Item
                    name="endpoint"
                    label="Endpoint"
                    required
                    rules={[
                        { required: true, message: 'Please input the Kubernetes Endpoint!' }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="apiToken"
                    label="API token"
                    required
                    rules={[{ required: true, message: 'Please input the API token!' }]}
                >
                    <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                </Form.Item>
                <Card>
                    <div style={{ marginBottom: "15px" }}>
                        <Checkbox
                            onChange={(e) => {
                                setAssociateNamespace(e.target.checked);
                                if (!e.target.checked) {
                                    // if no namespace is associated, remove the resource quotas values
                                    resetResourceQuotas();
                                    form.resetFields(["associatedNamespace"]);
                                }
                            }}
                            checked={associateNamespace}
                        >
                            Associate this cluster to a specific namespace
                        </Checkbox>
                        <div style={{ marginLeft: "25px", fontSize: "75%" }}>
                            This Kubernetes cluster will only have access to the specified namespace
                        </div>
                    </div>
                    <Col span={22} offset={2}>
                        <Form.Item
                            name="associatedNamespace"
                            label="Associated namespace"
                            hidden={!associateNamespace}
                            rules={[
                                { required: associateNamespace, message: 'Please input the associated namespace!' },
                                { min: 5, type: "string", message: "Namespace name must be at least 5 characters" },
                                { max: 63, type: "string", message: "Namespace name must not be more than 63 characters" }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    {associateNamespace && <div style={{ marginBottom: "15px" }}>
                        <Checkbox
                            onChange={(e) => {
                                setResourceQuotas(e.target.checked);
                                !e.target.checked && resetResourceQuotas();
                            }}
                            checked={resourceQuotas}
                        >
                            Set resource quota for the namespace
                        </Checkbox>
                        <div style={{ marginLeft: "25px", fontSize: "75%" }}>
                            Limit the resources available to the namespace
                        </div>
                    </div>}
                    <Row gutter={[24, 12]} hidden={!resourceQuotas}>
                        <Col span={24} offset={2}>
                            <Text>CPU</Text>
                        </Col>
                        <Col span={11} offset={2}>
                            <Form.Item
                                name="cpuRequests"
                            >
                                <Input type="number" addonAfter="mCPU" placeholder="Requests" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="cpuLimits"
                            >
                                <Input type="number" addonAfter="mCPU" placeholder="Limits" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 12]} hidden={!resourceQuotas}>
                        <Col span={24} offset={2}>
                            <Text>Memory</Text>
                        </Col>
                        <Col span={11} offset={2}>
                            <Form.Item
                                name="memoryRequests"
                            >
                                <Input type="number" addonAfter="MiB" placeholder="Requests" />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="memoryLimits"
                            >
                                <Input type="number" addonAfter="MiB" placeholder="Limits" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 12]} hidden={!resourceQuotas}>
                        <Col span={24} offset={2}>
                            <Text>Storage</Text>
                        </Col>
                        <Col span={11} offset={2}>
                            <Form.Item
                                name="storageRequests"
                            >
                                <Input type="number" addonAfter="MiB" placeholder="Requests" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </Modal>
    )
}

export default ConnectClusterModal;