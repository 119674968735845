import { Col, Menu, Row, Skeleton, Space } from "antd";
import { BarsOutlined, ClusterOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useOutletContext } from "react-router";
import { IEnvDetails } from "../../types";

export default function EnvironmentSettingsRoot() {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("general");
    const { env } = useOutletContext<{ env: IEnvDetails }>();

    useEffect(() => {
        setActiveTab(location.pathname.split("/")[6] || "general");
    }, [location.pathname]);

    return (
        <Row gutter={24}>
            <Col span={6}>
                <Menu
                    mode="inline"
                    className="ant-card-bordered"
                    selectedKeys={[activeTab]}
                    onSelect={(item) => {
                        navigate(item.key, { replace: true });
                        setActiveTab(item.key);
                    }}
                    items={[
                        {
                            key: "general",
                            label: <Space>
                                <BarsOutlined />
                                General
                            </Space>
                        },
                        {
                            key: "danger-zone",
                            danger: true,
                            label: <Space>
                                <ExclamationCircleOutlined />
                                Danger Zone
                            </Space>
                        }
                    ]}
                />
            </Col>
            <Col span={18}>
                {env ? <Outlet context={{ env }} /> : <Skeleton />}
            </Col>
        </Row >
    );

}