import { Tooltip } from "antd";
import { ComponentType } from "../types";
import { KubernetesOutlined } from "@ant-design/icons";
import FargateLogo from "../assets/fargate.png";


const ComponentIcon = (props: { type: ComponentType, size?: "small" | "large" }) => {
    switch (props.type) {
        case "kubernetes":
        case "k8s":
            return <Tooltip title="Kubernetes">
                <KubernetesOutlined
                    style={{
                        color: "#326CE5",
                        fontSize: props.size === "large" ? "35px" : "22px"
                    }}
                />
            </Tooltip>
        case "fargate":
        case "ecs":
            return <Tooltip title="AWS Fargate - Serverless compute">
                <img
                    src={FargateLogo}
                    style={{
                        width: props.size === "large" ? "40px" : "25px"
                    }}
                    alt="fargate"
                />
            </Tooltip>
        default:
            return undefined;

    }
}

export default ComponentIcon;