import { Button } from "antd";
import CardMarketing from "../../components/marketing/CardMarketing";

export default function AppSettingsDeployments() {
    return (
        <CardMarketing
            title="Deployments"
            subtitle="Deployments is available in Advanced Plan ($99/month)."
            description="Get an insight of each environment deployment, which resources and apps were deployed with which version. Understand who did the changes and drill down to the exact commit."
            docs={
                <Button type="link" href="https://docs.microtica.com/" style={{ paddingLeft: 0 }} target="_blank">Learn more about Deployments</Button>
            }
            action={
                <Button>Enable Deployments</Button>
            }
        />
    );
}