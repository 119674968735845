import { Card, Tabs } from "antd";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { App, Component, IEnvDetails } from "../../types";
import { useEffect, useState } from "react";
import FargateLogs from "../app/FargateLogs";
import AppOverview from "../app/AppOverview";
import AppMetrics from "../app/AppMetrics";

const EnvironmentFargateAppDetails = () => {
    const navigate = useNavigate();
    const { projectId, envId, componentId, section } = useParams() as {
        projectId: string;
        envId: string;
        componentId: string;
        namespace: string;
        appName: string;
        section: string;
    };
    const { env, component, app } = useOutletContext<{
        env: IEnvDetails;
        component: Component;
        app: App;
    }>();
    const [activeTab, setActiveTab] = useState(section);

    useEffect(() => {
        setActiveTab(section);
    }, [section]);

    if (!app) {
        return <></>
    }

    return (
        <Card
            bordered
            title={
                <div style={{ padding: "10px 0" }}>
                    <div style={{ fontWeight: 400 }}>Application</div>
                    <div style={{ fontWeight: 600 }}>{app.name}</div>
                </div>
            }
            className="full-width"
            style={{ height: section === "metrics" ? "auto" : "calc(100vh - 128px)" }}
            styles={{
                header: { border: "none" }
            }}
        >
            <Tabs
                activeKey={activeTab}
                onChange={(activeKey) => {
                    setActiveTab(activeKey);
                    navigate(`/projects/${projectId}/environments/${envId}/components/${componentId}/apps/${app.name}/${activeKey}`, { replace: true });
                }}
                items={[
                    {
                        key: "overview",
                        label: "Overview"
                    },
                    {
                        key: "logs",
                        label: "Logs"
                    },
                    {
                        key: "metrics",
                        label: "Metrics"
                    }
                ]}
            />
            {activeTab === "overview" && <AppOverview component={component} app={app} />}
            {activeTab === "logs" && <FargateLogs env={env} component={component} app={app} />}
            {activeTab === "metrics" && <AppMetrics component={component} app={app} />}
        </Card>
    )
}

export default EnvironmentFargateAppDetails;