import { Card, Row, Col, Select } from "antd";
import { AWS_REGIONS } from "../enums/enums";
import AwsLogo from "../assets/aws-logo.svg";
import { useEffect, useState } from "react";
import MessageCardAwsAccountCostReport from "./cards/MessageCardAwsAccountCostReport";

interface ChooseAwsAccountProps {
    classNames?: string;
    selectedAccount?: {
        accountId?: string;
        region?: string;
    };
    awsAccounts: { id: string, name: string }[];
    onSelect?: ({ accountId, region }: { accountId: string, region: string }) => void;
    visibleInfoForCostReport?: boolean;
}
const ChooseAwsAccount = ({
    onSelect,
    selectedAccount,
    classNames,
    awsAccounts,
    visibleInfoForCostReport = false
}: ChooseAwsAccountProps) => {
    // this component re-renders every time the selectedAccount is changed because it propagates back the change
    // and expects the same parametar as an input prop, so this is a solution to always have items in the list that is used
    // for the dropdown menu, because otherwise it will flicker
    const [awsAccountsSelectItems, setAwsAccountSelectItems] = useState(
        selectedAccount?.accountId || selectedAccount?.region ?
            [{ id: selectedAccount?.accountId, name: selectedAccount?.accountId }] :
            []
    );

    useEffect(() => {
        setAwsAccountSelectItems(awsAccounts);
    }, [awsAccounts])

    const handleOnSelectAccount = (accountId: string) => {
        onSelect?.({ accountId, region: selectedAccount?.region! });
    }

    const handleOnSelectRegion = (region: string) => {
        onSelect?.({ accountId: selectedAccount?.accountId!, region });
    }

    return (
        <Card type="inner" className={classNames} >
            <Card.Meta
                title="Link AWS account"
                description="The components and apps will be deployed in the selected account and region." />
            <br />
            {
                <Row gutter={24}>
                    <Col xs={24} sm={12}>
                        <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select AWS account"
                            value={selectedAccount?.accountId}
                            onSelect={handleOnSelectAccount}
                        >
                            {
                                awsAccountsSelectItems.map(acc => (
                                    <Select.Option value={acc.id} key={acc.id}>
                                        <img src={AwsLogo} style={{ width: "14px" }} alt="aws" />  {acc.name}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select region"
                            filterOption={(input, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                            filterSort={(optionA, optionB) => {
                                return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }}
                            value={selectedAccount?.region}
                            onSelect={handleOnSelectRegion}
                        >
                            {
                                AWS_REGIONS.map(region => (
                                    <Select.Option value={region.id} key={region.id}>
                                        {region.name}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                    {/* <MessageCardAwsAccountCostReport
                        condition={visibleInfoForCostReport}
                    /> */}
                </Row>
            }
        </Card >
    );
}

export default ChooseAwsAccount;