import { fetchProjectLimitations } from "./local-storage";

// duration of time in milliseconds to count consecutive clicks: minutes * seconds * milliseconds
const durationTimeMilliseconds: number = 10 * 60 * 1000;


interface ButtonState { noOfClicks: number; lastClickTimestamp: number }

/**
 * Calculates the number of subsequent deploy environment button 
 * clicks within a specific time period
 *
 * @param {ButtonState} state
 * @returns {ButtonState}
 */
export const calculateSubsequentDeployButtonClicks = (state: ButtonState): ButtonState => {

    const { automatedDeployments } = fetchProjectLimitations();
    const currentTimestamp = Date.now();
    if (automatedDeployments) {
        return {
            noOfClicks: 0,
            lastClickTimestamp: currentTimestamp
        }
    }

    const { lastClickTimestamp } = state;
    const timeDiff = currentTimestamp - lastClickTimestamp;

    if (timeDiff <= durationTimeMilliseconds || lastClickTimestamp === 0) {
        return {
            noOfClicks: state.noOfClicks + 1,
            lastClickTimestamp: currentTimestamp
        }
    } else {
        return {
            noOfClicks: 1,
            lastClickTimestamp: currentTimestamp
        }
    }
}

/**
 * Calculates the number of subsequent build pipeline button 
 * clicks within a specific time period
 *
 * @param {ButtonState} state
 * @returns {ButtonState}
 */
export const calculateSubsequentBuildButtonClicks = (state: ButtonState): ButtonState => {
    const { automatedBuilds } = fetchProjectLimitations();
    const currentTimestamp = Date.now();

    if (automatedBuilds) {
        return {
            noOfClicks: 0,
            lastClickTimestamp: currentTimestamp
        }
    }

    const { lastClickTimestamp } = state;
    const timeDiff = currentTimestamp - lastClickTimestamp;

    if (timeDiff <= durationTimeMilliseconds || lastClickTimestamp === 0) {
        return {
            noOfClicks: state.noOfClicks + 1,
            lastClickTimestamp: currentTimestamp
        }
    } else {
        return {
            noOfClicks: 1,
            lastClickTimestamp: currentTimestamp
        }
    }
}