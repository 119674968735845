import { useOutletContext } from "react-router";
import PipelineSettings from "../../components/template-modules/PipelineSettings";
import { IComponentDetails } from "../../types";


const ResourceSettingsPipeline = () => {
    const { component: { component: { pipelineId } } } = useOutletContext<{ component: IComponentDetails }>();

    return (
        <PipelineSettings pipelineId={pipelineId} />
    );
}

export default ResourceSettingsPipeline;