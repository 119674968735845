
import { DeployMicroserviceRequest, MicroserviceConfigurationItem } from "@microtica/ms-kube-sdk";
import { Card, Divider, notification, Space } from "antd";
import { useState } from "react";
import { useOutletContext, useParams } from "react-router";
import { getKubeService } from "../../backend";
import ComponentConfigForm from "../../components/ComponentConfigForm";
import ExplanationButton from "../../components/explanations/ExplanationButton";
import EnvironmentVariablesExplanation from "../../components/explanations/EnvironmentVariablesExplanation";
import { ComponentConfig, IAppDetails } from "../../types";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { PricingPlanNames } from "../../enums/enums";
import { LoadingOutlined } from "@ant-design/icons";


const AppSettingsEnvironmentVars = () => {
    const { projectId, clusterId, appName } = useParams();
    const [loading, setLoading] = useState(false);
    const { app: { schema, image, namespace, configuration, monitoring } } = useOutletContext<{ app: IAppDetails }>();
    const { paymentPlan: userPaymentPlan } = useRecoilValue(currentProjectState)

    const deployApplication = async (configurations: ComponentConfig[] = []) => {
        const domainConfigs = (configuration || [])
            .filter(c => ["MIC_DOMAIN_NAME", "MIC_DOMAIN_TLS"].includes(c.key));

        const microserviceConfigurations = configurations
            .filter(c => c.key !== "MIC_CONTAINER_PORT")
            .map(c => ({
                key: c.key,
                value: c.value,
                reference: c.reference,
                sensitive: c.sensitive
            }) as MicroserviceConfigurationItem).concat(domainConfigs);

        const microserviceRequest: DeployMicroserviceRequest = {
            deployment: {
                image,
                configurations: microserviceConfigurations,
                containerPort: parseInt(configurations.find(c => c.key === "MIC_CONTAINER_PORT")!.value)
            },
            autoScaling: userPaymentPlan?.id === PricingPlanNames.FREE ? undefined : {
                cpu: monitoring.initialCpu,
                maxCpu: monitoring.cpuLimit,
                memory: monitoring.initialMemory,
                maxMemory: monitoring.memoryLimit,
                cpuUtilization: 80,
                memoryUtilization: 80,
                minReplicas: monitoring.minReplicas,
                maxReplicas: monitoring.maxReplicas
            }
        };

        await getKubeService().deployMicroservice(
            appName!,
            clusterId!,
            namespace,
            projectId!,
            microserviceRequest
        );
    }

    const handleUpdateEnvVars = async (configs: ComponentConfig[]) => {
        try {
            setLoading(true);

            await deployApplication(configs);

            notification.success({
                message: "Environment variables updated",
                description: "It would take a few moments to apply the changes.",
                icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
            });
        } catch (error: any) {
            notification.error({
                message: "Updating environment variables failed",
                description: error.response.data.message
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <Space direction="vertical" className="full-width">
            <Card>
                <Card.Meta
                    title={
                        <>
                            <span>Environment variables</span>
                            <ExplanationButton
                                content={<EnvironmentVariablesExplanation />}
                            />
                        </>
                    }
                    description={
                        <>
                            Update the values of the runtime environment variables declared in the <code style={{ color: "var(--primary-color)" }}>.microtica/schema.json</code> file of your app.
                            A new deployment will be initiated for your changes to take effect.
                        </>
                    }
                />
                <Divider />
                <ComponentConfigForm
                    schema={{
                        ...schema,
                        properties: {
                            ...schema?.properties,
                            inputs: {
                                ...schema?.properties?.inputs,
                                required: (schema?.properties?.inputs?.required || []).concat("MIC_CONTAINER_PORT"),
                                properties: {
                                    ...schema?.properties?.inputs?.properties,
                                    "MIC_CONTAINER_PORT": {
                                        minimum: 80,
                                        maximum: 65555,
                                        type: "number",
                                        description: "The port on which your application is listening for requests",
                                        default: parseInt((configuration || []).find(c => c.key === "MIC_CONTAINER_PORT")?.value || "80")
                                    }
                                }
                            }
                        }
                    }}
                    values={configuration!}
                    loading={loading}
                    onChange={handleUpdateEnvVars}
                />
            </Card>
        </Space>
    );

}

export default AppSettingsEnvironmentVars;