
interface ParseTemplatesRepoUrlResponse {
    origin: string;         // https://github.com
    username: string;       // microtica
    templateRepo: string;   // templates
    templateName: string[]; // angularjs
}

/**
 * 
 * @param url works only for GitHub repos (format: https://github.com/microtica/templates/tree/master/strapi)
 */
export const parseTemplatesRepositoryUrl = (url: string): ParseTemplatesRepoUrlResponse => {
    const { origin, pathname } = new URL(url);
    const [, username, templateRepo, , , ...templateName] = pathname!.split("/");
    return {
        origin,
        username,
        templateRepo,
        templateName
    }
}
