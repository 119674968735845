import { Card, Divider, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { getElasticSearchService, getEnvironmentService, getKubeService } from "../../backend";
import { ProjectLimitations } from "@microtica/ms-project-sdk";
import { fetchProjectLimitations } from "../../utils/local-storage";
import { GetStagesItem } from "@microtica/ms-engine-sdk/axios/api";
import CircleProgress from "../../components/CircleProgress";
import { useParams } from "react-router-dom";

const ProjectSettingsUsage = () => {
    const { projectId } = useParams();
    const [loading, setLoading] = useState(true);
    const [projectLimitations, setProjectLimitations] = useState<ProjectLimitations>();
    const [environments, setEnvironments] = useState<GetStagesItem[]>([]);
    const [totalEnvironments, setTotalEnvironments] = useState<number>();
    const [totalDeployedServices, setTotalDeployedServices] = useState<number>();
    const [buildMinutesUsed, setBuildMinutesUsed] = useState<number>();
    const [deploymentsUsed, setDeploymentsUsed] = useState<number>();


    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!environments.length) {
            fetchKubernetesServices();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [environments]);

    const loadData = async () => {
        try {
            const limitations = fetchProjectLimitations();
            setProjectLimitations(limitations);

            await Promise.all([
                fetchEnvironments(),
                fetchBuildMinutes(),
                fetchDeployments()
            ]);
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    const fetchEnvironments = async () => {
        try {
            const { data: { stages } } = await getEnvironmentService().getStages(projectId!);
            setTotalEnvironments(stages.length);
            setEnvironments(stages);
        } catch (e) {
            setTotalEnvironments(undefined);
        }
    }

    const fetchBuildMinutes = async () => {
        try {
            const [{ data: { response: { buildTime } } }, { data: { response: { deploymentTime } } }] = await Promise.all([
                getElasticSearchService().getPipelineMetrics(projectId!),
                getElasticSearchService().getDeploymentTime(projectId!)
            ]);
            const MILLISECONDS_TO_MINUTES = 60000;
            setBuildMinutesUsed(Math.round((buildTime + deploymentTime) / (MILLISECONDS_TO_MINUTES)));
        } catch (e) {
            setBuildMinutesUsed(undefined);
        }
    }

    const fetchDeployments = async () => {
        try {
            const { data: { response: { numberOfDeployments } } } = await
                getElasticSearchService().getNumberOfDeployments(projectId!, "stage")
            setDeploymentsUsed(numberOfDeployments)
        } catch (e) {
            setDeploymentsUsed(undefined);
        }
    }

    const fetchKubernetesServices = async () => {
        try {
            const { data: { microservices } } = await getKubeService().getDeployedMicroservicesInProject(projectId!);
            setTotalDeployedServices(microservices.length);
        } catch (e) {
            setTotalDeployedServices(undefined);
        }
    }

    return (
        <>
            <Card bordered>
                <Card.Meta
                    title="Plan usage"
                    description="Track how much of your resources you have used"
                />
                <Divider />
                {loading ?
                    <Skeleton active /> :
                    <Row gutter={[24, 24]}>
                        <CircleProgress
                            title="Environments"
                            subtitle="Number of existing environments"
                            currentUsage={totalEnvironments}
                            limit={projectLimitations?.environmentsAllowed}
                            percent={
                                totalEnvironments! / projectLimitations?.environmentsAllowed! <= 1 ?
                                    Math.round(totalEnvironments! / projectLimitations?.environmentsAllowed! * 100) :
                                    totalEnvironments && projectLimitations?.environmentsAllowed ?
                                        100 :   // in case the user somehow surpassed the limitation, should not happen (eg. 500/400 used)
                                        0       // unable to fetch current usage or limitations
                            }
                            mdSize={8}
                        />
                        <CircleProgress
                            title="Applications"
                            subtitle="Number of deployed applications"
                            currentUsage={totalDeployedServices}
                            limit={projectLimitations?.kubernetesServices}
                            percent={
                                totalDeployedServices! / projectLimitations?.kubernetesServices! <= 1 ?
                                    Math.round(totalDeployedServices! / projectLimitations?.kubernetesServices! * 100) :
                                    totalDeployedServices && projectLimitations?.kubernetesServices ?
                                        100 :   // in case the user somehow surpassed the limitation, should not happen (eg. 500/400 used)
                                        0       // unable to fetch current usage or limitations
                            }
                            mdSize={8}
                        />
                        <CircleProgress
                            title="Build minutes"
                            subtitle="Number of used build minutes"
                            currentUsage={buildMinutesUsed}
                            limit={projectLimitations?.buildMinutes}
                            percent={
                                buildMinutesUsed! / projectLimitations?.buildMinutes! <= 1 ?
                                    Math.round(buildMinutesUsed! / projectLimitations?.buildMinutes! * 100) :
                                    buildMinutesUsed && projectLimitations?.buildMinutes ?
                                        100 :   // in case the user somehow surpassed the limitation, should not happen (eg. 500/400 used)
                                        0       // unable to fetch current usage or limitations
                            }
                            mdSize={8}
                        />
                        <CircleProgress
                            title="Deployments"
                            subtitle="Number of used deployments"
                            currentUsage={deploymentsUsed}
                            limit={projectLimitations?.deploymentsPerMonth}
                            percent={
                                deploymentsUsed! / projectLimitations?.deploymentsPerMonth! <= 1 ?
                                    Math.round(deploymentsUsed! / projectLimitations?.deploymentsPerMonth! * 100) :
                                    deploymentsUsed && projectLimitations?.deploymentsPerMonth ?
                                        100 :   // in case the user somehow surpassed the limitation, should not happen (eg. 500/400 used)
                                        0       // unable to fetch current usage or limitations
                            }
                            mdSize={8}
                        />
                    </Row>
                }
            </Card>
        </>
    )
}

export default ProjectSettingsUsage;