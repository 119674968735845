import { trackEvent } from "./index";

export const trackKubeConnectInit = () => {
    trackEvent("kube_connect_init", {
    });
};

export const trackRegistryCreateInit = () => {
    trackEvent("registry_create_init", {
    });
};