import { DependencyList, useMemo } from "react";

export function formatAmount(amount: number, currency: string, maximumFractionDigits: number = 2) {
    try {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
            maximumFractionDigits
        });
        return formatter.format(amount);
    } catch (error) {
        return "";
    }
}

export const useCardClassName = (disabled: boolean, deps: DependencyList): string => {
    return useMemo(() => {
        const classNames = [];

        if (disabled) {
            classNames.push("ant-card-disabled");
        }

        return classNames.join(" ");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
