import { ReactNode, useEffect, useState } from "react";
import { Card, Space } from "antd";
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

interface CardProperties {
    style: CardStyle;
    icon: ReactNode;
}
interface CardStyle {
    backgroundColor: string;
    borderStyle?: string;
    borderColor?: string;
    borderWidth?: string;
}
type MessageCardType = "info" | "error" | "neutral" | "domain-info" | "cta" | "warning" | "success";

export interface MessageCardProps {
    type: MessageCardType;
    text: ReactNode;
    extra?: ReactNode;
    style?: object;
    showIcon?: boolean;
    icon?: ReactNode;
}

const MessageCard = ({ text, type, extra, style = {}, icon, showIcon = true }: MessageCardProps) => {
    const [cardProperties, setCardProperties] = useState<CardProperties>();

    useEffect(() => {
        formatStyle(type, icon);
    }, [type, icon])

    const formatStyle = (type: MessageCardType, icon: ReactNode) => {
        switch (type) {
            case "info":
                return setCardProperties({
                    icon: icon || <ExclamationCircleOutlined style={{ color: "#3399ff", fontSize: 28 }} />,
                    style: {
                        borderStyle: "none none none solid",
                        borderColor: "#3399ff",
                        borderWidth: "5px",
                        backgroundColor: "#cce5ff",
                    }
                });
            case "error":
                return setCardProperties({
                    icon: icon || <ExclamationCircleOutlined style={{ color: "red", fontSize: 28 }} />,
                    style: {
                        borderStyle: "none none none solid",
                        borderColor: "red",
                        borderWidth: "5px",
                        backgroundColor: "#ffe6e6",
                    }
                });
            case "neutral":
                return setCardProperties({
                    icon: icon || <ExclamationCircleOutlined style={{ color: "gray", fontSize: 28 }} />,
                    style: {
                        borderStyle: "none none none solid",
                        borderColor: "gray",
                        borderWidth: "5px",
                        backgroundColor: "whitesmoke"
                    }
                });
            case "domain-info":
                return setCardProperties({
                    icon: icon || <ExclamationCircleOutlined style={{ color: "#3399ff", fontSize: 28 }} />,
                    style: {
                        borderStyle: "none none none solid",
                        borderColor: "#3399ff",
                        borderWidth: "5px",
                        backgroundColor: "#3899ff1f"
                    }
                });
            case "cta":
                return setCardProperties({
                    icon: icon || <ExclamationCircleOutlined style={{ color: "#3399ff", fontSize: 28 }} />,
                    style: {
                        backgroundColor: "#cce5ff",
                        borderStyle: "solid solid solid solid",
                        borderColor: "#3399ff",
                        borderWidth: "1px"
                    }
                });
            case "warning":
                return setCardProperties({
                    icon: icon || <ExclamationCircleOutlined style={{ color: "#faad14", fontSize: 28 }} />,
                    style: {
                        backgroundColor: "#faad141a",
                        borderStyle: "none none none solid",
                        borderColor: "#faad14",
                        borderWidth: "5px"
                    }
                })
            case "success":
                return setCardProperties({
                    icon: icon || <CheckCircleOutlined style={{ color: "#2E9A00", fontSize: 28 }} />,
                    style: {
                        backgroundColor: "#F6FFED",
                        borderStyle: "none none none solid",
                        borderColor: "#2E9A00",
                        borderWidth: "5px"
                    }
                })
        }
    }

    return (
        <Card
            style={{
                ...cardProperties?.style,
                ...style
            }}
            key="messageCard"
            className="message-card"
        >
            <Space className="flex-justify-space-between">
                <Space size="large">
                    {
                        showIcon ?
                            cardProperties?.icon :
                            null
                    }
                    {text}
                </Space>
                <div style={{ textAlign: "right" }}>
                    {extra}
                </div>
            </Space>
        </Card>
    )
}

export default MessageCard;