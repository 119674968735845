
import { atom } from "recoil";
import { Dictionary, FormState } from "../types";

export const templateState = atom({
    key: "TemplateState",
    default: {
        login: "disabled",
        git: "disabled",
        config: "disabled",
        env: "disabled",
        cluster: "disabled",
        deploy: "disabled"
    } as Dictionary<FormState>
});

export const currentTemplateStepState = atom({
    key: "CurrentTempalateState",
    default: {
        name: "" as "login" | "git" | "config" | "cluster" | "env" | "deploy",
        index: -1
    }
});

export const latestTemplateStepState = atom({
    key: "LatestTemplateState",
    default: {
        name: "" as "login" | "git" | "config" | "cluster" | "env" | "deploy",
        index: -1
    }
});