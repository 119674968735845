import { useNavigate, useParams } from "react-router";
import { ChartMetric } from "../../../types";
import { Button, Space, Tooltip } from "antd";
import AlarmEditSvg from "../../../assets/alarm-edit.svg";
import AlarmCreateSvg from "../../../assets/alarm-create.svg";
import { PixieMetricGroupKeys } from "./index";
import { GetAlarmConfigByIdResponseOneOf } from "@microtica/ms-monitoring-sdk";

export const ActionElement = ({ metric, metricGroup, selectedApps, selectedCluster }:
    { metric: ChartMetric, metricGroup: PixieMetricGroupKeys, selectedApps: string[], selectedCluster: string }) => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    // there isn't exactly one app selected, disable actions
    if (selectedApps.length !== 1) {
        return (
            <Tooltip title={"Choose exactly one application"}>
                <Button
                    type="link"
                    style={{ padding: "4px 0" }}
                    disabled={true}
                >
                    <img src={AlarmCreateSvg} style={{ width: "22px", opacity: "50%" }} alt="alarm" />
                </Button>
            </Tooltip>
        )
    }

    // alarm exists, display Edit and Delete actions
    if (selectedApps.length === 1 && metric.alarms &&
        metric.alarms.findIndex(alarm => (alarm as GetAlarmConfigByIdResponseOneOf).namespace ?
            selectedApps[0] === `${(alarm as GetAlarmConfigByIdResponseOneOf).namespace}/${alarm.applicationName}` :
            selectedApps[0] === alarm.applicationName) !== -1) {
        return (
            <Space>
                <Tooltip title={"Edit alarm"}>
                    <Button
                        type="link"
                        style={{ padding: "4px 0" }}
                        onClick={() => navigate(
                            `/projects/${projectId}/monitoring/${metric.type}/alarm/${metricGroup}`,
                            {
                                state: {
                                    default: {
                                        namespaceAndApplication: selectedApps[0],
                                        clusterId: selectedCluster,
                                    },
                                }
                            }
                        )}
                    >
                        <img src={AlarmEditSvg} style={{ width: "22px" }} alt="alarm" />
                    </Button>
                </Tooltip>
            </Space>
        );
    }
    // alarm doesn't exist, display Create action
    return (
        <Tooltip title={"Create alarm"}>
            <Button
                type="link"
                style={{ padding: "4px 0" }}
                onClick={() => navigate(
                    `/projects/${projectId}/monitoring/${metric.type}/alarm/${metricGroup}`,
                    {
                        state: {
                            default: {
                                namespaceAndApplication: selectedApps[0],
                                clusterId: selectedCluster,
                            },
                        }
                    }
                )}
            >
                <img src={AlarmCreateSvg} style={{ width: "22px" }} alt="alarm" />
            </Button>
        </Tooltip>
    );
}