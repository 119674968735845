import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Row, Space } from "antd";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { useDoubleConfigurationModal } from "../../contexts/Modal";
import { IAppDetails } from "../../types";
import { handleDeleteApp, handleRestartApp } from "../../utils/application/handle-app-danger-actions";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";

const AppSettingsDangerZone = () => {
    const navigate = useNavigate();
    const { projectId, envId, componentId, clusterId, appName } = useParams() as {
        projectId: string;
        envId: string;
        componentId: string;
        clusterId: string;
        namespace: string;
        appName: string;
    };
    const { app: { image, configuration, namespace, monitoring } } = useOutletContext<{ app: IAppDetails }>();
    const { open: openConfirmModal } = useDoubleConfigurationModal();
    const { paymentPlan: userPaymentPlan } = useRecoilValue(currentProjectState)

    return (
        <Space direction="vertical" className="full-width">
            <Card bordered>
                <Card.Meta
                    title={
                        <Space>
                            <ExclamationCircleOutlined style={{ color: 'red' }} />
                            Restart Application
                        </Space>
                    }
                    description="The current application instances will be terminated and new instances will be created."
                />
                <Divider />
                <Row>
                    <Col span={24} style={{ textAlign: "right" }}>
                        <Button
                            danger
                            onClick={() => openConfirmModal({
                                title: "Restart Application?",
                                description: "The current application instances will be terminated and new instances will be created.",
                                alert: <b>This action cannot be undone.</b>,
                                confirmation: appName,
                                okText: "Restart Application",
                                cancelText: 'Cancel',
                                onOk: () => handleRestartApp({
                                    projectId: projectId,
                                    envId: envId,
                                    app: {
                                        name: appName,
                                        clusterId: clusterId || componentId,
                                        namespace,
                                        image,
                                        monitoring,
                                        configurations: configuration
                                    },
                                    userPaymentPlan: userPaymentPlan?.id!,
                                    navigate
                                })
                            })}>
                            Restart
                        </Button>
                    </Col>
                </Row>
            </Card>
            <br />
            <Card bordered>
                <Card.Meta
                    title={
                        <Space>
                            <ExclamationCircleOutlined style={{ color: 'red' }} />
                            Delete Application
                        </Space>
                    }
                    description="The application will be permanently deleted, including its deployments and domains. This action is irreversible and can not be undone."
                />
                <Divider />
                <Row>
                    <Col span={24} style={{ textAlign: "right" }}>
                        <Button
                            type="primary"
                            danger
                            onClick={() => openConfirmModal({
                                title: "Delete Application?",
                                description: "The application will be permanently deleted, including its deployments and domains.",
                                alert: <b>This action cannot be undone.</b>,
                                confirmation: appName,
                                okText: "Delete Application",
                                cancelText: 'Cancel',
                                onOk: () => handleDeleteApp({
                                    envId: envId,
                                    projectId: projectId,
                                    app: {
                                        name: appName,
                                        clusterId: clusterId || componentId,
                                        namespace
                                    },
                                    navigate
                                })
                            })}>
                            Delete
                        </Button>
                    </Col>
                </Row>
            </Card>
        </Space>
    );
}

export default AppSettingsDangerZone;