import { Card, Input, Form, Button, Divider, notification, Col, Row } from "antd";
import { LoginUserBasicResponse, LoginUserChallengeResponse } from "@microtica/ms-usermanagement-sdk";
import { GithubOutlined, GoogleOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
import { RequiredMark } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import Title from "antd/lib/typography/Title";
import { getUserManagementService } from "../../backend";
import { useNavigate } from "react-router";
import { useAuth } from "../../contexts/Auth";
import { Link } from "react-router-dom";
import MicroticaLogo from "../../assets/logo-color.svg";

export default function Login() {
    const [form] = Form.useForm();
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const { loginWithExternalProvider, processAuthData } = useAuth();
    const [loading, setLoading] = useState(false);
    const [requiredMark] = useState<RequiredMark>('optional');

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/projects");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    async function handleLogin(values: { email: string; password: string }) {
        try {
            setLoading(true);
            const { data } = (await getUserManagementService().loginUser({
                username: values.email,
                password: values.password
            })) as { data: LoginUserBasicResponse | LoginUserChallengeResponse };

            const { done, challengeName, sessionToken } = data as LoginUserChallengeResponse;

            if (!done && challengeName === "NEW_PASSWORD_REQUIRED") {
                navigate(`/auth/set-password/${values.email}/${sessionToken}`);
            } else {
                await processAuthData(data as LoginUserBasicResponse, "email");
            }

        } catch (error: any) {
            notification.error({
                message: "Unable to Login",
                description: error.response.data.message
            });
        } finally {
            setLoading(false);
        }
    }

    const handleLoginWithGitHub = async () => {
        await loginWithExternalProvider("github", "login");
    }

    const handleLoginWithGoogle = async () => {
        await loginWithExternalProvider("google", "login");
    }

    return (
        <Row gutter={24}>
            <Col xs={24}>
                <Link to="/">
                    <img src={MicroticaLogo} className="login-logo" alt="" />
                </Link>
                <br />
                <Card className="login-card" style={{ margin: "auto" }} bordered>
                    <Card.Meta
                        title={<Title level={4}>Welcome back!</Title>}
                    />
                    <Form
                        form={form}
                        layout="vertical"
                        requiredMark={requiredMark}
                        onFinish={handleLogin}
                        className="auth-form"
                    >
                        <div className="flex-justify-space-between providers-section">
                            <Button className="ant-btn-github provider-btn" icon={<GithubOutlined />} onClick={handleLoginWithGitHub}>
                                GitHub
                            </Button>
                            <Button className="ant-btn-google provider-btn" icon={<GoogleOutlined />} onClick={handleLoginWithGoogle}>
                                Google
                            </Button>
                        </div>
                        <Divider plain>or continue with email</Divider>
                        <div className="input-field-section">
                            <Form.Item
                                name="email"
                                required
                                rules={[
                                    { required: true, message: `Please enter email address` },
                                    { pattern: /^\S+@\S+\.\S+$/, message: "Please enter a valid email address" }
                                ]}
                                className="input-form-item"
                            >
                                <Input placeholder="Email" prefix={<MailOutlined />} />
                            </Form.Item>
                        </div>
                        <div className="input-field-section">
                            <Form.Item
                                name="password"
                                required
                                rules={[{ required: true, message: `Please input password!` }]}
                                className="input-form-item"
                            >
                                <Input type="password" placeholder="Password" prefix={<LockOutlined />} />
                            </Form.Item>
                        </div>
                        <div className="forgot-password-btn">
                            <Link to="/auth/reset-password" tabIndex={-1}>Forgot password?</Link>
                        </div>

                        <Button htmlType="submit" type="default" loading={loading} block className="log-in-btn">
                            Log in
                        </Button>
                        <br />
                        <br />
                        <div>
                            Don't have an account? <Link to="/auth/signup">Sign up</Link>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}