/* eslint-disable import/no-anonymous-default-export */
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { RefreshTokenResponse } from "@microtica/ms-usermanagement-sdk";
import { getUserManagementService, getIdToken } from ".";
import { setRecoil } from "recoil-nexus";
import { paymentPlanRequiredModalState } from "../recoil/payment-plan-required";

import { handleTrackLogoutEvent } from "./tracking";
type Subscriber = (idToken: string) => void;

let isAlreadyFetchingIdToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [] as Subscriber[];

export const registerRequestInterceptor = (instance: AxiosInstance) => {
    instance.interceptors.response.use(
        // If the request succeeds, we don't have to do anything and just return the response
        response => {
            return response;
        },
        error => {
            const errorResponse = error.response;

            if (isPaymentPlanRequiredError(errorResponse)) {
                handlePaymentPlanRequiredError();
                // this supresses the notification.error popup because it returns an object with different format than expected, so it breaks 
                // alternative to this is to handle the 402 error from backend on every request that has a isPaymentLimited flag
                return Promise.reject({
                    code: 402,
                    message: undefined
                });
            }
            if (isRefreshTokenExpiredError(errorResponse)) {
                logout();
            }
            if (isTokenExpiredError(errorResponse)) {
                return resetTokenAndReattemptRequest(error);
            }
            if (isTokenInvalidError(errorResponse)) {
                logout();
            }

            // If the error is due to other reasons, we just throw it back to axios
            return Promise.reject({
                ...error,
                status: errorResponse?.status
            })
        }
    );
}

function isPaymentPlanRequiredError(
    errorResponse?: AxiosResponse<{
        code: number;
        message: string;
        errorCode?: string;
        type: string
    }>
): boolean {
    return errorResponse?.data.code === 402 &&
        // we want the Payment required modal to be displayed only on other errors
        errorResponse?.data.errorCode !== "BUILD_MINUTES_EXCEEDED" &&
        errorResponse?.data.errorCode !== "MANUAL_SCALING_UNAVAILABLE" &&
        errorResponse?.data.errorCode !== "AUTO_SCALING_UNAVAILABLE" &&
        errorResponse?.data.errorCode !== "DEPLOYMENTS_USEDD"
}

function isTokenExpiredError(
    errorResponse: AxiosResponse<{
        code: number;
        message: string;
        type: string
    }>
): boolean {
    return errorResponse?.data.code === 401 && errorResponse.data.type === "TokenExpiredError";
}

function isTokenInvalidError(
    errorResponse: AxiosResponse<{
        code: number;
        message: string;
        type: string
    }>
): boolean {
    return errorResponse?.data.code === 401 && errorResponse.data.type === "JsonWebTokenError";
}

function isRefreshTokenExpiredError(
    errorResponse: AxiosResponse<{
        code: number;
        message: string;
        type: string
    }>
): boolean {
    try {
        return errorResponse?.data.code === 401 &&
            JSON.parse(errorResponse.data.message).code === "NotAuthorizedException";
    } catch (error) {
        return false;
    }
}

function getRefreshToken(): string | null {
    return localStorage.getItem("refreshToken");
}

function refreshLocalStorage(response: RefreshTokenResponse): void {
    localStorage.setItem("idToken", response.idToken!);
    localStorage.setItem("expirationDate", new Date(
        new Date().getTime() + response.expiresIn! * 1000
    ).toString());
}

async function resetTokenAndReattemptRequest(error: AxiosError) {
    try {
        const { response: errorResponse } = error;
        const refreshToken = getRefreshToken();
        const idToken = getIdToken();
        if (!refreshToken || !idToken) {
            // We can't refresh, throw the error anyway
            return Promise.reject(error);
        }
        /* Proceed to the token refresh procedure
        We create a new Promise that will retry the request,
        clone all the request configuration from the failed
        request in the error object. */
        const retryOriginalRequest = new Promise(resolve => {
            /* We need to add the request retry to the queue
            since there another request that already attempt to
            refresh the token */
            addSubscriber(idToken => {
                errorResponse!.config.headers!.Authorization = 'Bearer ' + idToken;
                resolve(axios(errorResponse!.config));
            });
        });
        if (!isAlreadyFetchingIdToken) {
            isAlreadyFetchingIdToken = true;
            const response = await getUserManagementService().refreshToken({ refreshToken, accessToken: "Bearer " + idToken });
            if (!response.data) {
                return Promise.reject(error);
            }
            const newToken = response.data.idToken;
            refreshLocalStorage(response.data);
            onIdTokenFetched(newToken!);
        }
        return retryOriginalRequest;
    } catch (err) {
        return Promise.reject(err);
    } finally {
        isAlreadyFetchingIdToken = false;
    }
}

function onIdTokenFetched(idToken: string) {
    // When the refresh is successful, we start retrying the requests one by one and empty the queue
    subscribers.forEach(callback => callback(idToken));
    subscribers = [];
}

function addSubscriber(callback: (idToken: string) => void) {
    subscribers.push(callback);
}


function logout() {
    localStorage.removeItem('idToken');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('profile');

    (window as Window).location = "/auth/login";
    handleTrackLogoutEvent();
}

function handlePaymentPlanRequiredError() {
    // Display PaymentPlanRequiredModal
    setRecoil(paymentPlanRequiredModalState, { visible: true });
}