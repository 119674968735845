const CostDashboardExplorerExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Cost Explorer
            </div>
            <div className="explanation-subtitle">
                How much do you spend on AWS?
            </div>
            <br />
            <p>
                Get a comprehensive view of your current and projected costs for this month, previous year's costs, and forecasts for the upcoming year. Easily track your spending by AWS services with a detailed breakdown.
            </p>
            <p>
                Stay informed by opting to receive weekly and monthly reports of your AWS spending directly in your email. These reports provide breakdowns of expenses by region, cost-intensive services, and recently created resources.
            </p>
        </>
    )
}

export default CostDashboardExplorerExplanation;