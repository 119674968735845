const AppSettingsScalingExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Scaling
            </div>
            <br />

            <p>
                Microtica allows you to define parameters to control the automated scaling of your application.
                The application is started with the initial value for CPU and Memory and that value can increase
                up to the maximum value specified.
            </p>
            <p>
                Additionally, you can control the minimium and maximum number of instances of your application.
            </p>
            <p>
                Automated scaling will be initiated when the application reaches 80% of the maximum assigned CPU or Memory resources.
                The application will not continue to scale if the maximum number of instances is reached.
            </p>
        </>
    )
}

export default AppSettingsScalingExplanation;