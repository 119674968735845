const PipelineBuildsExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Pipeline builds
            </div>
            <div className="explanation-subtitle">
                Defined automated build pipeline
            </div>
            <br />

            <p>
                Microtica allows you to define the process your source code should go through from your local machine
                to production by using pipelines.
            </p>
            <p>
                <span>
                    <span style={{ fontStyle: "italic" }}>Pipeline</span> is a composition of multiple steps executed in sequence and/or parallel. Each step performs a specific action such as:
                </span>
                <ul>
                    <li>Compile and package the code</li>
                    <li>Run unit and integration tests</li>
                    <li>Run code quality checks</li>
                    <li>Build and push Docker images</li>
                    <li>Deploy applications on Kubernetes</li>
                </ul>
            </p>
            <p>
                Learn more about <a href="https://docs.microtica.com/pipelines" target="_blank" rel="noreferrer">Pipelines.</a>
            </p>
        </>
    )
}

export default PipelineBuildsExplanation;