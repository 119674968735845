import { Card, Divider, Space } from "antd";
import { useOutletContext, useParams } from "react-router";
import AssignAppDomainSteps from "../../components/settings/AssignAppDomainSteps";
import MessageCard from "../../components/cards/MessageCard";
import { IAppDetails } from "../../types";

const AppSettingsCustomDomain = () => {
    const { envId } = useParams();
    const { app } = useOutletContext<{ app: IAppDetails }>();

    return (
        <Space direction="vertical" className="full-width">
            {app.clusterType === "shared" || !!app.associatedNamespace ?
                <Card>
                    <Card.Meta
                        title="Assign domain"
                        description={`Domain configuration is not enabled for apps deployed on a ${app.clusterType === "shared" ? "shared cluster" : "cluster with access limitation"}`}
                    />
                </Card> :
                <Card>
                    <Card.Meta
                        title="Assign domain"
                        description={
                            <>
                                In order to enable external access from the Internet to your deployed application, you need to assign a domain.<br />
                                If you have already configured a domain, you may change it here.
                            </>
                        }
                    />
                    <Divider />
                    {
                        app.isDomainWorking ?
                            <MessageCard
                                type="domain-info"
                                style={{ marginBottom: 30 }}
                                text={
                                    <div>
                                        <div style={{ fontWeight: 600 }}>
                                            Your application is exposed on <a href={`https://${app.domain}`} target="_blank" rel="noreferrer">{app.domain}</a>
                                        </div>
                                        <div>
                                            If you want to change the domain, follow the steps below.
                                        </div>
                                    </div>
                                }
                                showIcon={false}
                            />
                            : null
                    }
                    <AssignAppDomainSteps
                        envId={envId!}
                        appDetails={app}
                        updateDomainEnabled={true}
                    />
                </Card>
            }
        </Space>
    );
}

export default AppSettingsCustomDomain;