import Modal from "antd/lib/modal/Modal";
import { Alert, Button, Space, notification } from "antd";
import { useEffect, useState } from "react";
import { getCloudService } from "../../backend";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { GetAwsAccountResponse } from "@microtica/ms-aws-sdk";

export interface ConnectAwsAccountModalProps {
    visible?: boolean;
    onOk?: (id: string) => void;
    onCancel?: () => void;
}

// In seconds
const CONNECTION_WAITING_TIME = 240;

const ConnectAwsAccountModal = ({
    visible,
    onOk,
    onCancel
}: ConnectAwsAccountModalProps) => {
    const currentProject = useRecoilValue(currentProjectState);
    const [modalVisible, setModalVisible] = useState(visible);
    const [connectingAccount, setConnectingAccount] = useState(false);
    const [connectUrl, setConnectUrl] = useState<string>();
    const [initialAwsAccounts, setInitialAwsAccounts] = useState<GetAwsAccountResponse[]>([]);
    // Waiting time for the connection to be established (in seconds)
    const [waitingTime, setWaitingTime] = useState(0);

    useEffect(() => {
        setModalVisible(visible);
    }, [visible]);

    useEffect(() => {
        const load = async () => {
            try {
                const { data: { url } } = await getCloudService().getAwsConnectUrl(currentProject!.id);
                setConnectUrl(url);
            } catch (error) {
                notification.error({
                    message: "Error getting AWS connect URL",
                    description: "Please contact our support team on support@microtica.com or write on our Discord community channel."
                });
            }
        }

        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const load = async () => {
            try {
                const { data: { awsAccounts: currentAwsAccounts } } = await getCloudService().getAwsAccounts(currentProject.id);
                if (currentAwsAccounts.length > initialAwsAccounts.length) {
                    const currentAccountIds = currentAwsAccounts.map(a => a.id);
                    const initialAccountIds = initialAwsAccounts.map(a => a.id);
                    const newAwsAccountId = currentAccountIds.find(id => !initialAccountIds.includes(id));
                    onOk?.(newAwsAccountId!);
                    setModalVisible(false);
                }
            } catch (error) {
                notification.error({
                    message: "Error connecting your AWS account",
                    description: "Please contact our support team on support@microtica.com or write on our Discord community channel."
                });
            }
        }

        if (connectingAccount === true) {
            const interval = setInterval(async () => {
                await load();
                setWaitingTime(t => t + 5);
            }, 5000);
            return () => clearInterval(interval);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectingAccount]);

    const handleConnectAwsAccount = async () => {
        const { data: { awsAccounts } } = await getCloudService().getAwsAccounts(currentProject.id);
        setInitialAwsAccounts(awsAccounts);
        setConnectingAccount(true);
    }

    return (
        <Modal
            title="Connect AWS Account"
            centered
            closable={false}
            open={modalVisible}
            footer={[
                <div className="flex-justify-space-between flex-align-center">
                    <a href="https://docs.microtica.com/connect-an-aws-account" target="_blank">How to connect AWS account?</a>
                    <Space>
                        <Button onClick={() => {
                            setModalVisible(false);
                            onCancel?.();
                        }}
                        >
                            Close
                        </Button>
                        <Button
                            type="primary"
                            loading={connectingAccount}
                            onClick={handleConnectAwsAccount}
                        >
                            <a href={connectUrl} target="_blank" style={{ color: "white" }} rel="noreferrer">
                                {
                                    connectingAccount ? "Connecting" : "Connect"
                                }
                            </a>
                        </Button>
                    </Space>
                </div>
            ]}
        >
            {
                connectingAccount && waitingTime <= CONNECTION_WAITING_TIME ?
                    <Alert
                        type="info"
                        message={
                            <span>
                                Waiting for the CloudFormation stack to finish. The dialog with close automatically when your AWS account is added successfully.
                            </span>
                        }
                    /> :
                    connectingAccount && waitingTime > CONNECTION_WAITING_TIME ?
                        <Alert
                            type="warning"
                            message={
                                <span>
                                    Connecting your AWS account takes more than usual!
                                    <br />
                                    <br />
                                    Please check the following:
                                    <ul>
                                        <li>You initiated the creation of the CloudFormation stack</li>
                                        <li>The AWS account isn't already connected in this project</li>
                                    </ul>
                                </span>
                            }
                        /> :
                        <span>
                            Click on <b>Connect</b> button to create the CloudFormation stack that will give Microtica permissions to provision infrastructure on your AWS account.
                            <br />
                            <br />
                            Once you create the stack, the connection will be established automatically.
                        </span>
            }
        </Modal>
    )
}

export default ConnectAwsAccountModal;