import { User } from "@microtica/ms-usermanagement-sdk";
import { getProjectService, getUserManagementService } from "../../backend";
import { Permission } from "@microtica/ms-project-sdk";

export const getUsersWithinProject = async (projectId: string): Promise<{
    users: {
        id: string;
        permission: Permission;
    }[],
    userProfiles: {
        [key: string]: User;
    }
}> => {
    const { data: { users } } = await getProjectService().getUsers(projectId!);
    const { data: userProfiles } = await getUserManagementService().getMultipleUsers(
        projectId!,
        {
            userIds: users.map(user => user.id)
        }
    );

    return {
        users,
        userProfiles
    };
}