import { Project } from "@microtica/ms-project-sdk";
import { Button, Skeleton } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getProjectService } from "../../backend";
import PageContentWrapper from "../../components/PageContentWrapper";
import { useCurrentProject } from "../../contexts/Project";
import { currentProjectState } from "../../recoil/project";
import CreateEnvironmentModal from "./CreateEnvironmentModal";
import ExplanationButton from "../../components/explanations/ExplanationButton";
import EnvironmentExplanation from "../../components/explanations/EnvironmentExplanation";

const ProjectRoot = () => {
    const location = useLocation();
    const { projectId } = useParams();
    const currentProject = useRecoilValue(currentProjectState);
    const { updateCurrentProject } = useCurrentProject();
    const [activeTab, setActiveTab] = useState("environments");
    const [projectDetails, setProjectDetails] = useState<Project>();
    const [createEnvironmentModalVisible, setCreateEnvironmentModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const loadData = async () => {
        setLoading(true);
        const { data: project } = await getProjectService().getProject(projectId!);

        if (currentProject.id !== projectId) {
            await updateCurrentProject(projectId!);
        }

        setProjectDetails(project);
        setLoading(false);
    }

    useEffect(() => {
        if (location.pathname.split("/")[3] && activeTab !== location.pathname.split("/")[3]) {
            setActiveTab(location.pathname.split("/")[3]);
        }

        if (!projectDetails
            || projectId !== projectDetails.id
            || currentProject.name !== projectDetails.name
            || currentProject.description !== projectDetails.description) {
            // fetch data if there is inconsistency between the data in the local storage (currentProject) and
            // the data used in the Outlet (projectDetails) that is passed between the Project* components
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <PageContentWrapper
            size="large"
            header={
                <PageHeader
                    title={
                        <>
                            Environments
                            <ExplanationButton
                                content={<EnvironmentExplanation />}
                            />
                        </>
                    }
                    extra={
                        <Button
                            onClick={() => {
                                setCreateEnvironmentModalVisible(true);
                            }}>
                            Create Environment
                        </Button>
                    }
                />
            }
            loading={loading}
        >
            {
                !loading ?
                    <Outlet
                        context={{ projectDetails }}
                    />
                    : <Skeleton />
            }
            {createEnvironmentModalVisible &&
                <CreateEnvironmentModal
                    visible={createEnvironmentModalVisible}
                    onCancel={() => setCreateEnvironmentModalVisible(false)}
                    onOk={async () => {
                        await loadData();
                        setCreateEnvironmentModalVisible(false);
                    }}
                />}
        </PageContentWrapper>
    );
}

export default ProjectRoot;