import { Button, Col, Result, Row, Space, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { App, ChartMetric, Component, Dictionary } from "../../types";
import { getMonitoringService } from "../../backend";
import { useNavigate, useParams } from "react-router";
import MonitoringDisabledPlaceholder from "../../components/explanations/MonitoringDisabledPlaceholder";
import MonitoringErrorPlaceholder from "../../components/explanations/MonitoringErrorPlaceholder";
import { GetServiceMetrics, mapMetricsToWidget } from "../../utils/metrics";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ChartWidget from "../../components/dashboards/widgets/ChartWidget";
import { ClusterMonitoringStatusResponseStatusEnum, EnableMonitoringRequestClusterTypeEnum, GetAlarmConfigByIdResponseOneOf, GetAlarmConfigByIdResponseOneOf1 } from "@microtica/ms-monitoring-sdk";
import MonitoringNoMetricsPlaceholder from "../../components/explanations/MonitoringNoMetricsPlaceholder";
import AlarmEditSvg from "../../assets/alarm-edit.svg";
import AlarmCreateSvg from "../../assets/alarm-create.svg";
import { PixieMetricGroupKeys, mapGraphNameToPixieMetricGroup, mapPixieMetricGroupToGraphName } from "../project/utils";

interface AppMetricsProps {
    component: Component;
    app: App;
}

const AppMetrics = ({ component }: AppMetricsProps) => {
    const navigate = useNavigate();
    const { projectId, envId, namespace, appName } = useParams() as { projectId: string; envId: string; namespace: string; appName: string; }
    const [loading, setLoading] = useState(true);
    const [metrics, setMetrics] = useState<Dictionary<ChartMetric>>({});
    const [monitoringEnabled, setMonitoringEnabled] = useState(true);
    const [monitoringError, setMonitoringError] = useState<{ status: ClusterMonitoringStatusResponseStatusEnum; message: string; }>();

    useEffect(() => {
        loadMetrics();
    }, []);

    const loadMetrics = async () => {
        try {
            setLoading(true);
            const [{ data: metrics }, { data: { alarmsConfigs } }] = await Promise.all([
                getMonitoringService().getServiceMetrics(
                    component.clusterId!,
                    envId,
                    "-2h",
                    projectId,
                    namespace ? `${namespace}/${appName}` : undefined,
                    undefined,
                    "5m"
                ),
                getMonitoringService().getAlarmsConfigsByClusterId(component.clusterId!, projectId)
            ]);

            const clusterType = component.type === "kubernetes" ? EnableMonitoringRequestClusterTypeEnum.K8s : EnableMonitoringRequestClusterTypeEnum.Ecs;
            const metricsData = await mapMetricsToWidget(metrics as GetServiceMetrics, clusterType, { envId, projectId });

            // group alarms by their metricName
            const formattedAlarms = alarmsConfigs
                .filter(alarm => alarm.applicationName === appName && ((alarm as GetAlarmConfigByIdResponseOneOf).namespace ? (alarm as GetAlarmConfigByIdResponseOneOf).namespace === namespace : true))
                .reduce((acc, alarm) => {
                    if (clusterType === EnableMonitoringRequestClusterTypeEnum.Ecs) {
                        const metricName = (alarm.config as unknown as { MetricName: string }).MetricName;
                        if (acc[metricName]?.length) {
                            acc[metricName].push(alarm);
                        } else {
                            acc[metricName] = [alarm];
                        }
                    } else {
                        if (acc[mapPixieMetricGroupToGraphName[alarm.metricGroup]]?.length) {
                            acc[mapPixieMetricGroupToGraphName[alarm.metricGroup]].push(alarm);
                        } else {
                            acc[mapPixieMetricGroupToGraphName[alarm.metricGroup]] = [alarm];
                        }
                    }
                    return acc;
                }, {} as { [key: string]: (GetAlarmConfigByIdResponseOneOf1 | GetAlarmConfigByIdResponseOneOf)[] });

            if (clusterType === EnableMonitoringRequestClusterTypeEnum.K8s) {
                // append alarm data
                // Pixie alarms
                Object.keys(metricsData).forEach(metricName => {
                    if (formattedAlarms[metricName]) {
                        metricsData[metricName].alarms = formattedAlarms[metricName];
                    }
                })
            } else {
                // AWS alarms
                Object.keys(formattedAlarms)
                    .forEach((metricName) => {
                        const chartName = (formattedAlarms[metricName][0] as GetAlarmConfigByIdResponseOneOf1).awsAlarmName.replace(`${formattedAlarms[metricName][0].clusterId}-`, "");
                        metricsData[chartName].alarms = formattedAlarms[metricName];
                    })
            }

            setMetrics(metricsData);
            setMonitoringError(undefined);
            setMonitoringEnabled(true);
        } catch (error: any) {
            if (error.response?.status === 404) {
                setMonitoringEnabled(false);
            } else {
                setMonitoringEnabled(true);
                setMonitoringError({
                    status: error.response?.data?.status,
                    message: error.response?.data?.statusMessage
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const handleRefresh = async () => {
        await loadMetrics();
    }

    const ActionElement = ({ metric, metricGroup }: { metric: ChartMetric, metricGroup: PixieMetricGroupKeys }) => {
        const selectedApp = namespace ? `${namespace}/${appName}` : appName;
        // alarm exists, display Edit and Delete actions
        if (metric.alarms &&
            metric.alarms.findIndex(alarm => (alarm as GetAlarmConfigByIdResponseOneOf).namespace ?
                selectedApp === `${(alarm as GetAlarmConfigByIdResponseOneOf).namespace}/${alarm.applicationName}` :
                selectedApp === alarm.applicationName) !== -1) {
            return (
                <Space>
                    <Tooltip title={"Edit alarm"}>
                        <Button
                            type="link"
                            style={{ padding: "4px 0" }}
                            onClick={() => navigate(
                                `/projects/${projectId}/monitoring/${metric.type}/alarm/${metricGroup}`,
                                {
                                    state: {
                                        default: {
                                            namespaceAndApplication: selectedApp,
                                            clusterId: component.clusterId,
                                        },
                                    }
                                }
                            )}
                        >
                            <img src={AlarmEditSvg} style={{ width: "22px" }} alt="alarm" />
                        </Button>
                    </Tooltip>
                </Space>
            );
        }
        // alarm doesn't exist, display Create action
        return (
            <Tooltip title={"Create alarm"}>
                <Button
                    type="link"
                    style={{ padding: "4px 0" }}
                    onClick={() => navigate(
                        `/projects/${projectId}/monitoring/${metric.type}/alarm/${metricGroup}`,
                        {
                            state: {
                                default: {
                                    namespaceAndApplication: selectedApp,
                                    clusterId: component.clusterId,
                                },
                            }
                        }
                    )}
                >
                    <img src={AlarmCreateSvg} style={{ width: "22px" }} alt="alarm" />
                </Button>
            </Tooltip>
        );
    }

    return (
        <Space direction="vertical" size="large" className="flex-justify-space-between">
            <div className="flex-justify-space-between flex-align-center">
                <div style={{ fontWeight: 600, fontSize: 16 }}>
                    Application Metrics
                    <div className="gray-text" style={{ fontWeight: 400, fontSize: 14 }}>
                        Metrics from the past 30 minutes
                    </div>
                </div>
                <Space size="middle">
                    <Link
                        to={
                            component.type === "kubernetes" ?
                                `/projects/${projectId}/${envId}/${component.clusterId}/${namespace}/${appName || component.name}/monitoring` :
                                `/projects/${projectId}/${envId}/${component.clusterId}/${appName || component.name}/monitoring`
                        }
                        style={{ fontWeight: "500" }}
                    >
                        See all metrics
                    </Link>
                    <Button icon={<ReloadOutlined />} onClick={handleRefresh} />
                </Space>
            </div>
            {
                loading && Object.entries(metrics).length === 0 ?
                    <div style={{ minHeight: "calc(100vh - 380px)" }}>
                        <Result
                            title="Loading application metrics..."
                            subTitle="Hang tight! We're fetching application monitoring metrics for you."
                            icon={<LoadingOutlined />}
                        />
                    </div> :
                    <div style={{ minHeight: "calc(100vh - 380px)" }}>
                        {
                            !monitoringEnabled ?
                                <MonitoringDisabledPlaceholder
                                    projectId={projectId}
                                    envId={envId}
                                    clusterId={component.clusterId!}
                                    clusterName={component.name}
                                    clusterType={component.clusterType!}
                                    onOk={loadMetrics}
                                /> :
                                monitoringError ? <MonitoringErrorPlaceholder
                                    projectId={projectId}
                                    envId={envId}
                                    componentId={component.id!}
                                    clusterId={component.clusterId!}
                                    clusterName={component.name}
                                    clusterType={component.clusterType!}
                                    error={monitoringError}
                                    onOk={loadMetrics}
                                /> :
                                    Object.keys(metrics).length === 0 ?
                                        <MonitoringNoMetricsPlaceholder
                                            projectId={projectId}
                                            envId={envId}
                                            clusterId={component.clusterId!}
                                            clusterType={component.clusterType!}
                                            onOk={loadMetrics}
                                        /> :
                                        <Row gutter={[24, 24]} style={{ opacity: loading ? 0.8 : 1 }}>
                                            {
                                                Object.entries(metrics).map(([key, metric]) =>
                                                    <Col span={12} key={key}>
                                                        <ChartWidget
                                                            title={key}
                                                            metric={metric}
                                                            action={<ActionElement
                                                                metric={metric}
                                                                metricGroup={metric.type === "k8s" ?
                                                                    mapGraphNameToPixieMetricGroup[key] || key.replaceAll(" ", "_") :
                                                                    key
                                                                }
                                                            />
                                                            }
                                                        />
                                                    </Col>
                                                )
                                            }
                                        </Row>
                        }
                    </div>
            }

        </Space >
    )
}

export default AppMetrics;