import { Button, Card, Col, Empty, Result, Row, Segmented, Space, Tooltip, Typography } from "antd";
import PageContentWrapper from "../../components/PageContentWrapper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ClockCircleOutlined, CrownOutlined, LoadingOutlined, UserOutlined } from "@ant-design/icons";
import Search from "antd/lib/input/Search";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/Auth";
import TemplateAvatar from "../../components/TemplateAvatar";
import { PageHeader } from "@ant-design/pro-layout";
import CloudIcon from "../../components/CloudIcon";
import IaCIcon from "../../components/IaCIcon";
import Paragraph from "antd/es/typography/Paragraph";
import { useRecoilState } from "recoil";
import { projectsState } from "../../recoil/project";
import { getTemplates } from "../../utils/templates";
import { ProjectTemplate } from "../../types";

const { Text } = Typography;

interface TemplatesProps {
}

const Templates = ({ }: TemplatesProps) => {
    const { projectId } = useParams() as { projectId: string; };
    const [projects] = useRecoilState(projectsState);
    const { isLoggedIn, profile } = useAuth();
    const [searchQuery, setSearchQuery] = useState<string>();
    const [loading, setLoading] = useState(true);
    const [templates, setTemplates] = useState<ProjectTemplate[]>([]);
    const [filter, setFilter] = useState('microtica');
    const [filteredTemplates, setFilteredTemplates] = useState<ProjectTemplate[]>([]);

    useEffect(() => {
        if (projects) {
            loadTemplates();
        }
    }, [projects]);

    useEffect(() => {
        // Filter templates
        const filteredTemplates = templates.filter(template => {

            const condition = (filter === "microtica" ?
                template.type === "template" || template.public :
                template.type === "component" && !template.public) &&
                (searchQuery ? template.filterMetadata.toLowerCase().includes(searchQuery.toLowerCase()) : true);

            return condition;
        });

        setFilteredTemplates(filteredTemplates);
    }, [searchQuery, filter, templates])

    const loadTemplates = async () => {
        const allTemplates = await getTemplates(isLoggedIn, projectId);

        setTemplates(allTemplates);

        setLoading(false);
    }

    return (
        <PageContentWrapper
            size="large"
            header={
                <PageHeader
                    title={
                        "Templates"
                    }
                />
            }
        >

            <div className="flex-justify-space-between">
                <Search
                    placeholder="Search templates..."
                    onKeyUp={(event: any) => {
                        if (event.target.value === "") {
                            setSearchQuery("");
                            // reset Templates to initial values (all items)
                            setFilteredTemplates(templates);
                        }
                    }}
                    style={{ width: 300 }}
                    onSearch={setSearchQuery}
                />
                {
                    projectId &&
                    <Segmented
                        options={[
                            { label: 'Microtica Templates', value: 'microtica', icon: <CrownOutlined style={{ color: "goldenrod" }} /> },
                            { label: 'My Templates', value: 'my' },
                        ]}
                        value={filter}
                        onChange={setFilter}
                    />
                }
            </div>
            <br />
            {
                !loading && filteredTemplates.length === 0 &&
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                        "No templates found for the search criteria or the linked cloud provider doesn't have available templates."
                    }
                />
            }
            {
                loading &&
                <Result
                    status="info"
                    icon={<LoadingOutlined />}
                    title="Loading templates..."
                />
            }
            <Row gutter={[24, 24]}>
                {
                    filteredTemplates.map(t =>
                        <Col span={6} style={{ display: "inline-flex", alignSelf: "stretch" }}>
                            <Link to={t.link || ""} style={{ width: "100%" }}>
                                <Card
                                    size="small"
                                    hoverable
                                    styles={{
                                        actions: {
                                            justifyContent: "right",
                                            margin: 0
                                        },
                                        body: {
                                            padding: "20px 17px"
                                        }
                                    }}
                                    actions={[
                                        <Space size="middle">
                                            <CloudIcon provider={t.cloudProvider} />
                                            <IaCIcon provider={t.iac} />
                                        </Space>,
                                        <Tooltip title={t.owner?.name}>
                                            <Text ellipsis style={{ padding: "0 10px" }} className="gray-text">
                                                <UserOutlined /> {t.owner?.name || "Microtica"}
                                            </Text>
                                        </Tooltip>
                                    ]}
                                >
                                    <Card.Meta
                                        title={
                                            <p>
                                                <TemplateAvatar shape="square" size="large" name={t.id} />
                                            </p>
                                        }
                                        description={
                                            <>
                                                <p style={{ fontWeight: 600, color: "black", fontSize: 14 }}>
                                                    <Space>
                                                        {t.name}
                                                        {
                                                            t.pack !== "free" && <CrownOutlined style={{ color: "goldenrod" }} />
                                                        }
                                                    </Space>
                                                </p>
                                                <Paragraph ellipsis={{ rows: 2 }} className="gray-text" style={{ height: "44px" }}>
                                                    {t.description}
                                                </Paragraph>
                                            </>
                                        }
                                    />
                                </Card>
                            </Link>
                        </Col>
                    )
                }
            </Row>
        </PageContentWrapper >
    );
}

export default Templates;