import { Card, Space, Button } from "antd";
import { useState } from "react";
import ConnectAwsAccountModal from "./settings/ConnectAwsAccountModal";
import ChooseAwsAccountCard from "./ChooseAwsAccountCard";
import { trackSpecCloudAccountInit } from "../backend/tracking/user-settings";
import CloudIcon from "./CloudIcon";

interface AwsAccountFormProps {
    disabled?: boolean;
    selectedAccount?: {
        accountId?: string;
        region?: string;
    };
    awsAccounts: { id: string, name: string }[];
    errorClassName?: string;
    onSelect?: ({ accountId, region }: { accountId: string, region: string }) => void;
    visibleInfoForCostReport?: boolean;
}

const AwsAccountSelect = ({
    onSelect,
    selectedAccount,
    disabled,
    errorClassName,
    awsAccounts,
    visibleInfoForCostReport = false
}: AwsAccountFormProps) => {
    const [modalVisible, setModalVisible] = useState(false);
    const classNames = [disabled ? "ant-card-disabled" : "", errorClassName || ""].join(" ");

    return (
        <>
            {
                selectedAccount?.accountId || selectedAccount?.region || awsAccounts.length > 0 ?
                    <ChooseAwsAccountCard
                        classNames={classNames}
                        awsAccounts={awsAccounts}
                        selectedAccount={selectedAccount}
                        onSelect={onSelect}
                        visibleInfoForCostReport={visibleInfoForCostReport}
                    />
                    :
                    <Card type="inner" className={classNames} >
                        <Card.Meta title="AWS Account" description="Configure the AWS account for the selected environment." />
                        <br />
                        <Space direction="vertical">
                            <Button
                                onClick={() => {
                                    setModalVisible(true);
                                    trackSpecCloudAccountInit("aws");
                                }}
                            >
                                <Space>
                                    <CloudIcon provider="aws" /> Connect AWS Account
                                </Space>
                            </Button>
                        </Space>
                    </Card>
            }
            {
                // Workaround. The modal is not unmouting when closed so we need to unmount the whole component using 'modalVisible'
                // We have a process of periodic API calls which remain active even if when the modal closes
                modalVisible &&
                <ConnectAwsAccountModal
                    visible={modalVisible}
                    onOk={(id) => {
                        setModalVisible(false);
                        onSelect?.({ accountId: id, region: "" });
                    }}
                    onCancel={() => setModalVisible(false)}
                />
            }
        </>
    );
}

export default AwsAccountSelect;