import { PipelineBuildDetails, PipelineBuildDetailsMetadataCommit } from "@microtica/ms-ap-sdk";
import { MicroserviceConfigurationItem, StageDeploymentComponentMetadataCommit, StageDeploymentLogsErrorMessages, StageDeploymentLogsEvents } from "@microtica/ms-elasticsearch-sdk";
import { GetComponentsResponseInstances, GetOutputsForResourceResponseOutputs, GetStageDetailsResponseComponent, GetStageDetailsResponseResources, GetStageResponseCloudProviderEnum, GetStageResponseInfrastructureAsCodeToolEnum, GetStagesItem } from "@microtica/ms-engine-sdk";
import { GetKubernetesStatusResponseTypeEnum, KubernetesPod, ListKubernetesResponseKubernetesesTypeEnum } from "@microtica/ms-kube-sdk";
import { EnableMonitoringRequestClusterTypeEnum, GetAlarmConfigByIdResponseOneOf, GetAlarmConfigByIdResponseOneOf1 } from "@microtica/ms-monitoring-sdk";
import { InlineResponse200PaymentPlans, Project } from "@microtica/ms-project-sdk";
import { ReactNode } from "react";

export type WindowConfig = Window & {
    woopra: {
        identify: (data: object) => void;
        track: (event: string, data?: object) => void;
        push: (callback?: Function) => void;
    };
    gist: {
        identify: (obj: { email: string }) => void;
        track: (event: string, data?: object) => void;
        chat: (event: string, action: string) => void;
    };
    _cio: {
        identify: (obj: { id: string; email: string }) => void;
        track: (event: string, data?: object) => void;
    }
};

export type CloudProvider = "aws" | "google" | "azurerm" | string;


export type AwsRegionType = "UsEast2" | "UsEast1" | "UsWest1" | "UsWest2" | "ApEast1" |
    "ApSouth1" | "ApNortheast3" | "ApNortheast2" | "ApSoutheast1" |
    "ApSoutheast2" | "ApNortheast1" | "CaCentral1" | "CnNorth1" |
    "CnNorthwest1" | "EuCentral1" | "EuWest1" | "EuWest2" |
    "EuWest3" | "EuNorth1" | "MeSouth1" | "SaEast1" | "UsGovEast1" | "UsGovWest1";

export type GCPRegionType = "AsiaEast1" | "AsiaEast2" | "AsiaNortheast1" | "AsiaNortheast2" |
    "AsiaNortheast3" | "AsiaSouth1" | "AsiaSoutheast1" | "AsiaSoutheast2" | "AustraliaSoutheast1" |
    "EuropeCentral2" | "EuropeNorth1" | "EuropeWest1" | "EuropeWest2" | "EuropeWest3" | "EuropeWest4" |
    "EuropeWest6" | "NorthamericaNortheast1" | "SouthamericaEast1" | "UsCentral1" | "UsEast1" | "UsEast4" |
    "UsWest1" | "UsWest2" | "UsWest3" | "UsWest4";

export type GCPZoneType =
    "AsiaEast1A" | "AsiaEast1B" | "AsiaEast1C" | "AsiaEast2A" | "AsiaEast2B" | "AsiaEast2C" |
    "AsiaNortheast1A" | "AsiaNortheast1B" | "AsiaNortheast1C" | "AsiaNortheast2A" | "AsiaNortheast2B" | "AsiaNortheast2C" |
    "AsiaNortheast3A" | "AsiaNortheast3B" | "AsiaNortheast3C" | "AsiaSouth1A" | "AsiaSouth1B" |
    "AsiaSouth1C" | "AsiaSoutheast1A" | "AsiaSoutheast1B" | "AsiaSoutheast1C" | "AsiaSoutheast2A" |
    "AsiaSoutheast2B" | "AsiaSoutheast2C" | "AustraliaSoutheast1A" | "AustraliaSoutheast1B" | "AustraliaSoutheast1C" |
    "EuropeCentral2A" | "EuropeCentral2B" | "EuropeCentral2C" | "EuropeNorth1A" | "EuropeNorth1B" | "EuropeNorth1C" |
    "EuropeWest1B" | "EuropeWest1C" | "EuropeWest1D" | "EuropeWest2A" | "EuropeWest2B" | "EuropeWest2C" | "EuropeWest3A" |
    "EuropeWest3B" | "EuropeWest3C" | "EuropeWest4A" | "EuropeWest4B" | "EuropeWest4C" |
    "EuropeWest6A" | "EuropeWest6B" | "EuropeWest6C" | "NorthamericaNortheast1A" | "NorthamericaNortheast1B" |
    "NorthamericaNortheast1C" | "SouthamericaEast1A" | "SouthamericaEast1B" | "SouthamericaEast1C" |
    "UsCentral1A" | "UsCentral1B" | "UsCentral1C" | "UsCentral1F" | "UsEast1B" | "UsEast1C" |
    "UsEast1D" | "UsEast4A" | "UsEast4B" | "UsEast4C" | "UsWest1A" | "UsWest1B" | "UsWest1C" | "UsWest2A" | "UsWest2B" |
    "UsWest2C" | "UsWest3A" | "UsWest3B" | "UsWest3C" | "UsWest4A" | "UsWest4B" | "UsWest4C";

export interface Dictionary<T> {
    [key: string]: T;
}

export type GitProvider = "github" | "bitbucket" | "gitlab";

export interface EnvVars extends NodeJS.ProcessEnv {
    REACT_APP_BACKEND_ENDPOINT: string;
    REACT_APP_COGNITO_HOSTED_UI_URL: string;
    REACT_APP_COGNITO_OAUTH_CLIENT_ID: string;
    REACT_APP_GITHUB_CLIENT_ID: string;
    REACT_APP_GITLAB_CLIENT_ID: string;
    REACT_APP_GITLAB_REDIRECT_URL: string;
    REACT_APP_BITBUCKET_CLIENT_ID: string;
    REACT_APP_STRIPE_PUBLISHABLE_KEY: string;
    REACT_APP_GIST_TOKEN: string;
    REACT_APP_MIXPANEL_TOKEN: string;
    REACT_APP_HELPHERO_APP_ID: string;
    REACT_APP_OLD_PORTAL_URL: string;
}
export interface IProjectDetails {
    id: string;
    name: string;
    description: string;
}

export interface ProjectDetails extends Project {
    environments?: GetStagesItem[];
    cost?: {
        current?: number;
        estimated?: number;
        error?: string;
    }
}

export interface ComponentDeployment {
    id: string;
    name: string;
    version: string;
    commitType: string;
    commitMessage: string;
    repositoryUrl: string;
    branch: string;
    createdAt: string;
    createdBy: string;
    pipeline?: {
        id: string;
        buildId: string;
        public: boolean;
    };
    initiator?: {
        name: string;
        externalId: string;
        avatar: string;
    };
    status: {
        name: string;
        errors?: {
            logicalResourceId: string;
            resourceType: string;
            statusReason: string;
        };
        error?: string;
    };
    timestamp: string;
}

export interface IAppMonitoring {
    memoryUsage: string;
    initialMemory: number;
    memoryLimit: number;
    cpuUsage: string;
    initialCpu: number;
    cpuLimit: number;
    runningPods: number;
    failedPods: number;
    minReplicas: number;
    maxReplicas: number;
    pods: {
        name: string;
        phase: PodPhase;
        containers: {
            name: string;
            image: string;
            restartCount: number;
            ready: boolean;
        }[]
    }[];
}

export interface IAppDetails {
    name: string;
    description: string;
    domain: string;
    isDomainWorking: boolean;
    lastDeploymentIncludesIngressSetup: boolean;
    branch: string;
    createdAt: string;
    version: string;
    image: string;
    status: string;
    commitMessage: string;
    commitDate?: string;
    namespace: string;
    isPublic: boolean;
    clusterId: string;
    schema: ComponentSchema;
    configuration?: ComponentConfig[],
    deployments: ComponentDeployment[];
    repositoryUrl: string;
    pipelineId: string;
    monitoring: IAppMonitoring;
    clusterType: GetKubernetesStatusResponseTypeEnum;
    associatedNamespace?: string;
}

export interface IEnvDetails {
    id: string;
    name: string;
    description: string;
    status?: string;
    lastDeployed?: number;
    awsAccountId?: string;
    region?: string;
    gcpProjectId?: string;
    gcpRegion?: string;
    gcpZone?: string;
    infrastructureAsCodeTool?: GetStageResponseInfrastructureAsCodeToolEnum;
    cloudProvider?: GetStageResponseCloudProviderEnum;
    lastDeploymentId?: string;
    resources: GetStageDetailsResponseResources[];
    totalDeployedResources?: number;
    onChange?: () => void;
}

export interface IComponentDetails {
    name: string;
    version: string;
    status: string;
    component: ComponentDetails;
    configurations: ComponentConfig[];
    domain: {
        displayAssignDomainBtn: boolean;
        eligibleForDomainConfiguration: boolean;
        accessUrl?: string;
        CNAME?: string;
    }

}

export interface IResourceDetails {
    name: string;
    status: string;
    version: string;
    component: ComponentDetails;
    isPublic: boolean;
    configurations: ComponentConfig[];
    currentDeploymentId?: string;
    previousDeploymentId?: string;
    pipelineId: string;
    isDeployed: boolean;
    deployments: ComponentDeployment[];
    awsAccountId: string;
    awsRegion: string;
    domain: {
        displayAssignDomainBtn: boolean;
        eligibleForDomainConfiguration: boolean;
        accessUrl?: string;
        CNAME?: string;
    },
    environment: {
        status: string;
        resources: GetStageDetailsResponseResources[];
    }
    refreshRoot: () => Promise<void>;
    outputs?: GetOutputsForResourceResponseOutputs[];
    outputsErrorMessage?: string;
}

export interface ComponentDetails {
    id: string;
    name: string;
    description: string;
    schema: ComponentSchema;
    pipelineId: string;
    isPublic: boolean;
}

export interface TemplateResourceProperty {
    key: string;
    value?: string;
    reference?: string;
}

export interface Template {
    name: string;
    description: string;
    coverImage: string;
    logo: string;
    repo: string;
    docs: string;
    inputs?: {
        type: "object";
        properties: ComponentSchemaInputProperties;
        required?: string[];
    };
    infrastructure?: {
        resources: Dictionary<{
            type?: string;
            source?: "git";
            properties: TemplateResourceProperty[]
        }>
    }
    git?: boolean;
    service?: Dictionary<{
        source?: "git";
        image?: string;
        registry?: string;
        containerPort?: number;
        autoScaling?: {
            minReplicas: number;
            maxReplicas: number;
        }
        configuration?: ComponentConfig[];
        deployment: {
            options: [
                "shared",
                "personal",
                "managed"
            ]
        };
        pipeline?: object;
    }>
}

export interface ExecutingTemplate {
    buildId: string;
    templateExecutionId: string;
    env: EnvironmentDetails;
    title: string;
    repo: string;
    started?: number;
    finished?: number;
    status?: string;
    type?: TemplateType;
    appName: string;
    gitAccount: { accountId: string; fullName: string; autoDeploy: boolean; branch: string; repositoryUrl: string; }
    framework: string;
    clusterDetails: {
        creatingCluster: boolean,
        clusterAppName?: string;
    }
}

export interface DeploymentStage {
    name: string;
    current: boolean;
    status: string;
    result?: ReactNode;
    error?: string;
}

export interface DeploymentState {
    stages: DeploymentStage[];
    status: string;
    action?: ReactNode;
    current?: boolean;
    disabled?: boolean;
    loading?: boolean;
    error?: string;
}

export interface SharedDestination {
    type: "shared" | "managed";
}

export interface Destination {
    type: ListKubernetesResponseKubernetesesTypeEnum;
    accountId: string;
    region: string;
    clusterId?: string;
    clusterNamespace?: string;
}

export interface EnvironmentDetails {
    id: string;
    name: string;
    cloudProvider: string;
    infrastructureAsCodeTool: string;
    accountId?: string;
    region?: string;
    gcpProjectId?: string;
    gcpRegion?: string;
    gcpZone?: string;
    clusterId?: string;
    clusterName?: string;
    clusterNamespace?: string;
    clusterType?: string;
}

export type TemplateType = "cluster" | "environment" | "both";

export interface Environment {
    id: string;
    name: string;
    cloudProvider: string;
    infrastructureAsCodeTool: string;
    accountId?: string;
    region?: string;
    gcpProjectId?: string;
    gcpRegion?: string;
    gcpZone?: string;
}

export interface NavigationItem {
    id: string;
    name: string;
}

export interface NavigationState {
    project: NavigationItem;
    environment?: NavigationItem;
    app?: NavigationItem;
}

export type NavigationActionTypes = "project:set" | "environment:set" | "environment:unset" | "app:set" | "app:unset";

export interface PricingPlan {
    id: string;
    name: string;
    description: string;
    features: string[];
    priceId?: string;
    price?: string;
    limitations?: string;
    active?: boolean;
}
export interface PaymentPlansResponse extends InlineResponse200PaymentPlans {
    displayOrder: number;
}

export type ComponentSchemaInputProp = {
    label?: string;
    type: "string" | "number" | "array";
    dataType?: "string" | "number" | "array";
    enum?: string[];
    default?: string | number;
    description?: string;
    placeholder?: string;
    minimum?: number;
    maximum?: number;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    patternErrorMessage?: string;
    sensitive?: boolean;
    reference?: boolean;
    category?: "advanced" | "scaling" | "autoscaling" | "allocation" | "alarm" | "storage" | "variables" | "domain";
    subcategory?: "replicas" | "allocation";
}

export type ComponentSchemaInputProperties = Dictionary<ComponentSchemaInputProp>;
export interface ComponentSchema {
    properties: {
        inputs: {
            type: "object";
            properties: ComponentSchemaInputProperties;
            required?: string[];
            additionalProperties?: boolean;
        };
        outputs?: {
            type: "object";
            properties:
            Dictionary<{ type: "string"; description?: string; }>;
            required?: string[];
            additionalProperties?: boolean;
        }
    },
    required?: string[];
}

export interface ComponentConfig {
    key: string;
    value: string;
    sensitive?: boolean;
    reference?: boolean;
    required?: boolean;
}

export interface UserProfile {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface PipelineSpec {
    stages?: string[];
    steps: Dictionary<{
        type: string;
        target?: "environment" | "kubernetes";
        pipeline?: string;
        service: string;
        parameters?: {
            branch_filter?: string;
            env_id?: string;
            cluster?: string;
            namespace?: string;
            service?: string;
            tag?: string;
            partial?: {
                resource_version_overrides: Dictionary<string>
            }
        }
    }>;
}

export interface ComponentVersion {
    name: string;
    date?: number;
    commit: PipelineBuildDetailsMetadataCommit;
    repoUrl?: string;
}

export type AllClusters = Dictionary<{
    env: Environment;
    clusters: { id: string; name: string; type: ListKubernetesResponseKubernetesesTypeEnum }[]
}>;

export type FormState = "editing" | "disabled" | "saved";

export type DeploymentType = "infrastructure" | "app";
export interface EnvDeployment {
    envId: string;
    id: string;
    type: DeploymentType;
    active: boolean;
    createdAt: string;
    timestamp: string;
    createdBy: string;
    error?: string;
    status: {
        name: string;
        errors: StageDeploymentLogsErrorMessages[];
    },
    resources: EnvDeploymentResource[];
}

export interface EnvDeploymentResource {
    id: string;
    timestamp: string;
    name: string;
    deploymentId: string;
    kubernetesId?: string;
    kubernetesName?: string;
    kubernetesNamespace?: string;
    version: string;
    branch: string;
    commitType: string;
    commitMessage: string;
    repositoryUrl: string;
    pipeline: {
        id: string;
        buildId: string;
        public: boolean;
    },
    createdAt: string;
    createdBy: string;
    avatarUrl: string;
    status: {
        name: string;
        errors?: StageDeploymentLogsErrorMessages[];
    },
    configurations: MicroserviceConfigurationItem[];
    prev?: {
        version: string;
        branch: string;
        repositoryUrl?: string;
        equal: boolean;
        changes: MicroserviceConfigurationItem[][];
        commitType: string;
    }
}

export enum StatusColor {
    Active = "#52c41a",
    Processing = "#097bb5",
    Failed = "#ff4d4f",
    Default = "graytext"
}

export enum WidgetColor {
    Blue = "#097bb5",
    FadedBlue = "#64afd5"
}

export type PodPhase = "Pending" | "Running" | "Succeeded" | "Failed" | "Unknown";

export interface ResourceConfigurationAndStatus {
    [resourceName: string]: {
        status: string;
        configurations: ComponentConfig[]
    }
}

export interface ISavingScheduleAggregatedUtilization {
    totalUtilizedHours: number;
    estimatedSavingPercentage: number;
    dailyUtilizedHours: number[];
    startHourInTimeZone: number;
    stopHourInTimeZone: number;
    utilizedDays: string[];
}

export interface Component {
    id: string;
    name: string;
    apps?: App[];
    type: ComponentType;// "kubernetes" | "other" | string;
    isCluster: boolean;
    clusterId?: string;
    clusterType?: EnableMonitoringRequestClusterTypeEnum;
    isPublic: boolean;
    version?: string;
    component?: GetStageDetailsResponseComponent;
    status: string;
    configurations?: ComponentConfig[];
    deployment?: Deployment
}

export type ComponentType = "kubernetes" | "k8s" | "fargate" | "ecs" | "other" | string;

export interface App {
    name: string;
    namespace: string;
    version: string;
    clusterId: string;
    clusterType: EnableMonitoringRequestClusterTypeEnum;
    instance: {
        pods: KubernetesPod[];
        status: {
            failedPods: number;
            pendingPods: number;
            runningPods: number;
            succeededPods: number;
            totalPods: number;
        }
    };
    deployment?: Deployment;
    loadingDeployment?: boolean
}

export interface Deployment {
    repository: string;
    message: string;
    branch: string;
    version: string;
    user: string;
    sha?: string;
    commitName: string;
    commitType: string;
    status: string;
    configurations: MicroserviceConfigurationItem[];
    pipelineId?: string;
    kubeConfig?: string;
}

export interface Build {
    id: string;
    pipelineId?: string;
    type: "build" | "deployment";
    repository: string;
    startDate: number;
    stopDate?: number;
    commit: PipelineBuildDetailsMetadataCommit;
    status: string;
    statusCode?: string;
    current: boolean;
}

export interface PipelineItem {
    type: "build" | "buildonly" | "deploy" | "template" | string;
    stage: "build" | "deploy" | string;
    targetType?: "environment" | "app" | string;
    id: string;
    name: string;
    envId?: string;
    cloudProvider?: string;
    build?: PipelineBuildDetails;
    initiatorName: string;
    initiatorAvatar?: string;
    events?: StageDeploymentLogsEvents[],
    startDate: Date;
    stopDate?: Date;
    status: string;
    statusCode?: string;
    targets: PipelineItemTarget[];
    partial?: boolean;
}

export interface PipelineItemTarget {
    type: "component" | "app" | "template" | "none" | string;
    name: string;
    commit: StageDeploymentComponentMetadataCommit;
    repository: string;
    repositoryName: string;
    envId?: string;
    status: string;
    statusReason?: string;
    undeployed?: boolean;
}

export interface ChartMetricItem {
    group: string;
    timestamp: number;
    metric?: string;
    value: number;
}

export type ChartMetricUnit = "Percent" | "Count" | "Bytes" | "Seconds" | "Nanoseconds";

export interface ChartMetric {
    data: ChartMetricItem[];
    unit: ChartMetricUnit;
    metrics: string[];
    type: "k8s" | "ecs";
    alarms?: (GetAlarmConfigByIdResponseOneOf | GetAlarmConfigByIdResponseOneOf1)[];
}

export interface Log {
    timestamp: number;
    message: string;
}

export type IaC = "cloudformation" | "terraform" | string;

export interface TreeNode {
    name: string;
    link?: string;
    // isExpanded?: boolean;
    children?: TreeNode[];
    attributes?: Dictionary<string | number | boolean>;
}

export interface ProjectTemplate {
    id: string;
    name: string | ReactNode;
    description: string | ReactNode;
    pack: string;
    avatar: ReactNode;
    type: string;
    public: boolean;
    owner?: Project;
    cloudProvider?: string;
    iac?: string;
    instances: GetComponentsResponseInstances[];
    numberOfProjects: number;
    filterMetadata: string;
    link: string;
}
