import { CreateStageRequestCloudProviderEnum, CreateStageRequestInfrastructureAsCodeToolEnum } from "@microtica/ms-engine-sdk";
import { Button, Card, Col, Divider, Form, Input, notification, Row, Select, Space } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { getEnvironmentService } from "../backend";
import { trackEnvCreateFailed, trackEnvCreateSucceeded } from "../backend/tracking/environment";
import { currentProjectState } from "../recoil/project";
import CloudIcon from "./CloudIcon";

interface CreateEnvironmentProps {
    onSuccessfullyCreatedStage: Function;
}
const CreateEnvironment = ({
    onSuccessfullyCreatedStage
}: CreateEnvironmentProps) => {
    const currentProject = useRecoilValue(currentProjectState);
    const [creatingEnv, setCreatingEnv] = useState(false);
    const [requiredMark] = useState<RequiredMark>('optional');
    const [form] = Form.useForm();

    const handleCreateEnvironment = async (values: { name: string, description: string, cloudProvider: string; }) => {
        try {
            setCreatingEnv(true);

            const provider = values.cloudProvider === "aws" ?
                CreateStageRequestCloudProviderEnum.Aws :
                CreateStageRequestCloudProviderEnum.Google;
            const iac = values.cloudProvider === "aws" ?
                CreateStageRequestInfrastructureAsCodeToolEnum.Cloudformation :
                CreateStageRequestInfrastructureAsCodeToolEnum.Terraform;

            const { data: { id } } = await getEnvironmentService().createStage(
                currentProject!.id,
                {
                    name: values.name,
                    description: values.description,
                    cloudProvider: provider,
                    infrastructureAsCodeTool: iac
                });

            await onSuccessfullyCreatedStage();
            notification.success({ message: "New environment created" });
            trackEnvCreateSucceeded(
                id,
                values.name,
                provider,
                iac,
                false
            );
        } catch (error: any) {
            notification.error({
                message: "Cannot create new environment",
                description: error.response.data.message
            });
            trackEnvCreateFailed(
                values.name,
                CreateStageRequestCloudProviderEnum.Aws,
                CreateStageRequestInfrastructureAsCodeToolEnum.Cloudformation,
                false,
                error.response.data.message
            );
        } finally {
            setCreatingEnv(false);
        }
    }

    return (
        <Card>
            <Card.Meta
                title="Create New Environment"
                description="Environments separate your cloud infrastructure for development and production apps."
            />
            <Divider />
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                onFinish={handleCreateEnvironment}
                requiredMark={requiredMark}
            >
                <Form.Item
                    name="name"
                    label="Name"
                    required
                    rules={[{ required: true, message: 'Please input environment name!' }]}
                >
                    <Input placeholder="development" />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    required
                    rules={[{ required: true, message: 'Please input environment description!' }]}
                >
                    <Input placeholder="My development environment" />
                </Form.Item>
                <Form.Item
                    name="cloudProvider"
                    label="Cloud provider"
                    required
                    rules={[{ required: true, message: 'Please select cloud provider!' }]}
                    extra={
                        <small className="gray-text">
                            Once the environment is set up, you can't switch providers.
                        </small>
                    }
                >
                    <Select className="full-width">
                        <Select.Option value="aws" key="aws">
                            <Space>
                                <CloudIcon provider="aws" /> Amazon Web Services
                            </Space>
                        </Select.Option>
                        <Select.Option value="google" key="google">
                            <Space>
                                <CloudIcon provider="google" /> Google Cloud Platform
                            </Space>
                        </Select.Option>
                    </Select>
                </Form.Item>
                <br />
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: "right" }}
                    loading={creatingEnv}
                >
                    Create
                </Button>
            </Form>
        </Card>
    )
}

export default CreateEnvironment;