import { Button, Space } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { Link, Outlet } from "react-router-dom";
import PageContentWrapper from "../../components/PageContentWrapper";
import { PlusCircleOutlined } from "@ant-design/icons";
import ProjectExplanation from "../../components/explanations/ProjectExplanation";
import ExplanationButton from "../../components/explanations/ExplanationButton";

const Projects = () => {
    return (
        <PageContentWrapper
            size="large"
            header={
                <PageHeader
                    title={
                        <>
                            All Projects Overview
                            <ExplanationButton
                                content={<ProjectExplanation />}
                            />
                        </>
                    }
                    extra={
                        <Button>
                            <Link to="/pricing/new">
                                <Space>
                                    <PlusCircleOutlined />
                                    Create Project
                                </Space>
                            </Link>
                        </Button>
                    }
                />
            }
        >
            <Outlet />
        </PageContentWrapper >
    )
}


export default Projects;