import { Result, Empty, Button, Divider, Space, notification } from "antd";
import { getMonitoringService } from "../../backend";
import { useState } from "react";
import { EnableMonitoringRequestClusterTypeEnum } from "@microtica/ms-monitoring-sdk";

interface MonitoringNoMetricsPlaceholderProps {
    projectId: string;
    envId: string;
    clusterId: string;
    clusterType: EnableMonitoringRequestClusterTypeEnum;
    onOk?: () => void;
}

const MonitoringNoMetricsPlaceholder = ({
    projectId,
    envId,
    clusterId,
    clusterType,
    onOk
}: MonitoringNoMetricsPlaceholderProps) => {
    const [syncing, setSyncing] = useState(false);

    const handleSyncMetrics = async () => {
        try {
            setSyncing(true);
            await getMonitoringService().disableMonitoring(clusterId, envId, projectId);
            await getMonitoringService().enableMonitoring(
                clusterId,
                projectId,
                {
                    clusterType,
                    stageId: envId
                }
            );
            onOk?.();
            notification.success({
                message: "Metrics successfully synced"
            });
        } catch (error) {

        } finally {
            setSyncing(false);
        }
    }

    return <div className="flex-justify-center full-width">
        <Result
            title="No metrics available!"
            icon={Empty.PRESENTED_IMAGE_SIMPLE}
            subTitle={
                "Try getting the latest metrics or refer to the documentation for guidance on instrumentation and metric exporting."
            }
            extra={
                <Space>
                    <Button
                        loading={syncing}
                        onClick={handleSyncMetrics}
                    >
                        Sync metrics
                    </Button>

                    <Divider type="vertical" />

                    <a href="https://docs.microtica.com/monitoring/metrics" target="_blank">
                        Configure metrics
                    </a>
                </Space>
            }
        />
    </div>
}


export default MonitoringNoMetricsPlaceholder;