const ProjectExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Project
            </div>
            <div className="explanation-subtitle">
                Logical group for your environments, applications and infrastructure components
            </div>
            <br />
            <ul>
                <li>
                    Top level organizational entity in Microtica
                </li>
                <li>
                    There could be multiple users assigned to a project with certain access privileges
                </li>
                <li>
                    Microtica allows you to integrate multiple Git accounts, AWS accounts and Container registries into your <span style={{ fontStyle: "italic" }}>Project</span>
                </li>
                <li>
                    You are able to use all those for the deployment of your applications and infrastructure resources within different <span style={{ fontStyle: "italic" }}>Environments</span>
                </li>
            </ul>
        </>
    )
}

export default ProjectExplanation;