import { Button, Card, notification, Space } from "antd";
import Title from "antd/lib/typography/Title";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getUserManagementService } from "../../backend";
import { LoadingOutlined } from "@ant-design/icons";
import MicroticaLogo from "../../assets/logo-color.svg";
import { Link } from "react-router-dom";

export default function EmailVerified() {
    const { email, code } = useParams();
    const navigate = useNavigate();
    const [done, setDone] = useState(false);

    function handleLogin() {
        navigate("/auth/login");
    }

    useEffect(() => {
        async function loadData() {
            if (email && code) {
                try {
                    await getUserManagementService().activateUser({ username: email, code });
                    setDone(true);
                    notification.success({
                        message: "Email verified!"
                    });
                } catch (error) {
                    notification.error({
                        message: "Email not verified",
                        description: "We were not able to verify your email."
                    });
                }
            }
        }
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Link to="/">
                <img src={MicroticaLogo} style={{ height: "40px" }} alt="microtica" />
            </Link>
            <br />
            <Card style={{ width: "400px" }} bordered>
                <Card.Meta
                    title={<Title level={4}>Email Verification</Title>}
                />
                <Space direction="vertical" size="large">
                    {
                        done ?
                            <>
                                <div>
                                    Your email address has been verified!
                                </div>
                                <div>
                                    Now you can start bringing your code to life.
                                </div>
                            </>
                            :
                            <div>
                                <LoadingOutlined /> Verifiying your email
                            </div>
                    }
                </Space>
                <br />
                <br />
                <Button type="primary" onClick={handleLogin} disabled={!done} block>Log in</Button>
            </Card >
        </>
    )
}