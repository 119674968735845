import { Card, Divider } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { trackTemplateConfigureSave } from "../../backend/tracking/templates";
import { useCardClassName } from "../../contexts/Helpers/helpers";
import { currentTemplateStepState, templateState } from "../../recoil/template";
import { ComponentConfig, ComponentSchema, TemplateType } from "../../types";
import ComponentConfigForm from "../ComponentConfigForm";

interface ConfigurationTemplateModuleProps {
    templateName: string;
    templateType: TemplateType;
    schema: ComponentSchema;
    disabled?: boolean;
    initialConfigValues: ComponentConfig[];
    onChange?: (props: ComponentConfig[]) => void;
}

const ConfigurationTemplateModule = ({
    templateName,
    templateType,
    schema,
    disabled,
    initialConfigValues,
    onChange
}: ConfigurationTemplateModuleProps) => {
    const [currentTemplateState] = useRecoilState(templateState);
    const currentTemplateStep = useRecoilValue(currentTemplateStepState);

    const cardClassName = useCardClassName(
        disabled || currentTemplateState["config"] === "disabled",
        [disabled, currentTemplateState, currentTemplateStep]
    );

    const handleConfigurationChange = (props: ComponentConfig[]) => {
        trackTemplateConfigureSave(templateName);
        onChange?.(props);
    }

    return (
        <Card bordered className={cardClassName}>
            <Card.Meta
                title="Configure Template"
                description="Variables provide a way to customize this template to meet your requirements. You can change the configuration later."
            />

            <Divider />

            <ComponentConfigForm
                disabled={disabled}
                schema={schema}
                onChange={handleConfigurationChange}
                values={initialConfigValues}
                addBackButton={true}
                templateType={templateType}
            />
        </Card >
    );
}

export default ConfigurationTemplateModule;