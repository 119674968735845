import { Card, Row, Col, Select } from "antd";
import { AWS_REGIONS, GCP_REGIONS, GCP_ZONES } from "../enums/enums";
import AwsLogo from "../assets/aws-logo.svg";
import { useEffect, useState } from "react";
import MessageCardAwsAccountCostReport from "./cards/MessageCardAwsAccountCostReport";
import CloudIcon from "./CloudIcon";

interface ChooseGcpAccountCardProps {
    classNames?: string;
    selectedAccount?: {
        gcpProjectId?: string;
        gcpRegion?: string;
        gcpZone?: string;
    };
    gcpAccounts: { id: string, name: string }[];
    onSelect?: ({ gcpProjectId, gcpRegion, gcpZone }: { gcpProjectId: string; gcpRegion: string; gcpZone?: string; }) => void;
}
const ChooseGcpAccountCard = ({
    onSelect,
    selectedAccount,
    classNames,
    gcpAccounts
}: ChooseGcpAccountCardProps) => {
    // this component re-renders every time the selectedAccount is changed because it propagates back the change
    // and expects the same parametar as an input prop, so this is a solution to always have items in the list that is used
    // for the dropdown menu, because otherwise it will flicker
    const [gcpAccountsSelectItems, setGcpAccountSelectItems] = useState(
        selectedAccount?.gcpProjectId || selectedAccount?.gcpRegion || selectedAccount?.gcpZone ?
            [{
                id: selectedAccount?.gcpProjectId,
                name: selectedAccount?.gcpProjectId
            }] :
            []
    );

    useEffect(() => {
        setGcpAccountSelectItems(gcpAccounts);
    }, [gcpAccounts])

    const handleOnSelectAccount = (gcpProjectId: string) => {
        onSelect?.({
            gcpProjectId,
            gcpRegion: selectedAccount?.gcpRegion!,
            gcpZone: selectedAccount?.gcpZone!
        });
    }

    const handleOnSelectRegion = (gcpRegion: string) => {
        onSelect?.({
            gcpProjectId: selectedAccount?.gcpProjectId!,
            gcpRegion,
            gcpZone: undefined
        });
    }

    const handleOnSelectZone = (gcpZone: string) => {
        onSelect?.({
            gcpProjectId: selectedAccount?.gcpProjectId!,
            gcpRegion: selectedAccount?.gcpRegion!,
            gcpZone
        });
    }

    return (
        <Card type="inner" className={classNames} >
            <Card.Meta
                title="Link GCP account"
                description="The components and apps will be deployed in the selected account, region, and zone."
            />
            <br />
            {
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select GCP account"
                            value={selectedAccount?.gcpProjectId}
                            onSelect={handleOnSelectAccount}
                        >
                            {
                                gcpAccountsSelectItems.map(acc => (
                                    <Select.Option value={acc.id} key={acc.id}>
                                        <CloudIcon provider="google" /> {acc.name}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                    <Col span={24}>
                        <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select region"
                            filterOption={(input, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                            filterSort={(optionA, optionB) => {
                                return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }}
                            value={selectedAccount?.gcpRegion}
                            onSelect={handleOnSelectRegion}
                        >
                            {
                                GCP_REGIONS.map(region => (
                                    <Select.Option value={region.id} key={region.id}>
                                        {region.name}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                    <Col span={24}>
                        <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select zone"
                            filterOption={(input, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                            filterSort={(optionA, optionB) => {
                                return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }}
                            value={selectedAccount?.gcpZone}
                            onSelect={handleOnSelectZone}
                        >
                            {
                                GCP_ZONES
                                    .filter(z =>
                                        z.name.startsWith(GCP_REGIONS.find(r => r.id === selectedAccount?.gcpRegion)?.value!)
                                    )
                                    .map(zone => (
                                        <Select.Option value={zone.id} key={zone.id}>
                                            {zone.name}
                                        </Select.Option>
                                    ))
                            }
                        </Select>
                    </Col>
                </Row>
            }
        </Card >
    );
}

export default ChooseGcpAccountCard;