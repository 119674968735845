import { useCallback, useState } from "react"

import useEventListener from "./eventListener"

type Value<T> = T | null

function useReadLocalStorage<T>(key: string): Value<T> {
    // Get from local storage then
    // parse stored json or return initialValue
    const readValue = useCallback((): Value<T> => {
        // Prevent build error "window is undefined" but keep keep working
        if (typeof window === "undefined") {
            return null
        }

        try {
            const item = window.localStorage.getItem(key)
            return item ? (JSON.parse(item) as T) : null
        } catch (error) {
            console.warn(`Error reading localStorage key “${key}”:`, error)
            return null
        }
    }, [key])

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<Value<T>>(readValue)

    const handleStorageChange = useCallback(
        (event: StorageEvent | CustomEvent) => {
            if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
                return
            }
            setStoredValue(readValue())
        },
        [key, readValue],
    )

    // this only works for other documents, not the current one
    useEventListener("storage", handleStorageChange)

    return storedValue
}

export default useReadLocalStorage
// The useReadLocalStorage hook is useful when we want to observe the localStorage object for changes and do something when a particular localStorage item is updated

// example usage of the useReadLocalStorage hook:
    // const executingTemplates = useReadLocalStorage<ExecutingTemplate[]>("executingTemplates"); [ref1]
// explanation:
    // Everytime "executingTemplates" is updated in localStorage (e.g. localStorage.setItem("executingTemplates", JSON.stringify(executingTemplates)))
    // a "storage" event is fired and we listen to that event using an event listener (useEventListener("storage", handleStorageChange))
    // meaning that everytime a storage event is received, we call the handleStorageChange method which is responsible
    // for fetching the current "executingTemplates" value from localStorage and for storing that value in the storedValue state.
    // useReadLocalStorage returns the storedValue state meaning that the executingTemplates constant (see ref1) equals the storedValue.