import { getProjectService } from "../backend";
import { formatAmount } from "../contexts/Helpers/helpers";
import { PRICING_PLANS, PricingPlanNames } from "../enums/enums";
import { PaymentPlansResponse } from "../types";


export const getDetailedPaymentPlan = async (planId: PricingPlanNames) => {
    const { data: { paymentPlans } } = await getProjectService().getAllPaymentPlans();
    const stripePlan = (paymentPlans as PaymentPlansResponse[]).find(plan => plan.id === planId)
    const plan = PRICING_PLANS.find(plan => plan.id === planId)!;

    if (stripePlan) {
        const pricing = stripePlan.pricing.find(price => price.isDefault);
        return {
            ...plan,
            id: stripePlan.id,
            name: stripePlan.name,
            description: stripePlan.description,
            priceId: pricing!.id,
            price: formatAmount(pricing!.price, pricing!.currency, 0),
            limitations: JSON.stringify(stripePlan.limitations)
        };
    } else {
        return { ...plan, priceId: planId };
    }
}