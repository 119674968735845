import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Divider, notification, Row, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { getEnvironmentService, getKubeService } from "../../backend";
import { useDoubleConfigurationModal } from "../../contexts/Modal";
import { IEnvDetails } from "../../types";
import DeploymentStatusTag from "../../components/DeploymentStatusTag";
import { handleUndeployEnvironment } from "../../utils/environment/handle-undeploy-environment";
import { currentProjectState } from "../../recoil/project";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import { trackEnvRemoveFailed, trackEnvRemoveSucceeded } from "../../backend/tracking/environment";

export default function EnvironmentSettingsDangerZone() {
    const navigate = useNavigate();
    const currentProject = useRecoilValue(currentProjectState);

    const { projectId, envId } = useParams() as { projectId: string; envId: string; componentId: string; };
    const { env } = useOutletContext<{ env: IEnvDetails }>();
    const { open: openConfirmModal } = useDoubleConfigurationModal();

    const [canUndeploy, setCanUndeploy] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const [isProjectPaymentPlanFree, setIsProjectPaymentPlanFree] = useState(false);

    useEffect(() => {
        setIsProjectPaymentPlanFree(!!(currentProject && currentProject.paymentPlan?.id === "FREE"));
    }, [currentProject]);

    useEffect(() => {
        setCanUndeploy([
            "CREATE_COMPLETE",
            "UPDATE_COMPLETE",
            "ROLLBACK_COMPLETE",
            "UPDATE_ROLLBACK_COMPLETE",
            "DELETE_FAILED"
        ].includes(env.status || ""));

        setCanDelete(![
            "CREATE_COMPLETE",
            "UPDATE_COMPLETE",
            "ROLLBACK_COMPLETE",
            "UPDATE_ROLLBACK_COMPLETE",
            "CREATE_IN_PROGRESS",
            "DELETE_IN_PROGRESS",
            "ROLLBACK_IN_PROGRESS",
            "UPDATE_IN_PROGRESS",
            "UPDATE_COMPLETE_CLEANUP_IN_PROGRESS",
            "UPDATE_ROLLBACK_COMPLETE_CLEANUP_IN_PROGRESS",
            "UPDATE_ROLLBACK_IN_PROGRESS",
            "DELETE_FAILED"
        ].includes(env.status || ""))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [env.status]);

    async function handleDelete() {
        try {
            await getEnvironmentService().deleteStage(envId, projectId);
            trackEnvRemoveSucceeded(env.id, env.name);
            notification.success({ message: "The environment has been deleted succcessfully." });
            navigate(`/projects/${projectId}`);
        } catch (error: any) {
            trackEnvRemoveFailed(env.id, env.name, error.response.data.message);
            notification.error({ message: error?.response?.data?.message || "We encountered an error while trying to delete this environment." });
        }
    }

    return (
        <>
            <Space direction="vertical" className="full-width">
                {/* Undeploy */}
                <Card bordered>
                    <Card.Meta
                        title={
                            <Space>
                                <ExclamationCircleOutlined style={{ color: 'red' }} />
                                Undeploy Environment
                            </Space>
                        }
                        description="This action will remove all components from your cloud account. However, the environment will still remain available here."
                    />
                    <Divider />
                    {
                        !env.awsAccountId && !env.gcpProjectId ?
                            <Alert
                                type="info"
                                message={
                                    <>
                                        Undeploy is currently unavailable as the environment is not linked to any cloud account.
                                        Please <Link to={`/projects/${projectId}/environments/${envId}/settings/general`}>link a cloud account</Link> to enable undeploy functionality.
                                    </>
                                }
                            /> :
                            !canUndeploy ?
                                <Alert
                                    type="info"
                                    message={
                                        <>
                                            The undeploy feature is currently disabled as the environment is in the <DeploymentStatusTag status={env.status} /> state.
                                            Please wait until the environment transitions to an appropriate state to perform undeploy operations.
                                        </>
                                    }
                                /> : undefined
                    }
                    <br />
                    <Button
                        type="primary"
                        danger
                        disabled={!canUndeploy}
                        className="float-right"
                        onClick={() => openConfirmModal({
                            title: "Undeploy Environment?",
                            description: "The environment components in the environment will be permanently deleted from your cloud account.",
                            confirmation: envId,
                            alert: <b>This action cannot be undone.</b>,
                            cancelText: "Cancel",
                            okText: "Undeploy Environment",
                            onOk: () => handleUndeployEnvironment({
                                envId,
                                projectId,
                                navigate
                            })
                        })}
                    >
                        Undeploy
                    </Button>
                </Card>

                <br />

                {/* Delete */}
                <Card bordered>
                    <Card.Meta
                        title={
                            <Space>
                                <ExclamationCircleOutlined style={{ color: 'red' }} />
                                Delete Environment
                            </Space>
                        }
                        description="Completely remove the environment."
                    />
                    <Divider />
                    {
                        isProjectPaymentPlanFree &&
                        <Alert
                            type="info"
                            message="You are not able to delete your only environment when using a project on a Free plan."
                        />
                    }
                    {
                        !canDelete &&
                        <Alert
                            type="info"
                            message="To delete the environment, please undeploy it first."
                        />
                    }
                    <br />
                    <Button type="primary"
                        danger
                        className="float-right"
                        disabled={!canDelete || isProjectPaymentPlanFree}
                        onClick={() => openConfirmModal({
                            title: "Delete Environment?",
                            description: "The environment will be permanently deleted, including its components.",
                            alert: <b>This action cannot be undone.</b>,
                            confirmation: envId!,
                            okText: "Delete Environment",
                            cancelText: 'Cancel',
                            onOk: () => handleDelete()
                        })}
                    >
                        Delete
                    </Button>
                </Card>

            </Space>
        </>
    );
}