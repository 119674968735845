import { CreateStageRequestCloudProviderEnum, CreateStageRequestInfrastructureAsCodeToolEnum } from "@microtica/ms-engine-sdk";
import { Modal, Form, Row, Col, Input, notification, Select, Space, Radio, Card } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { getEnvironmentService } from "../../backend";
import { trackEnvCreateSucceeded, trackEnvCreateFailed } from "../../backend/tracking/environment";
import { currentProjectState } from "../../recoil/project";
import CloudIcon from "../../components/CloudIcon";
import IaCIcon from "../../components/IaCIcon";

interface CreateEnvironmentModalProps {
    visible?: boolean;
    onOk: () => void;
    onCancel: () => void;
}

const CreateEnvironmentModal = ({
    visible,
    onOk,
    onCancel
}: CreateEnvironmentModalProps) => {
    const [form] = Form.useForm();
    const currentProject = useRecoilValue(currentProjectState);
    const [requiredMark] = useState<RequiredMark>('optional');
    const [creatingEnv, setCreatingEnv] = useState(false);
    const [cloudProvider, setCloudProvider] = useState("aws");

    const handleCreateEnvironment = async (values: { name: string; description: string; cloudProvider: string; iac: string; }) => {
        const cloudProviderEnum = values.cloudProvider === "aws" ?
            CreateStageRequestCloudProviderEnum.Aws :
            CreateStageRequestCloudProviderEnum.Google;
        const iacProviderEnum = values.cloudProvider === "aws" && values.iac === "cloudformation" ?
            CreateStageRequestInfrastructureAsCodeToolEnum.Cloudformation :
            CreateStageRequestInfrastructureAsCodeToolEnum.Terraform;

        try {
            setCreatingEnv(true);


            const { data: { id } } = await getEnvironmentService().createStage(
                currentProject!.id,
                {
                    name: values.name,
                    description: values.description,
                    cloudProvider: cloudProviderEnum,
                    infrastructureAsCodeTool: iacProviderEnum
                });

            // from parent
            await onOk();

            notification.success({ message: "New environment created" });
            trackEnvCreateSucceeded(
                id,
                values.name,
                cloudProviderEnum,
                iacProviderEnum,
                false
            );
        } catch (error: any) {
            notification.error({
                message: "Cannot create new environment",
                description: error.response.data.message
            });
            trackEnvCreateFailed(
                values.name,
                cloudProviderEnum,
                iacProviderEnum,
                false,
                error.response.data.message
            );
        } finally {
            setCreatingEnv(false);
        }
    }

    return (
        <Modal
            title={
                <div>
                    <div>Create new environment</div>
                    <div className="gray-text" style={{ fontSize: "12px", fontWeight: 500 }}>
                        Environments separate your cloud infrastructure for development and production apps
                    </div>
                </div>
            }
            open={visible}
            okText="Create"
            onOk={() => form.submit()}
            confirmLoading={creatingEnv}
            onCancel={() => onCancel()}
        >
            <br />
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    cloudProvider: "aws",
                    iac: "cloudformation"
                }}
                onFinish={handleCreateEnvironment}
                requiredMark={requiredMark}
            >
                <Form.Item
                    name="name"
                    label="Name"
                    required
                    rules={[{ required: true, message: "Please input environment name" }]}
                >
                    <Input placeholder="development" />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    required
                    rules={[{ required: true, message: "Please input environment description" }]}
                >
                    <Input placeholder="My development environment" maxLength={200} />
                </Form.Item>
                <Form.Item
                    name="cloudProvider"
                    label="Cloud provider"
                    required
                    rules={[{ required: true, message: 'Please select cloud provider!' }]}
                    extra={
                        <small className="gray-text">
                            Once the environment is set up, you can't switch providers.
                        </small>
                    }
                >
                    <Select className="full-width" value={cloudProvider} onChange={setCloudProvider}>
                        <Select.Option value="aws" key="aws">
                            <Space>
                                <CloudIcon provider="aws" /> Amazon Web Services
                            </Space>
                        </Select.Option>
                        <Select.Option value="google" key="google">
                            <Space>
                                <CloudIcon provider="google" /> Google Cloud Platform
                            </Space>
                        </Select.Option>
                    </Select>
                </Form.Item>

                {
                    cloudProvider === "aws" &&
                    <Form.Item name="iac">
                        <Radio.Group className="full-width">
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Card size="small">
                                        <Radio value="cloudformation">
                                            <IaCIcon provider="cloudformation" /> CloudFormation
                                        </Radio>
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card size="small">
                                        <Radio value="terraform">
                                            <IaCIcon provider="terraform" /> Terraform
                                        </Radio>
                                    </Card>
                                </Col>
                            </Row>
                        </Radio.Group>
                    </Form.Item>
                }
            </Form>
        </Modal>
    )
}

export default CreateEnvironmentModal;
