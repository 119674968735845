import { Col, Menu, Row, Skeleton } from "antd";
import type { MenuProps } from "antd";
import {
    BarsOutlined,
    BranchesOutlined,
    DatabaseOutlined,
    ExclamationCircleOutlined,
    GlobalOutlined,
    MonitorOutlined,
    RiseOutlined,
    SettingOutlined,
    WarningOutlined
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router";
import { CONFIG_CATEGORIES } from "../../enums/enums";
import MenuItemNotification from "../../components/MenuItemNotification";
import { getEnvironmentService, getKubeService } from "../../backend";
import { GetResourceResponse } from "@microtica/ms-engine-sdk";
import { ComponentDetails, IComponentDetails, IEnvDetails } from "../../types";

export default function KubernetesSettingsRoot() {
    const navigate = useNavigate();
    const location = useLocation();
    const { env } = useOutletContext<{ env: IEnvDetails }>();
    const [menuItems, setMenuItems] = useState<MenuProps["items"]>();
    const [activeTab, setActiveTab] = useState("general");

    useEffect(() => {
        const items: MenuProps["items"] = [];

        items.push({
            key: "general",
            label: <MenuItemNotification
                icon={<BarsOutlined />}
                text="General"
            />
        });

        items.push({
            key: "monitoring",
            label: <MenuItemNotification
                icon={<MonitorOutlined />}
                text="Monitoring"
                badgeText="New"
            />
        });

        items.push({
            key: "danger-zone",
            label: <MenuItemNotification
                icon={<ExclamationCircleOutlined />}
                text="Danger Zone"
            />,
            danger: true
        });

        setMenuItems(items);
    }, [env]);

    useEffect(() => {
        if (menuItems) {
            if (!location.pathname.split("/")[9]) {
                navigate(`${location.pathname}/${menuItems[0]?.key}`, { replace: true });
                setActiveTab(menuItems[0]?.key as string);
            } else {
                setActiveTab(location.pathname.split("/")[9]);
            }
        }
    }, [menuItems]);

    return (
        <Row gutter={24}>
            <Col span={6}>
                <Menu
                    mode="inline"
                    className="ant-card-bordered"
                    selectedKeys={[activeTab]}
                    onSelect={(item) => {
                        navigate(item.key);
                        setActiveTab(item.key);
                    }}
                    items={menuItems}
                />
            </Col>
            <Col span={18}>
                {
                    <Outlet context={{ env }} />
                }
            </Col>
        </Row>
    )
}