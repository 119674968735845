import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router";
import { IAppDetails } from "../../types";
import PipelineBuilds from "../../components/PipelineBuilds";

const AppPipelineBuilds = () => {
    const { projectId, appName, envId, clusterId, namespace } = useParams();
    const { appDetails } = useOutletContext<{ appDetails: IAppDetails }>();
    const [pipelineId, setPipelineId] = useState<string>();

    useEffect(() => {
        setPipelineId(appDetails?.pipelineId)
    }, [appDetails])

    return (
        <PipelineBuilds
            buildPath={`/projects/${projectId}/environments/${envId}/apps/${clusterId}/${namespace}/${appName}`}
            pipelineId={pipelineId!}
            projectId={projectId!}
        />
    )
}

export default AppPipelineBuilds;