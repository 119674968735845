import { Tag, Tooltip } from "antd";
import { ExclamationCircleOutlined, CheckCircleFilled, ReloadOutlined, WarningFilled, ClockCircleFilled } from '@ant-design/icons';
import { PIPELINE_BUILD_STATUS_CODES } from "../enums/enums";

const renderStatusColor = (status: string, statusCode?: string) => {
    switch (status) {
        case "SUCCEEDED":
            return {
                icon: statusCode ? <ExclamationCircleOutlined /> : <CheckCircleFilled />,
                color: statusCode ? "warning" : "success",
                status: "Succeeded"
            }
        case "FAILED":
            return {
                icon: <WarningFilled />,
                color: "error",
                status: "Failed"
            };
        case "RUNNING":
            return {
                icon: <ReloadOutlined spin />,
                color: "processing",
                status: "Building"
            };
        case "QUEUED":
            return {
                icon: <ClockCircleFilled />,
                status: "Queued",
            };
        case "ABORTED":
            return {
                icon: <WarningFilled />,
                color: "warning",
                status: "Aborted",
            };
        default:
            return {
                status: "Unknown status"
            };
    }
}

export default function BuildStatusTag(props: { status: string, statusCode?: string; size?: "small" | "large" }) {
    const className = props.size === "large" ? "no-margin ant-tag-lg" : "no-margin";
    const { color, icon, status } = renderStatusColor(props.status, props.statusCode);

    return (
        <Tag className={`no-margin ${className}`} color={color}>
            <Tooltip title={props.statusCode && PIPELINE_BUILD_STATUS_CODES[props.statusCode]}>
                {icon} {status}
            </Tooltip>
        </Tag>
    );
};