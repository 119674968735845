interface IWidgetSkeleton {
    width: number,
    height: number
}

const WidgetSkeleton = ({
    width,
    height
}: IWidgetSkeleton) => {
    if (width > 800) {
        throw Error("The provided width is too large...");
    }

    return (
        <div className="widget-skeleton">
            <svg aria-labelledby="r7vg48-aria" role="img" viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
                <title id="r7vg48-aria">Loading...</title>
                <rect role="presentation" x="0" y="0" width="100%" height="100%" clip-path="url(#r7vg48-diff)" style={{ fill: "url(#r7vg48-animated-diff)" }}></rect>
                <defs role="presentation">
                    <clipPath id="r7vg48-diff">
                        <rect x="10" y="75" rx="0" ry="0" width="35" height="100"></rect>
                        <rect x="50" y="125" rx="0" ry="0" width="35" height="50"></rect>
                        <rect x="90" y="105" rx="0" ry="0" width="35" height="70"></rect>
                        <rect x="130" y="35" rx="0" ry="0" width="35" height="140"></rect>
                        <rect x="170" y="55" rx="0" ry="0" width="35" height="120"></rect>
                        <rect x="210" y="15" rx="0" ry="0" width="35" height="160"></rect>
                        <rect x="250" y="135" rx="0" ry="0" width="35" height="40"></rect>
                        <rect x="290" y="85" rx="0" ry="0" width="35" height="90"></rect>
                        <rect x="330" y="15" rx="0" ry="0" width="35" height="160"></rect>
                        <rect x="370" y="125" rx="0" ry="0" width="35" height="50"></rect>
                        <rect x="410" y="105" rx="0" ry="0" width="35" height="70"></rect>
                        <rect x="450" y="35" rx="0" ry="0" width="35" height="140"></rect>
                        <rect x="490" y="55" rx="0" ry="0" width="35" height="120"></rect>
                        <rect x="530" y="15" rx="0" ry="0" width="35" height="160"></rect>
                        <rect x="570" y="135" rx="0" ry="0" width="35" height="40"></rect>
                        <rect x="610" y="85" rx="0" ry="0" width="35" height="90"></rect>
                        <rect x="650" y="35" rx="0" ry="0" width="35" height="140"></rect>
                        <rect x="690" y="55" rx="0" ry="0" width="35" height="120"></rect>
                        <rect x="730" y="15" rx="0" ry="0" width="35" height="160"></rect>
                    </clipPath>
                    <linearGradient id="r7vg48-animated-diff">
                        <stop offset="25%" stopColor="#bebebe33" stopOpacity="1">
                            <animate attributeName="offset" values="-2; -2; 1" keyTimes="0; 0.25; 1" dur="1.4s" repeatCount="indefinite"></animate>
                        </stop>
                        <stop offset="37%" stopColor="#8181813d" stopOpacity="1">
                            <animate attributeName="offset" values="-1; -1; 2" keyTimes="0; 0.25; 1" dur="1.4s" repeatCount="indefinite"></animate>
                        </stop>
                        <stop offset="63%" stopColor="#bebebe33" stopOpacity="1">
                            <animate attributeName="offset" values="0; 0; 3" keyTimes="0; 0.25; 1" dur="1.4s" repeatCount="indefinite"></animate>
                        </stop>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );

}

export default WidgetSkeleton;
