const PaymentMethodsExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Payment methods
            </div>
            <br />

            <p>
                The payment methods you enter are not stored anywhere by Microtica and are used directly by Stripe.
            </p>
            <p>
                You are able to enter multiple cards, but the <span style={{ fontStyle: "italic" }}>Default</span> one will be charged for the subscription.
            </p>
            <br />
            <p>
                Learn more about <a href="https://stripe.com/docs/security" target="_blank" rel="noreferrer">security at Stripe.</a>
            </p>
        </>
    )
}

export default PaymentMethodsExplanation;