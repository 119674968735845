import { ReactNode, useRef } from "react";
import Heading from "./Heading";
import { ViewportList } from "react-viewport-list";
import moment from "moment";
import Ansi from "ansi-to-react";
import { Alert } from "antd";

interface LogsProps {
    title?: ReactNode;
    extra?: ReactNode;
    logs?: string[];
    height?: string;
    limit?: number;
    onSelect?: (timestamp: number) => void;
}

// @ts-ignore
const timestampRegex = /^(?<isoDate>\b[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.\d+Z\b)|^(?<isoDate1>\b[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\+[0-9]{2}:[0-9]{2}\b)|^(?<isoDate2>\b[0-9]{4}\/[0-9]{2}\/[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]+\b)|^\[(?<timestamp>\d+)\]|^\[(?<date>.*)\]/;

const Logs = ({
    title,
    extra,
    logs = [],
    height,
    limit,
    onSelect
}: LogsProps) => {
    const ref = useRef<HTMLDivElement | null>(null);

    return (
        <div className="content-logs" style={{ height }}>
            {
                title &&
                <div style={{ marginTop: -30 }}>
                    <Heading
                        level={5}
                        title={
                            <div className="flex-justify-space-between flex-align-center">
                                <div style={{ color: "white" }}>
                                    {title}
                                </div>
                                {extra}
                            </div>
                        }
                    />
                </div>
            }
            <code ref={ref} style={{ lineHeight: "18px" }}>
                {
                    <ViewportList
                        viewportRef={ref}
                        items={limit ? logs.slice(0, 1000) : logs}
                    >
                        {
                            (item, index) => {
                                const logMatch = timestampRegex.exec(item);
                                const logTime = logMatch?.[0] || "";
                                const date = logMatch?.groups?.timestamp ?
                                    parseInt(logMatch?.groups.timestamp) :
                                    logMatch?.groups?.isoDate || logMatch?.groups?.isoDate1 || logMatch?.groups?.isoDate2 || logMatch?.groups?.date || "";

                                const logLine = item.replace(logTime, "");

                                // color formatting if the log has a valid timestamp
                                return moment(date).isValid() ?
                                    <div className="log-line" key={index}
                                        onClick={async () => {
                                            onSelect?.(moment.utc(date).valueOf());
                                            // await navigator.clipboard.writeText(item);
                                            // notification.info({
                                            //     message: "Copied to clipboard!"
                                            // });
                                        }}
                                    >
                                        <span className="log-time">
                                            {/* display the timestamp */}
                                            {
                                                moment
                                                    .utc(date)
                                                    .local()
                                                    .format("DD MMM, HH:mm:ss.SSS")
                                            }
                                        </span>&nbsp;
                                        <span className="log-text">
                                            <Ansi>
                                                {/* display the rest of the log */}
                                                {logLine}
                                            </Ansi>
                                        </span>
                                    </div>
                                    :
                                    <div
                                        key={index}
                                        className="log-line"
                                    // onClick={async () => {
                                    //     onSelect?.(moment.utc(date).valueOf());
                                    //     // await navigator.clipboard.writeText(item);
                                    //     // notification.info({
                                    //     //     message: "Copied to clipboard!"
                                    //     // });
                                    // }}
                                    >
                                        <Ansi>
                                            {logLine}
                                        </Ansi>
                                    </div>
                            }}
                    </ViewportList>
                }
                {
                    limit && logs.length > limit &&
                    <>
                        <br />
                        <Alert
                            type="info"
                            showIcon
                            message="For optimal performance, we display only the last 2000 logs. We're working on improved log capabilities. Stay tuned for updates!"
                        />
                    </>
                }
            </code>
        </div>
    )
}

export default Logs;