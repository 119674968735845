import { Project } from "@microtica/ms-project-sdk";
import { LoginUserBasicResponse } from "@microtica/ms-usermanagement-sdk";
import { useNavigate } from "react-router";
import { getProjectService, getUserManagementService } from "../../backend";
import { UserProfile } from "../../types";
import { env } from "../../env";
import { useSetRecoilState } from "recoil";
import { projectsState, currentProjectState } from "../../recoil/project";
import { useCurrentProject } from "../Project";
import { notification } from "antd";
import { trackUserLogin, trackUserRegister } from "../../backend/tracking/authentication";
import { getDefaultPlan, getUser, resetDefaultPlan, setProjectLocalStorage } from "../../utils/local-storage";
import * as jwt from "react-jwt";
import { handleSetMixpanelUserGroups } from "../../backend/tracking";
import { LOGIN_TYPE } from "../../enums/enums";

export function useAuth(props?: { redirectUrl?: string }) {
    const navigate = useNavigate();
    const token = localStorage.getItem("idToken");
    const setProjects = useSetRecoilState(projectsState)
    const setCurrentProject = useSetRecoilState(currentProjectState);
    const { updateCurrentProject } = useCurrentProject();

    async function initiateOAuthFlow(url: string): Promise<any> {
        const width = 500;
        const height = 600;
        const left = window.screenX + (window.outerWidth - width) / 2;
        const top = window.screenY + (window.outerHeight - height) / 2.5;

        const popup = window.open(url, "OAuth", `width=${width},height=${height},left=${left},top=${top},location=yes`);

        return new Promise<{ code: string; type?: string; }>((resolve, reject) => {
            const timer = setInterval(async () => {
                if (!popup || popup.closed !== false) {
                    popup?.close();
                    timer && clearInterval(timer);
                    return;
                }

                const currentUrl = popup.location.href;
                if (!currentUrl) {
                    return;
                }

                const searchParams = new URL(currentUrl).searchParams;
                const code = searchParams.get("code");
                const type = searchParams.get("type") || "";
                const errorDescription = searchParams.get("error_description");

                if (errorDescription && /already.found.an.entry.for.username/gi.test(errorDescription)) {
                    // external provider sign-up was successful, bug cognito still returns an error format
                    popup.close();
                    timer && clearInterval(timer);
                    reject({ code: "EXTERNAL_PROVIDER_SIGN_UP_SUCCESS" });
                } else if (errorDescription && /time.*out/gi.test(errorDescription)) {
                    popup.close();
                    timer && clearInterval(timer);
                    reject({ code: "REQUEST_TIMED_OUT" });
                }

                if (code) {
                    popup.close();
                    timer && clearInterval(timer);
                    resolve({ code, type });
                }
            }, 10);
        });
    }

    async function loginWithExternalProvider(externalProviderType: "google" | "github", authenticationType: "signup" | "login") {
        const mappedProviderType = externalProviderType === "google" ? "Google" : "GitHub";
        const { REACT_APP_COGNITO_HOSTED_UI_URL: COGNITO_HOSTED_UI_URL, REACT_APP_COGNITO_OAUTH_CLIENT_ID: COGNITO_OAUTH_CLIENT_ID } = env;

        let code: string;
        try {
            ({ code } = await initiateOAuthFlow(
                `${COGNITO_HOSTED_UI_URL}/oauth2/authorize?identity_provider=${mappedProviderType}&response_type=CODE&client_id=${COGNITO_OAUTH_CLIENT_ID}`
            ));
        } catch (err: any) {
            if (err.code === "EXTERNAL_PROVIDER_SIGN_UP_SUCCESS") {
                notification.success({
                    message: `${mappedProviderType} sign-up success`,
                    description: "Proceed to log in now"
                });
            } else if (err.code === "REQUEST_TIMED_OUT") {
                notification.error({
                    message: `Trouble reaching ${mappedProviderType}`,
                    description: `We had trouble reaching ${mappedProviderType}. Please try again later.`
                });
            }
            navigate("/auth/login");
            return;
        }

        const { data } = (await getUserManagementService().loginUserWithExternalProvider(externalProviderType, {
            code: code!
        })) as { data: LoginUserBasicResponse };
        const idTokenData = jwt.decodeToken(data.idToken) as { identities: { userId: string }[] };

        if (authenticationType === "signup") {
            // this needs to be called only on signup
            trackUserRegister({
                gitAccountId: idTokenData.identities[0].userId,
                userId: data.profile.id,
                username: data.profile.email,
                type: externalProviderType
            });
            // Mixpanel recommends calling identify() at least 1sec after alias event
            await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        await processAuthData(data, externalProviderType);

        return data;
    }

    async function connectGitHub() {
        const { REACT_APP_GITHUB_CLIENT_ID: GITHUB_CLIENT_ID } = env;
        return initiateOAuthFlow(
            `https://github.com/login/oauth/authorize?client_id=${GITHUB_CLIENT_ID}&scope=repo`
        ) as Promise<{ code: string; type: "github" }>;
    }

    async function connectGitLab() {
        const { REACT_APP_GITLAB_CLIENT_ID: GITLAB_CLIENT_ID, REACT_APP_GITLAB_REDIRECT_URL: GITLAB_REDIRECT_URL } = env;
        return initiateOAuthFlow(
            `https://gitlab.com/oauth/authorize?client_id=${GITLAB_CLIENT_ID}&redirect_uri=${GITLAB_REDIRECT_URL}&response_type=code&scope=read_repository+api+read_user`
        ) as Promise<{ code: string; type: "gitlab" }>;
    }

    async function connectBitbucket() {
        const { REACT_APP_BITBUCKET_CLIENT_ID: BITBUCKET_CLIENT_ID } = env;
        return initiateOAuthFlow(
            `https://bitbucket.org/site/oauth2/authorize?client_id=${BITBUCKET_CLIENT_ID}&response_type=code`
        ) as Promise<{ code: string; type: "bitbucket" }>;
    }

    async function processAuthData(data: LoginUserBasicResponse, type: LOGIN_TYPE) {
        const { idToken, profile, expiresIn, refreshToken } = data;
        const expirationDate = new Date(
            new Date().getTime() + expiresIn * 1000
        );
        localStorage.setItem("idToken", idToken);
        localStorage.setItem("profile", JSON.stringify(profile));
        localStorage.setItem("expirationDate", expirationDate.toString());
        localStorage.setItem("refreshToken", refreshToken);

        trackUserLogin({
            username: profile.email,
            type
        });

        await handleProjectSelection(profile.email);
    }

    async function handleProjectSelection(email: string) {
        // If user has no projects, create a default one
        const { data: { projects } } = await getProjectService().listProjects();

        const storedProject = JSON.parse(localStorage.getItem("project") || "{}") as Project;
        const projectExists = projects.find(p => p.id === storedProject.id);
        let activeProject: Project;

        // Make sure that a project is always selected
        if (projectExists) {
            const { data: project } = await getProjectService().getProject(storedProject.id);
            activeProject = project;
            setProjectLocalStorage(activeProject);
        } else {
            if (projects.length > 0) {
                const { data: project } = await getProjectService().getProject(projects[0].id);
                activeProject = project!;
                setProjectLocalStorage(activeProject);
            }
        }

        setCurrentProject(activeProject!);

        // Make sure that a project is always selected
        if (projects.length === 0) {
            // navigate first so that the Welcome screen is displayed, we are displaying a Skeleton while the code after this finishes and the data is loaded
            navigate(props?.redirectUrl || "/welcome");

            const [username] = email.split("@");
            const projectName = `${username}'s project`;
            const projectDescription = `${username}'s default project`;
            const { data: project } = await getProjectService().createProject({
                name: projectName,
                description: projectDescription,
                paymentPlanId: getDefaultPlan()
            });
            await updateCurrentProject(project.id);

            handleSetMixpanelUserGroups([project.id]);
            setProjects([project]);
            resetDefaultPlan();
        } if (projects.length === 1) {
            await updateCurrentProject(activeProject!.id);

            setProjects(projects);

            navigate(props?.redirectUrl || `/projects/${activeProject!.id}`);
        } else {
            setProjects(projects);

            navigate(props?.redirectUrl || "/projects");
        }
    }

    if (token) {
        const profile = getUser() as UserProfile;

        return {
            isLoggedIn: true,
            profile,
            loginWithExternalProvider,
            processAuthData,
            connectGitHub,
            connectGitLab,
            connectBitbucket
        }
    } else {
        return {
            isLoggedIn: false,
            loginWithExternalProvider,
            processAuthData,
            connectGitHub,
            connectGitLab,
            connectBitbucket
        }
    }
}