
import { AlertOutlined, CheckCircleOutlined, CrownOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { Button, Card, Col, Modal, Row, Space, Tooltip, notification } from "antd";
import Title from "antd/lib/typography/Title";
import CostDashboardPotentialSavingsExplanation from "../explanations/CostDashboardPotentialSavingsExplanation";
import ExplanationButton from "../explanations/ExplanationButton";
import CostExplorerBlurScreenshot from "../../assets/cost-explorer.png";
import SavingScheduleBlurScreenshot from "../../assets/saving-schedule.png";
import { convertToCurrencyWithoutFractionDigits } from "../../utils/conversion";
import { ESTIMATED_SAVINGS_COEFICIENT } from "../../utils/cron";
import { Dictionary, PricingPlan } from "../../types";
import { useRecoilValue } from "recoil";
import { ProjectRecoilState, currentProjectState } from "../../recoil/project";
import { getProjectService } from "../../backend";
import { useCurrentProject } from "../../contexts/Project";
import { Project } from "@microtica/ms-project-sdk";
import { useEffect, useState } from "react";
import { getDetailedPaymentPlan } from "../../utils/payment-plans";
import { PricingPlanNames } from "../../enums/enums";
import MessageCard from "../cards/MessageCard";

interface IDisabledFeatureInfoCardProps {
    upgradePaymentPlanTo: PricingPlanNames;
    expectedSavings?: number;
}

interface ISelectPlan {
    plan: PricingPlan;
    advancedPlan: PricingPlan;
    currentProject: ProjectRecoilState;
    updateCurrentProject: (projectId: string) => Promise<Project>
}

const upgradePlanTitleStyle: any = { color: "green", fontWeight: "bold", marginBottom: 0 }

const UpgradeToStarterPlanTitle = (
    <Space direction="vertical" size={0}>
        <Title level={3} style={upgradePlanTitleStyle}>
            Track and analyze your cloud
            <br />
            expenses
        </Title>
    </Space>
);

const UpgradeToAdvancedPlanTitle = ({ expectedSavings }: { expectedSavings?: number }) => (
    <Space size={0}>
        <Title level={3} style={upgradePlanTitleStyle}>
            <Space>
                <div>You can save up to {expectedSavings ? convertToCurrencyWithoutFractionDigits(expectedSavings || 0) : "60%"}</div>
                <ExplanationButton
                    content={<CostDashboardPotentialSavingsExplanation />}
                    blockElement={true}
                />
            </Space>
            <br />
            <div>each month</div>
        </Title>
    </Space>
);

const StarterPlanCardContent = (
    <Space size={16} direction="vertical">
        {UpgradeToStarterPlanTitle}
        <div>
            <ul className="no-margin" style={{ paddingLeft: 0, listStyleType: "none" }}>
                <Space direction="vertical">
                    <li><CheckCircleOutlined style={{ color: "green" }} /> Understand your spending patterns</li>
                    <li><CheckCircleOutlined style={{ color: "green" }} /> Identify cost drivers</li>
                    <li><CheckCircleOutlined style={{ color: "green" }} /> Forecast your future costs</li>
                </Space>
            </ul>
        </div>
    </Space>
);

const AdvancedPlanCardContent = ({ expectedSavings }: { expectedSavings?: number }) => (
    <Space size={8} direction="vertical">
        <UpgradeToAdvancedPlanTitle expectedSavings={expectedSavings} />
        <div className="no-margin">
            by reducing cloud costs for non-production environments
        </div>
    </Space>
);

const handleSelectPlan = async ({
    plan,
    currentProject,
    updateCurrentProject
}: Omit<ISelectPlan, "advancedPlan">) => {
    try {
        await getProjectService().changeProjectSubscription(
            currentProject!.id,
            {
                paymentPlanId: plan.id,
                priceId: plan.priceId!
            }
        );
        await updateCurrentProject(currentProject!.id);
    } catch (error: any) {
        notification.error({
            message: `Error activating the ${plan.name} plan`,
            description: <>
                {error.response.data.message}&nbsp;
                <a href={`/projects/${currentProject!.id}/settings/billing`}>Go to Billing</a> to check your settings.
            </>
        })
    }
}

const showSelectPlanConfirm = async ({
    plan,
    advancedPlan,
    currentProject,
    updateCurrentProject
}: ISelectPlan) => {
    const activatePlanMainContent = [
        <div>
            You will be upgraded to our {plan.name} plan ({plan.price}/month).
        </div>,
        currentProject?.freeTrials && !(currentProject.freeTrials as Dictionary<boolean>)[plan.id] ?
            <div>
                <b>7 day free trial</b> will be applied.
            </div> :
            <div>
                Your credit card will be <b>charged immediately</b> because you've already used your free trial period.
            </div>
    ]
    if (plan.id === PricingPlanNames.STARTER) {
        const modal = Modal.confirm({
            title: `Activate ${plan.name} plan`,
            content:
                <Space direction='vertical'>
                    {activatePlanMainContent}
                    <MessageCard
                        type="warning"
                        text={
                            <Space direction="vertical">
                                <h4>
                                    <b>
                                        Start saving up to {ESTIMATED_SAVINGS_COEFICIENT * 100}% on your cloud expenses&nbsp;
                                        <Tooltip title={"Estimated savings if you limit your EC2 and RDS resources to run exclusively during weekday business hours"}>
                                            <InfoCircleTwoTone style={{ fontSize: 14, color: "black", cursor: "pointer" }} />
                                        </Tooltip>
                                    </b>
                                </h4>
                                <div>
                                    Take control of your cloud costs with actionable recommendations and active saving schedules.</div>
                                <div>
                                    Saving schedules can help you reduce cloud costs on <b>non-production environments</b>.
                                </div>
                                {
                                    currentProject?.freeTrials && !(currentProject.freeTrials as Dictionary<boolean>)[advancedPlan.id] ?
                                        <div> If you choose to proceed, a <b>7 day free trial</b> will be applied.</div> :
                                        <div> If you choose to proceed, your credit card will be <b>charged immediately</b> because you've already used your free trial period.</div>
                                }
                                <Button
                                    style={{ marginTop: "10px" }}
                                    onClick={() => {
                                        handleSelectPlan({
                                            plan: advancedPlan,
                                            currentProject,
                                            updateCurrentProject
                                        });
                                        modal.destroy();
                                    }}
                                >
                                    Activate Advanced plan
                                </Button>
                            </Space>
                        }
                        showIcon={true}
                        icon={<AlertOutlined style={{ color: "var(--warning-color)", fontSize: 28 }} />}
                    />
                </Space>,
            okText: `Activate ${plan.name} plan`,
            centered: true,
            width: 550,
            async onOk() {
                return handleSelectPlan({
                    plan,
                    currentProject,
                    updateCurrentProject
                });
            }
        });
    }
    else {
        Modal.confirm({
            title: `Activate ${plan.name} plan`,
            content:
                <Space direction='vertical'>
                    {activatePlanMainContent}
                </Space>,
            okText: `Activate ${plan.name} plan`,
            centered: true,
            async onOk() {
                return handleSelectPlan({
                    plan,
                    currentProject,
                    updateCurrentProject
                });
            }
        });
    }
}

const DisabledFeatureInfoCard = ({
    upgradePaymentPlanTo,
    expectedSavings
}: IDisabledFeatureInfoCardProps) => {
    const currentProject = useRecoilValue(currentProjectState);
    const [nextPaymentPlan, setNextPaymentPlan] = useState<any>();
    const [advancedPlan, setAdvancedPlan] = useState<any>();
    const { updateCurrentProject } = useCurrentProject();

    useEffect(() => {
        async function loadData() {
            const plan = await getDetailedPaymentPlan(upgradePaymentPlanTo);
            if (upgradePaymentPlanTo === PricingPlanNames.ADVANCED) {
                setAdvancedPlan(plan);
            } else {
                const advancedPlan = await getDetailedPaymentPlan(PricingPlanNames.ADVANCED);
                setAdvancedPlan(advancedPlan);
            }
            setNextPaymentPlan(plan);
        }
        loadData();
    }, [upgradePaymentPlanTo]);

    const screenshotImage = upgradePaymentPlanTo === PricingPlanNames.STARTER ?
        <img src={CostExplorerBlurScreenshot} alt="cost-explorer" className="cost-dashboard-screenshot" /> :
        <img src={SavingScheduleBlurScreenshot} alt="saving-schedule" className="cost-dashboard-screenshot" />

    const messageCardContent = upgradePaymentPlanTo === PricingPlanNames.STARTER ?
        StarterPlanCardContent :
        <AdvancedPlanCardContent expectedSavings={expectedSavings} />

    const CtaText = upgradePaymentPlanTo === PricingPlanNames.ADVANCED ?
        "Create Saving Schedule" :
        "Enable Cost Explorer";

    return (
        <Card styles={{ body: { padding: 0 } }}>
            <Row>
                <Col offset={2} span={9} className="flex-align-center">
                    <Space direction="vertical" size={24}>
                        {messageCardContent}
                        <Button
                            type="primary"
                            onClick={() => showSelectPlanConfirm({
                                plan: nextPaymentPlan,
                                advancedPlan,
                                currentProject,
                                updateCurrentProject
                            })}
                        >
                            <Space>
                                <CrownOutlined />
                                {CtaText}
                            </Space>
                        </Button>
                    </Space>
                </Col>
                <Col offset={1} span={12}>
                    {screenshotImage}
                </Col>
            </Row>
        </Card>
    );
}

export default DisabledFeatureInfoCard;