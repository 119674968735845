import moment from "moment-timezone";
import { useRecoilState } from "recoil";
import { getProjectService } from "../../backend";
import { currentProjectState } from "../../recoil/project";
import { setProjectLocalStorage } from "../../utils/local-storage";

export const useCurrentProject = () => {
    const [currentProject, setCurrentProject] = useRecoilState(currentProjectState);

    const updateCurrentProject = async (projectId: string) => {
        const [{ data: project }, { data: subscription }, { data: paymentMethods }] = await Promise.all([
            getProjectService().getProject(projectId),
            getProjectService().getPaymentSubscription(projectId).catch(() => ({ data: {} })),
            getProjectService().getProjectPaymentMethods(projectId).catch(() => ({ data: [] }))
        ]);

        const projectWithSubscription = {
            ...project,
            subscription,
            paymentMethods
        };

        setProjectLocalStorage(projectWithSubscription);
        setCurrentProject(projectWithSubscription!);

        return project;
    }

    if (!currentProject) {
        return {
            updateCurrentProject
        };
    } else {
        return {
            updateCurrentProject,
            isProjectActive: currentProject.paymentPlan?.active,
            trialDaysLeft: currentProject.subscription?.status === "trialing" ?
                moment(currentProject.subscription.trial_end * 1000).diff(moment(), 'days') + 1 : 0,
            paymentMethodRequired: currentProject.subscription?.status === "trialing" &&
                !currentProject.paymentMethods?.length,
            shouldInitiateTrial: false //currentProject.paymentPlan?.id === "FREE"
        }
    }

}
