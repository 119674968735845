import Modal from "antd/lib/modal/Modal";
import { Button, Form, Input, notification } from "antd";
import { useEffect, useState } from "react";
import { getPipelinesService } from "../../backend";
import { RequiredMark } from "antd/lib/form/Form";
import { AddDockerRegistryRequest, DockerRegistryType } from "@microtica/ms-ap-sdk";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";

export interface ConnectContainerRegistryModalProps {
    provider: "dockerhub" | "gitlab-container-registry" | "ecr";
    visible?: boolean;
    onOk?: (id: string) => void;
    onCancel?: () => void;
}

const ConnectContainerRegistryModal = ({
    provider,
    visible,
    onOk,
    onCancel
}: ConnectContainerRegistryModalProps) => {
    const [form] = Form.useForm();
    const currentProject = useRecoilValue(currentProjectState);
    const [requiredMark] = useState<RequiredMark>('optional');
    const [modalVisible, setModalVisible] = useState(visible);
    const [connectingAccount, setConnectingAccount] = useState(false);

    useEffect(() => {
        setModalVisible(visible);
    }, [visible]);

    const handleConnectAwsAccount = async (values: { name: string; docker_username: string; docker_password: string; }) => {
        try {
            setConnectingAccount(true);
            await getPipelinesService().addDockerRegistry(
                currentProject!.id,
                {
                    type: DockerRegistryType.Dockerhub,
                    registryName: values.name,
                    credentials: [{
                        key: "docker_username",
                        value: ""
                    }]
                } as AddDockerRegistryRequest
            );
            setModalVisible(false);
            onOk?.(values.name);
            form.resetFields();
        } catch (error) {
            notification.error({
                message: "Error connecting AWS account",
                description: "Please check if the role exists on your account and/or you've entered a correct External ID"
            });
        } finally {
            setConnectingAccount(false);
        }
    }

    const EcrForm = () => (
        <Form
            form={form}
            layout="vertical"
            initialValues={{}}
            onFinish={handleConnectAwsAccount}
            requiredMark={requiredMark}
        >
            <Form.Item
                name="name"
                label="Registry name"
                required
                rules={[{ required: true, message: 'Please input the Docker namename!' }]}
            >
                <Input autoFocus={true} />
            </Form.Item>
            <Form.Item
                name="docker_username"
                label="Username"
                required
                rules={[{ required: true, message: 'Please input the Docker namename!' }]}
            >
                <Input autoFocus={true} />
            </Form.Item>
            <Form.Item
                name="docker_password"
                label="Password/Token"
                required
                rules={[
                    { required: true, message: 'Please input the Docker username/token!' }
                ]}
            >
                <Input />
            </Form.Item>
            <br />
            <Button htmlType="submit" className="display-none"></Button>
        </Form>
    )

    return (
        <Modal
            title="Connect AWS Account"
            centered
            closable={false}
            open={modalVisible}
            footer={[
                <Button type="link" style={{ float: "left", padding: 0 }}>
                    <a href="https://docs.microtica.com/connect-an-aws-account" target="_blank" rel="noreferrer">
                        How to connect AWS account?
                    </a>
                </Button>,
                <Button onClick={() => {
                    setModalVisible(false);
                    onCancel?.();
                    form.resetFields();
                }}
                >
                    Cancel
                </Button>,
                <Button
                    type="primary"
                    loading={connectingAccount}
                    onClick={async () => {
                        try {
                            const values = await form.validateFields();
                            handleConnectAwsAccount(values);
                        } catch (error) {
                            
                        }
                    }}
                >
                    Connect
                </Button>
            ]}
        >
            <EcrForm />
        </Modal>
    )
}

export default ConnectContainerRegistryModal;