import { FallOutlined, RiseOutlined } from "@ant-design/icons";
import { Skeleton, Statistic } from "antd";
import { Fragment } from "react";
import { convertToCurrency } from "../../../utils/conversion";

interface MetricsWidgetProps {
    title: string;
    loading: boolean;
    value?: number;
    lastValue?: number;
    trendThisMonth?: number;
}

const MetricsWidget = ({
    title,
    loading,
    value,
    lastValue: valueLastMonth,
    trendThisMonth
}: MetricsWidgetProps) => {
    return (
        loading ?
            <Skeleton active paragraph={{ rows: 2 }} className="pt-5" /> :
            <Fragment>
                <Statistic
                    title={<b>{title}</b>}
                    value={(value && convertToCurrency(value)) || "n/a"}
                />
                {
                    trendThisMonth ?
                        <>
                            <span style={{ color: trendThisMonth > 0 ? "green" : "red" }}>
                                {
                                    trendThisMonth > 0 ? <FallOutlined /> : <RiseOutlined />
                                }
                                <b>
                                    &nbsp;{trendThisMonth > 0 ? "-" : "+"}
                                    {Math.abs(trendThisMonth)}%
                                </b>
                            </span>
                            <span style={{ color: "GrayText" }}>
                                {
                                    valueLastMonth ?
                                        <>
                                            &nbsp;compared to {valueLastMonth && convertToCurrency(valueLastMonth)} last month
                                        </> :
                                        <>
                                            &nbsp;vs last month
                                        </>
                                }

                            </span>
                        </> :
                        null
                }
            </Fragment>
    )
};
export default MetricsWidget;