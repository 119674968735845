import { Alert, Button, Empty, List, Modal, ModalFuncProps } from "antd"
import Search from "antd/es/input/Search";
import { useEffect, useState } from "react";
import { getEnvironmentService, getProjectService } from "../../backend";
import { Project } from "@microtica/ms-project-sdk";
import { useParams } from "react-router";
import { GetComponentResponse } from "@microtica/ms-engine-sdk";
import { CaretRightOutlined, CloseCircleFilled } from "@ant-design/icons";


interface ShareComponentModalProps {
    componentId: string;
    open?: boolean;
    onOk?: (key: string) => void;
    onCancel?: () => void;
}

const ShareComponentModal = ({
    componentId,
    open,
    onOk,
    onCancel,
}: ShareComponentModalProps) => {
    const { projectId } = useParams() as { projectId: string; }
    const [nameFilter, setNameFilter] = useState("");
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState<Project[]>([]);
    const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
    const [component, setComponent] = useState<GetComponentResponse>();
    const [, setModal] = useState<{
        destroy: () => void;
        update: (configUpdate: ModalFuncProps) => void;
    }>();


    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        setFilteredProjects(
            projects.filter(p =>
                nameFilter ? p.name.toLocaleLowerCase().includes(nameFilter.toLocaleLowerCase()) : true
            )
        );

    }, [projects, nameFilter]);

    const load = async () => {
        const [
            { data: { projects } },
            { data: component }
        ] = await Promise.all([
            getProjectService().listProjects(),
            getEnvironmentService().getComponent(componentId, projectId)
        ]);

        setProjects(projects);
        setComponent(component);
        setLoading(false);
    }

    const handleRevoke = async (shareWithProjectId: string) => {
        await getEnvironmentService().unshareComponent(
            component?.id!,
            projectId,
            {
                granteeId: shareWithProjectId
            }
        );

        load();
    }

    const handleShare = async (shareWithProjectId: string) => {
        await getEnvironmentService().shareComponent(
            component?.id!,
            projectId,
            {
                granteeId: shareWithProjectId
            }
        );

        load();
    }


    const showRevokeConfirm = (projectId: string) => {
        const instances = component?.instances
            .filter(i => i.projectId === projectId);

        const modal = Modal.confirm({
            title: "Revoke access",
            icon: <CloseCircleFilled style={{ color: "#ff4d4f" }} />,
            content: <>
                <p>
                    Are you sure you want to revoke access to this component?
                </p>

                <p>
                    {
                        instances?.length ?
                            <>
                                This component is currently used in the following <b>{projects.find(p => p.id === projectId)?.name}</b> environments:
                                {
                                    instances?.map(i => (
                                        <div><CaretRightOutlined /> {i.stageName}</div>
                                    ))
                                }
                            </> : undefined
                    }
                </p>

                <p>
                    {
                        instances?.length ?
                            "Revoking access will most probably break any future deployments in these environments." :
                            <>
                                This component is currently NOT used in the <b>{projects.find(p => p.id === projectId)?.name}</b> project.
                            </>
                    }

                </p>
            </>,
            okText: "Revoke Access",
            cancelText: "No",
            centered: true,
            okButtonProps: {
                danger: true
            },
            async onOk() {
                return handleRevoke(projectId);
            },
            onCancel() {
                setModal(undefined);
            }
        });
        setModal(modal);
    }

    return (
        <Modal
            title={
                <div>
                    <div>Share Template</div>
                    <div className="gray-text" style={{ fontSize: "12px", fontWeight: 500 }}>
                        Application will be deployed on the existing or newly created cluster
                    </div>
                </div>
            }
            centered
            closable
            open={open}
            footer={null}
            onCancel={onCancel}
            className="ant-scollable-modal"
        >
            <br />
            <List
                header={
                    <Search
                        className="full-width"
                        placeholder="Search project"
                        onSearch={setNameFilter}
                        allowClear
                    />
                }
                loading={loading}
                dataSource={filteredProjects}
                renderItem={(item) => (
                    <List.Item
                        extra={
                            item.id === projectId ?
                                <div className="gray-text">Current project</div> :
                                component?.accessList.some(al => al.assigneeType === "shared" && al.assigneeId === item.id) ?
                                    <Button
                                        danger
                                        onClick={() => {
                                            showRevokeConfirm(item.id);
                                        }}>
                                        Revoke
                                    </Button> :
                                    <Button
                                        onClick={() => handleShare(item.id)}
                                    >
                                        Share
                                    </Button>
                        }
                    >
                        <List.Item.Meta
                            title={item.name}
                            description={
                                component?.accessList.find(al => al.assigneeType === "shared" && al.assigneeId === item.id)?.permissions.join("|")
                            }
                        />
                    </List.Item >
                )}
                locale={{
                    emptyText: <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No projects found for the search criteria."
                    />
                }}
            />

        </Modal >
    )
}

export default ShareComponentModal;