const SlackIntegrationExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Slack
            </div>
            <div className="explanation-subtitle">
                Messaging platform
            </div>
            <br />

            <p>
                Integrating with Slack allows you to receive build and deployment notifications about your applications
                and your infrastructure directly in your Slack channel.
            </p>
            <br />
            <p>
                Learn more about how to <a href="https://api.slack.com/messaging/webhooks" target="_blank" rel="noreferrer">create a Slack webhook.</a>
            </p>
        </>
    )
}

export default SlackIntegrationExplanation;