import { Card, Col, Row, Space } from "antd";
import { ReactNode } from "react";

export interface CardMarketingProps {
    title: ReactNode;
    subtitle?: ReactNode;
    description: ReactNode;
    docs?: ReactNode;
    action?: ReactNode;
    image?: string;
    extra?: ReactNode;
    imageShadow?: boolean;
}

export default function CardMarketing(props: CardMarketingProps) {

    return (
        <Card bordered style={{ padding: "10px", marginBottom: "30px", textAlign: "left" }}>
            <Row gutter={24}>
                <Col span={props.image || props.extra ? 12 : 24}>
                    <Space direction="vertical" size="large">
                        <div style={{ fontSize: "30px", fontWeight: "700" }}>
                            {props.title}
                        </div>
                        {
                            props.subtitle && <b>{props.subtitle}</b>
                        }
                        <div style={{ color: "GrayText", fontWeight: 600 }}>
                            {props.description}
                        </div>
                        {props.docs}
                        {props.action}
                    </Space>
                </Col>
                {
                    props.image ?
                        <Col span={12} className="flex-justify-center">
                            <img
                                src={props.image}
                                height={"250px"}
                                className={props.imageShadow ? "image-bordered" : ""}
                                alt="card-img"
                            />
                        </Col> :
                        undefined
                }
                {
                    props.extra ?
                        <Col span={12} className="flex-align-center" style={{ padding: "40px" }}>
                            {props.extra}
                        </Col> :
                        undefined
                }
            </Row>
        </Card>
    )
}