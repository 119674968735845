import { Card, Row, Col, Select } from "antd";
import { useEffect } from "react";

interface ChooseStageProps {
    stages: { id: string; name: string }[],
    onSelect?: ({ envId }: { envId: string }) => void;
}
const ChooseStage = ({
    stages,
    onSelect
}: ChooseStageProps) => {
    useEffect(() => {
        // pre-select the stage if it is the only one in the project
        if (stages?.length === 1) {
            onSelect?.({ envId: stages[0].id })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stages])

    const handleOnSelectStage = (envId: string) => {
        onSelect?.({ envId });
    }

    const getDefaultValue = () => {
        // pre-select the stage if it is the only one in the project
        if (stages?.length === 1) {
            return stages[0].id;
        }
        return undefined;
    }

    return (
        <Card type="inner">
            <Card.Meta
                title="Environment"
                description="Choose an environment to link the AWS account to."
            />
            <br />
            {
                <Row gutter={24}>
                    <Col xs={24} sm={12}>
                        <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Choose environment"
                            defaultValue={getDefaultValue()}
                            onSelect={handleOnSelectStage}
                        >
                            {
                                stages.map(stage => (
                                    <Select.Option value={stage.id} key={stage.id}>
                                        {stage.name}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Col>
                </Row>
            }
        </Card >
    );
}

export default ChooseStage;