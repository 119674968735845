import TerraformLogo from "../assets/terraform.svg";
import CloudFormationLogo from "../assets/aws-cloudformation.svg";
import KubernetesLogo from "../assets/kubernetes-icon.svg";
import { IaC } from "../types";


interface IacIconProps {
    provider?: IaC;
}

const IaCIcon = ({
    provider
}: IacIconProps) => {

    return provider === "cloudformation" ? <img src={CloudFormationLogo} style={{ width: "19px", height: "19px" }} alt="cloudformation" /> :
        provider === "terraform" ? <img src={TerraformLogo} style={{ width: "17px", height: "17px" }} alt="terraform" /> :
        provider === "kubernetes" ? <img src={KubernetesLogo} style={{ width: "17px", height: "17px" }} alt="kubernetes" /> :
            undefined

}

export default IaCIcon;