import { Button, Card, Col, Divider, Dropdown, Empty, Form, Input, notification, Result, Row, Select, Skeleton, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProjectService, getUserManagementService } from "../../backend";
import { MoreOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useAuth } from "../../contexts/Auth";
import { useDoubleConfigurationModal } from "../../contexts/Modal";
import { Permission } from "@microtica/ms-project-sdk";
import { PERMISSIONS } from "../../enums/enums";
import capitalizeFirstLetter from "../../utils/capitalize-first-letter";
import { trackUserAdded, trackUserInviteResend, trackUserRemoved } from "../../backend/tracking/user-settings";
import { ItemType } from "antd/es/menu/interface";
import { getUsersWithinProject } from "../../utils/project/get-users";

type Role = "Owner" | "Admin" | "Write" | "Read";

const ProjectSettingsMembers = () => {
    const [form] = Form.useForm();
    const { profile } = useAuth();
    const { projectId } = useParams() as { projectId: string; };
    const [loadingMembers, setLoadingMembers] = useState(true);
    const [loadingInvite, setLoadingInvite] = useState(false);
    const [members, setMembers] = useState<{ id: string; email: string; fullname?: string; role: string; pending: boolean; }[]>([]);
    const [selectedRole, setSelectedRole] = useState<"Owner" | "Admin" | "Write" | "Read">("Write");
    const [disableMembersSection, setDisableMembersSection] = useState<boolean>(false);

    const { open: openConfirmModal } = useDoubleConfigurationModal();

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (!!members.length) {
            const user = members.find(member => member.id === profile?.id);
            if (user && user.role !== "Owner" && user.role !== "Admin") {
                setDisableMembersSection(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [members]);

    const loadData = async () => {
        try {
            const { users, userProfiles } = await getUsersWithinProject(projectId);

            setMembers(
                Object.entries(userProfiles).map(([key, profile]) => ({
                    key: profile.id,
                    id: profile.id,
                    email: profile.email,
                    fullname: profile.firstName && `${profile.firstName} ${profile.lastName}`,
                    role: capitalizeFirstLetter(users.find(u => u.id === key)?.permission!),
                    pending: profile.status === "FORCE_CHANGE_PASSWORD"
                })).sort((a) => {
                    if (a.id === profile?.id) {
                        return -1;
                    } else {
                        return 0
                    };
                })
            );
        } catch (error) {

        } finally {
            setLoadingMembers(false);
        }
    }

    const handleRemoveMember = async (userId: string) => {
        try {
            await getProjectService().removeUser(projectId, userId, window.location.origin);
            await loadData();
            notification.success({
                message: "Member removed from the project"
            });
            trackUserRemoved(userId);
        } catch (error: any) {
            notification.error({
                message: "Error removing member from the project",
                description: error.response.data.message
            });
        }
    }

    const handleAddMember = async (values: { email: string; role: Role }) => {
        try {
            setLoadingInvite(true);
            await getProjectService().addUser(
                projectId,
                window.location.origin,
                {
                    email: values.email,
                    permission: Permission[values.role]
                }
            );
            await loadData();
            form.resetFields();
            notification.success({
                message: "New member added on the project"
            });
            trackUserAdded(values.email);
        } catch (error: any) {
            notification.error({
                message: "Error adding member on the project",
                description: error.response.data.message
            });
        } finally {
            setLoadingInvite(false);
        }
    }

    const handleUpdateRole = async (userId: string, newRole: Role) => {
        try {
            await getProjectService().updateUserPermissions(
                projectId,
                userId,
                { permission: Permission[newRole] }
            );
            await loadData();
            notification.success({
                message: `Member role changed to ${newRole}`
            });
        } catch (error: any) {
            notification.error({
                message: "Error changing member role",
                description: error.response.data.message
            });
        }
    }

    const handleResendInvitation = async (email: string) => {
        try {
            await getUserManagementService().resendUserInvitation(
                projectId,
                {
                    username: email
                }
            );

            notification.success({
                message: `Invitation sent`
            });
            trackUserInviteResend(email);
        } catch (error: any) {
            notification.error({
                message: "Error resending the invitation.",
                description: error.response.data.message
            });
        }
    }

    const showDeleteConfirm = (userId: string, confirmation: string) => {
        openConfirmModal({
            title: "Remove project member?",
            description: "The user will be permanently removed from this project.",
            confirmation,
            okText: "Remove Member",
            cancelText: 'Cancel',
            onOk: () => {
                return handleRemoveMember(userId);
            }
        })
    }

    return (
        <>
            {
                loadingMembers ? <Skeleton /> :
                    disableMembersSection ?
                        <Card>
                            <Result
                                status="warning"
                                title="You are not authorized to access members settings."
                                subTitle="Only project owner and admin are allowed to access and modify members."
                            />
                        </Card> :
                        <>
                            <Card>
                                <Card.Meta
                                    title="Invite new team members"
                                    description="Invite new members on this project using email address."
                                />
                                <Divider />
                                <Form
                                    form={form}
                                    initialValues={{
                                        role: selectedRole
                                    }}
                                    onFinish={handleAddMember}
                                >
                                    <Row gutter={24}>
                                        <Col span={18}>
                                            <Form.Item
                                                name="email"
                                                required
                                                rules={[
                                                    { required: true, type: "email", message: 'Please enter email address' }
                                                ]}
                                            >
                                                <Input placeholder="Email address" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                name="role"
                                                valuePropName="role"
                                                required
                                                rules={[
                                                    { required: true, message: 'Please select role' }
                                                ]}
                                            >
                                                <Select onChange={value => setSelectedRole(value)} value={selectedRole}>
                                                    {
                                                        Object.entries(PERMISSIONS).map(([key]) => (
                                                            <Select.Option value={key} key={key}>{key}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Card type="inner">
                                        <Card.Meta
                                            title={
                                                <>
                                                    <InfoCircleOutlined /> {selectedRole} permissions
                                                </>
                                            }
                                            description={PERMISSIONS[selectedRole]}
                                        />
                                    </Card>
                                    <br />
                                    <Row>
                                        <Col span={24} style={{ textAlign: "right" }}>
                                            <Button type="primary" htmlType="submit" loading={loadingInvite}>
                                                Invite
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                            <br />
                            <Table
                                showHeader={false}
                                loading={loadingMembers}
                                dataSource={members}
                                pagination={false}
                                title={() => "Team Members"}
                                locale={{
                                    emptyText: <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={"No members in the project yet!"}
                                    />
                                }}
                                columns={[
                                    {
                                        key: "profile",
                                        render: profile =>
                                            <>
                                                <b>{profile.fullname || undefined}</b>
                                                <div style={{ color: "GrayText" }}>
                                                    {profile.email}
                                                </div>
                                            </>
                                    },
                                    {
                                        key: 'role',
                                        render: _profile =>
                                            <Select
                                                onChange={value => { handleUpdateRole(_profile.id, value) }}
                                                value={_profile.role}
                                                bordered={false}
                                                disabled={_profile.id === profile?.id}>
                                                {
                                                    Object.entries(PERMISSIONS).map(([key]) => (
                                                        <Select.Option value={key} key={key}>{key}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                    },
                                    {
                                        key: 'actions',
                                        align: "right",
                                        render: _profile => (
                                            <Space size="large">
                                                {_profile.pending && <Tag>Pending</Tag>}
                                                <Dropdown
                                                    placement="bottomRight"
                                                    trigger={["click"]}
                                                    menu={{
                                                        items: (_profile.pending ? [
                                                            {
                                                                key: "resend-invitation",
                                                                label: "Resend invitation",
                                                                onClick: () => handleResendInvitation(_profile.email)
                                                            } as ItemType
                                                        ] : []
                                                        ).concat([
                                                            {
                                                                key: "delete-member",
                                                                label: profile?.id === _profile.id ? "Leave Team" : "Remove member",
                                                                onClick: () => showDeleteConfirm(_profile.id, _profile.email),
                                                                danger: true
                                                            }
                                                        ])
                                                    }}
                                                >
                                                    <Button icon={<MoreOutlined />} />
                                                </Dropdown>
                                            </Space>
                                        )
                                    }
                                ]}
                            />
                        </>
            }
        </>
    )
}

export default ProjectSettingsMembers;