
export const convertToCurrency = (amount: number) => {
    return Math.round(amount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
    });
}

export const convertToCurrencyWithoutFractionDigits = (amount: number) => {
    return Math.round(amount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0
    });
}

export const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}