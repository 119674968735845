import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Divider, notification, Space } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getKubeService } from "../../backend";
import { useDoubleConfigurationModal } from "../../contexts/Modal";

export default function KubernetesSettingsDangerZone() {
    const { projectId, envId, clusterId } = useParams() as { projectId: string; envId: string; clusterId: string; };
    const navigate = useNavigate();
    const { open: openConfirmModal } = useDoubleConfigurationModal();
    const [deleting, setDeleting] = useState(false);

    const handleDelete = async () => {
        try {
            setDeleting(true);
            await getKubeService().deleteKubernetes(clusterId, projectId);
            notification.success({
                message: "Cluster deleted",
                description: `The cluster ${clusterId} has been successfully deleted from this environment.`
            });
            setTimeout(() => {
                navigate(`/projects/${projectId}/environments/${envId}`);
            }, 1000);
        } catch (error: any) {
            notification.error({
                message: "Error while trying to delete cluster",
                description: error?.response?.data?.message ? error?.response?.data?.message : "We encountered an error while trying to delete this cluster. Please try again."
            });
        } finally {
            setDeleting(false);
        }
    }

    const showDeleteConfirm = () => {
        openConfirmModal({
            title: "Delete component",
            description:
                <Space direction="vertical">
                    <div>
                        The cluster and <b>all of its associated applications</b> will be permanently deleted.
                    </div>
                    <Alert message={<b>This action cannot be undone.</b>} type="error" />
                </Space>,
            confirmation: clusterId,
            okText: "Delete Cluster",
            cancelText: "Cancel",
            onOk: () => { return handleDelete(); }
        })
    }

    return (
        <>
            <Space direction="vertical" className="full-width">
                <Card bordered>
                    <Card.Meta
                        title={
                            <Space>
                                <ExclamationCircleOutlined style={{ color: 'red' }} />
                                Delete Cluster
                            </Space>
                        }
                        description="The cluster and all of its associated applications will be permanently deleted. Please note that this action is irreversible and cannot be undone."
                    />
                    <Divider />

                    <div className="text-right">
                        <Button
                            type="primary"
                            danger
                            onClick={showDeleteConfirm}
                            loading={deleting}
                        >
                            Delete
                        </Button>
                    </div>
                </Card>
            </Space>
        </>
    );
}