import { Button, Card, Divider, Skeleton, Space, Tooltip, notification } from "antd"
import MonitoringDisabledPlaceholder from "../../components/explanations/MonitoringDisabledPlaceholder";
import MonitoringErrorPlaceholder from "../../components/explanations/MonitoringErrorPlaceholder";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getMonitoringService } from "../../backend";
import { useDoubleConfigurationModal } from "../../contexts/Modal";
import { ClusterMonitoringStatusResponseStatusEnum, EnableMonitoringRequestClusterTypeEnum } from "@microtica/ms-monitoring-sdk";
import { ReloadOutlined } from "@ant-design/icons";


const ClusterMonitoringSettings = () => {
    const { projectId, envId, componentId, clusterId } = useParams() as {
        projectId: string;
        envId: string,
        componentId: string;
        clusterId: string;
    }
    const [loading, setLoading] = useState(true);
    const [monitoringEnabled, setMonitoringEnabled] = useState(true);
    const [monitoringError, setMonitoringError] = useState<{ status: ClusterMonitoringStatusResponseStatusEnum; message: string; }>();
    const [disabling, setDisabling] = useState(false);
    const [syncing, setSyncing] = useState(false);
    const { open: openConfirmModal } = useDoubleConfigurationModal();

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        try {
            setLoading(true);
            const { data: { status, statusMessage } } = await getMonitoringService().clusterMonitoringStatus(
                clusterId,
                envId,
                projectId
            );
            if (status !== ClusterMonitoringStatusResponseStatusEnum.Healthy) {
                setMonitoringError({
                    status,
                    message: statusMessage
                });
            } else {
                setMonitoringError(undefined);
            }
            setMonitoringEnabled(true);
        } catch (error) {
            if ((error as any).response?.status === 404) {
                setMonitoringEnabled(false);
            } else {
                setMonitoringError({
                    status: (error as any).response?.data?.status,
                    message: (error as any).response?.data?.statusMessage
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const handleDisableMonitoring = async () => {
        try {
            setDisabling(true);
            await getMonitoringService().disableMonitoring(clusterId, envId, projectId);
            notification.success({
                message: "Disable monitoring initiated",
                description: "It could take a few minutes for the monitoring to be completely disabled for your cluster."
            });
            await load();
        } catch (error) {
            notification.error({
                message: "Cannot disable monitoring",
                description: <pre className="white-space-pre-wrap" style={{ fontSize: "12px" }}>
                    {(error as any).response.data.message}
                    <br />
                    <br />
                    {(error as any).response.data.kubernetesError?.message}
                </pre>
            })
        } finally {
            setDisabling(false);
        }
    }

    const handleSyncMetrics = async () => {
        try {
            setSyncing(true);
            await getMonitoringService().disableMonitoring(clusterId, envId, projectId);
            await getMonitoringService().enableMonitoring(
                clusterId,
                projectId,
                {
                    clusterType: EnableMonitoringRequestClusterTypeEnum.Ecs,
                    stageId: envId
                }
            );
            notification.success({
                message: "Metrics successfully synced"
            });
        } catch (error) {

        } finally {
            setSyncing(false);
        }
    }

    return (
        <Space direction="vertical" className="full-width">
            <Card bordered>
                <Card.Meta
                    title={
                        <div className="flex-justify-space-between flex-align-center">
                            <div>
                                Cluster Monitoring
                                <div style={{ fontSize: 14, fontWeight: 400, color: "rgba(0, 0, 0, 0.45)" }}>
                                    Monitor resource utilization, response times, and overall system health to ensure optimal functionality and user experience.
                                </div>
                            </div>
                            <Button icon={<ReloadOutlined />} onClick={load} />
                        </div>
                    }
                />
                <Divider />

                {
                    loading ? <Skeleton /> :
                        <>
                            {
                                !monitoringEnabled ?
                                    <MonitoringDisabledPlaceholder
                                        projectId={projectId}
                                        envId={envId}
                                        clusterId={clusterId}
                                        clusterName={componentId || clusterId}
                                        clusterType={
                                            componentId === clusterId ?
                                                EnableMonitoringRequestClusterTypeEnum.Ecs :
                                                EnableMonitoringRequestClusterTypeEnum.K8s
                                        }
                                        onOk={() => load()}
                                    /> :
                                    monitoringError ? <MonitoringErrorPlaceholder
                                        projectId={projectId}
                                        envId={envId}
                                        clusterId={clusterId}
                                        clusterName={componentId || clusterId}
                                        clusterType={
                                            componentId === clusterId ?
                                                EnableMonitoringRequestClusterTypeEnum.Ecs :
                                                EnableMonitoringRequestClusterTypeEnum.K8s
                                        }
                                        error={monitoringError}
                                    /> :
                                        <Space direction="vertical" size="large">
                                            Monitoring is currently enabled, allowing you to track system performance and resource usage. To stop monitoring, click the button below.

                                            <Space>
                                                {
                                                    componentId === clusterId &&
                                                    <>
                                                        <Tooltip title="Fetch and update metrics for this component from the configured sources.">
                                                            <Button
                                                                loading={syncing}
                                                                onClick={handleSyncMetrics}
                                                            >
                                                                Sync Metrics
                                                            </Button>
                                                        </Tooltip>
                                                        <Divider type="vertical" />
                                                    </>
                                                }
                                                <Button
                                                    danger
                                                    loading={disabling}
                                                    onClick={() => openConfirmModal({
                                                        title: "Disable Monitoring?",
                                                        description: "Disabling monitoring will pause data collection and analysis. You can enable monitoring again at any time.",
                                                        confirmation: clusterId,
                                                        okText: "Disable Monitoring",
                                                        cancelText: 'Cancel',
                                                        onOk: async () => await handleDisableMonitoring()
                                                    })}
                                                >
                                                    Disable Monitoring
                                                </Button>
                                            </Space>
                                        </Space>
                            }
                        </>
                }
            </Card>
        </Space>
    )
}


export default ClusterMonitoringSettings;

