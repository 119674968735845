import { MonitorOutlined } from "@ant-design/icons";
import { Button, Empty, Result } from "antd";
import { useState } from "react";
import EnableMonitoringModal from "../modals/EnableMonitoringModal";
import { EnableMonitoringRequestClusterTypeEnum } from "@microtica/ms-monitoring-sdk";

interface MonitoringDisabledPlaceholderProps {
    projectId: string;
    envId: string;
    clusterId: string;
    clusterName: string;
    clusterType: EnableMonitoringRequestClusterTypeEnum;
    onOk?: () => void;
}

const MonitoringDisabledPlaceholder = ({
    projectId,
    envId,
    clusterId,
    clusterName,
    clusterType,
    onOk
}: MonitoringDisabledPlaceholderProps) => {
    const [modalVisible, setModalVisible] = useState(false);

    return <div className="flex-justify-center full-width">
        <Result
            title="Monitoring disabled!"
            status="info"
            subTitle={
                <div>
                    No data to display yet. Enable monitoring to start tracking application metrics and insights.
                </div>
            }
            extra={
                <Button icon={<MonitorOutlined />} onClick={() => setModalVisible(true)}>
                    Enable Monitoring
                </Button>
            }
        />
        {
            modalVisible &&
            <EnableMonitoringModal
                projectId={projectId}
                envId={envId}
                clusterId={clusterId}
                clusterName={clusterName}
                clusterType={clusterType}
                open={modalVisible}
                onOk={() => {
                    setModalVisible(false);
                    onOk?.();
                }}
                onCancel={() => setModalVisible(false)}
            />
        }
    </div>
}


export default MonitoringDisabledPlaceholder;