import { Badge, Button, Col, Dropdown, Row, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Fragment, MouseEventHandler, ReactNode } from "react";
import { ItemType } from "antd/es/menu/interface";
const { Text } = Typography;

interface IPageHeaderContent {
    title: {
        mainText: string;
        prefix?: string;
    },
    summaryTag?: {
        color: string;
        text: string;
    }
    actionItems?: ItemType[];
    summary: {
        primaryText?: ReactNode;
        secondaryText?: ReactNode;
    }[];
    mainLinkButton?: {
        title: ReactNode;
        link: string;
    };
    mainPrimaryButton?: {
        title: ReactNode;
        onClick: MouseEventHandler<HTMLElement> | undefined
    };
}

const PageHeaderContent = ({
    title,
    actionItems,
    summary,
    mainLinkButton,
    mainPrimaryButton,
    summaryTag
}: IPageHeaderContent) => {
    return (
        <Row align="middle" gutter={[0, 8]}>
            <Col xs={24} sm={24} md={24} lg={17}>
                <Row style={{ fontSize: "18px" }}>
                    {title.prefix && <Text strong className="gray-text">{title.prefix}&nbsp;</Text>}
                    <Text strong>{title.mainText}</Text>
                </Row>
                <Row>
                    {summaryTag &&
                        <>
                            <Text style={{ color: summaryTag.color }}>
                                <b>{summaryTag.text}</b>
                            </Text>
                            {summary.length !== 0 &&
                                <Badge status="default" className="summary-badge" />
                            }
                        </>
                    }
                    {summary.map((item, index) => (
                        <Fragment key={index}>
                            {item.primaryText !== undefined && <Text>{item.primaryText}</Text>}
                            {item.secondaryText !== undefined ?
                                item.primaryText !== undefined ?
                                    <>
                                        &nbsp;<Text className="gray-text">{item.secondaryText}</Text>
                                    </> :
                                    <Text className="gray-text">{item.secondaryText}</Text>
                                : null
                            }
                            {index !== summary.length - 1 &&
                                <Badge status="default" className="summary-badge" />
                            }
                        </Fragment>
                    ))}
                </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={7} className="flex-justify-end">
                <Row>
                    {mainLinkButton ?
                        <Button type="primary" style={{ marginRight: "8px" }}>
                            <a href={mainLinkButton.link} target="_blank" rel="noreferrer">
                                {mainLinkButton.title}
                            </a>
                        </Button> : null
                    }
                    {
                        mainPrimaryButton ?
                            <Button type="primary" style={{ marginRight: "8px" }} onClick={mainPrimaryButton.onClick}>
                                {mainPrimaryButton.title}
                            </Button> : null
                    }
                    {actionItems && actionItems.length ?
                        <Dropdown
                            trigger={["click"]}
                            placement="bottomRight"
                            menu={{
                                direction: "ltr",
                                items: actionItems
                            }}
                        >
                            <Button type="primary" ghost>
                                Actions <DownOutlined />
                            </Button>
                        </Dropdown> : null
                    }
                </Row>
            </Col >
        </Row >
    );
}

export default PageHeaderContent;