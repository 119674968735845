import { Button, Dropdown, Layout, Space, Badge } from "antd";
import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { getNotificationService, getProjectService } from "../../backend";

import Logo from "../../assets/logo-color.svg";
import Avatar from "antd/lib/avatar/avatar";
import { useAuth } from "../../contexts/Auth";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { projectsState } from "../../recoil/project";
import {
    UserOutlined,
    BellFilled,
    NotificationFilled,
    WarningFilled
} from "@ant-design/icons";
import { handleSetMixpanelUserGroups, handleTrackLogoutEvent } from "../../backend/tracking";
import ChangelogDrawer from "./ChangelogDrawer";
import { trackNotificationsClicked, trackWhatsNewClicked } from "../../backend/tracking/user-settings";
import NotificationsDrawer from "./NotificationsDrawer";
import { newNotificationsState } from "../../recoil/notification";
import { UserNotificationsNotifications } from "@microtica/ms-notification-sdk";

const { Header, Content } = Layout;

const HomePageLayout = () => {
    const badgeColor = "#1890ff";
    const navigate = useNavigate();
    const { profile } = useAuth();
    const { isLoggedIn } = useAuth();
    // recoil project
    const [projects, setProjects] = useRecoilState(projectsState);
    const resetProjectsState = useResetRecoilState(projectsState);
    // recoil notification
    const setNewUserNotifications = useSetRecoilState(newNotificationsState);
    // useState
    const [loadingProjects, setLoadingProjects] = useState(false);
    const [showChangelog, setShowChangelog] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showAlarmNotifications, setShowAlarmNotifications] = useState(false);
    const [userNotifications, setUserNotifications] = useState([] as UserNotificationsNotifications[]);

    useEffect(() => {
        const loadData = async () => {
            setLoadingProjects(true);
            try {
                const [{ data: { projects } }] = await Promise.all([
                    getProjectService().listProjects()
                ])

                setProjects(projects);
                handleSetMixpanelUserGroups(projects.map(item => item.id));
            } catch (error) {
            } finally {
                setLoadingProjects(false);
            }
        }
        if (isLoggedIn) {
            loadData();
            fetchNotifications();

            const notificationsInterval = setInterval(async () => {
                await fetchNotifications();
            }, 30000);

            return () => {
                clearInterval(notificationsInterval);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    const handleLogout = () => {
        localStorage.removeItem('idToken');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('profile');

        resetProjectsState();

        handleTrackLogoutEvent();

        navigate("/auth/login");
    }

    const handleOpenChangelog = () => {
        setShowChangelog(true);
        trackWhatsNewClicked();
    }

    const handleOpenNotifications = () => {
        setShowNotifications(true);
        trackNotificationsClicked();
    }

    const handleOpenAlarmNotifications = () => {
        setShowAlarmNotifications(true);
    }

    const fetchNotifications = async () => {
        const { data: { notifications } } = await getNotificationService().getAllUserNotifications();

        // update recoil state only if there are new notifications
        setUserNotifications(oldNotifications => {
            if ((oldNotifications.length === 0 && notifications.length > 0) ||
                (notifications[0]?.id !== oldNotifications[0]?.id || notifications.filter(n => n.isRead).length !== oldNotifications.filter(o => o.isRead).length)) {
                const oldNotificationsIds = oldNotifications.map(oldNotification => oldNotification.id);
                const newNotifications = notifications.filter(notification => !oldNotificationsIds.includes(notification.id));
                setNewUserNotifications(newNotifications);
                return notifications;
            } else {
                return oldNotifications;
            }
        })
    }

    const HeaderActions = () => (
        <div>
            <Space size="middle">
                <Badge
                    style={{ backgroundColor: badgeColor }}
                    count={userNotifications.filter(notification => !notification.isRead).length}
                    size="small"
                    offset={[-10, -5]}>
                    <Button
                        type="text"
                        icon={<BellFilled style={{ fontSize: "22px !important" }} />}
                        className="ant-btn-ghost-inverse"
                        onClick={handleOpenNotifications}
                    >
                        <div className="button-text">
                            Updates
                        </div>
                    </Button>
                </Badge>
                <Badge
                    style={{ backgroundColor: "red" }}
                    count={userNotifications.filter(notification => !notification.isRead && notification.entityType === "MONITORING").length}
                    size="small"
                    offset={[-10, -5]}>
                    <Button
                        type="text"
                        icon={<WarningFilled style={{ fontSize: "18px" }} />}
                        className="ant-btn-ghost-inverse"
                        onClick={handleOpenAlarmNotifications}
                    >
                        <div className="button-text">
                            Incidents
                        </div>
                    </Button>
                </Badge>
                <Badge
                    style={{ backgroundColor: badgeColor }}
                    size="small"
                    offset={[-10, -5]}
                >
                    <Button
                        type="text"
                        icon={<NotificationFilled />}
                        className="ant-btn-ghost-inverse"
                        onClick={handleOpenChangelog}
                    >
                        <div className="button-text">
                            What's new
                        </div>
                    </Button>
                </Badge>
                <Dropdown
                    placement="bottomRight"
                    menu={{
                        items: [{
                            key: "profile-email",
                            label: profile?.email,
                            disabled: true
                        },
                        {
                            key: "logout",
                            label: "Logout",
                            onClick: handleLogout
                        }]
                    }}
                    trigger={['click']}
                >
                    <Avatar icon={<UserOutlined />} style={{ cursor: "pointer" }} />
                </Dropdown>
            </Space>
        </div>
    )

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Layout>
                <Header className="header flex-align-center flex-justify-space-between" >
                    <div style={{ margin: "16px 0" }}>
                        <Link to="/projects">
                            <img src={Logo} height="35px" alt="Microtica" />
                        </Link>
                    </div>
                    {
                        isLoggedIn && <HeaderActions />
                    }
                </Header>
                <Content>
                    <Outlet />
                </Content>
            </Layout>
            <ChangelogDrawer visible={showChangelog} onClose={() => setShowChangelog(false)} />
            {
                (showNotifications || showAlarmNotifications) &&
                <NotificationsDrawer
                    projects={projects}
                    notifications={userNotifications.filter(notification => showAlarmNotifications ? notification.entityType === "MONITORING" : notification.entityType !== "MONITORING")}
                    visible={showNotifications || showAlarmNotifications}
                    type={showAlarmNotifications ? "Incidents" : "Updates"}
                    onClose={() => {
                        setShowNotifications(false);
                        setShowAlarmNotifications(false);
                        fetchNotifications();
                    }}
                    onMarkAsRead={() => {
                        fetchNotifications();
                    }}
                />
            }
        </Layout>
    );
}

export default HomePageLayout;