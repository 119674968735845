import { Alert, Button, Descriptions, Form, FormInstance, Input, Space } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import Text from "antd/lib/typography/Text";
import { Fragment } from "react";
import MessageCard from "../../components/cards/MessageCard";

interface AssignResourceDomainStepsProps {
    currentStep: number,
    waitingTime: number,
    form: FormInstance<any>,
    requiredMark: RequiredMark,
    isModal: boolean,
    shouldInitiateStarterPlanTrial: boolean,
    domainCNAME: string,
    newDomain?: string,
    setNewDomain: React.Dispatch<React.SetStateAction<string | undefined>>,
    requestCertificateForDomain: () => Promise<void>,
    checkCertificateStatus: () => Promise<() => void>,
    deployResource: () => Promise<void>,
    currentAction?: number,
    CNAMERecord?: {
        name: string;
        value: string;
    }
}

// wait max 5 minutes for completion of DNS propagation check
export const PROPAGATION_WAITING_TIME = 300; // seconds
// wait max 3 minutes for retrieving CNAME property of the certificate
export const CERTIFICATE_WAITING_TIME = 180; // seconds

export const getAssignResourceDomainSteps = ({
    currentStep,
    waitingTime,
    form,
    requiredMark,
    isModal,
    shouldInitiateStarterPlanTrial,
    domainCNAME,
    newDomain,
    setNewDomain,
    requestCertificateForDomain,
    checkCertificateStatus,
    deployResource,
    currentAction,
    CNAMERecord
}: AssignResourceDomainStepsProps) => {


    const customDomainStep = currentStep > 0 ?
        {
            title: "Added custom domain"
        }
        : {
            title: <Text strong>Add custom domain</Text>,
            description:
                <Fragment>
                    {
                        currentAction === 0 && waitingTime <= CERTIFICATE_WAITING_TIME &&
                        <MessageCard
                            type="neutral"
                            style={{ marginBottom: 25 }}
                            text={
                                <div>
                                    <div style={{ fontWeight: 600 }}>
                                        Requesting certificate for your custom domain...
                                    </div>
                                    <div>
                                        {/* different message if it's displayed in the Assign Domain Modal or in the Resource Settings page */}
                                        This could take a few moments, please do not {isModal ? "close this modal." : "leave this page."}
                                    </div>
                                </div>
                            }
                        />
                    }
                    {
                        waitingTime > CERTIFICATE_WAITING_TIME &&
                        <Alert
                            type="warning"
                            style={{ marginBottom: 25 }}
                            message={
                                <span>
                                    Certificate request takes more than usual!
                                    <br />
                                    <br />
                                    If you need extra help, please contact our support team on support@microtica.com.
                                </span>
                            }
                        />
                    }
                    <Form
                        form={form}
                        layout="vertical"
                        wrapperCol={{ span: 12 }}
                        requiredMark={requiredMark}
                    >
                        <Space direction="vertical" size={25} className="full-width">
                            <Space size={5}>
                                <Text strong>Add your own custom domain</Text>
                                <span style={{ color: "GrayText" }}>(Starter)</span>
                            </Space>
                            <Form.Item
                                key="customDomain"
                                name="customDomain"
                                label={<Text style={{ fontSize: 14 }}>Enter custom domain name on which your application is going to be exposed</Text>}
                                rules={[
                                    { required: true, message: "Please enter custom domain" },
                                    {
                                        pattern: new RegExp(/^(((?!-)[A-Za-z0-9-]{1,62})\.){2,}((?!-)[A-Za-z0-9-]{2,62})$/),
                                        message: "Custom domain is not valid"
                                    },
                                ]}
                                initialValue={newDomain}
                                className="no-margin"
                            >
                                <Input
                                    type={"text"}
                                    onChange={(event) => setNewDomain(event.target.value)}
                                    placeholder="myapp.domain.com"
                                    disabled={currentAction === 0}
                                    style={{ width: "135%" }}
                                />
                            </Form.Item>
                            <div className={shouldInitiateStarterPlanTrial && currentAction !== 0 ? "flex-justify-space-between flex-align-center" : "flex-justify-end"}>
                                {shouldInitiateStarterPlanTrial && currentAction !== 0 ?
                                    <Text strong>
                                        Continuing will initiate a 7 day trial of the <a href="https://microtica.com/pricing" target="_blank" rel="noreferrer">Starter plan.</a>
                                    </Text> : null}
                                <Button
                                    onClick={requestCertificateForDomain}
                                    loading={currentAction === 0}
                                    disabled={!!form.getFieldError("customDomain").length || !form.getFieldValue("customDomain")}
                                    type="primary"
                                    ghost
                                >
                                    {currentAction === 0 ? "Configuring domain..." : "Next"}
                                </Button>
                            </div>
                        </Space>
                    </Form>
                </Fragment>
        };

    const CNAMERecordStep = currentStep > 1 ?
        {
            title: "Created CNAME record",
        } :
        {
            title: <Text strong={currentStep === 1}>Create CNAME record</Text>,
            description:
                <Space direction="vertical" size={15} className="full-width">
                    <Fragment>
                        {
                            currentAction === 1 && waitingTime <= PROPAGATION_WAITING_TIME &&
                            <MessageCard
                                type="neutral"
                                style={{ marginBottom: 10 }}
                                text={
                                    <div>
                                        <div style={{ fontWeight: 600 }}>
                                            DNS propagation in progress...
                                        </div>
                                        <div>
                                            {/* different message if it's displayed in the Assign Domain Modal or in the App Settings page */}
                                            This could take a few minutes, please do not {isModal ? "close this modal." : "leave this page."}
                                        </div>
                                    </div>
                                }
                            />
                        }
                        {
                            waitingTime > PROPAGATION_WAITING_TIME &&
                            <Alert
                                type="warning"
                                style={{ marginBottom: 10 }}
                                message={
                                    <span>
                                        The propagation check takes more than usual!
                                        <br />
                                        <br />
                                        <ul>
                                            <li> Please check if you have created the CNAME records.</li>
                                            <li> If so, the DNS propagation may need more time and you should try again later. </li>
                                        </ul>
                                    </span>
                                }
                            />
                        }
                        {
                            !!CNAMERecord && !!CNAMERecord.name && !!CNAMERecord.value ?
                                <Space direction="vertical" size={35} className="full-width">
                                    <Descriptions
                                        bordered
                                        title={
                                            <Space size={4} direction="vertical" style={{ fontSize: 14 }}>
                                                <Text>Update your DNS records by adding the following CNAME records</Text>
                                                <Text style={{ fontWeight: "normal" }}>When you are done click the Next button</Text>
                                            </Space>
                                        }
                                        size={"small"}
                                    >
                                        <Descriptions.Item label="Name" span={24} labelStyle={{ width: "90px" }}><Text copyable>{CNAMERecord?.name}</Text></Descriptions.Item>
                                        <Descriptions.Item label="Type" span={24} labelStyle={{ width: "90px" }}>CNAME</Descriptions.Item>
                                        <Descriptions.Item label="Value" span={24} labelStyle={{ width: "90px" }}><Text copyable>{CNAMERecord?.value}</Text></Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions
                                        bordered
                                        size={"small"}
                                    >
                                        <Descriptions.Item label="Name" span={24} labelStyle={{ width: "90px" }}><Text copyable>{newDomain}</Text></Descriptions.Item>
                                        <Descriptions.Item label="Type" span={24} labelStyle={{ width: "90px" }}>CNAME</Descriptions.Item>
                                        <Descriptions.Item label="Value" span={24} labelStyle={{ width: "90px" }}>
                                            {
                                                domainCNAME.includes("execute-api") ?
                                                    <Alert
                                                        message="The value will become available in the Resource Outputs once the resource deployment is completed. Please do NOT forget to update the DNS record once it becomes available."
                                                        type="info"
                                                        showIcon
                                                    /> :
                                                    <Text copyable>{domainCNAME}</Text>
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Space> :
                                <Descriptions
                                    title={
                                        <Text style={{ fontSize: 14 }}>
                                            Fetching CNAME record, please wait...
                                        </Text>}
                                    size={"small"}
                                />
                        }
                        <div className="flex-justify-end">
                            <Button onClick={checkCertificateStatus} loading={currentAction === 1} type="primary" ghost>
                                {currentAction === 1 ? "Propagation check in progress..." : "Next"}
                            </Button>
                        </div>
                    </Fragment>
                </Space>
        };

    const partialDeployStep = {
        title: "Deploy",
        description:
            <Space direction="vertical" size={15}>
                <>
                    <Descriptions
                        title={<Text style={{ fontSize: 14 }}>Deploy your resource</Text>}
                        size={"small"}
                    >
                        <Descriptions.Item span={24} className="single-description-item">
                            <div>Your resource needs to be redeployed in order for domain changes to take effect. The application will be available on
                                <Button
                                    type="link"
                                    href={`https://${newDomain}`}
                                    target="_blank"
                                    style={{ paddingLeft: "3px", paddingRight: 0 }}
                                >
                                    https://{newDomain}
                                </Button>
                            </div>
                        </Descriptions.Item>
                    </Descriptions>
                    <div className="flex-justify-end">
                        <Button
                            onClick={deployResource}
                            loading={currentAction === 2}
                            type="primary"
                        >
                            Deploy
                        </Button>
                    </div>
                </>
            </Space>
    };

    const stepItems = [
        customDomainStep,
        CNAMERecordStep,
        partialDeployStep
    ];

    return stepItems;
}