import { useOutletContext, useParams } from "react-router";
import PipelineSettings from "../../components/template-modules/PipelineSettings";
import { IAppDetails } from "../../types";


const AppSettingsPipeline = () => {
    const { appName } = useParams() as { appName: string; };
    const { app } = useOutletContext<{ app: IAppDetails }>();

    return (
        <PipelineSettings pipelineId={app.pipelineId || appName} />
    );
}

export default AppSettingsPipeline;