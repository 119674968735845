import { Card, Space, Button } from "antd";
import { useState } from "react";
import ChooseAwsAccountCard from "./ChooseAwsAccountCard";
import { trackSpecCloudAccountInit } from "../backend/tracking/user-settings";
import CloudIcon from "./CloudIcon";
import ConnectGcpAccountModal from "./settings/ConnectGcpAccountModal";
import ChooseGcpAccountCard from "./ChooseGcpAccountCard";

interface GcpAccountSelectProps {
    disabled?: boolean;
    selectedAccount?: {
        gcpProjectId?: string;
        gcpRegion?: string;
        gcpZone?: string;
    };
    gcpAccounts: { id: string, name: string }[];
    errorClassName?: string;
    onSelect?: ({ gcpProjectId, gcpRegion, gcpZone }: { gcpProjectId: string; gcpRegion: string; gcpZone?: string; }) => void;
}

const GcpAccountSelect = ({
    onSelect,
    selectedAccount,
    disabled,
    errorClassName,
    gcpAccounts,
}: GcpAccountSelectProps) => {
    const [modalVisible, setModalVisible] = useState(false);
    const classNames = [disabled ? "ant-card-disabled" : "", errorClassName || ""].join(" ");

    return (
        <>
            {
                selectedAccount?.gcpProjectId || selectedAccount?.gcpRegion || selectedAccount?.gcpZone || gcpAccounts.length > 0 ?
                    <ChooseGcpAccountCard
                        classNames={classNames}
                        gcpAccounts={gcpAccounts}
                        selectedAccount={selectedAccount}
                        onSelect={onSelect}
                    />
                    :
                    <Card type="inner" className={classNames} >
                        <Card.Meta title="GCP Account" description="Configure the GCP account for the selected environment." />
                        <br />
                        <Space direction="vertical">
                            <Button
                                onClick={() => {
                                    setModalVisible(true);
                                    trackSpecCloudAccountInit("aws");
                                }}
                            >
                                <Space>
                                    <CloudIcon provider="google" /> Connect GCP Account
                                </Space>
                            </Button>
                        </Space>
                    </Card>
            }
            {
                // Workaround. The modal is not unmouting when closed so we need to unmount the whole component using 'modalVisible'
                // We have a process of periodic API calls which remain active even if when the modal closes
                modalVisible &&
                <ConnectGcpAccountModal
                    visible={modalVisible}
                    onOk={(id) => {
                        setModalVisible(false);
                        onSelect?.({ gcpProjectId: id, gcpRegion: "", gcpZone: "" });
                    }}
                    onCancel={() => setModalVisible(false)}
                />
            }
        </>
    );
}

export default GcpAccountSelect;