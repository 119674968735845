import PageContentWrapper from "../../components/PageContentWrapper";
import { Button, Card, Col, ConfigProvider, Empty, Result, Row, Select, Skeleton, Space, Tooltip } from "antd";
import { ExportOutlined, FilterOutlined, LoadingOutlined, ReloadOutlined, WarningFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { GetStageResourcesResponseComponentTypeEnum, GetStagesItem } from "@microtica/ms-engine-sdk";
import { useParams } from "react-router";
import { getEnvironmentService, getKubeService, getMonitoringService } from "../../backend";
import MonitoringErrorPlaceholder from "../../components/explanations/MonitoringErrorPlaceholder";
import MonitoringDisabledPlaceholder from "../../components/explanations/MonitoringDisabledPlaceholder";
import ComponentIcon from "../../components/ComponentIcon";
import EnableMonitoringModal from "../../components/modals/EnableMonitoringModal";
import { isResourceDeployed } from "../../components/DeploymentStatusTag";
import {
    ClusterMonitoringStatusResponseStatusEnum,
    EnableMonitoringRequestClusterTypeEnum,
    GetAlarmConfigByIdResponseOneOf,
    GetAlarmConfigByIdResponseOneOf1,
    ListClustersResponseInner
} from "@microtica/ms-monitoring-sdk";
import { ChartMetric, Dictionary, IEnvDetails } from "../../types";
import ChartWidget from "../../components/dashboards/widgets/ChartWidget";
import { GetServiceMetrics, mapMetricsToWidget } from "../../utils/metrics";
import { getMonitoringFilter, setMonitoringFilter } from "../../utils/local-storage";
import { Link } from "react-router-dom";
import ChooseAppModal from "../../components/modals/ChooseAppModal";
import { MONITORING_CLUSTER_STATUS_ORDER } from "../../enums/enums";
import MonitoringNoMetricsPlaceholder from "../../components/explanations/MonitoringNoMetricsPlaceholder";
import { getAllProjectClusters } from "./getAllProjectClusters";
import { Cluster, mapGraphNameToPixieMetricGroup, mapPixieMetricGroupToGraphName } from "./utils";
import { ActionElement } from "./utils/ActionElement";


const ProjectMonitoring = () => {
    const { projectId, envId, clusterId, namespace, appName } = useParams() as {
        projectId: string;
        envId: string;
        clusterId: string;
        namespace: string;
        appName: string;
    }
    const [recentFilter] = useState(getMonitoringFilter());
    const [loading, setLoading] = useState(true);
    const [envs, setEnvs] = useState<GetStagesItem[]>([]);
    const [selectedEnv, setSelectedEnv] = useState<string | undefined>(envId || recentFilter.envId);
    const [clusters, setClusters] = useState<Cluster[]>([]);
    const [selectedCluster, setSelectedCluster] = useState<string | undefined>(clusterId || recentFilter.clusterId);
    const [loadingClusters, setLoadingClusters] = useState(false);
    const [apps, setApps] = useState<{
        id: string;
        name: string;
        clusterId: string;
    }[]>([]);
    const [selectedApps, setSelectedApps] = useState<string[]>(
        appName ? [namespace ? `${namespace}/${appName}` : appName] : recentFilter.apps || []
    );
    const [loadingApps, setLoadingApps] = useState(false);
    const [monitoringEnabled, setMonitoringEnabled] = useState(true);
    const [monitoringError, setMonitoringError] = useState<{ status: ClusterMonitoringStatusResponseStatusEnum; message: string; }>();
    const [monitoringDashboardUrl, setMonitoringDashboardUrl] = useState<string>();
    const [showMonitoringDashboardButton, setShowMonitoringDashboardButton] = useState(false);
    const [showMonitoringModal, setShowMonitoringModal] = useState(false);
    const [enableClusterId, setEnableClusterId] = useState<string>();
    const [openAppsModal, setOpenAppsModal] = useState(false);

    const [loadingMetrics, setLoadingMetrics] = useState(false);
    const [metrics, setMetrics] = useState<Dictionary<ChartMetric>>({});

    const [timerange, setTimerange] = useState(recentFilter.timerange || "-30m");

    const [clustersStatus, setClustersStatus] = useState<ListClustersResponseInner[]>([])

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        const load = async () => {
            setLoadingClusters(true);
            const allClusters = await getAllProjectClusters({
                projectId,
                selectedEnv: selectedEnv!,
                envs,
                envId,
                clustersStatus
            });
            setClusters(allClusters);

            const activeClusters = clustersStatus
                .filter(s => ["CS_HEALTHY", "CS_UPDATING", "CS_CONNECTED"].includes(s.status))
                .sort((a, b) => MONITORING_CLUSTER_STATUS_ORDER.indexOf(a.status) - MONITORING_CLUSTER_STATUS_ORDER.indexOf(b.status));

            const newlySelectedCluster = selectedCluster && allClusters.some(c => c.id === selectedCluster) ?
                selectedCluster :
                allClusters?.[0]?.id;
            
            if (activeClusters.findIndex(c => c.stageId === selectedEnv && c.clusterId === selectedCluster) === -1) {
                setSelectedCluster(newlySelectedCluster);
                setSelectedApps([]);
            }
            setLoadingClusters(false);
        }
        if (envs.length > 0 && selectedEnv) {
            load();
        }
    }, [envs, selectedEnv]);

    useEffect(() => {
        const load = async () => {
            setLoadingApps(true);
            const cluster = clusters.find(c => c.id === selectedCluster);

            if (cluster?.type === EnableMonitoringRequestClusterTypeEnum.K8s) {
                const { data: { deployedMicroservices: apps } } = await getKubeService().getDeployedMicroservices(
                    cluster.id,
                    projectId
                );
                setApps(
                    apps.map(a => ({
                        id: `${a.namespace}/${a.name}`,
                        name: `${a.namespace}/${a.name}`,
                        clusterId: cluster.id
                    }))
                );
                setShowMonitoringDashboardButton(true);
            } else if (cluster?.type === EnableMonitoringRequestClusterTypeEnum.Ecs) {
                setApps([{
                    id: cluster.id,
                    name: cluster.id,
                    clusterId: cluster.id
                }]);
                setShowMonitoringDashboardButton(false);
            } else {
                setApps([]);
                setShowMonitoringDashboardButton(false);
            }

            setLoadingApps(false);
        }
        if (clusters.length > 0 && selectedCluster) {
            load();
        }
    }, [clusters, selectedCluster]);

    useEffect(() => {
        loadMetrics();
        setMonitoringFilter(selectedEnv!, selectedCluster, selectedApps, timerange)
    }, [apps, selectedApps, timerange]);

    const mapTimerangeToWindow = (timerange: string) => {
        if (timerange === "-30m") {
            return "1m";
        } else {
            return "5m"
        }
    }

    const load = async () => {
        try {
            const [
                { data: { stages } },
                { data: status },
            ] = await Promise.all([
                getEnvironmentService().getStages(projectId),
                getMonitoringService().listClusters(projectId)
                    .catch(error => {
                        if (error.response.status === 404) {
                            return { data: [] as ListClustersResponseInner[] }
                        } else {
                            throw error;
                        }
                    })
            ]);

            setClustersStatus(status);
            setEnvs(stages);

            const activeClusters = status
                .filter(s => ["CS_HEALTHY", "CS_UPDATING", "CS_CONNECTED"].includes(s.status))
                .sort((a, b) => MONITORING_CLUSTER_STATUS_ORDER.indexOf(a.status) - MONITORING_CLUSTER_STATUS_ORDER.indexOf(b.status));

            if (!(
                recentFilter.envId && recentFilter.clusterId &&
                activeClusters.some(c => c.stageId === recentFilter.envId && c.clusterId === recentFilter.clusterId)
            )) {
                setSelectedEnv(activeClusters.length ? activeClusters[0].stageId : stages[0].id);
            }
            if (status.length === 0) {
                return loadAllClusters(stages);
            }

            const { data: { accessToken, idToken } } = await getMonitoringService()
                .getMonitoringCredentials(projectId)
                .catch(() => ({ data: { accessToken: "", idToken: "" } }));
            if (accessToken && idToken) {
                setMonitoringDashboardUrl(
                    `https://monitoring.microtica.com/auth/callback?access_token=${accessToken}&id_token=${idToken}`
                );
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    const loadAllClusters = async (stages: GetStagesItem[]) => {
        const { data: { kuberneteses } } = await getKubeService().listKubernetes(projectId);

        setLoadingClusters(true);
        const envComponents = await Promise.all(stages.map(s =>
            getEnvironmentService()
                .getStageResources(s.id, projectId)
                .then(({ data: { resources } }) => resources
                    .filter(r => ["fargate", "kubernetes"].includes(r.component.type) && isResourceDeployed(r.status))
                    .map(r => ({
                        id: r.component.type as string === "kubernetes" ?
                            `${s.id}-${r.name}` : r.name,
                        name: r.name,
                        type: r.component.type === GetStageResourcesResponseComponentTypeEnum.Kubernetes ?
                            EnableMonitoringRequestClusterTypeEnum.K8s :
                            EnableMonitoringRequestClusterTypeEnum.Ecs,
                        envId: s.id,
                        envName: s.name!
                    }))
                ).catch(_e => [] as any)
        ));

        const envClusters = envComponents.reduce((clusters, component) => {
            clusters.push(
                ...component
            )
            return clusters;
        }, [] as Cluster[])

        setClusters([
            ...kuberneteses.reduce((clusters, cluster) => {
                const envId = cluster.id.split("-").slice(0, 2).join("-");
                const clusterName = cluster.id.replace(`${envId}-`, "");
                const component = (envClusters || [])
                    .find((c: Cluster) => c.envId === envId && c.name === clusterName);

                if (!component) {
                    clusters.push({
                        id: cluster.id,
                        name: clusterName,
                        type: EnableMonitoringRequestClusterTypeEnum.K8s,
                        envId,
                        envName: stages.find(s => s.id === envId)?.name!
                    });
                }
                return clusters;
            }, [] as Cluster[]),
            ...envComponents.reduce((clusters, component) => {
                clusters.push(
                    ...component
                )
                return clusters;
            }, [] as Cluster[])
        ]);
        setLoadingClusters(false);
    }

    const loadMetrics = async () => {
        const cluster = clusters.find(c => c.id === selectedCluster);
        if (!apps.some(a => a.clusterId === cluster?.id)) {
            return;
        }

        const appsQuery = cluster?.type === EnableMonitoringRequestClusterTypeEnum.Ecs ?
            undefined :
            selectedApps.length === 0 ? apps.map(a => a.name).join("|") : selectedApps.join("|")

        try {
            setLoadingMetrics(true);

            const [{ data: metrics }, { data: { alarmsConfigs } }] = await Promise.all([
                getMonitoringService().getServiceMetrics(
                    selectedCluster!,
                    selectedEnv!,
                    timerange,
                    projectId,
                    appsQuery,
                    undefined,
                    mapTimerangeToWindow(timerange)),
                getMonitoringService().getAlarmsConfigsByClusterId(selectedCluster!, projectId)
            ]);

            const clusterType = cluster?.type || "k8s";
            const metricsData = await mapMetricsToWidget(metrics as GetServiceMetrics, clusterType, { envId: selectedEnv!, projectId });
            const formattedAlarms = alarmsConfigs
                .reduce((acc, alarm) => {
                    if (clusterType === EnableMonitoringRequestClusterTypeEnum.Ecs) {
                        const metricName = (alarm.config as unknown as { MetricName: string }).MetricName;
                        if (acc[metricName]?.length) {
                            acc[metricName].push(alarm);
                        } else {
                            acc[metricName] = [alarm];
                        }
                    } else {
                        if (acc[mapPixieMetricGroupToGraphName[alarm.metricGroup]]?.length) {
                            acc[mapPixieMetricGroupToGraphName[alarm.metricGroup]].push(alarm);
                        } else {
                            acc[mapPixieMetricGroupToGraphName[alarm.metricGroup]] = [alarm];
                        }
                    }
                    return acc;
                }, {} as { [key: string]: (GetAlarmConfigByIdResponseOneOf1 | GetAlarmConfigByIdResponseOneOf)[] });

            if (clusterType === EnableMonitoringRequestClusterTypeEnum.K8s) {
                // append alarm data
                // Pixie alarms
                Object.keys(metricsData).forEach(metricName => {
                    if (formattedAlarms[metricName]) {
                        metricsData[metricName].alarms = formattedAlarms[metricName];
                    }
                })
            } else {
                // AWS alarms
                Object.keys(formattedAlarms)
                    .forEach(metricName => {
                        const chartName = (formattedAlarms[metricName][0] as GetAlarmConfigByIdResponseOneOf1).awsAlarmName.replace(`${formattedAlarms[metricName][0].clusterId}-`, "");
                        metricsData[chartName].alarms = formattedAlarms[metricName];
                    })
            }
            setMetrics(metricsData);
            setMonitoringEnabled(true);
            setMonitoringError(undefined);
        } catch (error: any) {
            if (error.response?.status === 404) {
                setMonitoringEnabled(false);
            } else {
                setMonitoringEnabled(true);
                setMonitoringError({
                    status: error.response?.data?.status,
                    message: error.response?.data?.statusMessage
                });
            }
        } finally {
            setLoadingMetrics(false);
        }
    }

    const handleRefresh = async () => {
        await loadMetrics()
    }

    const handleSelectCluster = (clusterId: string) => {
        setSelectedCluster(clusterId);
        setSelectedApps([]);
    }

    const MonitoringPlaceholder = () => {
        if (clustersStatus.length === 0) {
            return <div className="flex-justify-center full-width">
                <Result
                    title="Welcome to Monitoring!"
                    status="403"
                    subTitle={
                        <>
                            <div>
                                Gain insights, stay informed, and optimize performance effortlessly with our intuitive monitoring platform.
                            </div>
                            <div>
                                Enable monitoring to start tracking application metrics and insights.
                            </div>
                        </>
                    }
                    extra={<MonitoringPlaceholderCTA />}
                />
            </div>
        } else if (!monitoringEnabled && selectedCluster) {
            return <MonitoringDisabledPlaceholder
                projectId={projectId}
                envId={clusters.find(c => c.id === selectedCluster)?.envId!}
                clusterId={selectedCluster}
                clusterName={clusters.find(c => c.id === selectedCluster)?.name!}
                clusterType={clusters.find(c => c.id === selectedCluster)?.type!}
                onOk={() => loadMetrics()}
            />
        } else if (monitoringError && selectedCluster) {
            return <MonitoringErrorPlaceholder
                projectId={projectId}
                envId={clusters.find(c => c.id === selectedCluster)?.envId!}
                clusterId={selectedCluster}
                clusterName={clusters.find(c => c.id === selectedCluster)?.name!}
                clusterType={clusters.find(c => c.id === selectedCluster)?.type!}
                error={monitoringError}
            />
        } else if (monitoringEnabled && selectedCluster && apps.length === 0) {
            return <div className="flex-justify-center full-width">
                <Result
                    title="No applications deployed yet!"
                    icon={Empty.PRESENTED_IMAGE_SIMPLE}
                    subTitle="This cluster is currently idle with no deployed applications."
                    extra={
                        <Button onClick={() => setOpenAppsModal(true)}>
                            Deploy application
                        </Button>
                    }
                />
            </div>
        }
    }

    const MonitoringPlaceholderCTA = () => (
        loadingClusters ? <Skeleton /> :
            clusters.length > 0 ?
                <Space direction="vertical" className="full-width">
                    {
                        clusters.map(cluster => (
                            <Card className="text-left" key={cluster.id}>
                                <div className="flex-justify-space-between flex-align-center">
                                    <div className="flex-align-center">
                                        <ComponentIcon type={cluster.type} size="large" />
                                        <div style={{ marginLeft: "10px" }}>
                                            <div style={{ fontSize: "16px", fontWeight: 600 }} className="flex-align-center">
                                                <div>
                                                    {cluster.type as string === "ecs" ? `${cluster.name} Cluster` : cluster.name}
                                                </div>
                                            </div>
                                            <div className="gray-text">Environment: {cluster.envName}</div>
                                        </div>
                                    </div>
                                    <Button
                                        onClick={() => {
                                            setShowMonitoringModal(true);
                                            setEnableClusterId(cluster.id);
                                        }}
                                    >
                                        Enable Monitoring
                                    </Button>
                                </div>
                            </Card>
                        ))
                    }
                </Space>
                :
                <Link to={`/projects/${projectId}/templates`}>
                    <Button>Deploy an App to get started</Button>
                </Link>
    )

    const MonitoringSkeleton = () => {
        return (
            <Result
                title="Loading application metrics..."
                subTitle="Hang tight! We're fetching application monitoring metrics for you."
                icon={<LoadingOutlined />}
            />
        )
    }

    const MonitoringDashboard = () => (
        <Space direction="vertical" className="flex-justify-center">
            {/* <Link to={`/projects/${projectId}/monitoring/alarms`}>
                            <Card className="flex-justify-center" style={{ fontSize: "16px", fontWeight: 700, borderColor: "red" }}>
                                <Space>
                                    <WarningFilled style={{ color: "red" }} />
                                    <span>You have <span style={{ color: "red" }}>5</span> Alarms!</span>
                                    <RightOutlined style={{ color: "red" }} />
                                </Space>
                            </Card>
                        </Link> */}
            <Card
                title="Application metrics"
                extra={
                    showMonitoringDashboardButton &&
                    <a href={monitoringDashboardUrl} target="_blank" rel="norefferer noreferrer">
                        <Tooltip title={monitoringDashboardUrl ? undefined : "Enable monitoring for at least one Kubernetes cluster to access detailed monitoring."}>
                            <Button disabled={!monitoringDashboardUrl}>
                                Metrics Explorer <ExportOutlined />
                            </Button>
                        </Tooltip>
                    </a>
                }
                style={{ minHeight: "calc(100vh - 192px)" }}
            >
                <Row gutter={[12, 12]} className="flex-justify-space-between" style={{ marginBottom: "24px", marginLeft: 0, marginRight: 0 }}>
                    <Space>
                        <Select
                            placeholder="Filter by Environment"
                            style={{ width: 300 }}
                            value={selectedEnv}
                            onChange={value => setSelectedEnv(value)}
                            suffixIcon={<FilterOutlined />}
                            options={envs.map(env => ({
                                value: env.id,
                                label: env.name
                            }))}
                        />

                        <ConfigProvider renderEmpty={() =>
                            <div className="gray-text text-center" style={{ padding: "10px" }} >
                                Currently, there are no clusters deployed in the selected environment.
                            </div>
                        }>
                            <Select
                                placeholder="Filter by Cluster"
                                disabled={!selectedEnv || loadingClusters}
                                style={{ width: 300 }}
                                value={selectedCluster}
                                onChange={handleSelectCluster}
                                loading={loadingClusters}
                                suffixIcon={<FilterOutlined />}
                                options={clusters.map(cluster => ({
                                    value: cluster.id,
                                    label: <div className="flex-justify-space-between">
                                        <div>{cluster.name}</div>
                                        {
                                            !!cluster.status && cluster.status !== ClusterMonitoringStatusResponseStatusEnum.Healthy &&
                                            <Tooltip title={
                                                <>
                                                    <div>
                                                        {cluster.status}
                                                    </div>
                                                    <div>
                                                        {cluster.statusMessage ? cluster.statusMessage : ""}
                                                    </div>
                                                </>
                                            }>
                                                <WarningFilled style={{ color: "#faad14" }} />
                                            </Tooltip>
                                        }
                                    </div>
                                }))}
                            />
                        </ConfigProvider>

                        <ConfigProvider renderEmpty={() =>
                            <div className="gray-text text-center" style={{ padding: "10px" }} >
                                Currently, there are no applications deployed in the selected cluster.
                            </div>
                        }>
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder="Filter by Apps"
                                disabled={!selectedCluster || loadingApps}
                                style={{ width: 300 }}
                                value={selectedApps}
                                onChange={(value) => setSelectedApps(value)}
                                loading={true}
                                suffixIcon={<FilterOutlined />}
                                options={apps.map(app => ({
                                    value: app.id,
                                    label: app.name
                                }))}
                            />
                        </ConfigProvider>

                    </Space>
                    <Space>
                        <Select
                            style={{ width: 200 }}
                            value={timerange}
                            onChange={(value) => setTimerange(value)}
                            options={[
                                {
                                    value: "-30m",
                                    label: "Last 30 minutes"
                                },
                                {
                                    value: "-6h",
                                    label: "Last 6 hours"
                                },
                                {
                                    value: "-24h",
                                    label: "Last 24 hours"
                                },
                                {
                                    value: "-3d",
                                    label: "Last 3 days"
                                }
                            ]}
                        />
                        <Button icon={<ReloadOutlined />} onClick={handleRefresh} />
                    </Space>
                </Row>

                {
                    loadingMetrics && Object.entries(metrics).length === 0 ?
                        <MonitoringSkeleton /> :
                        monitoringEnabled && !monitoringError && apps.length > 0 && Object.keys(metrics).length === 0 ?
                            <MonitoringNoMetricsPlaceholder
                                projectId={projectId}
                                envId={clusters.find(c => c.id === selectedCluster)?.envId!}
                                clusterId={selectedCluster!}
                                clusterType={clusters.find(c => c.id === selectedCluster)?.type!}
                                onOk={loadMetrics}
                            /> :
                            monitoringEnabled && !monitoringError && apps.length > 0 ?
                                <Row gutter={[24, 24]} style={{ opacity: loadingMetrics ? 0.8 : 1 }}>
                                    {
                                        Object.entries(metrics).map(([key, metric]) =>
                                            <Col span={8} key={key}>
                                                <ChartWidget
                                                    title={key}
                                                    metric={metric}
                                                    action={<ActionElement
                                                        metric={metric}
                                                        metricGroup={metric.type === "k8s" ?
                                                            mapGraphNameToPixieMetricGroup[key] || key.replaceAll(" ", "_") :
                                                            key
                                                        }
                                                        selectedApps={selectedApps}
                                                        selectedCluster={selectedCluster!}
                                                    />}
                                                />
                                            </Col>
                                        )
                                    }
                                </Row> :
                                <MonitoringPlaceholder />
                }
            </Card>
        </Space >
    )

    return (
        <PageContentWrapper size="large">
            {
                loading ? <MonitoringSkeleton /> :
                    clustersStatus.length === 0 ?
                        <MonitoringPlaceholder /> :
                        <MonitoringDashboard />
            }
            {
                showMonitoringModal && enableClusterId &&
                <EnableMonitoringModal
                    projectId={projectId}
                    envId={clusters.find(c => c.id === enableClusterId)?.envId!}
                    clusterId={enableClusterId}
                    clusterName={clusters.find(c => c.id === enableClusterId)?.name!}
                    clusterType={clusters.find(c => c.id === enableClusterId)?.type!}
                    open={showMonitoringModal}
                    onOk={() => {
                        setSelectedEnv(clusters.find(c => c.id === enableClusterId)?.envId!)
                        setSelectedCluster(enableClusterId);
                        setMonitoringEnabled(true);
                        setMonitoringError(undefined);
                        setShowMonitoringModal(false);
                        load();
                    }}
                    onCancel={() => setShowMonitoringModal(false)}
                />
            }
            {
                selectedEnv && selectedCluster && openAppsModal &&
                <ChooseAppModal
                    projectId={projectId}
                    env={envs.find(e => e.id === selectedEnv) as Omit<IEnvDetails, "resources">}
                    filter={{
                        componentId: clusters.find(c => c.id === selectedCluster)?.componentId!,
                        deploymentTarget: {
                            type: "kubernetes",
                            value: clusters.find(c => c.id === selectedCluster)?.id!
                        }
                    }}
                    open={openAppsModal}
                    onCancel={() => setOpenAppsModal(false)}
                />
            }
        </PageContentWrapper>
    )
}

export default ProjectMonitoring;