import { Card, Input, Form, Button, Divider, notification, Row, Col, Space } from "antd";
import { GithubOutlined, GoogleOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
import { RequiredMark } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import Title from "antd/lib/typography/Title";
import { getUserManagementService } from "../../backend";
import { useAuth } from "../../contexts/Auth";
import { CheckCircleFilled } from "@ant-design/icons";
import MicroticaLogo from "../../assets/logo-color.svg";
import StabenfeldtLogo from "../../assets/stabenfeldt.svg";
import HyphaLogo from "../../assets/hypha.svg";
import DrNatureLogo from "../../assets/drnature.svg";
import LavaLogo from "../../assets/lava.svg";
import SentianLogo from "../../assets/sentian-ai.svg";
import VerttLogo from "../../assets/vertt.svg";
import FoundrLogo from "../../assets/foundr.svg";
import { Link, useNavigate } from "react-router-dom";
import { trackUserRegister } from "../../backend/tracking/authentication";

export default function Signup() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { isLoggedIn, loginWithExternalProvider } = useAuth();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/projects");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    async function handleSignup(values: { email: string; password: string }) {
        try {
            setLoading(true);
            const { data: { id } } = await getUserManagementService().signUpUser({
                username: values.email,
                password: values.password
            });
            setEmailSent(true);
            trackUserRegister({
                userId: id,
                username: values.email,
                type: "email"
            });
        } catch (error: any) {
            notification.warning({
                message: "Unable to sign up",
                description: error.response.data.message
            });
        } finally {
            setLoading(false);
        }
    }

    const handleSignupWithGitHub = async () => {
        await loginWithExternalProvider("github", "signup");
    }

    const handleSignupWithGoogle = async () => {
        await loginWithExternalProvider("google", "signup");
    }

    return (
        <Row gutter={24}>
            <Col xs={24} lg={14} style={{ marginBottom: 40 }}>
                <img src={MicroticaLogo} style={{ height: "40px", marginLeft: "-2px" }} alt="microtica" />
                <br />
                <br />
                <div style={{ fontSize: "30px", fontWeight: "700" }}>
                    Your apps. Deployed on AWS.
                    <br />
                    In minutes.
                </div>
                <br />
                <br />
                <Space direction="vertical">
                    <div>
                        <CheckCircleFilled style={{ color: "var(--primary-color)" }} /> Spin up infrastructure from ready-made templates
                    </div>
                    <div>
                        <CheckCircleFilled style={{ color: "var(--primary-color)" }} /> Deploy apps with git-push
                    </div>
                    <div>
                        <CheckCircleFilled style={{ color: "var(--primary-color)" }} /> Monitor apps and infrastructure state in real-time
                    </div>
                </Space>
                <br />
                <br />
                <br />
                <div>
                    <b>YOU ARE IN GOOD COMPANY</b>
                </div>
                <br />
                <Space direction="vertical" size="large">
                    <Space size="large">
                        <img src={StabenfeldtLogo} height="15px" alt="stabenfeldt" />
                        <img src={LavaLogo} height="15px" alt="lava" />
                        <img src={HyphaLogo} height="20px" alt="hypha" />
                        <img src={VerttLogo} height="15px" alt="vertt" />
                    </Space>
                    <Space size="large">
                        <img src={FoundrLogo} height="17px" alt="foundr" />
                        <img src={DrNatureLogo} height="25px" alt="drnature" />
                        <img src={SentianLogo} height="25px" alt="sentian" />
                    </Space>
                </Space>
            </Col>
            <Col xs={24} lg={10}>
                <Card className="login-card" bordered>
                    <Card.Meta title={<Title level={4}>Join 1000+ developers!</Title>} />
                    <Form
                        form={form}
                        layout="vertical"
                        requiredMark={requiredMark}
                        onFinish={handleSignup}
                        className="auth-form"
                    >
                        <div className="flex-justify-space-between providers-section">
                            <Button className="ant-btn-github provider-btn" icon={<GithubOutlined />} onClick={handleSignupWithGitHub}>
                                GitHub
                            </Button>
                            <Button className="ant-btn-google provider-btn" icon={<GoogleOutlined />} onClick={handleSignupWithGoogle}>
                                Google
                            </Button>
                        </div>
                        <Divider plain>or sign up with your email</Divider>
                        <div className="input-field-section">
                            <Form.Item
                                name="email"
                                required
                                rules={[
                                    { required: true, message: `Please enter email address` },
                                    { pattern: /^\S+@\S+\.\S+$/, message: "Please enter a valid email address" }
                                ]}
                                className="input-form-item"
                            >
                                <Input placeholder="Email" prefix={<MailOutlined />} onChange={() => setEmailSent(false)} />
                            </Form.Item>
                        </div>
                        <div className="input-field-section">
                            <Form.Item
                                name="password"
                                required
                                rules={[{ required: true, message: `Please input password` }]}
                                className="input-form-item"
                            >
                                <Input type="password" prefix={<LockOutlined />} placeholder="Password" />
                            </Form.Item>
                        </div>

                        <Button htmlType="submit" type="default" loading={loading} disabled={emailSent} block className="log-in-btn">
                            {
                                emailSent ? "Email verification sent" : "Sign up"
                            }
                        </Button>
                        <small>By signing up to Microtica, you agree to our <a href="https://microtica.com/terms-of-service/" target="_blank" rel="noreferrer"> terms of service</a>.</small>
                        <br />
                        <br />
                        <div>
                            Already have an account? <Link to="/auth/login">Log in</Link>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}