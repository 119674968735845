import { Tag } from "antd";

export type Severity = "Critical" | "High" | "Medium" | "Low" | "Error" | "Warning" | "Normal";
export interface AlarmSeverityTagProps {
    severity: Severity;
}

const AlarmSeverityTag = ({
    severity
}: AlarmSeverityTagProps) => {

    const style = { marginRight: "4px" };
    switch (severity) {
        case "Critical":
            return (<Tag color="red" style={style}>Critical</Tag>);
        case "High":
        case "Error":
            return (<Tag color="red" style={style}>High</Tag>);

        case "Medium":
        case "Warning":
            return (<Tag color="orange" style={style}>Medium</Tag>);
        case "Low":
            return (<Tag color="green" style={style}>Low</Tag>);
        case "Normal":
            return (<Tag color="green" style={style}>Normal</Tag>);
    }
}


export default AlarmSeverityTag;