export interface ChartAnnotation {
  type: string;
  start: [string, number];
  end: [string, number];
  text: {
    content: string;
    style: {
      textAlign?: "right" | "left" | "start" | "center" | "end";
      fill: string;
    };
    offsetY?: number;
  };
  style: {
    lineDash: number[];
    stroke: string;
  };
}

export const handleAnnotations = (
  maxYValue: number,
  stepValue: number,
  annotations?: ChartAnnotation[]
) => {
  return (annotations || []).map((annotation) => {
    if (maxYValue !== 0) {
      if (annotation.start[1] + stepValue > maxYValue + (stepValue * 0.1)) {
        annotation.text.offsetY = 14; // display text below line
      }
    }
    return annotation;
  });
};
