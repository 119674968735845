import Ansi from "ansi-to-react";
import Heading from "./Heading"
import { ReactNode } from "react";

interface LogsPlaceholderProps {
    title?: ReactNode;
    extra?: ReactNode;
    height?: string;
}

const FETCHING_LOGS_PLACEHOLDER = `⏳ \u001b[36mFetching logs...\u001b[0m

    `;
const LogsPlaceholder = ({ title, extra, height }: LogsPlaceholderProps) => {
    return <div className="content-logs" style={{ height }}>
        {
            title &&
            <div style={{ marginTop: -30 }}>
                <Heading
                    level={5}
                    title={
                        <div className="flex-justify-space-between flex-align-center">
                            <div style={{ color: "white" }}>
                                {title}
                            </div>
                            {extra}
                        </div>
                    }
                />
            </div>
        }
        <code style={{ lineHeight: "18px" }}>
            <Ansi>
                {FETCHING_LOGS_PLACEHOLDER}
            </Ansi>
        </code>
    </div>
}

export default LogsPlaceholder;