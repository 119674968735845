import { Button, Col, Modal, notification, Row, Skeleton, Space } from "antd";
import Heading from "../../components/Heading";
import PricingPlanCard from "../../components/billing/PricingPlanCard";
import { useNavigate, useParams } from "react-router";
import { PRICING_PLANS } from "../../enums/enums";
import PageContentWrapper from "../../components/PageContentWrapper";
import { useEffect, useState } from "react";
import { getProjectService } from "../../backend";
import { Dictionary, PaymentPlansResponse, PricingPlan } from "../../types";
import { formatAmount } from "../../contexts/Helpers/helpers";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { useCurrentProject } from "../../contexts/Project";
import { useSearchParams } from "react-router-dom";

export default function PricingPlans() {
    const navigate = useNavigate();
    const currentProject = useRecoilValue(currentProjectState);
    const { action } = useParams<{ action: "activate" | "upgrade" | "new" }>();
    const [searchParams] = useSearchParams();
    const [plans, setPlans] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState<PricingPlan>();
    const { updateCurrentProject } = useCurrentProject();

    const defaultPlan = searchParams.get("selected_plan");

    const handleSelectPlan = async (plan: PricingPlan) => {
        try {
            setSelectedPlan(plan);
            if (action === "activate") {
                await getProjectService().activateSubscription(
                    currentProject!.id,
                    {
                        paymentPlanId: plan.id,
                        priceId: plan.priceId!
                    }
                );
                await updateProjectInfo(currentProject!.id);
                navigate(`/projects/${currentProject!.id}/settings/billing`);
            } else if (action === "upgrade") {
                await getProjectService().changeProjectSubscription(
                    currentProject!.id,
                    {
                        paymentPlanId: plan.id,
                        priceId: plan.priceId!
                    }
                );
                await updateProjectInfo(currentProject!.id);
                navigate(`/projects/${currentProject!.id}/settings/billing`);
            } else {
                navigate(`/projects/new?plan=${plan.id}`);
            }
        } catch (error: any) {
            notification.error({
                message: `Error activating the ${plan.name} plan`,
                description: <>
                    {error.response.data.message}&nbsp;
                    <a href={`/projects/${currentProject!.id}/settings/billing`}>Go to Billing</a> to check your settings.
                </>
            })
        } finally {
            setSelectedPlan(undefined);
        }
    }

    const updateProjectInfo = async (projectId: string) => {
        await updateCurrentProject(projectId);
    }

    const isTrialEligible = (plan: PricingPlan) => {
        return action === "new" || (currentProject?.freeTrials && !(currentProject.freeTrials as Dictionary<boolean>)[plan.id]);
    }

    const showSelectPlanConfirm = (plan: PricingPlan) => {
        Modal.confirm({
            title: `Activate ${plan.name} plan`,
            content:
                <Space direction='vertical'>
                    <div>
                        You are about to activate the {plan.name} plan that will cost {plan.price} per month.
                    </div>
                    {
                        isTrialEligible(plan) ?
                            <div>
                                <b>7 day free trial</b> will be applied.
                            </div> :
                            <div>
                                Your credit card will be <b>charged immediately</b> because you've already used your free trial period.
                            </div>
                    }
                </Space>,
            okText: `Activate ${plan.name} plan`,
            centered: true,
            async onOk() {
                return handleSelectPlan(plan);
            }
        });
    }

    useEffect(() => {
        async function loadData() {
            const { data: { paymentPlans } } = await getProjectService().getAllPaymentPlans();
            setLoading(false);

            setPlans(
                PRICING_PLANS.map(plan => {
                    const stripePlan = (paymentPlans as PaymentPlansResponse[]).find(p => p.displayOrder === plan.displayOrder)

                    if (stripePlan) {
                        const pricing = stripePlan.pricing.find(price => price.isDefault);
                        return {
                            ...PRICING_PLANS.find(p => p.displayOrder === stripePlan.displayOrder),
                            id: stripePlan.id,
                            name: stripePlan.name,
                            description: stripePlan.description,
                            priceId: pricing!.id,
                            price: formatAmount(pricing!.price, pricing!.currency, 0),
                            limitations: stripePlan.limitations
                        }
                    } else {
                        return plan;
                    }
                })
            );
        }
        loadData();
    }, []);

    return (
        <PageContentWrapper size="large">
            <Heading
                title="Choose a pricing plan"
                description="Choose the pricing plan the suits your needs."
                level={2}
            />
            {
                loading ?
                    <Skeleton /> :
                    <>
                        <Row gutter={[48, 48]}>
                            {
                                plans.map((plan: PricingPlan) => (
                                    <Col xs={24} sm={12} md={8} key={plan.id}>
                                        <PricingPlanCard
                                            id={plan.id}
                                            name={plan.name}
                                            description={plan.description}
                                            features={plan.features}
                                            price={plan.price}
                                            trialEligible={isTrialEligible(plan)}
                                            loading={plan.id === selectedPlan?.id}
                                            action={
                                                plan.id === "CUSTOM" ?
                                                    <Button type="primary">
                                                        <a href="https://calendly.com/microtica/30min" target="_blank" rel="noreferrer">
                                                            Talk to us
                                                        </a>
                                                    </Button> :
                                                    undefined
                                            }
                                            disabled={action !== "new" && currentProject?.paymentPlan?.id === plan.id && plan.active === true}
                                            emphasize={defaultPlan === plan.id}
                                            onSelect={() => {
                                                if (action === "new") {
                                                    handleSelectPlan(plan);
                                                } else {
                                                    showSelectPlanConfirm(plan)
                                                }
                                            }}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                        <br />
                        Explore features on our <a href="https://microtica.com/pricing" target="_blank" rel="noreferrer">detailed pricing page</a>
                    </>
            }
        </PageContentWrapper>
    )
}