import Modal from "antd/lib/modal/Modal";
import { Button, Form, notification, Select, Skeleton, } from "antd";
import { useEffect, useState } from "react";
import { getCloudService, getPipelinesService } from "../../backend";
import { RequiredMark } from "antd/lib/form/Form";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { AWS_REGIONS } from "../../enums/enums";
import AwsLogo from "../../assets/aws-logo.svg";
import { Link } from "react-router-dom";
import { AddCodeCommitAccountViaTokenRequestTypeEnum } from "@microtica/ms-ap-sdk";

interface ConnectCodeCommitModalProps {
    visible: boolean;
    onOk: (accountId: string) => void;
    onCancel: () => void;
}

interface ConnectCodeCommitFormValues {
    awsAccount: string;
    awsRegion: string;
}

const ConnectCodeCommitModal = ({
    visible,
    onOk,
    onCancel
}: ConnectCodeCommitModalProps) => {
    const currentProject = useRecoilValue(currentProjectState);
    const [requiredMark] = useState<RequiredMark>('optional');
    const [form] = Form.useForm();
    const [connecting, setConnecting] = useState(false);
    const [loadingAccounts, setLoadingAccounts] = useState(true);
    const [awsAccounts, setAwsAccounts] = useState<{ id: string; name: string }[]>([]);

    useEffect(() => {
        const loadData = async () => {
            try {

                const { data: { awsAccounts } } = await getCloudService().getAwsAccounts(currentProject.id);
                setAwsAccounts(awsAccounts.map(acc => ({ id: acc.id, name: acc.accountName })));
            } catch (error) {

            } finally {
                setLoadingAccounts(false);
            }
        }
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleConnectCodeCommit = async (values: ConnectCodeCommitFormValues) => {
        try {
            setConnecting(true);
            const awsAccountId = values.awsAccount;
            const awsRegion = AWS_REGIONS.find(r => r.id === values.awsRegion)!.value;
            const { data: { gitAccountId } } = await getPipelinesService().addGitAccountViaToken(
                currentProject.id,
                {
                    type: AddCodeCommitAccountViaTokenRequestTypeEnum.Codecommit,
                    credentials: {
                        awsAccountId,
                        awsRegion
                    }
                }
            );
            onOk(gitAccountId);

            notification.success({
                message: "CodeCommit connected",
                description: `CodeCommit registry was successfully connected`,
            });
        } catch (error) {
            notification.error({
                message: "Error connecting CodeCommit",
                description: (error as any).response?.data?.message
            });
        } finally {
            setConnecting(false);
        }
    }

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            handleConnectCodeCommit(values);
        } catch (error) {

        }
    }

    return (
        <Modal
            title="Connect AWS CodeCommit"
            centered
            closable={false}
            open={visible}
            footer={[
                <Button
                    key="cancel"
                    onClick={onCancel}
                >
                    Cancel
                </Button>,
                <Button
                    type="primary"
                    key="submit"
                    loading={connecting}
                    disabled={awsAccounts.length === 0}
                    onClick={handleSubmit}
                >
                    Connect CodeCommit
                </Button>
            ]}
        >
            {
                loadingAccounts ? <Skeleton /> :
                    awsAccounts.length === 0 ?
                        <>
                            No available AWS accounts in this project!

                            <br />
                            <br />
                            To connect CodeCommit you need to <Link to={`/projects/${currentProject.id}/admin/cloud`}>Connect your AWS account</Link> first.
                        </> :
                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={{}}
                            requiredMark={requiredMark}
                        >
                            <Form.Item
                                name="awsAccount"
                                label="AWS Account"
                                required
                                rules={[{ required: true, message: 'Please select an AWS account!' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select AWS account"
                                >
                                    {
                                        awsAccounts.map(acc => (
                                            <Select.Option value={acc.id} key={acc.id}>
                                                <img src={AwsLogo} style={{ width: "14px" }} alt="aws" />  {acc.name}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="awsRegion"
                                label="AWS Region"
                                required
                                rules={[
                                    { required: true, message: 'Please select an AWS region!' }
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select region"
                                    filterOption={(input, option: any) => {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                    filterSort={(optionA, optionB) => {
                                        return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }}
                                >
                                    {
                                        AWS_REGIONS.map(region => (
                                            <Select.Option value={region.id} key={region.id}>
                                                {region.name}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Form>
            }
        </Modal>
    )
}

export default ConnectCodeCommitModal;