import { Alert, Button, Descriptions, Form, FormInstance, Input, Radio, Space, Tag } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import Text from "antd/lib/typography/Text";
import { Fragment } from "react";
import { trackAssignDomainSelected } from "../../backend/tracking/services";
import MessageCard from "../../components/cards/MessageCard";

interface AssignAppDomainStepsProps {
    currentStep: number,
    waitingTime: number,
    form: FormInstance<any>,
    requiredMark: RequiredMark,
    isModal: boolean,
    app: {
        name: string,
        namespace: string,
        lastDeploymentIncludesIngressSetup: boolean,
        clusterId: string
    },
    shouldInitiateStarterPlanTrial: boolean,
    newDomain: string,
    setNewDomain: React.Dispatch<React.SetStateAction<string>>,
    useCustomDomain: boolean;
    setUseCustomDomain: React.Dispatch<React.SetStateAction<boolean>>,
    handleAddIngressAndCertManager: () => Promise<void>,
    handleRedeployApp: () => Promise<void>,
    handleApplyNewTemplateAndDeploy: () => Promise<void>,
    checkDnsRecord: () => void,
    currentAction?: number,
    ingressHostname?: string,
    ingressIP?: string
}

// wait max 5 minutes for completion of DNS propagation check
export const PROPAGATION_WAITING_TIME = 300; // seconds
// wait max 5 and a half minutes for completion of domain setup
export const DOMAIN_SETUP_WAITING_TIME = 330; // seconds
// backend throws timeout after 30 seconds
export const BACKEND_TIMEOUT_IN_MS = 30000;
export const AXIOS_TIMEOUT_ERROR_CODE = "ECONNABORTED";

export const getAssignAppDomainSteps = ({
    currentStep,
    waitingTime,
    form,
    requiredMark,
    isModal,
    app,
    shouldInitiateStarterPlanTrial,
    newDomain,
    setNewDomain,
    useCustomDomain,
    setUseCustomDomain,
    handleAddIngressAndCertManager,
    handleRedeployApp,
    handleApplyNewTemplateAndDeploy,
    checkDnsRecord,
    currentAction,
    ingressHostname,
    ingressIP
}: AssignAppDomainStepsProps) => {


    const chooseDomainStep = currentStep > 0 ?
        {
            title: "Chose domain"
        }
        : {
            title: <Text strong>Choose domain</Text>,
            description:
                <Fragment>
                    {
                        currentAction === 0 && waitingTime <= DOMAIN_SETUP_WAITING_TIME &&
                        <MessageCard
                            type="neutral"
                            style={{ marginBottom: 25 }}
                            text={
                                <div>
                                    <div style={{ fontWeight: 600 }}>
                                        Setting up domain...
                                    </div>
                                    <div>
                                        {/* different message if it's displayed in the Assign Domain Modal or in the App Settings page */}
                                        The setup could take up to {Math.floor(DOMAIN_SETUP_WAITING_TIME / 60)} mins, please do not {isModal ? "close this modal." : "leave this page."}
                                    </div>
                                </div>
                            }
                        />
                    }
                    {
                        waitingTime > DOMAIN_SETUP_WAITING_TIME &&
                        <Alert
                            type="warning"
                            style={{ marginBottom: 25 }}
                            message={
                                <span>
                                    Domain setup takes more than usual!
                                    <br />
                                    <br />
                                    Check the pods status in your cluster. If there are pending pods you should check the number of running pods and the maximum number of pods per node.
                                    <br />
                                    <br />
                                    If you need extra help, please contact our support team on support@microtica.com.
                                </span>
                            }
                        />
                    }
                    <Form
                        form={form}
                        layout="vertical"
                        wrapperCol={{ span: 12 }}
                        requiredMark={requiredMark}
                    >
                        {/* {(values, formInstance) => ( */}
                        <Space direction="vertical" size={25} className="full-width">
                            <Radio.Group
                                onChange={(e) => {
                                    setUseCustomDomain(e.target.value);
                                    // microtica domain
                                    if (!e.target.value) {
                                        setNewDomain(`${app.name}-${app.namespace}.microtica.rocks`);
                                        form.setFieldsValue({ customDomain: undefined })
                                    } else if (form.getFieldValue("customDomain")) {
                                        setNewDomain(form.getFieldValue("customDomain"))
                                    }
                                }}
                                value={useCustomDomain}
                            >
                                <Space direction="vertical" size={40}>
                                    <Radio value={false}>
                                        <Space direction="vertical" size={2}>
                                            <Space size={5}>
                                                <Text strong>Use Microtica domain</Text>
                                                <span style={{ color: "GrayText" }}>(Free)</span>
                                            </Space>
                                            <Text style={{ fontSize: 14 }}>A microtica.rocks subdomain will be dedicated for your application</Text>
                                            <span></span>
                                            <Tag className="no-margin" style={{ padding: "5px 11px", fontSize: 14 }}>https://{app.name}-{app.namespace}.microtica.rocks</Tag>
                                        </Space>
                                    </Radio>
                                    <Radio value={true}>
                                        <Space direction="vertical" size={2}>
                                            <Space size={5}>
                                                <Text strong>Add your own custom domain</Text>
                                                <span style={{ color: "GrayText" }}>(Starter)</span>
                                            </Space>
                                            <Form.Item
                                                key="customDomain"
                                                name="customDomain"
                                                label={<Text style={{ fontSize: 14 }}>Enter custom domain name on which your application is going to be exposed</Text>}
                                                rules={[
                                                    { required: true, message: "Please enter custom domain" },
                                                    {
                                                        pattern: new RegExp(/^(((?!-)[A-Za-z0-9-]{1,62})\.){2,}((?!-)[A-Za-z0-9-]{2,62})$/),
                                                        message: "Custom domain is not valid"
                                                    }
                                                ]}
                                                initialValue={newDomain !== `${app.name}-${app.namespace}.microtica.rocks` ? newDomain : undefined}
                                                className="no-margin"
                                            >
                                                <Input
                                                    type={"text"}
                                                    onChange={(event) => setNewDomain(event.target.value)}
                                                    placeholder="myapp.domain.com"
                                                    disabled={currentAction === 0 || !useCustomDomain}
                                                    addonBefore="https://"
                                                    style={{ width: "135%" }}
                                                />
                                            </Form.Item>
                                        </Space>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                            <div className={shouldInitiateStarterPlanTrial && useCustomDomain && currentAction !== 0 ? "flex-justify-space-between flex-align-center" : "flex-justify-end"}>
                                {shouldInitiateStarterPlanTrial && useCustomDomain && currentAction !== 0 ?
                                    <Text strong>
                                        Continuing will initiate a 7 day trial of the <a href="https://microtica.com/pricing" target="_blank" rel="noreferrer">Starter plan</a>.
                                    </Text> : null}
                                <Button
                                    onClick={() => {
                                        handleAddIngressAndCertManager();
                                        trackAssignDomainSelected({
                                            serviceName: app.name,
                                            clusterName: app.clusterId,
                                            domain: newDomain
                                        });
                                    }}
                                    loading={currentAction === 0}
                                    disabled={useCustomDomain && (!!form.getFieldError("customDomain").length || !form.getFieldValue("customDomain"))}
                                    type="primary"
                                    ghost
                                >
                                    {currentAction === 0 ? "Configuring domain..." : "Next"}
                                </Button>
                            </div>
                        </Space>
                        {/* )} */}
                    </Form>
                </Fragment>
        };

    const createCnameRecordStep = currentStep > 1 ?
        {
            title: `Created ${ingressIP ? "A" : "CNAME"} record`,
        } :
        {
            title: <Text strong={currentStep === 1}>Create {ingressIP ? "A" : "CNAME"} record</Text>,
            description:
                <Space direction="vertical" size={15}>
                    <Fragment>
                        {
                            currentAction === 1 && waitingTime <= PROPAGATION_WAITING_TIME &&
                            <MessageCard
                                type="neutral"
                                style={{ marginBottom: 10 }}
                                text={
                                    <div>
                                        <div style={{ fontWeight: 600 }}>
                                            DNS propagation in progress...
                                        </div>
                                        <div>
                                            {/* different message if it's displayed in the Assign Domain Modal or in the App Settings page */}
                                            This could take a few minutes, please do not {isModal ? "close this modal." : "leave this page."}
                                        </div>
                                    </div>
                                }
                            />
                        }
                        {
                            waitingTime > PROPAGATION_WAITING_TIME &&
                            <Alert
                                type="warning"
                                style={{ marginBottom: 10 }}
                                message={
                                    <span>
                                        The propagation check takes more than usual!
                                        <br />
                                        <br />
                                        {useCustomDomain ?
                                            <ul>
                                                <li> Please check if you have created the CNAME/A record.</li>
                                                <li> If so, the DNS propagation may need more time and you should try again later. </li>
                                            </ul> :
                                            <ul>
                                                <li> The DNS propagation may need more time and you should try again later. </li>
                                            </ul>
                                        }
                                    </span>
                                }
                            />
                        }
                        {useCustomDomain ?
                            <Descriptions
                                bordered
                                title={
                                    <Space size={4} direction="vertical" style={{ fontSize: 14, whiteSpace: "pre-wrap" }}>
                                        <Text>{`Update your DNS records for the ${newDomain.split(".").slice(-2).join(".")} domain by adding the following ${ingressIP ? "A" : "CNAME"} record`}</Text>
                                        <Text style={{ fontWeight: "normal" }}>When you are done click the Next button</Text>
                                    </Space>
                                }
                                size={"small"}
                            >
                                <Descriptions.Item label="Name" span={24}><Text copyable>{newDomain.split(".").slice(0, -2).join(".") || newDomain}</Text></Descriptions.Item>
                                <Descriptions.Item label="Type" span={24}>{ingressHostname ? "CNAME" : "A"}</Descriptions.Item>
                                <Descriptions.Item label="Value" span={24}><Text copyable>{ingressIP || ingressHostname}</Text></Descriptions.Item>
                            </Descriptions> :
                            <Descriptions
                                title={
                                    <Text style={{ fontSize: 14 }}>
                                        A CNAME record for your Microtica domain was created
                                    </Text>}
                                size={"small"}
                            >
                                {
                                    currentAction === 1 ? null :
                                        <Descriptions.Item span={24} className="single-description-item">
                                            <div>Click the Next button to start the propagation check</div>
                                        </Descriptions.Item>
                                }
                            </Descriptions>
                        }
                        <div className="flex-justify-end">
                            <Button onClick={checkDnsRecord} loading={currentAction === 1} type="primary" ghost>
                                {currentAction === 1 ? "Propagation check in progress..." : "Next"}
                            </Button>
                        </div>
                    </Fragment>
                </Space>
        };

    const deployApplicationStep = {
        title: "Deploy application",
        description:
            <Space direction="vertical" size={15}>
                <>
                    <Descriptions
                        title={
                            <Text style={{ fontSize: 14 }}>
                                {
                                    app.lastDeploymentIncludesIngressSetup ?
                                        "Restart your application" :
                                        "Update Microtica files in Git"
                                }
                            </Text>}
                        size={"small"}
                    >
                        <Descriptions.Item span={24} className="single-description-item">
                            {
                                app.lastDeploymentIncludesIngressSetup ?
                                    <div>Your app needs to be restarted in order for domain changes to take effect. The application will be available on <Tag>https://{newDomain}</Tag></div> :
                                    <div>If you proceed with this step, we will automatically push the necessary Microtica file changes to Git, which will lead to exposing your application on
                                        <Button
                                            type="link"
                                            href={`https://${newDomain}`}
                                            target="_blank"
                                            style={{ paddingLeft: "3px", paddingRight: 0 }}
                                        >
                                            https://{newDomain}
                                        </Button>
                                    </div>
                            }
                        </Descriptions.Item>
                    </Descriptions>
                    <div className="flex-justify-end">
                        <Button
                            onClick={
                                app.lastDeploymentIncludesIngressSetup ?
                                    handleRedeployApp :
                                    handleApplyNewTemplateAndDeploy
                            }
                            loading={currentAction === 2}
                            type="primary"
                        >
                            {app.lastDeploymentIncludesIngressSetup ? "Restart" : "Proceed"}
                        </Button>
                    </div>
                </>
            </Space>
    };

    const stepItems = [
        chooseDomainStep,
        createCnameRecordStep,
        deployApplicationStep
    ];

    return stepItems;
}