import { Alert, Avatar, Button, Card, Form, Modal, notification, Select, Skeleton, Space, Tag, Typography } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import ComponentConfigFormItems from "../ComponentConfigFormItems";
import { getCloudService, getEnvironmentService } from "../../backend";
import { ComponentConfig, ComponentSchemaInputProperties, ComponentVersion, Dictionary, IEnvDetails } from "../../types";
import { PipelineBuildDetails } from "@microtica/ms-ap-sdk";
import { ClockCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { useNavigate, useParams } from "react-router";
import { jaroWrinkerAlgorithm } from "../../utils/jaro-winker-algorithm";
import { trackQuickDeploy } from "../../backend/tracking/deployment";
import { useSetRecoilState } from "recoil";
import { linkAwsAccountToStage } from "../../utils/aws-accounts";
import { GetStageResourcesResponseResources } from "@microtica/ms-engine-sdk";
import ConnectAwsAccountModal from "../settings/ConnectAwsAccountModal";
import ChooseAwsAccountModal from "./ChooseAwsAccountModal";
import { calculateSubsequentDeployButtonClicks } from "../../utils/subsequent-button-clicks";
import { deployEnvironmentButtonState } from "../../recoil/payment-plan-required";
import GitCommitLink from "../GitCommitLink";
import ConnectGcpAccountModal from "../settings/ConnectGcpAccountModal";
import ChooseGcpAccountModal from "./ChooseGcpAccountModal";

interface DeployResourceModalProps {
    env: IEnvDetails;
    resource: GetStageResourcesResponseResources & { configurations: ComponentConfig[] };
    visible: boolean;
    stageResourcesOutputs: Dictionary<string[]>;
    notDeployedResources: string[];
    onOk: () => void;
    onCancel: () => void;
}
const DeployResourceModal = ({
    env,
    resource,
    visible,
    stageResourcesOutputs,
    notDeployedResources,
    onOk,
    onCancel
}: DeployResourceModalProps) => {
    const navigate = useNavigate();
    const { projectId, envId } = useParams() as { projectId: string; envId: string; };
    const [requiredMark] = useState<RequiredMark>('optional');
    const [form] = Form.useForm();
    const [required, setRequired] = useState<string[]>([]);
    const [componentVersions, setComponentVersions] = useState<ComponentVersion[]>([]);
    const [componentVersion, setComponentVersion] = useState<ComponentVersion>();
    const [inputProps, setInputProps] = useState<ComponentSchemaInputProperties>({});
    const [mappedResourcesOutputs, setMappedResourcesOutputs] = useState<{ [key: string]: { name: string; suggestionScore: number; }[] }>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSchema, setLoadingSchema] = useState<boolean>(true);
    const [references, setReferences] = useState<string[]>((resource.configurations || []).filter(val => val.reference).map(val => val.key));
    const [deployingResource, setDeployingResource] = useState<boolean>(false);
    const [connectAwsAccountModalVisible, setConnectAwsAccountModalVisible] = useState(false);
    const [chooseAwsAccountModal, setChooseAwsAccountModal] = useState(false);
    const [connectGcpAccountModalVisible, setConnectGcpAccountModalVisible] = useState(false);
    const [chooseGcpAccountModal, setChooseGcpAccountModal] = useState(false);
    const [cloudAccountId, setCloudAccountId] = useState<string>();
    const [referencedResources, setReferencedResources] = useState<string[]>([]);
    const { Text } = Typography;

    const setDeployEnvironmentButton = useSetRecoilState(deployEnvironmentButtonState);


    useEffect(() => {
        if (resource) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resource]);

    useEffect(() => {
        // fetch schema for version when version changes
        if (componentVersion) {
            fetchSchema(componentVersion.name!);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentVersion]);

    useEffect(() => {
        // set the form's intiial values every time the input props change
        // this is due to the fact that the initial values are fetched asynchronously
        form.setFieldsValue(initialValues);
        updateReferencedResources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputProps]);

    useEffect(() => {
        updateReferencedResources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [references]);

    const updateReferencedResources = () => {
        setReferencedResources([
            ...new Set(references
                .map(reference => form.getFieldValue(reference)?.split(".")?.[0] || "")
                .filter(reference => reference !== "" && notDeployedResources.includes(reference))
            )
        ]);
    }

    const loadData = async () => {
        setLoading(true);
        await fetchComponentVersions();
        setLoading(false);
    }

    const fetchComponentVersions = async () => {
        try {
            const { data: versions } = await getEnvironmentService().getComponentBuilds(resource.component.id, projectId);

            const componentVersions: ComponentVersion[] = (versions.builds as unknown as PipelineBuildDetails[] || [])
                .sort((a, b) => {
                    if ((new Date(a.stopDate!) as any) > (new Date(b.stopDate!) as any)) return -1;
                    if ((new Date(a.stopDate!) as any) < (new Date(b.stopDate!) as any)) return 1;
                    return 0;
                })
                .sort((a) => a.name === "latest" ? -1 : 0)
                .filter(build => build.status === "SUCCEEDED")
                .map(build => ({
                    name: build.id,
                    date: build.stopDate!,
                    commit: build.metadata.commit,
                    repoUrl: build.metadata.repository
                }));

            setComponentVersions(componentVersions);
            const currentComponentVersion = componentVersions.find(version => version.name === resource.component?.version!);
            setComponentVersion(currentComponentVersion);
        } catch (error) {
            notification.error({
                message: "Error fetching versions",
                description: (error as any).response!.data!.message
            });
        }
    }

    const fetchSchema = async (version: string) => {
        setLoadingSchema(true);

        const { data: { schema } } = await getEnvironmentService().getComponent(resource.component.id, projectId, version);
        if (!schema) {
            setRequired([]);
            setInputProps({});
            setLoadingSchema(false);
            return;
        }
        if (schema.properties.inputs.required?.length) {
            setRequired(schema.properties.inputs.required);
        }
        if (schema.properties.inputs.properties) {
            setInputProps(schema.properties.inputs.properties as ComponentSchemaInputProperties);

            if (!!stageResourcesOutputs && Object.keys(stageResourcesOutputs).length && Object.keys(schema.properties.inputs.properties).length) {
                const mappedOutputs: { [key: string]: { name: string; suggestionScore: number }[] } = {};

                Object.entries(schema.properties.inputs.properties).forEach(([key]) => {
                    Object.keys(stageResourcesOutputs)
                        .filter(resourceName => resourceName !== resource.name)
                        .forEach(resourceName => {
                            const outputs = stageResourcesOutputs[resourceName] || [];
                            mappedOutputs[key] = (mappedOutputs[key] || []).concat(
                                outputs.map(output => ({
                                    name: `${resourceName}.${output}`,
                                    suggestionScore: jaroWrinkerAlgorithm(key, output)
                                }))
                            )
                        })
                    if (mappedOutputs[key]) {
                        mappedOutputs[key].sort((a, b) => b.suggestionScore - a.suggestionScore);
                    }
                })
                setMappedResourcesOutputs(mappedOutputs);
            }

        }
        setLoadingSchema(false);
    }

    const handleDeploy = async () => {
        try {
            setDeployingResource(true);
            const values = await form.validateFields();

            if (
                (env.cloudProvider === "aws" && env.awsAccountId && env.region) ||
                (env.cloudProvider === "google" && env.gcpProjectId && env.gcpRegion && env.gcpZone)
            ) {
                // Cloud account is connected to the environment
                await handleUpdateResource(values);
                await getEnvironmentService().deployStage(
                    envId,
                    projectId,
                    {
                        partial: true,
                        resourceVersionOverrides: {
                            [resource.name]: values.version
                        }
                    }
                );
                onOk();
                notification.success({
                    message: "Deployment initiated",
                    description: "It could take a few moments for the changes to take effect.",
                    icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
                });

                trackQuickDeploy(envId);

                setDeployEnvironmentButton((prevState) => {
                    return calculateSubsequentDeployButtonClicks(prevState);
                });
                setTimeout(() => {
                    navigate(`/projects/${projectId}/pipelines`);
                }, 3000);
            } else {
                if (env.cloudProvider === "aws") {
                    const { data: { awsAccounts } } = await getCloudService().getAwsAccounts(projectId);
                    if (!!awsAccounts.length) {
                        // project has AWS accounts, but none of them is connected to the stage
                        setChooseAwsAccountModal(true);
                    } else {
                        // there is no AWS account on the project
                        setConnectAwsAccountModalVisible(true);
                    }
                } else if (env.cloudProvider === "google") {
                    const { data: { gcpAccounts } } = await getCloudService().getGcpAccounts(projectId);
                    if (!!gcpAccounts.length) {
                        // project has GCP accounts, but none of them is connected to the stage
                        setChooseGcpAccountModal(true);
                    } else {
                        // there is no GCP account on the project
                        setConnectGcpAccountModalVisible(true);
                    }
                }
            }
        } catch (error: any) {
            notification.error({
                message: "Error while trying to deploy resource",
                description: error?.response?.data?.message ? error?.response?.data?.message : "We encountered an error while trying to deploy this resource. Please try again."
            });
        } finally {
            setDeployingResource(false);
        }
    }

    const handleUpdateResource = async (values: { name: string; version: string, [key: string]: any }) => {
        // isolate and map the resource's environment vars
        const { name, version, ...envVars } = values;

        const configurations = Object.entries(envVars)
            .filter(([_key, value]) => value !== "" && value !== undefined)
            .map(([key, value]) => ({
                key,
                value: Array.isArray(value) ? value.join(",") : value.toString(),
                sensitive: !!inputProps[key].sensitive,
                reference: references.includes(key)
            }));

        await getEnvironmentService().updateResource(
            envId,
            resource.name,
            projectId,
            {
                componentVersion: values.version,
                configurations
            }
        );
    }

    const initialValues = Object.entries(inputProps).reduce((acc, [key, value]) => {
        // Get the initial value from the user input, otherwise get the default value from the schema
        const val = resource.configurations?.find((v: ComponentConfig) => v.key === key)?.value || value.default || "";
        const defaultValue = value.type === "array" ?
            (val as string).split(",") :
            val;
        acc[key] = defaultValue;
        return acc;
    }, {} as Dictionary<string | string[] | number>);

    return (
        <>
            <Modal
                title={`Deploy ${resource.name} Component`}
                centered
                open={visible}
                width="700px"
                footer={[
                    <Space>
                        <Button
                            key="cancel"
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            disabled={loadingSchema}
                            loading={deployingResource}
                            onClick={handleDeploy}
                        >
                            Deploy
                        </Button>
                    </Space>
                ]}
                onCancel={onCancel}
            >
                {!loading ?
                    <Form
                        form={form}
                        layout="vertical"
                        requiredMark={requiredMark}
                    >
                        <Card type="inner" style={{ marginTop: 20 }}>
                            <Card.Meta
                                title="Version"
                                description="Configure the component version to be deployed."
                                style={{ marginBottom: 4 }}
                            />
                            <br />
                            <Form.Item
                                key="version"
                                name="version"
                                required={true}
                                rules={[
                                    { required: true, message: `Please select version!` }
                                ]}
                                initialValue={componentVersion ? componentVersion.name : undefined}
                            >
                                <Select
                                    className="ant-select-deploy-versions"
                                    onChange={(value) => setComponentVersion(componentVersions.find(({ name }) => name === value))}
                                >
                                    {
                                        componentVersions.map(componentVersion => (
                                            <Select.Option value={componentVersion.name} key={componentVersion.name} className=" flex-align-center">
                                                <div className="flex-justify-space-between flex-align-center">
                                                    <Text ellipsis style={{ marginRight: "10px" }}>
                                                        {componentVersion.commit?.message}
                                                    </Text>
                                                    <Text className="gray-text">
                                                        <ClockCircleOutlined /> {moment(componentVersion.date).fromNow()}
                                                    </Text>
                                                </div>
                                                <Text style={{ fontSize: 12, marginRight: "10px" }} >
                                                    <GitCommitLink
                                                        version={componentVersion.commit.version}
                                                        commitType={componentVersion.commit.type}
                                                        repoUrl={componentVersion.repoUrl}
                                                        hideIcon
                                                        branch={componentVersion.commit.name}
                                                    />
                                                </Text>
                                                <div>
                                                    <Text style={{ fontSize: 12 }} ellipsis>
                                                        <Avatar src={componentVersion.commit?.user?.avatar} style={{ width: "15px", height: "15px" }} />&nbsp;
                                                        {componentVersion.commit?.user?.name}
                                                    </Text>
                                                </div>
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Card>

                        <br />

                        <Card type="inner">
                            <Card.Meta
                                title="Configuration"
                                description="Configure component properties"
                            />
                            <br />
                            <ComponentConfigFormItems
                                formItems={inputProps}
                                required={required}
                                setFieldsValue={form.setFieldsValue}
                                getFieldValue={form.getFieldValue}
                                references={references}
                                handleUpdateReferences={setReferences}
                                loading={loadingSchema}
                                emptyText={<Tag color="default">No environment variables</Tag>}
                                mappedResourcesOutputs={mappedResourcesOutputs}
                                onReferenceChange={updateReferencedResources}
                                filterCategories={["all"]}
                            />
                        </Card>
                        {
                    !!referencedResources.length &&
                    <Space direction="vertical">
                        <br />
                        <Alert
                            message={
                                <div>
                                    If you proceed to <b>Deploy</b> this resource, the following inactive resources will also be deployed since they are referenced in the <i>Resource Configuration</i> section:
                                    <b> {referencedResources.join(", ")}.</b>
                                </div>
                            }
                            type="info"
                        />
                    </Space>
                }
            </Form> :
            <Skeleton />
                }
        </Modal >

            {/* AWS account */ }
    {
        // Workaround. The modal is not unmouting when closed so we need to unmount the whole component using 'modalVisible'
        // We have a process of periodic API calls which remain active even if when the modal closes
        connectAwsAccountModalVisible &&
            <ConnectAwsAccountModal
                visible={connectAwsAccountModalVisible}
                onOk={async (newAccountId) => {
                    setConnectAwsAccountModalVisible(false);
                    setCloudAccountId(newAccountId);
                    setChooseAwsAccountModal(true);
                }}
                onCancel={() => setConnectAwsAccountModalVisible(false)}
            />
    }
    {
        // This modal becomes active when the user connects an AWS account and now needs to select a region for the stage
        chooseAwsAccountModal &&
            <ChooseAwsAccountModal
                selectedAccount={{ accountId: cloudAccountId, region: undefined }}
                visible={chooseAwsAccountModal}
                onCancel={() => setChooseAwsAccountModal(false)}
                onOk={async ({ accountId, region }: { accountId: string, region: string }) => {
                    await linkAwsAccountToStage({
                        envId,
                        projectId,
                        awsAccountId: accountId,
                        awsRegion: region
                    });
                    setChooseAwsAccountModal(false);
                    handleDeploy();
                }}
            />
    }

    {/* GCP account */ }
    {
        connectGcpAccountModalVisible &&
            <ConnectGcpAccountModal
                visible={connectGcpAccountModalVisible}
                onOk={async (newAccountId) => {
                    setConnectGcpAccountModalVisible(false);
                    setCloudAccountId(newAccountId);
                    setChooseGcpAccountModal(true);
                }}
                onCancel={() => setConnectGcpAccountModalVisible(false)}
            />
    }
    {
        // This modal becomes active when the user connects an GCP account and now needs to select a region for the stage
        chooseGcpAccountModal &&
            <ChooseGcpAccountModal
                env={env}
                visible={chooseGcpAccountModal}
                onCancel={() => setChooseGcpAccountModal(false)}
                onOk={() => {
                    setChooseGcpAccountModal(false);
                    handleDeploy();
                }}
            />
    }
        </>
    )
}

export default DeployResourceModal;