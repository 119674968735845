import { trackEvent } from "./index";

export const trackTemplateRequest = (template: string, email?: string) => {
    trackEvent("template_request", {
        template,
        email
    });
};

export const trackTemplateSearch = (query: string, matches: number) => {
    trackEvent("template_search", {
        template: query,
        matches
    });
};

export const trackTemplateGitRepoCreate = (template: string) => {
    trackEvent("template_git_repo_create", {
        template
    });
};

export const trackTemplateGitRepoImport = (template: string) => {
    trackEvent("template_git_repo_import", {
        template
    });
};

export const trackTemplateKubernetesSave = (template: string) => {
    trackEvent("template_kubernetes_save", {
        template
    });
};

export const trackTemplateKubernetesRequestShared = (template: string) => {
    trackEvent("template_kubernetes_request_shared", {
        template
    });
};

export const trackTemplateKubernetesAwsCreate = (template: string) => {
    trackEvent("template_kubernetes_aws_create", {
        template
    });
};

export const trackTemplateKubernetesConnectExisting = (template: string) => {
    trackEvent("template_kubernetes_connect_existing", {
        template
    });
};

export const trackTemplateEnvironmentSelect = (template: string) => {
    trackEvent("template_environment_select", {
        template
    });
};

export const trackTemplateConfigureSave = (template: string) => {
    trackEvent("template_configure_save", {
        template
    });
};

export const trackDeployAppClicked = () => {
    trackEvent("deploy_app_clicked");
};