import { Button, Card, Divider, Form, Input, notification, Space } from "antd";
import { useEffect, useState } from "react";
import { getNotificationService } from "../../backend";
import { CreateSlackIntegrationRequestTypeEnum, IntegrationType } from "@microtica/ms-notification-sdk";
import { useDoubleConfigurationModal } from "../../contexts/Modal";
import { SlackSquareOutlined } from "@ant-design/icons";
import { trackSlackAdded, trackSlackRemoved } from "../../backend/tracking/user-settings";
import ExplanationButton from "../../components/explanations/ExplanationButton";
import SlackIntegrationExplanation from "../../components/explanations/SlackIntegrationExplanation";
import { useParams } from "react-router-dom";

export default function ProjectIntegrationsSlack() {
    const [form] = Form.useForm();
    const { projectId } = useParams();
    const [connectingSlack, setConnectingSlack] = useState(false);
    const [enableDisconnect, setEnableDisconnect] = useState(false);
    const { open: openConfirmModal } = useDoubleConfigurationModal();

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadData() {
        const { data: integrations } = await getNotificationService().listIntegrations(projectId!) as any;

        if (integrations.length > 0) {
            setEnableDisconnect(true);
            // Strangely but using useState did not set the webhook URL in the form
            // I had to set the form value direclty to solve the issue
            form.setFieldsValue({ webhookUrl: integrations[0].webhookUrl })
        } else {
            setEnableDisconnect(false);
        }
    }

    async function handleConnectSlack(values: { webhookUrl: string }) {
        try {
            setConnectingSlack(true);
            await getNotificationService().addIntegration(
                projectId!,
                {
                    type: CreateSlackIntegrationRequestTypeEnum.Slack,
                    webhookUrl: values.webhookUrl
                }
            );
            await loadData();
            notification.success({
                message: "Slack integration was successfully added",
                description: "We've sent a test message on your Slack channel."
            });
            trackSlackAdded();
        } catch (error: any) {
            notification.error({
                message: "Error connecting with Slack",
                description: error.response.data.message
            });
        } finally {
            setConnectingSlack(false);
        }
    }

    async function handleDisconnectSlack() {
        try {
            setConnectingSlack(true);
            await getNotificationService().deleteIntegration(
                projectId!,
                IntegrationType.Slack
            );
            await loadData();
            form.resetFields();
            notification.success({
                message: "Slack integration successfully disconnected"
            });
            trackSlackRemoved();
        } catch (error: any) {
            notification.error({
                message: "Error disconnecting from Slack",
                description: error.response.data.message
            });
        } finally {
            setConnectingSlack(false);
        }
    }

    function showDisconnectConfirm(confirmation: string) {
        openConfirmModal({
            title: "Disconnect Slack?",
            description: <>
                By disconnecting the Slack integration you will stop receiving build and deployment messages on your Slack channel.
            </>,
            confirmation,
            okText: "Disconnect",
            cancelText: 'Cancel',
            onOk: () => {
                return handleDisconnectSlack();
            }
        })
    }

    return (
        <Card>
            <Card.Meta
                title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Space>
                            <SlackSquareOutlined style={{ fontSize: 30 }} /> Slack
                        </Space>
                        <ExplanationButton
                            content={<SlackIntegrationExplanation />}
                        />
                    </div>
                }
                description="Integrating with Slack will allow you to receive build and deployment notifications directly in your Slack channel."
            />

            <Divider />

            <Form
                form={form}
                onFinish={handleConnectSlack}
            >
                <Form.Item
                    name="webhookUrl"
                    required
                    rules={[
                        { required: true, message: "Please enter Slack webhook URL" },
                        { type: "url", message: "Provided value is not a valid URL" }
                    ]}
                >
                    <Input placeholder="Slack webhook URL" disabled={enableDisconnect} />
                </Form.Item>
                <div className="flex-justify-space-between flex-align-center">
                    <div>
                        Learn how to <a href="https://api.slack.com/messaging/webhooks" target="_blank" rel="noreferrer">Create Slack webhook</a>
                    </div>
                    <div>
                        {
                            enableDisconnect ?
                                <Button danger type="default" onClick={() => showDisconnectConfirm("disconnect")}>
                                    Disconnect
                                </Button> :
                                <Button htmlType="submit" type="primary" loading={connectingSlack}>
                                    Connect
                                </Button>
                        }
                    </div>
                </div>
            </Form>
        </Card>
    )
}