import { Button, Col, Divider, Form, Input, notification, Row, Select, Skeleton, Space } from "antd";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getEnvironmentService } from "../../backend";
import { currentProjectState } from "../../recoil/project";
import { Environment, EnvironmentDetails } from "../../types";
import { PlusCircleOutlined } from "@ant-design/icons";
import { CreateStageRequestCloudProviderEnum, CreateStageRequestInfrastructureAsCodeToolEnum } from "@microtica/ms-engine-sdk";
import { trackEnvCreateSucceeded, trackEnvCreateFailed } from "../../backend/tracking/environment";
import { getEnvironmentsLimit } from "../../utils/local-storage";
import CloudIcon from "../CloudIcon";
import { RequiredMark } from "antd/es/form/Form";

interface EnvironmentSelectorProps {
    disabled?: boolean;
    selectedEnv?: EnvironmentDetails;
    onSelect?: (environment?: EnvironmentDetails) => void;
}

const EnvironmentSelect = ({
    disabled,
    selectedEnv,
    onSelect
}: EnvironmentSelectorProps) => {
    const currentProject = useRecoilValue(currentProjectState);
    const [form] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [environments, setEnvironments] = useState<Environment[]>([]);
    const [mode, setMode] = useState<"new" | "existing">("existing");
    const [enableCreateEnvironment, setEnableCreateEnvironment] = useState(true);
    const [loadingEnvs, setLoadingEnvs] = useState(true);
    const [creatingEnv, setCreatingEnv] = useState(false);

    const loadEnvironments = async () => {
        try {
            const { data: { stages: environments } } = await getEnvironmentService().getStages(currentProject!.id);
            const environmentsLimit = getEnvironmentsLimit();
            if (environmentsLimit <= environments.length) {
                setEnableCreateEnvironment(false);
            }
            const defaultEnv = environments.length === 1 ? environments[0] : undefined;

            if (!selectedEnv && defaultEnv) {
                onSelect?.({
                    id: defaultEnv.id,
                    name: defaultEnv.name,
                    accountId: defaultEnv.awsAccountId,
                    region: defaultEnv.awsRegion,
                    gcpProjectId: defaultEnv.gcpProjectId,
                    gcpRegion: defaultEnv.gcpRegion,
                    gcpZone: defaultEnv.gcpZone,
                    cloudProvider: defaultEnv.cloudProvider,
                    infrastructureAsCodeTool: defaultEnv.infrastructureAsCodeTool
                });
            }

            setEnvironments(environments.map(env => ({
                id: env.id,
                name: env.name,
                cloudProvider: env.cloudProvider,
                infrastructureAsCodeTool: env.infrastructureAsCodeTool,
                accountId: env.awsAccountId,
                region: env.awsRegion,
                gcpProjectId: env.gcpProjectId,
                gcpRegion: env.gcpRegion,
                gcpZone: env.gcpZone
            })));
            setMode(environments.length > 0 ? "existing" : "new");

        } catch (error) {

        } finally {
            setLoadingEnvs(false);
        }
    }

    useEffect(() => {
        loadEnvironments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (mode === "new") {
            onSelect?.();
        } else {
            if (!selectedEnv && environments.length === 1) {
                // onSelect() must be explicitely called because the hook for selectedEnvironment is not triggered if environments[0] === selectedEnvironment
                onSelect?.(environments[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    const handleCreateEnvironment = async () => {
        const values = await form.validateFields() as { name: string, description: string; cloudProvider: string; };

        const cloudProvider = values.cloudProvider === "aws" ?
            CreateStageRequestCloudProviderEnum.Aws :
            CreateStageRequestCloudProviderEnum.Google;
        const infrastructureAsCodeTool = values.cloudProvider === "aws" ?
            CreateStageRequestInfrastructureAsCodeToolEnum.Cloudformation :
            CreateStageRequestInfrastructureAsCodeToolEnum.Terraform;

        try {
            setCreatingEnv(true);
            const { data: { id } } = await getEnvironmentService().createStage(
                currentProject!.id,
                {
                    name: values.name,
                    description: values.description,
                    cloudProvider,
                    infrastructureAsCodeTool
                });
            await loadEnvironments();
            setMode("existing");
            onSelect?.({
                id,
                name: values.name,
                cloudProvider,
                infrastructureAsCodeTool
            });
            notification.success({ message: "New environment created" });
            trackEnvCreateSucceeded(
                id,
                values.name,
                cloudProvider,
                infrastructureAsCodeTool,
                false
            );
        } catch (error: any) {
            notification.error({
                message: "Cannot create new environment",
                description: error.response.data.message
            });
            trackEnvCreateFailed(
                values.name,
                cloudProvider,
                infrastructureAsCodeTool,
                false,
                error.response.data.message
            );
        } finally {
            setCreatingEnv(false);
        }
    }

    return (
        loadingEnvs ?
            <Skeleton /> :
            mode === "new" ?
                // New environment
                <div>
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={{
                            cloudProvider: "aws"
                        }}
                        requiredMark={requiredMark}
                    >
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <Form.Item
                                    name="name"
                                    label="Name"
                                    required
                                    rules={[{ required: true, message: 'Please input environment name!' }]}
                                >
                                    <Input placeholder="development" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="description"
                                    label="Description"
                                    required
                                    rules={[{ required: true, message: 'Please input environment description!' }]}
                                >
                                    <Input placeholder="My development environment" maxLength={100} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="cloudProvider"
                                    label="Cloud provider"
                                    required
                                    rules={[{ required: true, message: 'Please select cloud provider!' }]}
                                    extra={
                                        <small className="gray-text">
                                            Once the environment is set up, you can't switch providers.
                                        </small>
                                    }
                                >
                                    <Select className="full-width">
                                        <Select.Option value="aws" key="aws">
                                            <Space>
                                                <CloudIcon provider="aws" /> Amazon Web Services
                                            </Space>
                                        </Select.Option>
                                        <Select.Option value="google" key="google">
                                            <Space>
                                                <CloudIcon provider="google" /> Google Cloud Platform
                                            </Space>
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                            <Col span={12} style={{ textAlign: "left" }}>
                                {
                                    environments.length > 0 &&
                                    <Button type="link" style={{ padding: 0 }} onClick={() => {
                                        setMode("existing");
                                    }}>
                                        Choose existing environment
                                    </Button>
                                }
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <Button
                                    type="primary"
                                    loading={creatingEnv}
                                    onClick={handleCreateEnvironment}
                                >
                                    Create
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
                :
                // Existing environment
                <Space>
                    Select Environment:
                    <Select
                        placement="bottomRight"
                        placeholder="Select environment"
                        value={selectedEnv?.id}
                        disabled={disabled}
                        onChange={(value) => {
                            onSelect?.(environments.find(e => e.id === value))
                        }}
                        style={{ width: 276 }}
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                {
                                    enableCreateEnvironment &&
                                    <>
                                        <Divider style={{ margin: "5px 0" }} />
                                        <Button type="text" className="full-width" onClick={() => setMode("new")}>
                                            <PlusCircleOutlined /> Create Environment
                                        </Button>
                                    </>
                                }

                            </>
                        )}
                        options={
                            environments.map(env => ({
                                value: env.id,
                                label: <Space>
                                    <CloudIcon provider={env.cloudProvider} />
                                    {env.name}
                                </Space>
                            }))
                        }
                    />
                </Space>
    );
};


export default EnvironmentSelect;