import { ResourceConfigurationAndStatus } from "../../types";

/**
 * Return a list of all the resources that are referencing the provided resource in their configuration
 * Used when trying to undeploy a resource from a stage to make sure there aren't any other resources
 * that are referencing this one in their configuration
 *
 * @param resourceName 
 * @returns A list of the resource names that are referencing the provided resource in their configurations
 */
export const getResourcesReferencingParticularResource = (resourceName: string, deployedResources: ResourceConfigurationAndStatus): string[] => {
    return [
        ...new Set(Object.keys(deployedResources)
            .filter(resName => {
                return deployedResources[resName].configurations.find(config => config.reference === true && config.value.startsWith(`${resourceName}.`));
            })
        )
    ];
}
