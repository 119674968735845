import { Fragment, useEffect, useState } from "react";
import { CheckCircleFilled, CloseCircleFilled, ClockCircleOutlined, AimOutlined, GithubOutlined, PlayCircleOutlined, LoadingOutlined, MonitorOutlined } from "@ant-design/icons";
import { disconnectRealtimeService, getPipelinesService, getProjectService, getRealtimeService } from "../backend";
import { Badge, Button, Card, Col, Divider, Modal, Row, Skeleton, Space, Steps, notification } from "antd";
import GitCommitLink from "./GitCommitLink";
import { PipelineBuildDetails as PipelineBuildDetailsModel, PipelineBuildDetailsStagesStatusEnum, PipelineBuildDetailsSteps } from "@microtica/ms-ap-sdk";
import moment from "moment-timezone";
import DisplayMetric from "./DisplayMetric";
import Paragraph from "antd/lib/typography/Paragraph";
import { Content } from "antd/lib/layout/layout";
import { Socket } from "socket.io-client";
import Ansi from "ansi-to-react";
import { useParams } from "react-router";
import AutomatedBuildsAndDeploymentsModal from "./modals/AutomateBuildsAndDeploymentsModal";
import { useRecoilValue } from "recoil";
import { buildPipelineButtonState } from "../recoil/payment-plan-required";
import MessageCard from "./cards/MessageCard";
import { Dictionary, PricingPlan } from "../types";
import { currentProjectState } from "../recoil/project";
import { getDetailedPaymentPlan } from "../utils/payment-plans";
import { PricingPlanNames } from "../enums/enums";
import { useCurrentProject } from "../contexts/Project";
const LOGS_PLACEHOLDER: string = `⏳ Initializing logs...

`;


enum StepStatus {
    WAIT = "wait",
    PROCESS = "process",
    FINISH = "finish",
    ERROR = "error"
}
const PipelineBuildDetails = () => {
    const { projectId, pipelineId, buildId } = useParams();
    const [buildDetails, setBuildDetails] = useState<PipelineBuildDetailsModel>();
    const [currentSelectedStep, setCurrentSelectedStep] = useState<{ stageIndex?: number, stepIndex: number }>();
    const [socket, setSocket] = useState<Socket>();
    const [logs, setLogs] = useState(LOGS_PLACEHOLDER);
    const [showDetailedLogs, setShowDetailedLogs] = useState(false);
    const [loading, setLoading] = useState(true);
    const [buildStages, setBuildStages] = useState<{ name: string, status: PipelineBuildDetailsStagesStatusEnum, steps: PipelineBuildDetailsSteps[] }[]>();
    const [buildSteps, setBuildSteps] = useState<PipelineBuildDetailsSteps[]>();
    const logTimestampColor = "#097BB5";
    const logTimestampRegex = /\[(\d+)\]/;
    const localTimezoneOffset = moment().utcOffset();

    const [plan, setPlan] = useState<PricingPlan>()
    const { updateCurrentProject } = useCurrentProject();
    const currentProject = useRecoilValue(currentProjectState);

    const { noOfClicks } = useRecoilValue(buildPipelineButtonState);

    useEffect(() => {
        let allLogs = "";
        if (socket !== undefined) {
            socket.on("logs", (logs: string) => {
                allLogs = allLogs.concat(logs);
                setLogs(allLogs);
            });
        }
    }, [socket]);

    useEffect(() => {
        if (currentSelectedStep?.stepIndex === undefined && buildDetails && buildDetails.status !== "QUEUED") {
            // manually select the first step once build data is fetched & there is no selected current step & status is not QUEUED anymore
            if (buildSteps) {
                const failedStepIndex = buildDetails?.steps.findIndex(step => step.status === "FAILED" || step.status === "RUNNING");
                return (failedStepIndex !== -1) ?
                    // set the current step to the failed step
                    onSelectedStepChange({ stepIndex: failedStepIndex! }) :
                    // set the current step to the first one
                    onSelectedStepChange({ stepIndex: 0 });
            } else if (buildStages) {
                // find the index of the step that is FAILED or RUNNING in the list of steps. Will use this to determine the stage that this step is part of
                const stepIndex = buildDetails.steps.findIndex(step => step.status === "FAILED" || step.status === "RUNNING");
                // find the index of the stage the FAILED or RUNNING step belongs to
                const stageIndex = buildStages.findIndex(stage => stage.name === buildDetails?.steps[stepIndex]?.stage);
                return (stepIndex !== -1) ?
                    // set the current step to the FAILED or RUNNING step
                    onSelectedStepChange({
                        stageIndex,
                        // the index of the step that is FAILED or RUNNING within that stage's steps
                        stepIndex: buildStages[stageIndex].steps.findIndex(step => step.name === buildDetails.steps[stepIndex].name)
                    }) :
                    // set the current step to the first one
                    onSelectedStepChange({
                        stageIndex: 0,
                        stepIndex: 0
                    });
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildDetails])

    useEffect(() => {
        loadPipeline();
        const interval = setInterval(async () => {
            const status = await loadPipeline();
            if (["SUCCEEDED", "FAILED"].includes(status)) {
                clearInterval(interval);
            }
        }, 5000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pipelineId, buildId]);

    useEffect(() => {
        if (currentSelectedStep?.stepIndex !== undefined) {
            onSelectedStepChange({
                stepIndex: currentSelectedStep.stepIndex,
                stageIndex: currentSelectedStep.stageIndex
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDetailedLogs]);

    const loadPipeline = async () => {
        if (projectId && pipelineId && buildId) {
            const { data } = await getPipelinesService().getPipelineBuild(projectId, pipelineId, buildId);
            if (data.errorMessage === "The Free plan only allows for manual builds & deployments. To enable automated builds & deployments, upgrade your plan.") {
                const plan = await getDetailedPaymentPlan(PricingPlanNames.STARTER);
                setPlan(plan);
            }
            if (data.errorMessage === "You have exceeded the number of build minutes for this month. Upgrade your plan to get more.") {
                let plan;
                if (currentProject?.paymentPlan?.id === PricingPlanNames.FREE) {
                    plan = await getDetailedPaymentPlan(PricingPlanNames.STARTER);
                } else {
                    plan = await getDetailedPaymentPlan(PricingPlanNames.ADVANCED);
                }
                setPlan(plan);
            }
            setBuildDetails(data);
            if (data.stages?.length) {
                // build has stages with steps
                setBuildStages(data.stages.map(stage => {
                    return {
                        name: stage.name,
                        status: stage.status,
                        steps: data.steps.filter(step => {
                            return step.stage === stage.name;
                        })
                    };
                }));
            } else if (data.steps?.length) {
                // build has steps without stages
                setBuildSteps(data.steps);
            }
            setLoading(false);
            return data.status;
        }
        return "Status unknown";
    }

    const onSelectedStepChange = ({ stageIndex, stepIndex }: { stageIndex?: number, stepIndex: number }) => {
        const stepName = (stageIndex !== undefined) ?
            buildStages?.[stageIndex]?.steps[stepIndex].name :
            buildSteps?.[stepIndex].name;

        const socket = getRealtimeService(
            {
                projectId: projectId!,
                pipelineId,
                buildId,
                stepName,
                typeOfLogs: "pipeline",
                codebuildLogs: showDetailedLogs
            }
        );
        setCurrentSelectedStep({ stageIndex, stepIndex });
        setSocket(socket);
        setLogs(LOGS_PLACEHOLDER);
        disconnectRealtimeService();
    }

    const renderStatus = (status: string) => {
        switch (status) {
            case "SUCCEEDED":
                return {
                    icon: <CheckCircleFilled />,
                    color: "green",
                    stepStatus: StepStatus.FINISH,
                    displayStatus: "Done"
                };
            case "FAILED":
                return {
                    icon: <CloseCircleFilled />,
                    color: "red",
                    stepStatus: StepStatus.ERROR,
                    displayStatus: "Failed"
                };
            case "RUNNING":
                return {
                    icon: <LoadingOutlined />,
                    stepStatus: StepStatus.PROCESS,
                    displayStatus: "In progress"
                };
            case "PENDING":
            case "QUEUED":
            default:
                return {
                    icon: <LoadingOutlined />,
                    stepStatus: StepStatus.WAIT,
                    displayStatus: "Pending"
                };
        }
    }

    const parseCurrentStepWithinStage = (stageIndex: number) => {
        return currentSelectedStep?.stageIndex === stageIndex ?
            currentSelectedStep.stepIndex :     // set the selected step within a stage as the current one IF this stage is the selected one
            -1;                                 // dont select any step within the rest of the stages
    }

    const isTrialEligible = (planId: string) => {
        return currentProject?.freeTrials && !(currentProject.freeTrials as Dictionary<boolean>)[planId];
    }

    const showSelectPlanConfirm = () => Modal.confirm({
        title: `Activate ${plan!.name} plan`,
        content:
            <Space direction='vertical'>
                <div>
                    You are about to activate the {plan!.name} plan that will cost {plan!.price} per month.
                </div>
                {
                    isTrialEligible(plan!.id) ?
                        <div>
                            <b>7 day free trial</b> will be applied.
                        </div> :
                        <div>
                            Your credit card will be <b>charged immediately</b> because you've already used your free trial period.
                        </div>
                }
            </Space>,
        okText: `Activate ${plan!.name} plan`,
        centered: true,
        async onOk() {
            return handleSelectPlan();
        }
    });

    const mapErrorMessage = (errorMessage: string) => {

        if (errorMessage === "The Free plan only allows for manual builds & deployments. To enable automated builds & deployments, upgrade your plan.") {
            const modifiedErrorMessage = errorMessage.replace("upgrade your plan.", "");

            return <Space size={0}>
                {modifiedErrorMessage}
                <Button
                    style={{ paddingLeft: 3, paddingRight: 3 }}
                    type="link"
                    onClick={showSelectPlanConfirm}>
                    upgrade your plan.
                </Button>
            </Space>
        } else if (errorMessage === "You have exceeded the number of build minutes for this month. Upgrade your plan to get more.") {
            const [firstPart, secondPart] = errorMessage.split("Upgrade your plan");

            return <Space size={0}>
                {firstPart}
                <Button
                    style={{ paddingLeft: 3, paddingRight: 3 }}
                    type="link"
                    onClick={showSelectPlanConfirm}>
                    Upgrade your plan
                </Button>
                {secondPart}
            </Space>
        }

        return errorMessage
    }


    const handleSelectPlan = async () => {
        try {
            await getProjectService().changeProjectSubscription(
                currentProject!.id,
                {
                    paymentPlanId: plan!.id,
                    priceId: plan!.priceId!
                }
            );
            await updateCurrentProject(currentProject!.id);
        } catch (error: any) {
            notification.error({
                message: `Error activating the ${plan!.name} plan`,
                description: <>
                    {error.response.data.message}&nbsp;
                    <a href={`/projects/${currentProject!.id}/settings/billing`}>Go to Billing</a> to check your settings.
                </>
            })
        }
    }

    return (
        <>
            {
                loading ?
                    <Skeleton /> :
                    <Card
                        title={
                            <>
                                <div>
                                    Build details
                                </div>
                                <Paragraph copyable={{ text: buildDetails?.id }}>
                                    <AimOutlined style={{ color: "black", fontSize: 12, marginRight: 5 }} />
                                    <span style={{ color: "gray", fontSize: 12 }}>
                                        {buildDetails?.id}
                                    </span>
                                </Paragraph>
                            </>
                        }
                    >
                        <Row gutter={24}>
                            <Col xs={24} sm={12} md={6}>
                                <DisplayMetric
                                    size="small"
                                    icon={renderStatus(buildDetails?.status!).icon}
                                    title="Status"
                                    data={buildDetails?.status!}
                                    style={{ iconColor: renderStatus(buildDetails?.status!).color }}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <DisplayMetric
                                    size="small"
                                    icon={<GithubOutlined />}
                                    title="Commit"
                                    data={
                                        <GitCommitLink version={buildDetails?.metadata.commit.sha || buildDetails?.metadata.commit.version} repoUrl={buildDetails?.metadata.repository} branch={buildDetails?.metadata.commit.name} hideIcon={true} />
                                    }
                                    style={{ iconColor: "gray" }}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <DisplayMetric
                                    size="small"
                                    icon={<ClockCircleOutlined />}
                                    title="Duration"
                                    data={
                                        moment.utc(
                                            moment.duration(moment(buildDetails?.stopDate || new Date()).diff(moment(buildDetails?.startDate))).as("milliseconds")
                                        ).format("m[min] s[sec]")
                                            .replace(/^0min (\d+)sec$/, "$1sec")
                                            .replace(/^(\d+)min 0sec$/, "$1min")
                                    }
                                    style={{ iconColor: "gray" }}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <DisplayMetric
                                    size="small"
                                    icon={<PlayCircleOutlined />}
                                    title="Start time"
                                    data={buildDetails?.startDate ? moment(buildDetails?.startDate).fromNow() : ""}
                                    style={{ iconColor: "gray" }}
                                />
                            </Col>
                        </Row>

                        <br />
                        {
                            buildDetails?.errorMessage ?
                                <Fragment>
                                    <MessageCard
                                        type="error"
                                        text={mapErrorMessage(buildDetails?.errorMessage)}
                                    />
                                    <br />
                                </Fragment> : null
                        }
                        {
                            buildStages || buildSteps || !!buildDetails?.steps?.length ?
                                <Card>
                                    <Row gutter={24}>
                                        {/** Stages & Steps */}
                                        {
                                            buildStages &&
                                            <Col xs={24} className="pipeline-build-steps">
                                                <div className="build-stages-wrapper">
                                                    {
                                                        buildStages?.map((stage, stageIndex) => (
                                                            <div key={stageIndex}>
                                                                <div className="build-stages-title">
                                                                    {stage.name}
                                                                </div>
                                                                <div>
                                                                    {/* Each stage has its own Steps view */}
                                                                    <Steps
                                                                        direction="vertical"
                                                                        size="small"
                                                                        current={parseCurrentStepWithinStage(stageIndex)}
                                                                        onChange={(stepIndex) => onSelectedStepChange({ stageIndex, stepIndex })}
                                                                        items={
                                                                            stage.steps.map((step) => (
                                                                                {
                                                                                    title: step.name,
                                                                                    description:
                                                                                        <small>
                                                                                            <span style={{ color: step.status === "FAILED" ? "red" : "black" }}>
                                                                                                {renderStatus(step.status).displayStatus}
                                                                                            </span>
                                                                                            {
                                                                                                step.startDate &&
                                                                                                <Badge
                                                                                                    color="lightgray"
                                                                                                    style={{ margin: "auto 8px" }}
                                                                                                    text={
                                                                                                        <small>
                                                                                                            {
                                                                                                                moment.utc(
                                                                                                                    moment.duration(moment(step.stopDate).diff(moment(step.startDate))).as("milliseconds")
                                                                                                                ).format("m[min] s[sec]")
                                                                                                                    .replace(/^0min /, "")
                                                                                                                    .replace(/^0sec$/, "")
                                                                                                            }
                                                                                                        </small>
                                                                                                    } />
                                                                                            }
                                                                                        </small>,
                                                                                    status: renderStatus(step.status).stepStatus as StepStatus,
                                                                                    disabled: renderStatus(step.status).stepStatus === "wait",
                                                                                    icon: renderStatus(step.status).stepStatus === "process" && <LoadingOutlined style={{ color: "#097BB5" }} />,
                                                                                }
                                                                            ))
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </Col>
                                        }
                                        {/** Steps only */}
                                        {
                                            buildSteps &&
                                            <Col xs={24} className="pipeline-build-steps">
                                                <Steps
                                                    current={currentSelectedStep?.stepIndex}
                                                    items={
                                                        buildDetails?.steps.map((step) => (
                                                            {
                                                                title: step.name,
                                                                description:
                                                                    <small>
                                                                        <span style={{ color: step.status === "FAILED" ? "red" : "black" }}>
                                                                            {renderStatus(step.status).displayStatus}
                                                                        </span>
                                                                        {
                                                                            step.startDate &&
                                                                            <Badge
                                                                                color="lightgray"
                                                                                style={{ margin: "auto 8px" }}
                                                                                text={
                                                                                    <small>
                                                                                        {
                                                                                            moment.utc(
                                                                                                moment.duration(moment(step.stopDate).diff(moment(step.startDate))).as("milliseconds")
                                                                                            ).format("m[min] s[sec]")
                                                                                                .replace(/^0min /, "")
                                                                                                .replace(/^0sec$/, "")
                                                                                        }
                                                                                    </small>
                                                                                } />
                                                                        }
                                                                    </small>,
                                                                status: renderStatus(step.status).stepStatus as StepStatus,
                                                                icon: renderStatus(step.status).stepStatus === "process" && <LoadingOutlined style={{ color: "#097BB5" }} />,
                                                                disabled: renderStatus(step.status).stepStatus === "wait"
                                                            }
                                                        ))
                                                    }
                                                    onChange={(stepIndex) => onSelectedStepChange({ stepIndex })}
                                                />
                                            </Col>
                                        }

                                        {/* Logs */}
                                        {
                                            !!buildDetails?.steps?.length ?
                                                <Col xs={24}>
                                                    <Content style={{ marginTop: 40 }} className="content-logs">
                                                        <div className="flex-justify-space-between flex-align-center">
                                                            <h3 style={{ color: "white", margin: 0 }}>
                                                                {
                                                                    buildStages ?
                                                                        // find the name of the selected step within a stage
                                                                        buildStages[currentSelectedStep?.stageIndex!]?.steps[currentSelectedStep?.stepIndex!].name :
                                                                        // find the name of the selected step
                                                                        buildSteps?.[currentSelectedStep?.stepIndex!]?.name
                                                                }
                                                                <span style={{ color: "GrayText" }}>
                                                                    &nbsp;Step
                                                                </span>
                                                            </h3>
                                                            <Space>
                                                                <MonitorOutlined style={{ color: "goldenrod", fontSize: 20 }} />
                                                                {
                                                                    !showDetailedLogs &&
                                                                    <span>
                                                                        Missing important logs?
                                                                    </span>
                                                                }
                                                                <Button type="link" className="no-padding" onClick={() => setShowDetailedLogs(!showDetailedLogs)}>
                                                                    {
                                                                        showDetailedLogs ? "Show pretty logs" : "Show detailed logs"
                                                                    }
                                                                </Button>
                                                            </Space>
                                                        </div>
                                                        <Divider style={{ borderColor: "var(--border-color-base)" }} />
                                                        <code>
                                                            <pre style={{ height: 500 }}>
                                                                {
                                                                    logs.split("\n").map((log, index) => {
                                                                        const logTime = logTimestampRegex.exec(log);
                                                                        const timestamp = logTime && logTime[1];
                                                                        // color formatting if the log has a valid timestamp
                                                                        return timestamp ?
                                                                            <div key={index}>
                                                                                <span style={{ color: logTimestampColor }}>
                                                                                    {/* display the timestamp */}
                                                                                    [{
                                                                                        moment(+timestamp!).utc()
                                                                                            .add(localTimezoneOffset, 'm')
                                                                                            .format("MMM DD, HH:mm:ss.SSS")
                                                                                    }]
                                                                                </span>&nbsp;
                                                                                <span>
                                                                                    <Ansi linkify>
                                                                                        {/* display the rest of the log */}
                                                                                        {log.split(" ").slice(1).join(" ")}
                                                                                    </Ansi>
                                                                                </span>
                                                                            </div>
                                                                            :
                                                                            <div key={index}>
                                                                                <Ansi linkify>
                                                                                    {log}
                                                                                </Ansi>
                                                                            </div>
                                                                    })
                                                                }
                                                            </pre>
                                                        </code>
                                                    </Content>
                                                </Col> :
                                                <></>
                                        }
                                    </Row>
                                </Card> :
                                null
                        }
                        {
                            <AutomatedBuildsAndDeploymentsModal
                                numberOfClicks={noOfClicks} />
                        }
                    </Card>
            }
        </>
    )
}

export default PipelineBuildDetails;