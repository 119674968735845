import { Alert, Input, Modal, ModalFuncProps, Space } from "antd";
import { ReactNode, useEffect, useState } from "react";

export interface DoubleConfirmationModalProps {
    title: ReactNode;
    description: ReactNode;
    icon?: ReactNode;
    alert?: ReactNode;
    okText?: ReactNode;
    okDisabled?: boolean;
    cancelText?: ReactNode;
    confirmation: string;
    onOk?: () => void;
}

export function useDoubleConfigurationModal() {
    const [modal, setModal] = useState<{
        destroy: () => void;
        update: (configUpdate: ModalFuncProps) => void;
    }>();
    const [nameConfirmation, setNameConfirmation] = useState("");
    const [confirmation, setConfirmation] = useState<string>();

    useEffect(() => {
        if (modal) {
            modal.update({
                okButtonProps: {
                    // disable OK button if entered value does not match OR for some other reason in the parent
                    disabled: nameConfirmation !== confirmation!
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameConfirmation]);

    function handleOpen(props: DoubleConfirmationModalProps) {
        const confirmModal = Modal.confirm({
            title: props.title,
            icon: props.icon,
            content:
                <Space direction='vertical'>
                    <div>{props.description}</div>
                    {
                        props.alert &&
                        <Alert message={props.alert} type="error" />
                    }
                    <br />
                    <div>Enter <b>{props.confirmation}</b> to continue:</div>
                    <Input
                        disabled={!!props.okDisabled}
                        autoFocus={true}
                        onPaste={(e) => setNameConfirmation((e.target as EventTarget & { value: string }).value)}
                        onChange={(e) => setNameConfirmation(e.target.value)}
                    />
                </Space>
            ,
            okButtonProps: {
                disabled: true,
            },
            okText: props.okText,
            okType: 'danger',
            cancelText: props.cancelText,
            centered: true,
            onOk() {
                setModal(undefined);
                return props.onOk?.();
            },
            onCancel() {
                setModal(undefined);
            },
        });

        setModal(confirmModal);
        setConfirmation(props.confirmation);
    }


    return {
        modal,
        open: handleOpen
    }
}