import { Card, Divider, Space } from "antd";
import { useOutletContext, useParams } from "react-router";
import MessageCard from "../../components/cards/MessageCard";
import AssignResourceDomainSteps from "../../components/settings/AssignResourceDomainSteps";
import { IComponentDetails, IEnvDetails } from "../../types";

const ResourceSettingsCustomDomain = () => {
    const { envId } = useParams();
    const { env, component } = useOutletContext<{ env: IEnvDetails, component: IComponentDetails }>();

    return (
        <Space direction="vertical" className="full-width">
            {
                component.domain.eligibleForDomainConfiguration ?
                    <Card>
                        <Card.Meta
                            title="Custom Domain"
                            description={
                                <>
                                    Set up your custom domain to make your application accessible from the Internet. Manage and modify your domain settings here as required.
                                </>
                            }
                        />
                        <Divider />
                        <MessageCard
                            type="domain-info"
                            style={{ marginBottom: 30 }}
                            text={
                                <div>
                                    <div style={{ fontWeight: 600 }}>
                                        Your application is exposed on <a href={component.domain.accessUrl} target="_blank" rel="noreferrer">{component.domain.accessUrl}</a>
                                    </div>
                                    <div>
                                        If you want to change the domain, follow the steps below.
                                    </div>
                                </div>
                            }
                            showIcon={false}
                        />
                        <AssignResourceDomainSteps
                            envId={envId!}
                            resourceName={component.name}
                            resourceVersion={component.version}
                            domainCNAME={component.domain.CNAME!}
                            isModal={false}
                            customDomainName={component.domain.displayAssignDomainBtn ? undefined : component.domain.accessUrl}
                            awsAccount={{
                                id: env.awsAccountId!,
                                // CloudFront only supports ACM certificates in the N. Virginia Region (us-east-1)
                                region: component.domain.CNAME!.endsWith("cloudfront.net") ?
                                    "us-east-1" :
                                    env.region!
                            }}
                        />
                    </Card> :
                    <Card>
                        <Card.Meta
                            title="Assign domain"
                            description="Domain configuration is not supported for this type of component"
                        />
                    </Card>
            }
        </Space >
    );
}

export default ResourceSettingsCustomDomain;