import AwsLogo from "../assets/aws-logo.svg";
import GCPLogo from "../assets/gcp-logo.svg";
import AzureLogo from "../assets/azure-logo.svg";
import { CloudProvider } from "../types";

interface CloudIconProps {
    provider?: CloudProvider;
}

const CloudIcon = ({
    provider
}: CloudIconProps) => {

    return provider === "aws" || provider === "ecr" ? <img src={AwsLogo} style={{ width: "24px" }} alt="aws" /> :
        provider === "google" ? <img src={GCPLogo} style={{ width: "20px", height: "20px" }} alt="gcp" /> :
            provider === "azure" || provider === "azurerm" ? <img src={AzureLogo} style={{ width: "22px", height: "22px" }} alt="azure" /> :
                undefined

}

export default CloudIcon;