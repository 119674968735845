import { Button, Col, Form, Input, InputNumber, Row, Select, Skeleton, Tag, Tooltip } from "antd";
import { ApiOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { ComponentSchemaInputProperties } from "../types";
import { ReactNode, useState } from "react";
import "./index.css";
import { NamePath } from "antd/lib/form/interface";
import { Rule } from "antd/lib/form";
import { CONFIG_CATEGORIES } from "../enums/enums";

interface ComponentConfigFormItemsProps {
    formItems: ComponentSchemaInputProperties;
    required: string[];
    setFieldsValue: (values: any) => void;
    getFieldValue: (name: NamePath) => any;
    validateFields?: (nameList?: NamePath[]) => Promise<any>,
    references: string[];
    handleUpdateReferences: React.Dispatch<React.SetStateAction<string[]>>;
    loading?: boolean;
    emptyText?: ReactNode;
    mappedResourcesOutputs?: { [key: string]: { name: string; suggestionScore: number; }[] };
    filterCategories?: string[];
    onChange?: () => void;
    onReferenceChange?: () => void;
    eksInitialNodeInstanceType?: string;
}

const ComponentConfigFormItems = ({
    formItems,
    required,
    setFieldsValue,
    getFieldValue,
    validateFields,
    references,
    handleUpdateReferences,
    loading,
    emptyText,
    mappedResourcesOutputs,
    onChange,
    onReferenceChange,
    filterCategories,
    eksInitialNodeInstanceType
}: ComponentConfigFormItemsProps) => {
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);

    const shouldRenderReferenceButton = mappedResourcesOutputs && Object.values(mappedResourcesOutputs).some(outputs => !!outputs.length);

    const getReferenceButton = (key: string) => (
        <Tooltip
            title={references.includes(key) ? "Remove the reference and switch to plain value mode" : "Reference the value of this parameter from other resource's output"}
        >
            <Button
                icon={<ApiOutlined />}
                className={references.includes(key) ? "ant-btn-primary" : ""}
                onClick={() => {
                    setFieldsValue?.({ [key]: undefined });
                    references.includes(key) ?
                        handleUpdateReferences(references.filter(r => r !== key)) :
                        handleUpdateReferences([...references, key])
                }}
            />
        </Tooltip>
    );

    return loading ? <Skeleton paragraph={{ rows: 1 }} /> :
        Object.keys(formItems).length ?
            <>
                {
                    Object.entries(formItems).sort(([a], [b]) => {
                        // Display the input field 'AppName' on the top
                        if (a === "AppName") { return -1; }     // when 'AppName' is not first in array
                        if (b === "AppName") { return 1; }      // when 'AppName' is first item in array
                        // when both props are required don't change the order
                        // this is done to avoid messing with props order as defined in the templates
                        if (required?.indexOf(b) !== -1 && required?.indexOf(a) !== -1) {
                            return 1;
                        }
                        return required?.indexOf(b) - required?.indexOf(a);
                    })
                        .map(([key, prop]) => {
                            const isRequred = required?.includes(key);
                            const validationRules: Rule[] = [{ required: isRequred, message: `Please input ${key}!` }];

                            if (prop.pattern) {
                                validationRules.push({
                                    pattern: new RegExp(prop.pattern),
                                    message: prop.patternErrorMessage
                                });
                            }

                            // check if resource is eks and formItem is "maxNodes"
                            if (!!eksInitialNodeInstanceType && key === "maxNodes") {
                                validationRules.push(
                                    ({ getFieldValue }) => ({
                                        validator(_rule, newMaxNodesValue) {
                                            const newInstanceTypeValue = getFieldValue("nodeInstanceType");
                                            // if maxNodes is 1 and instance type is changed show validation error
                                            if (newMaxNodesValue === "1" && eksInitialNodeInstanceType !== newInstanceTypeValue) {
                                                return Promise.reject("In order to be able to deploy with no downtime when nodeInstanceType is changed, maxNodes value must be greater than 1. After successful deployment, you can set the maxNodes value back to 1 and redeploy.");
                                            } else {
                                                return Promise.resolve();
                                            }
                                        }
                                    })
                                );
                            }

                            const shouldHideItem = !filterCategories ? CONFIG_CATEGORIES.includes(prop.category!) :
                                filterCategories.includes("all") ? false :
                                    !filterCategories.includes(prop.category!);

                            return (
                                <Form.Item
                                    key={key}
                                    name={key}
                                    label={prop.label || key}
                                    required={isRequred}
                                    tooltip={prop.description}
                                    rules={validationRules}
                                    className={shouldRenderReferenceButton ? "references" : "no-references"}
                                    hidden={shouldHideItem}
                                >
                                    {
                                        references.includes(key) && !!mappedResourcesOutputs && Object.keys(mappedResourcesOutputs).length ?
                                            <Input.Group className="flex-justify-space-between">
                                                <Select
                                                    showSearch
                                                    filterOption={(value, option) => option && option.value ? String(option.value).toLowerCase().includes(value.trim().toLowerCase()) : true}
                                                    placeholder={`Select output from existing resource`}
                                                    optionFilterProp="children"
                                                    onChange={(value) => {
                                                        onChange?.();
                                                        setFieldsValue?.({ [key]: value });
                                                        setShouldUpdate(!shouldUpdate);
                                                        // needed to update alert message in DeployResourceModal
                                                        onReferenceChange?.();
                                                    }}
                                                    value={getFieldValue?.(key)}
                                                >
                                                    {
                                                        mappedResourcesOutputs[key].map(({ name, suggestionScore }) => (
                                                            <Select.Option value={name} key={name} className="flex-align-center">
                                                                <Row justify="space-between" align="middle">
                                                                    <Col>
                                                                        {name}
                                                                    </Col>
                                                                    <Col>
                                                                        {suggestionScore > 0.65 ?
                                                                            <Tag className="flex-align-center">suggested</Tag> :
                                                                            null
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                                <Input addonAfter={getReferenceButton(key)} className="hidden-input-use-add-on" />
                                            </Input.Group>
                                            :
                                            prop.enum ?
                                                <Input.Group className="flex-justify-space-between">
                                                    <Select
                                                        showSearch
                                                        mode={prop.type === "array" ? "multiple" : undefined}
                                                        placeholder={`Select ${key}`}
                                                        optionFilterProp="children"
                                                        onChange={async (value) => {
                                                            onChange?.();
                                                            setFieldsValue?.({ [key]: value });
                                                            setShouldUpdate(!shouldUpdate);
                                                            // check if resource is eks and formItem is "nodeInstanceType"
                                                            if (!!eksInitialNodeInstanceType && key === "nodeInstanceType") {
                                                                await validateFields?.(["maxNodes"]);
                                                            }
                                                        }}
                                                        value={getFieldValue?.(key)}
                                                    >
                                                        {
                                                            Object.entries(prop.enum).map(([_, enumValue]) => (
                                                                <Select.Option value={enumValue} key={enumValue}>{enumValue}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                    {shouldRenderReferenceButton && <Input addonAfter={getReferenceButton(key)} className="hidden-input-use-add-on" />}
                                                </Input.Group>
                                                :
                                                (prop.dataType || prop.type) === "number" ?
                                                    <InputNumber
                                                        onChange={onChange}
                                                        min={prop.minimum}
                                                        max={prop.maximum}
                                                        placeholder={prop.placeholder}
                                                        style={{ width: "100%" }}
                                                        addonAfter={shouldRenderReferenceButton ? getReferenceButton(key) : null}
                                                    /> :
                                                    !prop.sensitive ?
                                                        <Input
                                                            type={"text"}
                                                            onChange={onChange}
                                                            placeholder={prop.placeholder}
                                                            minLength={prop.minLength}
                                                            maxLength={prop.maxLength}
                                                            addonAfter={shouldRenderReferenceButton ? getReferenceButton(key) : null}
                                                        /> :
                                                        <Input.Password
                                                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                            addonAfter={shouldRenderReferenceButton ? getReferenceButton(key) : null}
                                                            onChange={onChange}
                                                        />
                                    }
                                </Form.Item>
                            )
                        })
                }
            </> :
            <>{emptyText}</>
}

export default ComponentConfigFormItems;