import { Badge, Button, Col, Collapse, Divider, Dropdown, Empty, Result, Row, Skeleton, Space, Tooltip, Tree, theme, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { getElasticSearchService, getEnvironmentService, getKubeService } from "../../backend";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router";
import { KubernetesOutlined, PlusOutlined } from "@ant-design/icons";
import { App, Component, Deployment, Dictionary, IEnvDetails } from "../../types";
import GitCommitLink from "../../components/GitCommitLink";
import DeploymentStatusTag from "../../components/DeploymentStatusTag";
import { ServiceDeployment, ServiceDeploymentCommit } from "@microtica/ms-elasticsearch-sdk";
import Search from "antd/es/input/Search";
import ChooseComponentModal from "../../components/modals/ChooseComponentModal";
import TemplateAvatar from "../../components/TemplateAvatar";
import ChooseAppModal from "../../components/modals/ChooseAppModal";
import { newUpdateNotificationsState } from "../../recoil/notification";
import { useRecoilValue } from "recoil";
import { SizeType } from "antd/es/config-provider/SizeContext";
import ComponentIcon from "../../components/ComponentIcon";
import { EnableMonitoringRequestClusterTypeEnum } from "@microtica/ms-monitoring-sdk";
import { UpdateComponentRequestTypeEnum } from "@microtica/ms-engine-sdk";
const { Text } = Typography;

export default function EnvironmentOverview() {
    const navigate = useNavigate();
    const { token } = theme.useToken();
    const { env } = useOutletContext<{ env: IEnvDetails }>();
    const updates = useRecoilValue(newUpdateNotificationsState);
    const leftContainerRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(true);
    const [loadingSideContainer, setLoadingSideContainer] = useState(true);
    const { projectId, envId, componentId, namespace = "", appName } = useParams() as {
        projectId: string;
        envId: string;
        componentId: string;
        namespace: string;
        appName: string;
    };
    const [components, setComponents] = useState<Component[]>([]);
    const [filteredComponents, setFilteredComponents] = useState<Component[]>([]);
    const [filter, setFilter] = useState("");
    const [selectedComponent, setSelectedComponent] = useState<Component>();
    const [selectedApp, setSelectedApp] = useState<App>();
    const [openComponentsModal, setOpenComponentsModal] = useState(false);
    const [openClusterModal, setOpenClusterModal] = useState(false);
    const [openAppsModal, setOpenAppsModal] = useState(false);

    useEffect(() => {
        if (env && updates.some(update => update.projectId === projectId)) {
            getComponents();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updates]);

    useEffect(() => {
        if (env) {
            loadData();
        }
    }, [env]);

    useEffect(() => {
        if (filter) {
            setFilteredComponents(
                components.reduce((acc, component) => {
                    if (
                        component.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
                        component.apps?.some(a => a.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
                    ) {
                        acc.push({
                            ...component,
                            apps: component.apps?.filter(a => a.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
                        });
                    }
                    return acc;
                }, [] as Component[])
            );
        } else {
            setFilteredComponents(components);
        }

    }, [components, filter]);

    useEffect(() => {
        const selectDefaultComponent = async () => {
            setLoadingSideContainer(true);
            await handleSelectComponent(components, components.find(c => c.id === components[0].id)!);
            setLoadingSideContainer(false);
        }

        if (!componentId && !appName && components.length) {
            selectDefaultComponent();
        }

    }, [componentId, appName, components.length]);

    useEffect(() => {
        // if (selectedComponent?.type === "kubernetes") {
        const interval = setInterval(async () => {
            await getComponents();
        }, 30000);

        return () => { clearInterval(interval) }
        // }
    }, [selectedComponent]);

    const updateAppStatus = async (comps?: Component[]) => {
        const { data: { deployedMicroservices } } = await getKubeService().getDeployedMicroservicesInStage(envId, projectId);

        const appsPerCluster = deployedMicroservices.reduce((clusters, item) => {
            item.microservices.forEach(m => {
                if (!clusters[m.kubernetesId]) {
                    clusters[m.kubernetesId] = [];
                }
                clusters[m.kubernetesId].push({
                    name: m.name,
                    clusterId: m.kubernetesId,
                    clusterType: EnableMonitoringRequestClusterTypeEnum.K8s,
                    version: m.version,
                    instance: {
                        pods: m.pods,
                        status: m.podStatus,
                    },
                    namespace: m.namespace,
                    loadingDeployment: true,
                    // deployment: (comps || components).find(c => c.deployment)?.apps?.find(a => a.name === m.name)?.deployment
                })
            })
            return clusters;
        }, {} as Dictionary<App[]>);

        const compsWithApps = (comps || components).map(c => {
            if (c.type === "kubernetes") {
                const apps = appsPerCluster[`${envId}-${c.name}`] || appsPerCluster[c.clusterId!] || [];

                return {
                    ...c,
                    clusterId: c.clusterId || apps[0]?.clusterId,
                    clusterType: EnableMonitoringRequestClusterTypeEnum.K8s,
                    apps
                };
            } else {
                return c;
            }
        });

        if (selectedComponent?.type === "kubernetes") {
            const appsDeployment = await fetchAppsDeployment(selectedComponent.clusterId!);

            setComponents(compsWithApps.map(c => {
                if (c.id === selectedComponent.id) {
                    c.apps?.forEach(a => {
                        a.deployment = appsDeployment[`${selectedComponent.clusterId}-${a.name}`]
                        a.loadingDeployment = false;
                    });
                }
                return c;
            }));
        } else {
            setComponents(compsWithApps);
        }
        return compsWithApps;
    }

    const getComponents = async () => {
        const [
            { data: { resources } },
            { data: { kuberneteses } },
            componentsDeployment
        ] = await Promise.all([
            getEnvironmentService().getStageDetails(envId, projectId),
            getKubeService().listKubernetes(projectId, envId),
            fetchComponentsDeployment()
        ]);

        const comps = [
            ...resources
                .map(r => ({
                    id: r.name,
                    name: r.name,
                    type: r.component.type,
                    component: r.component,
                    isCluster: r.component.type as string === "fargate" || r.component.type as string === "kubernetes",
                    clusterId: r.component.type as string === "kubernetes" ?
                        `${envId}-${r.name}` :
                        r.component.type as string === "fargate" ?
                            r.name :
                            undefined,
                    clusterType: r.component.type as string === "fargate" ? EnableMonitoringRequestClusterTypeEnum.Ecs : EnableMonitoringRequestClusterTypeEnum.K8s,
                    apps: r.component.type as string === "fargate" ? [{
                        name: r.name,
                        clusterId: r.name,
                        clusterType: EnableMonitoringRequestClusterTypeEnum.Ecs,
                        namespace: "",
                        deployment: componentsDeployment[r.name],
                        instance: {
                            pods: [{}],
                            status: {
                                failedPods: 0,
                                pendingPods: 0,
                                runningPods: 0,
                                succeededPods: 0,
                                totalPods: 0
                            }
                        },
                        version: ["NOT_DEPLOYED", "DELETE_COMPLETE"].includes(r.status) ? undefined : r.component.version
                    }] : undefined,
                    version: ["NOT_DEPLOYED", "DELETE_COMPLETE"].includes(r.status) ? undefined : r.component.version,
                    deployment: componentsDeployment[r.name],
                    isPublic: r.component.id.startsWith("microtica-"),
                    status: r.status,
                    configurations: r.configurations
                })) as Component[],
            ...kuberneteses
                .reduce((acc, k) => {
                    const component = (resources || []).find(r => r.name === k.name.split("-").reverse()[0]);

                    if (!component) {
                        acc.push({
                            id: k.id,
                            clusterId: k.id,
                            clusterType: EnableMonitoringRequestClusterTypeEnum.K8s,
                            name: k.name,
                            type: "kubernetes",
                            isCluster: true,
                            apps: [],
                            isPublic: false,
                            status: "DEPLOYED"
                        });
                    }

                    return acc;
                }, [] as Component[])
        ] as Component[];

        const orderedByCluster = comps.sort((a, b) => {
            if (a.isCluster && !b.isCluster) {
                return -1; // a comes before b
            } else if (!a.isCluster && b.isCluster) {
                return 1; // b comes before a
            } else {
                return 0; // maintain the current order
            }
        })

        return updateAppStatus(orderedByCluster);

    }

    const loadData = async () => {
        const compsWithApps = await getComponents();

        if (componentId && appName) {
            const comp = compsWithApps.find(c => c.id === componentId)!;
            await handleSelectComponent(compsWithApps, comp, false);
            setSelectedApp(comp.apps?.find(a => a.name === appName));
        } else if (componentId) {
            await handleSelectComponent(compsWithApps, compsWithApps.find(c => c.id === componentId)!);
        }
        setLoading(false);
        setLoadingSideContainer(false);
    }

    const fetchComponentsDeployment = async () => {
        try {
            const { data: { response: stagesDeploymentHistory } } = await getElasticSearchService()
                .getStageDeploymentHistory(projectId, envId, env.lastDeploymentId);

            const commitMap: { [serviceName: string]: Deployment } = stagesDeploymentHistory[0].resources
                .reduce((acc, deployment) => {
                    return {
                        ...acc,
                        [deployment.name]: {
                            repository: deployment.component.metadata.repository,
                            message: deployment.component.metadata.commit.message,
                            branch: deployment.component.metadata.commit.name,
                            version: deployment.component.metadata.commit.version,
                            sha: deployment.component.metadata.commit.sha,
                            user: deployment.component.metadata.commit.user,
                            commitName: deployment.component.metadata.commit.name,
                            commitType: deployment.component.metadata.commit.type,
                            status: deployment.status,
                            pipelineId: deployment.component.pipelineId,
                            configurations: deployment.configurations
                        }
                    }
                }, {});
            return commitMap;
        } catch (e) {
            return {};
        }
    }

    const fetchAppsDeployment = async (componentId: string) => {
        const { data: { response: appDeployments } } = await getElasticSearchService().getDeployedMicroservices(projectId, componentId);

        const commitMap: { [serviceName: string]: Deployment } = appDeployments
            .filter((d: ServiceDeployment & { stageId?: string }) => d.stageId === envId)
            .reduce((acc, deployment) => {
                return {
                    ...acc,
                    [`${deployment.kubernetesId}-${deployment.name}`]: {
                        repository: deployment.commit?.repository,
                        message: deployment.commit?.message,
                        branch: deployment.commit?.name,
                        version: deployment.commit?.version,
                        sha: deployment.commit?.sha,
                        user: deployment.commit?.user,
                        commitName: deployment.commit?.name,
                        commitType: deployment.commit?.type,
                        status: deployment.status,
                        pipelineId: (deployment.commit as ServiceDeploymentCommit & { pipelineId: string })?.pipelineId,
                        configurations: deployment.configurations,
                        kubeConfig: deployment.kubeConfig
                    }
                }
            }, {});

        return commitMap;
    }

    const handleSelectComponent = async (components: Component[], component: Component, redirect: boolean = true) => {
        setSelectedComponent(components.find(c => c.id === component.id));
        if (component.type === "kubernetes") {
            const appsDeployment = await fetchAppsDeployment(component.clusterId!);

            setComponents(components.map(c => {
                if (c.id === component.id) {
                    c.apps?.forEach(a => {
                        a.deployment = appsDeployment[`${component.clusterId}-${a.name}`]
                        a.loadingDeployment = false;
                    });
                }
                return c;
            }));

            if (redirect) {
                navigate(`/projects/${projectId}/environments/${envId}/components/${component.id}/overview`, { replace: true });
            }

        } else if (component.type === "other") {
            // TODO: This is a temporary solution and the purpose is to migrate component type to "fargate"
            // if the component has output with name ApplicationLogs
            const { data: { outputs } } = await getEnvironmentService().getOutputsForResource(
                envId,
                component.name,
                projectId
            );

            if (outputs.some(o => o.key === "ApplicationLogs")) {
                await getEnvironmentService().updateComponent(
                    component.component?.id!,
                    projectId,
                    {
                        type: UpdateComponentRequestTypeEnum.Fargate
                    }
                );

                const comps = await getComponents();

                setComponents(comps);
            }

            if (redirect) {
                navigate(`/projects/${projectId}/environments/${envId}/components/${component.id}/pipelines`, { replace: true });
            }
        } else {
            if (redirect) {
                navigate(`/projects/${projectId}/environments/${envId}/components/${component.id}/pipelines`, { replace: true });
            }
        }
        setSelectedApp(undefined);
    }

    const handleSelectApp = async (component: Component, appName: string) => {
        const [namespace, name] = appName.split("/");

        const app = component.apps?.find(a => a.namespace === namespace && a.name === name);

        if (app) {
            setSelectedApp(app);
            setSelectedComponent(components.find(c => c.id === component.id));

            if (component.type === "fargate") {
                navigate(
                    `/projects/${projectId}/environments/${envId}/components/${component.id}/apps/${app.name}/overview`,
                    { replace: true }
                );
            } else {
                navigate(
                    `/projects/${projectId}/environments/${envId}/components/${component.id}/apps/${app.namespace}/${app.name}/overview`,
                    { replace: true }
                );
            }

        }
    }

    const ComponentMetadata = ({ component }: { component: Component }) => (
        <div className="flex-justify-space-between flex-align-center">
            <div>
                <div style={{ fontSize: "16px", fontWeight: 600 }} className="flex-align-center">
                    <div>{component.type as string === "fargate" ? `${component.name} Cluster` : component.name}</div> &nbsp;<ComponentIcon type={component.type} />
                </div>
                {
                    component.type === "kubernetes" && !component.deployment ?
                        <div style={{ fontSize: "11px" }}>
                            Manually connected cluster
                        </div> :
                        component.deployment ?
                            <>
                                <Text ellipsis={{ tooltip: component.deployment.message }} style={{ fontSize: "11px", maxWidth: "13vw", verticalAlign: "text-top" }}>
                                    {component.deployment.message}
                                </Text>
                                <div style={{ fontSize: "11px", fontWeight: 400 }}>
                                    <GitCommitLink
                                        version={component.deployment.sha || component.deployment.version}
                                        repoUrl={component.deployment.repository}
                                        commitType={component.deployment.commitType}
                                        branch={component.deployment.commitName}
                                    />
                                </div>
                            </> : undefined
                }

            </div>
            <div>
                {
                    component.component ?
                        <DeploymentStatusTag status={component.status} />
                        : undefined
                }
            </div>
        </div>
    )

    const ComponentApps = ({ component }: { component: Component; }) => (
        <>
            <Tree
                showLine
                style={{ marginTop: "-20px" }}
                onSelect={(appName) => handleSelectApp(component, appName[0] as string)}
                selectedKeys={[`${namespace}/${appName}`]}
                treeData={component.apps?.map(app => {
                    const { failedPods, pendingPods, runningPods, totalPods } = app.instance.status;
                    return {
                        key: `${app.namespace}/${app.name}`,
                        title: <div className="flex-justify-space-between flex-align-center">
                            <div>
                                <Space style={{ fontSize: "14px" }}>
                                    {app.name}
                                </Space>
                                <div style={{ fontSize: "11px" }}>
                                    {
                                        app.loadingDeployment ?
                                            <Skeleton.Input style={{ height: 11 }} /> :
                                            app.deployment ?
                                                <GitCommitLink
                                                    version={app.deployment.sha || app.deployment.version}
                                                    repoUrl={app.deployment.repository}
                                                    commitType={app.deployment.commitType}
                                                    branch={app.deployment.commitName}
                                                /> :
                                                undefined
                                    }
                                </div>
                            </div>
                            <div>
                                <Tooltip title={
                                    <>
                                        {failedPods > 0 && <div>{failedPods} failed pods</div>}
                                        {pendingPods > 0 && <div>{pendingPods} pending pods</div>}
                                        {runningPods > 0 && <div>{runningPods} running pods</div>}
                                    </>
                                }>
                                    <Space>
                                        {
                                            Array.from(Array(Math.min(totalPods, 4)).keys()).map(index => {
                                                if (index === 0 && failedPods > 0) {
                                                    return <Badge key={index} status="error" />
                                                } else if ((index === 0 || index === 1) && pendingPods > 0) {
                                                    return <Badge key={index} status="processing" />
                                                } else {
                                                    return <Badge key={index} status="success" />
                                                }
                                            })
                                        }
                                        {
                                            totalPods > 4 ? `+${totalPods - 4}` : undefined
                                        }
                                    </Space>
                                </Tooltip>
                            </div>
                        </div>
                    }
                })}
            />
            {
                component.type === "kubernetes" ?
                    <>
                        <Divider style={{ margin: 0 }} />
                        <div className="flex-align-center flex-justify-center">
                            <Button
                                type="link"
                                size="large"
                                style={{ fontWeight: 500, fontSize: "14px" }}
                                onClick={() => setOpenAppsModal(true)}
                            >
                                <PlusOutlined /> Add Application
                            </Button>
                        </div>
                    </> : undefined
            }
        </>
    )

    const AddComponentDropdown = (props: { size?: SizeType }) => (
        <Dropdown
            key="add-new"
            trigger={["click"]}
            menu={{
                items: [
                    {
                        key: "app",
                        onClick: () => setOpenClusterModal(true),
                        label: <div style={{ padding: "10px" }}>
                            <div style={{ fontSize: "16px", fontWeight: 500 }}>
                                Cluster + App
                            </div>
                            <div className="gray-text">
                                Deploy NextJS, ReactJS and other apps.
                            </div>
                            <Space style={{ marginTop: "3px" }}>
                                <TemplateAvatar size="xs" name={"NodeJS"} />
                                <TemplateAvatar size="xs" name={"NextJS"} />
                                <TemplateAvatar size="xs" name={"ReactJS"} />
                                <TemplateAvatar size="xs" name={"Strapi"} />
                                <TemplateAvatar size="xs" name={"Medusa"} />
                            </Space>
                        </div>
                    },
                    {
                        key: "component",
                        onClick: () => setOpenComponentsModal(true),
                        label: <div style={{ padding: "10px" }}>
                            <div style={{ fontSize: "16px", fontWeight: 500 }}>
                                Standalone Component
                            </div>
                            <div className="gray-text">
                                Deploy individual databases, clusters and other AWS services.
                            </div>
                            <Space style={{ marginTop: "3px" }} className="flex-align-center">
                                {
                                    env.cloudProvider === "aws" ?
                                        <>
                                            <TemplateAvatar size="xs" name="AWS" />
                                            <KubernetesOutlined style={{ fontSize: "18px", color: "#326CE5" }} className="flex-align-center" />
                                            <TemplateAvatar size="xs" name="Lambda" />
                                            <TemplateAvatar size="small" name="Fargate" />
                                        </> :
                                        <>
                                            <TemplateAvatar size="xs" name="google" />
                                            <TemplateAvatar size="xs" name="terraform" />
                                            <KubernetesOutlined style={{ fontSize: "18px", color: "#326CE5" }} className="flex-align-center" />
                                        </>
                                }
                            </Space>
                        </div>
                    }
                ]
            }}
            placement="bottomRight"
        >
            <Button className="full-width" size={props.size}>
                <PlusOutlined /> Add new
            </Button>
        </Dropdown>
    )

    const ComponentSearch = () => (
        <Row gutter={[12, 12]} style={{ marginBottom: "15px" }}>
            <Col span={17}>
                <Search
                    className="full-width"
                    defaultValue={filter}
                    allowClear
                    placeholder="Search components and apps"
                    onSearch={setFilter}
                />
            </Col>
            <Col span={7}>
                <AddComponentDropdown />
            </Col>
        </Row>
    )

    const EnvironmentPlaceholder = () => (
        <div className="flex-justify-center full-width">
            <Result
                title="Welcome to your environment!"
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                subTitle={
                    <>
                        <div>
                            It's currently blank, with no components or apps ready to roll.
                        </div>
                        <div>
                            Start by adding new components below!
                        </div>
                    </>
                }
                extra={
                    <AddComponentDropdown />
                }
            />
        </div>
    )

    return (
        <div className="environment-overview">
            <Row gutter={16}>
                {
                    !loading && components.length === 0 ? <EnvironmentPlaceholder /> :
                        <>
                            <Col span={8} style={{}} ref={leftContainerRef}>
                                {
                                    loading ? <Skeleton /> :
                                        <>
                                            <ComponentSearch />
                                            <Collapse
                                                accordion
                                                size="large"
                                                bordered={false}
                                                className="no-bg full-width"
                                                items={filteredComponents.map(component => ({
                                                    key: component.id,
                                                    label: <ComponentMetadata component={component} />,
                                                    children: component.isCluster && <ComponentApps component={component} />,
                                                    showArrow: component.isCluster,
                                                    className: component.isCluster ? "ant-collapse-item-with-content" : undefined,
                                                    headerClass: selectedApp ? "ant-collapse-header-selected-children" : undefined,
                                                    style: {
                                                        marginBottom: 8,
                                                        background: token.colorBgBase,
                                                        borderRadius: token.borderRadiusLG
                                                    }
                                                }))}
                                                onChange={(key) => {
                                                    const component = filteredComponents.find(c => c.id === key[0]);
                                                    if (component) {
                                                        handleSelectComponent(filteredComponents, component);
                                                    } else if (selectedComponent) {
                                                        handleSelectComponent(filteredComponents, selectedComponent);
                                                    }
                                                }}
                                                activeKey={selectedComponent?.id}
                                            />
                                        </>
                                }
                            </Col>
                            <Col span={16} style={{ display: "inline-flex", alignSelf: "stretch" }}>
                                {
                                    loading || loadingSideContainer ? <Skeleton /> :
                                        <>
                                            {selectedComponent && !selectedApp && <Outlet context={{ env: env, component: selectedComponent }} />}
                                            {selectedComponent && selectedApp && <Outlet context={{ env: env, component: selectedComponent, app: selectedApp }} />}
                                        </>
                                }
                            </Col>
                        </>

                }
                {
                    env && openComponentsModal &&
                    <ChooseComponentModal
                        projectId={projectId}
                        env={env}
                        open={openComponentsModal}
                        onOk={async () => {
                            loadData();
                            setOpenComponentsModal(false);
                        }}
                        onCancel={() => setOpenComponentsModal(false)}
                    />
                }
                {
                    env && openClusterModal &&
                    <ChooseAppModal
                        projectId={projectId}
                        env={env}
                        open={openClusterModal}
                        onCancel={() => setOpenClusterModal(false)}
                    />
                }
                {
                    env && selectedComponent && openAppsModal &&
                    <ChooseAppModal
                        projectId={projectId}
                        env={env}
                        filter={{
                            componentId,
                            deploymentTarget: {
                                type: "kubernetes",
                                value: selectedComponent.clusterId!
                            }
                        }}
                        open={openAppsModal}
                        onCancel={() => setOpenAppsModal(false)}
                    />
                }
            </Row>
        </div>
    );
}