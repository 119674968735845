const ContainerRegistryExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Container Registries
            </div>
            <div className="explanation-subtitle">
                Docker images repository
            </div>
            <br />

            <p>
                Microtica allows you to connect your own Docker registries where you would store Docker images built during pipeline execution.
                You can use images to deploy services on Kubernetes clusters.
            </p>
            <br />

            <h4>Registry types</h4>
            <span>
                Currently, Microtica supports three types of private Docker registries:
            </span>
            <ul>
                <li>DockerHub</li>
                <li>Amazon ECR</li>
                <li>GitLab Container Registry</li>
            </ul>
            <br />

            <h4>Default registry</h4>
            <p>
                If you mark the registry as default, when composing pipelines, if you don't specify the registry the default registry will be used automatically.
            </p>
            <br />

            <p>
                Learn more about how to <a href="https://docs.microtica.com/connect-a-container-registry" target="_blank" rel="noreferrer">connect a container registry.</a>
            </p>
        </>
    )
}

export default ContainerRegistryExplanation;