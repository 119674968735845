import { Col, Menu, Row, Space } from "antd";
import { BarsOutlined, BarChartOutlined, TeamOutlined, CreditCardOutlined, MailOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import { getProjectService } from "../../backend";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import PageContentWrapper from "../../components/PageContentWrapper";
import { PageHeader } from "@ant-design/pro-layout";

export default function ProjectSettingsRoot() {
    const navigate = useNavigate();
    const location = useLocation();
    const { projectId } = useParams();
    const [activeTab, setActiveTab] = useState("general");
    const currentProject = useRecoilValue(currentProjectState);

    useEffect(() => {
        setActiveTab(location.pathname.split("/")[4] || "general");

        const loadData = async () => {
            await getProjectService().getUsers(projectId!);
        }
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <PageContentWrapper
            size="large"
            header={
                <PageHeader
                    title="Project Settings"
                />
            }
        >
            <Row gutter={24}>
                <Col span={6}>
                    <Menu
                        mode="inline"
                        className="ant-card-bordered"
                        selectedKeys={[activeTab]}
                        onSelect={(item) => {
                            navigate(item.key);
                            setActiveTab(item.key);
                        }}
                        items={[
                            {
                                key: "general",
                                label: <Space>
                                    <BarsOutlined />
                                    General
                                </Space>
                            },
                            {
                                key: "cost-report",
                                label: <Space>
                                    <MailOutlined />
                                    Cost Report
                                </Space>
                            },
                            {
                                key: "usage",
                                label: <Space>
                                    <BarChartOutlined />
                                    Plan usage
                                </Space>
                            },
                            {
                                key: "members",
                                label: <Space>
                                    <TeamOutlined />
                                    Members
                                </Space>
                            }, {
                                key: "billing",
                                label: <Space>
                                    <CreditCardOutlined />
                                    Billing
                                </Space>
                            }
                        ]}
                    />
                </Col>
                <Col span={18}>
                    <Outlet context={{ projectDetails: currentProject }} />
                </Col>
            </Row >
        </PageContentWrapper>
    )
}