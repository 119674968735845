const SelectClusterExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Kubernetes Cluster
            </div>
            <div className="explanation-subtitle">
                Set of nodes that run containerized applications
            </div>
            <br />

            <p>
                Microtica allows you to deploy your applications on a Kubernetes cluster. This cluster can be <span style={{ fontStyle: "italic" }}>provisioned on AWS through Microtica </span>
                or you can <span style={{ fontStyle: "italic" }}>connect a cluster</span> that is already running.
            </p>
        </>
    )
}

export default SelectClusterExplanation;