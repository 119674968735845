import { Card, Col, Progress, Space, Statistic } from "antd";
import CountUp from 'react-countup';

interface ICircleProgress {
    title: string | React.ReactNode;
    subtitle: string | React.ReactNode;
    percent: number;
    mdSize: number;
    currentUsage?: number;
    limit?: number;
}

const countUpFormatter = (value: any) => <CountUp end={Number(value)} />;

const CircleProgress = ({
    title,
    subtitle,
    percent,
    mdSize,
    currentUsage,
    limit
}: ICircleProgress) => {
    return (
        <Col xs={24} md={mdSize} style={{ display: "grid" }}>
            <Card>
                <Space direction="vertical" align="center" size="large" className="flex-align-center">
                    <div style={{ textAlign: "center" }}>
                        <div style={{ fontWeight: 500, fontSize: 16 }}>
                            {title}
                        </div>
                        <small>{subtitle}</small>
                    </div>
                    <Progress
                        type="circle"
                        strokeColor={{
                            '0%': '#108ee9',
                            '100%': '#87d068',
                        }}
                        format={() => {
                            return currentUsage !== undefined && limit !== undefined ?
                                <div style={{ fontSize: 18, color: "#112d3d", fontWeight: 500 }}>
                                    <Statistic
                                        style={{ display: "inline-block" }}
                                        valueStyle={{ fontSize: 18 }}
                                        value={currentUsage}
                                        formatter={countUpFormatter} /> / {limit}
                                </div> :
                                <div style={{ fontSize: 12 }}>
                                    No information available
                                </div>
                        }}
                        percent={percent}
                        width={120}
                    />
                </Space>
            </Card>
        </Col>
    )
}

export default CircleProgress;