import Modal from "antd/lib/modal/Modal";
import { Button, Card, Form, notification, Skeleton, Typography, Tag } from "antd";
import { useEffect, useState } from "react";
import { getKubeService } from "../../backend";
import { DeployMicroserviceRequest, DeployMicroserviceRequestAutoScaling, GetMicroserviceVersionsResponseVersionsTags, MicroserviceConfigurationItem } from "@microtica/ms-kube-sdk";
import { RequiredMark } from "antd/lib/form/Form";
import ComponentConfigFormItems from "../ComponentConfigFormItems";
import { App, ComponentSchemaInputProperties } from "../../types";
import DeployApplicationModalGeneralSection from "./DeployApplicationModalGeneralSection";
import { Link, useParams } from "react-router-dom";
import { calculateSubsequentDeployButtonClicks } from "../../utils/subsequent-button-clicks";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { deployEnvironmentButtonState } from "../../recoil/payment-plan-required";
import { currentProjectState } from "../../recoil/project";
import { PricingPlanNames } from "../../enums/enums";

interface DeployAppModalProps {
    visible: boolean;
    app: App;
    onOk: () => void;
    onCancel: () => void;
}

const DeployAppModal = ({
    visible,
    app,
    onOk,
    onCancel
}: DeployAppModalProps) => {

    const { projectId, envId, componentId } = useParams() as { projectId: string; envId: string; componentId: string };
    const [requiredMark] = useState<RequiredMark>('optional');
    const [deploying, setDeploying] = useState(false);
    const [form] = Form.useForm();
    const [required, setRequired] = useState<string[]>([]);
    const [existingConfigurations, setExistingConfigurations] = useState<MicroserviceConfigurationItem[]>([]);
    const [serviceVersions, setServiceVersions] = useState<GetMicroserviceVersionsResponseVersionsTags[]>([]);
    const [serviceVersion, setServiceVersion] = useState<GetMicroserviceVersionsResponseVersionsTags>({
        name: app.version
    });
    const [inputProps, setInputProps] = useState<ComponentSchemaInputProperties>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSchema, setLoadingSchema] = useState<boolean>(true);
    const [references, setReferences] = useState<string[]>([]);
    const [autoScaling, setAutoScaling] = useState<DeployMicroserviceRequestAutoScaling>();
    const [containerPort, setContainerPort] = useState<number>();
    const [tagCursor, setTagCursor] = useState<string>();
    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [isFetchingMoreData, setIsFetchingMoreData] = useState(false);
    const { paymentPlan: userPaymentPlan } = useRecoilValue(currentProjectState)

    const setDeployServiceButton = useSetRecoilState(deployEnvironmentButtonState);


    const { Text } = Typography;

    const fetchSchema = async (version: string) => {
        setLoadingSchema(true);
        const [{ data: { schema } }, { data: { configurations } }] = await Promise.all([
            getKubeService().getMicroservice(app!.name, projectId!, version),
            await getKubeService().getMicroserviceDeploymentStatus(
                app.name,
                app.clusterId,
                app.namespace,
                projectId!
            )
        ]);
        const domainConfigs = (configurations || [])
            .filter(c => ["MIC_DOMAIN_NAME", "MIC_DOMAIN_TLS"].includes(c.key));

        if (!schema) {
            setRequired([]);
            setInputProps({});
            setExistingConfigurations(domainConfigs);
            setLoadingSchema(false);
            return;
        }

        if (schema.properties.inputs.required?.length) {
            setRequired(schema.properties.inputs.required)
        }
        if (schema.properties.inputs.properties) {
            const configurationNames = configurations.map(configuration => configuration.key);
            const inputProps = Object.keys(schema.properties.inputs.properties)
                .filter(k => !configurationNames.includes(k))
                .reduce(
                    (obj, key) => Object.assign(obj, {
                        [key]: {
                            ...(schema.properties.inputs.properties as ComponentSchemaInputProperties)[key],
                            hidden: configurationNames.includes(key)
                        }
                    }), {})

            setInputProps(inputProps as ComponentSchemaInputProperties);

            const existingConfigurations = Object.keys(schema.properties.inputs.properties)
                .filter(key => configurationNames.includes(key))
                .map((item) => ({ ...configurations.find(c => c.key === item)! }));

            setExistingConfigurations(existingConfigurations.concat(domainConfigs));
        }
        setLoadingSchema(false);
    }
    const fetchMoreData = async () => {
        if (!isFetchingMoreData && pagination.offset !== -1) {
            setIsFetchingMoreData(true);
            await fetchMicroserviceVersions();
            setIsFetchingMoreData(false);
        }
    }

    const fetchMicroserviceVersions = async () => {
        try {
            const { data: { versions } } = await getKubeService().getMicroserviceVersions(app!.name, projectId!, String(pagination.offset), String(pagination.limit), tagCursor);
            const { tags, lastElementCursor } = versions!;

            const fetchedVersions = tags
                .sort((a, b) => b.date! - a.date!)
                .sort((a) => a.name === "latest" ? -1 : 0);

            const allVersions = serviceVersions.concat(fetchedVersions);
            setServiceVersions(allVersions);

            // If the number of returned tags is less than the pagination limit (no more data to fetch)
            // set offset to -1 (prevents from making any more requests)
            fetchedVersions.length === pagination.limit ?
                setPagination({ ...pagination, offset: pagination.offset + pagination.limit }) :
                setPagination({ ...pagination, offset: -1 });

            setTagCursor(lastElementCursor);
        } catch (error) {
            // sometimes Service Temporarily Unavailable is thrown (when there are many retrieved tags e.g. 70+)
            if ((error as any).response!.data!.code === 503) {
                fetchMicroserviceVersions();
            }
            else {
                notification.error({
                    message: "Error fetching versions",
                    description: (error as any).response!.data!.message
                });
            }
        }
    }

    const fetchConfigurations = async () => {
        try {
            const { data: deploymentStatus } = await getKubeService().getMicroserviceDeploymentStatus(
                app.name,
                app.clusterId,
                app.namespace,
                projectId!
            );

            setAutoScaling({
                memoryUtilization: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MEMORY_UTILIZATION")!.value!),
                minReplicas: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MIN_REPLICAS")!.value!),
                maxReplicas: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MAX_REPLICAS")!.value!),
                cpuUtilization: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_CPU_UTILIZATION")!.value!),
                cpu: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_CPU")!.value!),
                maxCpu: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MAX_CPU")!.value!),
                memory: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MEMORY")!.value!),
                maxMemory: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MAX_MEMORY")!.value!),
            });

            const micContainerPortItem = deploymentStatus.configurations.find(c => c.key === "MIC_CONTAINER_PORT");
            if (micContainerPortItem) {
                setContainerPort(Number(micContainerPortItem.value));
            }
        } catch (error) {
            notification.error({
                message: "Error fetching configurations",
                description: (error as any).response!.data!.message
            });
        }
    }

    const loadData = async () => {
        setLoading(true);
        await Promise.all([
            fetchMicroserviceVersions(),
            fetchConfigurations()
        ]);
        setLoading(false);
    }

    useEffect(() => {
        if (serviceVersion) {
            fetchSchema(serviceVersion.name!);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceVersion]);

    useEffect(() => {
        if (app) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app]);


    const handleDeploy = async (values: { version: string, [key: string]: any }) => {
        try {
            setDeploying(true);
            const { version, ...envVars } = values;

            const configurations = (existingConfigurations || [])
                .filter(config => config.value !== undefined)

            const envVarsConfigs = Object.keys(envVars)
                .filter(key => envVars[key] !== undefined)
                .map(key => ({ key, value: envVars[key], reference: false, sensitive: !!inputProps[key].sensitive }))

            const microserviceRequest: DeployMicroserviceRequest = {
                deployment: {
                    image: version,
                    configurations: configurations.concat(envVarsConfigs),
                    containerPort
                },
                autoScaling: userPaymentPlan?.id === PricingPlanNames.FREE ? undefined : autoScaling
            };

            await getKubeService().deployMicroservice(
                app.name,
                app.clusterId,
                app.namespace,
                projectId!,
                microserviceRequest
            )

            onOk();

            setDeployServiceButton((prevState) => {
                return calculateSubsequentDeployButtonClicks(prevState);
            })

            notification.success({
                message: `Application deployed`,
                description: `Application ${app!.name} was successfully deployed`,
            });

        } catch (error) {
            notification.error({
                message: "Error deploying application",
                description: <pre className="white-space-pre-wrap">{(error as any).response.data.message}</pre>
            });

            setDeploying(false);
        }
    }

    const handleServiceVersionChange = (value: string) => {
        setServiceVersion(serviceVersions.find(({ name }) => name === value)!);
    }

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            handleDeploy(values);
        } catch (error) {

        }
    }

    const handleKeyUp = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    }

    return (
        <Modal
            title={`Deploy ${app.name} Application`}
            centered
            closable
            open={visible}
            width="800px"
            onCancel={onCancel}
            footer={[
                <Button
                    key="cancel"
                    onClick={onCancel}
                >
                    Cancel
                </Button>,
                <Button
                    type="primary"
                    key="submit"
                    htmlType="submit"
                    disabled={loadingSchema}
                    loading={deploying}
                    onClick={handleSubmit}
                >
                    Deploy
                </Button>
            ]}
        >
            {!loading ?
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={requiredMark}
                    onKeyUp={handleKeyUp}
                >
                    <div style={{ marginTop: 20 }}>
                        <DeployApplicationModalGeneralSection
                            serviceVersion={serviceVersion}
                            serviceVersions={serviceVersions}
                            handleServiceVersionChange={handleServiceVersionChange}
                            fetchMoreData={fetchMoreData}
                            loading={isFetchingMoreData}
                        />
                    </div>
                    <br />
                    <Card type="inner">
                        <Card.Meta
                            title="Environment Variables"
                            description="Environment variables will be automatically injected in the service runtime"
                        />
                        <br />
                        <ComponentConfigFormItems
                            formItems={inputProps}
                            required={required}
                            setFieldsValue={form.setFieldsValue}
                            getFieldValue={form.getFieldValue}
                            references={references}
                            handleUpdateReferences={setReferences}
                            loading={loadingSchema}
                            emptyText={
                                <>
                                    <div>
                                        <Tag color="default">No environment variables</Tag>
                                    </div>
                                    <br />
                                </>
                            }
                        />
                        {!loadingSchema &&
                            <div style={{ marginTop: !!Object.keys(inputProps).length ? "24px" : "-4px" }}>
                                <Text type="secondary">
                                    Only environment variables of the chosen version that aren't contained in the environment variables of the current deployed version are displayed here.
                                    You can change your current environment variables in <Link to={`/projects/${projectId}/environments/${envId}/components/${componentId}/${app.clusterId}/apps/${app.namespace}/${app.name}/settings/environment`}>App Settings</Link>
                                </Text>
                            </div>
                        }
                    </Card>
                </Form> :
                <Skeleton />
            }
        </Modal >
    )
}

export default DeployAppModal;