import { Card, Col, Divider, Row, Skeleton, Form, Input, Button, Radio, Select, notification, Dropdown, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCloudService, getCostOptimizationService } from "../../backend";
import { CreateReportRequest, CreateReportRequestGranularityEnum, GetReportsResponseReports, GetReportsResponseReportsStateEnum, HandleReportEntitiesStateRequest, UpdateReportRequest, UpdateReportRequestNewGranularityEnum, UpdateReportStateRequestNewStateEnum } from "@microtica/ms-cloud-cost-optimizer-sdk";
import capitalizeFirstLetter from "../../utils/capitalize-first-letter";
import { getUsersWithinProject } from "../../utils/project/get-users";
import { AWS_REGIONS } from "../../enums/enums";
import { useDoubleConfigurationModal } from "../../contexts/Modal";

interface ScheduleActionProps {
    name: string,
    awsAccounts: string[],
    awsRegions: string[],
    emails: string[],
    granularity: CreateReportRequestGranularityEnum
}

const ProjectSettingsCostReport = () => {
    const { open: openConfirmModal } = useDoubleConfigurationModal();
    const { projectId } = useParams();
    const [form] = Form.useForm();
    const [loadingData, setLoadingData] = useState(true);
    const [btnLoading, setBtnLoading] = useState(true);
    const [report, setReport] = useState<GetReportsResponseReports>();
    const [userEmails, setUserEmails] = useState<string[]>([]);
    const [awsAccounts, setAwsAccounts] = useState<string[]>([]);
    const [reportAction, setReportAction] = useState<"Create" | "Update">("Create");

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = async () => {
        try {
            const [{ data: { reports } }, { userProfiles }, { data: { awsAccounts } }] = await Promise.all([
                getCostOptimizationService().listReports(projectId!),
                getUsersWithinProject(projectId!),
                getCloudService().getAwsAccounts(projectId!)
            ]);

            const report = reports[0];
            setReport(report);
            setUserEmails(Object.values(userProfiles).map(user => user.email));
            setAwsAccounts(awsAccounts.map(acc => (acc.id)));
            if (report) {
                setReportAction("Update");
            } else {
                setReportAction("Create");
            }
        } catch (e) {
            notification.error({
                message: "Error getting cost report information"
            })
        } finally {
            setLoadingData(false);
            setBtnLoading(false);
        }
    }

    const deleteReport = async () => {
        openConfirmModal({
            title: "Delete cost report",
            description:
                <Space direction="vertical">
                    <Divider style={{ margin: "5px 0 15px 0" }} />
                    <div>This report will be <b>deleted</b> and you won't be receiving emails anymore.</div>
                </Space>,
            confirmation: report!.reportName,
            okText: "Delete",
            cancelText: "Cancel",
            onOk: async () => {
                setBtnLoading(true);
                await getCostOptimizationService().deleteReport(report!.id, projectId!);
                // reset fields
                form.setFieldsValue({
                    name: undefined,
                    awsAccounts: undefined,
                    awsRegions: undefined,
                    emails: undefined,
                    granularity: CreateReportRequestGranularityEnum.WEEKLY
                })
                notification.success({
                    message: "Successfully deleted report"
                });
                loadData();
            }
        });
    }

    const handleReportAction = async ({
        name,
        awsAccounts,
        awsRegions,
        emails,
        granularity
    }: ScheduleActionProps) => {
        setBtnLoading(true);
        if (reportAction === "Create") {
            const createReportRequest: CreateReportRequest = {
                name,
                awsAccountIds: awsAccounts,
                regions: awsRegions,
                emails,
                granularity: granularity as CreateReportRequestGranularityEnum
            }

            await getCostOptimizationService().createReport(projectId!, createReportRequest);
            notification.success({
                message: "Successfully created report"
            });
        } else {
            const updateReportRequest: UpdateReportRequest = {
                newName: name,
                newGranularity: granularity as unknown as UpdateReportRequestNewGranularityEnum
            }
            const updateReportEntitiesStateRequest: HandleReportEntitiesStateRequest = {
                awsAccountIds: awsAccounts,
                regions: awsRegions,
                emails
            }

            await Promise.all([
                getCostOptimizationService().updateReport(report!.id, projectId!, updateReportRequest),
                getCostOptimizationService().handleReportEntitiesState(report!.id, projectId!, updateReportEntitiesStateRequest)
            ]);
            notification.success({
                message: "Successfully updated report"
            });
        }
        loadData();
    }

    const updateReportState = async (newState: UpdateReportStateRequestNewStateEnum) => {
        const formattedStateName = capitalizeFirstLetter(newState);
        const statusColor = newState === UpdateReportStateRequestNewStateEnum.DISABLED ? "red" : "green";
        const explanation = newState === UpdateReportStateRequestNewStateEnum.DISABLED ? "you won't be receiving emails anymore" : "you will start receiving emails"

        openConfirmModal({
            title: "Update report state",
            description:
                <Space direction='vertical'>
                    <Divider style={{ margin: "5px 0 15px 0" }} />
                    <div>This report will be <b style={{ color: statusColor }}>{formattedStateName}</b> and {explanation}.</div>
                </Space>,
            confirmation: report!.reportName,
            okText: formattedStateName.slice(0, -1),
            cancelText: "Cancel",
            onOk: async () => {
                setBtnLoading(true);
                await getCostOptimizationService().updateReportState(report!.id, projectId!, {
                    newState
                });
                notification.success({
                    message: "Successfully updated report state"
                });
                loadData();
            }
        });
    }

    return (
        <Card bordered>
            <Card.Meta
                title="Cost report"
                description="Receive weekly/monthly reports on your email. They provide an overview into your AWS costs, current month-to-date spend and estimated savings."
            />
            <Divider />
            {loadingData ?
                <Skeleton active /> :
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        name: report?.reportName,
                        awsAccounts: report?.awsAccountIds,
                        awsRegions: report?.regions,
                        emails: report?.emails,
                        granularity: report?.granularity || CreateReportRequestGranularityEnum.WEEKLY
                    }}
                    requiredMark={"optional"}
                    onFinish={handleReportAction}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        required
                        rules={[{ required: true, message: 'Please enter report name!' }]}
                    >
                        <Input placeholder="Enter report name" />
                    </Form.Item>
                    <Form.Item
                        name="awsAccounts"
                        label="AWS accounts"
                        required
                        rules={[{ required: true, message: 'Please select AWS accounts!' }]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                return (option?.label || "").toLowerCase().includes(input.toLowerCase())
                            }}
                            mode="multiple"
                            allowClear
                            placeholder="Please select AWS accounts"
                            options={awsAccounts.map(accountId => ({ label: accountId, value: accountId }))}
                        />
                    </Form.Item>
                    <Form.Item
                        name="awsRegions"
                        label="AWS regions"
                        required
                        rules={[{ required: true, message: 'Please select AWS regions!' }]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                return (option?.label || "").toLowerCase().includes(input.toLowerCase())
                            }}
                            mode="multiple"
                            allowClear
                            placeholder="Please select AWS regions"
                            options={AWS_REGIONS.map(region => ({ label: region.name, value: region.value }))}
                        />
                    </Form.Item>
                    <Form.Item
                        name="emails"
                        label="Notify your team"
                        required
                        rules={[{ required: true, message: 'Please select emails!' }]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                return (option?.label || "").toLowerCase().includes(input.toLowerCase())
                            }}
                            mode="multiple"
                            allowClear
                            placeholder="Please select emails"
                            options={userEmails.map(email => ({ label: email, value: email }))}
                        />
                    </Form.Item>
                    <Form.Item
                        name="granularity"
                        label="Choose frequency of report"
                        required
                    >
                        <Radio.Group>
                            <Radio value={CreateReportRequestGranularityEnum.WEEKLY}>
                                {capitalizeFirstLetter(CreateReportRequestGranularityEnum.WEEKLY)}
                            </Radio>
                            <Radio value={CreateReportRequestGranularityEnum.MONTHLY}>
                                {capitalizeFirstLetter(CreateReportRequestGranularityEnum.MONTHLY)}
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Divider />
                    <Row className={report?.state ? "flex-justify-space-between" : "flex-justify-end"}>
                        {report?.state ?
                            <Col style={{ marginBottom: "12px" }}>
                                <Tag color={report?.state === GetReportsResponseReportsStateEnum.ENABLED ? "success" : "error"}>
                                    Report status: {report?.state}
                                </Tag>
                            </Col> :
                            null
                        }
                        <Col>
                            {reportAction === "Create" ?
                                <Button htmlType="submit" loading={btnLoading}>
                                    Create report
                                </Button> :
                                <Dropdown.Button
                                    trigger={["click"]}
                                    type="default"
                                    htmlType="submit"
                                    loading={btnLoading}
                                    menu={{
                                        items: [
                                            {
                                                key: "update-state",
                                                label: `${report?.state === GetReportsResponseReportsStateEnum.ENABLED ? "Disable" : "Enable"} report`,
                                                onClick: () => updateReportState(report?.state === GetReportsResponseReportsStateEnum.ENABLED ? UpdateReportStateRequestNewStateEnum.DISABLED : UpdateReportStateRequestNewStateEnum.ENABLED)
                                            },
                                            {
                                                key: "delete",
                                                label: "Delete report",
                                                danger: true,
                                                onClick: deleteReport
                                            }
                                        ]
                                    }}
                                >
                                    Save
                                </Dropdown.Button>
                            }
                        </Col>
                    </Row>
                </Form>
            }
        </Card>
    )
}

export default ProjectSettingsCostReport;