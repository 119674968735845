import { CrownOutlined, InfoCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Col, Row, Skeleton, Space, Statistic, Tag, Tooltip } from "antd";
import GridList from "../../components/GridList";
import AwsLogo from "../../assets/aws-logo.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { ProjectDetails } from "../../types";
import Paragraph from "antd/es/typography/Paragraph";
import { useRecoilState } from "recoil";
import { projectsState } from "../../recoil/project";
import { getCostOptimizationService, getEnvironmentService, getProjectService } from "../../backend";
import { handleSetMixpanelUserGroups } from "../../backend/tracking";
import { DEFAULT_COST_ALLOCATION_TAG } from "../../enums/enums";
import { GetAgregatedForecastResponse, GetAgregatedHistoryResponse } from "@microtica/ms-cloud-cost-optimizer-sdk";
import { convertToCurrency } from "../../utils/conversion";

interface AggregatedHistory extends GetAgregatedHistoryResponse {
    error?: string;
}

interface AggregatedForecast extends GetAgregatedForecastResponse {
    error?: string;
}


export default function Projects() {
    const [projects, setProjects] = useRecoilState(projectsState)
    const [loadingProjects, setLoadingProjects] = useState(true);
    const [loadingCosts, setLoadingCosts] = useState(true);
    const [loadingEnvs, setLoadingEnvs] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            setLoadingProjects(true);

            const { data: { projects } } = await getProjectService().listProjects();
            const projectDetails = await Promise.allSettled(projects.map((project) =>
                getProjectService().getProject(project.id)
            ));

            const finalProjects = projects.map(p => {
                const details = projectDetails.find(e => e.status === "fulfilled" && e.value.data.id === p.id);
                if (details && details.status === "fulfilled") {
                    return details.value.data;
                } else {
                    return p;
                }
            }) as ProjectDetails[];

            setProjects(finalProjects);
            handleSetMixpanelUserGroups(finalProjects.map(item => item.id));
            setLoadingProjects(false);

            const projectsWithEnvs = await Promise.all(finalProjects.map(async (p) => {
                const { data: { stages } } = await getEnvironmentService().getStages(p.id);
                return {
                    ...p,
                    environments: stages,
                };
            }));
            setProjects(projectsWithEnvs);
            setLoadingEnvs(false);

            setProjects(
                await Promise.all(projectsWithEnvs.map(async (p) => {
                    const [
                        { data: costHistory },
                        { data: costForecast }
                    ] = await Promise.all([
                        getCostOptimizationService().getAggregatedHistory(p.id, DEFAULT_COST_ALLOCATION_TAG)
                            .catch(e => ({ data: { error: e.response?.data?.message as string } })),
                        getCostOptimizationService().getAggregatedForecast(p.id, DEFAULT_COST_ALLOCATION_TAG)
                            .catch(e => ({ data: { error: e.response?.data?.message as string } }))
                    ]);

                    return {
                        ...p,
                        cost: {
                            error: (costHistory as AggregatedHistory).error || (costForecast as AggregatedForecast).error,
                            estimated: (costForecast as AggregatedForecast)?.estimatedCostForTheCurrentMonth?.total,
                            current: (costHistory as AggregatedHistory & { error: string }).costForTheCurrentMonth?.total
                        }
                    };
                }))
            );
            setLoadingCosts(false);
        }

        loadData();
    }, []);

    const ProjectTitle = ({ projectName }: { projectName: string }) => (
        <div className="flex-justify-space-between flex-align-center" style={{ fontSize: "18px" }}>
            {projectName}
        </div>
    )

    const ProjectDescription = (props: { envs: string[], cost?: { current?: number; estimated?: number; error?: string; } }) => (
        <Space direction="vertical" size="middle" className="flex-justify-space-between" style={{ margin: "14px 0" }}>
            <Row gutter={16}>
                <Col span={24}>
                    <Statistic
                        title="Environments"
                        loading={loadingEnvs}
                        formatter={(value) =>
                            <Paragraph style={{ width: "25vw", margin: 0 }} ellipsis={{ rows: 1, tooltip: value }}>
                                {value}
                            </Paragraph>
                        }
                        value={props.envs.join(", ") || "No environments"}
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Statistic
                        title="Current month costs"
                        loading={loadingCosts}
                        value={props.cost?.current}
                        formatter={(value) => {
                            if (!props.cost?.error) {
                                return <>
                                    {convertToCurrency(parseFloat(value as string))} <img src={AwsLogo} style={{ width: "25px" }} alt="aws" />
                                </>;
                            } else {
                                return <>
                                    <Tooltip title={props.cost?.error}>
                                        <InfoCircleOutlined /> Not available
                                    </Tooltip>
                                </>
                            }
                        }}
                    />
                </Col>
                <Col span={12}>
                    <Statistic
                        title="Estimated month end costs"
                        loading={loadingCosts}
                        value={props.cost?.estimated}
                        formatter={(value) => {
                            if (!props.cost?.error) {
                                return <>
                                    {convertToCurrency(parseFloat(value as string))} <img src={AwsLogo} style={{ width: "25px" }} alt="aws" />
                                </>;
                            } else {
                                return <>
                                    <Tooltip title={props.cost?.error}>
                                        <InfoCircleOutlined /> Not available
                                    </Tooltip>
                                </>
                            }
                        }}
                    />
                </Col>
            </Row>
        </Space>
    )

    return (
        loadingProjects ? <Skeleton /> :
            <Space direction="vertical" size="large" className="full-width">
                <GridList
                    filter={projects.length > 6}
                    filterPlaceholder="Search project"
                    itemsInRow={3}
                    // disableEmptyState={false}
                    dataSource={projects.map(project => ({
                        key: project.id,
                        title: <ProjectTitle projectName={project.name} />,
                        filterMetadata: `${project.id} ${project.name} ${project.description}`,
                        extra:
                            <Link to={`/projects/${project.id}/settings`} onClick={(e) => {
                                e.stopPropagation();
                            }}>
                                <Button icon={<SettingOutlined />} />
                            </Link>,
                        description: <ProjectDescription
                            envs={(project.environments || []).map(e => e.name)}
                            cost={project.cost}
                        />,
                        tags: project.paymentPlan ? [
                            {
                                position: "bottom",
                                value:
                                    <Tag color="blue">
                                        <Space>
                                            {project.paymentPlan?.id !== "FREE" ? <CrownOutlined /> : null}
                                            {project.paymentPlan?.name}
                                        </Space>
                                    </Tag>
                            }
                        ] : [],
                        link: `/projects/${project.id}/environments`
                    }))}
                />
            </Space>
    )
}