export const formatScaled = (data: number, scale: number, suffixes: string[], decimals = 2) => {
    const dm = decimals < 0 ? 0 : decimals;

    let i = Math.floor(Math.log(Math.abs(data)) / Math.log(scale));

    i = Math.max(0, Math.min(i, suffixes.length - 1));

    const val = `${parseFloat((data / (scale ** i)).toFixed(dm))}`;

    const units = suffixes[i];

    return {
        val: [val],
        units: [units],
    };
};

export const formatScaledReverse = (data: number, scale: number, suffixes: string[]) => {
    let scaledData = data;
    for (let i = 0; i < suffixes.length; i++) {
        scaledData = data * Math.pow(scale, i);
        if (scaledData >= 1) {
            return {
                val: [scaledData.toFixed(1)], // Limit to 1 decimal place
                units: [suffixes[i]],
            };
        }
    }
    // If no whole number found, return the original data in seconds
    return {
        val: [data.toString()],
        units: ['\u00a0s'],
    };
};

export const formatBytes = (data: number, chartMetricUnit?: {name: string; value: number}) => {
    if(chartMetricUnit) {
        const decimalPlaces = chartMetricUnit.name === "Gigabytes" ? 6 :
                              chartMetricUnit.name === "Megabytes" ? 3 :
                              2;
        return {
                val: [`${parseFloat((data / chartMetricUnit.value).toFixed(decimalPlaces))}`],
                units: [chartMetricUnit.name]
            }
    }
    return formatScaled(data,
        1024,
        // \u00a0 is a space, written in order to prevent it from being stripped by React.
        ['\u00a0B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        1)
    };

const nanosPerS = 1000 * 1000 * 1000;
const nanosPerMin = 60 * nanosPerS;
const nanosPerH = 60 * nanosPerMin;
const nanosPerD = 24 * nanosPerH;


export const formatNanoseconds = (dataInNanoseconds: number, chartMetricUnit?: {name: string; value: number}) => {
    if(chartMetricUnit) {        
        const decimalPlaces = chartMetricUnit.name === "Seconds" ? 6 : 3;
        return {
            val: [`${parseFloat((dataInNanoseconds / chartMetricUnit.value).toFixed(decimalPlaces))}`],
            units: [chartMetricUnit.name]
        }
    }
    const absRounded = Math.abs(Math.round(dataInNanoseconds));
    const days = Math.floor(absRounded / nanosPerD);
    const hours = Math.floor((absRounded % nanosPerD) / nanosPerH);
    const min = Math.floor((absRounded % nanosPerH) / nanosPerMin);
    const seconds = Math.floor((absRounded % nanosPerMin) / nanosPerS);

    if (days > 0) {
        return {
            val: [`${days * Math.sign(dataInNanoseconds)}`, `${hours}`],
            units: ['days', 'hours'],
        };
    }
    if (hours > 0) {
        return {
            val: [`${hours * Math.sign(dataInNanoseconds)}`, `${min}`],
            units: ['hours', 'min'],
        };
    }
    if (min > 0) {
        return {
            val: [`${min * Math.sign(dataInNanoseconds)}`, `${seconds}`],
            units: ['min', 's'],
        };
    }
    return formatScaled(dataInNanoseconds,
        1000,
        // \u00a0 is a space, which can sometimes be stripped by React if written as ' '.
        // \u00b5 is μ.
        ['ns', '\u00b5s', 'ms', '\u00a0s'],
        1);
};

export const formatSeconds = (dataInSeconds: number, chartMetricUnit?: {name: string; value: number}) => {
    if(chartMetricUnit) {
        return {
            val: [`${parseFloat((dataInSeconds / chartMetricUnit.value).toFixed(3))}`],
            units: [chartMetricUnit.name]
        }
    }

    return formatScaledReverse(dataInSeconds,
        1000,
        // \u00a0 is a space, which can sometimes be stripped by React if written as ' '.
        // \u00b5 is μ.
        ['\u00a0s', 'ms', '\u00b5s','ns'],
    );
}