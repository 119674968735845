import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import RecoilNexus from "recoil-nexus";
import PaymentPlanRequiredModal from "./components/modals/PaymentPlanRequiredModal";
import { ConfigProvider } from 'antd';

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <RecoilNexus /> {/** Used for getting/setting Recoil state anywhere */}
      <PaymentPlanRequiredModal />
      <ConfigProvider
        theme={{
          token: {
            colorLink: "#097BB5",
            colorInfo: "#097BB5",
            colorPrimary: "#097BB5"
          },
          components: {
            Collapse: {
              headerBg: "white",
              lineHeight: 1.5,
              borderRadius: 6
            },
            Card: {
              borderRadius: 6
            },
            Table: {
              borderRadius: 6
            }
          },
        }}
      >
        <App />
      </ConfigProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
