import {
    GetAgregatedForecastResponse,
    GetAgregatedHistoryResponse,
    ListSchedulesResponseRules,
    ListSchedulesResponseRulesStateEnum,
    UpdateScheduleRequest,
    UpdateScheduleStateRequestNewStateEnum
} from "@microtica/ms-cloud-cost-optimizer-sdk";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AwsLogo from "../../assets/aws-logo.svg";
import { getCloudService, getCostOptimizationService, getEnvironmentService } from "../../backend";
import YearlyCostWidget from "../../components/dashboards/widgets/YearlyCostWidget";
import MetricsWidget from "../../components/dashboards/widgets/MetricsWidget";
import { Button, Card, Col, notification, Row, Space, Divider, Statistic, Dropdown, Select, Tooltip, Badge, ConfigProvider, Skeleton } from "antd";
import ColumnWidget from "../../components/dashboards/widgets/ColumnWidget";
import Heading from "../../components/Heading";
import { CheckCircleOutlined, FilterOutlined, LockOutlined } from "@ant-design/icons";
import { ISavingScheduleAggregatedUtilization, WidgetColor } from "../../types";
import { convertToCurrencyWithoutFractionDigits } from "../../utils/conversion";
import ExplanationButton from "../../components/explanations/ExplanationButton";
import CostDashboardExplorer from "../../components/explanations/CostDashboardExplorerExplanation";
import CostDashboardSavingSchedulesExplanation from "../../components/explanations/CostDashboardSavingSchedulesExplanation";
import { TOTAL_HOURS_IN_MONTH, calculateUtilization, getLocalTimeZone } from "../../utils/cron";
import DisabledFeatureInfoCard from "../../components/dashboards/DisabledFeatureInfoCard";
import CreateSavingScheduleModal from "../../components/modals/CreateSavingScheduleModal";
import SavingScheduleWidget from "../../components/dashboards/widgets/SavingScheduleWidget";
import { useDoubleConfigurationModal } from "../../contexts/Modal";
import capitalizeFirstLetter from "../../utils/capitalize-first-letter";
import { DEFAULT_COST_ALLOCATION_TAG, PricingPlanNames } from "../../enums/enums";
import { currentProjectState } from "../../recoil/project";
import { useRecoilValue } from "recoil";
import { getExpectedMonthlySavingsAndCost } from "../../utils/environment/expected-monthly-savings-and-cost";
import MessageCard from "../../components/cards/MessageCard";
import PageContentWrapper from "../../components/PageContentWrapper";
import { PageHeader } from "@ant-design/pro-layout";
import { GetAwsAccountResponse } from "@microtica/ms-aws-sdk";
import ConnectAwsAccountModal from "../../components/settings/ConnectAwsAccountModal";
import { trackSpecCloudAccountInit } from "../../backend/tracking/user-settings";
import Title from "antd/es/typography/Title";
import CostExplorerBlurScreenshot from "../../assets/cost-explorer.png";
import { GetStagesItem } from "@microtica/ms-engine-sdk";
import CloudAccountLabel from "../../components/CloudAccountLabel";

interface EnvironmentExtended extends GetStagesItem {
    schedule?: ListSchedulesResponseRules;
    savingUtilization?: {
        aggregatedUtilization?: ISavingScheduleAggregatedUtilization;
        expectedMonthlySavings?: number;
        estimatedEc2AndRdsCost?: number;
        expectedMonthlySavingsPercentage?: number;
        estimatedAllServicesCost?: number;
    }
}

const CostExplorer = () => {
    const LOCAL_TIME_ZONE = getLocalTimeZone();

    const { projectId } = useParams() as { projectId: string; };
    const navigate = useNavigate();
    const { open: openConfirmModal } = useDoubleConfigurationModal();
    const currentProject = useRecoilValue(currentProjectState);
    const [cloudAccounts, setCloudAccounts] = useState<GetAwsAccountResponse[]>([]);
    const [cloudAccountId, setCloudAccountId] = useState<string>("");
    const [loadingCloudAccounts, setLoadingCloudAccounts] = useState(true);
    const [envs, setEnvs] = useState<EnvironmentExtended[]>([]);
    const [env, setEnv] = useState<EnvironmentExtended>();

    const [isScheduleLoading, setIsScheduleLoading] = useState(true);
    const [isCostExplorerLoading, setIsCostExplorerLoading] = useState(true);
    const [isCostExplorerEnabled, setIsCostExplorerEnabled] = useState(!!currentProject.paymentPlan?.limitations?.cloudCostOptimizer);

    // Saving schedule (advanced plan)
    const [isScheduleCreating, setIsScheduleCreating] = useState(false);
    const [savingScheduleAction, setSavingScheduleAction] = useState<"Create" | "Update">("Create");
    const [cronExpression, setCronExpression] = useState<{ startExpression: string; stopExpression: string; } | undefined>();
    const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
    const [isScheduleEnabled, setIsScheduleEnabled] = useState(!!currentProject.paymentPlan?.limitations?.savingSchedules);

    // Cost explorer (starter plan)
    const [estimatedMonthTrend, setEstimatedMonthTrend] = useState(0);
    const [estimatedYearTrend, setEstimatedYearTrend] = useState(0);
    const [totalYearCost, setTotalYearCost] = useState(0);
    const [montlyCostByService, setMontlyCostByService] = useState<{ value: number; type: string; entity: string; }[]>([]);
    const [yearCostByMonth, setYearCostByMonth] = useState<any[]>([]);
    const [aggregatedHistory, setAggregatedHistory] = useState<GetAgregatedHistoryResponse>();
    const [aggregatedForecast, setAggregatedForecast] = useState<GetAgregatedForecastResponse>();

    const [connectAwsAccountModalVisible, setConnectAwsAccountModalVisible] = useState(false);

    useEffect(() => {
        Promise.all([
            loadCloudAccounts(),
            loadSchedules()
        ]);
    }, []);

    useEffect(() => {
        if (isCostExplorerEnabled && cloudAccountId) {
            loadCostExplorer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cloudAccountId]);

    const loadCloudAccounts = async (defaultAccountId?: string) => {
        const { data: { awsAccounts } } = await getCloudService().getAwsAccounts(projectId);
        setCloudAccounts(awsAccounts);
        setCloudAccountId(defaultAccountId || awsAccounts[0]?.id);
        setLoadingCloudAccounts(false);
    }

    const loadSchedules = async () => {
        const [
            { data: { stages } },
            { data: { rules: schedules } }
        ] = await Promise.all([
            getEnvironmentService().getStages(projectId),
            getCostOptimizationService().listSchedules(projectId)
                .catch(e => {
                    if (e.response?.data.code === 402) {
                        setIsScheduleEnabled(false);
                        return { data: { rules: [] } };
                    } else {
                        throw e;
                    }
                })
        ]);

        setEnvs(
            await Promise.all(stages.filter(s => s.cloudProvider === "aws").map(env => {
                const schedule = schedules.find(s => s.awsAccountId === env.awsAccountId && s.awsRegion && env.awsRegion);

                if (env.awsAccountId && env.awsRegion) {
                    return getExpectedMonthlySavingsAndCost({
                        awsAccountId: env.awsAccountId,
                        awsRegion: env.awsRegion,
                        envId: env.id,
                        projectId
                    }).then(saving => {
                        if (schedule) {
                            const aggregatedUtilization = calculateUtilization(schedule, LOCAL_TIME_ZONE);
                            const coeficient = (+(aggregatedUtilization?.totalUtilizedHours / TOTAL_HOURS_IN_MONTH).toFixed(2));
                            return {
                                ...env,
                                schedule,
                                savingUtilization: {
                                    aggregatedUtilization,
                                    estimatedEc2AndRdsCost: saving.environment.estimatedEc2AndRdsCost,
                                    estimatedAllServicesCost: saving.awsAccount.estimatedAllServicesCost,
                                    expectedMonthlySavings: saving.environment.expectedMonthlySavings * coeficient
                                }
                            }
                        } else {
                            const coeficient = (+(430 / TOTAL_HOURS_IN_MONTH).toFixed(2));
                            return {
                                ...env,
                                savingUtilization: {
                                    estimatedEc2AndRdsCost: saving.environment.estimatedEc2AndRdsCost,
                                    estimatedAllServicesCost: saving.awsAccount.estimatedAllServicesCost,
                                    expectedMonthlySavings: saving.environment.expectedMonthlySavings * coeficient
                                }
                            }
                        }
                    });
                }

                return env;
            }))
        );
        setIsScheduleLoading(false);
    }

    const loadCostExplorer = async () => {
        try {
            setIsCostExplorerLoading(true);

            const estimatedEc2Cost = 0;
            const estimatedRdsCost = 0;
            const estimatedAllServicesCost = 0;

            const [
                { data: costHistory },
                { data: costForecast },
            ] = await Promise.all([
                getCostOptimizationService().getAggregatedHistory(projectId, DEFAULT_COST_ALLOCATION_TAG),
                getCostOptimizationService().getAggregatedForecast(projectId, DEFAULT_COST_ALLOCATION_TAG).catch(e => {
                    if (e.response?.data?.code === "DataUnavailableException") {
                        return { data: undefined };
                    } else {
                        throw e;
                    }
                })
            ]).catch(err => {
                if (err?.code === 402) {
                    setIsCostExplorerEnabled(false);
                }
                throw err;
            });

            // Cost explorer
            if (costForecast && costHistory) {
                setEstimatedMonthTrend(Math.round(
                    (costHistory.costForTheLastMonth.total - costForecast.estimatedCostForTheCurrentMonth.total) /
                    costHistory.costForTheLastMonth.total * 100
                ));
                setEstimatedYearTrend(Math.round(
                    (costHistory.costByAWSAccountForTheCurrentYear.cost[cloudAccountId].total - costForecast.estimatedCostByAWSAccountForTheNextYear.cost[cloudAccountId].total) /
                    costHistory.costByAWSAccountForTheCurrentYear.cost[cloudAccountId].total * 100
                ));

                const {
                    ec2: currentEc2Cost,
                    rds: currentRdsCost,
                    allServices: currentAllServicesCost
                } = costHistory.costForTheCurrentMonth.ids[cloudAccountId];

                setMontlyCostByService([
                    { value: estimatedEc2Cost > currentEc2Cost ? estimatedEc2Cost - currentEc2Cost : 0, type: "Expected additional cost", entity: "EC2" },
                    { value: currentEc2Cost, type: "Cost to date", entity: "EC2" },
                    { value: estimatedRdsCost > currentRdsCost ? estimatedRdsCost - currentRdsCost : 0, type: "Expected additional cost", entity: "RDS" },
                    { value: currentRdsCost, type: "Cost to date", entity: "RDS" },
                    { value: estimatedAllServicesCost > currentAllServicesCost ? estimatedAllServicesCost - currentAllServicesCost : 0, type: "Expected additional cost", entity: "All Services" },
                    { value: currentAllServicesCost, type: "Cost to date", entity: "All Services" }
                ]);

                const { months, total } = costHistory.costByAWSAccountForTheCurrentYear.cost[cloudAccountId];
                setYearCostByMonth(months.map(m => ({ ...m, account: cloudAccountId })));
                setTotalYearCost(Math.round(total));
            }
            setAggregatedHistory(costHistory);
            setAggregatedForecast(costForecast);
        } catch (error) {
            if ((error as any).response?.status === 403) {
                setIsCostExplorerEnabled(false);
            }

            notification.error({
                message: "Error getting cost metrics",
                description: (error as any).response?.data?.message
            })
        } finally {
            setIsCostExplorerLoading(false);
        }
    };

    const updateScheduleState = async (schedule: ListSchedulesResponseRules, newState: UpdateScheduleStateRequestNewStateEnum) => {
        const formattedStateName = capitalizeFirstLetter(newState);
        const explanation = newState === UpdateScheduleStateRequestNewStateEnum.DISABLED ? "you won't be saving money on your AWS bill anymore" : "you will start saving money on your AWS bill"
        // enable or disable schedule
        openConfirmModal({
            title: "Update schedule state",
            description:
                <Space direction='vertical'>
                    <Divider style={{ margin: "5px 0 15px 0" }} />
                    <div>This saving schedule will be <b>{formattedStateName}</b> and {explanation}.</div>
                </Space>,
            confirmation: schedule!.name,
            okText: formattedStateName.slice(0, -1),
            cancelText: "Cancel",
            onOk: async () => {
                await getCostOptimizationService().updateScheduleState(schedule!.id, projectId!, { newState });
                notification.success({
                    message: "Successfully updated schedule state"
                });
                loadSchedules();
            }
        });

    }

    const deleteSchedule = async (schedule: ListSchedulesResponseRules) => {
        openConfirmModal({
            title: "Delete saving schedule",
            description:
                <Space direction='vertical'>
                    <Divider style={{ margin: "5px 0 15px 0" }} />
                    <div>This saving schedule will be deleted and you won't be saving money on your AWS bill anymore.</div>
                </Space>,
            confirmation: schedule!.name,
            okText: "Delete",
            cancelText: "Cancel",
            onOk: async () => {
                await getCostOptimizationService().deleteSchedule(schedule!.id, projectId!);
                notification.success({
                    message: "Successfully deleted schedule"
                });
                loadSchedules();
            }
        });
    }

    const handleScheduleAction = async (env: EnvironmentExtended) => {
        const createSchedule = async () => {
            setIsScheduleCreating(true);
            const scheduleOptions = {
                name: `${env.name} Env Schedule`,
                startScheduleExpression: cronExpression!.startExpression, //"cron(0 6 ? * Monday,Tuesday,Wednesday,Thursday,Friday *)"
                stopScheduleExpression: cronExpression!.stopExpression, //"cron(0 16 ? * Monday,Tuesday,Wednesday,Thursday,Friday *)"
                awsAccountId: env.awsAccountId!,
                region: env.awsRegion!,
                tagKey: DEFAULT_COST_ALLOCATION_TAG,
                tagValue: env.id,
                timezone: LOCAL_TIME_ZONE //"UTC+2"
            };
            await getCostOptimizationService().createSchedule(
                projectId!,
                scheduleOptions
            );
            notification.success({
                message: "Successfully created schedule"
            });
            setIsScheduleCreating(false);
        }

        const updateSchedule = async () => {
            const updateScheduleProps: UpdateScheduleRequest = {
                name: `${env.name} Env Schedule`,
                startScheduleExpression: cronExpression!.startExpression,
                stopScheduleExpression: cronExpression!.stopExpression,
                timezone: LOCAL_TIME_ZONE
            }
            await getCostOptimizationService().updateSchedule(
                env.schedule!.id,
                projectId!,
                updateScheduleProps
            );
            notification.success({
                message: "Successfully updated schedule"
            });
        }

        if (savingScheduleAction === "Create") {
            await createSchedule();
        } else {
            await updateSchedule();
        }
        // reload schedule
        loadSchedules();
    }

    const CostExplorerSection = () => (
        !isCostExplorerEnabled ?
            <DisabledFeatureInfoCard upgradePaymentPlanTo={PricingPlanNames.STARTER} /> :
            !loadingCloudAccounts && cloudAccounts.length === 0 ?
                <Card styles={{ body: { padding: 0 } }}>
                    <Row>
                        <Col offset={2} span={9} className="flex-align-center">
                            <Space direction="vertical" size={24}>
                                <Space direction="vertical" size={0}>
                                    <Title level={3} style={{ color: "green", fontWeight: "bold", marginBottom: 0 }}>
                                        Start tracking your cloud cost
                                    </Title>
                                    <br />
                                    <div>
                                        <ul className="no-margin" style={{ paddingLeft: 0, listStyleType: "none" }}>
                                            <Space direction="vertical">
                                                <li><CheckCircleOutlined style={{ color: "green" }} /> Understand your spending patterns</li>
                                                <li><CheckCircleOutlined style={{ color: "green" }} /> Identify cost drivers</li>
                                                <li><CheckCircleOutlined style={{ color: "green" }} /> Forecast your future costs</li>
                                            </Space>
                                        </ul>
                                    </div>
                                </Space>
                                <Button onClick={() => {
                                    setConnectAwsAccountModalVisible(true);
                                    trackSpecCloudAccountInit("aws");
                                }}>
                                    <Space>
                                        <img src={AwsLogo} style={{ width: "20px" }} alt="aws" /> Connect AWS Account
                                    </Space>
                                </Button>
                            </Space>
                        </Col>
                        <Col offset={1} span={12}>
                            <img src={CostExplorerBlurScreenshot} alt="cost-explorer" className="cost-dashboard-screenshot" />
                        </Col>
                    </Row>
                </Card> :
                <div className="cost-explorer">
                    <Heading
                        level={3}
                        title={
                            <div className="flex-justify-space-between flex-align-center">
                                <Select
                                    showSearch
                                    style={{ width: "300px" }}
                                    placeholder="Select AWS account"
                                    suffixIcon={<FilterOutlined />}
                                    value={cloudAccountId}
                                    onSelect={setCloudAccountId}
                                    loading={loadingCloudAccounts}
                                >
                                    {
                                        cloudAccounts.map(acc => (
                                            <Select.Option value={acc.id} key={acc.id}>
                                                <img src={AwsLogo} style={{ width: "14px" }} alt="aws" />  {acc.accountName}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                                <Button onClick={() => navigate(`/projects/${projectId}/settings/cost-report`)}>
                                    Configure Email Report
                                </Button>
                            </div>
                        }
                    />

                    <br />

                    {
                        !isCostExplorerEnabled &&
                        <>
                            <MessageCard
                                text={
                                    <div>
                                        <b>Cost Explorer is not enabled</b> for your AWS account, or you do not currently have access. Please <a href="https://docs.aws.amazon.com/cost-management/latest/userguide/ce-enable.html" target="_blank" rel="noreferrer">enable Cost Explorer</a> from the AWS Console and <a href="https://docs.aws.amazon.com/cost-management/latest/userguide/ce-access.html" target="_blank" rel="noreferrer">verify your access</a> before attempting again.
                                    </div>
                                }
                                type="error"
                            />
                            <br />
                        </>
                    }

                    <div className="cost-explorer-content">
                        {
                            <Space size="large" direction="vertical" className="full-width">
                                <Row gutter={24}>
                                    <Col span={10}>
                                        <Card title="Current month's cost" style={{ height: "361px" }}>
                                            <MetricsWidget
                                                title="Current month costs"
                                                loading={isCostExplorerLoading}
                                                value={aggregatedHistory?.costForTheCurrentMonth.ids[cloudAccountId].allServices}
                                            />
                                            <Divider style={{ margin: isCostExplorerLoading ? "24px 0" : "32px 0" }} />
                                            <MetricsWidget
                                                title="Estimated month end costs"
                                                loading={isCostExplorerLoading}
                                                value={aggregatedForecast?.estimatedCostForTheCurrentMonth.ids[cloudAccountId].allServices}
                                                lastValue={aggregatedHistory?.costForTheLastMonth.ids[cloudAccountId].allServices}
                                                trendThisMonth={estimatedMonthTrend}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={14}>
                                        <ColumnWidget
                                            title="Top AWS services by spending"
                                            description="Current month's AWS spending breakdown with estimated total"
                                            data={montlyCostByService}
                                            xField="entity"
                                            yField="value"
                                            seriesField="type"
                                            color={(datum) => datum.type === "Expected additional cost" ? WidgetColor.FadedBlue : WidgetColor.Blue}
                                            pattern={(_datum, color) => {
                                                if (color === WidgetColor.FadedBlue) {
                                                    return {
                                                        type: "line",
                                                        cfg: {
                                                            rotation: 135,
                                                            backgroundColor: WidgetColor.FadedBlue
                                                        }
                                                    }
                                                }
                                                return {
                                                    type: "dot",
                                                    cfg: {
                                                        rotation: 0,
                                                        fill: color
                                                    }
                                                }
                                            }}
                                            loading={isCostExplorerLoading}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={24}>
                                        <YearlyCostWidget
                                            data={yearCostByMonth}
                                            totalCostPastYear={totalYearCost}
                                            trendNextYear={estimatedYearTrend}
                                            totalCostNextYear={aggregatedForecast?.estimatedCostByAWSAccountForTheNextYear.cost[cloudAccountId].total}
                                            loading={isCostExplorerLoading}
                                        />
                                    </Col>
                                </Row>
                            </Space>
                        }
                    </div>
                </div>
    );

    const ScheduleItem = (props: { env: EnvironmentExtended, schedule: ListSchedulesResponseRules }) => {
        const { env, schedule } = props;
        return <Card
            style={{ marginBottom: "24px" }}
            title={
                <div className="flex-align-center" style={{ padding: "10px 0" }}>
                    <div>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Badge: {
                                        statusSize: 10
                                    },
                                },
                            }}>
                            {
                                schedule.state === ListSchedulesResponseRulesStateEnum.ENABLED &&
                                    env.savingUtilization?.aggregatedUtilization?.utilizedDays.includes(new Date().toLocaleDateString("en-US", { weekday: "long" })) &&
                                    (new Date().getHours() < env.savingUtilization?.aggregatedUtilization.startHourInTimeZone || new Date().getHours() > env.savingUtilization?.aggregatedUtilization.stopHourInTimeZone)
                                    ?
                                    <Tooltip title="Saving mode is ON">
                                        <Badge status="processing" color="green" />
                                    </Tooltip> :
                                    <Tooltip title="Saving mode is OFF">
                                        <Badge status="default" />
                                    </Tooltip>
                            }
                        </ConfigProvider>
                    </div>
                    <div style={{ marginLeft: "15px" }}>
                        <div>{env.name}</div>
                        <CloudAccountLabel
                            provider={env.cloudProvider}
                            envId={env.id}
                            accountId={env.awsAccountId || env.gcpProjectId}
                            region={env.awsRegion || env.gcpRegion}
                        />
                    </div>
                </div>
            }
            extra={
                <Dropdown.Button
                    trigger={["click"]}
                    type="default"
                    onClick={() => {
                        setEnv(env);
                        setIsScheduleModalOpen(true);
                    }}
                    menu={{
                        items: [
                            {
                                key: "update-state",
                                label: `${schedule.state === ListSchedulesResponseRulesStateEnum.ENABLED ? "Disable" : "Enable"} schedule`,
                                onClick: () => updateScheduleState(
                                    schedule,
                                    schedule.state === ListSchedulesResponseRulesStateEnum.ENABLED ?
                                        UpdateScheduleStateRequestNewStateEnum.DISABLED :
                                        UpdateScheduleStateRequestNewStateEnum.ENABLED
                                )
                            },
                            {
                                key: "delete",
                                label: "Delete schedule",
                                danger: true,
                                onClick: () => deleteSchedule(schedule)
                            }
                        ]
                    }}
                >
                    Edit schedule
                </Dropdown.Button>
            }
        >
            <SavingScheduleWidget
                aggregatedUtilization={env.savingUtilization?.aggregatedUtilization}
                expectedMonthlySavingsAmount={env.savingUtilization?.expectedMonthlySavings || 0}
                isLoading={isScheduleLoading}
                scheduleRule={schedule}
                timeZone={LOCAL_TIME_ZONE}
            />
        </Card>
    }

    const SchedulePlaceholderItem = (props: { env: EnvironmentExtended }) => {
        const { env } = props;
        return <Card
            style={{ marginBottom: "24px" }}
            title={
                <div style={{ padding: "10px 0" }}>
                    <div>{env.name}</div>
                    <CloudAccountLabel
                        provider={env.cloudProvider}
                        envId={env.id}
                        accountId={env.awsAccountId || env.gcpProjectId}
                        region={env.awsRegion || env.gcpRegion}
                    />
                </div>
            }
            extra={
                <Button
                    type="primary"
                    onClick={() => {
                        setEnv(env);
                        setIsScheduleModalOpen(true);
                    }}
                    loading={isScheduleCreating}
                >
                    Create schedule
                </Button>
            }
        >
            <Row gutter={[32, 32]}>
                <Col>
                    <Statistic
                        title={<b style={{ fontSize: 14, fontWeight: 500 }}>Estimated Saving per month</b>}
                        value={convertToCurrencyWithoutFractionDigits(env.savingUtilization?.expectedMonthlySavings || 0)}
                        valueStyle={{ color: "green", fontSize: "24px" }}
                    />
                </Col>
                <Col>
                    <Statistic
                        title={<b style={{ fontSize: 14, fontWeight: 500 }}>Estimated Saving per year</b>}
                        value={convertToCurrencyWithoutFractionDigits(Math.round(env.savingUtilization?.expectedMonthlySavings || 0) * 12)}
                        valueStyle={{ color: "green", fontSize: "24px" }}
                    />
                </Col>
            </Row>
        </Card>
    }

    const SavingSchedulesSection = () => (
        <div className="saving-schedules">
            <Heading
                level={4}
                title={
                    <Space direction="horizontal">
                        <Space style={!!currentProject.paymentPlan?.limitations?.savingSchedules ? {} : { color: "gray" }} direction="horizontal">
                            {!currentProject.paymentPlan?.limitations?.savingSchedules && <LockOutlined />}
                            Saving Schedules
                        </Space>
                        <ExplanationButton
                            content={<CostDashboardSavingSchedulesExplanation />}
                            blockElement={true}
                        />
                    </Space>
                }
            />

            <div className="saving-schedules-content">
                {
                    isScheduleLoading ? <Skeleton /> :
                        !isScheduleLoading && !isScheduleEnabled ?
                            <DisabledFeatureInfoCard
                                expectedSavings={0}
                                upgradePaymentPlanTo={PricingPlanNames.ADVANCED}
                            /> :
                            <>
                                {
                                    envs.map(env => {
                                        if (env.schedule) {
                                            return <ScheduleItem env={env} schedule={env.schedule} />
                                        } else {
                                            return <SchedulePlaceholderItem env={env} />
                                        }
                                    })
                                }
                            </>
                }
            </div>
        </div>
    );

    return (
        <PageContentWrapper
            size="large"
            header={
                <PageHeader
                    title={
                        <Space>
                            <span>Cost Explorer</span>
                            <ExplanationButton
                                content={<CostDashboardExplorer />}
                                blockElement={true}
                            />
                        </Space>
                    }
                />
            }
        >
            <CostExplorerSection />

            <br />

            <SavingSchedulesSection />

            {
                // Workaround. The modal is not unmouting when closed so we need to unmount the whole component using 'modalVisible'
                // We have a process of periodic API calls which remain active even if when the modal closes
                connectAwsAccountModalVisible &&
                <ConnectAwsAccountModal
                    visible={connectAwsAccountModalVisible}
                    onOk={(newAwsAccountId) => {
                        loadCloudAccounts(newAwsAccountId);
                        setConnectAwsAccountModalVisible(false);
                    }}
                    onCancel={() => setConnectAwsAccountModalVisible(false)}
                />
            }

            {
                isScheduleModalOpen && env &&
                <CreateSavingScheduleModal
                    localTimeZone={LOCAL_TIME_ZONE}
                    savingScheduleAction={
                        !env.savingUtilization?.aggregatedUtilization?.utilizedDays?.length || !env.schedule ?
                            "Create" :
                            "Update"
                    }
                    expectedMonthly={{
                        ec2AndRdsCost: env.savingUtilization?.estimatedEc2AndRdsCost || 0,
                        costWithoutSchedule: env.savingUtilization?.estimatedAllServicesCost || 0
                    }}
                    isModalOpen={isScheduleModalOpen}
                    setIsModalOpen={setIsScheduleModalOpen}
                    setCronExpression={setCronExpression}
                    handleScheduleAction={() => handleScheduleAction(env)}
                    aggregatedUtilization={env.savingUtilization?.aggregatedUtilization}
                />
            }
        </PageContentWrapper>
    )
}

export default CostExplorer;