import { QuestionCircleTwoTone } from "@ant-design/icons";
import { Space, Tooltip } from "antd";
import { ReactNode, useState } from "react";
import SideDrawer from "../../pages/home/SideDrawer";
import MicroticaLogo from "../../assets/logo-color-circle.svg";

interface IExplanationButton {
    content: ReactNode;
    blockElement?: boolean;
}

const ExplanationButton = ({
    content,
    blockElement
}: IExplanationButton) => {
    const [showSideDrawer, setShowSideDrawer] = useState(false);

    const WrapperElement = ({ children }: { children: ReactNode }) => !!blockElement ?
        <div
            className="flex-align-center"
            style={{ cursor: "pointer" }}
            onClick={() => setShowSideDrawer(true)}
        >
            {children}
        </div> :
        <span
            style={{ cursor: "pointer", marginLeft: 10 }}
            onClick={() => setShowSideDrawer(true)}
        >
            {children}
        </span>

    return (
        <>
            <WrapperElement>
                <Tooltip title={"More info"}>
                    <QuestionCircleTwoTone style={{ fontSize: 16 }} />
                </Tooltip>
            </WrapperElement>
            <SideDrawer
                visible={showSideDrawer}
                title={
                    <Space>
                        <img src={MicroticaLogo} style={{ height: "22px" }} alt="" />
                        Additional information
                    </Space>
                }
                content={content}
                onClose={() => setShowSideDrawer(false)}
            />
        </>
    )
}

export default ExplanationButton;