const CloudAccountExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                AWS Account
            </div>
            <div className="explanation-subtitle">
                Container for your AWS resources
            </div>
            <br />

            <p>
                Microtica provisions infrastructure on your own AWS account. In order to enable Microtica to manage cloud resources, you need to connect your AWS account.
            </p>
            <br />
            <h4>Revoking access</h4>
            <p>
                To completely revoke Microtica access to your AWS account you just need to remove the account from Microtica. After that, Microtica will no longer have access to your cloud account.
            </p>
            <br />
            <p>
                Learn more about <a href="https://docs.microtica.com/connect-an-aws-account" target="_blank" rel="noreferrer">connecting an AWS account.</a>
            </p>
        </>
    )
}

export default CloudAccountExplanation;