import { MonitorOutlined, RocketOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Space, Tabs } from "antd";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { Component, Dictionary, IEnvDetails } from "../../types";
import { useEffect, useState } from "react";
import AppPipelines from "../app/AppPipelines";
import { Tab } from "rc-tabs/lib/interface";
import ComponentOutputs from "../resource/ComponentOutputs";
import KubernetesOverview from "../resource/KubernetesOverview";
import { Link } from "react-router-dom";
import DeployResourceModal from "../../components/modals/DeployResourceModal";
import { getMonitoringService } from "../../backend";
import EnableMonitoringModal from "../../components/modals/EnableMonitoringModal";
import { ClusterMonitoringStatusResponseStatusEnum } from "@microtica/ms-monitoring-sdk";
import { isResourceDeployed } from "../../components/DeploymentStatusTag";

const EnvironmentComponentDetails = () => {
    const navigate = useNavigate();
    const { projectId, envId, componentId, section } = useParams() as {
        projectId: string;
        envId: string;
        componentId: string;
        section: string;
    };
    const { env, component } = useOutletContext<{ env: IEnvDetails, component: Component; }>();
    const [activeTab, setActiveTab] = useState(section);
    const [outputs, setOutputs] = useState<Dictionary<string[]>>({});
    const [showDeployModal, setShowDeployModal] = useState(false);
    const [showMonitoringModal, setShowMonitoringModal] = useState(false);
    const [isMonitoringAvailable, setIsMonitoringAvailable] = useState(false);

    const tabItems: Tab[] = [];

    if (component.type === "kubernetes") {
        tabItems.push({
            key: "overview",
            label: "Overview"
        });
    }

    if (component.component) {
        tabItems.push({
            key: "pipelines",
            label: "Pipelines"
        });
        tabItems.push({
            key: "outputs",
            label: "Outputs"
        });
    }

    useEffect(() => {
        const outputs = env.resources.reduce((acc, resource) => {
            const outputs = resource.component.schema?.properties?.outputs?.properties || {};
            acc[resource.name] = Object.keys(outputs);
            return acc;
        }, {} as Dictionary<string[]>);

        setOutputs(outputs);
    }, []);

    useEffect(() => {
        setActiveTab(section);
    }, [section]);

    useEffect(() => {
        loadData();
    }, [component.id]);

    const loadData = async () => {
        try {
            if (isResourceDeployed(component.status)) {
                const { data: { status } } = await getMonitoringService().clusterMonitoringStatus(
                    component.clusterId || componentId,
                    envId,
                    projectId
                );

                setIsMonitoringAvailable(status === ClusterMonitoringStatusResponseStatusEnum.Disconnected ? true : false);
            } else {
                setIsMonitoringAvailable(false);
            }
        } catch (error) {
            if ((error as any).response.status !== 404) {
                throw error;
            }
            setIsMonitoringAvailable(
                (component.type === "kubernetes" || component.type === "fargate") &&
                isResourceDeployed(component.status)
            );
        }
    }

    return (
        <>
            <Card
                bordered
                style={{ height: "calc(100vh - 128px)" }}
                title={
                    <div style={{ padding: "10px 0" }}>
                        <div style={{ fontWeight: 400 }}>
                            Component&nbsp;
                            {
                                component.isPublic ?
                                    <>
                                        <Divider type="vertical" /> {component.component!.name}
                                    </> :
                                    undefined
                            }
                        </div>
                        <div style={{ fontWeight: 600 }}>{component.name}</div>
                    </div>
                }
                extra={
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => setShowDeployModal(true)}
                            disabled={!component.component}
                            icon={<RocketOutlined style={{ fontSize: 16 }} rotate={45} />}
                        >
                            Deploy
                        </Button>
                        {
                            isMonitoringAvailable &&
                            <Button onClick={() => setShowMonitoringModal(true)}>
                                <MonitorOutlined /> Enable Monitoring
                            </Button>
                        }
                        {
                            !component.component && component.type === "kubernetes" ?
                                <Link to={`/projects/${projectId}/environments/${envId}/components/${componentId}/kubernetes/settings`}>
                                    <Button icon={<SettingOutlined />} />
                                </Link> :
                                component.component && component.isCluster ?
                                    <Link to={`/projects/${projectId}/environments/${envId}/components/${componentId}/${component.clusterId}/settings`}>
                                        <Button icon={<SettingOutlined />} />
                                    </Link> :
                                    <Link to={`/projects/${projectId}/environments/${envId}/components/${componentId}/settings`}>
                                        <Button icon={<SettingOutlined />} disabled={!component.component} />
                                    </Link>
                        }
                    </Space>
                }
                className="full-width"
                styles={{
                    header: { border: "none" }
                }}
            >
                <Tabs
                    activeKey={activeTab}
                    onChange={(activeKey) => {
                        setActiveTab(activeKey);
                        navigate(`/projects/${projectId}/environments/${envId}/components/${componentId}/${activeKey}`, { replace: true });
                    }}
                    items={tabItems}
                />
                {component.component && activeTab === "pipelines" && <AppPipelines env={env} component={component} />}
                {component.type === "kubernetes" && activeTab === "overview" && <KubernetesOverview env={env} component={component} />}
                {component.component && activeTab === "outputs" && <ComponentOutputs env={env} component={component} />}
            </Card>
            {
                showDeployModal &&
                <DeployResourceModal
                    visible={showDeployModal}
                    env={env}
                    resource={component as any}
                    stageResourcesOutputs={outputs}
                    notDeployedResources={
                        env.resources
                            .filter(r => ["NOT_DEPLOYED", "DELETE_COMPLETE", "ROLLBACK_COMPLETE"].includes(r.status))
                            .map(r => r.name)
                    }
                    onCancel={() => setShowDeployModal(false)}
                    onOk={() => {
                        setShowDeployModal(false);
                    }}
                />
            }
            {
                showMonitoringModal &&
                <EnableMonitoringModal
                    projectId={projectId}
                    envId={envId}
                    clusterId={component.clusterId!}
                    clusterName={component.name}
                    clusterType={component.clusterType!}
                    open={showMonitoringModal}
                    onOk={() => {
                        setShowMonitoringModal(false);

                        if (component.component && ["kubernetes", "k8s"].includes(component.type)) {
                            navigate(
                                `/projects/${projectId}/environments/${envId}/components/${componentId}/${component.clusterId}/settings/monitoring`
                            )
                        } else {
                            loadData();
                        }


                    }}
                    onCancel={() => setShowMonitoringModal(false)}
                />
            }
        </>
    )
}

export default EnvironmentComponentDetails;