import { UserNotificationsNotifications, UserNotificationsNotificationsEntityTypeEnum } from "@microtica/ms-notification-sdk";
import { Avatar, Card, Space, Tag, Typography, notification } from "antd";
import { BellOutlined, WarningOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { useNavigate } from "react-router";
import { getNotificationService } from "../../backend";
import AlarmSeverityTag, { Severity } from "../../components/alarms/AlarmSeverityTag";

interface NotificationProps extends UserNotificationsNotifications {
    projectName: string;
    type: "Incidents" | "Updates";
    onClose: () => void;
    updateProjectInfo: (projectId: string) => Promise<void>;
}

const Notification = (data: NotificationProps) => {
    const navigate = useNavigate();
    const { Text } = Typography;
    // const automaticTrigger = "Microtica Deployments";
    // const microticaSavingSchedule = "Microtica Saving Schedule";
    /*
        const renderColor = (status: string) => {
            switch (true) {
                case status.endsWith("COMPLETE"):
                case status.endsWith("DEPLOYED"):
                case status.endsWith("UNDEPLOYED"):
                    return "green";
                case status.endsWith("IN_PROGRESS"):
                case status.endsWith("RUNNING"):
                    return "#4775d1";
                case status.endsWith("FAILED"):
                    return "red";
                default:
                    return "gray";
            }
        }
    */
    const handleOnClick = async () => {
        await Promise.all([
            getNotificationService().markNotificationAsRead(data.id, data.projectId),
            data.updateProjectInfo(data.projectId)
        ]);

        data.onClose();

        if (data.type === "Incidents") {
            if (data.status === "ALARM") {
                navigate(`/projects/${data.projectId}/monitoring/alarms?id=${data.itemId}`);
            } else {
                notification.info({
                    message: "Resolved alarms are not shown in the Alarms page"
                })
                navigate(`/projects/${data.projectId}/monitoring/alarms`);
            }
        } else if (data.entityType === UserNotificationsNotificationsEntityTypeEnum.MICROSERVICE) {
            const clusterComponent = data.additionalInfo?.clusterId.replace(`${data.additionalInfo?.stageId}-`, "");
            if (data.status === "UNDEPLOYED" || data.status === "FAILED") {
                navigate(`/projects/${data.projectId}/environments/${data.additionalInfo?.stageId}/components/${clusterComponent}`);
            } else {
                navigate(`/projects/${data.projectId}/environments/${data.additionalInfo?.stageId}/components/${clusterComponent}/apps/${data.additionalInfo?.namespace}/${data.itemId}/overview`);
            }
        } else if (data.entityType === UserNotificationsNotificationsEntityTypeEnum.STAGE) {
            navigate(`/projects/${data.projectId}/pipelines`);
        } else {
            // We dont have a way to distinguish Service vs Resource in order to provide proper routing here
            navigate(`/projects/${data.projectId}`);
        }
        return;
    }

    const renderNotificationColor = (isRead: boolean) => {
        return isRead ? "white" : "whitesmoke";
    }

    /*
    const mapEntityType = () => {
        let value;
        switch ((data.entityType) as any) {
            case "MICROSERVICE":
                value = "APPLICATION";
                break;
            case "STAGE":
                value = "ENVIRONMENT";
                break;
            case "SCHEDULE":
                value = `${data.message.split(" ")[0]} resources`;
                break;
            default:
                value = data.entityType;
                break;
        }
        return value[0] + value.slice(1).toLowerCase();
    }

    const mapStatus = () => {
        if ([
            "ROLLBACK_COMPLETE",
            "UPDATE_ROLLBACK_COMPLETE"
        ].includes(data.status)) {
            return "rollback completed";
        } else if ([
            "DELETE_COMPLETE"
        ].includes(data.status)) {
            // the environment has a status DELETE_COMPLETE when it is being undeployed
            return "undeployed";
        } else if ([
            "CREATE_IN_PROGRESS",
            "UPDATE_IN_PROGRESS",
            "DELETE_IN_PROGRESS",
            "ROLLBACK_IN_PROGRESS",
            "UPDATE_ROLLBACK_IN_PROGRESS",
            "UPDATE_COMPLETE_CLEANUP_IN_PROGRESS",
            "UPDATE_ROLLBACK_COMPLETE_CLEANUP_IN_PROGRESS"
        ].includes(data.status)) {
            // these are all STAGE related statuses
            return "deployment in progress";
        } else if ([
            "RUNNING"
        ].includes(data.status)) {
            // this is a PIPELINE related status
            return "build in progress";
        } else if ([
            "FAILED",
            "UPDATE_FAILED",
            "CREATE_FAILED",
            "DELETE_FAILED",
            "ROLLBACK_FAILED",
            "UPDATE_ROLLBACK_FAILED",
        ].includes(data.status)) {
            return "failed";
        } else if ([
            "DEPLOYED",
            "CREATE_COMPLETE",
            "UPDATE_COMPLETE"
        ].includes(data.status)) {
            return "deployed";
        } else {
            // Known statuses: ABORTED, UNDEPLOYED
            return data.status.toLocaleLowerCase();
        }
    }
    */

    const notificationContent = (
        <>
            <div style={{ fontWeight: 600, fontSize: "14px" }}>
                {data.projectName}
            </div>
            <div style={{ fontSize: "12px" }}>
                <div style={{ fontSize: "12px", fontWeight: 600 }}>
                    {
                        data.entityType === "MICROSERVICE" ?
                            `Application ${data.status.toLowerCase()}` :
                            undefined
                    }
                    {
                        data.entityType === "PIPELINE" ?
                            `Pipeline ${data.status.toLowerCase()}` :
                            undefined
                    }
                    {
                        (data.entityType as any) === "SCHEDULE" ?
                            "Saving schedule" :
                            undefined
                    }
                    {
                        data.entityType === "STAGE" ?
                            `Component ${data.status.replace(/_/g, " ").toLowerCase()}` :
                            undefined
                    }
                </div>
                {data.message}
            </div>
            <div className="gray-text" style={{ fontSize: "11px" }}>
                {
                    `${moment(parseInt(data.timestamp)).fromNow()} • ${moment(parseInt(data.timestamp)).format("HH:mm • DD MMM YY")}`
                }
            </div>
        </>
    );

    const alarmNotificationContent = (
        <>
            {
                data.status === "OK" ?
                    <Text ellipsis title={data.additionalInfo?.alarmName} style={{ maxWidth: "190pt" }}>
                        <Tag color="green" style={{ marginRight: "4px" }}>Resolved</Tag>
                        {data.additionalInfo?.alarmName}
                    </Text> :
                    <Text ellipsis title={data.additionalInfo?.alarmName} style={{ maxWidth: "190pt" }}>
                        <AlarmSeverityTag severity={data.additionalInfo?.severity as Severity} />
                        {data.additionalInfo?.alarmName}
                    </Text>
            }
            <div style={{ fontWeight: 600, fontSize: "14px" }}>
                {data.projectName}
            </div>
            <div style={{ fontSize: "12px" }}>
                <div><b>Env: </b>{data.additionalInfo?.envId}</div>
                <div><b>App: </b>{data.additionalInfo?.appName}</div>
            </div>
            <div className="gray-text" style={{ fontSize: "11px" }}>
                {
                    `${moment(parseInt(data.timestamp)).fromNow()} • ${moment(parseInt(data.timestamp)).format("HH:mm • DD MMM YY")}`
                }
            </div>
        </>
    )

    return (
        <Card
            size="small"
            type="inner"
            style={{ marginBottom: 6, cursor: "pointer", backgroundColor: renderNotificationColor(data.isRead) }}
            onClick={handleOnClick}
        >
            <div className="flex-justify-start">
                <div style={{ marginRight: "12px" }}>
                    <Avatar
                        style={{ background: "#808080" }}
                        icon={data.type === "Incidents" ? <WarningOutlined /> : <BellOutlined />}
                    />
                </div>
                <Space direction="vertical" size="small" style={{ lineHeight: "16px" }}>
                    {data.type === "Incidents" ?
                        alarmNotificationContent :
                        notificationContent
                    }
                </Space>
            </div>
        </Card>
    )
}

export default Notification;