import { Alert, Button, Form, Input, Modal, Space } from "antd";
import EnvironmentSelect from "../template-modules/EnvironmentSelect";
import { AwsRegionType, EnvironmentDetails, GCPRegionType, GCPZoneType } from "../../types";
import AwsAccountSelect from "../AwsAccountSelect";
import { useEffect, useState } from "react";
import { RequiredMark } from "antd/lib/form/Form";
import { getCloudService, getEnvironmentService } from "../../backend";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { usePrevious } from "../../contexts/State";
import { AWS_REGIONS, GCP_REGIONS, GCP_ZONES } from "../../enums/enums";
import { GetStageResourcesResponseResources, GetStageResponse, GetStageResponseCloudProviderEnum, UpdateStageRequestAwsRegionEnum, UpdateStageRequestGcpRegionEnum, UpdateStageRequestGcpZoneEnum } from "@microtica/ms-engine-sdk";
import GcpAccountSelect from "../GcpAccountSelect";
import { AwsAccountAction, handleReport } from "../../utils/handle-report";

export interface CreateClusterModalProps {
    envId?: string;
    modalVisible?: boolean;
    setModalVisible: (visible: boolean) => void;
    selectedEnv?: EnvironmentDetails;
    setSelectedEnv: React.Dispatch<React.SetStateAction<EnvironmentDetails | undefined>>;
    clusterAppName?: string;
    setClusterAppName: (clusterAppName: string) => void;
    onOk?: () => void;
    onCancel?: () => void;
}

const CreateClusterModal = ({
    modalVisible,
    setModalVisible,
    selectedEnv,
    setSelectedEnv,
    clusterAppName,
    setClusterAppName,
    onOk,
    onCancel
}: CreateClusterModalProps) => {
    const currentProject = useRecoilValue(currentProjectState);
    const [form] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [cloudAccounts, setCloudAccounts] = useState<{ id: string; name: string }[]>([]);
    const prevEnvironment = usePrevious(selectedEnv);
    const [missingCloudConfigs, setMissingCloudConfigs] = useState(false);
    const [stageResources, setStageResources] = useState<GetStageResourcesResponseResources[]>([]);
    const [env, setEnv] = useState<GetStageResponse>();

    useEffect(() => {
        const loadData = async () => {
            if (selectedEnv) {
                if (selectedEnv.id !== prevEnvironment?.id) {
                    const { data: envDetails } = await getEnvironmentService().getStage(
                        selectedEnv.id,
                        currentProject!.id
                    );

                    setEnv(envDetails);

                    if (envDetails.cloudProvider === GetStageResponseCloudProviderEnum.Aws) {
                        if (envDetails.awsAccountId && envDetails.region) {
                            setMissingCloudConfigs(false);
                        } else {
                            const { data: { awsAccounts } } = await getCloudService().getAwsAccounts(currentProject!.id);
                            setCloudAccounts(awsAccounts.map(acc => ({ id: acc.id, name: acc.accountName })));
                            setMissingCloudConfigs(true);
                        }
                    } else {
                        if (envDetails.gcpProjectId && envDetails.gcpRegion && envDetails.gcpZone) {
                            setMissingCloudConfigs(false);
                        } else {
                            const { data: { gcpAccounts } } = await getCloudService().getGcpAccounts(currentProject!.id);
                            setCloudAccounts(gcpAccounts.map(acc => ({ id: acc.gcpProjectId, name: acc.projectName })));
                            setMissingCloudConfigs(true);
                        }
                    }
                }

                // these resources are used to check whether the cluster's name matches one of them
                const { data: { resources } } = await getEnvironmentService().getStageResources(selectedEnv.id, currentProject!.id);
                setStageResources(resources);
            };
        };

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEnv]);

    const handleContinue = async () => {
        // Link cloud account of not linked already
        await form.validateFields();

        if (selectedEnv?.cloudProvider === "aws" && !env?.awsAccountId && !env?.region) {
            const awsAccountId = selectedEnv.accountId!;
            const awsRegion = UpdateStageRequestAwsRegionEnum[selectedEnv.region as AwsRegionType];

            await Promise.all([
                getEnvironmentService().updateStage(
                    selectedEnv.id,
                    currentProject!.id,
                    {
                        awsAccountId,
                        awsRegion
                    }
                ),
                // handleReport(AwsAccountAction.CreateUpdate, currentProject!.id, awsAccountId, awsRegion)
            ]);
        } else if (selectedEnv?.cloudProvider === "google" && !env?.gcpProjectId && !env?.gcpRegion && !env?.gcpZone) {
            await getEnvironmentService().updateStage(
                selectedEnv.id,
                currentProject!.id,
                {
                    gcpProjectId: selectedEnv.gcpProjectId,
                    gcpRegion: UpdateStageRequestGcpRegionEnum[selectedEnv.gcpRegion as GCPRegionType],
                    gcpZone: UpdateStageRequestGcpZoneEnum[selectedEnv.gcpZone as GCPZoneType]
                }
            )
        }

        setModalVisible(false);
        onOk?.();
    }

    return (
        <Modal
            title="Create Kubernetes Cluster"
            centered
            closable={false}
            maskClosable={false}
            open={modalVisible}
            footer={[
                <Button
                    key="cancel"
                    onClick={() => {
                        setModalVisible(false);
                        onCancel?.();
                    }}
                >
                    Cancel
                </Button>,
                <Button
                    htmlType="submit"
                    type="primary"
                    key="continue"
                    disabled={
                        selectedEnv?.cloudProvider === "aws" ?
                            !selectedEnv?.accountId || !selectedEnv?.region :
                            !selectedEnv?.gcpProjectId || !selectedEnv?.gcpRegion || !selectedEnv?.gcpZone
                    }
                    onClick={handleContinue}
                >
                    Continue
                </Button>
            ]}
            onCancel={() => {
                setModalVisible(false);
                onCancel?.();
            }}
        >
            <Form
                form={form}
                layout="horizontal"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14, offset: 1 }}
                labelAlign="right"
                requiredMark={requiredMark}
            >
                <Space direction="vertical" size="large">
                    <Alert
                        message="By continuing the Kubernetes cluster will be deployed first and then your app. The cluster creation could take up to 15 mins."
                    />
                    <EnvironmentSelect
                        selectedEnv={selectedEnv}
                        onSelect={(env) => {
                            setSelectedEnv({
                                ...env,
                                region: env?.region ? AWS_REGIONS.find(r => r.value === env?.region)?.id : undefined,
                                gcpRegion: env?.gcpRegion ? GCP_REGIONS.find(r => r.value === env.gcpRegion)?.id : undefined,
                                gcpZone: env?.gcpZone ? GCP_ZONES.find(r => r.name === env.gcpZone)?.id : undefined,
                            } as EnvironmentDetails
                            )
                        }}
                    />
                    {
                        selectedEnv && missingCloudConfigs && selectedEnv.cloudProvider === "aws" ?
                            <AwsAccountSelect
                                selectedAccount={selectedEnv}
                                onSelect={({ accountId, region }) => {
                                    setSelectedEnv(env => ({
                                        ...env,
                                        accountId,
                                        region
                                    }) as EnvironmentDetails)
                                }}
                                awsAccounts={cloudAccounts}
                                visibleInfoForCostReport={true}
                            /> :
                            selectedEnv && missingCloudConfigs && selectedEnv.cloudProvider === "google" ?
                                <GcpAccountSelect
                                    selectedAccount={selectedEnv}
                                    onSelect={({ gcpProjectId, gcpRegion, gcpZone }) => {
                                        setSelectedEnv(env => ({
                                            ...env,
                                            gcpProjectId,
                                            gcpRegion,
                                            gcpZone
                                        }) as EnvironmentDetails)
                                    }}
                                    gcpAccounts={cloudAccounts}
                                /> : undefined
                    }
                    <Form.Item
                        key="clusterAppName"
                        name="clusterAppName"
                        label="Cluster name"
                        initialValue={clusterAppName}
                        rules={[
                            { required: true, message: "Enter cluster name" },
                            { min: 3, message: 'Cluster name must be minimum 3 characters.' },
                            { max: 15, message: 'Cluster name must be maximum 15 characters.' },
                            { pattern: new RegExp(/^[A-Za-z0-9]+$/), message: "Cluster name must contain only alphanumeric characters." },
                            {
                                validator: async (_, value) => {
                                    if (selectedEnv) {
                                        // check if resource with the same name already exists
                                        // the check must be case insesitive due to db primary key restrictions
                                        const existingResource = stageResources.find(r => r.name.toLowerCase() === value?.toLowerCase());
                                        if (existingResource) {
                                            throw new Error(`Resource with the same name already exists in your environment: ${existingResource.name}`);
                                        }
                                    }
                                },
                            }
                        ]}
                        tooltip="The cluster identifier (e.g., EKS, GKE) - this name will be displayed within the Microtica console."
                    >
                        <Input
                            type={"text"}
                            onChange={(event) => setClusterAppName(event.target.value)}
                            placeholder="ProductionCluster"
                        />
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    )
}

export default CreateClusterModal;