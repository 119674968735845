const AlarmSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" overflow="hidden" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4459">
        <path 
        d="M 887.4667 162.1333 L 836.2667 123.7333 L 759.4667 217.6 L 806.4 256 L 887.4667 162.1333 Z M 486.4 21.3333 H 550.4 V 144.2133 H 486.4 Z M 273.0667 217.6 L 200.5333 123.7333 L 149.3333 162.1333 L 226.1333 256 L 273.0666 217.6 Z M 512 204.8 A 332.8 332.8 0 0 0 174.9333 537.6 V 878.9333 H 862 V 819.2 H 422.4 V 512 H 362.6667 V 819.2 H 234.6667 V 537.6 A 273.0667 273.0667 0 0 1 512 268.8 A 285.8667 285.8667 0 0 1 797.8667 516.2667 V 819 H 863 L 861.8667 533.3334 V 533 V 817 H 863 V 548 A 345.6 345.6 0 0 0 512 204.8 Z M 601.6 942.9333 H 51.2 V 1002.6666 H 985.2 L 985 940.9999 Z"
        fill="currentColor"
        >
        </path>
    </svg>
)

export default AlarmSvg;