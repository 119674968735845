import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

interface IPageHeaderBreadcrumb {
    items: { link: string; name: string }[];
}

const PageHeaderBreadcrumb = ({ items }: IPageHeaderBreadcrumb) => {
    return (
        <Breadcrumb
            className="custom-breadcrumb"
            items={
                items.map(item => (
                    {
                        key: item.name,
                        title: (
                            <Link to={item.link}>
                                {item.name}
                            </Link>
                        )
                    }
                ))
            }
        >
        </Breadcrumb>
    );
}

export default PageHeaderBreadcrumb;