import { Skeleton } from "antd";
import { ReactNode } from "react";
import PageNotFound from "../pages/home/PageNotFound";

export interface PageContentWrapperProps {
    children: ReactNode;
    header?: ReactNode
    loading?: boolean;
    background?: string;
    size?: "small" | "medium" | "large";
    notFoundError?: boolean;
}

const formatMargin = (size?: "small" | "medium" | "large") => {
    switch (size) {
        case "large":
            return "64px";
        case "medium":
            return "32px";
        default:
            return 0;
    }
}

export default function PageContentWrapper(props: PageContentWrapperProps) {
    return (
        <>
            {
                !props.notFoundError ?
                    <div
                        className="ant-page-header-wrapper"
                        style={{ background: props.background }}
                    >
                        <div className="ant-page-header-wrapper-header">
                            {props.header}
                        </div>
                        <div
                            className="ant-page-header-wrapper-content"
                            style={{
                                marginTop: formatMargin(props.size),
                                marginBottom: formatMargin(props.size)
                            }}
                        >
                            {
                                props.loading ? <Skeleton active={true} /> : props.children
                            }
                        </div>
                    </div>
                    :
                    <PageNotFound />
            }
        </>
    )
}