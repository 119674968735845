import { Avatar, Button, Card, Divider, Dropdown, List, notification, Space, Image, Form, Input, Radio, Row, Col, Select, Tag, Empty } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { getPipelinesService } from "../../backend";
import { MoreOutlined } from "@ant-design/icons";
import { useDoubleConfigurationModal } from "../../contexts/Modal";
import AwsLogo from "../../assets/aws-logo.svg";
import GCPLogo from "../../assets/gcp-logo.svg";
import DockerLogo from "../../assets/docker-logo.svg";
import { GitlabOutlined } from "@ant-design/icons";
import ConnectContainerRegistryModal from "../../components/settings/ConnectContainerRegistryModal";
import { RequiredMark } from "antd/lib/form/Form";
import { DockerCredentials, DockerRegistry, DockerRegistryType } from "@microtica/ms-ap-sdk";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { AWS_REGIONS, GCP_REGIONS } from "../../enums/enums";
import { trackRegistryCreateInit } from "../../backend/tracking/kubernetes";
import ExplanationButton from "../../components/explanations/ExplanationButton";
import ContainerRegistryExplanation from "../../components/explanations/ContainerRegistryExplanation";
import { useParams } from "react-router-dom";

export default function ProjectIntegrationsContainer() {
    const { projectId } = useParams();
    const [form] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [loadingRegistries, setLoadingRegistries] = useState(true);
    const [registries, setRegistries] = useState<{ id: ReactNode; name: string; provider: string; default: boolean; }[]>([]);
    const [connectingRegistry, setConnectingRegistry] = useState(false);
    const { open: openConfirmModal } = useDoubleConfigurationModal();
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState<"Dockerhub" | "Ecr" | "GitlabContainerRegistry">("Dockerhub");

    useEffect(() => {
        loadRegistries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function getRegistryId(registry: DockerRegistry) {

        const creds = {
            dockerUsername: registry.credentials.find(cred => cred.key === "docker_username")?.value,
            awsAccountId: registry.credentials.find(cred => cred.key === "aws_account_id")?.value,
            awsRegion: AWS_REGIONS.find(r => r.value === registry.credentials.find(cred => cred.key === "aws_region")?.value)?.name,
            gitlabId: registry.credentials.find(cred => cred.key === "gitlab_id")?.value,
            gcpProjectId: registry.credentials.find(cred => cred.key === "gcp_project_id")?.value,
            gcpRegion: GCP_REGIONS.find(r => r.value === registry.credentials.find(cred => cred.key === "gcp_region")?.value)?.name
        }

        if (registry.type === DockerRegistryType.Dockerhub) {
            return creds.dockerUsername;
        } else if (registry.type === DockerRegistryType.Ecr) {
            return `${creds.awsAccountId} | ${creds.awsRegion}`;
        } else if (registry.type === DockerRegistryType.GitlabContainerRegistry) {
            return creds.gitlabId;
        } else if (registry.type === DockerRegistryType.Gcr) {
            return `${creds.gcpProjectId} | ${creds.gcpRegion}`;
        } else {
            return "";
        }
    }

    async function loadRegistries() {
        try {
            setLoadingRegistries(true);
            const { data: { dockerRegistries = [] } } = await getPipelinesService().listDockerRegistries(projectId!);

            setRegistries(dockerRegistries.map(reg => ({
                id: getRegistryId(reg),
                name: reg.registryName,
                provider: reg.type,
                default: reg.isDefault
            })));
        } catch (error) {

        } finally {
            setLoadingRegistries(false);
        }
    }

    async function handleDisconnectRegistry(registryName: string) {
        try {
            await getPipelinesService().deleteDockerRegistry(projectId!, registryName);
            await loadRegistries();
            notification.success({
                message: "Container registry disconnected"
            });
        } catch (error: any) {
            notification.error({
                message: "Error disconnecting container registry",
                description: error.response.data.message
            });
        }
    }

    function showDisconnectConfirm(accountId: string, confirmation: string) {
        openConfirmModal({
            title: "Disconnect container registry?",
            description: <>
                By disconnecting this container registry, you won't be able to deploy changes for your apps.
                <br />
                <br />
                To reactivate the deployment just reconnect this registry with same name.
            </>,
            confirmation,
            okText: `Disconnect Registry`,
            cancelText: 'Cancel',
            onOk: () => {
                return handleDisconnectRegistry(accountId);
            }
        })
    }

    async function handleConnectRegistry(values: { name: string; default: boolean; }) {
        const credentials = Object
            .entries(values)
            .filter(([key]) => key.startsWith("creds."))
            .map(([key, value]) => ({
                key: key.split(".")[1],
                value
            })) as DockerCredentials[];

        try {
            setConnectingRegistry(true);
            await getPipelinesService().addDockerRegistry(
                projectId!,
                {
                    registryName: values.name,
                    type: DockerRegistryType[selectedProvider],
                    isDefault: values.default,
                    credentials: credentials
                }
            );
            form.resetFields();
            await loadRegistries();
            notification.success({
                message: "Container registry connected"
            });
        } catch (error: any) {
            notification.error({
                message: "Error connecting container registry",
                description: error.response.data.message
            });
        } finally {
            setConnectingRegistry(false);
        }
    }

    const ContainerAvatar = (props: { provider: string }) => {
        if (props.provider === "dockerhub") {
            return <Image src={DockerLogo} style={{ width: "25px", height: "25px" }} preview={false} />
        } else if (props.provider === "gitlab-container-registry") {
            return <GitlabOutlined style={{ fontSize: "25px" }} />
        } else if (props.provider === "ecr") {
            return <Image src={AwsLogo} style={{ width: "25px", height: "25px" }} preview={false} />
        } else if (props.provider === "gcr") {
            return <Image src={GCPLogo} style={{ width: "25px", height: "25px" }} preview={false} />
        } else {
            return <Avatar />
        }
    }

    const DockerHubForm = () => (
        <Form
            form={form}
            layout="vertical"
            initialValues={{}}
            onFinish={handleConnectRegistry}
            requiredMark={requiredMark}
        >
            <Form.Item
                name="name"
                label="Registry name"
                required
                rules={[
                    { required: true, message: 'Please input the name of the registry!' },
                    {
                        pattern: RegExp("^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$"),
                        message: "Registry name must consist of lower case alphanumeric characters, '-' or '.', and must start and end with an alphanumeric character"
                    }
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="creds.docker_username"
                label="Username"
                required
                rules={[{ required: true, message: 'Please input the Docker namename!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="creds.docker_password"
                label="Password/Token"
                required
                rules={[
                    { required: true, message: 'Please input the Docker password/token!' }
                ]}
            >
                <Input type="password" />
            </Form.Item>
            <Form.Item name="default" valuePropName="checked">
                <Checkbox>Default Container Registry</Checkbox>
            </Form.Item>
            <Divider />
            <Row style={{ display: "flex", alignItems: "center" }}>
                <Col span={12}>
                    Learn how to <a href="https://docs.microtica.com/connect-a-container-registry#xc-connect-your-dockerhub-registry" target="_blank" rel="noreferrer">Setup DockerHub access</a>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                    <Button htmlType="submit" type="primary" loading={connectingRegistry}>
                        Connect
                    </Button>
                </Col>
            </Row>
        </Form>
    )

    const GitLabForm = () => {
        const [selectedScope, setSelectedScope] = useState("Project");
        return (<Form
            form={form}
            layout="vertical"
            initialValues={{ "creds.gitlab_hierarchy_type": "projects", "creds.registry_url": "registry.gitlab.com" }}
            onFinish={handleConnectRegistry}
            requiredMark={requiredMark}
        >
            <Form.Item
                name="name"
                label="Registry name"
                required
                rules={[
                    { required: true, message: 'Please input the name of the registry!' },
                    {
                        pattern: RegExp("^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$"),
                        message: "Registry name must consist of lower case alphanumeric characters, '-' or '.', and must start and end with an alphanumeric character"
                    }
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="creds.docker_username"
                label="Token name"
                required
                rules={[{ required: true, message: 'Please input the GitLab personal access token name!' }]}
            >
                <Input placeholder="GitLab personal access token name" />
            </Form.Item>
            <Form.Item
                name="creds.docker_password"
                label="Token"
                required
                rules={[{ required: true, message: 'Please GitLab personal access token value!' }]}
            >
                <Input type="password" placeholder="GitLab personal access token value" />
            </Form.Item>
            <Form.Item
                name="creds.gitlab_hierarchy_type"
                label="Scope"
                required
                rules={[
                    { required: true, message: 'Please select the GitLab scope!' }
                ]}
            >
                <Select
                    showSearch
                    style={{ width: "100%" }}
                    onChange={(value) => setSelectedScope(value)}
                    filterOption={(input, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    filterSort={(optionA, optionB) => {
                        return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }}
                >
                    <Select.Option value="groups">Group</Select.Option>
                    <Select.Option value="projects">Project</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="creds.gitlab_id"
                label={selectedScope === "groups" ? "Group ID" : "Project ID"}
                required
                rules={[
                    { required: true, message: `Please input the GitLab ${selectedScope === "groups" ? "Group ID" : "Project ID"}!` }
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item name="creds.registry_url" hidden>
                <Input />
            </Form.Item>
            <Form.Item name="default" valuePropName="checked">
                <Checkbox>Default Container Registry</Checkbox>
            </Form.Item>
            <Divider />
            <Row style={{ display: "flex", alignItems: "center" }}>
                <Col span={24} style={{ textAlign: "right" }}>
                    <Button htmlType="submit" type="primary" loading={connectingRegistry}>
                        Connect
                    </Button>
                </Col>
            </Row>
        </Form>
        )
    }

    const EcrForm = () => (
        <Form
            form={form}
            layout="vertical"
            initialValues={{}}
            onFinish={handleConnectRegistry}
            requiredMark={requiredMark}
        >
            <Form.Item
                name="name"
                label="Registry name"
                required
                rules={[
                    { required: true, message: 'Please input the name of the registry!' },
                    {
                        pattern: RegExp("^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$"),
                        message: "Registry name must consist of lower case alphanumeric characters, '-' or '.', and must start and end with an alphanumeric character"
                    }
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="creds.aws_account_id"
                label="AWS Account Id"
                required
                rules={[
                    { required: true, message: 'Please input the AWS account ID!' }
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="creds.aws_region"
                label="AWS Region"
                required
                rules={[
                    { required: true, message: 'Please input the AWS region!' }
                ]}
            >
                <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select region"
                    filterOption={(input, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    filterSort={(optionA, optionB) => {
                        return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }}
                >
                    {
                        AWS_REGIONS.map(region => (
                            <Select.Option value={region.value} key={region.value}>
                                {region.name}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Form.Item>
            <Form.Item
                name="creds.aws_access_key_id"
                label="Access Key ID"
                required
                rules={[
                    { required: true, message: 'Please input the AWS access key ID!' }
                ]}
            >
                <Input type="password" />
            </Form.Item>
            <Form.Item
                name="creds.aws_secret_access_key"
                label="Secret Access Key"
                required
                rules={[
                    { required: true, message: 'Please input the AWS access key secret!' }
                ]}
            >
                <Input type="password" />
            </Form.Item>
            <Form.Item name="default" valuePropName="checked">
                <Checkbox>Default Container Registry</Checkbox>
            </Form.Item>
            <Divider />
            <Row style={{ display: "flex", alignItems: "center" }}>
                <Col span={12}>
                    Learn how to <a href="https://docs.microtica.com/connect-a-container-registry#4z-connect-your-amazon-ecr-registry" target="_blank" rel="noreferrer">Setup Amazon ECR access</a>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                    <Button htmlType="submit" type="primary" loading={connectingRegistry}>
                        Connect
                    </Button>
                </Col>
            </Row>
        </Form>
    )

    return (
        <>
            <Card>
                <Card.Meta
                    title={
                        <>
                            <span>
                                Connect Container Registry
                            </span>
                            <ExplanationButton
                                content={<ContainerRegistryExplanation />}
                            />
                        </>
                    }
                />
                <Divider />
                <Space>
                    <Radio.Group
                        onChange={(e) => {
                            setSelectedProvider(e.target.value);
                            trackRegistryCreateInit();
                        }}
                        value={selectedProvider} buttonStyle="solid"
                    >
                        <Radio.Button value="Dockerhub">
                            DockerHub
                        </Radio.Button>
                        <Radio.Button value="Ecr">
                            Amazon ECR
                        </Radio.Button>
                        <Radio.Button value="GitlabContainerRegistry">
                            GitLab Container Registry
                        </Radio.Button>
                    </Radio.Group>
                </Space>
                <br />
                <br />
                {
                    selectedProvider === "Dockerhub" ? <DockerHubForm /> :
                        selectedProvider === "Ecr" ? <EcrForm /> :
                            <GitLabForm />
                }
            </Card>

            <br />

            <List
                bordered
                itemLayout="horizontal"
                className="ant-list-wrapper-bordered"
                dataSource={registries}
                loading={loadingRegistries}
                header="Connected Container registries"
                locale={{
                    emptyText: <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <>
                                <div>
                                    No connected container registries yet!
                                </div>
                            </>
                        }
                    />
                }}
                renderItem={item => (
                    <List.Item
                        style={{ background: "white" }}
                        actions={[
                            <Tag hidden={!item.default} color="processing">Default</Tag>,
                            <Dropdown
                                placement="bottomRight"
                                trigger={["click"]}
                                menu={{
                                    items: [
                                        {
                                            key: "disconnect",
                                            label: `Disconnect ${item.name}`,
                                            danger: true,
                                            onClick: () => showDisconnectConfirm(item.name, item.name)
                                        }
                                    ]
                                }}
                            >
                                <Button icon={<MoreOutlined />} />
                            </Dropdown>
                        ]}
                    >
                        <List.Item.Meta
                            avatar={<ContainerAvatar provider={item.provider} />}
                            title={item.name}
                            description={item.id}
                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        />
                    </List.Item>
                )}
            />
            <ConnectContainerRegistryModal
                provider="dockerhub"
                visible={modalVisible}
                onOk={() => {
                    loadRegistries();
                    setModalVisible(false);
                }}
                onCancel={() => setModalVisible(false)}
            />
        </>
    )
}