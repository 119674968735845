import { Badge, Button, Select, Space, TreeSelect, DatePicker, Typography, InputRef } from "antd";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Logs from "../../components/Logs";
import { PodPhase, App, Component, IEnvDetails } from "../../types";
import { useEffect, useRef, useState } from "react";
import { getKubeService } from "../../backend";
import { useParams } from "react-router";
import AppLogsDrawer from "../../components/AppLogsDrawer";
import Search from "antd/es/input/Search";
import { KubernetesPod } from "@microtica/ms-kube-sdk";
import { CloseCircleFilled, ReloadOutlined } from "@ant-design/icons";
import LogsPlaceholder from "../../components/LogsPlaceholder";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

dayjs.extend(utc);

interface KubernetesLogsProps {
    env: IEnvDetails;
    component: Component;
    app: App;
    advancedFilter?: boolean;
}

// @ts-ignore
const timestampRegex = /^(?<isoDate>\b[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.\d+Z\b)|^(?<isoDate1>\b[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\+[0-9]{2}:[0-9]{2}\b)|^(?<isoDate2>\b[0-9]{4}\/[0-9]{2}\/[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]+\b)|^\[(?<timestamp>\d+)\]|^\[(?<date>.*)\]/;

const KubernetesLogs = ({ env, component, app, advancedFilter, /*last*/ }: KubernetesLogsProps) => {
    const clearIconRef = useRef<HTMLSpanElement>(null);
    const regexFilterRef = useRef<InputRef>(null);
    const { projectId } = useParams() as { projectId: string; };
    const [selectedContainer, setSelectedContainer] = useState<string>("");
    const [logs, setLogs] = useState<string>("");
    const [filteredLogs, setFilteredLogs] = useState<string>("");
    const [showAppLogs, setShowAppLogs] = useState(false);
    const [filterOperator, setFilterOperator] = useState<"regex">("regex");
    const [filterValue, setFilterValue] = useState("");
    const [pods, setPods] = useState<KubernetesPod[]>([]);
    const [loadingPods, setLoadingPods] = useState(true);
    const [startTime, setStartTime] = useState<number>();
    const [endTime, setEndTime] = useState<number>();
    const [minStartTime, setMinStartTime] = useState<number>();
    const [maxEndTime, setMaxEndTime] = useState<number>();
    const [logsDataFetched, setLogsDataFetched] = useState(false);

    useEffect(() => {
        const load = async () => {
            setLoadingPods(true);
            const { data: { pods } } = await getKubeService().getMicroserviceDeploymentStatus(
                app.name,
                component.clusterId!,
                app.namespace,
                projectId
            );

            setPods(pods);
            setLoadingPods(false);

            return pods;
        }

        load().then(pods => {
            // Set default pod
            const defaultPod = pods.find(p => p.status.phase === "Running");

            if (defaultPod) {
                handleSelectContainer(
                    `${defaultPod.metadata.name}:${defaultPod.status.containerStatuses[0].name}`
                );
            }
        });

        const interval = setInterval(() => {
            load()
        }, 30000);

        return () => { clearInterval(interval); }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (logsDataFetched) {
            setLogsDataFetched(false);
            const logsArray = filteredLogs.split("\n");
            if (filterValue) {
                const matchedLogs = logsArray.reduce((acc: string[], logLine) => {
                    const regex = new RegExp(filterValue);

                    if (regex.test(logLine)) {
                        acc.push(logLine.replace(regex, (match) => `\x1b[48;5;34m${match}\x1b[0m`));
                    }

                    return acc;
                }, []);

                if (matchedLogs.length > 0) {
                    setFilteredLogs(matchedLogs.join("\n"));
                } else {
                    setFilteredLogs([
                        "\u001b[33mNo logs matching the search criteria were found.\u001b[0m",
                        "Consider adjusting your search parameters to ensure they match your requirements."
                    ].join("\n"));
                }
            }
            new Promise(res => setTimeout(res, 500))
                .then(_ => setLogsDataFetched(true))
            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue]);


    const filterLogs = async (fromTime: number, toTime: number) => {
        setLogsDataFetched(false);
        const logsArray = logs.split("\n");
        const startTimeDate = dayjs(fromTime).utc();
        const endTimeDate = dayjs(toTime).utc();
        const filteredLogsByTime = logsArray.reduce((arr, log) => {
            const logMatch = timestampRegex.exec(log);
            const date = logMatch?.groups?.timestamp ?
                parseInt(logMatch?.groups.timestamp) :
                logMatch?.groups?.isoDate || logMatch?.groups?.isoDate1 || logMatch?.groups?.isoDate2 || logMatch?.groups?.date || "";
            const logDate = dayjs(date).utc();
            if (logDate.isValid() && logDate.isAfter(startTimeDate) && logDate.isBefore(endTimeDate)) {
                // check if there is no filter or there is a matching for the specified filter
                if (filterValue !== "") {
                    if (new RegExp(filterValue).test(log)) {
                        arr.push(log.replace(filterValue, (match) => `\x1b[48;5;34m${match}\x1b[0m`))
                    }
                } else {
                    arr.push(log);
                }
            }
            return arr;
        }, [] as string[]);

        if (filteredLogsByTime.length > 0) {
            setFilteredLogs(filteredLogsByTime.join("\n"));
        } else {
            setFilteredLogs([
                "\u001b[33mNo logs found in the specified time range.\u001b[0m"
            ].join("\n"));
        }
        // add some delay so the "Fetching logs" placeholder could show up
        await new Promise(res => setTimeout(res, 500));
        setLogsDataFetched(true);
    }

    const findMinStartTime = (logsArray: string[]) => {
        for (let i = logsArray.length - 1; i >= 0; i--) {
            const logMatch = timestampRegex.exec(logsArray[i]);
            const date = logMatch?.groups?.timestamp ?
                parseInt(logMatch?.groups.timestamp) :
                logMatch?.groups?.isoDate || logMatch?.groups?.isoDate1 || logMatch?.groups?.isoDate2 || logMatch?.groups?.date || "";

            if (dayjs(date).isValid()) {
                return dayjs(date).utc().local().valueOf();
            }
        }
    }

    const getLogsFromLast30Minutes = (allLogs: string): string => {
        let latestLogs = [];
        let logsArray = allLogs.split("\n");
        const threshold = dayjs().utc().add(-30, 'm')
        for (let i = 0; i < logsArray.length; i++) {
            const logMatch = timestampRegex.exec(logsArray[i]);
            const date = logMatch?.groups?.timestamp ?
                parseInt(logMatch?.groups.timestamp) :
                logMatch?.groups?.isoDate || logMatch?.groups?.isoDate1 || logMatch?.groups?.isoDate2 || logMatch?.groups?.date || "";

            const logDate = dayjs(date);
            if (logDate.isValid()) {
                if (logDate.utc().isAfter(threshold)) {
                    // check if there is no filter or there is a matching for the specified filter
                    if (filterValue !== "") {
                        if (new RegExp(filterValue).test(logsArray[i])) {
                            latestLogs.push(logsArray[i].replace(filterValue, (match) => `\x1b[48;5;34m${match}\x1b[0m`));
                        }
                    } else {
                        latestLogs.push(logsArray[i]);
                    }
                } else {
                    if (!latestLogs.length) {
                        latestLogs = [
                            "\u001b[33mNo logs found in the specified time range.\u001b[0m"
                        ];
                    }
                    return latestLogs.join("\n");
                }
            }
        }
        if (!latestLogs.length) {
            latestLogs = [
                "\u001b[33mNo logs found in the specified time range.\u001b[0m"
            ];
        }
        return latestLogs.join("\n")
    }

    const loadLogs = async (podName: string, containerName: string) => {
        try {
            setLogsDataFetched(false);
            const dateNow = dayjs().utc();
            setStartTime(dateNow.add(-30, 'm').valueOf());
            setEndTime(dateNow.valueOf());
            const { data: { logs } } = await getKubeService().getMicroserviceContainerLogs(
                app.name,
                app.namespace,
                component.clusterId!,
                podName,
                containerName,
                projectId
            );
            // const pods = app.instance.pods;
            const containerPhase = pods?.find(container => container.metadata.name === podName)?.status.phase;
            if (logs && containerPhase?.toLowerCase() !== "pending") {
                // Display the actual logs only if logs exist AND the container is not in a Pending state
                const logsArray = logs.split("\n").reverse();
                setLogs(logsArray.join("\n"));
                setFilteredLogs(getLogsFromLast30Minutes(logsArray.join("\n")));
                const newStartTime = findMinStartTime(logsArray);
                if (newStartTime) {
                    setMinStartTime(newStartTime);
                }
                setMaxEndTime(dateNow.local().valueOf());
            }

        } catch (error) {
            setFilteredLogs(`\u001b[31m${(error as any).response?.data?.message}\u001b[0m

                \u001b[31m${(error as any).response?.data?.kubernetesError?.message || ""}\u001b[0m
            `)
        } finally {
            setLogsDataFetched(true);
        }
    }

    const handleSelectContainer = (container: string) => {
        const [podName, containerName] = container.split(":");
        loadLogs(podName, containerName);
        setSelectedContainer(container);
    }

    const PodPhaseIcon = (props: { phase: PodPhase }) => {
        switch (props.phase) {
            case "Pending":
                return <Badge color="blue" />
            case "Running":
                return <Badge color="green" />
            case "Succeeded":
                return <Badge color="green" />
            case "Failed":
                return <Badge color="red" />
            default:
                return <Badge color="black" />
        }
    }

    return (
        <Space direction="vertical" size="large" className="flex-justify-space-between">
            {
                advancedFilter ?
                    <div>
                        <div style={{ fontWeight: 600, marginBottom: "8px" }}>
                            Filters
                        </div>
                        <div className="flex-justify-space-between">
                            <div>
                                <Space>
                                    <Search
                                        enterButton={false}
                                        onSearch={(value) => setFilterValue(value)}
                                        addonBefore={
                                            <Select
                                                defaultValue={filterOperator}
                                                value={filterOperator}
                                                onSelect={(value) => setFilterOperator(value)}
                                            >
                                                <Option value="regex">Line contains regex match</Option>
                                            </Select>
                                        }
                                        addonAfter={undefined}
                                        allowClear={{ clearIcon: <CloseCircleFilled ref={clearIconRef} /> }}
                                        ref={regexFilterRef}
                                    />
                                    <TreeSelect
                                        key={app.name}
                                        style={{ width: '400px' }}
                                        defaultValue={selectedContainer || "No available containers"}
                                        value={selectedContainer}
                                        treeDefaultExpandedKeys={[selectedContainer]}
                                        onChange={(containerName) => handleSelectContainer(containerName)}
                                        placeholder="Please select container"
                                        multiple={false}
                                        loading={loadingPods}
                                        treeData={
                                            pods.map(pod => ({
                                                value: pod.metadata.name,
                                                title: <div className="flex-justify-space-between flex-align-center" style={{ padding: "5px" }}>
                                                    <Text ellipsis style={{ width: "18vw" }}>
                                                        <PodPhaseIcon phase={pod.status.phase as PodPhase} /> {pod.metadata.name}
                                                    </Text>
                                                </div>,
                                                children: (pod.status.containerStatuses || []).map((c: { name: string, restartCount: number }) => ({
                                                    value: `${pod.metadata.name}:${c.name}`,
                                                    title: <div className="flex-justify-space-between flex-align-center" style={{ padding: "5px" }}>
                                                        <Text ellipsis style={{ marginRight: "10px", width: "13vw" }}>
                                                            <span style={{ fontWeight: 600 }}>Container:</span> {c.name}
                                                        </Text>
                                                        <Text className="gray-text" ellipsis>
                                                            {c.restartCount} restarts
                                                        </Text>
                                                    </div>
                                                })),
                                                selectable: false,
                                                disableCheckbox: true
                                            }))
                                        }
                                    />
                                </Space>
                            </div>
                            <Space>
                                <RangePicker
                                    value={[dayjs(startTime), dayjs(endTime)]}
                                    showTime={{ format: 'HH:mm' }}
                                    presets={[
                                        { label: 'Last 5 minutes', value: [dayjs().utc().add(-5, 'm'), dayjs().utc()] },
                                        { label: 'Last 30 minutes', value: [dayjs().utc().add(-30, 'm'), dayjs().utc()] },
                                        { label: 'Last 12 hours', value: [dayjs().utc().add(-12, 'h'), dayjs().utc()] },
                                        { label: 'Last day', value: [dayjs().utc().add(-1, 'd'), dayjs().utc()] }
                                    ]}
                                    onChange={([start, end]: any) => {
                                        setStartTime(start?.valueOf() || startTime)
                                        setEndTime(end?.valueOf() || endTime)
                                        filterLogs(start?.valueOf() || startTime!, end?.valueOf() || endTime!);
                                    }}
                                    minDate={dayjs(minStartTime)}
                                    maxDate={dayjs(maxEndTime)}
                                    // force user to change dates using the calendar
                                    inputReadOnly={true}
                                />
                                <Button
                                    icon={<ReloadOutlined />}
                                    onClick={() => loadLogs(selectedContainer.split(":")[0], selectedContainer.split(":")[1])}
                                />
                            </Space>
                        </div>
                    </div> :
                    <div className="flex-justify-space-between flex-align-center">
                        <div style={{ fontWeight: 600, fontSize: 16 }}>
                            Real-time logs
                            <div className="gray-text" style={{ fontWeight: 400, fontSize: 14 }}>
                                Logs from the past 30 minutes
                            </div>
                        </div>
                        <Space>
                            <TreeSelect
                                key={app.name}
                                style={{ width: '400px' }}
                                defaultValue={selectedContainer || "No available containers"}
                                value={selectedContainer}
                                treeDefaultExpandedKeys={[selectedContainer]}
                                onChange={(containerName) => handleSelectContainer(containerName)}
                                placeholder="Please select container"
                                multiple={false}
                                loading={loadingPods}
                                treeData={
                                    pods.map(pod => ({
                                        value: pod.metadata.name,
                                        title: <div className="flex-justify-space-between flex-align-center" style={{ padding: "5px" }}>
                                            <Text ellipsis style={{ width: "18vw" }}>
                                                <PodPhaseIcon phase={pod.status.phase as PodPhase} /> {pod.metadata.name}
                                            </Text>
                                        </div>,
                                        children: (pod.status.containerStatuses || []).map((c: { name: string, restartCount: number }) => ({
                                            value: `${pod.metadata.name}:${c.name}`,
                                            title: <div className="flex-justify-space-between flex-align-center" style={{ padding: "5px" }}>
                                                <Text ellipsis style={{ marginRight: "10px", width: "13vw" }}>
                                                    <span style={{ fontWeight: 600 }}>Container:</span> {c.name}
                                                </Text>
                                                <Text className="gray-text" ellipsis>
                                                    {c.restartCount} restarts
                                                </Text>
                                            </div>
                                        })),
                                        selectable: false,
                                        disableCheckbox: true
                                    }))
                                }
                            />
                            {
                                advancedFilter ? undefined :
                                    <Space>
                                        <Button onClick={() => setShowAppLogs(true)}>Search logs</Button>
                                        <Button icon={<ReloadOutlined />} onClick={() => loadLogs(selectedContainer.split(":")[0], selectedContainer.split(":")[1])} />
                                    </Space>
                            }
                        </Space>
                    </div>
            }
            {logsDataFetched ?
                <Logs
                    logs={filteredLogs.split("\n")}
                    limit={2000}
                    height={advancedFilter ? "calc(100vh - 295px)" : "calc(100vh - 380px)"}
                /> :
                <LogsPlaceholder height={advancedFilter ? "calc(100vh - 295px)" : "calc(100vh - 380px)"} />
            }
            {
                showAppLogs &&
                <AppLogsDrawer
                    env={env}
                    component={component}
                    app={app}
                    open={showAppLogs}
                    onClose={() => setShowAppLogs(false)}
                />
            }
        </Space>
    )
}

export default KubernetesLogs;