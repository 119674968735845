import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getElasticSearchService, getEnvironmentService, getKubeService, getPipelinesService } from "../../backend";
import PageContentWrapper from "../../components/PageContentWrapper";
import { PageHeader } from "@ant-design/pro-layout";
import { GetStagesItem } from "@microtica/ms-engine-sdk";
import { Avatar, Button, Card, Col, Divider, Dropdown, Empty, notification, Row, Segmented, Skeleton, Space, Table, Tabs, Tag, Typography } from "antd";
import { Link } from "react-router-dom";
import {
    ArrowLeftOutlined,
    ControlOutlined,
    LockOutlined,
    NodeIndexOutlined,
    ReadOutlined,
    RocketOutlined
} from "@ant-design/icons";
import SkeletonInput from "antd/es/skeleton/Input";
import MarkdownPreview from "@uiw/react-markdown-preview";
import CloudIcon from "../../components/CloudIcon";
import IaCIcon from "../../components/IaCIcon";
import { useRecoilState } from "recoil";
import { projectsState } from "../../recoil/project";
import { DockerRegistry, DockerRegistryType, Pipeline, PipelineBuildDetails } from "@microtica/ms-ap-sdk";
import { ComponentSchemaInputProp } from "../../types";
import Paragraph from "antd/es/typography/Paragraph";
import GitCommitLink from "../../components/GitCommitLink";
import moment from "moment";
import BuildStatusTag from "../../components/BuildStatusTag";
import CloudAccountLabel from "../../components/CloudAccountLabel";
import { ServiceDeploymentCommit } from "@microtica/ms-elasticsearch-sdk";
import { GetMicroserviceResponse } from "@microtica/ms-kube-sdk";

const { Text } = Typography;

interface TemplateAppDetailsProps {

}

interface SchemaProp {
    key: string;
    description?: string;
    default?: string;
    enum?: string[];
    required: boolean;
    sensitive?: boolean;
}

const TemplateAppDetails = ({

}: TemplateAppDetailsProps) => {
    const navigate = useNavigate();
    const [projects] = useRecoilState(projectsState);
    const { projectId, appId } = useParams() as { projectId: string; appId: string; }
    const [envs, setEnvs] = useState<GetStagesItem[]>([]);
    const [app, setApp] = useState<GetMicroserviceResponse>();
    const [registry, setRegistry] = useState<DockerRegistry>();
    const [pipeline, setPipeline] = useState<Pipeline>();
    const [versions, setVersions] = useState<PipelineBuildDetails[]>();
    const [inputProps, setInputProps] = useState<SchemaProp[]>([]);
    const [filter, setFilter] = useState('All');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (projects) {
            load();
        }
    }, [projects]);

    const load = async () => {
        try {
            const [
                { data: app },
                { data: { response: allDeployments } }
            ] = await Promise.all([
                getKubeService().getMicroservice(appId, projectId),
                getElasticSearchService().getServiceDeploymentHistory(
                    projectId,
                    appId
                )
            ]);

            setApp(app);

            const lastDeployment = allDeployments[0];
            const pipelineId = (lastDeployment?.commit as ServiceDeploymentCommit & { pipelineId: string })?.pipelineId;

            if (!pipelineId) {
                setLoading(false);
                return;
            }

            const [
                { data: { stages } },
                { data: pipeline },
                { data: { builds } },
                { data: registry }
            ] = await Promise.all([
                getEnvironmentService().getStages(projectId),
                getPipelinesService().getPipelineById(projectId, pipelineId),
                getPipelinesService().getPipelineBuilds(projectId, pipelineId),
                getPipelinesService().getDockerRegistry(projectId, app.registry).catch(err => ({
                    data: {
                        registryName: app.registry,
                        credentials: [],
                        projectId,
                        type: DockerRegistryType.GitlabContainerRegistry,
                        isDefault: false
                    }
                }))
            ]);

            setEnvs(stages);
            setRegistry(registry);
            setPipeline(pipeline);
            setVersions(builds);
        } catch (error) {
            notification.error({
                message: "Error getting application",
                description: (error as any).response?.data?.message || "We encountered an error while trying to get application details. Please try again."
            });
        } finally {
            setLoading(false);
        }
    }

    const getProjectDetails = (projectId: string) => {
        return projects.find(p => p.id === projectId);
    }

    useEffect(() => {
        setInputProps(
            Object.entries(app?.schema?.properties.inputs.properties || {})
                .filter(([key, prop]) =>
                    filter === "Required" ? app?.schema?.properties?.inputs?.required?.some(r => r === key) :
                        filter === "Sensitive" ? prop.sensitive === true : true
                )
                .map(([key, prop]) => ({
                    key,
                    description: prop.description,
                    default: prop.default,
                    enum: prop.enum,
                    required: !!app?.schema?.properties?.inputs?.required?.some(r => r === key),
                    sensitive: prop.sensitive
                }))
        );
    }, [app, filter]);

    const ConfigurationTab = () => (
        <>
            <Table
                pagination={false}
                dataSource={inputProps}
                sticky={{ offsetHeader: 64 }}
                title={() => (
                    <div className="flex-justify-space-between flex-align-center">
                        <div style={{ fontWeight: 600, fontSize: 16 }}>
                            Input Parameters
                            <div className="gray-text" style={{ fontWeight: 400, fontSize: 14 }}>
                                Configure component using custom input parameters. Example: database type, instance size.
                            </div>
                        </div>
                        <Segmented
                            options={["All", "Required", "Sensitive"]}
                            value={filter}
                            onChange={setFilter}
                        />
                    </div>
                )}
                locale={{
                    emptyText:
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <>
                                    <div>
                                        No input parameters are currently defined for this component.
                                    </div>
                                    <div>
                                        To define input parameters, please add them to the <b>.microtica/schema.json</b> file in your Git repository.
                                    </div>
                                </>
                            }
                        />
                }}
                columns={[
                    {
                        title: "Name",
                        render: (item: SchemaProp) => <>
                            {item.key}
                            {
                                item.required ? <span style={{ color: "red" }}>*</span> : undefined
                            }
                        </>
                    },
                    {
                        title: "Description",
                        render: (item: ComponentSchemaInputProp) => item.description
                    },
                    {
                        title: "Allowed values",
                        render: (item: SchemaProp) =>
                            <Paragraph ellipsis={{ rows: 2, symbol: "show more", expandable: true }} style={{ margin: 0 }}>
                                {
                                    item.enum?.map(e => (
                                        <Tag bordered={false} style={{ marginBottom: 5 }}>{e}</Tag>
                                    ))
                                }
                            </Paragraph>
                    },
                    {
                        title: "Default",
                        render: (item: SchemaProp) => item.default
                    },
                    {
                        title: "Sensitive",
                        align: "center",
                        width: "100px",
                        render: (item: SchemaProp) => item.sensitive ? <LockOutlined /> : undefined
                    }
                ]}
            />
        </>
    )

    const VersionsTab = () => (
        <Table
            pagination={false}
            dataSource={versions}
            locale={{
                emptyText:
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <>
                                <div>
                                    This template does not have any versions yet!
                                </div>
                                <div>
                                    Please either push changes on Git or manually trigger a pipeline from the branch.
                                </div>
                            </>
                        }
                    />
            }}
            columns={[
                {
                    title: 'Author',
                    key: 'author',
                    width: "100px",
                    render: (item: PipelineBuildDetails) => (
                        <div style={{ fontWeight: 700, fontSize: "13px" }}>
                            <Avatar className="no-border" src={item.metadata.commit.user.avatar} />
                        </div>
                    )
                },
                {
                    title: 'Commit',
                    key: 'commit',
                    render: (item: PipelineBuildDetails) => (
                        <Space direction="vertical" style={{ fontSize: "12px" }}>
                            <div style={{ fontSize: "12px", lineHeight: "20px" }}>
                                <b>{item.metadata.commit.message} </b>
                                <div>
                                    <GitCommitLink
                                        version={item.metadata.commit.sha || item.metadata.commit.version}
                                        repoUrl={item.metadata.repository}
                                        branch={item.metadata.commit.name}
                                    />
                                </div>
                            </div>
                        </Space>
                    )
                },
                {
                    key: 'time',
                    title: "Execution time",
                    render: (item: PipelineBuildDetails) => (
                        <Space direction="vertical" style={{ fontSize: "12px" }}>
                            <div style={{ fontSize: "13px" }}>
                                {moment(item.startDate).fromNow()}
                            </div>
                            <div>
                                {item.metadata.commit.user.name}
                            </div>
                        </Space>
                    )
                },
                {
                    key: 'status',
                    title: "Status",
                    align: "right",
                    render: (build: PipelineBuildDetails) => (
                        <BuildStatusTag status={build.status} statusCode={build.statusCode} />
                    )
                }
            ]}
        />
    )


    return (
        <PageContentWrapper
            size="large"
            header={
                <PageHeader>
                    {
                        app ?
                            <div className="flex-justify-space-between flex-align-center">
                                <div>
                                    <Space size="large">
                                        <Link to={`/projects/${projectId}/templates`}>
                                            <Button icon={<ArrowLeftOutlined />} />
                                        </Link>
                                        <div>
                                            <div style={{ fontSize: "16px", }}>
                                                Template
                                            </div>
                                            <div style={{ fontSize: "20px", fontWeight: 600 }}>
                                                {app.name}
                                            </div>
                                        </div>
                                    </Space>
                                </div>
                                <Space>
                                    <Dropdown
                                        menu={{
                                            items: envs.map(e => ({
                                                key: e.id,
                                                label: <>
                                                    <div style={{ padding: "10px" }}>
                                                        <div style={{ fontSize: "16px", fontWeight: 500 }}>
                                                            {e.name}
                                                        </div>
                                                        <div className="gray-text">
                                                            <CloudAccountLabel
                                                                provider={e.cloudProvider}
                                                                envId={e.id}
                                                                accountId={e.awsAccountId || e.gcpProjectId}
                                                                region={e.awsRegion || e.gcpRegion}
                                                            />
                                                        </div>
                                                    </div>
                                                </>,
                                                onClick: () => {
                                                    navigate(
                                                        `/projects/${projectId}/environments/${e.id}/templates/${app.name}/add-app`
                                                    )
                                                }
                                            }))
                                        }}
                                        trigger={["click"]}
                                        placement="bottomRight"
                                    >
                                        <Button type="primary">
                                            <RocketOutlined rotate={45} /> Deploy
                                        </Button>
                                    </Dropdown>
                                </Space>
                            </div> :
                            <Space direction="vertical">
                                <SkeletonInput size="small" />
                                <SkeletonInput />
                            </Space>
                    }
                </PageHeader>
            }
        >
            {/* Overview */}
            <Card>
                <Card.Meta
                    title="Description"
                    description={app?.description}
                />
                <br />
                <Row gutter={[12, 12]}>
                    <Col span={6}>
                        <Card size="small">
                            <Card.Meta
                                title={
                                    <div style={{ fontSize: "12px" }}>
                                        <div className="gray-text">
                                            Container image
                                        </div>
                                        {
                                            loading ?
                                                <Skeleton.Input size="small" style={{ lineHeight: "15px" }} /> :
                                                <Text
                                                    ellipsis={{ tooltip: `${registry?.registryName} | ${app?.imageRepository}` }}
                                                    style={{ width: "50vw" }}
                                                >
                                                    <CloudIcon provider={registry?.type} />
                                                    <span style={{ marginLeft: "10px" }}>
                                                        {
                                                            registry?.registryName &&
                                                            <>
                                                                {registry?.registryName} <Divider type="vertical" />
                                                            </>
                                                        }
                                                        {app?.imageRepository}
                                                    </span>
                                                </Text>
                                        }
                                    </div>
                                }
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card size="small">
                            <Card.Meta
                                title={
                                    <div style={{ fontSize: "12px" }}>
                                        <div className="gray-text">
                                            Infrastructure as a Code
                                        </div>
                                        {
                                            loading ? <Skeleton.Input size="small" /> :
                                                <Text
                                                    ellipsis={{ tooltip: "Kubernetes" }}
                                                    style={{ width: "50vw" }}
                                                >
                                                    <IaCIcon provider="kubernetes" />
                                                    <span style={{ marginLeft: "8px" }}>
                                                        Kubernetes
                                                    </span>
                                                </Text>
                                        }
                                    </div>
                                }
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card size="small">
                            <Card.Meta
                                title={
                                    <div style={{ fontSize: "12px" }}>
                                        <div className="gray-text">
                                            Repository
                                        </div>
                                        {
                                            loading ? <Skeleton.Input size="small" /> :
                                                pipeline ?
                                                    <Text ellipsis={{ tooltip: pipeline.repositoryUrl }}>
                                                        <a href={pipeline.repositoryUrl} target="_blank">
                                                            {pipeline.repositoryUrl}
                                                        </a>
                                                    </Text> :
                                                    "n/a"
                                        }
                                    </div>
                                }
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card size="small">
                            <Card.Meta
                                title={
                                    <div style={{ fontSize: "12px" }}>
                                        <div className="gray-text">
                                            Branch filter
                                        </div>
                                        {
                                            loading ? <Skeleton.Input size="small" /> :
                                                <Text ellipsis={{ tooltip: pipeline?.branchFilter || "n/a" }}>
                                                    {pipeline?.branchFilter || "n/a"}
                                                </Text>
                                        }
                                    </div>
                                }
                            />
                        </Card>
                    </Col>
                </Row>

            </Card>

            <br />
            <br />

            {/* Tabs */}
            <Tabs items={[
                {
                    key: "readme",
                    label: "Readme",
                    children: <Card>
                        {
                            app?.readme ?
                                <MarkdownPreview
                                    source={app?.readme!}
                                    wrapperElement={{
                                        "data-color-mode": "light"
                                    }}
                                    style={{ padding: "10px" }}
                                /> :
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                        <>
                                            <div>
                                                <b>No README.md Available</b>
                                            </div>
                                            <div>
                                                To provide a README and have it rendered here, please add a README.md file to the <b>.microtica/README.md</b> of your project.
                                            </div>
                                        </>
                                    }
                                />
                        }
                    </Card>,
                    icon: <ReadOutlined />
                },
                {
                    key: "schema",
                    label: "Configuration",
                    children: <ConfigurationTab />,
                    icon: <ControlOutlined />
                },
                {
                    key: "Versions",
                    label: "Versions",
                    children: <VersionsTab />,
                    icon: <NodeIndexOutlined />
                }
            ]}
            />
        </PageContentWrapper >
    )
}

export default TemplateAppDetails;