import { Modal } from "antd";
import AssignResourceDomainSteps from "../../components/settings/AssignResourceDomainSteps";

interface AssignResourceDomainModalProps {
    visible: boolean;
    envId: string;
    resourceName: string;
    resourceVersion: string;
    domainCNAME: string;
    awsAccount: {
        id: string;
        region: string;
    }
    onCancel: () => void;
}

const AssignResourceDomainModal = ({
    visible,
    envId,
    resourceName,
    resourceVersion,
    domainCNAME,
    awsAccount,
    onCancel
}: AssignResourceDomainModalProps) => {

    return (
        <Modal
            title="Assign domain"
            open={visible}
            footer={null}
            width="700px"
            onCancel={onCancel}
            maskClosable={false}
        >
            <AssignResourceDomainSteps
                envId={envId}
                resourceName={resourceName}
                resourceVersion={resourceVersion}
                domainCNAME={domainCNAME}
                isModal={true}
                awsAccount={awsAccount}
                onCancel={onCancel}
            />
        </Modal>
    )
}

export default AssignResourceDomainModal;