import Modal from "antd/lib/modal/Modal";
import { Button } from "antd";
import { useSetRecoilState } from "recoil";
import { buildPipelineButtonState, deployEnvironmentButtonState } from "../../recoil/payment-plan-required";
import { WarningTwoTone } from '@ant-design/icons';

const AutomatedBuildsAndDeploymentsModal = ({ numberOfClicks }: { numberOfClicks: number; }) => {

    const setBuildPipelineClicks = useSetRecoilState(buildPipelineButtonState);
    const setDeployEnvClicks = useSetRecoilState(deployEnvironmentButtonState);

    const title = "Activate Automated Deployments";
    const description = <>Upgrade you plan to automatically deploy code changes on every <b> Git Push </b> for faster, error-free deployments that save you time and effort.</>;
    const numberOfSubsequentClicks: number = 2;

    const onCancel = () => {
        setBuildPipelineClicks({ noOfClicks: 0, lastClickTimestamp: 0 })
        setDeployEnvClicks({ noOfClicks: 0, lastClickTimestamp: 0 })
    }

    return (
        <Modal
            title={title}
            centered
            closable={true}
            open={numberOfClicks === numberOfSubsequentClicks}
            onCancel={onCancel}
            footer={[
                <Button
                    type="primary"
                >
                    <a href="/pricing/upgrade">
                        Upgrade Now
                    </a>
                </Button>
            ]}
        >
            <div className="flex-justify-center">
                <span style={{ width: "15%", display: "inline-block", textAlign: "center", fontSize: 40 }}>
                    <WarningTwoTone />
                </span>
                <span style={{ width: "85%", display: "inline-block" }}>
                    {description}
                </span>
            </div>
        </Modal>
    )
}

export default AutomatedBuildsAndDeploymentsModal;
