import { Card, Form, notification, Steps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Fragment, useState } from "react";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { RequiredMark } from "antd/lib/form/Form";
import { CERTIFICATE_WAITING_TIME, PROPAGATION_WAITING_TIME, getAssignResourceDomainSteps } from "../../utils/resource/assign-domain-steps";
import { useCurrentProject } from "../../contexts/Project";
import { getCloudService, getEnvironmentService, getProjectService } from "../../backend";
import { CertificateResponse } from "@microtica/ms-aws-sdk";
import { trackResourceAssignDomainFailed, trackResourceDomainCheckSucceeded, trackResourceDomainEntered, trackResourceDomainUpdateInit } from "../../backend/tracking/resource";
import { trackQuickDeploy } from "../../backend/tracking/deployment";
import { MicroserviceConfigurationItem } from "@microtica/ms-kube-sdk";
import { useNavigate } from "react-router";

interface AssignResourceDomainStepsProps {
    envId: string;
    resourceName: string;
    resourceVersion: string;
    domainCNAME: string;
    isModal: boolean;
    awsAccount: {
        id: string;
        region: string;
    }
    customDomainName?: string;
    onCancel?: () => void;
}

const AssignResourceDomainSteps = ({
    envId,
    resourceName,
    resourceVersion,
    domainCNAME,
    isModal,
    awsAccount,
    customDomainName,
    onCancel
}: AssignResourceDomainStepsProps) => {
    const navigate = useNavigate();
    const parsedCustomDomainName = customDomainName?.replace("http://", "").replace("https://", "");
    const currentProject = useRecoilValue(currentProjectState);
    const { updateCurrentProject } = useCurrentProject();
    const [newDomain, setNewDomain] = useState(parsedCustomDomainName);
    const [certificateId, setCertificateId] = useState<string>();
    const [CNAMERecord, setCNAMERecord] = useState<{ name: string; value: string }>();
    const [currentStep, setCurrentStep] = useState(0);
    const [currentAction, setCurrentAction] = useState<number>();
    const [form] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>('optional');
    // Waiting time for the connection to be established (in seconds)
    const [waitingTime, setWaitingTime] = useState(0);
    const shouldInitiateStarterPlanTrial = currentProject?.paymentPlan?.id === "FREE";

    const moveUserToCNAMERecordStep = (CNAME: {
        name: string;
        value: string;
    }) => {
        setCurrentStep(1);
        setCNAMERecord({
            name: CNAME.name,
            value: CNAME.value
        });
        setCurrentAction(undefined);
    }

    const moveUserToPartialDeployStep = () => {
        setCurrentStep(2);
        setCurrentAction(undefined);
    }

    const handleCreatedCertificate = (response: CertificateResponse) => {
        setWaitingTime(0);
        const { id, CNAME } = response;
        setCertificateId(id);
        if (!!CNAME?.name && !!CNAME?.value) {
            moveUserToCNAMERecordStep(CNAME as { name: string, value: string });
        } else {
            const interval = setInterval(async () => {
                const { data: { CNAME } } = await getCloudService().fetchCertificate(awsAccount.id, awsAccount.region, id, currentProject!.id)
                    .catch(_ => {
                        return { data: { CNAME: undefined } };
                    });

                setWaitingTime(waitingTime => {
                    const waitingTimeElapsed = waitingTime + 5 > CERTIFICATE_WAITING_TIME;
                    const foundCertificateCname = !!CNAME?.name && !!CNAME?.value;
                    if (foundCertificateCname || waitingTimeElapsed) {
                        // clear the state
                        clearInterval(interval);
                        setCurrentAction(undefined);

                        if (foundCertificateCname) {
                            moveUserToCNAMERecordStep(CNAME as any);
                            return 0; // reset waiting time
                        } else {
                            // waiting time has elapsed -> assume the setup failed
                            trackResourceAssignDomainFailed({
                                envId,
                                resourceName,
                                domain: newDomain!
                            });
                            return waitingTime + 5;
                        }
                    } else {
                        return waitingTime + 5;
                    }
                })
            }, 5000);
            return () => clearInterval(interval);
        }
    }

    const requestCertificateForDomain = async () => {
        setCurrentAction(0);
        notification.info({
            message: "Domain configuration has started",
            description: "It would take a few moments",
            icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
        });
        try {
            if (shouldInitiateStarterPlanTrial) {
                const { data: { paymentPlans } } = await getProjectService().getAllPaymentPlans();
                const starterPlan = paymentPlans.find(plan => plan.id === "STARTER")!;
                await getProjectService().changeProjectSubscription(
                    currentProject!.id,
                    {
                        paymentPlanId: starterPlan.id!,
                        priceId: starterPlan.pricing.find(p => p.isDefault)!.id
                    }
                );
                await updateCurrentProject(currentProject.id);
            }

            const { data } = await getCloudService().createACMCertificate(awsAccount.id, awsAccount.region, currentProject!.id, {
                domainName: newDomain!
            });

            isModal ?
                trackResourceDomainEntered({
                    envId,
                    resourceName,
                    domain: newDomain!
                }) :
                trackResourceDomainUpdateInit({
                    envId,
                    resourceName,
                    domain: newDomain!
                })

            handleCreatedCertificate(data);
        } catch (error) {
            setCurrentAction(undefined);
            notification.error({
                message: "Error requesting certificate",
                description: (error as any)?.response?.data?.message || "An unexpected error occurred."
            });
            trackResourceAssignDomainFailed({
                envId,
                resourceName,
                domain: newDomain!
            });
        }
    }

    const checkCertificateStatus = async () => {
        setWaitingTime(0);
        setCurrentAction(1);
        notification.info({
            message: "Propagation check has begun",
            description: "It could take a few moments"
        });
        const interval = setInterval(async () => {
            const { data: { status } } = await getCloudService().fetchCertificate(awsAccount.id, awsAccount.region, certificateId!, currentProject!.id)
                .catch(_ => {
                    return { data: { status: "UNKNOWN" } };
                });

            setWaitingTime(waitingTime => {
                const waitingTimeElapsed = waitingTime + 5 > PROPAGATION_WAITING_TIME;
                // check if status is ISSUED (success) OR the waiting time has elapsed (assume failure)
                if (status === "ISSUED" || waitingTimeElapsed) {
                    // reset the state
                    clearInterval(interval);
                    setCurrentAction(undefined);

                    if (status === "ISSUED") {
                        moveUserToPartialDeployStep();
                        trackResourceDomainCheckSucceeded({
                            envId,
                            resourceName,
                            domain: newDomain!
                        });
                        return 0; // reset waiting time
                    } else {
                        // waiting time has elapsed
                        trackResourceAssignDomainFailed({
                            envId,
                            resourceName,
                            domain: newDomain!
                        });
                        return waitingTime + 5;
                    }
                } else {
                    return waitingTime + 5;
                }
            });
        }, 5000);
        return () => clearInterval(interval);
    }

    const deployResource = async () => {
        setCurrentAction(2);
        try {
            // update resource configs (DomainName and CertificateArn/CertificateARN)
            const { data: { resources } } = await getEnvironmentService().getStageDetails(envId!, currentProject!.id);
            
            const resource = resources.find(resource => resource.name === resourceName);
            const resourceInputParams = Object.keys(resource?.component.schema.properties.inputs.properties || {});

            const resourceConfigs = resourceInputParams.reduce((configs, key) => {
                if (key === "DomainName") {
                    configs.push({
                        key,
                        value: newDomain!,
                        reference: false,
                        sensitive: false
                    });
                } else if (key === "CertificateArn" || key === "CertificateARN") {
                    // Some components expose CertificateArn and some expose CertificateARN
                    // Handle both for backwards compatibility
                    configs.push({
                        key: key,
                        value: `arn:aws:acm:${awsAccount.region}:${awsAccount.id}:certificate/${certificateId}`,
                        reference: false,
                        sensitive: false
                    });
                } else {
                    const existingConfig = resource?.configurations.find(c => c.key === key);
                    if (existingConfig) {
                        configs.push(existingConfig);
                    }
                }

                return configs;
            }, [] as MicroserviceConfigurationItem[]);

            await getEnvironmentService().updateResource(
                envId!,
                resourceName,
                currentProject!.id,
                {
                    componentVersion: resourceVersion,
                    configurations: resourceConfigs
                }
            );
            // partial deploy
            await getEnvironmentService().deployStage(
                envId,
                currentProject!.id,
                {
                    partial: true,
                    resourceVersionOverrides: {
                        [resourceName]: resourceVersion
                    }
                });
            notification.success({
                message: "Deployment initiated",
                description: "It could take a few moments for the changes to take effect.",
                icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
            });
            // close modal
            onCancel?.();
            setCurrentAction(undefined);
            setCurrentStep(0);

            trackQuickDeploy(envId);

            setTimeout(() => {
                navigate(`/projects/${currentProject!.id}/pipelines`);
            }, 2000);
        } catch (error: any) {
            notification.error({
                message: "Error deploying resource",
                description: error?.response?.data?.message || "An unexpected error occurred."
            });
            setCurrentAction(undefined);
            trackResourceAssignDomainFailed({
                envId,
                resourceName,
                domain: newDomain!
            });
        }
    }

    const stepItems = getAssignResourceDomainSteps({
        currentStep,
        waitingTime,
        form,
        requiredMark,
        isModal,
        shouldInitiateStarterPlanTrial,
        domainCNAME,
        newDomain,
        setNewDomain,
        requestCertificateForDomain,
        checkCertificateStatus,
        deployResource,
        currentAction,
        CNAMERecord
    });

    return (
        <Fragment>
            <Steps
                current={currentStep}
                size="small"
                items={stepItems.map((item, index) => ({
                    title: item.title,
                    disabled: currentStep !== index,
                    status: currentStep > index ? "finish" : currentStep < index ? "wait" : "process",
                    icon: currentAction === index && <LoadingOutlined />
                }))}
            />
            <Card style={{ marginTop: 32 }}>
                {stepItems[currentStep].description}
            </Card>
        </Fragment>
    )
}

export default AssignResourceDomainSteps;