import { EnableMonitoringRequestClusterTypeEnum } from "@microtica/ms-monitoring-sdk";
import {
  ChartMetric,
  ChartMetricItem,
  ChartMetricUnit,
  Dictionary,
} from "../types";
import { getEnvironmentService } from "../backend";

const DefaultDataForMetricGroup = {
  CPU: { fields: ["cpu_usage"], unit: "Percent" },
  Memory: { fields: ["memory_usage"], unit: "Bytes" },
  Latency: { fields: ["p50.00", "p90.00", "p99.00"], unit: "Nanoseconds" },
  "Number Of Requests": {
    fields: [
      "successful_requests_count",
      "client_errors_count",
      "server_errors_count",
    ],
    unit: "Count",
  },
  Crashes: { fields: ["count"], unit: "Count" },
};

export interface GetServiceMetrics {
  [metricGroup: string]: {
    [app: string]: {
      unit: ChartMetricUnit;
      data: {
        [timestamp: string]: {
          [metric: string]: number;
        };
      }[];
    };
  };
}

export const mapMetricsToWidget = async (
  metrics: GetServiceMetrics,
  clusterType: EnableMonitoringRequestClusterTypeEnum,
  { envId, projectId }: { envId: string; projectId: string }
) => {
  const acc = {} as Dictionary<ChartMetric>;
  await Promise.all(
    Object.entries(metrics).map(async ([metricGroup, apps]) => {
      acc[metricGroup] = Object.entries(apps).reduce(
        (acc, [appName, { data, unit }]) => {
          const dataPoints = data.reduce((acc, dataItem) => {
            const timestamp = Object.keys(dataItem)[0];
            const metrics = Object.keys(dataItem[timestamp]).map((metric) => {
              return {
                group: appName,
                timestamp: parseInt(timestamp),
                metric,
                value: dataItem[timestamp][metric],
              };
            });

            acc.push(...metrics);
            return acc;
          }, [] as ChartMetricItem[]);

          acc.data.push(...dataPoints);
          acc.unit = unit;
          acc.metrics = [
            ...new Set(
              dataPoints.filter((dp) => !!dp.metric).map((dp) => dp.metric!)
            ),
          ];
          acc.type = clusterType;
          return acc;
        },
        { data: [], unit: "Percent", metrics: [], type: "k8s" } as ChartMetric
      );

      acc[metricGroup].data = acc[metricGroup].data.sort((a, b) => {
        return a.timestamp - b.timestamp;
      });

      if (acc[metricGroup].data.length === 0) {
        if (clusterType === EnableMonitoringRequestClusterTypeEnum.K8s) {
          const { unit, fields } =
            DefaultDataForMetricGroup[
              metricGroup as keyof typeof DefaultDataForMetricGroup
            ];
          acc[metricGroup].unit = unit as ChartMetricUnit;
          acc[metricGroup].metrics = fields;
        } else {
          const {
            data: { outputs },
          } = await getEnvironmentService().getOutputsForResource(
            envId,
            Object.keys(apps)[0]!,
            projectId
          );
          const metricConfig = outputs.find((o) =>
            o.value.startsWith("Name:" + metricGroup)
          )?.value;
          const unit =
            metricConfig
              ?.split("|")
              .find((c) => c.startsWith("Unit:"))
              ?.replace("Unit:", "") || "Count";
          acc[metricGroup].unit = unit as ChartMetricUnit;
          acc[metricGroup].metrics = [unit.toLowerCase()];
        }
      }
    })
  );
  return acc;
};
