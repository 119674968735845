import { getProject } from "../../utils/local-storage";
import { trackEvent } from "./index";


export const trackEnvCreateInit = () => {
    trackEvent("env_create_init", {
        projectId: getProject().id
    });
};
export const trackEnvReplicateInit = (
    replicateEnvId: string,
    replicateEnvName: string
) => {
    trackEvent("env_replicate_init", {
        replicateEnvId,
        replicateEnvName
    });
};

export function trackEnvCreateSucceeded(
    envId: string,
    envName: string,
    cloudProvider: string,
    iacTool: string,
    enableSaving: boolean,
    template?: string) {

    trackEvent("env_create_succeeded", {
        envId,
        envName,
        cloudProvider,
        iacTool,
        enableSaving,
        template
    });
}
export function trackEnvCreateFailed(
    envName: string,
    cloudProvider: string,
    iacTool: string,
    enableSaving: boolean,
    error: string,
    template?: string) {

    trackEvent("env_create_failed", {
        envName,
        cloudProvider,
        iacTool,
        enableSaving,
        template,
        error
    });
}

export function trackEnvReplicateSucceeded(
    envId: string,
    envName: string,
    replicateEnvId: string,
    replicateEnvName: string,
    cloudProvider: string,
    iacTool: string,
    enableSaving: boolean) {

    trackEvent("env_replicate_succeeded", {
        replicateEnvId,
        replicateEnvName,
        envId,
        envName,
        cloudProvider,
        iacTool,
        enableSaving
    });
}
export function trackEnvReplicateFailed(
    replicateEnvId: string,
    replicateEnvName: string,
    envName: string,
    cloudProvider: string,
    iacTool: string,
    enableSaving: boolean,
    error: string) {

    trackEvent("env_replicate_failed", {
        replicateEnvId,
        replicateEnvName,
        cloudProvider,
        iacTool,
        enableSaving,
        envName,
        error
    });
}

export function trackEnvRemoveSucceeded(
    envId: string,
    envName: string) {

    trackEvent("env_remove_succeeded", {
        envId,
        envName
    });
}

export function trackEnvRemoveFailed(
    envId: string,
    envName: string,
    error: string) {

    trackEvent("env_remove_failed", {
        envName,
        envId,
        error
    });
}

export function trackEnvComponentAdded(
    envId: string,
    envName: string,
    componentId: string,
    componentVersion: string,
    componentName: string
) {

    trackEvent("env_component_added", {
        envId,
        envName,
        componentId,
        componentVersion,
        componentName
    })
}

export function trackEnvComponentRemoved(
    envId: string,
    componentId: string,
    componentName: string
) {

    trackEvent("env_component_removed", {
        envId,
        componentId,
        componentName
    })
}

export function trackEnvComponentConfigured(
    envId: string,
    envName: string,
    componentId: string,
    componentVersion: string,
    componentName: string
) {

    trackEvent("env_component_configured", {
        envId,
        envName,
        componentId,
        componentVersion,
        componentName
    })
}