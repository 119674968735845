
import { GithubOutlined, GitlabOutlined, BranchesOutlined } from "@ant-design/icons";
import BitbicketGrayLogo from "../assets/bitbucket-icon-gray.svg";
import BitbicketLogo from "../assets/bitbucket-icon.svg";
import AwsGrayLogo from "../assets/aws-logo-gray.svg";
import AwsLogo from "../assets/aws-logo.svg";

interface GitIconProps {
    provider?: "github" | "gitlab" | "codecommit" | "bitbucket";
    repoUrl?: string;
    color?: "gray" | "black"
}

const GitIcon = ({
    provider,
    repoUrl,
    color = "black"
}: GitIconProps) => {
    if (repoUrl) {
        try {
            new URL(repoUrl);
        } catch (error) {
            return <BranchesOutlined />;
        }

        const { host } = new URL(repoUrl);
        const [prov] = host.split(".");

        switch (prov) {
            case "github":
            case "bitbucket":
            case "gitlab":
                provider = prov;
                break;
            case "git-codecommit":
                provider = "codecommit";
                break;
        }
    }

    return provider === "github" ? <GithubOutlined /> :
        provider === "gitlab" ? <GitlabOutlined /> :
            provider === "codecommit" ? <img src={color === "gray" ? AwsGrayLogo : AwsLogo} style={{ width: "10px" }} alt="codecommit" /> :
                provider === "bitbucket" ? <img src={color === "gray" ? BitbicketGrayLogo : BitbicketLogo} style={{ width: "10px" }} alt="bitbucket" /> :
                    <BranchesOutlined />;

}

export default GitIcon;