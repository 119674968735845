import { UserNotificationsNotifications, UserNotificationsNotificationsEntityTypeEnum } from "@microtica/ms-notification-sdk";
import { atom, selector } from "recoil"

export const newNotificationsState = atom({
    key: "newNotificationsState",
    default: [] as UserNotificationsNotifications[]
});

export const newStageDeploymentNotificationsState = selector({
    key: "newStageDeploymentNotificationsState",
    get: ({ get }) => {
        const newStageDeployments = get(newNotificationsState)
            .filter(notification => notification.entityType === UserNotificationsNotificationsEntityTypeEnum.STAGE);

        return newStageDeployments;
    },
});

export const newServiceDeploymentNotificationsState = selector({
    key: "newServiceDeploymentNotificationsState",
    get: ({ get }) => {
        const newServiceDeployments = get(newNotificationsState)
            .filter(notification => notification.entityType === UserNotificationsNotificationsEntityTypeEnum.MICROSERVICE);

        return newServiceDeployments;
    }
});

export const newBuildNotificationsState = selector({
    key: "newBuildNotificationsState",
    get: ({ get }) => {
        const newBuildNotifications = get(newNotificationsState)
            .filter(notification => notification.entityType === UserNotificationsNotificationsEntityTypeEnum.PIPELINE);

        return newBuildNotifications;
    }
});

export const newUpdateNotificationsState = selector({
    key: "newUpdateNotificationsState",
    get: ({ get }) => {
        const newUpdateNotifications = get(newNotificationsState)
            .filter(notification =>
                notification.entityType === UserNotificationsNotificationsEntityTypeEnum.PIPELINE ||
                notification.entityType === UserNotificationsNotificationsEntityTypeEnum.MICROSERVICE ||
                notification.entityType === UserNotificationsNotificationsEntityTypeEnum.STAGE
            );

        return newUpdateNotifications;
    }
});