import { Button, Col, Form, Input, Row, Table, notification } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import { useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";

interface ResourceVariablesProps {
    values: Variable[];
    onChange?: (vars: Variable[]) => void;
}

interface Variable {
    key: string;
    value: string;
}

const ResourceVariables = ({
    values,
    onChange
}: ResourceVariablesProps) => {
    const [form] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>('optional');
    const [variables, setVariables] = useState<Variable[]>(values);

    const handleAddVariable = async (key: string, value: string) => {
        try {
            const keyExists = variables.find(v => v.key === key) !== undefined;

            if (keyExists) {
                return notification.error({
                    message: `The specified key '${key}' already exists`,
                    description: "To update existing environment variable, remove the old one and then add new."
                });
            }

            const updatedVars = [
                { key, value },
                ...variables,
            ];

            form.resetFields();

            setVariables(updatedVars);

            onChange?.(updatedVars);
        } catch (error) {
            notification.error({ message: "Unable to save automated deployment settings" });
        }
    }

    const handleDeleteVariable = async (key: string) => {
        try {
            const updatedVars = variables.filter(v => v.key !== key);

            setVariables(updatedVars);

            onChange?.(updatedVars);
        } catch (error) {
            notification.error({ message: "Unable to delete environment variable" });
        }
    }

    return (
        <>
            <Form
                layout="vertical"
                form={form}
                requiredMark={requiredMark}
                onFinish={() => {
                    handleAddVariable(
                        form.getFieldValue("key"),
                        form.getFieldValue("value")
                    )
                }}
            >
                <Row gutter={24}>
                    <Col span={10}>
                        <Form.Item
                            label="Name"
                            name="key"
                            required
                            rules={[
                                { required: true, message: 'Please enter variable name!' }
                            ]}
                        >
                            <Input placeholder="ENV_ID" />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Value"
                            name="value"
                            required
                            rules={[
                                { required: true, message: 'Please enter variable value!' }
                            ]}
                        >
                            <Input placeholder="env-a2cbnm" />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label=" " required>
                            <Button htmlType="submit" style={{ float: "right" }}>Add</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            {
                variables && variables.length > 0 &&
                <Table
                    size="small"
                    dataSource={variables} pagination={false}
                    columns={[
                        {
                            title: "Name",
                            key: 'name',
                            dataIndex: "key",

                        },
                        {
                            title: "Value",
                            key: 'value',
                            render: variable => variable.sensitive ? "******" : variable.value
                        },
                        {
                            key: "actions",
                            align: "right",
                            render: variable => (
                                <Button
                                    type="link"
                                    icon={<DeleteOutlined />}
                                    danger={true}
                                    onClick={() => handleDeleteVariable(variable.key)}
                                />
                            )
                        }
                    ]}>
                </Table >
            }
        </>
    )
}

export default ResourceVariables;