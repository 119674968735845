import { trackEvent } from "./index";

interface IAssignDomain {
    serviceName: string;
    clusterName: string;
    domain: string;
}

export const trackServiceCreateInit = () => {
    trackEvent("service_create_init", {
    });
};

export const trackAssignDomainInit = ({ serviceName, clusterName }: Omit<IAssignDomain, "domain">) => {
    trackEvent("service_domain_init", {
        serviceName,
        clusterName
    });
};

export const trackAssignDomainSelected = ({ serviceName, clusterName, domain }: IAssignDomain) => {
    trackEvent("service_domain_selected", {
        serviceName,
        clusterName,
        domain
    });
};

export const trackAssignDomainSucceeded = ({ serviceName, clusterName, domain }: IAssignDomain) => {
    trackEvent("service_assign_domain_succeeded", {
        serviceName,
        clusterName,
        domain
    });
};

export const trackAssignDomainFailed = ({ serviceName, clusterName, domain }: IAssignDomain) => {
    trackEvent("service_assign_domain_failed", {
        serviceName,
        clusterName,
        domain
    });
};