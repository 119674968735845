import { AlertOutlined, ArrowRightOutlined, BankOutlined, ClockCircleOutlined, FallOutlined, RiseOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Divider, Form, Modal, Row, Select, Space } from "antd";
import { HOURS, TOTAL_HOURS_IN_MONTH, WEEK_DAYS, convertToCron } from "../../utils/cron";
import { ISavingScheduleAggregatedUtilization } from "../../types";
import { convertToCurrencyWithoutFractionDigits } from "../../utils/conversion";
import { useEffect, useState } from "react";
import MessageCard from "../cards/MessageCard";

interface ICreateSavingScheduleModalProps {
    localTimeZone: string;
    savingScheduleAction: "Create" | "Update";
    expectedMonthly: {
        ec2AndRdsCost: number;
        costWithoutSchedule: number;
    }
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setCronExpression: React.Dispatch<React.SetStateAction<{
        startExpression: string;
        stopExpression: string;
    } | undefined>>;
    handleScheduleAction: () => Promise<void>;
    aggregatedUtilization?: ISavingScheduleAggregatedUtilization;
}

const FeedbackMessage = ({
    weekDays,
    startHour,
    stopHour
}: { weekDays: string[], startHour: string, stopHour: string }) => {
    if (!startHour || !stopHour || parseInt(startHour) >= parseInt(stopHour) || !weekDays || !weekDays.length) {
        return null;
    }
    return <MessageCard
        type={"domain-info"}
        text={<div style={{ fontSize: "13px" }}>
            Microtica will automatically start your cloud resources on <br />
            <b>{weekDays.join(", ")}</b><br />
            at <b>{startHour}:00</b> and turn them off at <b>{stopHour}:00</b>
        </div>}
        showIcon={true}
        icon={<AlertOutlined style={{ color: "#3399ff", fontSize: 28 }} />}
        style={{ marginBottom: "15px" }}
    />
}

const CreateSavingScheduleModal = ({
    localTimeZone,
    savingScheduleAction,
    expectedMonthly,
    isModalOpen,
    setIsModalOpen,
    setCronExpression,
    handleScheduleAction,
    aggregatedUtilization
}: ICreateSavingScheduleModalProps) => {
    const [form] = Form.useForm();
    const [expectedMonthlySavingsAmount, setExpectedMonthlySavingsAmount] = useState(0);

    useEffect(() => {
        if (isModalOpen) {
            if (aggregatedUtilization) {
                const coeficient = (+(aggregatedUtilization.totalUtilizedHours / TOTAL_HOURS_IN_MONTH).toFixed(2));
                setExpectedMonthlySavingsAmount(expectedMonthly.ec2AndRdsCost * coeficient);
            } else {
                // initial values
                form.setFieldsValue({
                    weekDays: WEEK_DAYS.slice(0, 5),
                    startHour: undefined,
                    stopHour: undefined
                })
                setExpectedMonthlySavingsAmount(0);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalOpen, expectedMonthly.ec2AndRdsCost, aggregatedUtilization?.totalUtilizedHours]);

    return (
        <Modal
            title={`${savingScheduleAction} Saving Schedule`}
            centered
            closable={true}
            maskClosable={false}
            open={isModalOpen}
            onCancel={() => {
                form.resetFields();
                setIsModalOpen(false);
            }}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={async () => {
                        await form.validateFields();
                        // create or update schedule action
                        handleScheduleAction();
                        setIsModalOpen(false);
                    }}
                >
                    {savingScheduleAction}
                </Button>
            ]}
        >
            <Form
                form={form}
                initialValues={{
                    weekDays: aggregatedUtilization?.utilizedDays || WEEK_DAYS.slice(0, 5),
                    startHour: aggregatedUtilization?.startHourInTimeZone ?
                        String(aggregatedUtilization?.startHourInTimeZone).padStart(2, "0") :
                        undefined,
                    stopHour: aggregatedUtilization?.stopHourInTimeZone ?
                        String(aggregatedUtilization?.stopHourInTimeZone).padStart(2, "0") :
                        undefined
                }}
                layout="vertical"
                requiredMark={"optional"}
            >
                <Form.Item
                    name="weekDays"
                    label={<b>Cloud resources should be active on:</b>}
                    rules={[{ required: true, message: "Select at least one day" }]}
                >
                    <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={(_) => convertToCron({
                            localTimeZone,
                            getFieldValue: form.getFieldValue,
                            setCronExpression,
                            setExpectedMonthlySavings: (totalMonthlyUtilizedHours) => setExpectedMonthlySavingsAmount(expectedMonthly.ec2AndRdsCost * (+(totalMonthlyUtilizedHours / TOTAL_HOURS_IN_MONTH).toFixed(2)))
                        })}
                    >
                        <Row>
                            {WEEK_DAYS.map(day => (
                                <Col span={6} key={day}>
                                    <Checkbox value={day} className="weekdays-checkbox">
                                        {day}
                                    </Checkbox>
                                </Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
                <div style={{ padding: "8px 0" }}><b>During the following hours:</b></div>
                <Space size={8} style={{ alignItems: "baseline" }}>
                    {
                        [{
                            name: "startHour",
                            ruleMessage: "Select wake up time",
                            placeholder: "Wake up time",
                            dependentField: "stopHour"
                        },
                        { name: "icon" },
                        {
                            name: "stopHour",
                            ruleMessage: "Select sleep time",
                            placeholder: "Sleep time",
                            dependentField: "startHour"
                        }].map(item => {
                            return item.name === "icon" ?
                                <ArrowRightOutlined style={{ marginBottom: "15px" }} key="icon" /> :
                                <Form.Item
                                    name={item.name}
                                    rules={[
                                        { required: true, message: item.ruleMessage },
                                        ({ getFieldValue, setFields }) => ({
                                            validator(_rule, _newValue) {
                                                const newStartHour = getFieldValue("startHour");
                                                const newStopHour = getFieldValue("stopHour");
                                                // reset dependentField's error message
                                                setFields([
                                                    {
                                                        name: item.dependentField!,
                                                        errors: undefined
                                                    }
                                                ])
                                                if (newStartHour > newStopHour) {
                                                    return Promise.reject("Wake up time should not be after sleep time");
                                                }
                                                else if (!!newStartHour && !!newStopHour && (newStartHour === newStopHour)) {
                                                    return Promise.reject("Wake up time should not be the same as sleep time");
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        })
                                    ]}
                                    style={{ width: "150px" }}
                                    key={item.name}
                                >
                                    <Select
                                        placeholder={item.placeholder}
                                        suffixIcon={<ClockCircleOutlined style={{ color: "#097BB5", fontSize: "14px" }} />}
                                        onSelect={(_) => convertToCron({
                                            localTimeZone,
                                            getFieldValue: form.getFieldValue,
                                            setCronExpression,
                                            setExpectedMonthlySavings: (totalMonthlyUtilizedHours) => setExpectedMonthlySavingsAmount(expectedMonthly.ec2AndRdsCost * (+(totalMonthlyUtilizedHours / TOTAL_HOURS_IN_MONTH).toFixed(2)))
                                        })}
                                    >
                                        {
                                            HOURS.map(hour => (
                                                <Select.Option value={hour} key={hour}>
                                                    {hour}:00 <span style={{ fontSize: "10px" }}>{localTimeZone}</span>
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                        })
                    }
                </Space>
                <FeedbackMessage
                    startHour={form.getFieldValue("startHour")}
                    stopHour={form.getFieldValue("stopHour")}
                    weekDays={form.getFieldValue("weekDays")}
                />
                {
                    expectedMonthly.costWithoutSchedule === undefined ?
                        null :
                        <Divider style={{ margin: "12px 0" }} />
                }
                <div style={{ padding: "8px 0" }}>
                    {
                        expectedMonthly.costWithoutSchedule === undefined ?
                            <Space style={{ fontWeight: "bold", marginTop: "12px" }} size={4}>
                                Estimated monthly savings with current setting:
                                <Space size={4} style={{ fontSize: "16px", color: "green" }}>
                                    {convertToCurrencyWithoutFractionDigits(expectedMonthlySavingsAmount)}
                                </Space>
                            </Space> :
                            <b>Estimated monthly costs:</b>
                    }
                </div>
                {
                    expectedMonthly.costWithoutSchedule === undefined ?
                        null :
                        <Row gutter={12}>
                            <Col span={8}>
                                <Card style={{ fontSize: "14px" }}>
                                    <Space direction="vertical">
                                        <div style={{ fontWeight: "500" }}><u>No schedule</u></div>
                                        <Row>
                                            <Col span={24}>
                                                <Space size={4}>
                                                    <RiseOutlined style={{ color: "black", fontSize: "16px" }} />
                                                    Cost
                                                </Space>
                                            </Col>
                                            <Col span={24} style={{ fontSize: "16px", color: "red", fontWeight: "bold" }}>
                                                {convertToCurrencyWithoutFractionDigits(expectedMonthly.costWithoutSchedule)}
                                            </Col>
                                        </Row>
                                    </Space>
                                </Card>
                            </Col>
                            <Col span={16}>
                                <Card style={{ fontSize: "14px" }}>
                                    <Space direction="vertical">
                                        <div style={{ fontWeight: "500" }}><u>Schedule with current setting</u></div>
                                        <Row>
                                            <Col span={12}>
                                                <Space size={4}>
                                                    <FallOutlined style={{ color: "black", fontSize: "16px" }} />
                                                    Cost
                                                </Space>
                                            </Col>
                                            <Col span={12}>
                                                <Space size={4}>
                                                    <BankOutlined style={{ color: "black", fontSize: "16px" }} />
                                                    Savings
                                                </Space>
                                            </Col>
                                            <Col span={12} style={{ fontSize: "16px", color: "green", fontWeight: "bold" }}>
                                                {convertToCurrencyWithoutFractionDigits(expectedMonthly.costWithoutSchedule - expectedMonthlySavingsAmount)}
                                            </Col>
                                            <Col span={12} style={{ fontSize: "16px", color: "green", fontWeight: "bold" }}>
                                                <Space size={4}>
                                                    {convertToCurrencyWithoutFractionDigits(expectedMonthlySavingsAmount)}
                                                    <span>({Math.round((expectedMonthlySavingsAmount / expectedMonthly.costWithoutSchedule) * 100)}%)</span>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Space>
                                </Card>
                            </Col>
                        </Row>
                }
            </Form>
        </Modal>
    );
}

export default CreateSavingScheduleModal;