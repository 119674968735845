import yaml from "js-yaml";
import { useEffect, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { PageHeader } from "@ant-design/pro-layout";
import { getEnvironmentService } from "../../backend";
import PageContentWrapper from "../../components/PageContentWrapper";
import { currentProjectState } from "../../recoil/project";
import { IEnvDetails, Template } from "../../types";
import { useCurrentProject } from "../../contexts/Project";
import ExplanationButton from "../../components/explanations/ExplanationButton";
import EnvironmentExplanation from "../../components/explanations/EnvironmentExplanation";
import CloudAccountLabel from "../../components/CloudAccountLabel";
import SkeletonInput from "antd/es/skeleton/Input";
import { GetStageResponseCloudProviderEnum, GetStageResponseInfrastructureAsCodeToolEnum } from "@microtica/ms-engine-sdk";
import { Button, Dropdown, Space } from "antd";
import { ArrowLeftOutlined, EllipsisOutlined, RocketOutlined, SettingOutlined } from "@ant-design/icons";
import { useQuery } from "../../contexts/Navigation";
import { getTemplate } from "../../utils/get-template";
import { newComponentState } from "../../recoil/environment";
import DeployMultipleComponentsModal from "../../components/modals/DeployMultipleComponentsModal";

interface EnvironmentRootProps {
    settings?: boolean;
    templates?: boolean;
    existingTemplate?: boolean;
}

const EnvironmentRoot = ({
    settings,
    templates,
    existingTemplate
}: EnvironmentRootProps) => {
    const query = useQuery();
    const { projectId, envId, componentId, namespace, appName, templateId } = useParams() as {
        projectId: string;
        envId: string;
        componentId: string;
        namespace: string;
        appName: string;
        templateId: string;
    };
    const currentProject = useRecoilValue(currentProjectState);
    const newComponentUpdate = useRecoilValue(newComponentState);
    const [env, setEnv] = useState<IEnvDetails>();
    const [notFoundError, setNotFoundError] = useState<boolean>(false);
    const { updateCurrentProject } = useCurrentProject();
    const [template, setTemplate] = useState<Template>();
    const [showDeployModal, setShowDeployModal] = useState(false);

    useEffect(() => {
        // change the current project if the projectId in the URL is different from the current project saved in local storage
        // this is the root component for all Environment related routes, so no need to do this in other places
        if (!!projectId && projectId !== currentProject.id) {
            updateCurrentProject(projectId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        (async () => {
            if (templates) {
                const templateString = await getTemplate(query.get("template")!);
                setTemplate(yaml.load(templateString) as Template);
            }
        })();

    }, [templateId]);

    useEffect(() => {
        if (newComponentUpdate.envId == envId) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newComponentUpdate]);

    const loadData = async () => {
        const [
            templateString,
            { data: env }
        ] = await Promise.all([
            templates ? getTemplate(query.get("template")!) : Promise.resolve(""),
            getEnvironmentService().getStageDetails(envId, projectId)
                .catch(err => {
                    // display PageNotFound if this API call failed with 404 error
                    if (err?.response?.data?.code === 404) {
                        setNotFoundError(true);
                    }
                    throw err;
                })
        ]);

        if (templates) {
            setTemplate(yaml.load(templateString) as Template)
        }

        setNotFoundError(false);

        setEnv({
            id: env.id,
            name: env.name,
            description: env.description,
            lastDeployed: env.lastDeployed,
            status: env.status,
            awsAccountId: env.awsAccountId,
            region: env.awsRegion,
            gcpProjectId: env.gcpProjectId,
            gcpRegion: env.gcpRegion,
            gcpZone: env.gcpZone,
            infrastructureAsCodeTool: env.infrastructureAsCodeTool as GetStageResponseInfrastructureAsCodeToolEnum,
            cloudProvider: env.cloudProvider as GetStageResponseCloudProviderEnum,
            lastDeploymentId: env.currentDeploymentId,
            resources: env.resources,
            onChange: async () => loadData()
        });
    }

    const EnvPageHeader = () => (
        <PageHeader>
            {
                env ?
                    <div className="flex-justify-space-between flex-align-center">
                        <div>
                            <div style={{ fontSize: "20px", fontWeight: 600 }}>
                                {env.name}
                                <ExplanationButton
                                    content={<EnvironmentExplanation />}
                                />
                            </div>
                            <CloudAccountLabel
                                provider={env.cloudProvider}
                                envId={env.id}
                                accountId={env.awsAccountId || env.gcpProjectId}
                                region={env.region || env.gcpRegion}
                            />
                        </div>
                        {/* <Dropdown
                            menu={{
                                items: [
                                    {
                                        label: <Link to={`/projects/${projectId}/environments/${envId}/settings`}>
                                            <div className="flex-align-center">
                                                <RocketOutlined rotate={45} style={{ fontSize: 15 }} />&nbsp;Deploy
                                            </div>
                                        </Link>,
                                        key: "deploy"
                                    },
                                    {
                                        label: <Link to={`/projects/${projectId}/environments/${envId}/settings`}>
                                            <div className="flex-align-center">
                                                <SettingOutlined />&nbsp;Environment Settings
                                            </div>
                                        </Link>,
                                        key: "settings"
                                    }
                                ]
                            }}
                            trigger={['click']}
                        >
                            <Button
                                icon={<EllipsisOutlined />}
                            />
                        </Dropdown> */}
                        <Space>
                            <Button
                                onClick={() => setShowDeployModal(true)}
                                // disabled={!component.component}
                                icon={<RocketOutlined style={{ fontSize: 16 }} rotate={45} />}
                            >
                                Bulk Deployment
                            </Button>
                            <Link to={`/projects/${projectId}/environments/${envId}/settings`}>
                                <Button icon={<SettingOutlined />} />
                            </Link>
                        </Space>
                        {
                            showDeployModal &&
                            <DeployMultipleComponentsModal
                                projectId={projectId}
                                envId={envId}
                                // lastDeploymentId={env.lastDeploymentId}
                                open={showDeployModal}
                                onCancel={() => setShowDeployModal(false)}
                            />
                        }
                    </div> :
                    <SkeletonInput size="small" />
            }
        </PageHeader>
    )

    const SettingsPageHeader = () => (
        <PageHeader>
            {
                env ?
                    <Space size="large">
                        <Link to={
                            appName ? `/projects/${projectId}/environments/${envId}/components/${componentId}/apps/${namespace}/${appName}/overview` :
                                componentId ?
                                    `/projects/${projectId}/environments/${envId}/components/${componentId}/deployments` :
                                    `/projects/${projectId}/environments/${envId}`
                        }>
                            <Button icon={<ArrowLeftOutlined />} />
                        </Link>
                        <div>
                            <div style={{ fontSize: "16px", }}>
                                {
                                    appName ? "App settings" :
                                        componentId ? "Component settings" :
                                            "Environment settings"
                                }
                            </div>
                            <div style={{ fontSize: "20px", fontWeight: 600 }}>
                                {appName || componentId || env.name}
                            </div>
                        </div>
                    </Space> :
                    <SkeletonInput />
            }
        </PageHeader>
    )

    const TemplatesPageHeader = () => (
        <PageHeader>
            {
                env && template ?
                    <Space size="large">
                        <Link to={
                            appName ? `/projects/${projectId}/environments/${envId}/components/${componentId}/apps/${namespace}/${appName}/overview` :
                                componentId ?
                                    `/projects/${projectId}/environments/${envId}/components/${componentId}/deployments` :
                                    `/projects/${projectId}/environments/${envId}`
                        }>
                            <Button icon={<ArrowLeftOutlined />} />
                        </Link>
                        <div>
                            <div style={{ fontSize: "16px", }}>
                                Deploy template
                            </div>
                            <div style={{ fontSize: "20px", fontWeight: 600 }}>
                                {template?.name}
                            </div>
                        </div>
                    </Space> :
                    <SkeletonInput />
            }
        </PageHeader>
    )

    const ExistingTemplatesPageHeader = () => (
        <PageHeader>
            {
                env ?
                    <Space size="large">
                        <Link to={
                            appName ? `/projects/${projectId}/environments/${envId}/components/${componentId}/apps/${namespace}/${appName}/overview` :
                                componentId ?
                                    `/projects/${projectId}/environments/${envId}/components/${componentId}/deployments` :
                                    `/projects/${projectId}/environments/${envId}`
                        }>
                            <Button icon={<ArrowLeftOutlined />} />
                        </Link>
                        <div>
                            <div style={{ fontSize: "16px", }}>
                                Deploy ready template
                            </div>
                            <div style={{ fontSize: "20px", fontWeight: 600 }}>
                                {query.get("templateName") || templateId}
                            </div>
                        </div>
                    </Space> :
                    <SkeletonInput />
            }
        </PageHeader>
    )

    return (
        <PageContentWrapper
            size="medium"
            header={
                settings ?
                    <SettingsPageHeader /> :
                    templates ?
                        <TemplatesPageHeader /> :
                        existingTemplate ?
                            <ExistingTemplatesPageHeader /> :
                            <EnvPageHeader />
            }
            notFoundError={notFoundError}
        >
            <Outlet context={{ env }} />
        </PageContentWrapper >
    );
}

export default EnvironmentRoot;