import { Drawer } from "antd";
import { ReactNode } from "react";

interface SideDrawerProps {
    title: string | ReactNode;
    content: ReactNode;
    visible?: boolean;
    onClose?: () => void;
}

const SideDrawer = ({
    title,
    content,
    visible,
    onClose
}: SideDrawerProps) => {

    return (
        <Drawer
            title={title}
            placement="right"
            open={visible}
            destroyOnClose={true}
            closable={true}
            onClose={onClose}
        >
            {content}
        </Drawer>
    )
}

export default SideDrawer;