import { PageHeader } from "@ant-design/pro-layout";
import PageContentWrapper from "../../components/PageContentWrapper";
import {
    Avatar,
    Button,
    Cascader,
    CascaderProps,
    ConfigProvider,
    Divider,
    Empty,
    InputRef,
    Modal,
    ModalFuncProps,
    Select,
    Space,
    Table,
    Tooltip,
    notification
} from "antd";
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getEnvironmentService, getPipelinesService } from "../../backend";
import { useParams } from "react-router";
import GitCommitLink from "../../components/GitCommitLink";
import moment from "moment";
import BuildStatusTag from "../../components/BuildStatusTag";
import DeploymentStatusTag, { isDeploymentStatusInProgress } from "../../components/DeploymentStatusTag";
import { Link } from "react-router-dom";
import {
    BranchesOutlined,
    ClockCircleOutlined,
    CloseCircleFilled,
    CloseCircleOutlined,
    ExportOutlined,
    FilterOutlined,
    FolderOutlined,
    LoadingOutlined,
    ProductOutlined,
    ReloadOutlined,
    RocketOutlined,
    ThunderboltOutlined
} from "@ant-design/icons";
import { GetStagesItem } from "@microtica/ms-engine-sdk";
import { PipelineItem, PipelineItemTarget } from "../../types";
import BuildDetailsDrawer from "../../components/BuildDetailsDrawer";
import ComponentDeployDetailsDrawer from "../../components/ComponentDeployDetailsDrawer";
import { getPipelines } from "../../utils/deployments";
import { newUpdateNotificationsState } from "../../recoil/notification";
import { useRecoilValue } from "recoil";
import AppDeployDetailsDrawer from "../../components/AppDeployDetailsDrawer";
import { ColumnsType } from "antd/es/table";
import Paragraph from "antd/es/typography/Paragraph";
import { trackCancelDeployment } from "../../backend/tracking/deployment";
import { Pipeline } from "@microtica/ms-ap-sdk";
import GitIcon from "../../components/GitIcon";
import Search from "antd/es/input/Search";
import Fuse, { FuseResultMatch } from 'fuse.js'

interface Option {
    value?: string;
    label: React.ReactNode;
    children?: Option[];
    isLeaf?: boolean;
    filterMetadata?: Pipeline;
    matches?: FuseResultMatch[];
}

const ProjectPipelines = () => {
    const { projectId } = useParams() as { projectId: string; }
    const updates = useRecoilValue(newUpdateNotificationsState);
    const [loading, setLoading] = useState(true);
    const [reloading, setReloading] = useState(false);
    const [pipelines, setPipelines] = useState<PipelineItem[]>([]);
    const [filteredPipelines, setFilterdPipelines] = useState<PipelineItem[]>();
    const [selectedPipeline, setSelectedPipeline] = useState<PipelineItem>();
    const [showPipelineDetails, setShowPipelineDetails] = useState(false);
    const [envs, setEnvs] = useState<GetStagesItem[]>([]);
    const [selectedEnvs, setSelectedEnvs] = useState<string[]>([]);
    const [repos, setRepos] = useState<string[]>([]);
    const [selectedRepos, setSelectedRepos] = useState<string[]>([]);
    const [timeFilter, setTimeFilter] = useState<"recent" | "week" | "month">("recent");
    const [initialFetch, setInitialFetch] = useState(true);
    const [, setModal] = useState<{
        destroy: () => void;
        update: (configUpdate: ModalFuncProps) => void;
    }>();

    const [allPipelines, setAllPipelines] = useState<Pipeline[]>([]);
    const [allPipelinesLoading, setAllPipelinesLoading] = useState(false);
    const [triggerOptions, setTriggerOptions] = useState<Option[]>([]);
    const [filteredTriggerOptions, setFilteredTriggerOptions] = useState<Option[]>([]);
    const [triggerFilter, setTriggerFilter] = useState<string>();
    const [selectedPipelineBranch, setSelectedPipelineBranch] = useState<string[]>([]);

    const searchPipelinesRef = useRef<InputRef>(null);


    const fuseOptions = {
        keys: [
            "filterMetadata.id",
            "filterMetadata.name",
            "filterMetadata.repositoryUrl",
            "filterMetadata.modifiedFiles",
            "children.value"
        ]
    };

    useEffect(() => {
        if (!initialFetch && updates.some(update => update.projectId === projectId)) {
            loadPipelines();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updates]);

    useEffect(() => {
        loadPipelines();
        setInitialFetch(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchPipelines(pipelines, envs);
    }, [selectedEnvs, selectedRepos, timeFilter]);

    useEffect(() => {
        if (!triggerFilter) {
            setFilteredTriggerOptions(triggerOptions)
        } else {
            const fuse = new Fuse(triggerOptions, fuseOptions);
            setFilteredTriggerOptions(
                fuse.search(triggerFilter).map(s => s.item)
            )
        }

    }, [triggerOptions, triggerFilter]);

    // the fetchPipelines function will be re-created whenever timeFilter, selectedEnvs, or selectedRepos change
    const fetchPipelines = useCallback(
        (pipelines: PipelineItem[], envs: GetStagesItem[]) => {
            const filteredPipelines = pipelines
                // We need deployments only for the current environments
                .filter(item => item.type === "deploy" && item.envId ?
                    item.targets.some(t => envs.map(e => e.id).includes(t.envId || "")) :
                    true
                )
                .filter(item => {
                    const referentDate = new Date();

                    if (timeFilter === "recent") {
                        referentDate.setDate(referentDate.getDate() - 3);
                    } else if (timeFilter === "week") {
                        referentDate.setDate(referentDate.getDate() - 7);
                    } else if (timeFilter === "month") {
                        referentDate.setMonth(referentDate.getMonth() - 1);
                    }

                    return referentDate.getTime() <= item.startDate.getTime();
                })
                .filter(item => {
                    return !!item.targets.find(t => {
                        return (selectedEnvs.length > 0 ? selectedEnvs.includes(t.envId!) : true) &&
                            (selectedRepos.length > 0 ? selectedRepos.includes(t.repositoryName) : true)
                    });
                });

            setFilterdPipelines(filteredPipelines);
            setRepos(
                [...new Set(filteredPipelines.flatMap(item => item.targets.map(target => target.repositoryName)))]
                    .filter(r => !!r)
            )
        },
        [timeFilter, selectedEnvs, selectedRepos]
    )

    const loadPipelines = async () => {
        const [
            { data: { stages: envs } },
            pipelines
        ] = await Promise.all([
            getEnvironmentService().getStages(projectId),
            getPipelines(
                projectId,
                {
                    // Month ago
                    from: new Date(new Date().setMonth(new Date().getMonth() - 1)).getTime()
                }
            )
        ]);

        setEnvs(envs);
        setPipelines(pipelines);
        setLoading(false);
        fetchPipelines(pipelines, envs);
    }

    const reloadPipelines = async () => {
        setReloading(true);
        await loadPipelines();
        setReloading(false);
    }


    const loadAllPipelines = async () => {
        setAllPipelinesLoading(true);


        const { data: { pipelines: allPipelines } } = await getPipelinesService().listPipelines(projectId);

        setAllPipelines(allPipelines);
        setTriggerOptions(allPipelines.map(p => ({
            value: p.id,
            label: <div style={{ padding: "10px" }}>
                <div>
                    <GitIcon provider={p.gitAccountType as any} /> {p.repositoryUrl?.split("/").reverse().slice(0, 2).reverse().join("/")}
                </div>
                <div className="gray-text">
                    {p.name}{p.modifiedFiles && <><Divider type="vertical" /><FolderOutlined /> {p.modifiedFiles}</>}
                </div>
            </div>,
            filterMetadata: p,
            isLeaf: false
        })));
        setAllPipelinesLoading(false);
        searchPipelinesRef.current?.focus({ cursor: 'all' });
    }

    const loadBranches = async (selectedOptions: Option[]) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        const selectedPipeline = allPipelines.find(p => p.id === targetOption.value)

        if (selectedPipeline) {
            const { data: { branches } } = await getPipelinesService().listGitAccountRepositoryBranches(
                projectId,
                selectedPipeline.gitAccountId!,
                selectedPipeline.repositoryUrl!
            );

            setTriggerOptions(
                triggerOptions.reduce((acc, option) => {
                    if (option.value === selectedPipeline.id) {
                        option.children = branches.map(b => ({
                            value: b,
                            label: <>
                                <BranchesOutlined /> {b}
                            </>
                        }))
                    }
                    acc.push(option);

                    return acc;
                }, [] as Option[])
            )
        }
    }

    const CommitTooltip = (props: {
        children: ReactNode,
        target: PipelineItemTarget
    }) => {
        const { children, target } = props;
        return <Tooltip
            title={
                <div style={{ fontSize: "12px" }}>
                    <div style={{ marginBottom: "10px" }}>
                        <b>{target.commit.message}</b>
                    </div>
                    <div>
                        <GitCommitLink version={target.commit.sha || target.commit.version} repoUrl={target.repository} branch={target.commit.name} />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        {target.repositoryName}
                    </div>
                    <div>
                        <Avatar className="no-border" src={target.commit.user.avatar} size={18} /> {target.commit.user.name}
                    </div>
                    <div>
                        {
                            target.commit.date ?
                                `${moment(target.commit.date).fromNow()} • ${moment(target.commit.date).format("HH:mm • DD MMM YY")}` :
                                undefined
                        }
                    </div>
                </div>
            }
        >
            {children}
        </Tooltip>
    }

    const getPipelineTypeColumnContent = (item: PipelineItem) => {
        if (item.status === "QUEUED") {
            return <Avatar style={{ paddingTop: 1 }} icon={<ClockCircleOutlined />} />
        }
        if (item.type === "buildonly") {
            return <Tooltip title="No deployment steps are included in this pipeline, thus, no deployment will be initiated.">
                <Avatar style={{ backgroundColor: "goldenrod", paddingTop: 1 }} icon={<ThunderboltOutlined />} />
            </Tooltip>
        }
        if (item.type === "build") {
            return <Tooltip title="No matching criteria to initiate deployment.">
                <Avatar style={{ backgroundColor: "goldenrod", paddingTop: 1 }} icon={<ThunderboltOutlined />} />
            </Tooltip>
        }
        if (item.type === "template") {
            return <Tooltip title="A temporary pipeline orchestrating the deployment of a template.">
                <Avatar style={{ backgroundColor: "goldenrod", paddingTop: 1 }} icon={<ProductOutlined />} />
            </Tooltip>
        }
        return <Tooltip title="This pipeline initiates deployment for components or apps.">
            <Avatar style={{ backgroundColor: "#077BB5" }} icon={<RocketOutlined rotate={45} />} />
        </Tooltip>
    }

    const PipelineTypeColumn = ({ item }: { item: PipelineItem }) => {
        return <div style={{ fontWeight: 700, fontSize: "13px" }}>
            {getPipelineTypeColumnContent(item)}
        </div>
    }

    const PipelineTargetColumn = ({ item }: { item: PipelineItem }) => {
        const envIds = [...new Set(item.targets.map(t => t.envId))].filter(envId => !!envId);
        const envComponents = envIds.map((envId, index) => (
            <>
                {envId} <Link to={`/projects/${projectId}/environments/${envId}`}><ExportOutlined /></Link>
                {index !== envIds.length - 1 && <Divider type="vertical" />}
            </>
        ));

        if (item.status === "QUEUED" || item.status === "DEPLOY_QUEUED") {
            return <>Pending targets...</>
        }
        if (item.type === "deploy") {
            return <Space direction="vertical" style={{ fontSize: "12px" }}>
                {
                    !item.targets.length ? <>No targets to display</> :
                        <>
                            {
                                envComponents.length ?
                                    <div>
                                        <span style={{ fontWeight: 600 }}>Environment:</span> {envComponents}
                                    </div> : undefined
                            }
                            <Paragraph
                                ellipsis={{
                                    rows: 3,
                                    expandable: true,
                                    symbol: "Show more",
                                    onExpand: (e) => e.stopPropagation()
                                }}
                                style={{ fontSize: 12, marginBottom: 0 }}
                            >
                                {
                                    item.targets.map(target => (
                                        <div key={target.commit.version}>
                                            <span style={{ fontWeight: 600 }}>
                                                {target.type === "component" ? "Component: " : "Application: "}
                                            </span>
                                            {target.name}
                                            {
                                                target.undeployed ?
                                                    <span> {target.status.endsWith("_IN_PROGRESS") ? "(undeploying)" : "(undeployed)"}</span> :
                                                    target.commit.sha || target.commit.version ?
                                                        <CommitTooltip target={target}>
                                                            <Divider type="vertical" />
                                                            <GitCommitLink
                                                                hideIcon
                                                                version={target.commit.sha || target.commit.version}
                                                                repoUrl={target.repository}
                                                                branch={target.commit.name}
                                                            />
                                                        </CommitTooltip> : <span> deployed</span>
                                            }
                                        </div>
                                    ))
                                }
                            </Paragraph>
                        </>
                }
            </Space>
        }
        if ((item.type === "buildonly" || item.type === "build") && item.status !== "QUEUED") {
            return <Space direction="vertical" style={{ fontSize: "12px" }}>
                <div>
                    Build only, no deployment of any component or app
                </div>
                <CommitTooltip target={item.targets[0]}>
                    <span>
                        <GitCommitLink
                            version={item.targets[0].commit.sha || item.targets[0].commit.version}
                            repoUrl={item.targets[0].repository}
                            branch={item.targets[0].commit.name}
                        />
                    </span>
                </CommitTooltip>
            </Space>
        }
        if (item.type === "template") {
            return <Space direction="vertical" style={{ fontSize: "12px" }}>
                <div>
                    <span style={{ fontWeight: 600 }}>Environment:</span> {
                        [...new Set(item.targets.map(t => t.envId))].map(envId => (
                            <>
                                {envId} <Link to={`/projects/${projectId}/environments/${envId}`}><ExportOutlined /></Link>
                            </>
                        ))
                    }
                </div>
                <div>
                    Template deployment pipeline
                </div>
            </Space>
        }
        return <></>;
    }

    const PipelineTimeColumn = ({ item }: { item: PipelineItem }) => {
        // Remaining days until next Monday, because we cleanup template pipelines each Monday at 1:00 AM
        const millisecondsUntilNextMonday = (8 - new Date().getDay()) % 7 * 24 * 60 * 60 * 1000;
        const expiration = Math.floor(millisecondsUntilNextMonday / (1000 * 60 * 60));
        const expirationLabel = expiration <= 0 ? "Expires in less then an hour" : `Expires in ${expiration} hours`;
        const [timeValue, setTimeValue] = useState(item.type === "template" ? expirationLabel : moment(item.startDate).fromNow());

        useEffect(() => {
            let intervalId: NodeJS.Timeout;
            // deployment status should be DEPLOY_QUEUED or it should end with IN_PROGRESS
            // build status should be QUEUED or RUNNING
            const isRunningStatus = ["DEPLOY_QUEUED", "QUEUED", "RUNNING"].includes(item.status) || isDeploymentStatusInProgress(item.status);
            if (isRunningStatus) {
                intervalId = setInterval(() => {
                    setTimeValue(moment(item.startDate).fromNow());
                    // the interval should be cleared after deployed status, but we don't clear it here because: 
                    // deployed status will be received via notification which will cause rerender of this component, 
                    // the rerender will trigger unmount and the existing interval will be cleared and then isRunningStatus will become false
                }, 15 * 1000);
            }
            // Clean up interval on component unmount
            return () => clearInterval(intervalId);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [item.status]);

        return (
            <Space direction="vertical" style={{ fontSize: "12px" }}>
                <div style={{ fontSize: "13px" }}>
                    {timeValue}
                </div>
                <div>
                    <Avatar className="no-border" src={item.initiatorAvatar} size={18} /> {item.initiatorName}
                </div>
            </Space>
        )
    }

    const PipelineStatusColumn = ({ item }: { item: PipelineItem }) => {
        if (item.stage === "deploy") {
            return <DeploymentStatusTag status={item.status} />
        } else {
            return <BuildStatusTag status={item.status} statusCode={item.statusCode} />
        }
    }

    const PipelineActionsColumn = ({ item }: { item: PipelineItem }) => {
        if (
            item.status === "DEPLOY_QUEUED" ||
            (["CREATE_IN_PROGRESS", "UPDATE_IN_PROGRESS"].includes(item.status) && item.cloudProvider === "aws" && item.stage === "deploy")
        ) {
            return <Tooltip title="Cancel Queued Deployment">
                <Button
                    type="text"
                    icon={<CloseCircleOutlined />}
                    danger
                    onClick={(e) => {
                        showCancelStageDeploymentConfirm(item);
                        e.stopPropagation();
                    }}
                />
            </Tooltip>
        } else if (item.status === "RUNNING" && item.stage === "build" && item.type !== "template") {
            return <Tooltip title="Cancel Build">
                <Button
                    type="text"
                    icon={<CloseCircleOutlined />}
                    danger
                    onClick={(e) => {
                        showCancelBuildConfirm(item);
                        e.stopPropagation();
                    }}
                />
            </Tooltip>
        } else {
            return <Tooltip title="Only Queued and Active pipelines can be canceled.">
                <Button
                    disabled
                    type="text"
                    icon={<CloseCircleOutlined />}
                />
            </Tooltip>
        }
    }

    const showCancelStageDeploymentConfirm = (pipeline: PipelineItem) => {
        const modal = Modal.confirm({
            title: "Cancel deployment",
            icon: <CloseCircleFilled style={{ color: "#ff4d4f" }} />,
            content: "Are you sure you want to cancel the deployment? This action cannot be undone.",
            okText: "Cancel Deployment",
            cancelText: "No",
            centered: true,
            okButtonProps: {
                danger: true
            },
            async onOk() {
                return handleCancelDeployment(pipeline);
            },
            onCancel() {
                setModal(undefined);
            }
        });
        setModal(modal);
    }

    const showCancelBuildConfirm = (pipeline: PipelineItem) => {
        const modal = Modal.confirm({
            title: "Cancel build",
            icon: <CloseCircleFilled style={{ color: "#ff4d4f" }} />,
            content: "Are you sure you want to cancel the build execution? This action cannot be undone.",
            okText: "Cancel Build",
            cancelText: "No",
            centered: true,
            okButtonProps: {
                danger: true
            },
            async onOk() {
                return handleCancelBuild(pipeline);
            },
            onCancel() {
                setModal(undefined);
            }
        });
        setModal(modal);
    }

    const handleCancelBuild = async (pipeline: PipelineItem) => {
        try {
            await getPipelinesService().stopPipelineExecution(
                projectId,
                pipeline.build?.pipelineId!,
                pipeline.build?.id!
            );
            notification.info({
                message: "Build canceled",
                description: "The pipeline build has been canceled"
            });
            trackCancelDeployment(pipeline.envId!);
        } catch (error: any) {
            notification.info({
                message: "Build canceled error",
                description: error.response?.data?.message || "An unknown erorr has occured"
            });
        }
        setModal(undefined);
    }

    const handleCancelDeployment = async (pipeline: PipelineItem) => {
        try {
            await getEnvironmentService().cancelDeploy(
                pipeline.envId!,
                projectId,
                pipeline.status === "DEPLOY_QUEUED" ? {
                    deploymentId: pipeline.id
                } : undefined
            );
            notification.info({
                message: "Deployment canceled",
                description: "The environment deployment has been canceled"
            });
            trackCancelDeployment(pipeline.envId!);
        } catch (error: any) {
            notification.info({
                message: "Deployment canceled error",
                description: error.response?.data?.message || "An unknown erorr has occured"
            });
        }
        setModal(undefined);
    }

    // we want to update the columns only when the datasource (filteredPipelines) is updated
    // otherwise we will have unnecessary rerenders
    const columnsMemo = useMemo<ColumnsType<PipelineItem>>(() => [
        {
            title: 'Type',
            key: 'type',
            width: "100px",
            render: (item: PipelineItem) => <PipelineTypeColumn item={item} />
        },
        {
            title: 'Status',
            key: 'status',
            // align: "right",
            width: "150px",
            render: (item: PipelineItem) => <PipelineStatusColumn item={item} />
        },
        {
            title: 'Target',
            key: 'target',
            render: (item: PipelineItem) => <PipelineTargetColumn item={item} />
        },
        {
            title: 'Execution time',
            key: 'time',
            width: "20%",
            render: (item: PipelineItem) => <PipelineTimeColumn item={item} />
        },
        {
            title: 'Actions',
            key: 'time',
            width: "100px",
            align: "center",
            render: (item: PipelineItem) => <PipelineActionsColumn item={item} />
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [filteredPipelines]);

    const handleSelectBranch: CascaderProps<Option>['onChange'] = async (value) => {
        try {
            await getPipelinesService().triggerPipeline(
                projectId,
                value[0],
                { ref: `refs/heads/${value[1]}` }
            );
            notification.success({
                message: "Pipeline build triggered",
                description: "It could take a few moments for the changes to take effect.",
                icon: <LoadingOutlined style={{ color: "var(--primary-color)" }} />
            });
        } catch (error) {
            notification.error({
                message: "Error triggering build",
                description: (error as any).response?.data?.message || "We encountered an error while trying to trigger a build. Please try again."
            });
        } finally {
            setSelectedPipelineBranch([]);
        }
    }


    return (
        <>
            <PageContentWrapper
                size="large"
                header={
                    <PageHeader
                        title="Pipelines"
                        extra={
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Cascader: {
                                            /* here is your component tokens */
                                            dropdownHeight: 500
                                        },
                                    },
                                }}
                            >
                                <Cascader
                                    options={filteredTriggerOptions}
                                    loadData={loadBranches}
                                    onChange={handleSelectBranch}
                                    value={selectedPipelineBranch}
                                    dropdownRender={(menus: ReactNode) => (
                                        <div>
                                            <Search
                                                className="full-width"
                                                style={{ padding: "10px" }}
                                                placeholder="Search pipelines..."
                                                onSearch={setTriggerFilter}
                                                defaultValue={triggerFilter}
                                                ref={searchPipelinesRef}
                                            />
                                            <Divider style={{ margin: 0 }} />
                                            {
                                                allPipelinesLoading ?
                                                    <Space className="flex-justify-center gray-text" style={{ padding: "10px" }}>
                                                        <LoadingOutlined /> Loading pipelines...
                                                    </Space>
                                                    :
                                                    menus
                                            }
                                        </div>
                                    )}
                                >
                                    <Button onClick={loadAllPipelines}>
                                        <ThunderboltOutlined /> Trigger
                                    </Button>
                                </Cascader>
                            </ConfigProvider>
                        }
                    />
                }
            >
                <Table
                    className="ant-table-selectable"
                    loading={loading}
                    dataSource={filteredPipelines}
                    sticky={{ offsetHeader: 64 }}
                    style={{ opacity: reloading ? 0.8 : 1 }}
                    locale={{
                        emptyText:
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    <>
                                        <div>
                                            There are no pipelines available for the chosen period.
                                        </div>
                                        <div>
                                            This could indicate a period of inactivity or no deployments during this time.
                                        </div>
                                    </>
                                }
                            />
                    }}
                    title={() =>
                        <div className="flex-justify-space-between flex-align-center">
                            <Space>
                                <Select
                                    mode="tags"
                                    placeholder="Filter by Environment"
                                    style={{ width: 300 }}
                                    onChange={(value) => setSelectedEnvs(value)}
                                    suffixIcon={<FilterOutlined />}
                                    options={envs.map(env => ({
                                        value: env.id,
                                        label: env.name
                                    }))}
                                />
                                <Select
                                    mode="tags"
                                    placeholder="Filter by repository name"
                                    style={{ width: 500 }}
                                    onChange={(value) => setSelectedRepos(value)}
                                    suffixIcon={<FilterOutlined />}
                                    options={repos.map(repo => ({
                                        value: repo,
                                        label: repo
                                    }))}
                                />
                            </Space>
                            <Space style={{ fontWeight: 600 }}>
                                <Select
                                    style={{ width: 150 }}
                                    defaultValue={timeFilter}
                                    onChange={(value) => setTimeFilter(value)}
                                    options={[
                                        { value: "recent", label: "Last 3 days" },
                                        { value: "week", label: "Last week" },
                                        { value: "month", label: "Last month" }
                                    ]}
                                />
                                <Button icon={<ReloadOutlined />} onClick={reloadPipelines} />
                            </Space>
                        </div>
                    }
                    columns={columnsMemo}
                    onRow={item => {
                        return {
                            onClick: () => {
                                setSelectedPipeline(item)
                                setShowPipelineDetails(true);
                            }, // click row
                        };
                    }}
                />
            </PageContentWrapper>
            {
                showPipelineDetails ? (
                    <>
                        {
                            selectedPipeline?.build &&
                            <BuildDetailsDrawer
                                type={selectedPipeline.type}
                                projectId={projectId}
                                pipelineId={selectedPipeline.build?.pipelineId!}
                                buildId={selectedPipeline.build?.id!}
                                open={showPipelineDetails}
                                onClose={() => setShowPipelineDetails(false)}
                            />
                        }
                        {
                            !selectedPipeline?.build && selectedPipeline?.targetType === "environment" &&
                            <ComponentDeployDetailsDrawer
                                type={selectedPipeline.type}
                                projectId={projectId}
                                envId={selectedPipeline.envId!}
                                cloudProvider={envs.find(e => e.id === selectedPipeline.envId!)?.cloudProvider!}
                                iac={envs.find(e => e.id === selectedPipeline.envId!)?.infrastructureAsCodeTool!}
                                deploymentId={selectedPipeline.id}
                                open={showPipelineDetails}
                                onClose={() => setShowPipelineDetails(false)}
                            />
                        }
                        {
                            !selectedPipeline?.build && selectedPipeline?.targetType === "app" &&
                            <AppDeployDetailsDrawer
                                type={selectedPipeline.type}
                                projectId={projectId}
                                envId={selectedPipeline.envId!}
                                deploymentId={selectedPipeline.id}
                                open={showPipelineDetails}
                                onClose={() => setShowPipelineDetails(false)}
                            />
                        }
                    </>
                ) : null
            }
        </>
    )
}

export default ProjectPipelines;