const EnvironmentExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Environment
            </div>
            <div className="explanation-subtitle">
                Logical group of multiple combined components that represent one complete and isolated system
            </div>
            <br />
            <ul>
                <li>
                    This is the scope in which all your applications and infrastructure resource operate together
                </li>
                <li>
                    The <span style={{ fontStyle: "italic" }}>Environment</span> is connected to exactly one AWS account where all of the components are deployed
                </li>
            </ul>
        </>
    )
}

export default EnvironmentExplanation;