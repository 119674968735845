import { UpdateStageRequestAwsRegionEnum } from "@microtica/ms-engine-sdk";
import { notification } from "antd";
import { getEnvironmentService, getCloudService } from "../backend";
import { AwsRegionType } from "./../types";
import { AwsAccountAction, handleReport } from "./handle-report";

/**
 * Connect AWS account to Stage
 */
export const linkAwsAccountToStage = async ({ envId, projectId, awsAccountId, awsRegion }: { envId: string, projectId: string, awsAccountId: string, awsRegion?: string }) => {
    try {
        const chosenAwsRegion = awsRegion ?
            UpdateStageRequestAwsRegionEnum[awsRegion as AwsRegionType] :
            UpdateStageRequestAwsRegionEnum.EuCentral1;

        await Promise.all([
            getEnvironmentService().updateStage(
                envId,
                projectId,
                {
                    awsAccountId,
                    awsRegion: chosenAwsRegion
                }
            ),
            // handleReport(AwsAccountAction.CreateUpdate, projectId, awsAccountId, chosenAwsRegion)
        ]);
        notification.success({
            message: "Successfully attached the AWS account to your environment"
        });
    } catch (e: any) {
        notification.error({
            message: "Can not attach the AWS account",
            description: e?.response?.data ? e.response.data.message : "We had trouble attaching the AWS account to your environemnt. Please contact our support team on support@microtica.com or write on our Discord community channel."
        });
    }
}

/**
 * Fetch AWS accounts info for stage and project
 */
export const fetchAwsAccountsInfo = async (envId: string, projectId: string): Promise<{ projectAwsAccounts: string[], stageAwsAccountId: string }> => {
    const [{ awsAccounts }, { awsAccountId }] = await Promise.all([
        getAwsAccounts(projectId),
        getStageAwsAccount(envId, projectId)
    ]);
    return {
        projectAwsAccounts: awsAccounts,
        stageAwsAccountId: awsAccountId!
    }
}

const getAwsAccounts = async (projectId: string) => {
    try {
        const { data: { awsAccounts } } = await getCloudService().getAwsAccounts(projectId);
        return {
            awsAccounts: awsAccounts.map(acc => acc.id)
        };
    } catch (error) {
        return { awsAccounts: [] };
    }
}

const getStageAwsAccount = async (envId: string, projectId: string) => {
    try {
        const { data: { awsAccountId } } = await getEnvironmentService().getStage(envId, projectId)
        return { awsAccountId };
    } catch (error) {
        return { awsAccountId: undefined };
    }
}
