import { GetStageDeploymentEventsResponseEvents } from "@microtica/ms-engine-sdk";
import { Avatar, Button, Col, Drawer, ModalFuncProps, Row, Skeleton, Space } from "antd";
import {
    ExpandAltOutlined,
    ShrinkOutlined,
    RocketOutlined,
    CloseOutlined,
    ThunderboltOutlined
} from "@ant-design/icons";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { getElasticSearchService } from "../backend";
import DeploymentStatusTag from "./DeploymentStatusTag";
import { EnvDeployment } from "../types";
import { mapAppDeployments } from "../utils/deployments";
import Logs from "./Logs";
import EnvironmentDeploymentResource from "./EnvironmentDeploymentResource";
import { ServiceDeployment } from "@microtica/ms-elasticsearch-sdk";

interface AppDeployDetailsDrawerProps {
    type: "build" | "deploy" | string;
    projectId: string;
    envId: string;
    deploymentId: string;
    open?: boolean;
    onClose?: () => void;
}

const AppDeployDetailsDrawer = ({
    type,
    projectId,
    envId,
    deploymentId,
    open,
    onClose
}: AppDeployDetailsDrawerProps) => {
    const [events, setEvents] = useState<{ timestamp: number; text: string }[]>([]);
    const [loading, setLoading] = useState(true);
    const [deploymentDetails, setDeploymentDetails] = useState<EnvDeployment>();
    const [eventsExpanded, setEventsExpanded] = useState(false);
    const [, setModal] = useState<{
        destroy: () => void;
        update: (configUpdate: ModalFuncProps) => void;
    }>();

    useEffect(() => {
        loadData();
        moment.updateLocale(moment.locale(), { invalidDate: "" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = async () => {
        try {
            const { data: { response: deployments } } = await getElasticSearchService().getDeploymentHistory(projectId);

            const appDeployments = deployments.filter(d => d.entityType === "service-deployment" && d.deploymentId === deploymentId);
            const appDeployment = mapAppDeployments(appDeployments as ServiceDeployment[], [])[0];
            const timestamp = moment(appDeployment.timestamp).valueOf();

            if (appDeployment.status.name === "FAILED") {
                setEvents(
                    appDeployment.status.errors.map((e: string) => ({
                        timestamp,
                        text: `\u001b[31m${e}\u001b[0m`
                    }))
                )
            } else {
                const target = appDeployment.resources[0];
                setEvents([
                    { timestamp, text: `\u001b[36mDeploying application\u001b[0m ${target.name}` },
                    { timestamp, text: `   -> Environment ID:   ${envId}` },
                    { timestamp, text: `   -> Cluster Name:     ${target.kubernetesName}` },
                    { timestamp, text: `   -> Application Name: ${target.name}` },
                    { timestamp, text: `   -> Namespace:        ${target.kubernetesNamespace}` },
                    { timestamp, text: `   -> Version:          ${target.version}` },
                    { timestamp, text: `` },
                    { timestamp, text: `\u001b[32mApplication deployed!\u001b[0m` },
                    { timestamp, text: `   -> Deployment ID:    ${appDeployment.id}` }
                ])
            }
            setDeploymentDetails(appDeployment);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    const handleClose = () => {
        onClose?.();
    }

    const Header = () => (
        !deploymentDetails ? <Skeleton /> :
            <div className="flex-justify-space-between" style={{ marginBottom: "48px" }}>
                <Row gutter={[24, 24]}>
                    <Col>
                        <Space direction="vertical">
                            <div style={{ fontSize: "14px", fontWeight: 600 }}>
                                Environment
                            </div>
                            <div>
                                {deploymentDetails.envId}
                            </div>
                        </Space>
                    </Col>
                    <Col>
                        <Space direction="vertical">
                            <div style={{ fontSize: "14px", fontWeight: 600 }}>
                                Application
                            </div>
                            <div>
                                {deploymentDetails.resources[0].name}
                            </div>
                        </Space>
                    </Col>
                    <Col>
                        <Space direction="vertical">
                            <div style={{ fontSize: "14px", fontWeight: 600 }}>
                                Cluster
                            </div>
                            <div>
                                {deploymentDetails.resources[0].kubernetesName}
                            </div>
                        </Space>
                    </Col>
                    <Col>
                        <Space direction="vertical">
                            <div style={{ fontSize: "14px", fontWeight: 600 }}>
                                Namespace
                            </div>
                            <div>
                                {deploymentDetails.resources[0].kubernetesNamespace}
                            </div>
                        </Space>
                    </Col>
                    <Col>
                        <Space direction="vertical">
                            <div style={{ fontSize: "14px", fontWeight: 600 }}>
                                Execution time
                            </div>
                            <div>
                                {
                                    moment(deploymentDetails.timestamp).fromNow()
                                }
                            </div>
                        </Space>
                    </Col>
                    <Col>
                        <Space direction="vertical">
                            <div style={{ fontSize: "14px", fontWeight: 600 }}>
                                Status
                            </div>
                            <div>
                                <DeploymentStatusTag status={deploymentDetails.status.name} />
                            </div>
                        </Space>
                    </Col>
                </Row>
            </div>
    )

    const TypeIcon = () => {
        if (type === "deploy") {
            return <Avatar style={{ backgroundColor: "#077BB5" }} icon={<RocketOutlined rotate={45} />} />
        } else {
            return <Avatar style={{ backgroundColor: "goldenrod", paddingTop: 1 }} icon={<ThunderboltOutlined />} />
        }
    }

    return (
        <Drawer
            title={
                <Space size="middle">
                    <TypeIcon />
                    <div>
                        <div>
                            Deployment
                        </div>
                        <div style={{ fontSize: "14px", fontWeight: 400 }}>
                            ID: {deploymentDetails?.id}
                        </div>
                    </div>
                </Space>
            }
            placement="bottom"
            height="100%"
            onClose={handleClose}
            open={open}
            destroyOnClose={true}
            closable={false}
            extra={
                <Button
                    icon={<CloseOutlined />}
                    onClick={handleClose}
                />
            }
        >
            {
                loading ?
                    <Skeleton /> :
                    <>
                        <Header />
                        <Row gutter={24}>
                            {/* Events */}
                            <Col span={eventsExpanded ? 24 : 18}>
                                <Logs
                                    title="Deployments logs"
                                    height="calc(100vh - 220px)"
                                    extra={
                                        deploymentDetails?.resources.length! > 0 &&
                                        <Button
                                            type="link"
                                            style={{ paddingRight: 0, color: "white" }}
                                            icon={eventsExpanded ? <ShrinkOutlined /> : <ExpandAltOutlined />}
                                            onClick={() => setEventsExpanded(!eventsExpanded)}
                                        >
                                            {eventsExpanded ? "Show updates" : "Expand"}
                                        </Button>
                                    }
                                    logs={events.map(e => {
                                        return `${moment(e.timestamp).format()} ${e.text}`;
                                    })}
                                />
                            </Col>

                            {/* Updated resources */}
                            {
                                !eventsExpanded &&
                                <Col span={6}>
                                    <div style={{ fontSize: "18px", fontWeight: 600, marginBottom: "16px" }}>
                                        Updates
                                    </div>
                                    {
                                        deploymentDetails?.resources
                                            .map(r => (
                                                <EnvironmentDeploymentResource
                                                    envId={envId}
                                                    deploymentType={deploymentDetails.type}
                                                    resource={r}
                                                    key={r.id}
                                                />
                                            ))
                                    }
                                </Col>
                            }
                        </Row>
                    </>
            }
        </Drawer>
    )
}

export default AppDeployDetailsDrawer;