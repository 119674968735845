import { Col, Menu, MenuProps, Row, Skeleton, Space } from "antd";
import { ExclamationCircleOutlined, SlidersOutlined, BranchesOutlined, GlobalOutlined, RiseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import { ComponentSchema, IAppDetails } from "../../types";
import { getElasticSearchService, getKubeService } from "../../backend";
import { ServiceDeploymentCommit } from "@microtica/ms-elasticsearch-sdk";
import { GetKubernetesStatusResponseTypeEnum } from "@microtica/ms-kube-sdk";

const AppSettingsRoot = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { projectId, clusterId, namespace, appName } = useParams() as {
        projectId: string;
        envId: string;
        componentId: string;
        clusterId: string;
        namespace: string;
        appName: string;
    };
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("general");
    const [app, setApp] = useState<IAppDetails>();
    const [menuItems, setMenuItems] = useState<MenuProps["items"]>();

    useEffect(() => {
        setActiveTab(location.pathname.split("/")[12] || "environment");
    }, [location.pathname]);

    useEffect(() => {
        const loadData = async () => {
            const [
                { data: deploymentStatus },
                { data: { microservicesStatus: servicesStatus, type: clusterType, associatedNamespace } },
                { data: { response: allDeployments } }
            ] = await Promise.all([
                getKubeService().getMicroserviceDeploymentStatus(
                    appName,
                    clusterId,
                    namespace,
                    projectId
                ),
                getKubeService().getKubernetesStatus(clusterId, projectId),
                getElasticSearchService().getServiceDeploymentHistory(
                    projectId,
                    appName,
                    clusterId
                )
            ]);

            const serviceDeployments = allDeployments.filter(d => d.name === appName);
            const lastDeployment = serviceDeployments[0];
            const status = servicesStatus.find(s => s.name === appName)!;
            const domain = deploymentStatus.configurations.find(c => c.key === "MIC_DOMAIN_NAME")?.value || "n/a";
            const lastDeploymentKubeConfig = JSON.parse(lastDeployment?.kubeConfig || "[{}]");

            const appDetails = {
                name: appName,
                description: lastDeployment?.description,
                schema: JSON.parse(lastDeployment?.schemaJSON || "{}") as ComponentSchema,
                image: lastDeployment?.commit?.version,
                namespace: status.namespace,
                configuration: lastDeployment.configurations,
                pipelineId: (lastDeployment?.commit as ServiceDeploymentCommit & { pipelineId: string })?.pipelineId,
                clusterType: clusterType,
                associatedNamespace,
                domain,
                isDomainWorking: clusterType === GetKubernetesStatusResponseTypeEnum.Shared || !!associatedNamespace ? true : checkIfDomainIsWorking(lastDeploymentKubeConfig),
                lastDeploymentIncludesIngressSetup: !!lastDeploymentKubeConfig.find((r: any) => r.kind === "Ingress" && r.spec?.ingressClassName === "nginx"),
                monitoring: {
                    initialCpu: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_CPU")!.value!),
                    cpuLimit: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MAX_CPU")!.value!),
                    cpuUsage: deploymentStatus.usage?.averageCpu!,
                    initialMemory: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MEMORY")!.value!),
                    memoryLimit: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MAX_MEMORY")!.value!),
                    memoryUsage: deploymentStatus.usage?.averageMemory!,
                    minReplicas: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MIN_REPLICAS")!.value!),
                    maxReplicas: parseInt(deploymentStatus.configurations.find(c => c.key === "MIC_AS_MAX_REPLICAS")!.value!),
                    runningPods: status.podStatus.runningPods,
                    failedPods: status.podStatus.failedPods
                }
            };

            const items: MenuProps["items"] = [];

            items.push({
                key: "environment",
                label: <Space>
                    <SlidersOutlined />
                    Environment Variables
                </Space>
            });

            if (appDetails.pipelineId) {
                items.push({
                    key: "pipeline",
                    label: <Space>
                        <BranchesOutlined />
                        Pipeline
                    </Space>
                });
            }

            items.push({
                key: "scaling",
                label: <Space>
                    <RiseOutlined />
                    Scaling
                </Space>
            });

            items.push({
                key: "domain",
                label: <Space>
                    <GlobalOutlined />
                    Domain
                </Space>,
                disabled: appDetails.clusterType === "shared" || !!appDetails.associatedNamespace,
                title: appDetails.clusterType === "shared" || !!appDetails.associatedNamespace ?
                    `Domain configuration is not enabled for apps deployed on a ${appDetails.clusterType === "shared" ? "shared cluster" : "cluster with access limitation"}` :
                    undefined
            });

            items.push({
                key: "danger-zone",
                label: <Space>
                    <ExclamationCircleOutlined />
                    Danger Zone
                </Space>,
                danger: true
            });

            setMenuItems(items);
            setApp(appDetails as any);
            setLoading(false);
        }

        loadData();
    }, []);


    const checkIfDomainIsWorking = (kubeConfig: any[]) => {
        // ingress-tls is used as a default secretName for shared clusters; 
        // ingress-tls is part of the certificate resource in a personal cluster => domain is not yet configured
        const hasCertificate = !!kubeConfig.find(r => r.kind === "Certificate" && r.apiVersion === "cert-manager.io/v1" && r.spec?.issuerRef?.kind === "ClusterIssuer" && r.spec?.secretName !== "ingress-tls");
        return hasCertificate;
    };

    return (
        <Row gutter={24}>
            <Col span={6}>
                {
                    loading ? <Skeleton /> :
                        <Menu
                            mode="inline"
                            className="ant-card-bordered"
                            selectedKeys={[activeTab]}
                            onSelect={(item) => {
                                navigate(item.key);
                                setActiveTab(item.key);
                            }}
                            items={menuItems}
                        />

                }
            </Col>
            <Col span={18}>
                {
                    loading ? <Skeleton /> :
                        <Outlet context={{ app }} />
                }
            </Col>
        </Row >
    );

}

export default AppSettingsRoot;