import Title from "antd/lib/typography/Title";
import { ReactNode } from "react";

interface HeadingProps {
    title: ReactNode;
    description?: ReactNode;
    align?: "left" | "center" | "right";
    level?: 1 | 2 | 3 | 4 | 5;
}

const Heading = ({
    title,
    description,
    align,
    level
}: HeadingProps) => {
    return (
        <div style={{ margin: "20px 0", textAlign: align }}>
            <Title level={level}>{title}</Title>
            {
                description &&
                <div style={{ color: "GrayText", fontSize: "16px" }}>
                    {description}
                </div>
            }
        </div>
    )
}

export default Heading;