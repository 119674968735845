import { GithubOutlined, GitlabOutlined, PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { RepositoriesResponseRepositories, RepositoryPermissionsResponse } from "@microtica/ms-ap-sdk";
import { Alert, Button, Card, Col, Divider, Form, Input, InputNumber, notification, Row, Select, Space } from "antd";
import { RequiredMark } from "antd/lib/form/Form";
import { Fragment, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import BitbicketLogoWhite from "../../assets/bitbucket-icon-white.svg";
import AwsLogoWhite from "../../assets/aws-logo-white.svg";
import { getPipelinesService } from "../../backend";
import { trackGitAccountInit } from "../../backend/tracking/user-settings";
import { useAuth } from "../../contexts/Auth";
import { currentProjectState } from "../../recoil/project";
import { currentTemplateStepState, templateState } from "../../recoil/template";
import { useCardClassName } from "../../contexts/Helpers/helpers";
import ConnectCodeCommitModal from "../modals/ConnectCodeCommitModal";
import { parseTemplatesRepositoryUrl } from "../../utils/parse-templates-repository-url";
import TemplateAvatar from "../TemplateAvatar";
import { FRAMEWORKS } from "../../enums/enums";
import GitIcon from "../GitIcon";
import { trackTemplateGitRepoCreate, trackTemplateGitRepoImport } from "../../backend/tracking/templates";
import { matchFramework } from "../../utils/match-framework";
import ExplanationButton from "../explanations/ExplanationButton";
import GitRepositoryExplanation from "../explanations/GitRepositoryExplanation";
import { GitStepCreateRepoData, GitStepSelectRepoData } from "../../pages/template/CreateFromTemplate";
import NotFoundRepo from "../explanations/NotFoundRepo";

interface GitTemplateModuleProps {
    mandatoryFilesRepositoryUrl: string;
    initialCreateRepoValues?: GitStepCreateRepoData;
    initialSelectRepoValues?: GitStepSelectRepoData;
    moduleType: "select" | "create";
    setModuleType: (type: "select" | "create") => void;
    showCreateRepoOptions: boolean;
    templateUrl: string;
    disabled: boolean;
    onSelect?: (
        formValues: GitStepCreateRepoData | GitStepSelectRepoData,
        additionalData: {
            fullName: string;
            repositoryUrl: string;
        }) => void;
}
interface IGitAccount { id: string; name: string; provider: string; }

const GitTemplateModule = ({
    mandatoryFilesRepositoryUrl,
    initialCreateRepoValues,
    initialSelectRepoValues,
    moduleType,
    setModuleType,
    showCreateRepoOptions,
    templateUrl,
    disabled,
    onSelect
}: GitTemplateModuleProps) => {
    const { isLoggedIn } = useAuth();
    const currentProject = useRecoilValue(currentProjectState);
    const [createGitForm] = Form.useForm();
    const [selectGitForm] = Form.useForm();

    const [requiredMark] = useState<RequiredMark>('optional');
    const { connectGitHub, connectBitbucket, connectGitLab } = useAuth();
    const [gitAccounts, setGitAccounts] = useState<IGitAccount[]>([]);
    const [selectedGitAccount, setSelectedGitAccount] = useState<{ id: string; name: string; provider: string; }>();
    const [loadingAccounts, setLoadingAccounts] = useState(true);
    const [loadingRepos, setLoadingRepos] = useState(false);
    const [loadingBranches, setLoadingBranches] = useState(false);
    const [loadingCreateRepo, setLoadingCreateRepo] = useState(false);
    const [createMandatoryRepositoryFilesLoading, setCreateMandatoryRepositoryFilesLoading] = useState(false);
    const [mode, setMode] = useState<"new" | "existing">("new");
    const [repositories, setRepositories] = useState<RepositoriesResponseRepositories[]>([]);
    const [branches, setBranches] = useState<string[]>([]);
    const [gitUsernames, setGitUsernames] = useState<string[]>([]);
    const [currentTemplateState, setCurrentTemplateState] = useRecoilState(templateState);
    const currentTemplateStep = useRecoilValue(currentTemplateStepState);
    const [showConnectCodeCommitModal, setShowConnectCodeCommitModal] = useState(false);
    const [displayBuildScriptAndDir, setDisplayBuildScriptAndDir] = useState(false);
    const [repoPermissions, setRepoPermissions] = useState<RepositoryPermissionsResponse>({
        permission: "admin",
        allowWebhooks: true
    });

    const cardClassName = useCardClassName(
        currentTemplateState["git"] === "disabled" || disabled,
        [currentTemplateState, currentTemplateStep]
    );

    const loadGitAccounts = async () => {
        const initialValues = moduleType === "select" ? initialSelectRepoValues : initialCreateRepoValues;

        try {
            setLoadingAccounts(true);
            const { data: { gitAccounts } } = await getPipelinesService().listGitAccounts(currentProject!.id);

            setGitAccounts(gitAccounts.map(acc => ({
                id: acc.gitAccountId,
                name: acc.accountName,
                provider: acc.type
            })));
            if (gitAccounts.length) {
                if (initialValues?.gitAccount) {
                    handleOnSelectAccount({ id: initialValues.gitAccount }, false)
                } else {
                    handleOnSelectAccount({
                        id: gitAccounts[0].gitAccountId,
                        name: gitAccounts[0].accountName,
                        provider: gitAccounts[0].type
                    });
                }
            }
            setMode(gitAccounts.length > 0 ? "existing" : "new");
        } catch (error) {

        } finally {
            setLoadingAccounts(false);
        }
    }

    useEffect(() => {
        if (isLoggedIn) {
            loadGitAccounts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    const handleReconnectGitAccount = async (provider: string) => {
        if (provider === "bitbucket") {
            await handleConnectBitbucketAccount();
        } else if (provider === "github") {
            await handleConnectGitHubAccount();
        } else if (provider === "gitlab") {
            await handleConnectGitLabAccount();
        }
    }

    const handleOnSelectRepository = async (id: string) => {
        const gitAccountId = selectGitForm.getFieldValue("gitAccount");
        const repo = repositories.find(repo => repo.fullName === id);
        setLoadingBranches(true);
        try {
            const [{ data: { branches } }, { data: permission }] = await Promise.all([
                getPipelinesService().listGitAccountRepositoryBranches(
                    currentProject!.id,
                    gitAccountId,
                    repo!.url
                ),
                getPipelinesService().getRepositoryPermissions(
                    currentProject!.id,
                    gitAccountId,
                    repo!.url
                )
            ]);
            setRepoPermissions(permission);
            setBranches(branches);
            // Select the 'main' or 'master' branch by default, if those branches does not exist then select the first branch
            const defaultBranch = branches.includes("main") ? "main" : branches.includes("master") ? "master" : branches[0]
            selectGitForm.setFieldsValue({ branch: defaultBranch });
        } catch (error) {

        } finally {
            setLoadingBranches(false);
        }
    }

    useEffect(() => {
        if (moduleType === "create") {
            createGitForm.setFieldsValue({
                gitAccount: selectGitForm.getFieldValue("gitAccount"),
                selectedUsername: selectGitForm.getFieldValue("gitRepository")?.split("/").slice(0, -1).join("/")
            });
        } else {
            setDisplayBuildScriptAndDir(!!initialSelectRepoValues?.buildScript && !!initialSelectRepoValues.buildDir);
            selectGitForm.setFieldsValue({
                gitAccount: createGitForm.getFieldValue("gitAccount")
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moduleType]);

    const handleOnSelectAccount = async (account: { id: string, name?: string, provider?: string }, resetValues = true) => {
        const form = moduleType === "select" ? selectGitForm : createGitForm;
        if (account.id === "new") {
            form.setFieldsValue({ gitAccount: selectedGitAccount!.id })
            return setMode("new");
        }
        form.setFieldsValue({ gitAccount: account.id });

        if (!account.provider || !account.name) {
            account = gitAccounts.find(acc => acc.id === account.id) || { id: account.id };
        }
        setSelectedGitAccount(account as IGitAccount);
        setLoadingRepos(true);

        if (resetValues) {
            form.setFieldsValue({
                gitRepository: undefined,
                branch: undefined
            });
        } else {
            const initialValues = moduleType === "select" ? initialSelectRepoValues : initialCreateRepoValues;
            // create git repo => next => ... => back => select git repo
            if (initialValues?.branch) {
                setBranches([initialValues.branch]);
                form.setFieldsValue({ branch: initialValues.branch });
            }
        }
        setRepoPermissions({ permission: "admin", allowWebhooks: true });
        try {
            if (moduleType === "create") {
                form.setFieldsValue({ selectedUsername: undefined });
            }
            const { data: { repositories } } = await getPipelinesService().listGitAccountRepositories(currentProject!.id, account.id);

            const uniqueUsernames = [...new Set(repositories.map(repo => repo.fullName.split("/")[0]))] as string[];

            setGitUsernames(uniqueUsernames);
            setRepositories(repositories);
            if (account.provider !== "codecommit" && moduleType === "create") {
                const accountName = gitAccounts.find(acc => acc.id === account.id)?.name;
                const defaultUsername = !uniqueUsernames.length || uniqueUsernames.includes(accountName!) ? accountName : uniqueUsernames[0];
                const newUsername = initialCreateRepoValues?.selectedUsername && uniqueUsernames.includes(initialCreateRepoValues.selectedUsername) ? initialCreateRepoValues.selectedUsername : defaultUsername
                form.setFieldsValue({ selectedUsername: newUsername })
            }
        } catch (error) {
            // TODO: the backend must provide exact error code that points to authentication problem
            notification.error({
                message: "Authentication failed for your Git account",
                description: <>
                    Please <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => handleReconnectGitAccount(account.provider!)}
                    >
                        Reconnect
                    </Button> your Git account to proceed.
                </>
            });
        } finally {
            setLoadingRepos(false);
        }
    }

    const handleConnectGitAccount = async (code: string, type: "github" | "bitbucket" | "gitlab") => {
        try {
            const { data: { gitAccountId } } = await getPipelinesService().initiateOAuth(
                currentProject!.id,
                code,
                type
            );

            await loadGitAccounts();

            setMode("existing");
            handleOnSelectAccount({ id: gitAccountId, name: "", provider: type });
        } catch (error: any) {
            notification.error({
                message: "Error connecting Git account",
                description: error.response.data.message
            });
        }
    }

    const handleCreateRepo = async (values: GitStepCreateRepoData) => {
        try {
            const repo = repositories.find(r => r.fullName.startsWith(values.selectedUsername || ""))!;
            const repoDomain = repo ?
                new URL(repo.url).origin :
                constructRepositoryUrlOrigin(selectedGitAccount?.provider!, selectedGitAccount?.name);

            setLoadingCreateRepo(true);
            const { origin, username, templateRepo, templateName } = parseTemplatesRepositoryUrl(templateUrl);
            trackTemplateGitRepoCreate(templateName.join("/"));

            const repoName = values.repoName!;
            const gitSlug = values.selectedUsername ? `${values.selectedUsername}/${repoName}` : repoName;
            await getPipelinesService().mirrorTemplateRepository(
                currentProject!.id,
                values.gitAccount!,
                {
                    gitSlug,
                    templateUrl: `${origin}/${username}/${templateRepo}`,
                    templateName: templateName.join("/")
                }
            );
            handleSaveGitStep();

            onSelect?.(values, // gitAccount, repoName and selectedUsername
                {
                    fullName: gitSlug,
                    repositoryUrl: `${repoDomain}/${gitSlug}`
                });
            notification.success({
                message: "Git repository created"
            });
        } catch (error: any) {
            notification.error({
                message: "Cannot create Git repository",
                description: error?.response?.data?.message
            });
        } finally {
            setLoadingCreateRepo(false);
        }
    }

    const handleChooseRepo = async (values: GitStepSelectRepoData) => {
        const repo = repositories.find(repo => repo.fullName === values.gitRepository)!;

        // create mandatory files only if .microtica/microtica.yaml does not exist
        const { microticaYamlExists } = await fetchRepositoryFile(
            currentProject.id,
            values.gitAccount!,
            values.branch!,
            repo.url,
            ".microtica/microtica.yaml"
        )
            .then(res => ({ microticaYamlExists: true }))
            .catch(err => ({ microticaYamlExists: false }));
        let done = true;
        if (!microticaYamlExists) {
            await createMandatoryRepositoryFiles({
                gitAccountId: values.gitAccount!,
                repositoryUrl: repo.url,
                branch: values.branch!
            }).then(res => done = res.done);
        }

        if (done) {
            const { templateName } = parseTemplatesRepositoryUrl(templateUrl);
            trackTemplateGitRepoImport(templateName.join("/"));

            handleSaveGitStep();

            onSelect?.(values, {
                fullName: values.gitRepository!,
                repositoryUrl: repo.url
            });
        }
    }

    const handleConnectGitHubAccount = async () => {
        trackGitAccountInit("github");

        const { code, type } = await connectGitHub();
        await handleConnectGitAccount(code, type);
    }

    const handleConnectGitLabAccount = async () => {
        trackGitAccountInit("gitlab");

        const { code, type } = await connectGitLab();
        await handleConnectGitAccount(code, type);
    }

    const handleConnectBitbucketAccount = async () => {
        trackGitAccountInit("bitbucket");

        const { code, type } = await connectBitbucket();
        await handleConnectGitAccount(code, type);
    }

    const handleConnectCodeCommitAccount = async (accountId: string) => {
        await loadGitAccounts();

        setMode("existing");
        setShowConnectCodeCommitModal(false);
        handleOnSelectAccount({ id: accountId, name: "", provider: "codecommit" });
    }

    const handleShowCodeCommitModal = () => {
        trackGitAccountInit("codecommit");
        setShowConnectCodeCommitModal(true);
    }

    const handleSaveGitStep = () => {
        setCurrentTemplateState(currentTemplateState => ({
            ...currentTemplateState,
            git: "saved",
            config: "editing"
        }));
    }

    const fetchRepositoryFile = async (projectId: string, gitAccountId: string, gitBranch: string, gitRepositoryUrl: string, filename: string) => {
        return getPipelinesService().getGitRepositoryFileContent(
            projectId,
            gitAccountId,
            gitBranch,
            gitRepositoryUrl,
            filename
        );
    }

    const createMandatoryRepositoryFiles = async ({ gitAccountId, repositoryUrl, branch }: { gitAccountId: string, repositoryUrl: string, branch: string }) => {
        setCreateMandatoryRepositoryFilesLoading(true);
        const { origin, username, templateRepo, templateName } = parseTemplatesRepositoryUrl(mandatoryFilesRepositoryUrl);

        try {
            await getPipelinesService().createMandatoryRepositoryFiles(
                currentProject.id,
                gitAccountId,
                {
                    targetRepositoryUrl: repositoryUrl,
                    targetBranch: branch || "master",
                    sourceRepositoryUrl: `${origin}/${username}/${templateRepo}`,
                    sourceSubPath: templateName.join("/")
                }
            );
            notification.success({
                message: "File created",
                description: "File .microtica/microtica.yaml was added to your repository"
            });
            setCreateMandatoryRepositoryFilesLoading(false);
            return { done: true };
        } catch (err: any) {
            notification.error({
                message: "Can not create file",
                description: err.response.data.message
            });
            setCreateMandatoryRepositoryFilesLoading(false);
            return { done: false };
        }
    }

    const handleFrameworkChange = (frameworkName: string) => {
        const selectedFramework = matchFramework(frameworkName);
        setDisplayBuildScriptAndDir(!!selectedFramework.buildScript && !!selectedFramework.buildDir);
        // set the default values dinamically based on the selected framework
        selectGitForm.setFieldsValue({
            framework: selectedFramework.name,
            port: selectedFramework.port,
            frameworkType: selectedFramework.type,
            buildScript: selectedFramework.buildScript,
            buildDir: selectedFramework.buildDir
        });
    }

    /**
     * Returns the URL origin for specific Git provider
     * User in cases when the selected Git account does not have any repositories (we usually extract the URL origin from the first repo url)
     */
    const constructRepositoryUrlOrigin = (provider: string, name?: string) => {
        switch (provider) {
            case "github":
                return "https://github.com";
            case "gitlab":
                return "https://gitlab.com";
            case "bitbucket":
                return "https://bitbucket.org";
            case "codecommit":
                const region = name?.split("-").slice(1).join("-");
                return `https://git-codecommit.${region}.amazonaws.com`;
        }
    }

    const ConnectNewGitAccount = () => (
        <Card type="inner">
            <div style={{ fontWeight: 500 }}>
                Choose the Git provider where you want to create a new repository or use an existing one:
            </div>
            <br />
            <Space direction="horizontal" wrap size="middle" id="deployment-cards-git-buttons">
                <Button className="ant-btn-github" onClick={handleConnectGitHubAccount} icon={<GithubOutlined />}>
                    GitHub
                </Button>
                <Button
                    className="ant-btn-bitbucket"
                    onClick={handleConnectBitbucketAccount}
                    icon={
                        <img
                            src={BitbicketLogoWhite}
                            alt="bitbucket"
                            style={{ width: "14px", marginRight: "10px", marginTop: "-1px" }}
                        />
                    }
                >
                    Bitbucket
                </Button>
                <Button className="ant-btn-gitlab" onClick={handleConnectGitLabAccount} icon={<GitlabOutlined />}>
                    GitLab
                </Button>
                <Button
                    className="ant-btn-codecommit"
                    onClick={handleShowCodeCommitModal}
                    icon={
                        <img
                            src={AwsLogoWhite}
                            alt="codecommit"
                            style={{ width: "18px", marginRight: "10px", marginTop: "-2px" }}
                        />
                    }
                >
                    CodeCommit
                </Button>
            </Space>
            <br />
            {
                gitAccounts.length > 0 ?
                    <>
                        <br />
                        <Button type="link" style={{ padding: 0 }} onClick={() => setMode("existing")}>
                            Choose existing Git account
                        </Button>
                    </> :
                    undefined
            }
        </Card>
    );

    const GitCardHeader = () => (
        <Card.Meta
            title={
                <>
                    <span>
                        {
                            moduleType === "select" ?
                                "Select Git Repository" :
                                "Create Git Repository"
                        }
                    </span>
                    <ExplanationButton
                        content={<GitRepositoryExplanation />}
                    />
                </>
            }
            description={
                moduleType === "select" ?
                    "Select your existing app and deploy it with this template. Every Git push will be deployed automatically." :
                    "We will create a new repository on your account and every Git push will be deployed automatically."
            }
        />
    );

    const SelectGitRepositoryForm = () => (
        <Form
            form={selectGitForm}
            requiredMark={requiredMark}
            layout="vertical"
            initialValues={initialSelectRepoValues}
            onFinish={(values) => {
                return handleChooseRepo(values);
            }}
        >
            <Row gutter={24}>
                <Col xs={24} md={8}>
                    <Form.Item
                        label="Git account"
                        name="gitAccount"
                        required
                        rules={[
                            { required: true, message: 'Please select Git account' }
                        ]}
                    >
                        <Select
                            style={{ width: "100%" }}
                            loading={loadingAccounts}
                            placeholder="Select Git account"
                            onChange={(id) => handleOnSelectAccount({ id })
                            }
                        >
                            {
                                gitAccounts.map(acc => (
                                    <Select.Option value={acc.id} key={acc.id}>
                                        <Space>
                                            <GitIcon provider={acc.provider as "github" | "gitlab" | "codecommit" | "bitbucket"} />{acc.name}
                                        </Space>
                                    </Select.Option>
                                ))
                            }
                            <Select.Option value="new">
                                <Space>
                                    <PlusCircleOutlined /> Connect Git account
                                </Space>
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={10}>
                    <Form.Item
                        name="gitRepository"
                        label="Repository"
                        required
                        rules={[
                            { required: true, message: 'Please select Git repository' }
                        ]}
                    >
                        <Select
                            style={{ width: "100%" }}
                            disabled={!selectedGitAccount || loadingRepos}
                            loading={loadingRepos}
                            placeholder="Select Git repository"
                            onChange={handleOnSelectRepository}
                            showSearch={true}
                            notFoundContent={
                                <NotFoundRepo
                                    projectId={currentProject.id}
                                    provider={selectedGitAccount?.provider}
                                    accountName={selectedGitAccount?.name!}
                                />
                            }
                        >
                            {
                                repositories.map(repo => (
                                    <Select.Option value={repo.fullName} key={repo.id}>
                                        {repo.fullName}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item
                        name="branch"
                        label="Branch"
                        required
                        rules={[
                            { required: true, message: 'Please select repository branch' }
                        ]}
                    >
                        <Select
                            style={{ width: "100%" }}
                            disabled={!selectGitForm.getFieldValue("gitRepository") || loadingBranches}
                            loading={loadingBranches}
                            placeholder="Select Branch"
                            showSearch={true}
                            notFoundContent="No branches"
                        >
                            {
                                branches.map(branch => (
                                    <Select.Option value={branch} key={branch}>
                                        {branch}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Divider style={{ fontSize: 14 }}>Build spec</Divider>
            <Row gutter={24}>
                <Col xs={24} md={7}>
                    <Form.Item
                        name="framework"
                        label="Framework"
                        required
                        rules={[
                            { required: true, message: "Select framework" }
                        ]}
                    >
                        <Select
                            onChange={handleFrameworkChange}
                        >
                            {FRAMEWORKS.map(type => (
                                <Select.Option value={type.name} id={type.name} key={type.name}>
                                    <Space>
                                        <TemplateAvatar size="xs" name={type.name} />{type.name}
                                    </Space>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="frameworkType"
                        label="Framework type"
                        hidden={true}
                    />
                </Col>
                {
                    displayBuildScriptAndDir ?
                        <Col xs={24} md={6}>
                            <Form.Item
                                name="buildScript"
                                label="Build script"
                                required
                                rules={[
                                    { required: true, message: "Enter build script for the app" },
                                    { pattern: new RegExp("^((?! ).)*$"), message: 'Build script should not contain space' }
                                ]}
                                tooltip={
                                    "The script your application framework provides for compiling your code. Here you just provide the script name (eg. 'build'), NOT the full command (eg. 'npm run build')."
                                }
                            >
                                <Input />
                            </Form.Item>
                        </Col> : null
                }
                {
                    displayBuildScriptAndDir ?
                        <Col xs={24} md={6}>
                            <Form.Item
                                name="buildDir"
                                label="Output directory"
                                required
                                rules={[
                                    { required: true, message: "Enter relative path to the build directory" },
                                    { pattern: new RegExp("^((?! ).)*$"), message: 'Build dir should not contain space' }
                                ]}
                                tooltip={
                                    "The directory in which your compiled application code will be located"
                                }
                            >
                                <Input />
                            </Form.Item>
                        </Col> : null
                }
                <Col xs={24} md={5}>
                    <Form.Item
                        name="port"
                        label="Port"
                        required
                        rules={[
                            { required: true, message: "Enter app's port" }
                        ]}
                        tooltip={
                            "The port on which your application is listening for requests"
                        }
                    >
                        <InputNumber min={80} max={65555} />
                    </Form.Item>
                </Col>


            </Row>
            {/* Mandatory files message */}
            <small className="gray-text">
                We are going to create a <i>.microtica</i> directory in your repository which contains necessary files for the CI/CD pipeline. You can view the directory and its contents  <a target="_blank" rel="noreferrer" href={mandatoryFilesRepositoryUrl}>here</a>.
            </small>
            <br />
            {
                !repoPermissions?.allowWebhooks &&
                <Alert
                    type="error"
                    message={
                        <>
                            Your account <b>{selectedGitAccount?.name}</b> doesn't have sufficient rights on this repository to create a webhook for automated deployments.
                            Your current permission is <b>{repoPermissions.permission}</b> but <b>owner/admin/maintainer</b> is required.
                        </>
                    }
                />
            }
            <br />
            <div className="flex-justify-space-between" style={!showCreateRepoOptions ? { float: "right" } : {}}>
                {
                    showCreateRepoOptions ?
                        <Button type="link" className="no-padding" onClick={() => {
                            setModuleType("create");
                        }}>
                            Create new repository
                        </Button>
                        : <></>
                }
                <Button
                    htmlType="submit"
                    loading={createMandatoryRepositoryFilesLoading}
                    type="primary"
                    disabled={currentTemplateState["git"] === "disabled" || !repoPermissions?.allowWebhooks || disabled || loadingAccounts || loadingRepos || loadingBranches}
                >
                    Next
                </Button>
            </div>
        </Form >
    );

    const CreateGitRepositoryForm = () => (
        <Form
            form={createGitForm}
            requiredMark={requiredMark}
            layout="vertical"
            initialValues={initialCreateRepoValues}
            onFinish={(values) => {
                return handleCreateRepo(values);
            }}
        >
            <Row gutter={24}>
                <Col xs={24} md={8}>
                    <Form.Item
                        label="Git account"
                        name="gitAccount"
                        required
                        rules={[
                            { required: true, message: 'Please select Git account' }
                        ]}
                    >
                        <Select
                            style={{ width: "100%" }}
                            loading={loadingAccounts}
                            placeholder="Select Git account"
                            onChange={(id) => handleOnSelectAccount({ id })
                            }
                        >
                            {
                                gitAccounts.map(acc => (
                                    <Select.Option value={acc.id} key={acc.id}>
                                        <Space>
                                            <GitIcon provider={acc.provider as "github" | "gitlab" | "codecommit" | "bitbucket"} />{acc.name}
                                        </Space>
                                    </Select.Option>
                                ))
                            }
                            <Select.Option value="new">
                                <Space>
                                    <PlusCircleOutlined /> Connect Git account
                                </Space>
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={16}>
                    <Form.Item
                        name="repoName"
                        label="New repository name"
                        required
                        rules={[
                            { required: true, message: 'Please enter repository name' },
                            { pattern: new RegExp("^((?!/).)*$"), message: 'Repository name should not contain slash' },
                            ({ getFieldValue }) => ({
                                validator() {
                                    const selectedUsernameValue = getFieldValue("selectedUsername");
                                    if (!selectedUsernameValue) {
                                        return Promise.reject("Please select username");
                                    } else {
                                        return Promise.resolve();
                                    }
                                }
                            })
                        ]}
                    >
                        <Input
                            addonBefore={
                                selectedGitAccount?.provider === "codecommit" ?
                                    undefined :
                                    <Form.Item
                                        name="selectedUsername"
                                        required
                                        className="no-margin"
                                        style={{ height: 30 }}
                                    >
                                        <Select
                                            style={{ paddingLeft: "11px" }}
                                            className="select-after text-left"
                                            placeholder="username"
                                            loading={loadingRepos}
                                            disabled={!selectedGitAccount || loadingRepos || disabled}
                                            suffixIcon={
                                                loadingRepos ? <LoadingOutlined /> : <span>/</span>
                                            }
                                        >
                                            {
                                                gitUsernames.map(username => (
                                                    <Select.Option value={username} key={username}>{username}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                            }
                            placeholder="my-repo"
                            className="combined-input-and-select"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <br />
            <div className="flex-justify-space-between" style={!showCreateRepoOptions ? { float: "right" } : {}}>
                {
                    showCreateRepoOptions ?
                        <Button type="link" className="no-padding" onClick={() => {
                            setModuleType("select");
                        }}>
                            Select an existing repository
                        </Button>
                        : <></>
                }
                <Button
                    htmlType="submit"
                    loading={loadingCreateRepo}
                    type="primary"
                    disabled={currentTemplateState["git"] === "disabled" || disabled || loadingAccounts || loadingRepos}
                >
                    Next
                </Button>
            </div>
        </Form>
    );

    return (
        <Card bordered key="destination" className={cardClassName}>
            <GitCardHeader />
            {
                mode === "existing" ?
                    <Fragment>
                        <Divider />
                        {
                            moduleType === "select" ?
                                <SelectGitRepositoryForm /> :
                                <CreateGitRepositoryForm />
                        }
                    </Fragment> :
                    <Fragment>
                        <br />
                        <ConnectNewGitAccount />
                    </Fragment>
            }
            {
                showConnectCodeCommitModal &&
                <ConnectCodeCommitModal
                    visible={showConnectCodeCommitModal}
                    onCancel={() => setShowConnectCodeCommitModal(false)}
                    onOk={handleConnectCodeCommitAccount}
                />
            }
        </Card>
    );
}

export default GitTemplateModule;