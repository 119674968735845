import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Divider, Form, notification, Row, Skeleton, Tag } from "antd";
import { getKubeService } from "../../backend";
import PageContentWrapper from "../../components/PageContentWrapper";
import { ComponentSchemaInputProperties } from "../../types";
import { DeployMicroserviceRequest, GetMicroserviceVersionsResponseVersionsTags, ListKubernetesResponseKuberneteses } from "@microtica/ms-kube-sdk";
import DeployApplicationModalGeneralSection from "../../components/modals/DeployApplicationModalGeneralSection";
import { RequiredMark } from "antd/lib/form/Form";
import ComponentConfigFormItems from "../../components/ComponentConfigFormItems";
import TemplateAvatar from "../../components/TemplateAvatar";
import { NAMESPACE_ORDER } from "../../enums/enums";

const EnvironmentDeployApp = () => {
    const { projectId, envId, componentId, clusterId, templateId } = useParams() as {
        projectId: string;
        envId: string;
        componentId: string;
        clusterId: string;
        templateId: string;
    };
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [serviceVersions, setServiceVersions] = useState<GetMicroserviceVersionsResponseVersionsTags[]>([]);
    const [serviceVersion, setServiceVersion] = useState<GetMicroserviceVersionsResponseVersionsTags>();
    const [inputProps, setInputProps] = useState<ComponentSchemaInputProperties>({});
    const [required, setRequired] = useState<string[]>([]);
    const [loadingSchema, setLoadingSchema] = useState<boolean>(true);
    const [isFetchingMoreData, setIsFetchingMoreData] = useState(false);
    const [kubernetesNamespaces, setKubernetesNamespaces] = useState<(ListKubernetesResponseKuberneteses & { namespaces: string[] })[]>([]);
    const [namespaceDisabled, setNamespaceDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [requiredMark] = useState<RequiredMark>('optional');
    const [references, setReferences] = useState<string[]>([]);
    const [deploying, setDeploying] = useState(false);
    const [serviceInfo, setServiceInfo] = useState<any>();

    useEffect(() => {
        if (serviceVersion) {
            fetchSchema(serviceVersion.name!);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceVersion]);

    useEffect(() => {
        if (templateId) {
            loadData();
            fetchServiceInfo(templateId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateId]);

    const loadData = async () => {
        setLoading(true);
        await Promise.all([
            fetchMicroserviceVersions(),
            fetchClusters()
        ]);
        setLoading(false);
    }

    const fetchServiceInfo = async (name: string) => {
        const { data } = await getKubeService().getMicroservice(name, projectId!);
        setServiceInfo(data);
    }

    const fetchSchema = async (version: string) => {
        setLoadingSchema(true);
        const { data: { schema } } = await getKubeService().getMicroservice(templateId!, projectId!, version);
        if (!schema) {
            setRequired([]);
            setInputProps({});
            setLoadingSchema(false);
            return;
        }
        if (schema.properties.inputs.required?.length) {
            setRequired(schema.properties.inputs.required)
        }
        if (schema.properties.inputs.properties) {
            setInputProps(schema.properties.inputs.properties as ComponentSchemaInputProperties)
        }
        setLoadingSchema(false);
    }

    const fetchMicroserviceVersions = async () => {
        try {
            const { data: { versions } } = await getKubeService().getMicroserviceVersions(templateId!, projectId!, String(0), String(10), undefined);
            const { tags } = versions!;

            const fetchedVersions = tags
                .sort((a, b) => b.date! - a.date!)
                .sort((a) => a.name === "latest" ? -1 : 0);

            const allVersions = serviceVersions.concat(fetchedVersions);
            setServiceVersions(allVersions);
            setServiceVersion(allVersions.length ? allVersions[0] : undefined);

            // If the number of returned tags is less than the pagination limit (no more data to fetch)
            // set offset to -1 (prevents from making any more requests)
            fetchedVersions.length === pagination.limit ?
                setPagination({ ...pagination, offset: pagination.offset + pagination.limit }) :
                setPagination({ ...pagination, offset: -1 });

            // setTagCursor(lastElementCursor);
        } catch (error) {
            // sometimes Service Temporarily Unavailable is thrown (when there are many retrieved tags e.g. 70+)
            if ((error as any).response!.data!.code === 503) {
                fetchMicroserviceVersions();
            }
            else {
                notification.error({
                    message: "Error fetching versions",
                    description: (error as any).response!.data!.message
                });
            }
        }
    }

    const fetchMoreData = async () => {
        if (!isFetchingMoreData && pagination.offset !== -1) {
            setIsFetchingMoreData(true);
            await fetchMicroserviceVersions();
            setIsFetchingMoreData(false);
        }
    }

    const fetchClusters = async () => {
        const { data: { kuberneteses } } = await getKubeService().listKubernetes(projectId, envId);

        const kubernetesNamespaces = await Promise.all(kuberneteses.map(async kubernetes => {
            const { data: { namespaces } } = await getKubeService()
                .getNamespaces(kubernetes.id, projectId)
                .catch(e => {
                    return { data: { namespaces: [] } };
                });

            return {
                ...kubernetes,
                namespaces: namespaces
                    .map(namespace => namespace.metadata.name)
                    .sort((a, b) => {
                        if (a === "microtica" || a === "default") return -1;
                        if (b === "microtica" || b === "default") return 1;
                        return a.localeCompare(b);
                    })
            }
        }));

        if (clusterId) {
            const namespaces = kubernetesNamespaces.find(({ id }) => id === clusterId)!.namespaces;
            form.setFieldsValue({ clusterId });

            if (namespaces.length === 1) {
                form.setFieldsValue({ namespace: namespaces[0] });
            } else if (namespaces.length > 1) {
                form.setFieldsValue({ namespace: "microtica" });
            }

            setNamespaceDisabled(namespaces.length === 1);
        }

        setKubernetesNamespaces(kubernetesNamespaces);
    }

    const handleServiceVersionChange = (value: string) => {
        setServiceVersion(serviceVersions.find(({ name }) => name === value));
    }

    const handleClusterChange = (value: string) => {
        const namespaces = kubernetesNamespaces.find(({ id }) => id === value)!.namespaces;
        setNamespaceDisabled(namespaces.length === 1);
        form.setFieldsValue({ namespace: namespaces[0] });
        handleNamespaceSnippetChange(namespaces[0]);
    }

    const handleNamespaceSnippetChange = (value: string) => {
        form.setFieldsValue({ namespace: value });
    }

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            handleDeploy(values);
        } catch (error) {

        }
    }

    const handleDeploy = async (values: { clusterId: string, namespace: string, version: string, [key: string]: any }) => {
        try {
            setDeploying(true);
            const { clusterId, namespace, version, ...envVars } = values;
            const configurations = Object.keys(envVars)
                .filter(key => envVars[key] !== undefined)
                .map(key => ({ key, value: envVars[key], reference: false, sensitive: !!inputProps[key].sensitive }))

            const microserviceRequest: DeployMicroserviceRequest = {
                deployment: {
                    image: version,
                    configurations
                }
            };

            await getKubeService().deployMicroservice(
                templateId!,
                clusterId,
                namespace,
                projectId!,
                microserviceRequest
            );

            notification.success({
                message: `Application deployed`,
                description: `Application ${templateId} was successfully deployed`,
            });

            setTimeout(() => {
                navigate(
                    `/projects/${projectId}/environments/${envId}/components/${componentId}/apps/${namespace}/${templateId}/overview`
                );
            }, 1000);
        } catch (error) {
            notification.error({
                message: "Error deploying application",
                description: <pre className="white-space-pre-wrap" style={{ fontSize: "12px" }}>
                    {(error as any).response.data.message}
                    <br />
                    <br />
                    {(error as any).response.data.kubernetesError.message}
                </pre>
            });
            setDeploying(false);
        }
    }

    return (
        <PageContentWrapper>
            <Row gutter={24} className="full-width">
                {
                    loading ? <Skeleton /> :
                        <>
                            <Col span={8}>
                                <div className="grid-list">
                                    <Card bordered>
                                        <Card.Meta
                                            avatar={<TemplateAvatar name={serviceInfo?.name} />}
                                            title={serviceInfo?.name}
                                            description={serviceInfo?.description}
                                        />
                                    </Card>
                                </div>
                            </Col>
                            <Col span={16}>
                                <Form
                                    form={form}
                                    layout="vertical"
                                    requiredMark={requiredMark}
                                >
                                    <DeployApplicationModalGeneralSection
                                        serviceVersion={serviceVersion}
                                        serviceVersions={serviceVersions}
                                        handleServiceVersionChange={handleServiceVersionChange}
                                        clusterSection={{
                                            namespaceDisabled: namespaceDisabled,
                                            kubernetesNamespaces: kubernetesNamespaces,
                                            handleClusterChange: handleClusterChange,
                                            handleNamespaceSnippetChange: handleNamespaceSnippetChange
                                        }}
                                        fetchMoreData={fetchMoreData}
                                        loading={isFetchingMoreData}
                                    />
                                    <br />

                                    {
                                        Object.keys(inputProps).length ?
                                            <>
                                                <Card type="inner">
                                                    <Card.Meta
                                                        title="Environment Variables"
                                                        description="Environment variables will be automatically injected in the service runtime"
                                                    />
                                                    <br />
                                                    <ComponentConfigFormItems
                                                        formItems={inputProps}
                                                        required={required}
                                                        setFieldsValue={form.setFieldsValue}
                                                        getFieldValue={form.getFieldValue}
                                                        references={references}
                                                        handleUpdateReferences={setReferences}
                                                        loading={loadingSchema}
                                                        emptyText={<Tag color="default">No environment variables</Tag>}
                                                    />
                                                </Card>
                                                <br />
                                            </> : undefined
                                    }

                                    <Card type="inner">
                                        <Card.Meta title="Deploy" />
                                        <Divider />
                                        <div>Deploy your application in the Kubernetes cluster</div>
                                        <br />
                                        <Button
                                            loading={deploying}
                                            type="primary"
                                            onClick={handleSubmit}
                                            style={{ float: "right" }}>
                                            Deploy
                                        </Button>
                                    </Card>
                                </Form>
                            </Col>
                        </>
                }
            </Row>
        </PageContentWrapper>
    );
}

export default EnvironmentDeployApp;