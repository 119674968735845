import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Row, Space } from "antd"
import GitCommitLink from "./GitCommitLink";
import { App, Component, IEnvDetails } from "../types";
import KubernetesLogs from "../pages/app/KubernetesLogs";
import FargateLogs from "../pages/app/FargateLogs";

interface BuildDetailsDrawerProps {
    env: IEnvDetails;
    component: Component;
    app: App;
    open?: boolean;
    onClose?: () => void;
}

const AppLogsDrawer = ({
    env,
    component,
    app,
    open,
    onClose
}: BuildDetailsDrawerProps) => {
    const handleClose = () => {
        onClose?.();
    }

    const Header = () => (
        <div className="flex-justify-space-between" style={{ marginBottom: "48px" }}>
            <Row gutter={[24, 24]}>
                <Col>
                    <Space direction="vertical">
                        <div style={{ fontSize: "14px", fontWeight: 600 }}>
                            Commit
                        </div>
                        <div>
                            <GitCommitLink
                                version={app.deployment?.sha || app.deployment?.version}
                                repoUrl={app.deployment?.repository}
                                branch={app.deployment?.commitName}
                            />
                        </div>
                    </Space>
                </Col>
                <Col>
                    <Space direction="vertical">
                        <div style={{ fontSize: "14px", fontWeight: 600 }}>
                            Environment
                        </div>
                        <div>
                            {env.name}
                        </div>
                    </Space>
                </Col>
                <Col>
                    <Space direction="vertical">
                        <div style={{ fontSize: "14px", fontWeight: 600 }}>
                            Cluster
                        </div>
                        <div>
                            {component.name}
                        </div>
                    </Space>
                </Col>
                {
                    component.type === "kubernetes" &&
                    <Col>
                        <Space direction="vertical">
                            <div style={{ fontSize: "14px", fontWeight: 600 }}>
                                Namespace
                            </div>
                            <div>
                                {app.namespace}
                            </div>
                        </Space>
                    </Col>
                }
            </Row>
        </div>
    )

    return (
        <Drawer
            title={
                <>
                    <div>
                        App logs
                    </div>
                    <div style={{ fontSize: "14px", fontWeight: 400 }}>
                        {app.name}
                    </div>
                </>
            }
            placement="bottom"
            height="100%"
            onClose={handleClose}
            open={open}
            destroyOnClose={true}
            closable={false}
            extra={
                <Button
                    icon={<CloseOutlined />}
                    onClick={handleClose}
                />
            }
        >
            <Header />

            {
                component.type === "kubernetes" ?
                    <KubernetesLogs
                        advancedFilter={true}
                        env={env}
                        component={component}
                        app={app}
                    /> :
                    <FargateLogs
                        advancedFilter={true}
                        env={env}
                        component={component}
                        app={app}
                    />
            }

        </Drawer>
    )
}

export default AppLogsDrawer;