const AppSettingsAutomatedDeploymentExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Automated deployments
            </div>
            <div className="explanation-subtitle">
                Deploy apps automatically
            </div>
            <br />

            <p>
                Microtica allows you to automatically trigger builds and deployments every time you push your code to a specific branch.
            </p>
            <p>
                This enables you to deliver continuous deployment to your application by automating your workflow and focusing solely on your code.
            </p>
            <p>
                Additionally you can customize this feature by defining filepath patterns that enable you to automatically trigger your
                builds and deployments <span style={{ fontStyle: "italic" }}>only</span> when specific files change.
            </p>
        </>
    )
}

export default AppSettingsAutomatedDeploymentExplanation;