import {
  EnableMonitoringRequestClusterTypeEnum,
  ListClustersResponseInner,
} from "@microtica/ms-monitoring-sdk";
import {
  getEnvironmentService,
  getKubeService,
  getMonitoringService,
} from "../../backend";
import { isResourceDeployed } from "../../components/DeploymentStatusTag";
import { GetStagesItem } from "@microtica/ms-engine-sdk";
import { Cluster } from "./utils";

export const getAllProjectClusters = async ({
  projectId,
  selectedEnv,
  envId,
  envs,
  clustersStatus,
}: {
  projectId: string;
  selectedEnv: string;
  envId: string;
  envs: GetStagesItem[];
  clustersStatus: ListClustersResponseInner[];
}): Promise<Cluster[]> => {
  const [
    {
      data: { resources },
    },
    {
      data: { kuberneteses },
    },
  ] = await Promise.all([
    getEnvironmentService().getStageResources(selectedEnv, projectId),
    getKubeService().listKubernetes(projectId, selectedEnv),
  ]);

  const kubernetesClusters = kuberneteses
    .filter((c) =>
      isResourceDeployed(
        resources.find((r) => r.name === c.id.replace(`${envId}-`, ""))
          ?.status || "DEPLOYED"
      )
    )
    .map((c) => {
      const envId = c.id.split("-").slice(0, 2).join("-");
      const clusterName = c.id.replace(`${envId}-`, "");
      const clusterStatus = clustersStatus.find(
        (s) => s.stageId === selectedEnv && s.id === c.id
      );
      const component = resources.find((r) => r.name === clusterName);

      return {
        id: c.id,
        name: clusterName,
        type: EnableMonitoringRequestClusterTypeEnum.K8s,
        envId: selectedEnv!,
        envName: envs.find((e) => e.id === selectedEnv)?.name!,
        componentId: component?.name || c.id,
        status: clusterStatus?.status,
      };
    });

  const ecsClusters = resources
    .filter(
      (r) =>
        (r.component.type as string) === "fargate" &&
        isResourceDeployed(r.status)
    )
    .map((r) => {
      const clusterStatus = clustersStatus.find(
        (s) => s.stageId === selectedEnv && s.id === r.name
      );
      return {
        id: r.name,
        name: r.name,
        type: EnableMonitoringRequestClusterTypeEnum.Ecs,
        envId: selectedEnv!,
        envName: envs.find((e) => e.id === selectedEnv)?.name!,
        componentId: r.name,
        status: clusterStatus?.status,
      };
    });

  return [...kubernetesClusters, ...ecsClusters];
};

export const getAllEcsClustersInProject = async (projectId: string) => {
  const [
    {
      data: { stages },
    },
    { data: clustersStatus },
  ] = await Promise.all([
    getEnvironmentService().getStages(projectId),
    getMonitoringService()
      .listClusters(projectId)
      .catch((error) => {
        if (error.response.status === 404) {
          return { data: [] as ListClustersResponseInner[] };
        } else {
          throw error;
        }
      }),
  ]);

  const ecsClusters = await Promise.all(
    stages.map(async (stage) => {
      const {
        data: { resources },
      } = await getEnvironmentService().getStageResources(stage.id, projectId);
      const foundCluster = resources
        .filter(
          (r) =>
            (r.component.type as string) === "fargate" &&
            isResourceDeployed(r.status)
        )
        .map((r) => {
          const clusterStatus = clustersStatus.find(
            (s) => s.stageId === stage.id && s.clusterId === r.name
          );
          return {
            id: r.name,
            name: r.name,
            type: EnableMonitoringRequestClusterTypeEnum.Ecs,
            envId: stage.id,
            envName: stage.name,
            componentId: r.name,
            status: clusterStatus?.status,
          };
        });
      return foundCluster;
    })
  );

  return ecsClusters.flatMap((r) => r);
};
