const ResourceSettingsStorageExplanation = () => {
    return (
        <>
            <div className="explanation-title">
                Storage
            </div>
            <br />

            <p>
                Choose which storage solution your application will use to efficiently handle and safeguard your data.
            </p>
            <p>
                Take control of your data storage type, with options including databases like Postgres and MySQL, or cloud storage choices such as S3 buckets, all tailored to match your application's unique data requirements.
            </p>
        </>
    )
}

export default ResourceSettingsStorageExplanation;