import { EnvironmentDetails } from "../types";

export const prepareEKSTemplate = (
    env: EnvironmentDetails,
    templateLink: string,
    vpcResourceName: string,
    appName?: string
) => {

    return {
        stageId: env.id,
        templateLink,
        workDir: "./.microtica",
        environmentVariables: [
            { key: "AppName", value: appName || "EKS" },
            { key: "MAX_NODES", value: "1" },
            { key: "MIN_NODES", value: "1" },
            { key: "NODE_INSTANCE_TYPE", value: "t3.medium" },
            { key: "VPC_RESOURCE_NAME", value: vpcResourceName }
        ],
    }
}

export const prepareGKETemplate = (
    env: EnvironmentDetails,
    templateLink: string,
    vpcResourceName: string,
    appName?: string
) => {
    return {
        stageId: env.id,
        templateLink,
        workDir: "./.microtica",
        environmentVariables: [
            { key: "AppName", value: appName || "EKS" },
            { key: "CLUSTER_NAME", value: `${env.id}-${appName}`.toLocaleLowerCase() },
            { key: "MIN_NODES", value: "1" },
            { key: "MAX_NODES", value: "2" },
            { key: "NODE_INSTANCE_TYPE", value: "e2-standard-2" },
            { key: "VPC_RESOURCE_NAME", value: vpcResourceName }
        ]
    }
}