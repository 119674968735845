import { Col, Menu, Row, Space } from "antd";
import { BranchesOutlined, CloudOutlined, SlackOutlined, ApartmentOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import PageContentWrapper from "../../components/PageContentWrapper";
import { PageHeader } from "@ant-design/pro-layout";
import { useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";

export default function ProjectIntegrationsRoot() {
    const navigate = useNavigate();
    const location = useLocation();
    const currentProject = useRecoilValue(currentProjectState);
    const [activeTab, setActiveTab] = useState("git");

    useEffect(() => {
        setActiveTab(location.pathname.split("/")[4] || "git");
    }, [location.pathname]);

    return (
        <PageContentWrapper
            size="large"
            header={
                <PageHeader
                    title="Integrations"
                />
            }
        >
            <Row gutter={24}>
                <Col span={6}>
                    <Menu
                        mode="inline"
                        className="ant-card-bordered"
                        selectedKeys={[activeTab]}
                        onSelect={(item) => {
                            navigate(item.key);
                            setActiveTab(item.key);
                        }}
                        items={[
                            {
                                key: "git",
                                label: <Space>
                                    <BranchesOutlined />
                                    Git
                                </Space>
                            },
                            {
                                key: "cloud",
                                label: <Space>
                                    <CloudOutlined />
                                    Cloud Accounts
                                </Space>
                            },
                            {
                                key: "container",
                                label: <Space>
                                    <ApartmentOutlined />
                                    Container Registries
                                </Space>
                            },
                            {
                                key: "slack",
                                label: <Space>
                                    <SlackOutlined />
                                    Slack
                                </Space>
                            }
                        ]}
                    />
                </Col>
                <Col span={18}>
                    <Outlet context={{ projectDetails: currentProject }} />
                </Col>
            </Row >
        </PageContentWrapper>
    )
}