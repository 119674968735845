import { HTMLProps, ReactNode } from "react";

interface GitDiffProps extends HTMLProps<ReactNode> {
    baseVersion?: string;
    compareVersion?: string;
    repoUrl?: string;
    commitType?: string;
    branch?: string;
}

const GitDiffLink = ({
    baseVersion,
    compareVersion,
    repoUrl,
    children
}: GitDiffProps) => {
    if (!baseVersion || !compareVersion) {
        return <></>;
    }

    try {
        new URL(repoUrl!);
        if (baseVersion.includes("-") || compareVersion.includes("-") || !repoUrl) {
            return <></>;
        }
    } catch (error) {
        return <></>;
    }

    const { host, pathname } = new URL(repoUrl);

    switch (host) {
        case "github.com":
            return (
                <a href={`${repoUrl}/compare/${baseVersion}...${compareVersion}`} target="_blank" rel="noreferrer">{children}</a>
            );
        case "bitbucket.org":
            return (
                <a href={`${repoUrl}/branches/compare/${compareVersion}%0D${baseVersion}#diff`} target="_blank" rel="noreferrer">{children}</a>
            );
        case "gitlab.com":
            return (
                <a href={`${repoUrl}/-/compare/${baseVersion}...${compareVersion}`} target="_blank" rel="noreferrer">{children}</a>
            );
        default:
            const [, region] = host.split(".");
            const repoName = pathname.split("/").reverse()[0];
            return (
                <a
                    target="_blank"
                    href={`https://${region}.console.aws.amazon.com/codesuite/codecommit/repositories/${repoName}/compare/${baseVersion}/.../${compareVersion}/?region=${region}`}
                    rel="noreferrer"
                >
                    {children}
                </a>
            );
    }
}

export default GitDiffLink;