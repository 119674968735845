const NotFoundRepo = (props: {
    projectId: string,
    provider?: string,
    accountName: string
}) => {
    if (props.provider === "github") {
        return <div style={{ padding: "10px" }} className="gray-text">
            Couldn't find the repository?
            <br />
            <br />

            If the repository is associated with a <b>GitHub organization</b>:

            <ol>
                <li>Reconnect your GitHub in <a href={`/projects/${props.projectId}/integrations/git`} target="_blank" rel="noreferrer">Project Integrations</a></li>
                <li>Verify whether you have granted Microtica access to your organization as per the <a href="https://docs.github.com/en/organizations/managing-oauth-access-to-your-organizations-data/approving-oauth-apps-for-your-organization" target="_blank" rel="noreferrer">guidelines</a></li>
            </ol>
        </div>

    } else {
        return <div className="text-center gray-text" style={{ padding: "10px" }}>
            There are no repositories matching the search in the <b>{props.accountName}</b> GitHub account.
        </div>;
    }
}

export default NotFoundRepo;