import { Button, Card, Divider, Radio, Select, Skeleton, Space, Tooltip } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { getEnvironmentService, getKubeService } from "../../backend";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentProjectState } from "../../recoil/project";
import { AllClusters, Environment, EnvironmentDetails, FormState } from "../../types";
import { ListKubernetesResponseKubernetesesTypeEnum } from "@microtica/ms-kube-sdk";
import ConnectClusterModal from "../modals/ConnectClusterModal";
import { trackKubeConnectInit } from "../../backend/tracking/kubernetes";
import CreateEnvironment from "../CreateEnvironment";
import { currentTemplateStepState, latestTemplateStepState, templateState } from "../../recoil/template";
import { useCardClassName } from "../../contexts/Helpers/helpers";
import { useAuth } from "../../contexts/Auth";
import CreateClusterModal from "../modals/CreateClusterModal";
import { trackTemplateKubernetesAwsCreate, trackTemplateKubernetesConnectExisting, trackTemplateKubernetesSave } from "../../backend/tracking/templates";
import ExplanationButton from "../explanations/ExplanationButton";
import SelectClusterExplanation from "../explanations/SelectClusterExplanation";
import { CreateStageRequestCloudProviderEnum, CreateStageRequestInfrastructureAsCodeToolEnum } from "@microtica/ms-engine-sdk";
import MessageCard from "../cards/MessageCard";
import { AWS_REGIONS, GCP_REGIONS, GCP_ZONES } from "../../enums/enums";
import { useParams } from "react-router";

interface ClusterTemplateModuleProps {
    templateName: string;
    creatingCluster: boolean;
    setCreatingCluster: React.Dispatch<React.SetStateAction<boolean>>;
    initialEnvironment?: EnvironmentDetails;
    onSelect?: (environment: EnvironmentDetails) => void;
    envId?: string;
    clusterAppName?: string;
    setClusterAppName: (clusterAppName?: string) => void;
    disabled?: boolean;
}

const ClusterTemplateModule = ({
    templateName,
    creatingCluster,
    setCreatingCluster,
    initialEnvironment,
    onSelect,
    envId,
    clusterAppName,
    setClusterAppName,
    disabled
}: ClusterTemplateModuleProps) => {
    const { isLoggedIn } = useAuth();
    const { componentId } = useParams() as { componentId: string; };
    const currentProject = useRecoilValue(currentProjectState);
    const [loadingClusters, setLoadingClusters] = useState(true);
    const [clusters, setClusters] = useState<AllClusters>({});
    const [namespaces, setNamespaces] = useState<{ id: string; name: string }[]>([]);
    const [loadingNamespaces, setLoadingNamespaces] = useState(false);
    const [clusterExists, setClusterExists] = useState(false);
    const [clusterType, setClusterType] = useState<"own" | "shared" | "connected">(!creatingCluster ? "connected" : "own");
    const [showConnectClusterModal, setShowConnectClusterModal] = useState(false);
    const [showCreateClusterModal, setShowCreateClusterModal] = useState(false);
    const [defaultEnvironment, setDefaultEnvironment] = useState<Omit<Environment, "cloudProvider">>();
    const [currentTemplateState, setCurrentTemplateState] = useRecoilState(templateState);
    const currentTemplateStep = useRecoilValue(currentTemplateStepState);
    const latestTemplateStep = useRecoilValue(latestTemplateStepState);
    const [selectedEnv, setSelectedEnv] = useState(initialEnvironment);
    const [validationError, setValidationError] = useState<ReactNode>();


    useEffect(() => {
        if (isLoggedIn && !!currentProject) {
            loadData(!initialEnvironment);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, currentProject]);

    useEffect(() => {
        handleSetTemplateState(disabled ? "disabled" : "editing");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);


    useEffect(() => {
        if (clusterType === "connected") {
            setCreatingCluster(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clusterType]);

    const cardClassName = useCardClassName(
        disabled || currentTemplateState["cluster"] === "disabled",
        [disabled, currentTemplateState, currentTemplateStep]
    );

    const handleSetTemplateState = (mode: FormState) => {
        setCurrentTemplateState({
            ...currentTemplateState,
            cluster: mode,
            ...(mode === "saved" && { deploy: "editing" })
        });
    }

    const loadData = async (selectDefaultCluster = true, newClusterName?: string) => {
        const { data: { stages: environments } } = await getEnvironmentService().getStages(currentProject!.id);

        const allClusters = (await Promise.all(
            environments.map(env =>
                getKubeService().listKubernetes(currentProject!.id, env.id)
                    .then(({ data: { kuberneteses } }) => ({ ...env, clusters: kuberneteses }))
            )
        )).reduce((allClusters, env) => {
            allClusters[env.id] = {
                clusters: env.clusters.map(cluster => ({
                    id: cluster.id,
                    name: cluster.name,
                    type: cluster.type
                })),
                env: {
                    id: env.id,
                    name: env.name,
                    accountId: env.awsAccountId,
                    region: AWS_REGIONS.find(r => r.value === env.awsRegion)?.id,
                    gcpProjectId: env.gcpProjectId,
                    gcpRegion: GCP_REGIONS.find(r => r.value === env.gcpRegion)?.id,
                    gcpZone: GCP_ZONES.find(r => r.name === env.gcpZone)?.id,
                    cloudProvider: env.cloudProvider,
                    infrastructureAsCodeTool: env.infrastructureAsCodeTool
                }
            };
            return allClusters;
        }, {} as AllClusters);

        const filteredClusters = envId ? { [envId]: allClusters[envId] } : allClusters;
        setClusters(filteredClusters);

        const clusterExists = Object.values(filteredClusters).some(cluster => !!cluster.clusters.length);
        setClusterExists(clusterExists);
        setLoadingClusters(false);

        const defaultEnv = envId ?
            allClusters[envId].env :
            clusterExists ?
                Object.values(filteredClusters).find(obj => !!obj.clusters.length)!.env :
                Object.values(filteredClusters)[0]?.env;

        setDefaultEnvironment(defaultEnv);
        // newClusterName indicates that the user has connected an existing cluster, set it as default
        if (selectDefaultCluster && !!newClusterName) {
            const { env, clusters } = Object.values(filteredClusters).find(({ clusters }) =>
                clusters.find(({ name }) => name === newClusterName)
            )!;
            const clusterInfo = clusters.find(({ name }) => name === newClusterName)!;

            setSelectedEnv({
                ...env,
                cloudProvider: env.gcpProjectId ?
                    CreateStageRequestCloudProviderEnum.Google :
                    CreateStageRequestCloudProviderEnum.Aws,
                clusterId: clusterInfo.id,
                clusterName: clusterInfo.name,
                clusterType: clusterInfo.type,
                clusterNamespace: undefined
            } as EnvironmentDetails);
            // Select the first radio option if the user already has a connected cluster
            setClusterType("connected");

            // set default cluster if there is at least one cluster
        } else if (clusterExists && !selectedEnv?.clusterId && selectDefaultCluster) {
            const cluster = filteredClusters[defaultEnv.id].clusters.find(c => c.id === componentId) || filteredClusters[defaultEnv.id].clusters[0];
            setSelectedEnv({
                ...defaultEnv,
                cloudProvider: defaultEnv.gcpProjectId ?
                    CreateStageRequestCloudProviderEnum.Google :
                    CreateStageRequestCloudProviderEnum.Aws,
                clusterId: cluster.id,
                clusterName: cluster.name,
                clusterType: cluster.type
            } as EnvironmentDetails);

            // Select the first radio option if the user already has a connected cluster
            setClusterType("connected");
        } else if (defaultEnv) {
            setSelectedEnv({
                ...defaultEnv,
                cloudProvider: defaultEnv.cloudProvider === "aws" ?
                    CreateStageRequestCloudProviderEnum.Aws :
                    CreateStageRequestCloudProviderEnum.Google
            } as EnvironmentDetails);
            setClusterType("own")
        }
    }

    useEffect(() => {
        const loadNamespaces = async () => {
            if (selectedEnv?.clusterId) {
                try {
                    setLoadingNamespaces(true);
                    const { data: { namespaces } } = await getKubeService().getNamespaces(selectedEnv.clusterId, currentProject!.id);
                    setNamespaces(namespaces
                        .map(ns => ({
                            id: ns.metadata.name,
                            name: ns.metadata.name
                        }))
                    );
                    // select new namespace if the namespaces of the newly selected cluster don't include the current namespace
                    if (!selectedEnv?.clusterNamespace || !namespaces.map(ns => ns.metadata.name).includes(selectedEnv.clusterNamespace)) {
                        handleOnSelectClusterNamespace(
                            namespaces.find(ns => ns.metadata.name === "microtica")?.metadata.name ||
                            namespaces.find(ns => ns.metadata.name === "default")?.metadata.name ||
                            namespaces[0].metadata.name
                        )
                    }
                } catch (error) {

                } finally {
                    setLoadingNamespaces(false);
                }
            }
        }

        loadNamespaces();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEnv?.clusterId]);

    const handleOnSelectCluster = (id: string, { type, key }: { type: ListKubernetesResponseKubernetesesTypeEnum, key: string }) => {
        if (selectedEnv?.clusterId !== id) {
            const { env } = Object.values(clusters).find(value => {
                return value.clusters.find(c => c.id === id);
            })!;
            setSelectedEnv(destination => ({
                ...destination,
                ...env,
                clusterId: id,
                clusterName: key,
                clusterType: type,
            }) as EnvironmentDetails);
            handleSetTemplateState("editing");
        }
    }

    const handleOnSelectClusterNamespace = (namespace: string) => {
        if (selectedEnv?.clusterNamespace !== namespace) {
            setSelectedEnv(destination => ({
                ...destination,
                clusterNamespace: namespace
            }) as EnvironmentDetails);
            if (validationError) {
                setValidationError(undefined);
            }
            handleSetTemplateState("editing");
        }
    }

    const handleActiveStepChange = () => {
        setCurrentTemplateState(currentTemplateState => ({
            ...currentTemplateState,
            config: "editing",
            cluster: latestTemplateStep.index > 2 ? "saved" : "editing"
        }))
    }

    const handleSelectDestination = () => {
        // create new eks option is chosen but there is no selected environment or cluster name
        if (clusterType === "own" && !creatingCluster) {
            setValidationError(<div>Please choose one of the options for creating/connecting a cluster <b><i>or</i></b> if you have already connected clusters choose one of them.</div>);
            return;
        }
        if (clusterType === "connected" && (!selectedEnv?.clusterId || !selectedEnv?.clusterNamespace)) {
            setValidationError(<div>Please select cluster and namespace <b><i>or</i></b> choose the option to create a new Kubernetes cluster.</div>);
            return;
        }
        trackTemplateKubernetesSave(templateName);
        // creatingCluster is true when 'Create Amazon EKS Cluster' is chosen and cluster name, env and aws account are provided
        if (creatingCluster) {
            onSelect?.({
                id: selectedEnv!.id,
                name: selectedEnv!.name,
                accountId: selectedEnv!.accountId,
                region: selectedEnv!.region,
                gcpProjectId: selectedEnv!.gcpProjectId,
                gcpRegion: selectedEnv!.gcpRegion,
                gcpZone: selectedEnv!.gcpZone,
                cloudProvider: selectedEnv!.cloudProvider === "aws" ?
                    CreateStageRequestCloudProviderEnum.Aws :
                    CreateStageRequestCloudProviderEnum.Google,
                infrastructureAsCodeTool: selectedEnv?.infrastructureAsCodeTool === "cloudformation" ?
                    CreateStageRequestInfrastructureAsCodeToolEnum.Cloudformation :
                    CreateStageRequestInfrastructureAsCodeToolEnum.Terraform

            })
        } else if (selectedEnv) {
            onSelect?.({
                ...selectedEnv,
                cloudProvider: selectedEnv.cloudProvider === "aws" ?
                    CreateStageRequestCloudProviderEnum.Aws :
                    CreateStageRequestCloudProviderEnum.Google,
                infrastructureAsCodeTool: selectedEnv?.infrastructureAsCodeTool === "cloudformation" ?
                    CreateStageRequestInfrastructureAsCodeToolEnum.Cloudformation :
                    CreateStageRequestInfrastructureAsCodeToolEnum.Terraform
            });
        }
        handleSetTemplateState("saved");
    }

    const FeedbackMessage = () => {
        let content, type: "error" | "domain-info";
        if (validationError) {
            type = "error";
            content = validationError;
        }
        else if (clusterType === "own" && creatingCluster) {
            type = "domain-info";
            content = <div>Your app will be deployed to the <b>{clusterAppName}</b> cluster right after the cluster has been successfully deployed in the <b>{selectedEnv?.name}</b> environment</div>
        }
        else if (clusterType === "connected" && selectedEnv?.clusterId && selectedEnv?.clusterNamespace) {
            type = "domain-info";
            content = <div>Your app will be deployed to the <b>{selectedEnv?.clusterName}</b> cluster under the <b>{selectedEnv?.clusterNamespace}</b> namespace</div>
        } else {
            return null;
        }

        return <MessageCard
            type={type}
            text={content}
            showIcon={false}
            style={{ marginTop: 24 }}
        />
    }

    return (
        <>
            {
                !defaultEnvironment &&
                (
                    !loadingClusters ?
                        <CreateEnvironment onSuccessfullyCreatedStage={loadData} /> :
                        <Card className={cardClassName}>
                            <Card.Meta
                                title={
                                    <>
                                        <span>Choose Where To Deploy</span>
                                        <ExplanationButton
                                            content={<SelectClusterExplanation />}
                                        />
                                    </>
                                }
                                description="Cluster is the infrastructure that runs your container applications."
                            />
                            <Divider />
                            <Skeleton />
                        </Card>
                )
            }
            {
                !!defaultEnvironment &&
                <Card className={cardClassName}>
                    <Card.Meta
                        title={
                            <>
                                <span>Choose Where To Deploy</span>
                                <ExplanationButton
                                    content={<SelectClusterExplanation />}
                                />
                            </>
                        }
                        description="Application will be deployed in the selected Cluster."
                    />
                    <Divider />

                    {
                        loadingClusters ? <Skeleton /> :
                            <Radio.Group
                                onChange={(e) => setClusterType(e.target.value)}
                                value={clusterType}
                                className="full-width"
                            >
                                {clusterExists &&
                                    <Card size="small" style={{ marginBottom: "12px" }}>
                                        <Radio value="connected" checked>
                                            <div>
                                                <div style={{ fontWeight: 500 }}>
                                                    Deploy on existing cluster
                                                </div>
                                                <div className="gray-text">
                                                    Select a cluster from the list of already connected clusters in your project.
                                                </div>
                                                {clusterType === "connected" &&
                                                    <span onClick={(e) => {
                                                        // there is a bug in antd when nesting a Select inside a Radio
                                                        // (Select is immediately closed upon opening) so this is a workaround
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}>
                                                        <Space style={{ marginTop: "12px" }}>
                                                            <Select
                                                                defaultValue={componentId}
                                                                showSearch
                                                                style={{ width: "250px" }}
                                                                placeholder="Select Cluster"
                                                                value={selectedEnv?.clusterId}
                                                                onSelect={handleOnSelectCluster}
                                                            >
                                                                {
                                                                    Object.entries(clusters).map(([key, value]) => (
                                                                        value.clusters.length &&
                                                                        <Select.OptGroup key={key} label={`${value.env.name} environment`}>
                                                                            {
                                                                                value.clusters.map(cluster => (
                                                                                    <Select.Option value={cluster.id} type={cluster.type} key={cluster.name}>
                                                                                        {cluster.name}
                                                                                    </Select.Option>
                                                                                ))
                                                                            }
                                                                        </Select.OptGroup>
                                                                    ))
                                                                }
                                                            </Select>
                                                            <Tooltip
                                                                title={
                                                                    selectedEnv?.clusterType === ListKubernetesResponseKubernetesesTypeEnum.Shared ?
                                                                        "Cannot change the namespace that was allocated from our free cluster." :
                                                                        undefined
                                                                }
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    style={{ width: "250px" }}
                                                                    placeholder="Select cluster namespace"
                                                                    loading={loadingNamespaces}
                                                                    filterOption={(input, option: any) => {
                                                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }}
                                                                    filterSort={(optionA, optionB) => {
                                                                        return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                    }}
                                                                    disabled={loadingNamespaces || selectedEnv?.clusterType === ListKubernetesResponseKubernetesesTypeEnum.Shared}
                                                                    value={selectedEnv?.clusterNamespace}
                                                                    onSelect={handleOnSelectClusterNamespace}
                                                                >
                                                                    {
                                                                        namespaces.map(ns => (
                                                                            <Select.Option value={ns.id} key={ns.id}>{ns.name}</Select.Option>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </Tooltip>
                                                        </Space>
                                                    </span>
                                                }
                                            </div>
                                        </Radio>
                                    </Card>
                                }

                                <Card>
                                    <Radio value="own" checked>
                                        <div style={{ fontWeight: 500 }}>
                                            Create new Kubernetes cluster
                                        </div>
                                        <div className="gray-text">
                                            Connect to existing cluster or create a new one in your own cloud environment.
                                        </div>
                                        {
                                            clusterType === "own" &&
                                            <Space style={{ marginTop: "12px" }}>
                                                <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        setShowCreateClusterModal(true);
                                                    }}
                                                >
                                                    Create new cluster
                                                </Button>
                                                <Divider type="vertical" />
                                                <Button onClick={() => {
                                                    setCreatingCluster(false);
                                                    setShowConnectClusterModal(true);
                                                    trackKubeConnectInit();
                                                }}>
                                                    Connect existing cluster
                                                </Button>
                                            </Space>
                                        }
                                    </Radio>
                                </Card>
                            </Radio.Group>
                    }
                    <FeedbackMessage />
                    <Divider />
                    <div className="flex-justify-space-between flex-align-center">
                        <Button onClick={handleActiveStepChange}>
                            Back
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleSelectDestination}
                            disabled={
                                currentTemplateState["cluster"] === "disabled"
                            }
                        >
                            Next
                        </Button>
                    </div>
                    {
                        showConnectClusterModal &&
                        <ConnectClusterModal
                            envId={envId}
                            environments={Object.values(clusters).map(({ env }) => env)}
                            visible={showConnectClusterModal}
                            onCancel={() => setShowConnectClusterModal(false)}
                            onOk={(newClusterName) => {
                                trackTemplateKubernetesConnectExisting(templateName);
                                loadData(true, newClusterName);
                                setShowConnectClusterModal(false);
                                setSelectedEnv(undefined);
                            }}
                        />
                    }
                    {
                        // Workaround. The modal is not unmouting when closed so we need to unmount the whole component using 'modalVisible'
                        // We have a process of periodic API calls which remain active even if when the modal closes
                        showCreateClusterModal &&
                        <CreateClusterModal
                            envId={envId}
                            modalVisible={showCreateClusterModal}
                            setModalVisible={setShowCreateClusterModal}
                            selectedEnv={selectedEnv}
                            setSelectedEnv={setSelectedEnv}
                            clusterAppName={clusterAppName}
                            setClusterAppName={setClusterAppName}
                            onOk={() => {
                                setCreatingCluster(true);
                                if (validationError) {
                                    setValidationError(undefined);
                                }
                                trackTemplateKubernetesAwsCreate(templateName);
                            }}
                            onCancel={() => {
                                setCreatingCluster(false);
                                setClusterAppName(undefined);
                            }}
                        />
                    }
                </Card>
            }
        </>
    )
}

export default ClusterTemplateModule;