import { ReactNode, useEffect, useState } from "react";
import { Card, Skeleton, Space } from "antd";

const DisplayMetric = ({ icon, title, data, size, style }: {
    icon: ReactNode,
    title: string,
    data: string | number | ReactNode,
    size: "small" | "medium" | "large",
    style?: any
}) => {
    const defaultIconColor = "#097BB5";
    const [cardStyle, setCardStyle] = useState<{ iconFontSize: number, titleFontSize: number, dataFontSize: number, cardPadding: string | number, iconColor: string }>({
        iconFontSize: 35,
        titleFontSize: 13,
        dataFontSize: 17,
        cardPadding: "10px 15px",
        iconColor: defaultIconColor
    });

    useEffect(() => {
        switch (size) {
            case "small":
                return setCardStyle({
                    iconFontSize: 25,
                    titleFontSize: 11,
                    dataFontSize: 13,
                    cardPadding: "8px 12px",
                    iconColor: style?.iconColor || defaultIconColor
                });
            case "medium":
                return setCardStyle({
                    iconFontSize: 35,
                    titleFontSize: 13,
                    dataFontSize: 17,
                    cardPadding: "10px 15px",
                    iconColor: style?.iconColor || defaultIconColor
                });
            case "large":
                return setCardStyle({
                    iconFontSize: 40,
                    titleFontSize: 14,
                    dataFontSize: 20,
                    cardPadding: "16px 24px",
                    iconColor: style?.iconColor || defaultIconColor
                });
        }
    }, [size, style])

    return (
        <>
            {
                title !== undefined && data !== undefined ?
                    <Card type="inner" bodyStyle={{ padding: cardStyle.cardPadding }}>
                        <Space>
                            <div style={{ color: cardStyle.iconColor, fontSize: cardStyle.iconFontSize }}>
                                {icon}
                            </div>
                            <div>
                                <div style={{ fontSize: cardStyle.titleFontSize }}>
                                    {title}
                                </div>
                                {
                                    data !== undefined ?
                                        <div style={{ color: "#112d3d", fontSize: cardStyle.dataFontSize, fontWeight: 500 }} >
                                            {data}
                                        </div> :
                                        <div style={{ fontSize: cardStyle.dataFontSize }}>
                                            No information available
                                        </div>
                                }
                            </div>
                        </Space>
                    </Card> :
                    <Skeleton />
            }
        </>
    )
}

export default DisplayMetric;