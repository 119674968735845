import { getCloudService, getEnvironmentService } from "../backend";

/**
 * Fetch GCP accounts info for stage and project
 */
export const fetchGcpAccountsInfo = async (envId: string, projectId: string): Promise<{ projectGcpAccounts: string[], stageGcpAccountId: string }> => {
    const [{ gcpAccounts }, { gcpProjectId }] = await Promise.all([
        getGcpAccounts(projectId),
        getStageGcpAccount(envId, projectId)
    ]);
    return {
        projectGcpAccounts: gcpAccounts,
        stageGcpAccountId: gcpProjectId!
    }
}

const getGcpAccounts = async (projectId: string) => {
    try {
        const { data: { gcpAccounts } } = await getCloudService().getGcpAccounts(projectId);
        return {
            gcpAccounts: gcpAccounts.map(acc => acc.gcpProjectId)
        };
    } catch (error) {
        return { gcpAccounts: [] };
    }
}

const getStageGcpAccount = async (envId: string, projectId: string) => {
    try {
        const { data: { gcpProjectId } } = await getEnvironmentService().getStage(envId, projectId)
        return { gcpProjectId };
    } catch (error) {
        return { gcpProjectId: undefined };
    }
}